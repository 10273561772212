import {
    Button,
    Input,
    InputRef,
    Row,
    Space,
    Spin,
    Table,
    Tag,
    Tooltip,
    Modal as ModalAntd,
    Typography,
    notification,
  } from 'antd';
  import {
    DeleteOutlined,
    FileOutlined,
    CheckCircleOutlined,
    ExclamationCircleOutlined,
    SearchOutlined,
    EditOutlined,
    CheckOutlined,
  } from '@ant-design/icons';
  import moment from 'moment';
  import { Link, useParams } from 'react-router-dom';
  import React, {useEffect, useRef, useState } from 'react';
 
  import Highlighter from 'react-highlight-words';
import Modal from '../../../General/Modal';
import AddCustomerDocument from './AddCustomerDocument.js/AddCustomerDocument';
import { fetchApi } from '../../../../helpers/fetch';
import { METHOD_DELETE, METHOD_GET } from '../../../../utils/constants';
import { getAccessTokenApi } from '../../../../api/auth';
  
  const label = 'archivo';
  
  let entries = [];
  
  const CustomerDocumentsTable = ({client}) => {
    const { Text } = Typography;
    const { confirm } = ModalAntd;
  
    const [innerFiles, setInnerFiles] = useState([]);
    const [files, setFiles] = useState([]);
    const [filesLoading, setFilesLoading] = useState(true);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [modalIsVisible, setModalIsVisible] = useState(false);
    const [reloadTable, setReloadTable] = useState(true);
    const [titleModal, setTitleModal] = useState('');
    const [contextModal, setContextModal] = useState(null);
    const searchInput = useRef(null);
    const { id } = useParams();
    const token = getAccessTokenApi();
  useEffect(() => {
    if (client.country === 29) {
        entries = [
            { key: 'taxSituation', label: 'Constancia Situación Fiscal' },
            { key: 'satOpinion', label: 'Opinión Positiva' },
            { key: 'billingProcess', label: 'Proceso de facturación y contactos' },
            { key: 'incorporationAct', label: 'Acta constitutiva' },
            { key: 'ineAgent', label: 'INE Representante' },
            { key: 'addressProof', label: 'Comprobante de domicilio' },
          ];
    }else if (client.country === 30) {
        entries = [
            { key:'w9Form', label: 'W9 Form' },
            { key: 'certificateOfFiling', label:'Certificate of Filing' },
            { key: 'billingProcess', label: 'Proceso de facturación y contactos' },
            { key: 'ineAgent', label: 'INE Representante' },
            { key: 'addressProof', label: 'Comprobante de domicilio' },
          ];
    }
  }, [client]);

    const getFilesCallback = React.useCallback(async () => {
            const config = {
                method: METHOD_GET,
                url: `customerFiles?customerId=${encodeURIComponent(client.id)}`,
                data: null,
                token,
              };
            fetchApi(config)
            .then((response) => {
            if (response.statusCode === 200) {
                setInnerFiles(() => response.result);
                let files= [];
                entries.forEach((entry) => {
                  const file = response.result.find((file) => file.key === entry.key);
                  if (file) {
                    files.push({
                      name: file.fileName,
                      name_description: entry.label,
                      status:'completo',
                      fileUrl: file.fileLocation,
                      created_at: file.createdAt,
                      key: entry.key,
                      id: file.id
                    });
                  } else {
                    files.push({
                      name: '-',
                      name_description: entry.label,
                      status: 'sin_archivo',
                      fileUrl: '',
                      created_at: undefined,
                      key: entry.key,
                    });
                  }
                });
                setFiles(files);
              }
              setReloadTable(false)
          })
          setFilesLoading(false);
    }, [token, client.id, reloadTable]);
  
    useEffect(() => {
      if (id !== undefined) getFilesCallback();
    }, [id, getFilesCallback]);
  
    useEffect(() => {
      getFilesCallback();
    }, [contextModal, getFilesCallback]);
    const handleSearch = (selectedKeys,confirm,dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {clearFilters();setSearchText('');};
    const getColumnSearchProps = (dataIndex) => ({
      filterDropdown: ({setSelectedKeys,selectedKeys,confirm,clearFilters,close}) => (
        <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
          <Input
            ref={searchInput}
            placeholder={`Buscar`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() =>
              handleSearch(selectedKeys, confirm, dataIndex)
            }
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() =>
                handleSearch(selectedKeys, confirm, dataIndex)
              }
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Buscar
            </Button>
            <Button
              onClick={() => {
                clearFilters && handleReset(clearFilters);
                handleSearch(selectedKeys, confirm, dataIndex);
              }}
              size="small"
              style={{ width: 90 }}
            >
              Limpiar
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                close();
              }}
            >
              Cerrar
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
      ),
      onFilter: (value, record) =>
        (record[dataIndex])
          .toLowerCase()
          .includes((value).toLowerCase()),
      onFilterDropdownOpenChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current?.select(), 100);
        }
      },
      render: (text) =>
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        ) : (
          text
        ),
    });
  
    const showModal = (type,row,file) => {
      setModalIsVisible(true);
      setTitleModal(`${type === 'add' ? 'Agregar' : type === 'edit' ? 'Editar' : 'Ver'} ${label}`);
      setContextModal(() => (
        <AddCustomerDocument
          row={row}
          type={type}
          file={file}
          setModalIsVisible={setModalIsVisible}
          setReloadTable={setReloadTable}
          add={(e) => {
            if (e) {
              setFiles((files) =>
                files.map((file) => {
                  if (file.key === e.name_key) {
                    return {
                      ...file,
                      status:'completo',
                      name: e.name,
                      fileUrl: e.fileUrl,
                      created_at: e.created_at,
                      key: e.name_key,
                    };
                  }
                  return file;
                })
              );
              setModalIsVisible(false);
            }
          }}
          update={(e) => {
            if (e) {
              setFiles((files) =>
                files.map((file) => {
                  if (file.key === e.name_key) {
                    return {
                      ...file,
                      status:'completo',
                      name: e.name,
                      fileUrl: e.fileUrl,
                      created_at: e.created_at,
                    };
                  }
                  return file;
                })
              );
              setModalIsVisible(false);
            }
          }}
          clientId={client.id}
        />
      ));
    };
  
    const handleDelete = (key) => {
      confirm({
        title: `Eliminar ${label}`,
        icon: <ExclamationCircleOutlined />,
        content: `¿Estás seguro que deseas borrar el ${label} seleccionado?, no podrá recuperarlo después.`,
        okText: 'Sí',
        okType: 'danger',
        cancelText: 'No',
        onOk() {
          let fileToDelete = innerFiles.find((f) => f.key === key);
          if (fileToDelete !== undefined) {
            const config = {
                method: METHOD_DELETE,
                url: `customerFiles/${fileToDelete.id}`,
                data: null,
                token,
              };
            fetchApi(config)
            .then((response) => {
              if (response.statusCode === 200) {
                notification.success({
                  message: `Se ha eliminado el ${label} correctamente.`,
                });
                // refresh files
                setFiles((files) =>
                  files.map((file) => {
                    if (file.key === key) {
                      return {
                        ...file,
                        status: 'sin_archivo',
                        name: '-',
                        fileUrl: '',
                        created_at: undefined,
                      };
                    }
                    return file;
                  })
                );
                setReloadTable(true);
              }
            });
          }
        },
        onCancel() {},
      });
    };
  
    const modalCallback = React.useCallback(
      (file, record) => {
        const config = {
            method: METHOD_GET,
            url: `customerFiles/${record.id}`,
            data: null,
            token,
          };
        fetchApi(config)
        .then((e) => {
          const resp = e.result;
        //   let algo = resp.find((f) => f.key === record.key);
          showModal('edit', file, resp);
        });
      },
      [id]
    );
  
    const columns = [
      {
        title: 'Nombre',
        dataIndex: 'name_description',
        key: 'name_description',
        ...getColumnSearchProps('name_description'),
        render: (name_description) => <Text>{name_description}</Text>,
      },
      {
        title: 'Estatus',
        key: 'status',
        width: 120,
        dataIndex: 'status',
        filters: [
          { text: 'SIN ARCHIVO', value: 'sin_archivo' },
          { text: 'COMPLETO', value: 'completo' },
        ],
        onFilter: (value, record) => record.status.startsWith(value),
        render: (status) => (
          <Row gutter={16} style={{ width: 190 }}>
            <Tag
              color={
                status === 'completo'
                  ? 'green': 'red'
              }
              key={status}
              style={{ marginBottom: 10 }}
            >
              {status.replace('_', ' ').toUpperCase()}
            </Tag>
          </Row>
        ),
      },
      {
        title: 'Archivo',
        dataIndex: 'fileUrl',
        key: 'fileUrl',
        ...getColumnSearchProps('fileUrl'),
        render: (fileUrl, rec) => <a target="_blank" rel='noreferrer' href={fileUrl}>{rec.name}</a>,
      },
      {
        title: 'Fecha de creación',
        dataIndex: 'created_at',
        key: 'created_at',
        render: (created_at) => (
          <>{created_at ? moment.unix(created_at).format('DD/MM/YYYY') : '-'}</>
        ),
      },
      {
        title: 'Acciones',
        key: 'sup',
        fixed: 'right',
        width: 120,
        align: 'center',
        render: (file, record) => (
          <>
            {record.status === 'sin_archivo' && (
                <Tooltip title="Cargar archivo">
                  <Button
                    style={{
                      marginLeft: 10,
                    }}
                    type="primary"
                    shape="default"
                    // size="small"
                    icon={<FileOutlined />}
                    onClick={() => {
                      showModal('add', file);
                    }}
                  />
                </Tooltip>
            )}
            {(record.fileUrl !== '') && (
                <Tooltip title="Editar archivo">
                  <Button
                    style={{
                      marginLeft: 10,
                    }}
                    ghost
                    type="primary"
                    shape="default"
                    // size="small"
                    icon={<EditOutlined />}
                    onClick={() => modalCallback(file, record)}
                  />
                </Tooltip>
            )}
              <Tooltip title="Eliminar">
                <Button
                  danger
                  style={{
                    marginLeft: 10,
                  }}
                  type="primary"
                  shape="default"
                //   size="small"
                  icon={<DeleteOutlined />}
                  // onclick delete file by id
                  onClick={() => handleDelete(record.key)}
                />
              </Tooltip>
          </>
        ),
      },
    ];
  
    return (
      <Spin spinning={reloadTable} tip={<p>Cargando...</p>}>
        {/* Tabla */}
        {!filesLoading ? (
          <Table
            columns={columns}
            dataSource={files}
            scroll={{ x: 1000 }}
            pagination={false}
          />
        ) : null}
        {/* Modal de detalle */}
        <Modal
          title={titleModal}
          isVisible={modalIsVisible}
          setIsVisible={setModalIsVisible}
          width={1000}
        >
          {contextModal}
        </Modal>
      </Spin>
    );
  };
  
  export default CustomerDocumentsTable;
  