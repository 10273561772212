import React from 'react';
import { Col, Layout, Row, Typography } from 'antd';
import GreetingsTop from '../../../components/Admin/GreetingsTop/GreetingsTop';
import { useParams } from 'react-router-dom';
import PaymentsTable from '../../../components/Logistic/PaymentsTable/PaymentsTable';
import BackButton from '../../../components/General/BackButton/BackButton';
import { ROL_SUPPLIER } from '../../../utils/constants';
import useUser from '../../../hooks/useUser';

export default function Payments() {
  let { invoiceId, type, folio } = useParams();

  return (
    <Layout>
      <GreetingsTop />
      <HeaderTop invoiceId={invoiceId} type={type} folio={folio} />
      <div className="billing__container">
        <PaymentsTable invoiceId={invoiceId} />
      </div>
    </Layout>
  );
}

function HeaderTop({ invoiceId, type, folio }) {
  const { Title } = Typography;
  const { person } = useUser();

  return (
    <div className="billing__container">
      <Row className="header-top">
        <Col xs={23} md={21} className="header-top__left">
          <Title
            level={3}
            style={{
              // position: 'absolute',
              top: 0,
              bottom: 0,
              height: '78%',
              margin: 'auto',
            }}
          >
            Pagos factura: {type === 'ap' ? invoiceId : folio}
          </Title>
        </Col>
        <Col xs={1} md={3}>
          <Row
            style={{
              marginBottom: 30,
              justifyContent: 'right',
            }}
          >
            {[ROL_SUPPLIER].some((r) => person.roles.includes(r)) && (
              <BackButton routeName={'/facturacion'}></BackButton>
            )}
            {![ROL_SUPPLIER].some((r) => person.roles.includes(r)) && (
              <BackButton
                routeName={
                  type === 'ap'
                    ? '/cxp/facturacion/proveedores'
                    : '/cxc/facturacion/clientes'
                }
              ></BackButton>
            )}
          </Row>
        </Col>
        {/* <Col
          xs={20}
          md={12}
          style={{
            display: 'grid',
            alignItems: 'center',
            justifyContent: 'end',
          }}
        >
          <Row xs={24} md={12} style={{ marginBottom: 10 }}>
            <p xs={24} style={{ marginRight: 20 }}>
              Fecha:
            </p>
            <DatePickerOptions xs={24} filterDate={filterDate} />
          </Row>
        </Col> */}
      </Row>
    </div>
  );
}
