import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Spin,
  Form,
  Button,
  notification,
} from 'antd';
import { getAccessTokenApi } from '../../../../api/auth';
import TextArea from 'antd/lib/input/TextArea';
import { fetchApi } from '../../../../helpers/fetch';
import {  METHOD_PUT} from '../../../../utils/constants';
import { API_URL_QUOTE } from '../../../../helpers/urls';

const AddEditComment = ({
  setModalIsVisible,
  type,
  quoteId,
  setReloadTable,
  comment
}) => {
  const [loadingForm, setLoadingForm] = useState(false);
  const [form] = Form.useForm();
  const token = getAccessTokenApi();

  useEffect(() => {
    if (type !== 'add' && comment) {
      form.setFieldsValue({
        nonUseComment: comment,
      });
    }
  }, [type, comment,form]);

  const editCommentFunction = async (values) => {
    setLoadingForm(true);
    let data = values;
    data['id'] = quoteId;

    let config = {
      method: METHOD_PUT,
      url: `${API_URL_QUOTE}/${quoteId}/NonUseComment`,
      data: data,
      token,
      showNotification: false,
    };
    fetchApi(config)
    .then((response) => {
      setLoadingForm(false);
      if (response) {
        if (response.statusCode === 200 ||response.statusCode === 201 ) {
          setReloadTable(true);
          setModalIsVisible(false);
          notification['success']({
            description: response.message,
          });
        }
        if (response.statusCode === 400 || response.statusCode === 404 || response.statusCode === 500) {
          notification['error']({
            description: response.message,
          });
        }
      }
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {
      setLoadingForm(false);
    });
    
  };
  
  return (
    <Spin spinning={loadingForm } tip="Cargando...">
      <Form
        name="add-promo"
        layout="vertical"
        form={form}
        onFinish={editCommentFunction}
      >
        <div>
            <Form.Item label="Comentarios:" name="nonUseComment">
                <TextArea disabled={type === 'watch'} placeholder="Ingresa tu información" />
            </Form.Item>
        </div>
        {type !== 'watch' && (
            <Row>
            <Col span={24}>
              <Form.Item>
                <Row justify="end">
                  <Button htmlType="submit" className="btn-enviar" block>
                    Guardar
                  </Button>
                </Row>
              </Form.Item>
            </Col>
          </Row>
        )}
      </Form>
    </Spin>
  );
};

export default AddEditComment;
