export const types = {
  getTypeOfLoad: '[catalogs] getTypeOfLoad',
  getOperations: '[catalogs] getOperations',
  getHazMat: '[catalogs] getHazMat',
  getTypeOfService: '[catalogs] getTypeOfService',
  getUnity: '[catalogs] getUnity',
  getEquipmentType: '[catalogs] getEquipmentType',
  getCurrency: '[catalogs] getCurrency',
  getMeasurementUnit: '[catalogs] getMeasurementUnit',
  getPackagingUnit: '[catalogs] getPackagingUnit',
  getFrecuency: '[catalogs] getFrecuency',
  getIncoterm: '[catalogs] getIncoterm',
  getInsurance: '[catalogs] getInsurance',
  getContainer: '[catalogs] getContainer',
  getModality: '[catalogs] getModality',
  getAirport: '[catalogs] getAirport',
  getPort: '[catalogs] getPort',
  getTimeOfMovements: '[catalogs] getTimeOfMovements',
  getRdcyd: '[catalogs] getRdcyd',
  getTdc: '[catalogs] getTdc',
};
