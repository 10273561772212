import React, { useEffect, useState } from 'react';
import { Table, Row, Col, Button, Tag, Input, Space } from 'antd';
import {
  FileExcelFilled,
  FilePdfFilled,
  SearchOutlined,
} from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import './BillingClarifications.scss';
import XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { getFilteredDataFromLocalStorage, saveFilteredDataToLocalStorage } from '../../../../helpers/saveFilterData';

export default function BillingClarifications({
  clarifications,
  reloadClarifications,
  dateRange,
  setReloadClarifications,
}) {
  let history = useHistory();
  const [filteredInfo, setFilteredInfo] = useState({});
  const [clarificationsExport, setClarificationsExport] =
    useState(clarifications);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');

  useEffect(() => {
    setClarificationsExport(clarifications);
    setReloadClarifications(false);
  }, [clarifications]);
  useEffect(() => {
    const datosObtenidos = getFilteredDataFromLocalStorage('billingClarifications');
    if (datosObtenidos !== null) {
    setFilteredInfo(datosObtenidos.filters);
    }
  },[])
  useEffect(() => {
    setFilteredInfo({});
  }, [dateRange]);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            // this.searchInput = node;
          }}
          placeholder={`Buscar`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => {
              handleReset(clearFilters);
              handleSearch(selectedKeys, confirm, dataIndex);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Limpiar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    filteredValue: filteredInfo[dataIndex] || null,
    onFilterDropdownVisibleChange: (visible) => {},
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const columns = [
    {
      title: 'Folio',
      dataIndex: 'id',
      key: 'id',
      ...getColumnSearchProps('id'),
      sorter: (a, b) => a.id - b.id,
      render: (id) => (
        <Button
          // type="primary"
          className="button-detail"
          onClick={() => history.push(`/aclaraciones/detail/${id}`)}
        >
          {id}
        </Button>
      ),
      ellipsis: true,
      width: 150,
    },
    {
      title: 'Fecha',
      dataIndex: 'creationDate',
      key: 'creationDate',
      sorter: (a, b) => moment(a.creationDate) - moment(b.creationDate),
      render: (creationDate) => (
        <>{moment.utc(creationDate).local().format('DD/MM/YYYY')}</>
      ),
      ellipsis: true,
      width: 150,
    },
    {
      title: 'Referencia',
      dataIndex: 'reference',
      key: 'reference',
      sorter: (a, b) => a.reference - b.reference,
      ...getColumnSearchProps('reference'),
      ellipsis: true,
      width: 150,
    },
    {
      title: 'Estatus',
      dataIndex: 'status',
      key: 'status',
      filters: [
        { text: 'ABIERTO', value: true },
        { text: 'CERRADO', value: false },
      ],
      sorter: (a, b) => a.status - b.status,
      filteredValue: filteredInfo.status || null,
      onFilter: (value, record) => record.status == value,
      render: (status) => (
        <Tag color={status ? 'green' : 'red'}>
          {status ? 'ABIERTO' : 'CERRADO'}
        </Tag>
      ),
      // ellipsis: true,
      width: 150,
    },
    {
      title: 'Summary/Titulo de la Aclaración',
      dataIndex: 'title',
      key: 'title',
      ...getColumnSearchProps('title'),
      ellipsis: true,
      width: 280,
    },
  ];

  const handleChange = (pagination, filters, sorter, extra) => {
    saveFilteredDataToLocalStorage('billingClarifications', pagination, sorter, filters);
    setFilteredInfo(filters);
    setClarificationsExport(extra.currentDataSource);
  };

  const generateExcel = (entries) => {
    const filename = `Aclaraciones${moment().format('L')}.xlsx`;

    const ws_name = 'Hoja1';
    let wb = {
      SheetNames: [],
      Sheets: {},
    };
    var mstrTitulo = `Aclaraciones del ${dateRange.startDate} al: ${dateRange.endDate}`;
    let fecha = moment().format('LLL');

    let ws = {
      A1: { v: mstrTitulo, t: 's' },
      F1: { v: fecha, t: 's' },
      A2: { v: 'Folio', t: 's' },
      B2: { v: 'Fecha', t: 's' },
      C2: { v: 'Referencia', t: 's' },
      D2: { v: 'Estatus', t: 's' },
      E2: { v: 'Título', t: 's' },
      F2: { v: 'Descripción', t: 's' },
    };
    let renInicial = 3;
    for (let i in entries) {
      const { id, creationDate, reference, status, title, description } =
        entries[i];
      ws['A' + renInicial] = {
        v: id,
        t: 's',
      };
      ws['B' + renInicial] = {
        v: moment.utc(creationDate).local().format('DD/MM/YYYY'),
        t: 's',
      };
      ws['C' + renInicial] = { v: reference, t: 's' };
      ws['D' + renInicial] = {
        v: status ? 'Abierto' : 'Cerrado',
        t: 's',
      };
      ws['E' + renInicial] = { v: title, t: 's' };
      ws['F' + renInicial] = { v: description, t: 's' };
      renInicial++;
    }
    // var wscols = [{ wch: 30 }, { wch: 40 }, { wch: 35 }, { wch: 35 }];
    // ws['!cols'] = wscols;
    ws['!ref'] = XLSX.utils.encode_range({
      s: { c: 0, r: 0 },
      e: { c: 15, r: renInicial },
    });
    wb.SheetNames.push(ws_name);
    wb.Sheets[ws_name] = ws;
    XLSX.writeFile(wb, filename, { cellStyles: true });
  };

  const exportPdf = (bills, startDate, endDate) => {
    var mstrTitulo = `Aclaraciones del ${dateRange.startDate} al: ${dateRange.endDate}`;
    // var mstrTitulo = `CORTE DE CAJA - Reporte de Ventas del: ${moment
    //     .unix(startDate)
    //     .format('LLL')} al: ${moment.unix(endDate).format('LLL')}`;
    var mstrPdf = `Aclaraciones${moment().format('L')}.pdf`;
    var registros = [];
    // let total_Sell = bills.length;

    bills.forEach((element) => {
      registros.push([
        element.id,
        moment.utc(element.creationDate).local().format('DD/MM/YYYY'),
        element.reference,
        element.status ? 'Abierto' : 'Cerrado',
        element.title,
        element.description,
      ]);
    });

    let fecha = moment().format('LLL');

    let doc = new jsPDF('landscape');
    doc.setFontSize(12);

    let xFecha = 220;
    let yFecha = 10;

    let totalPagesExp = '{total_pages_count_string}';

    let pageContent = function (data) {
      //header
      doc.text(15, 10, mstrTitulo);
      doc.text(-10, -10, mstrTitulo);
      doc.text(fecha, xFecha, yFecha);
      // FOOTER
      let str = 'Página ' + data.pageCount;
      //Total page number plugin only available in jspdf v1.0+
      if (typeof doc.putTotalPages === 'function') {
        str = str + ' de ' + totalPagesExp;
      }
      doc.setFontSize(10);
      doc.text(
        str,
        data.settings.margin.left,
        doc.internal.pageSize.height - 10
      );
    };
    doc.autoTable({
      head: [
        ['Folio', 'Fecha', 'Referencia', 'Estatus', 'Título', 'Descripción'],
      ],
      didDrawPage: pageContent,
      theme: 'grid',
      headStyles: {
        valign: 'middle',
        halign: 'center',
        fillColor: [49, 101, 120],
        textColor: [255, 255, 255],
      },
      body: registros,
      columnStyles: {
        0: { cellWidth: 'auto' },
        1: { cellWidth: 'auto' },
        2: { cellWidth: 'auto' },
        4: { cellWidth: 100 },
      },
    });

    // call footer() after each doc.addPage()

    // and before doc.save() do not forget put
    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(totalPagesExp);
    }
    //spinner.style.display = 'none';
    doc.save(mstrPdf);
  };

  return (
    <>
      <Table
        onChange={handleChange}
        columns={columns}
        dataSource={clarifications}
        scroll={{ x: 500 }}
        pagination={{ defaultPageSize: 5 }}
        loading={reloadClarifications}
      />

      <Row>
        <Col span={24} style={{ textAlign: 'right' }}>
          <Button
            icon={<FilePdfFilled style={{ fontSize: 18 }} />}
            className="btn-download"
            onClick={() => exportPdf(clarificationsExport)}
            disabled={clarificationsExport.length === 0}
            style={{ width: 150 }}
          >
            Descargar Pdf
          </Button>
          <Button
            icon={<FileExcelFilled style={{ fontSize: 18 }} />}
            className="btn-download"
            onClick={() => generateExcel(clarificationsExport)}
            disabled={clarificationsExport.length === 0}
            style={{ width: 160 }}
          >
            Descargar Excel
          </Button>
        </Col>
      </Row>
    </>
  );
}
