import React, { useEffect, useState } from 'react';
import { Row, Col, Spin, Form, Input, Button, notification } from 'antd';

import { editSerieApi, getInvoiceSerieApi } from '../../../../../api/series';
import { getAccessTokenApi, logout } from '../../../../../api/auth';

const EditSerieForm = ({ setModalIsVisible, setReloadSeries, idSerie }) => {
    const [loadingForm, setLoadingForm] = useState(true);
    const [form] = Form.useForm();
    const token = getAccessTokenApi();
    const [editSerieForm, setEditSerieForm] = useState([]);

    useEffect(() => {
        getInvoiceSerieApi(token, idSerie).then((response) => {
            // console.log(response, 'get id serie');
            setEditSerieForm([
                {
                    name: ['serie'],
                    value: response.result.serie,
                },
                {
                    name: ['name'],
                    value: response.result.name,
                },
            ]);

            setLoadingForm(false);
        });
        // return () => {
        //     cleanup
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idSerie]);

    const editSerieFunction = async (values) => {
        setLoadingForm(true);
        let data = values;
        const response = await editSerieApi(token, data, idSerie);
        // console.log(response, 'edit serie');
        if (response === undefined) {
            notification['error']({
                message: 'Ocurrió un error, Inténtelo más tarde',
            });
            return false;
        }
        if (response.statusCode === 401) {
            notification['error']({
                message: 'Usuario no autorizado.',
            });
            setTimeout(() => {
                logout();
                window.location.reload();
            }, 1500);
            return false;
        }
        if (response.statusCode === 200) {
            setModalIsVisible(false);
            setReloadSeries(true);
            notification['success']({
                message: 'Serie editada con éxito',
            });
        } else {
            let messageError = 'Ocurrió un error, Inténtelo otra vez';
            if (response.statusCode === 409) {
                messageError = response.description;
            }
            if (response.status === 400) {
                messageError = response.errors;
            }
            notification['error']({
                message: messageError,
            });
        }
        setLoadingForm(false);
    };
    return (
        <Spin spinning={loadingForm} tip="Guardando...">
            <Form
                name="add-serie"
                layout="vertical"
                form={form}
                onFinish={editSerieFunction}
                fields={editSerieForm}
            >
                <div>
                    <Row xs={24} className="table-company">
                        <Col xs={20}>
                            <Form.Item
                                label="Serie:"
                                name="serie"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Por favor ingrese una serie',
                                    },
                                ]}
                            >
                                <Input placeholder="Ingresa tu información" />
                            </Form.Item>

                            <Form.Item
                                label="Nombre:"
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Por favor ingrese un nombre',
                                    },
                                ]}
                            >
                                <Input placeholder="Ingresa tu información" />
                            </Form.Item>

                            <Row>
                                <Col span={24}>
                                    <Form.Item>
                                        <Button
                                            htmlType="submit"
                                            className="btn-enviar"
                                            block
                                        >
                                            Guardar
                                        </Button>
                                    </Form.Item>

                                    {/* <Button className="btn-cancelar">
                                        Editar
                                    </Button> */}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </Form>
        </Spin>
    );
};

export default EditSerieForm;
