import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Input,
  Modal as ModalAntd,
  Row,
  Space,
  Table,
  Tag,
  Tooltip,
} from 'antd';
import {
  CommentOutlined,
  ReloadOutlined,
  EyeOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  SearchOutlined,
  FileExcelFilled,
  FilePdfFilled,
} from '@ant-design/icons';
import AddQuoteForm from '../Quote/AddQuoteForm';
import Modal from '../../General/Modal';
import { useHistory } from 'react-router';
import {
  METHOD_GET, ROL_ADMIN, ROL_LAYOUT, ROL_OPERACIONES, ROL_VENTAS,
} from '../../../utils/constants';
import { getAccessTokenApi } from '../../../api/auth';
import { fetchApi } from '../../../helpers/fetch';
import { API_URL_QUOTE } from '../../../helpers/urls';
import moment from 'moment';
import Highlighter from 'react-highlight-words';
import { isMobile } from '../../../utils/general';
import XLSX from 'xlsx';
import jsPDF from 'jspdf';
import { getFilteredDataFromLocalStorage, saveFilteredDataToLocalStorage } from '../../../helpers/saveFilterData';
import AddEditComment from './AddEditComment/AddEditComment';
import useUser from '../../../hooks/useUser';

const UnUsedQuotesTable = () => {
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [titleModal, setTitleModal] = useState(false);
  const [contextModal, setContextModal] = useState(null);
  const [quoteSelected, setQuoteSelected] = useState(null);
  // const hasSelected = selectedRowKeys.length > 0;
  const { confirm } = ModalAntd;
  const { person } = useUser();
  const history = useHistory();
  const [isLoadingTable, setIsLoadingTable] = useState(true);
  const token = getAccessTokenApi();
  const [reloadTable, setReloadTable] = useState(false);
  const [quotes, setQuotes] = useState([]);
  const [startDate, setStartDate] = useState({
    createdAt: '',
    startDate: '',
    endDate: '',
  });
  const [endDate, setEndDate] = useState({
    createdAt: '',
    startDate: '',
    endDate: '',
  });
  const x = React.createRef();
  const [searchedColumn, setSearchedColumn] = useState('');
  const [filteredInfo, setFilteredInfo] = useState({});
  const [searchText, setSearchText] = useState('');
  const classButtonDownload = isMobile()
    ? 'btn-download-mobile'
    : 'btn-download';

  useEffect(() => {
    setIsLoadingTable(true);

    const config = {
      method: METHOD_GET,
      url: `${API_URL_QUOTE}/Unused`,
      data: null,
      token,
      showNotificationError: true,
    };
    fetchApi(config)
      .then((response) => {
        if (response.statusCode === 200 && response.result) {
          const allQuotes = response.result;
          allQuotes.map((quote) => (quote['key'] = quote.id));
            setQuotes(allQuotes);
        }
        setIsLoadingTable(false);
        setReloadTable(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoadingTable(false);
        setReloadTable(false);
      });
  }, [reloadTable, token]);
  useEffect(() => {
    const datosObtenidos = getFilteredDataFromLocalStorage('quote');
    if (datosObtenidos !== null) {
      setFilteredInfo(datosObtenidos.filters);
      setStartDate(datosObtenidos.dateFilters.startDates)
      setEndDate(datosObtenidos.dateFilters.endDates)
    }
  },[])
  const getColumnSearchDateProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Col>
          <DatePicker
            ref={x}
            id={'startDate' + dataIndex}
            placeholder={'Fecha inicial'}
            style={{ marginRight: '10px' }}
            format="DD/MM/YYYY"
            allowClear={false}
            value={
              startDate[dataIndex] !== ''
                ? moment.unix(startDate[dataIndex])
                : null
            }
            onChange={(e) => {
              // setStartDate(moment(e).unix());
              startDate[dataIndex] = moment(e).startOf('day').unix();
              setSelectedKeys(
                quotes
                  .filter(
                    (op) =>
                      moment(e)
                        .hours(0)
                        .minutes(0)
                        .seconds(0)
                        .milliseconds(0)
                        .unix() <= op[dataIndex]
                  )
                  .map((op) => op.key)
              );

              // setSelectedKeys(e !== null ? [e.format('DD/MM/YYYY')] : [])
            }}
          />
          <DatePicker
            placeholder={'Fecha final'}
            format="DD/MM/YYYY"
            allowClear={false}
            value={
              endDate[dataIndex] !== '' ? moment.unix(endDate[dataIndex]) : null
            }
            onChange={(e) => {
              // setEndDate(moment(e).unix());
              endDate[dataIndex] = moment(e).endOf('day').unix();
              setSelectedKeys(
                quotes
                  .filter(
                    (op) =>
                      op[dataIndex] <=
                      moment(e)
                        .hours(23)
                        .minutes(59)
                        .seconds(59)
                        .milliseconds(0)
                        .unix()
                  )
                  .map((op) => op.key)
              );
            }}
          />
        </Col>
        <Row justify="end" gutter={16}>
          <Col>
            <Button
            disabled={endDate[dataIndex] === ''}
              type="primary"
              onClick={() => {
                handleSearchDate(selectedKeys, confirm);
                setSelectedKeys(
                  quotes
                    .filter(
                      (d) =>
                        startDate[dataIndex] <= d[dataIndex] &&
                        d[dataIndex] <= endDate[dataIndex]
                    )
                    .map((d) => d.key)
                );
              }}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Buscar
            </Button>
            <Button
              onClick={() => {
                handleReset(clearFilters, selectedKeys, confirm, dataIndex);
                handleSearchDate(selectedKeys, confirm, dataIndex);
              }}
              size="small"
              style={{ width: 90 }}
            >
              Limpiar
            </Button>
          </Col>
        </Row>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      startDate[dataIndex] <= record[dataIndex] &&
      record[dataIndex] <= endDate[dataIndex],
    filteredValue: filteredInfo[dataIndex] || null,
    onFilterDropdownVisibleChange: (visible) => {},
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? moment.unix(text).format('DD/MM/YYYY') : ''}
        />
      ) : (
        moment.unix(text).format('DD/MM/YYYY')
      ),
  });
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            // this.searchInput = node;
          }}
          placeholder={`Buscar`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => {
              handleReset(clearFilters);
              handleSearch(selectedKeys, confirm, dataIndex);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Limpiar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    filteredValue: filteredInfo[dataIndex] || null,
    onFilterDropdownVisibleChange: (visible) => {},
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  const checkQuote = (id) => {
    if (quoteSelected === id) {
      setQuoteSelected(null);
    } else {
      setQuoteSelected(id);
    }
  };
  const showModal = (type, quote) => {
    if (type === 'add') {
      setModalIsVisible(true);
      setTitleModal('Agregar comentario');
      setContextModal(
        <AddEditComment
          setModalIsVisible={setModalIsVisible}
          type={type}
          quoteId={quote.id}
          setReloadTable={setReloadTable}
        ></AddEditComment>
      );
    } else if (type === 'edit') {
      setModalIsVisible(true);
      setTitleModal( `Editar comentario cotización: ${quote.quoteIdStr}` );
      setContextModal(
        <AddEditComment
          setModalIsVisible={setModalIsVisible}
          type={type}
          quoteId={quote.id}
          setReloadTable={setReloadTable}
          comment={quote.nonUseComment}
        ></AddEditComment>
      );
    } else if (type === 'watch') {
      setModalIsVisible(true);
      setTitleModal(`Comentario cotización: ${quote.quoteIdStr}`);
      setContextModal(
        <AddEditComment
          setModalIsVisible={setModalIsVisible}
          type="watch"
          comment={quote.nonUseComment}
        ></AddEditComment>
      );
    }
  };
  const columns = [
    {
      title: '',
      ellipsis: true,
      width: 35,
      dataIndex: 'id',
      key: 'company',
      render: (quote) => (
        <Checkbox
          onChange={() => checkQuote(quote)}
          checked={quoteSelected === quote}
          style={{ padding: 10 }}
          className="button-detail"
        ></Checkbox>
      ),
    },
    {
      title: 'No. / Id. cotización',
      dataIndex: 'quoteIdStr',
      key: 'quoteIdStr',
      width: 80,
      align: 'center',

      render: (id, quote) => (
        <span>{quote.quoteIdStr ? quote.quoteIdStr : id}</span>
      ),
      ...getColumnSearchProps('quoteIdStr'),

    },
    {
      title: 'Cliente',
      dataIndex: 'customerId',
      key: 'customerId',
      width: 130,
      align: 'center',
      ...getColumnSearchProps('customerId'),
    },
    {
      title: 'Estatus',
      dataIndex: 'status',
      key: 'status',
      width: 100,
      align: 'center',
      filters: [
        { text: 'Activa', value: 'active' },
        { text: 'Inactiva', value: 'inactive' },
        { text: 'Pendiente', value: 'pending' },
      ],
      filteredValue: filteredInfo.status || null,
      onFilter: (value, record) => record.status === value,
      render: (status) => (
        <Tag
          color={
            status === 'active'
              ? 'green'
              : status === 'inactive'
              ? 'red'
              : 'orange'
          }
        >
          {status === 'active'
            ? 'Activa'
            : status === 'inactive'
            ? 'Inactiva'
            : 'Pendiente'}
        </Tag>
      ),
    },

    {
      title: 'Fecha creación',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 100,
      align: 'center',
      render: (createdAt) => <>{moment.unix(createdAt).format('DD/MM/YYYY')}</>,
      ...getColumnSearchDateProps('createdAt'),
    },
    {
      title: 'Fecha inicial',
      dataIndex: 'startDate',
      key: 'startDate',
      width: 100,
      align: 'center',
      render: (startDate) => <>{moment.unix(startDate).format('DD/MM/YYYY')}</>,
      ...getColumnSearchDateProps('startDate'),
    },

    {
      title: 'Fecha final',
      dataIndex: 'endDate',
      key: 'endDate',
      width: 100,
      // fixed: 'right',
      align: 'center',
      render: (endDate) => <>{moment.unix(endDate).format('DD/MM/YYYY')}</>,
      ...getColumnSearchDateProps('endDate'),
    },
    {
      title: 'Estatus Completa',
      dataIndex: 'isCompleted',
      key: 'isCompleted',
      width: 80,
      align: 'center',
      filteredValue: filteredInfo.isCompleted || null,
      filters: [
        {
          text: 'Completa',
          value: true,
        },
        { text: 'Incompleta', value: false },
      ],
      onFilter: (value, record) => record.isCompleted === value,
      render: (isCompleted) => (
        <Tag
          icon={isCompleted ? <CheckCircleOutlined /> : <CloseCircleOutlined />}
          color={isCompleted ? 'green' : 'red'}
        >
          {isCompleted ? 'Completa' : 'Incompleta'}
        </Tag>
      ),
    },
    {
      title: 'Líneas',
      dataIndex: 'quoteLines',
      key: 'quoteLines',
      width: 50,
      align: 'center',
      render: (quoteLines, quote) => (
        <>
          <Tooltip title="Líneas">
            <Button
              style={{ marginLeft: 2 }}
              type="primary"
              shape="default"
              icon={<EyeOutlined />}
              onClick={() => history.push(`/lineas/analisis-cotizacion/${quote.id}`)}
            />
          </Tooltip>
        </>
      ),
    },
    {
      title: 'Acciones',
      key: 'quote',
      fixed: 'right',
      width: 70,
      align: 'center',
      render: (quote) => (
        <>
            {[ROL_VENTAS, ROL_OPERACIONES].some((r) => person.roles.includes(r)) && (
            <>
              <Tooltip title="Comentarios">
                <Button
                  disabled={quote.isCompleted}
                  style={{ marginLeft: 2 }}
                  type="primary"
                  shape="default"
                  icon={<CommentOutlined />}
                  onClick={() => showModal( quote.nonUseComment === null ? 'add' :  'edit', quote)}
                />
              </Tooltip>
            </>
            )}
            {[ROL_LAYOUT, ROL_ADMIN].some((r) => person.roles.includes(r)) && (
            <>
              <Tooltip title="Comentarios">
                <Button
                  disabled={quote.nonUseComment === null}
                  style={{ marginLeft: 2 }}
                  type="primary"
                  shape="default"
                  icon={<CommentOutlined />}
                  onClick={() => showModal('watch', quote)}
                />
              </Tooltip>
            </>
            )}
        </>
      ),
    },
  ];
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleSearchDate = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };
  const handleReset = (clearFilters, selectedKeys, confirm, dataIndex) => {
    clearFilters();
    setSearchText('');
    startDate[dataIndex] = '';
    endDate[dataIndex] = '';
  };
  const handleChange = (pagination, filters, sorter, extra) => {
    const dateFilters = { startDates: startDate, endDates: endDate}
    saveFilteredDataToLocalStorage('quote', pagination, sorter, filters, dateFilters);
    setFilteredInfo(filters);
  };
  const exportPdf = (quotes, startDate, endDate) => {
    let mstrTitulo = '';
    let mstrPdf = '';
    mstrTitulo = `Cotizaciones`;
    mstrPdf = `Cotizaciones-${moment().format('L')}.pdf`;

    let registros = [];
    quotes.forEach((element) => {
      registros.push([
        element.id,
        element.customerId,
        element.status === 'active'
          ? 'Activa'
          : element.status === 'inactive'
          ? 'Inactiva'
          : 'Pendiente',
        moment.unix(element.createdAt).format('DD/MM/YYYY'),
        moment.unix(element.startDate).format('DD/MM/YYYY'),
        moment.unix(element.endDate).format('DD/MM/YYYY'),
        element.isCompleted ? 'Completa' : 'Incompleta',
      ]);
    });

    let fecha = moment().format('LLL');
    let doc = new jsPDF('landscape');
    doc.setFontSize(12);

    let xFecha = 220;
    let yFecha = 10;

    let totalPagesExp = '{total_pages_count_string}';

    let pageContent = function (data) {
      //header
      doc.text(15, 10, mstrTitulo);
      doc.text(-10, -10, mstrTitulo);
      doc.text(fecha, xFecha, yFecha);
      // FOOTER
      let str = 'Página ' + data.pageCount;
      //Total page number plugin only available in jspdf v1.0+
      if (typeof doc.putTotalPages === 'function') {
        str = str + ' de ' + totalPagesExp;
      }
      doc.setFontSize(10);
      doc.text(
        str,
        data.settings.margin.left,
        doc.internal.pageSize.height - 10
      );
    };
    doc.autoTable({
      A3: { v: 'No./Id. cotización', t: 's' },
      B3: { v: 'Cliente', t: 's' },
      C3: { v: 'Estatus', t: 's' },
      D3: { v: 'Fecha creación', t: 's' },
      E3: { v: 'Fecha inicial', t: 's' },
      F3: { v: 'Fecha final', t: 's' },
      G3: { v: 'Estatus completa', t: 's' },
      head: [
        [
          'No./Id. cotización',
          'Cliente',
          'Estatus',
          'Fecha creación',
          'Fecha inicial',
          'Fecha final',
          'Estatus completa',
        ],
      ],
      didDrawPage: pageContent,
      theme: 'grid',
      headStyles: {
        valign: 'middle',
        halign: 'center',
        fillColor: [230, 81, 0],
        textColor: [255, 255, 255],
        // cellWidth: { 3: 200 },
      },
      body: registros,
      columnStyles: {
        text: {
          cellWidth: 'wrap',
        },
      },
    });
    // call footer() after each doc.addPage()
    // and before doc.save() do not forget put
    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(totalPagesExp);
    }
    //spinner.style.display = 'none';
    doc.save(mstrPdf);
  };
  const generateExcel = (entries) => {
    // setExcelLoading(true);
    let filename = '';
    let mstrTitulo = '';
    filename = `Cotizaciones-${moment().format('L')}.xlsx`;
    mstrTitulo = `Cotizaciones`;

    let fecha = moment().format('LLL');
    const ws_name = 'Hoja1';
    let wb = {
      SheetNames: [],
      Sheets: {},
    };
    let ws = {};
    let renInicial = 4;

    // var mstrTitulo = `Facturación y notas de crédito del ${dateRange.startDate} al: ${dateRange.endDate}`;

    ws = {
      A1: { v: mstrTitulo, t: 's' },
      I1: { v: fecha, t: 's' },

      A3: { v: 'No./Id. cotización', t: 's' },
      B3: { v: 'Cliente', t: 's' },
      C3: { v: 'Estatus', t: 's' },
      D3: { v: 'Fecha creación', t: 's' },
      E3: { v: 'Fecha inicial', t: 's' },
      F3: { v: 'Fecha final', t: 's' },
      G3: { v: 'Estatus completa', t: 's' },
    };
    for (let i in entries) {
      const {
        id,
        customerId,
        status,
        createdAt,
        startDate,
        endDate,
        isCompleted,
      } = entries[i];
      ws['A' + renInicial] = { v: id, t: 's' };
      ws['B' + renInicial] = { v: customerId, t: 's' };
      ws['C' + renInicial] = {
        v:
          status === 'active'
            ? 'Activa'
            : status === 'inactive'
            ? 'Inactiva'
            : 'Pendiente',
        t: 's',
      };
      ws['D' + renInicial] = {
        v: moment.unix(createdAt).format('DD/MM/YYYY'),
        t: 's',
      };
      ws['E' + renInicial] = {
        v: moment.unix(startDate).format('DD/MM/YYYY'),
        t: 's',
      };
      ws['F' + renInicial] = {
        v: moment.unix(endDate).format('DD/MM/YYYY'),
        t: 's',
      };
      ws['G' + renInicial] = {
        v: isCompleted ? 'Completa' : 'Incompleta',
        t: 's',
      };

      renInicial++;
    }
    // var wscols = [{ wch: 30 }, { wch: 40 }, { wch: 35 }, { wch: 35 }];
    // ws['!cols'] = wscols;
    ws['!ref'] = XLSX.utils.encode_range({
      s: { c: 0, r: 0 },
      e: { c: 15, r: renInicial },
    });
    wb.SheetNames.push(ws_name);
    wb.Sheets[ws_name] = ws;
    XLSX.writeFile(wb, filename, { cellStyles: true });
    // setExcelLoading(false);
  };
  return (
    <div>
          <Button
            className={classButtonDownload}
            icon={<ReloadOutlined />}
            onClick={() => setReloadTable(true)}
          >
            Refrescar
          </Button>
       
      <Table
        dataSource={quotes}
        columns={columns}
        bordered
        size="middle"
        scroll={{ x: 1200 }}
        // setSelectedRowKeys
        className="table-striped-rows"
        // rowSelection={rowSelection}
        loading={isLoadingTable || reloadTable}
        onChange={handleChange}
      />
      <Row>
        <Col span={24} style={{ textAlign: 'right' }}>
          <Button
            icon={<FilePdfFilled style={{ fontSize: 18 }} />}
            className="btn-download"
            onClick={() => exportPdf(quotes)}
            disabled={quotes.length === 0}
            style={{ width: 150 }}
          >
            Descargar Pdf
          </Button>
          <Button
            icon={<FileExcelFilled style={{ fontSize: 18 }} />}
            className="btn-download"
            onClick={() => generateExcel(quotes)}
            disabled={quotes.length === 0}
            style={{ width: 160 }}
          >
            Descargar Excel
          </Button>
        </Col>
      </Row>
      <Modal
        title={titleModal}
        visible={modalIsVisible}
        setIsVisible={setModalIsVisible}
        width={1000}
      >
        {contextModal}
      </Modal>
    </div>
  );
};

export default UnUsedQuotesTable;
