/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  Form,
  Input,
  Col,
  Row,
  Button,
  Select,
  Checkbox,
  Divider,
  Modal as ModalAntd,
  InputNumber,
  Spin,
  Alert,
  Typography,
  Tag,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import {
  UserSwitchOutlined,
  ClearOutlined,
  ExclamationCircleOutlined,
  DollarOutlined,
  UsergroupDeleteOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import {
  ADUANA_VALUE_ID,
  TERRESTRE_VALUE_ID,
  TYPE_OF_LOAD_CATALOG_ID,
  HAZMAT_VALUE_ID,
  SERVICES_CATALOG_ID,
  HAZMAT_CATALOG_ID,
  TYPE_OF_SERVICE_CATALOG_ID,
  UNIT_TYPE_CATALOG_ID,
  MEASUREMENT_UNIT_CATALOG_ID,
  OPERACIONES_CATALOG_ID,
  FRECUENCY_CATALOG_ID,
  PESO_VALUE_ID,
  PIEZAS_VALUE_ID,
  DIMENSIONES_VALUE_ID,
  FLETE_VALUE_ID,
  CURRENCY_CATALOG_ID,
  MODALITY_CATALOG_ID,
  CURRENCY_MXN,
  CURRENCY_USD,
  LOAD_UNLOAD_REQ_CATALOG_ID,
  CREDIT_TIME_CATALOG_ID,
} from '../../../../../../helpers/constants';
import { getCatalogValueReducerHandle } from '../../../../../../helpers/handlerFunctionApi';
import { useReducer } from 'react';
import { fetchApi } from '../../../../../../helpers/fetch';
import { catalogQouteReducer } from '../../../../../../reducers/catalogsQoute/catalogQouteReducer';
import { getAccessTokenApi } from '../../../../../../api/auth';
import { METHOD_GET } from '../../../../../../utils/constants';
import {
  API_URL_CATALOG_SUPPLIER,
  API_URL_CATALOG_VALUES,
} from '../../../../../../helpers/urls';
import { types } from '../../../../../../types/types';
import DimensionsTable from '../../DimensionsTable/DimensionsTable';
import Route from '../Route/Route';
import { formatMoney } from '../../../../../../utils/general';

import './NewLine.scss';

const initialStateQT = {
  typeOfLoad: [],
  operations: [],
  hazMat: [],
  typeOfService: [],
  typeOfEquipment: [],
  unit: [],
  measurementUnit: [],
  currency: [],
  packaging: [],
  frecuency: [],
  modality: [],
  rdcyd: [],
  tdc: [],
};

const { Text } = Typography;

const NewLine = ({
  disableAllInputs,
  showModal,
  newLineForm,
  pieces,
  setPieces,
  saveKey,
  onChangeFilterSupplier,
  supplierSelected,
  setFilterSupplier,
  showAllValuesNL,
  setShowAllValuesNL,
  rateSelected,
  setRateSelected,
  clearSupplier,
  clearIsRFQ,
  setClearIsRFQ,
  handleSaveLine,
  linesData,
  routes,
  clientSelectedQuote,
  allRoutes,
  setRouteSelected,
  routeSelected,
}) => {
  const [service, setService] = useState(TERRESTRE_VALUE_ID);
  const [serviceCatalag, setServiceCatalag] = useState([]);
  const [rfqChecked, setRfqChecked] = useState(false);
  const [ivaChecked, setIvaChecked] = useState(false);
  const [loadingForm, setLoadingForm] = useState(false);
  const [catalogs, dispatch] = useReducer(catalogQouteReducer, initialStateQT);
  const [typeOfload, setTypeOfload] = useState(0);
  const token = getAccessTokenApi();
  const [loadingCatalog, setLoadingCatalog] = useState(false);
  const [textType, setTextType] = useState('');
  const [textDescription, setTextDescription] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [unitCost, setUnitCost] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  const [totalSale, setTotalSale] = useState(0);
  const [totalProfit, setTotalProfit] = useState(0);
  let [unitPrice, setUnitPrice] = useState(0);
  const [piecesTable, setPiecesTable] = useState({
    totalWeight: 0,
    totalCBM: 0,
    totalPieces: 0,
  });
  const [showAlertProfit, setShowAlertProfit] = useState(false);
  const [disableRate, setDisabledRate] = useState(
    rateSelected === null ? false : true
  );
  const { confirm } = ModalAntd;
  const [currencyValue, setCurrencyValue] = useState(null);
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const { Option } = Select;

  const catalogCurrency = [
    {value: CURRENCY_MXN , label: 'MXN'},
    {value: CURRENCY_USD , label: 'USD'}]
  const errorFields = ({ values, errorFields, outOfDate }) => {
    if (
      !values.unitType ||
      !values.destinationAddress ||
      !values.destinationCity ||
      !values.destinationCountry ||
      !values.destinationState ||
      !values.destinationZipCode ||
      !values.fullPrice ||
      !values.lineNumber ||
      !values.loadType ||
      !values.measurementUnit ||
      !values.originAddress ||
      !values.originCity ||
      !values.originCountry ||
      !values.originState ||
      !values.originZipCode ||
      !values.rateIdStr ||
      !values.rateId ||
      !values.sale ||
      !values.service ||
      !values.typeOfService ||
      !values.unitPrice ||
      !values.unitType
    ) {
      setTextType('Datos incompletos');
      setTextDescription('Complete todos los datos de la línea.');
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }
  };
  useEffect(() => {
    let countWeight = 0;
    let countCbm = 0;
    let countPieces = 0;
    pieces?.forEach((element) => {
      countWeight += element.totalWeight;
      countCbm += element.totalCBM;
      countPieces += element.piecesNumber;
    });
    newLineForm.setFieldsValue({
      totalWeight: countWeight,
      totalCBM: countCbm,
      totalPieces: countPieces,
    });
    setPiecesTable({
      totalWeight: countWeight,
      totalCBM: countCbm,
      totalPieces: countPieces,
    });
  }, [pieces]);
  useEffect(() => {
    setUnitPrice(0);
    newLineForm.setFieldsValue({
      unitPrice: null,
    });
    if (rateSelected) {
    setSelectedCurrency(rateSelected.currency)
    }
  }, [rateSelected]);

  useEffect(() => {
    //  console.log(linesData, 'linesdata');
    let cont = linesData.length + 1;
    newLineForm.setFieldsValue({
      name: ['lineNumber'],
      value: cont,
    });
  }, [linesData, newLineForm]);

  useEffect(() => {
    setLoadingForm(true);
    const config = {
      method: METHOD_GET,
      url: `${API_URL_CATALOG_VALUES}/GetAll/${SERVICES_CATALOG_ID}`,
      data: null,
      token,
      // showNotification: true
    };
    fetchApi(config)
      .then((response) => {
        if (response) {
          let calogsValues = response.result;

          if (calogsValues) {
            calogsValues.map((cat, index) => {
              cat['key'] = index;
              cat['label'] = cat.description;
              cat['value'] = cat.id;
              return cat;
            });
          }
          setServiceCatalag(calogsValues);
        }
        setLoadingForm(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [token]);

  useEffect(() => {
    getCatalogValueReducerHandle(
      TYPE_OF_LOAD_CATALOG_ID,
      dispatch,
      setLoadingForm,
      0,
      types.getTypeOfLoad
    );
    getCatalogValueReducerHandle(
      HAZMAT_CATALOG_ID,
      dispatch,
      setLoadingForm,
      0,
      types.getHazMat
    );
    getCatalogValueReducerHandle(
      TYPE_OF_SERVICE_CATALOG_ID,
      dispatch,
      setLoadingForm,
      0,
      types.getTypeOfService
    );
    getCatalogValueReducerHandle(
      UNIT_TYPE_CATALOG_ID,
      dispatch,
      setLoadingForm,
      0,
      types.getUnity
    );
    getCatalogValueReducerHandle(
      MEASUREMENT_UNIT_CATALOG_ID,
      dispatch,
      setLoadingForm,
      0,
      types.getMeasurementUnit
    );
    getCatalogValueReducerHandle(
      FRECUENCY_CATALOG_ID,
      dispatch,
      setLoadingForm,
      0,
      types.getFrecuency
    );
    getCatalogValueReducerHandle(
      CURRENCY_CATALOG_ID,
      dispatch,
      setLoadingForm,
      0,
      types.getCurrency
    );
    getCatalogValueReducerHandle(
      MODALITY_CATALOG_ID,
      dispatch,
      setLoadingForm,
      0,
      types.getModality
    );
    getCatalogValueReducerHandle(
      LOAD_UNLOAD_REQ_CATALOG_ID,
      dispatch,
      setLoadingForm,
      0,
      types.getRdcyd,
      'RDCYD'
    );
    getCatalogValueReducerHandle(
      CREDIT_TIME_CATALOG_ID,
      dispatch,
      setLoadingForm,
      0,
      types.getTdc,
      'TDC'
    );
  }, []);

  useEffect(() => {
    if (showAllValuesNL) {
      setLoadingForm(true);
      const config = {
        method: METHOD_GET,
        url: `${API_URL_CATALOG_VALUES}/GetAll/${SERVICES_CATALOG_ID}`,
        data: null,
        token,
        // showNotification: true
      };
      fetchApi(config)
        .then((response) => {
          if (response) {
            let calogsValues = response.result;

            if (calogsValues) {
              calogsValues.map((cat, index) => {
                cat['key'] = index;
                cat['label'] = cat.description;
                cat['value'] = cat.id;
                return cat;
              });
            }
            setServiceCatalag(calogsValues);
          }
          setLoadingForm(false);
        })
        .catch((err) => {
          console.log(err);
        });

      setRfqChecked(false);
    }
  }, [showAllValuesNL, token]);

  useEffect(() => {
    if (showAllValuesNL) {
      getCatalogValueReducerHandle(
        TYPE_OF_LOAD_CATALOG_ID,
        dispatch,
        setLoadingForm,
        0,
        types.getTypeOfLoad
      );
      getCatalogValueReducerHandle(
        OPERACIONES_CATALOG_ID,
        dispatch,
        setLoadingForm,
        0,
        types.getOperations
      );
      getCatalogValueReducerHandle(
        HAZMAT_CATALOG_ID,
        dispatch,
        setLoadingForm,
        0,
        types.getHazMat
      );
      getCatalogValueReducerHandle(
        TYPE_OF_SERVICE_CATALOG_ID,
        dispatch,
        setLoadingForm,
        0,
        types.getTypeOfService
      );
      getCatalogValueReducerHandle(
        UNIT_TYPE_CATALOG_ID,
        dispatch,
        setLoadingForm,
        0,
        types.getUnity
      );
      getCatalogValueReducerHandle(
        UNIT_TYPE_CATALOG_ID,
        dispatch,
        setLoadingForm,
        0,
        types.getEquipmentType
      );
      getCatalogValueReducerHandle(
        MEASUREMENT_UNIT_CATALOG_ID,
        dispatch,
        setLoadingForm,
        0,
        types.getMeasurementUnit
      );
      getCatalogValueReducerHandle(
        CURRENCY_CATALOG_ID,
        dispatch,
        setLoadingForm,
        0,
        types.getCurrency
      );
      // getCatalogValueReducerHandle(
      //    PACKAGING_UNIT_CATALOG_ID,
      //    dispatch,
      //    setLoadingForm,
      //    0,
      //    types.getPackagingUnit
      // );
    }
  }, [showAllValuesNL]);

  useEffect(() => {
    if (clearIsRFQ === true) {
      setRfqChecked(false);
      setClearIsRFQ(false);
    }
  }, [clearIsRFQ]);

  function updateReducerCatalog(arrayCatalog, type) {
    if (arrayCatalog) {
      arrayCatalog.map((cat, index) => {
        cat['key'] = index;
        cat['label'] = cat.description;
        cat['value'] = cat.id;
        return cat;
      });
      if (type === 'service') {
        setServiceCatalag(arrayCatalog);
      } else {
        dispatch({ type: type, payload: arrayCatalog });
      }
    }
  }

  useEffect(() => {
    let ignore = false;
    const getCatalogsSupplier = () => {
      setLoadingCatalog(true);
      const config = {
        method: METHOD_GET,
        url: `${API_URL_CATALOG_SUPPLIER}/${supplierSelected.supplierId}`,
        data: null,
        token,
      };
      fetchApi(config)
        .then((response) => {
          if (response) {
            if (response.statusCode === 200) {
              const catalogSupplierResult = response.result;

              const loadTypeArray = catalogSupplierResult.loadType;
              const hazMatArray = catalogSupplierResult.hazMat;
              const operationsArray = catalogSupplierResult.operations;
              const typeOfServicesArray = catalogSupplierResult.typeOfServices;
              const unitTypeArray = catalogSupplierResult.unitType;
              const servicesArray = catalogSupplierResult.services;

              //Update catalog
              if (!ignore) {
                updateReducerCatalog(loadTypeArray, types.getTypeOfLoad);

                updateReducerCatalog(hazMatArray, types.getHazMat);
                updateReducerCatalog(operationsArray, types.getOperations);
                updateReducerCatalog(
                  typeOfServicesArray,
                  types.getTypeOfService
                );
                updateReducerCatalog(unitTypeArray, types.getUnity);
                updateReducerCatalog(unitTypeArray, types.getEquipmentType);

                updateReducerCatalog(servicesArray, 'service');
              }
            }
            if (!ignore) setLoadingCatalog(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    if (supplierSelected) {
      setShowAllValuesNL(false);
      getCatalogsSupplier();
    }
    return () => {
      ignore = true;
    };
  }, [supplierSelected, token]);

  
  useEffect(() => {
    // getRatesSupplier();
    if (supplierSelected) {
      newLineForm.setFieldsValue({
        supplierId: supplierSelected.supplierId,
        supplierName: supplierSelected.name,
      });

      if (rateSelected?.supplier.supplierId != supplierSelected.supplierId) {
        newLineForm.setFieldsValue({
          rateId: null,
          rateIdStr: null,
          measurementUnit: null,
          rateCurrency: null,
        });
        setDisabledRate(false);
      }
    } else {
      newLineForm.setFieldsValue({
        supplierId: null,
        contactId: null,
      });
      setUnitCost(0);
    }
  }, [supplierSelected, rateSelected]);

  useEffect(() => {
    if (rateSelected) {
      // newLineForm.setFieldsValue({
      //   rateIdStr: rateSelected.rateId,
      //   rateId: rateSelected.id,
      //   measurementUnit: rateSelected.measurementUnit,
      // });
      let costo =
        service === TERRESTRE_VALUE_ID
          ? rateSelected.unitPrice
          : rateSelected.cost;
      setUnitCost(costo);

      if (currencyValue !== null && selectedCurrency === CURRENCY_USD) {
         unitPrice = unitPrice * currencyValue;
      }else if(currencyValue !== null && selectedCurrency === CURRENCY_MXN){
        unitPrice = unitPrice / currencyValue;
      }
      let totalCostLine = 0;
      let totalSaleLine = 0;
      let profiltLine = 0;

      if (rateSelected.measurementUnit === PESO_VALUE_ID) {
        totalCostLine = costo * piecesTable.totalWeight;
        totalSaleLine = unitPrice * piecesTable.totalWeight;
      }
      if (rateSelected.measurementUnit === PIEZAS_VALUE_ID) {
        totalCostLine = costo * piecesTable.totalPieces;
        totalSaleLine = unitPrice * piecesTable.totalPieces;
      }
      if (rateSelected.measurementUnit === DIMENSIONES_VALUE_ID) {
        totalCostLine = costo * piecesTable.totalCBM;
        totalSaleLine = unitPrice * piecesTable.totalCBM;
      }
      if (rateSelected.measurementUnit === FLETE_VALUE_ID) {
        totalCostLine = costo;
        totalSaleLine = unitPrice;
      }
      profiltLine = totalSaleLine - totalCostLine;

      if (currencyValue !== null && selectedCurrency === CURRENCY_USD) {
        totalCostLine = totalCostLine / currencyValue ;
        totalSaleLine = totalSaleLine / currencyValue ;
        profiltLine = profiltLine / currencyValue ;
      }else if (currencyValue !== null && selectedCurrency === CURRENCY_MXN) {
        totalCostLine = totalCostLine * currencyValue ;
        totalSaleLine = totalSaleLine * currencyValue ;
        profiltLine = profiltLine * currencyValue ;
      }
      setTotalCost(totalCostLine);
      setTotalSale(totalSaleLine);
      setTotalProfit(profiltLine);

      if (profiltLine < 0) {
        setShowAlertProfit(true);
      } else {
        setShowAlertProfit(false);
      }
      if (selectedCurrency === rateSelected.currency) {
        newLineForm.setFieldsValue({
          exchangerate: 0,
        });
      }
      newLineForm.setFieldsValue({
        rateIdStr: rateSelected.rateId,
        rateId: rateSelected.id,
        measurementUnit: rateSelected.measurementUnit,
        rateCurrency: rateSelected.currency,
        fullPrice: parseFloat(totalCostLine.toFixed(2)),
        sale: parseFloat(totalSaleLine.toFixed(2)),
        rateUnitPrice: costo,
        currency: selectedCurrency !== null ? selectedCurrency :rateSelected.currency,
      });
    } else {
      newLineForm.setFieldsValue({
        rateId: null,
        rateIdStr: null,
        measurementUnit: null,
        rateCurrency: null,
      });
    }
  }, [rateSelected, piecesTable, unitPrice,currencyValue, selectedCurrency]);

  function clearFilterRate() {
    setFilterSupplier({});
    const rateEmpty = {
      loadType: null,
      hazMat: null,
      HhzMatDescription: null,
      service: null,
      typeOfService: null,
      unitType: null,
      typeOfLoad: null,
      supplierId: null,
      rateId: null,
      rateIdStr: null,
      originAddress: null,
      destinationAddress: null,
      originZipCode: null,
      destinationZipCode: null,
      supplierName:null,

    };
    newLineForm.setFieldsValue(rateEmpty);
    setShowAllValuesNL(true);
    setUnitCost(0);
    clearSupplier();
    setTypeOfload(0);
    setRateSelected(null)
  }

  function clearSupplierLine() {
    setFilterSupplier((prevState) => ({
      ...prevState,
      supplierId: null,
      rateId: null,
      rateIdStr: null,
    }));
    // setFilterSupplier({
    //   supplierId: null,
    //   rateId: null,
    //   rateIdStr: null,
    // });
    const rateEmpty = {
      supplierId: null,
      rateId: null,
      rateIdStr: null,
      originAddress: null,
      destinationAddress: null,
      originZipCode: null,
      destinationZipCode: null,
    };
    newLineForm.setFieldsValue(rateEmpty);
    clearSupplier();
    setRateSelected(null);
  }

  function showConfirm(type) {
    let title = '';
    switch (type) {
      case 'all':
        title = 'Estás seguro que deseas limpiar las opciones';
        break;

      case 'supplier':
        title = 'Estás seguro que deseas limpiar el proveedor seleccionado';
        break;

      default:
        break;
    }
    confirm({
      title: title,
      icon: <ExclamationCircleOutlined />,
      content: 'No podrá deshacer esta opción',
      okText: 'Si, limpiar',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk() {
        if (type === 'supplier') {
          clearSupplierLine();
        } else {
          clearFilterRate();
          setDisabledRate(false);
        }
      },
    });
  }
  useEffect(() => {
    if (rateSelected !== null) {
      setDisabledRate(true);
    } else {
      setDisabledRate(false);
    }
  }, [rateSelected]);

  // (currencyValue !== null && selectedCurrency === CURRENCY_MXN)
  return (
    <Form
      name="add-newline"
      layout="vertical"
      form={newLineForm}
      onFinish={handleSaveLine}
      onFinishFailed={errorFields}
      className="new-line-form"

      // scrollToFirstError
    >
      <>
        <Spin spinning={loadingCatalog || loadingForm}>
          <Row>
            <Col md={24}>
              {/* ROW4 */}
              <Row>
                <Col>
                </Col>
                {service !== ADUANA_VALUE_ID && (
                  <Col xs={4} md={4}>
                    <Form.Item
                      name="hasRFQ"
                      valuePropName="checked"
                      initialValue={false}
                    >
                      <Checkbox
                        onChange={(e) => setRfqChecked(e.target.checked)}
                        disabled={disableAllInputs}
                        checked={rfqChecked}
                      >
                        RFQ
                      </Checkbox>
                    </Form.Item>
                  </Col>
                )}
                <Col xs={24} md={10} lg={10}>
                  <Button
                    style={{ marginLeft: 0, width: 150 }}
                    className="btn-download"
                    block
                    size="small"
                    icon={<ClearOutlined />}
                    onClick={() => showConfirm('all')}
                    // disabled={rateApi?.isCompleted}
                  >
                    Limpiar opciones
                  </Button>
                </Col>
                <Col xs={24} md={10} lg={10}>
                  <Button
                    style={{ marginLeft: 0, width: 160 }}
                    className="btn-download"
                    block
                    size="small"
                    icon={<UsergroupDeleteOutlined />}
                    onClick={() => showConfirm('supplier')}
                  >
                    Limpiar proveedor
                  </Button>
                </Col>
              </Row>
              {/* ROW5 */}
              <Row gutter={16}>
                <Col xxl={6} xl={8} lg={12} md={12} xs={24}>
                  <Form.Item
                    label="Tipo de carga:"
                    name="loadType"
                    rules={[
                      {
                        required: true,
                        message: 'Por favor ingrese tipo de carga',
                      },
                    ]}
                  >
                    <Select
                      placeholder="Selecciona una opción"
                      // onChange={(value) => setTypeOfload(value)}
                      onChange={(value) => {
                        setRateSelected(null);
                        setUnitCost(0)
                        setTotalCost(0)
                        setTotalSale(0)
                        setTotalProfit(0)
                        setTypeOfload(value);
                        onChangeFilterSupplier(value, 'typeOfLoad');
                      }}
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {catalogs.typeOfLoad.map((s) => (
                        <Select.Option key={s.value} value={s.value}>
                          {s.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col
                  xxl={6}
                  xl={typeOfload === HAZMAT_VALUE_ID ? 6 : 8}
                  lg={12}
                  md={12}
                  xs={24}
                >
                  <Form.Item
                    label="Frecuencia:"
                    name="frecuency"
                    rules={[
                      {
                        required: false,
                        message: 'Por favor ingrese frecuencia',
                      },
                    ]}
                  >
                    <Select
                      placeholder="Selecciona una opción"
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {catalogs.frecuency.map((s) => (
                        <Select.Option key={s.value} value={s.value}>
                          {s.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                {typeOfload === HAZMAT_VALUE_ID && (
                  <Col xl={10} lg={12} md={12} xs={24}>
                    <Row gutter={16}>
                      <Col xs={24} md={16}>
                        <Form.Item
                          label="HAZ MAT:"
                          name="hazMat"
                          rules={[
                            {
                              required: typeOfload === HAZMAT_VALUE_ID,
                              message: 'Por favor ingrese HAZ MAT',
                            },
                          ]}
                        >
                          <Select
                            disabled={typeOfload !== HAZMAT_VALUE_ID}
                            placeholder=""
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={(value) => {
                              onChangeFilterSupplier(value, 'hazMat');
                            }}
                          >
                            {catalogs.hazMat.map((s) => (
                              <Select.Option key={s.value} value={s.value}>
                                {s.label}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={8}>
                        <Form.Item
                          name="hazMatDescription"
                          label="Descripción:"
                          rules={[
                            {
                              required: typeOfload === HAZMAT_VALUE_ID,
                              message: 'Por favor ingrese HAZ MAT',
                            },
                          ]}
                        >
                          <Input disabled={typeOfload !== HAZMAT_VALUE_ID} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                )}
              </Row>
              {/* ROW6 */}
              <Row gutter={16}>
                <Col md={6} xs={24}>
                  <Form.Item
                    label="Servicio:"
                    name="service"
                    rules={[
                      {
                        required: true,
                        message: 'Por favor ingrese servicio',
                      },
                    ]}
                  >
                    <Select
                      disabled={disableAllInputs}
                      placeholder="Selecciona una opción"
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(value) => {
                        setRateSelected(null);
                        setUnitCost(0)
                        setTotalCost(0)
                        setTotalSale(0)
                        setTotalProfit(0)
                        setService(value);
                        onChangeFilterSupplier(value, 'service');
                      }}
                      // onChange={(value) => setService(value)}
                    >
                      {serviceCatalag.map((s) => (
                        <Select.Option key={s.value} value={s.value}>
                          {s.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                {service !== ADUANA_VALUE_ID && (
                  <Col md={6} xs={24}>
                    <Form.Item
                      label="Tipo de servicio:"
                      name="typeOfService"
                      rules={[
                        {
                          required: true,
                          message: 'Por favor ingrese un tipo de servicio',
                        },
                      ]}
                    >
                      <Select
                        // disabled={disableAllInputs}
                        placeholder="Selecciona una opción"
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(value) => {
                          setRateSelected(null);
                          setUnitCost(0)
                          setTotalCost(0)
                          setTotalSale(0)
                          setTotalProfit(0)
                          onChangeFilterSupplier(value, 'typeOfService');
                        }}
                      >
                        {catalogs.typeOfService.map((s) => (
                          <Select.Option key={s.value} value={s.value}>
                            {s.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                )}
                <Col md={6} xs={24}>
                  <Form.Item
                    label="Unidad:"
                    name="unitType"
                    rules={[
                      {
                        required: true,
                        message: 'Por favor ingrese unidad',
                      },
                    ]}
                  >
                    <Select
                      disabled={disableAllInputs}
                      placeholder="Selecciona una opción"
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(value) => {
                        setRateSelected(null);
                        setUnitCost(0)
                        setTotalCost(0)
                        setTotalSale(0)
                        setTotalProfit(0)
                        onChangeFilterSupplier(value, 'unit');
                      }}
                    >
                      {catalogs.unit.map((s) => (
                        <Select.Option key={s.value} value={s.value}>
                          {s.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                {service === ADUANA_VALUE_ID && (
                  <Col md={8} xs={24}>
                    <Row>
                      <Form.Item
                        label="Aduana:"
                        name="type"
                        rules={[
                          {
                            required: true,
                            message: 'Por favor ingrese aduana',
                          },
                        ]}
                      >
                        <Select
                          placeholder="Selecciona una opción"
                          disabled={disableAllInputs}
                        />
                      </Form.Item>
                    </Row>
                  </Col>
                )}
                <Col xs={24} md={6}>
                  <Form.Item label="Modalidad:" name="modalityId">
                    <Select
                      disabled={disableAllInputs || !rfqChecked}
                      placeholder="Selecciona una opción"
                      showSearch
                      optionFilterProp="children"
                      onChange={(value) => {
                        onChangeFilterSupplier(value, 'modality');
                      }}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {catalogs.modality.map((s) => (
                        <Select.Option key={s.value} value={s.value}>
                          {s.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                {/* //nuevos catalogos */}
                <Col xs={24} md={6}>
                    <Form.Item label="Requisito de carga:" name="loadUnloadRequirementIds">
                      <Select
                        disabled={disableAllInputs}
                        placeholder="Selecciona"
                        showSearch
                        allowClear
                        mode='multiple'
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {catalogs.rdcyd.map((s) => (
                          <Select.Option key={s.value} value={s.value}>
                            {s.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                      <Form.Item
                        label="Tiempo de carga/descarga:"
                        name="loadUnloadTime"
                        tooltip={{
                          title:
                            'Tiempo en horas.',
                          icon: <InfoCircleOutlined />,
                        }}
                      >
                        <InputNumber
                          disabled={disableAllInputs}
                          placeholder="Tiempo en horas"
                          style={{ width: '100%' }}
                          min={0}
                          formatter={(value) =>
                            ` ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                          }
                          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        />
                      </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                      <Form.Item
                        label="Volumen de carga:"
                        name="cargoVolume"
                      >
                        <InputNumber
                         disabled={disableAllInputs}
                         min={0}
                         style={{ width: '100%' }}
                          formatter={(value) =>
                            ` ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                          }
                          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        />
                      </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                    <Form.Item label="Tiempo de crédito:" name="creditTimeId">
                      <Select
                        disabled={disableAllInputs}
                        placeholder="Selecciona una opción"
                        showSearch
                        allowClear
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {catalogs.tdc.map((s) => (
                          <Select.Option key={s.value} value={s.value}>
                            {s.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                    <Form.Item label="Tipo de evidencia:" name="evidence">
                    <Select
                    allowClear
                    style={{ width: '100%', marginBottom: 20 }}
                    placeholder="Seleccione una opción"
                  >
                    <Option value={0}>
                      Físico
                    </Option>
                    <Option value={1}>
                      Virtual
                    </Option>
                    <Option value={2}>
                      Ambas
                    </Option>
                  </Select>
                    </Form.Item>
                </Col>
              </Row>
              {service === ADUANA_VALUE_ID && (
                <Row gutter={16}>
                  <Col md={8} xs={24}>
                    <Form.Item
                      label="Patente:"
                      name="patente"
                      rules={[
                        {
                          required: true,
                          message: 'Por favor ingrese patente',
                        },
                      ]}
                    >
                      <Select disabled={disableAllInputs}></Select>
                    </Form.Item>
                  </Col>
                  <Col md={8} xs={24}>
                    <Row>
                      <Form.Item
                        label="Agente aduanal:"
                        name="type"
                        rules={[
                          {
                            required: true,
                            message: 'Por favor ingrese agente aduanal',
                          },
                        ]}
                      >
                        <Input
                          placeholder="Ingrese información"
                          disabled={disableAllInputs}
                        />
                      </Form.Item>
                    </Row>
                  </Col>
                  <Col md={8} xs={24}>
                    <Form.Item
                      label="Pedimento:"
                      name="unit"
                      rules={[
                        {
                          required: true,
                          message: 'Por favor ingrese pedimento',
                        },
                      ]}
                    >
                      <Select
                        placeholder="Selecciona una opción"
                        disabled={disableAllInputs}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              )}
              {/* RUTA */}
              <Route
                disableAllInputs={disableAllInputs}
                service={service}
                routes={routes}
                isRFQ={rfqChecked}
                setLoadingForm={setLoadingForm}
                clientSelectedQuote={clientSelectedQuote}
                form={newLineForm}
                allRoutes={allRoutes}
                setRouteSelected={setRouteSelected}
                setRateSelected={setRateSelected}
              />
             
              {service !== ADUANA_VALUE_ID && rfqChecked === false && (
                <>
                  <Divider>Proveedor</Divider>
                  <Row gutter={16}>
                    <Col md={3} xs={24}>
                      <Button
                        style={{
                          marginBottom: 5,
                        }}
                        type="primary"
                        icon={<UserSwitchOutlined />}
                        disabled={disableAllInputs || !clientSelectedQuote}
                        onClick={() => showModal('Suppliers')}
                      >
                        Proveedor
                      </Button>
                    </Col>
                    <Form.Item name="supplierId" hidden>
                      <Input placeholder="Ingrese información" disabled />
                    </Form.Item>
                    <Col md={9} xs={24}>
                      <Form.Item
                        name="supplierName"
                        rules={[
                          {
                            required: !rfqChecked,
                            message: 'Por favor ingrese proveedor',
                          },
                        ]}
                      >
                        <Input placeholder="Ingrese información" disabled />
                      </Form.Item>
                    </Col>

                    <Col md={3} xs={24}>
                      <Button
                        style={{
                          marginBottom: 5,
                        }}
                        type="primary"
                        disabled={
                          disableAllInputs ||
                          disableRate ||
                          !clientSelectedQuote ||
                          !routeSelected ||
                          !supplierSelected
                        }
                        icon={<DollarOutlined />}
                        onClick={() => showModal('Rate')}
                      >
                        Tarifa
                      </Button>
                    </Col>
                    <Col md={9} xs={24}>
                      <Form.Item
                        name="rateIdStr"
                        rules={[
                          {
                            required: !rfqChecked,
                            message: 'Por favor ingrese tarifa',
                          },
                        ]}
                      >
                        <Input placeholder="Ingrese información" disabled />
                      </Form.Item>
                      <Form.Item
                        hidden
                        name="rateId"
                        rules={[
                          {
                            required: !rfqChecked,
                            message: 'Por favor ingrese tarifa',
                          },
                        ]}
                      >
                        <Input placeholder="Ingrese información" disabled />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              )}
              <Divider>Peso y dimensiones</Divider>

              <DimensionsTable
                disableAllInputs={disableAllInputs}
                pieces={pieces}
                setPieces={setPieces}
                linesData={[]}
                setLinesData={[]}
                saveKey={saveKey}
              ></DimensionsTable>

              <Row gutter={16} style={{ marginTop: 20 }}>
                <Col md={8} xs={24}>
                  <Form.Item label="Piezas totales:" name="totalPieces">
                    <InputNumber style={{ width: '100%' }} disabled={true} />
                  </Form.Item>
                </Col>
                <Col md={8} xs={24}>
                  <Form.Item label="Peso total:" name="totalWeight">
                    <InputNumber style={{ width: '100%' }} disabled={true} />
                  </Form.Item>
                </Col>
                <Col md={8} xs={24}>
                  <Form.Item label="CBM total:" name="totalCBM">
                    <InputNumber style={{ width: '100%' }} disabled={true} />
                  </Form.Item>
                </Col>
              </Row>
              {!rfqChecked && (
                <Row gutter={16} justify="center">
                  <Divider>Información de la tarifa</Divider>
                  <Col xs={12} md={8}>
                    <Form.Item
                      label="Unidad de medida:"
                      name="measurementUnit"
                      rules={[
                        {
                          required: !rfqChecked,
                          message: 'Por favor ingrese unidad de medida',
                        },
                      ]}
                    >
                      <Select
                        disabled
                        placeholder="unidad de medida"
                        // style={{ width: 150 }}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {catalogs.measurementUnit.map((s) => (
                          <Select.Option key={s.value} value={s.value}>
                            {s.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={12} md={8}>
                    <Form.Item
                      label="Moneda tarifa:"
                      name="rateCurrency"
                      rules={[
                        {
                          required: !rfqChecked,
                          message: 'Por favor ingrese una moneda',
                        },
                      ]}
                    >
                      <Select
                        disabled
                        placeholder="Moneda"
                        // style={{ width: 150 }}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {catalogs.currency.map((s) => (
                          <Select.Option key={s.value} value={s.value}>
                            {s.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={8}>
                    <p style={{ marginBottom: 7 }}>Costo unitario:</p>
                    <Tag style={{ padding: 5, width: '100%' }}>
                      <Text className="text-label" style={{ color: 'gray' }}>
                        ${formatMoney(unitCost)} 
                      </Text>
                    </Tag>
                    <Form.Item
                      label="rateUnitPrice"
                      name="rateUnitPrice"
                      hidden
                      rules={[
                        {
                          required: false,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
              )}
             
              <Divider></Divider>
              {rateSelected && !rfqChecked && (
                <Row gutter={16}>
                <Col xs={12} md={8}>
                    <Form.Item
                     label="Moneda cotización:"
                     name="currency"
                     rules={[
                       {
                         required: true,
                         message: 'Por favor ingrese una moneda',
                       },
                     ]}
                    >
                      <Select
                        placeholder="Moneda"
                        disabled={disableAllInputs}
                        // style={{ width: 150 }}
                        onChange={(value) => setSelectedCurrency(value)}
                        allowClear
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        options={catalogCurrency.map((c) => ({
                          label: c.label,
                          value: c.value,
                          // disabled: c.value === rateSelected?.currency? true : false,
                        }))}
                      >
                      </Select>
                    </Form.Item>
                  </Col>
                  {(selectedCurrency && rateSelected !== null && selectedCurrency !== rateSelected.currency) && (
                    <Col xs={24} md={8} lg={6}>
                    <Form.Item
                      label="Cambio de moneda:"
                      name="exchangerate"
                    >
                      <InputNumber
                        disabled={disableAllInputs}
                        placeholder="Ingresa un costo"
                        style={{ width: '100%' }}
                        min={0}
                        formatter={(value) =>
                          `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        }
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        onChange={(value) => setCurrencyValue(value)}
                      />
                    </Form.Item>
                  </Col>
                  )}
                  
                </Row>
              )}
              {!rfqChecked && rateSelected && (
                <>
                <Row gutter={[16, 10]}>
                  <Col xs={24} md={12} lg={6}>
                    <Form.Item
                      label="Precio unitario:"
                      name="unitPrice"
                      rules={[
                        {
                          required: true,
                          message: 'Por favor ingrese un costo',
                          type: 'number',
                        },
                      ]}
                    >
                      <InputNumber
                        disabled={disableAllInputs}
                        placeholder="Ingresa un costo"
                        style={{ width: '100%' }}
                        min={0}
                        formatter={(value) =>
                          `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        }
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        onChange={(value) => setUnitPrice(value)}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={6}>
                    <p style={{ marginBottom: 7 }}>Costo total:</p>
                    <Tag color="blue" style={{ padding: 5, width: '100%' }}>
                      <Text className="text-label">
                        ${formatMoney(totalCost)} {selectedCurrency === CURRENCY_USD ? 'USD' : 'MXN' }
                      </Text>
                    </Tag>
                    <Form.Item
                      label="fullPrice"
                      name="fullPrice"
                      hidden
                      rules={[
                        {
                          required: false,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={12} lg={6}>
                    <p style={{ marginBottom: 7 }}>Venta:</p>
                    <Tag color="orange" style={{ padding: 5, width: '100%' }}>
                      <Text className="text-label">
                        ${formatMoney(totalSale)} {selectedCurrency === CURRENCY_USD ? 'USD' : 'MXN' }
                      </Text>
                    </Tag>
                    <Form.Item
                      label="sale"
                      name="sale"
                      hidden
                      rules={[
                        {
                          required: false,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={12} lg={6}>
                    <p style={{ marginBottom: 7 }}>Profit:</p>
                    <Tag
                      color={totalProfit < 0 ? 'red' : 'green'}
                      style={{ padding: 5, width: '100%' }}
                    >
                      <Text className="text-label">
                        ${formatMoney(totalProfit)} {selectedCurrency === CURRENCY_USD ? 'USD' : 'MXN' }
                      </Text>
                    </Tag>
                  </Col>
                </Row>
                <Row gutter={16}>
                <Col  xs={24} md={6} lg={6}>
                <Form.Item name="iva" valuePropName="checked">
                        <Checkbox
                          onChange={(e) => setIvaChecked(e.target.checked)}
                          checked={ivaChecked}
                        >
                          IVA:
                        </Checkbox>
                      </Form.Item>
                </Col>
                {ivaChecked === true && (
                  <>
                  <Col xs={24} md={6} lg={6} style={{ marginBottom: 10 }}>
                      <p style={{ marginBottom: 7 }}>Subtotal:</p>
                      <Tag color="orange" style={{ padding: 5, width: '100%' }}>
                        <Text className="text-label">
                          ${formatMoney(totalSale)} {selectedCurrency === CURRENCY_USD ? 'USD' : 'MXN' }
                        </Text>
                      </Tag>
                  </Col>
                  <Col xs={24} md={6} lg={6} style={{ marginBottom: 10 }}>
                      <p style={{ marginBottom: 7 }}>IVA 16%:</p>
                      <Tag color="lightgray" style={{ padding: 5, width: '100%' }}>
                        <Text className="text-label">
                          ${formatMoney((totalSale * 16) / 100)} {selectedCurrency === CURRENCY_USD ? 'USD' : 'MXN' }
                        </Text>
                      </Tag>
                  </Col>
                  <Col xs={24} md={6} lg={6} style={{ marginBottom: 10 }}>
                      <p style={{ marginBottom: 7 }}>Total:</p>
                      <Tag color="green" style={{ padding: 5, width: '100%' }}>
                        <Text className="text-label">
                          ${formatMoney(totalSale + ((totalSale * 16) / 100))} {selectedCurrency === CURRENCY_USD ? 'USD' : 'MXN' }
                        </Text>
                      </Tag>
                  </Col>
                  </>
                )}
                
                </Row>
                </>
              )}

              <Row
                gutter={16}
                style={{
                  justifyContent: 'space-between',
                  marginTop: 20,
                  marginBottom: 20,
                }}
              >
                <Col lg={12} md={24} xs={24} style={{ marginBottom: -20 }}>
                  <Form.Item label="Comentarios:" name="comments">
                    <TextArea
                      placeholder="Ingrese información"
                      disabled={disableAllInputs}
                    />
                  </Form.Item>
                </Col>

                {showAlertProfit && !rfqChecked && rateSelected &&(
                  <Col
                    xs={24}
                    md={10}
                    style={{ marginBottom: 20, marginTop: 27 }}
                  >
                    <Alert
                      message="No esta obteniendo una ganancia en esta línea"
                      type="warning"
                      showIcon
                      closable
                    />
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </Spin>
        {showAlert && (
          <Alert
            message={textType}
            description={textDescription}
            type="warning"
            showIcon
            style={{ marginBottom: 20 }}
          />
        )}
      </>
    </Form>
  );
};

export default NewLine;
