import React, { useEffect, useState } from 'react';
import {
  Table,
  Spin,
  Button,
  notification,
  Modal as ModalAntd,
  Input,
  Space,
  Image,
  Row,
  Col,
} from 'antd';
import {
  DownloadOutlined,
  DeleteOutlined,
  FileOutlined,
  ExclamationCircleOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import AddCommentForm from '../AddCommentForm';
import Modal from '../../../General/Modal';
import { deleteDocumentApi } from '../../../../api/documents';
import { getAccessTokenApi, logout } from '../../../../api/auth';
import { messageError } from '../../../../utils/general';
import moment from 'moment';
import Highlighter from 'react-highlight-words';
import { ROL_MANAGER, ROL_FINANCIAL } from '../../../../utils/constants';
import useUser from '../../../../hooks/useUser';
import './DocumentsTable.scss';
import { getFilteredDataFromLocalStorage, saveFilteredDataToLocalStorage } from '../../../../helpers/saveFilterData';

export default function DocumentsTable({
  documents,
  laodingData,
  setReloadDocuments,
}) {
  const [loadingForm, setLoadingForm] = useState(false);
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [titleModal, setTitleModal] = useState(false);
  const [contextModal, setContextModal] = useState(null);
  const token = getAccessTokenApi();
  const { confirm } = ModalAntd;
  const [filteredInfo, setFilteredInfo] = useState({});
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const { person } = useUser();

  useEffect(() => {
    const datosObtenidos = getFilteredDataFromLocalStorage('clarificationsDocuments');
    if (datosObtenidos !== null) {
    setFilteredInfo(datosObtenidos.filters);
    }
  },[])
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            // this.searchInput = node;
          }}
          placeholder={`Buscar`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => {
              handleReset(clearFilters);
              handleSearch(selectedKeys, confirm, dataIndex);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Limpiar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    filteredValue: filteredInfo[dataIndex] || null,
    onFilterDropdownVisibleChange: (visible) => {},
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: 'Referencia',
      dataIndex: 'reference',
      key: 'reference',
      align: 'center',
      // render: (reference, doc) => (
      //     <p>{`${doc.id}`}</p>
      // )
      render: (reference, operation) => (
        <p>{`${reference} - ${operation.key + 1}`}</p>
      ),
    },
    {
      title: 'Imagen',
      // dataIndex: 'description',
      // key: 'image',
      align: 'center',
      render: (fileName, image) => (
        <>
          {(image.extension === 'jpeg' || image.extension === 'png') && (
            <Image
              width={120}
              height={120}
              src={`data:image/${image.extension};base64, ${image.stringFileContent}`}
              alt="doc"
              // style={{ width: 100, marginLeft: 20 }}
            />
          )}
          {image.extension !== 'jpeg' && image.extension !== 'png' && (
            <FileOutlined style={{ fontSize: 50, color: 'gray' }} />
          )}
        </>
      ),
    },
    {
      title: 'Nombre del archivo',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      ...getColumnSearchProps('name'),
    },
    {
      title: 'Fecha de alta de archivo',
      dataIndex: 'creationDate',
      key: 'creationDate',
      align: 'center',
      sorter: (a, b) =>
        moment(a.creationDate).unix() - moment(b.creationDate).unix(),
      render: (creationDate) => (
        <>{moment.utc(creationDate).local().format('DD/MM/YYYY')}</>
      ),
    },
    {
      title: 'Usuario',
      dataIndex: 'creationUsername',
      key: 'creationUsername',
      align: 'center',
      ...getColumnSearchProps('creationUsername'),
    },
    {
      title: 'Acción',
      dataIndex: 'extension',
      key: 'extension',
      align: 'center',
      // width: 200,
      render: (extension, data) => (
        <Row gutter={[10, 10]}>
          <Col xs={24} lg={12}>
            <Button
              icon={<DownloadOutlined />}
              // className="btn-download"
              type="primary"
              // style={{ width: 130 }}
              size="small"
              href={'data:image/png;base64,' + data.stringFileContent}
              download={data.name}
              // className="btn-download-doc"
              style={{
                marginBottom: 11,
                justifyContent: 'center',
                alignItems: 'center',
                display: 'inline-flex',
              }}
            />
          </Col>
          {[ROL_FINANCIAL, ROL_MANAGER].some((r) =>
            person.roles.includes(r)
          ) && (
            <Col xs={24} md={12}>
              <Button
                icon={<DeleteOutlined />}
                danger
                onClick={() => handleDeleteDoc(data.id, data.name)}
                type="primary"
                // htmlType="submit"
                size="small"
                className="login-form-regresar"
                // style={{ marginLeft: 20, width: 130, borderRadius: 4 }}
              />
            </Col>
          )}
        </Row>
      ),
    },
    // {
    //     title: 'Comentarios',
    //     dataIndex: 'hasComments',
    //     align: 'center',
    //     key: 'hasComments',
    //     render: (hasComments, docs) => (
    //         <>
    //             {hasComments === false && (
    //                 <CommentOutlined
    //                     style={{ fontSize: 18 }}
    //                     onClick={() => showModal(docs.id)}
    //                 />
    //             )}
    //             {hasComments === true && (
    //                 <CommentOutlined
    //                     style={{ fontSize: 18, color: 'green' }}
    //                     onClick={() => showModal(docs.id)}
    //                 />
    //             )}
    //         </>
    //     ),
    // },
  ];

  const handleDeleteDoc = (id, name) => {
    confirm({
      title: 'Eliminar documento',
      icon: <ExclamationCircleOutlined />,
      content: `¿Estás seguro que desea eliminar el documento ${name}?, no se podrá recuperar después`,
      okText: 'Si',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteDocument(id);
      },
      onCancel() {},
    });
  };

  const deleteDocument = async (id) => {
    setLoadingForm(true);

    const data = {
      documentId: id,
      RefreshToken: '',
    };

    const result = await deleteDocumentApi(token, data);

    // console.log(result, 'Result');
    if (result === undefined) {
      notification['error']({
        message: messageError(),
      });
    } else {
      if (result.statusCode === 401) {
        notification['error']({
          message: 'Usuario no autorizado.',
        });

        setTimeout(() => {
          logout();
          window.location.reload();
        }, 1200);

        return false;
      }

      if (result.statusCode === 200) {
        notification['success']({
          message: 'Documento eliminado exitosamente',
        });
        setReloadDocuments(true);
        setLoadingForm(false);
      } else {
        let msg = 'Lo sentimos, ocurrió un error vuelva a intertarlo.';

        if (
          result.statusCode === 400 ||
          result.statusCode === 409 ||
          result.statusCode === 404
        ) {
          msg = result.description;
        }
        notification['error']({
          message: msg,
        });
      }
    }
    setLoadingForm(false);
  };

  const showModal = (id) => {
    setTitleModal('Agregar comentario');
    setModalIsVisible(true);
    setContextModal(
      <AddCommentForm
        setModalIsVisible={setModalIsVisible}
        documentId={id}
      ></AddCommentForm>
    );
  };
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const handleChange = (pagination, filters, sorter, extra) => {
    saveFilteredDataToLocalStorage('clarificationsDocuments', pagination, sorter, filters);
    setFilteredInfo(filters);
    // setbillsExport(extra.currentDataSource);
  };

  const clearAll = () => {
    setFilteredInfo({});
    // setSortedInfo({});
  };
  return (
    <Spin spinning={loadingForm} tip="Guardando...">
      <div>
        <Table
          onChange={handleChange}
          columns={columns}
          dataSource={documents}
          loading={laodingData}
          scroll={{ x: 800 }}
          // pagination={{ defaultPageSize: 5 }}
        />
      </div>
      <Modal
        title={titleModal}
        visible={modalIsVisible}
        setIsVisible={setModalIsVisible}
        width={1000}
      >
        {contextModal}
      </Modal>
    </Spin>
  );
}
