import React, { useEffect, useState } from 'react';
import {
  Form,
  InputNumber,
  Button,
  Row,
  Col,
  Select,
  Spin,
  Typography,
  Tag,
  notification,
} from 'antd';

import { METHOD_GET, METHOD_PUT } from '../../../../../utils/constants';
import {
  API_URL_ADDITIONAL_SERVICE,
  API_URL_SYPPLIER_ADDITIONAL_SERVICE,
} from '../../../../../helpers/urls';
import { getAccessTokenApi } from '../../../../../api/auth';
import { fetchApi } from '../../../../../helpers/fetch';
import { messageErrorGeneral } from '../../../../../utils/feedbackMessages';

const EditAdditionalService = ({
  additionServiceId,
  supplierId,
  supplierName,
  setModalIsVisible,
  setReloadAdditionalServices,
}) => {
  const [additionalServices, setAdditionalServices] = useState([]);
  const [loadingForm, setLoadingForm] = useState(false);
  const [disableAllInputs, setDisableAllInputs] = useState(false);
  const { Text } = Typography;
  const token = getAccessTokenApi();
  const [form] = Form.useForm();

  useEffect(() => {
    setLoadingForm(true);
    const config = {
      method: METHOD_GET,
      url: `${API_URL_ADDITIONAL_SERVICE}`,
      data: null,
      token,
      // showNotification: true
    };
    fetchApi(config)
      .then((response) => {
        if (response) {
          let calogsValues = response.result;

          if (calogsValues) {
            calogsValues.map((cat, index) => {
              cat['key'] = index;
              cat['label'] = cat.name;
              cat['value'] = cat.id;
              return cat;
            });
          }
          setAdditionalServices(calogsValues);
        }
        setLoadingForm(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [token]);

  useEffect(() => {
    setLoadingForm(true);
    const config = {
      method: METHOD_GET,
      url: `${API_URL_SYPPLIER_ADDITIONAL_SERVICE(
        supplierId
      )}/${additionServiceId}`,
      data: null,
      token,
      // showNotification: true
    };
    fetchApi(config)
      .then((response) => {
        if (response?.statusCode === 200) {
          let resource = response.result;

          form.setFieldsValue({
            AdditionalServiceId: resource.additionalServiceId,
            Cost: resource.cost,
          });
        }

        setLoadingForm(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [token, additionServiceId]);

  const onFinish = (values) => {
    values['SupplierId'] = supplierId;
    values['id'] = additionServiceId;

    const config = {
      method: METHOD_PUT,
      url: `${API_URL_SYPPLIER_ADDITIONAL_SERVICE(
        supplierId
      )}/${additionServiceId}`,
      data: values,
      token,
      // showNotification: true
    };
    fetchApi(config)
      .then((response) => {
        if (response) {
          if (response.statusCode === 200) {
            setReloadAdditionalServices(true);
            setModalIsVisible(false);
          } else {
            notification.error({
              description: messageErrorGeneral(),
            });
          }
        }
        setLoadingForm(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Spin spinning={loadingForm}>
      <Text strong>
        Proveedor: <Tag color="blue">{supplierName}</Tag>
      </Text>
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete="off"
        style={{ marginTop: 20 }}
        form={form}
        // layout='inline'
      >
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} lg={12}>
            <Form.Item
              label="Servicio Adicional:"
              name="AdditionalServiceId"
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese un servicio adicional',
                },
              ]}
            >
              <Select
                disabled={disableAllInputs}
                placeholder="Selecciona una opción"
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {additionalServices.map((s) => (
                  <Select.Option key={s.value} value={s.value}>
                    {s.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} lg={12}>
            <Form.Item
              label="Monto:"
              name="Cost"
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese un monto',
                  type: 'number',
                },
              ]}
            >
              <InputNumber
                disabled={disableAllInputs}
                placeholder="Ingresa un costo"
                style={{ width: '100%' }}
                min={0}
                formatter={(value) =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit" className="btn-enviar">
            Guardar
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  );
};

export default EditAdditionalService;
