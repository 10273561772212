import React, { useEffect, useState } from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Line,
  Svg,
} from '@react-pdf/renderer';
import {
  formatMoney,
  formatPhoneNumber,
  nameMonth,
} from '../../../../utils/general';

import Fondo from '../../../../assets/img/png/fondopdf.png';
import {
  Table,
  TableHeader,
  TableCell,
  TableBody,
  DataTableCell,
} from '@david.kucsai/react-pdf-table';
// Create styles
const styles = StyleSheet.create({
  page: {
    // paddingTop: 35,
    // paddingBottom: 65,
    // paddingHorizontal: 35,
  },
  containerTop: {
    marginTop: 150,
  },
  containerBottom: {
    marginBottom: 100,
  },
  pageBackground: {
    position: 'absolute',
    minWidth: '100%',
    minHeight: '100%',
    display: 'block',
    height: '100%',
    width: '100%',
  },
  section: {
    margin: 5,
    padding: 10,
    marginTop: 0,
    // flexGrow: 1,
  },
  containerRight: {
    margin: 10,
    marginTop: 0,
  },
  textClient: {
    marginTop: 0,
    fontSize: 13,
  },
  marginTop: {
    marginTop: 20,
    fontSize: 13,
  },
  rowTable: {
    flexDirection: 'row',
    marginTop: 20,
  },
  sectionTable: {
    flexGrow: 1,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    display: 'flex',
    width: 200,
    borderWidth: 1,
    borderColor: '#000',
  },
  titleTable: {
    fontSize: 11,
    textAlign: 'center',
    fontWeight: 'extrabold',
    paddingTop: 5,
    paddingBottom: 5,
  },
  titleTableSmall: {
    fontSize: 10,
    textAlign: 'center',
    fontWeight: 'extrabold',
    paddingTop: 5,
    paddingBottom: 5,
  },
  infoTable: {
    textAlign: 'center',
    fontSize: 12,
    marginBottom: 5,
  },
  rowTableInfo: {
    flexDirection: 'row',
  },
  note: {
    fontStyle: 'italic',
    fontSize: 12,
    marginTop: 5,
  },
  text: {
    fontSize: 13,
  },
  text2: {
    fontSize: 13,
    marginTop: 5
  },
  margingText: {
    marginTop: 5,
  },
  containerTable: {
    marginTop: 20,
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 10,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  boldText: {
    fontWeight: 'bold',
    fontSize: 12,
  },
  container: {
    flexDirection: 'row', 
    justifyContent: 'space-between', 
    padding: 20, 
  },
  column: {
    width: '45%',
    alignItems: 'center',
  },
});


const currentMonth = new Date().getMonth() + 1;
const currentYear = new Date().getFullYear();
const currentDay = new Date().getDate();

const dateString = `${currentDay} DE ${nameMonth(
  currentMonth
).toUpperCase()} DEL ${currentYear}`;

export const QuotePdf = ({ quote }) => {
  const [validDate, setValidDate] = useState(null);

  useEffect(() => {
    if (quote) {
      const dayValid = new Date(quote.endDate * 1000);
      const day = dayValid.toLocaleString('es-MX', { day: 'numeric' });
      const month = dayValid.toLocaleString('es-MX', { month: 'long' });
      const year = dayValid.toLocaleString('es-MX', { year: 'numeric' });
      setValidDate(`${day} ${month} ${year}`);
    }
  }, [quote]);

  if (!quote) {
    return <View />;
  }
  const chargesTable = [
    {description: 'ESTADÍA', charge:'$5.000.00 M.N por día (Se considera estadía una vez cumplidas 12 horas en proceso de descarga o carga)'},
    {description: 'STOP / REPARTO', charge:'$ 3,800.00 M.N. por evento (Siempre y cuando no salga de ruta).'},
    {description: 'MOVIMIENTO EN FALSO', charge:'$ 3,800.00 M.N. por evento (Siempre y cuando se realice en ciudad donde contemos con presencia de terminal).'},
    {description: 'TIEMPO DE CARGA/DESCARGA', charge:'2 horas libres en caso de exceder a partir de la 3era hora se hará cargo de $ 75.00 USD por hora o fracción.'},
    {description: 'DÍAS EN FRONTERA PARA CRUCE DE EXPORTACIÓN.', charge:'3 Días libres, en caso de exceder a partir del TERCER día se hará cargo $100.00 USD por día O fracción, en caso de aplicar.'},
    {description: 'DÍAS EN DESCARGA EN BODEGA DE LAREDO TEXAS.', charge:'2 Días libres, en caso de exceder a partir del TERCER día se hará cargo $100.00 USD por día o fracción en caso de aplicar.'},
    {description: 'DÍAS EN BODEGA DE LAREDO TEXAS PARA CARGAS DE IMPORTACIÓN.', charge:'3 Días para carga de unidad, a partir del 4to. Día se retira el equipo (con previo aviso) y Tiene un cargo de $100.00 USD por el movimiento, en caso de aplicar.'},
    {description: 'MOVIMIENTO EN FALSO LAREDO TX', charge:'$ 50.00 USD Por recolección o colocación de caja, en caso de aplicar.'},
    {description: 'DETENCIÓN POR INSPECCIÓN EN CRUCE.', charge:'$ 130.00 USD con 2 horas libres, a partir de la 3er hora se hará cargo de $ 30.00 USD por Hora o fracción en caso de aplicar.'},
    {description: 'EQUIPO DE AMARRE (Gata, tensor,banda, cadenas)', charge:'Renta de equipo de sujeción: $20.00 USD c/u $ 60.00 USD C/U En caso de no ser recuperada en la descarga.'},
    {description: 'COLOCACIÓN DE LONAS', charge:'Incluye 1 lona sin costo, a partir de 2da lona se agrega costo de $ 50.00 USD c/u.'},
    {description: 'DOBLE OPERADOR', charge:'Se agrega el 21% del flete regular más pistas completas.'},
    {description: 'HAZMAT Y OTROS (Para transporte o servicio que requiera custodia o de alto riesgo de robo)', charge:'Se agrega el 35% del flete regular más pistas completas.'},
    {description: 'SEGURO DE MERCANCIA', charge:'En caso de accidente o robo el transportista tiene responsabilidad mínima. \n Deducibles \n -20% sobre el valor total del embarque de toda y cada perdida ocasionada por Robo. \n-25% sobre el valor total del embarque de toda y cada perdida ocasionada por Robo Total en Estado de Mexico, D.F Guadalajara y Querétaro.\n -5% sobre el valor total del embarque de toda y cada perdida ocasionada por cualquier otro Riesgo cubierto. En todos los casos con un mínimo de $250 dólares o su equivalente en Moneda Nacional al momento del siniestro.'},
  ]
  return (
    <Document>
      <Page size="LETTER" style={styles.page} wrap>
        {/* <Image src={TopImage} /> */}
        <Image src={Fondo} style={styles.pageBackground} fixed />
        <View style={styles.containerTop} fixed />
        <View style={styles.containerRight}>
          <Text style={{ textAlign: 'right', fontSize: 13 }}>{dateString}</Text>
          <Text style={{ textAlign: 'right', fontSize: 13, marginTop: 5 }}>
            {`COTIZACIÓN NO. - ${
              quote.quoteIdStr ? quote.quoteIdStr : quote.id
            }`}
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.textClient}>{quote.customerName}</Text>
          <Text style={[styles.marginTop]}>
            Por medio de la presente y agradeciendo de antemano su confianza,
            ofrezco la siguiente cotización para su empresa
          </Text>
          <Text style={[styles.marginTop, { marginBottom: 5 }]}>
            CARACTERÍSTICAS
          </Text>
          {quote.quoteLines.map((line, index) => (
            <Text
              key={`line-${index}`}
              style={[styles.marginTop, { marginTop: 4 }]}
            >
              L{index + 1} PIEZAS - {line.numberPieces} PESO -{' '}
              {line.totalWeight} KG - CBM:
              {line.totalCBM} M2
            </Text>
          ))}

          <View style={styles.containerTable}>
            <Table data={quote.quoteLines}>
              <TableHeader textAlign={'center'}>
                <TableCell style={styles.titleTable} weighting={0.5}>
                  LÍNEA
                </TableCell>
                <TableCell style={styles.titleTable}>SERVICIO</TableCell>
                <TableCell style={styles.titleTable}>TIPO</TableCell>
                <TableCell style={styles.titleTable}>
                  ORIGEN - DESTINO
                </TableCell>
                <TableCell style={styles.titleTable}>UNIDAD</TableCell>
                <TableCell style={styles.titleTable}>PRECIO-SUBTOTAL</TableCell>
                {/* <TableCell style={styles.titleTable}>MONEDA</TableCell> */}
              </TableHeader>

              <TableBody textAlign={'center'}>
                <DataTableCell
                  weighting={0.5}
                  getContent={(r, index) => r.lineNumber}
                  style={styles.titleTable}
                />
                <DataTableCell
                  style={styles.titleTable}
                  getContent={(r) => r.service}
                />
                <DataTableCell
                  style={styles.titleTable}
                  getContent={(r) => r.typeOfService}
                />
                <DataTableCell
                  style={styles.titleTable}
                  getContent={(r) => r.originCity + ' - ' + r.destinationCity}
                />
                <DataTableCell
                  style={styles.titleTable}
                  getContent={(r) => r.unitType}
                />
                <DataTableCell
                  style={styles.titleTable}
                  getContent={(r) =>
                    `$ ${formatMoney(r.sale)} ${r.currency ? r.currency : ''}`
                  }
                />
              </TableBody>
            </Table>
          </View>
          {quote.quoteAS.length >= 1 && (
            <>
              <Text style={[styles.marginTop, { marginBottom: 5 }]}>
                Servicios Adicionales
              </Text>

              <View style={styles.containerTable}>
                <Table data={quote.quoteAS}>
                  <TableHeader textAlign={'center'}>
                    <TableCell style={styles.titleTable} weighting={0.5}>
                      No
                    </TableCell>
                    <TableCell style={styles.titleTable}>SERVICIO</TableCell>
                    <TableCell style={styles.titleTable}>TIPO</TableCell>
                    <TableCell style={styles.titleTableSmall}>
                      SERVICIO ADICIONAL
                    </TableCell>
                    <TableCell style={styles.titleTable}>PROVEEDOR</TableCell>
                    <TableCell style={styles.titleTable}>COSTO</TableCell>
                    {/* <TableCell style={styles.titleTable}>MONEDA</TableCell> */}
                  </TableHeader>

                  <TableBody textAlign={'center'}>
                    <DataTableCell
                      weighting={0.5}
                      getContent={(r, index) => r.number + 1}
                      style={styles.titleTable}
                    />
                    <DataTableCell
                      style={styles.titleTable}
                      getContent={(r) => r.service}
                    />
                    <DataTableCell
                      style={styles.titleTable}
                      getContent={(r) => r.typeOfService}
                    />
                    <DataTableCell
                      style={styles.titleTable}
                      getContent={(r) => r.additionalService}
                    />
                    <DataTableCell
                      style={styles.titleTable}
                      getContent={(r) => r.supplierName}
                    />
                    <DataTableCell
                      style={styles.titleTable}
                      getContent={(r) => `$ ${formatMoney(r.cost)}`}
                    />
                  </TableBody>
                </Table>
              </View>
            </>
          )}

          <View style={styles.containerRight}>
            <Text style={{ textAlign: 'right', fontSize: 13, marginTop: 5 }}>
              {`TOTAL  $ ${formatMoney(quote.totalSale)} ${
                quote.quoteLines.length > 0
                  ? quote.quoteLines[0]['currency'] || ''
                  : ''
              }`}
            </Text>
          </View>
          <Text
            style={[
              styles.marginTop,
              { fontSize: 14, fontWeight: 'bold', marginTop: 30 },
            ]}
          >
            NOTAS IMPORTANTES.
          </Text>
          <Text style={styles.note}>{quote.comments}</Text>
          <Text style={[styles.note, { fontSize: 12, fontWeight: 'bold' }]}>
            *VIGENCIA {validDate}{' '}
          </Text>
          <Text
            style={[
              styles.marginTop,
              { fontSize: 14, fontWeight: 'bold', marginTop: 30 },
            ]}
          >
           Condición de pago: {quote.paymentTerms}
          </Text>
          <Text style={styles.note}>Vigencia: 15 días a partir de la fecha de emisión (sujeta a cambios sin previo aviso) Notas.</Text>
          <Text style={styles.note}>- Los precios están considerados en {quote.quoteLines[0].currency} y no incluyen IVA.</Text>
          <Text style={styles.note}>- Sujetos a disponibilidad de equipo en la zona.</Text>
          <Text style={styles.note}>- Peso máximo para cargar de 8 - 14 toneladas</Text>
          <Text style={styles.note}>- No incluye seguro de mercancía</Text>
          <Text style={styles.note}>- No incluye demoras ocasionadas por el remitente o destinatario</Text>
          <Text style={styles.note}>- No incluye maniobras de carga y descarga. (No contamos con ese servicio)</Text>
          <Text style={styles.note}>- No incluye movimientos adicionales y/o recolecciones.</Text>
          <Text
            style={[
              styles.marginTop,
              { fontSize: 14, fontWeight: 'bold', marginTop: 30 },
            ]}
          >
            CONDICIONES.
          </Text>
          <Text style={styles.note}>1. Está cotización se basa en las características antes proporcionadas</Text>
          <Text style={styles.note}>
            2. Toda mercancía y embalaje de madera (huacales, tarimas, cajones, cajas, etc.) deberán de cumplir con la norma NOM 144-SEMARNAT.</Text>
          <Text style={styles.note}>No transportamos: Artesanías, menajes de casa, motocicletas, vehículos particulares, pieles, teja de barro, plata. </Text>
          <Text style={styles.note}>No circulamos por: zona residencial, minas, brechas, cerros y terracerías.</Text>
          <Text style={styles.note}>Para entregas en Zonas Prohibidas: Se requiere que se cuente con permiso por parte del cliente para cargar y/o descargar, sujeto a autorización de Gerencia de GP MET LOGISTICS, S.A DE C.V.</Text>
        
        <View style={styles.container}>
          <View style={styles.column}>
          <Svg height="10"  style={{marginTop: 30}}>
            <Line x1="0" y1="5" x2="250" y2="5" strokeWidth={1} stroke="rgb(0,0,0)" />
            <Line x1="0" y1="5" x2="250" y2="5" strokeWidth={1} stroke="rgb(0,0,0)" />
          </Svg>
            <Text style={styles.text}>Nombre y firma de aceptación</Text>
            <Text style={styles.text2}>Cliente</Text>
          </View>
          <View style={styles.column}>
          <Svg height="10"  style={{marginTop: 30}}>
            <Line x1="0" y1="5" x2="250" y2="5" strokeWidth={1} stroke="rgb(0,0,0)" />
            <Line x1="0" y1="5" x2="250" y2="5" strokeWidth={1} stroke="rgb(0,0,0)" />
          </Svg>
          <Text style={styles.text}>Nombre</Text>
            <Text style={styles.text2}>Gerente Comercial</Text>
          </View>
        </View>
        <Text
            style={[
              styles.marginTop,
              { fontSize: 14, fontWeight: 'bold', marginTop: 30 },
            ]}
          >
            CARGOS ACCESORIALES
          </Text>
          <View style={styles.containerTable}>
            <Table data={chargesTable}>
              <TableHeader textAlign={'center'}>
                <TableCell style={styles.titleTable} weighting={0.5}>
                Descripción
                </TableCell>
                <TableCell style={styles.titleTable}>Cargo</TableCell>
              </TableHeader>

              <TableBody textAlign={'center'}>
                <DataTableCell
                  weighting={0.5}
                  getContent={(r, index) => r.description}
                  style={styles.titleTable}
                />
                <DataTableCell
                  style={styles.titleTable}
                  getContent={(r) => r.charge}
                />
              </TableBody>
            </Table>
          </View>
         

          <Text style={styles.marginTop}>
            Esperando que la cotización sea de su agrado quedo a sus órdenes.
          </Text>
          <Text style={[styles.text, styles.margingText]}>
            {quote.user.name} {quote.user.lastName}
          </Text>
          <Text style={[styles.text, styles.margingText]}>
            {`TEL. ${
              quote.user.phoneNumber
                ? formatPhoneNumber(quote.user.phoneNumber)
                : ''
            }`}
          </Text>
        </View>

        <View style={styles.containerBottom} fixed />

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};
