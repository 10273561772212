import React, { useState, createContext } from 'react';

export const MenuCollapseContext = createContext();

export default function MenuCollapseProvider(props) {
  const { children } = props;
  const [menuCollapsed, setMenuCollapsed] = useState(false);

  return (
    <MenuCollapseContext.Provider value={{ menuCollapsed, setMenuCollapsed }}>
      {children}
    </MenuCollapseContext.Provider>
  );
}
