import { Button, Divider, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { getAccessTokenApi } from '../../../../api/auth';
import { getFileInvoice } from '../../../../api/billing';
import { DownloadOutlined,EyeOutlined } from '@ant-design/icons';
import { METHOD_GET } from '../../../../utils/constants';
import { fetchBlob } from '../../../../helpers/fetch';

const FileForm = ({ setModalIsVisible, type, reference }) => {
  const token = getAccessTokenApi();
  const [dataUrl, setDataUrl] = useState([]);
  const [typeFile, setTypeFile] = useState([]);
  const [url, setUrl] = useState('');

  useEffect(() => {
    let data = {};
    if (type === 'pdf' || type === 'xml') {
      data = {
        reference: reference,
        documentType: 'APInvoice',
        extensionFilter: [type],
      };
    } else if (type === 'cfdi') {
      data = {
        reference: reference,
        documentType: 'APInvoiceAdd',
      };
    }

    getFileInvoice(token, data).then((response) => {
      if (response.statusCode === 200) {
        const res =response.result[0]
        setDataUrl(res);
        if (response.result.length !== 0) {
          if (res.extension !== 'docx'){
            setUrl(res.fileLocation)
          } else{
            var encodedUrl = encodeURIComponent(res.fileLocation);
            var iFrameUrl = 'https://docs.google.com/viewer?url=' + encodedUrl + '&embedded=true';
            setUrl(iFrameUrl)
          }
         }
        // setDataUrl(response?.result[0]?.stringFileContent);
        // setTypeFile(response?.result[0]?.extension);
      }
    });
  }, [token, reference, type]);

  const downloadFile = async (data) => {
    let a = document.createElement('a');
    document.body.appendChild(a);
    a.style = 'display: none';
    const config = {
      method: METHOD_GET,
      url: `Documents/DownloadAPInvoice?DocumentId=${data.id}`,
      data: null,
      token,
      showNotificationError: false,
    };
    fetchBlob(config)
      .then((response) => {
        let url = window.URL.createObjectURL(response);
        a.href = url;
        a.download = data.fileName;
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div>
      {dataUrl !== undefined && (
        <>
        {type !== 'xml' && (
          <>
          <iframe src={url}  style={{width:'100%', height:400 ,marginBottom: 20}}/>
          <Divider/>
          </>
        )}
        
        <Row justify='end'>
        <Button
          icon={<DownloadOutlined />}
          className="btn-download"
          // href={'data:image/png;base64,' + dataUrl}
          // download={'Archivo_facturacion.' + typeFile}
          onClick={() => downloadFile(dataUrl)}
          style={{ width: 170 }}
        >
          Descargar archivo
        </Button>
        {type === 'xml' && (
          <a href={url} target="_blank" rel="noopener noreferrer">
        <Button
          icon={<EyeOutlined />}
          className="btn-download"
          style={{ width: 170 }}
        >
          Ver archivo
        </Button>
        </a>
        )}
        </Row>
        
        
        </>
      )}
      {dataUrl === undefined && <p>No hay archivo</p>}
    </div>
  );
};

export default FileForm;
