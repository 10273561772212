/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useReducer } from 'react';
import { Row, Form, Select, Col, Input, Divider } from 'antd';
import { METHOD_GET } from '../../../../utils/constants';
import { API_URL_CATALOG_VALUES } from '../../../../helpers/urls';
import {
  CITY_CATALOG_ID,
  COUNTRY_CATALOG_ID,
  STATE_CATALOG_ID,
  TERRESTRE_VALUE_ID,
  MARITIMO_VALUE_ID,
  AEREO_VALUE_ID,
  CANADA_VALUE_ID,
  PORT_CATALOG_ID,
  AIRPORT_CATALOG_ID,
} from '../../../../helpers/constants';
import { getAccessTokenApi } from '../../../../api/auth';
import { fetchApi } from '../../../../helpers/fetch';
import useWindowDimensions from '../../../../hooks/useWindowdimensions';
import {
  getCatalogValueHandle,
  getCatalogValueReducerHandle,
} from '../../../../helpers/handlerFunctionApi';
import { types } from '../../../../types/types';
import { catalogsReducer } from '../../../../reducers/catalogs/catalogsReducer';

const initialState = {
  airport: [],
  port: [],
};
const RouteTab = (props) => {
  const {
    rate,
    disableAllInputs,
    service,
    type,
    setLoadingForm,
    form,
    isRfq,
    allRoutes,
    setRouteSelected,
    supplierSelected,
    routes,
  } = props;
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [ports, setPorts] = useState([]);
  const [originPorts, setOriginPorts] = useState([]);
  const [originAirports, setOriginAirports] = useState([]);
  const [destinationPorts, setDestinationPorts] = useState([]);
  const [destinationAirports, setDestinationAirports] = useState([]);
  const [airports, setAirports] = useState([]);
  const [countriesDestination, setCountriesDestination] = useState([]);
  const [statesDestination, setStatesDestination] = useState([]);
  const [citiesDestination, setCitiesDestination] = useState([]);
  const [portsDestination, setPortsDestination] = useState([]);
  // const [airportsDestination, setAirportsDestination] = useState([]);
  const suppliers = [{ value: '123', label: 'op123' }];
  const token = getAccessTokenApi();
  const { width } = useWindowDimensions();
  const [catalogs, dispatch] = useReducer(catalogsReducer, initialState);
  const [serviceCatalog, setServiceCatalog] = useState([]);
  const [routeFindSelected, setRouteFindSelected] = useState({});

  useEffect(() => {
    setLoadingForm(true);
    const config = {
      method: METHOD_GET,
      url: `${API_URL_CATALOG_VALUES}/GetAll/${COUNTRY_CATALOG_ID}`,
      data: null,
      token,
      // showNotification: true
    };
    fetchApi(config)
      .then((response) => {
        if (response) {
          let calogsValues = response.result;

          if (calogsValues) {
            calogsValues.map((cat, index) => {
              cat['key'] = index;
              cat['label'] = cat.description;
              cat['value'] = cat.id;
              return cat;
            });
          }
          setCountries(calogsValues);
          setCountriesDestination(calogsValues);
        }
        setLoadingForm(false);
      })
      .catch((err) => {
        console.log(err);
      });

    //   getStateCatalogHandle();
  }, []);
  useEffect(() => {
    setLoadingForm(true);
    const config = {
      method: METHOD_GET,
      url: `${API_URL_CATALOG_VALUES}/GetAll/${STATE_CATALOG_ID}`,
      data: null,
      token,
      // showNotification: true
    };
    fetchApi(config)
      .then((response) => {
        if (response) {
          let calogsValues = response.result;

          if (calogsValues) {
            calogsValues.map((cat, index) => {
              cat['key'] = index;
              cat['label'] = cat.description;
              cat['value'] = cat.id;
              return cat;
            });
          }
          setStates(calogsValues);
          setStatesDestination(calogsValues);
        }
        setLoadingForm(false);
      })
      .catch((err) => {
        console.log(err);
      });

    //   getStateCatalogHandle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setLoadingForm(true);
    const config = {
      method: METHOD_GET,
      url: `${API_URL_CATALOG_VALUES}/GetAll/${CITY_CATALOG_ID}`,
      data: null,
      token,
      // showNotification: true
    };
    fetchApi(config)
      .then((response) => {
        if (response) {
          let calogsValues = response.result;

          if (calogsValues) {
            calogsValues.map((cat, index) => {
              cat['key'] = index;
              cat['label'] = cat.description;
              cat['value'] = cat.id;
              return cat;
            });
          }
          setCities(calogsValues);
          setCitiesDestination(calogsValues);
        }
        setLoadingForm(false);
      })
      .catch((err) => {
        console.log(err);
      });

    //   getStateCatalogHandle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getCatalogValueHandle(PORT_CATALOG_ID, setPorts, setLoadingForm);
    getCatalogValueHandle(AIRPORT_CATALOG_ID, setAirports, setLoadingForm);
  }, []);
  useEffect(() => {
    let puertosorigen = ports.filter(
      (e) =>
        e.fatherId ===
        (Object.keys(routeFindSelected).length !== 0
          ? routeFindSelected.originCity
          : rate?.originCity)
    );
    let puertosdestino = ports.filter(
      (e) =>
        e.fatherId ===
        (Object.keys(routeFindSelected).length !== 0
          ? routeFindSelected.destinationCity
          : rate?.destinationCity)
    );
    let aeropuertosorigen = airports.filter(
      (e) =>
        e.fatherId ===
        (Object.keys(routeFindSelected).length !== 0
          ? routeFindSelected.originCity
          : rate?.originCity)
    );
    let aeropuertosdestino = airports.filter(
      (e) =>
        e.fatherId ===
        (Object.keys(routeFindSelected).length !== 0
          ? routeFindSelected.destinationCity
          : rate?.destinationCity)
    );
    setOriginPorts(puertosorigen);
    setDestinationPorts(puertosdestino);
    setOriginAirports(aeropuertosorigen);
    setDestinationAirports(aeropuertosdestino);
  }, [routeFindSelected, rate]);

  const onChangeRoute = (value) => {
    let find = allRoutes.find((e) => e.routeId === value);
    setRouteFindSelected(find);
    setRouteSelected({
      routeId: value,
      routeStr: `${find.originCityStr} ${find.originStateStr} - ${find.destinationCityStr} ${find.destinationStateStr}`,
    });
    form.setFieldsValue({ ...find });
    const clearRoute = {
      originZipCode: null,
      originAddress: null,
      destinationZipCode: null,
      destinationAddress: null,
    };
    form.setFieldsValue(clearRoute);
  };

  return (
    <div className="rute-tab">
      <Col xs={24} sm={24} lg={24}>
        <Form.Item
          label="Ruta:"
          name="routeId"
          rules={[
            {
              required: true,
              message: `Por favor ingrese una ruta`,
            },
          ]}
        >
          <Select
            disabled={disableAllInputs || isRfq || !supplierSelected}
            placeholder={`${
              supplierSelected === null
                ? 'Seleccione proveedor'
                : 'Selecciona una opción'
            }`}
            showSearch
            onChange={onChangeRoute}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {routes.map((s) => (
              <Select.Option key={s.value} value={s.value}>
                {s.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>

      <Divider style={{ marginTop: 0, marginBottom: 0 }}>Origen</Divider>

      <Row gutter={[16, 16]}>
        <Col xs={12} sm={12} lg={6}>
          <Form.Item
            label="País:"
            name="originCountry"
            rules={[
              {
                required: true,
                message: 'Por favor ingrese un país',
              },
            ]}
          >
            <Select
              disabled
              placeholder="Selecciona una opción"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {countries.map((s) => (
                <Select.Option key={s.value} value={s.value}>
                  {s.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={12} sm={12} lg={6}>
          <Form.Item
            label="Estado:"
            name="originState"
            rules={[
              {
                required: true,
                message: 'Por favor ingrese un estado',
              },
            ]}
          >
            <Select
              disabled
              placeholder="Selecciona una opción"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {states.map((s) => (
                <Select.Option key={s.value} value={s.value}>
                  {s.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={12} sm={12} lg={6}>
          <Form.Item
            label="Ciudad:"
            name="originCity"
            rules={[
              {
                required: true,
                message: 'Por favor ingrese una ciudad',
              },
            ]}
          >
            <Select
              disabled
              placeholder="Selecciona una opción"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {cities.map((s) => (
                <Select.Option key={s.value} value={s.value}>
                  {s.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        {service === TERRESTRE_VALUE_ID && (
          <Col xs={12} sm={12} lg={6}>
            <Form.Item
              label="Código postal:"
              name="originZipCode"
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese un código postal',
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (getFieldValue('OriginCountry') === CANADA_VALUE_ID) {
                      if (
                        value &&
                        value.toString().length >= 3 &&
                        value.toString().length <= 6
                      ) {
                        return Promise.resolve();
                      }
                    } else {
                      if (
                        value === undefined ||
                        value === '' ||
                        (value.toString().length === 5 && !isNaN(value))
                      ) {
                        return Promise.resolve();
                      }
                    }
                    return Promise.reject(
                      'Por favor ingrese un código postal válido'
                    );
                  },
                }),
              ]}
            >
              <Input
                maxLength={5}
                disabled={disableAllInputs || isRfq}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
        )}
        {service === MARITIMO_VALUE_ID && (
          <Col xs={12} sm={12} lg={6}>
            <Form.Item
              label="Puerto:"
              name="originPort"
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese un puerto',
                },
              ]}
            >
              <Select
                placeholder="Selecciona una opción"
                disabled={disableAllInputs || isRfq}
              >
                {originPorts.map((s) => (
                  <Select.Option key={s.value} value={s.value}>
                    {s.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        )}

        {service === AEREO_VALUE_ID && (
          <Col xs={12} sm={12} lg={6}>
            <Form.Item
              label="Aeropuerto:"
              name="originAirport"
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese un aeropuerto',
                },
              ]}
            >
              <Select
                placeholder="Selecciona una opción"
                disabled={disableAllInputs || isRfq}
              >
                {originAirports.map((s) => (
                  <Select.Option key={s.value} value={s.value}>
                    {s.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        )}

        {service === TERRESTRE_VALUE_ID && (
          <Col xs={24} sm={24} lg={24}>
            <Form.Item
              label="Dirección:"
              name="originAddress"
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese una dirección',
                },
              ]}
            >
              <Input disabled={disableAllInputs || isRfq} />
            </Form.Item>
          </Col>
        )}
      </Row>

      <Divider style={{ marginTop: 0, marginBottom: 0 }}>Destino</Divider>

      <Row gutter={[16, 16]}>
        <Col xs={12} sm={12} lg={6}>
          <Form.Item
            label="País:"
            name="destinationCountry"
            rules={[
              {
                required: true,
                message: 'Por favor ingrese un país',
              },
            ]}
          >
            <Select
              disabled
              placeholder="Selecciona una opción"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {countriesDestination.map((s) => (
                <Select.Option key={s.value} value={s.value}>
                  {s.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={12} sm={12} lg={6}>
          <Form.Item
            label="Estado:"
            name="destinationState"
            rules={[
              {
                required: true,
                message: 'Por favor ingrese un estado',
              },
            ]}
          >
            <Select
              disabled
              placeholder="Selecciona una opción"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {statesDestination.map((s) => (
                <Select.Option key={s.value} value={s.value}>
                  {s.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={12} sm={12} lg={6}>
          <Form.Item
            label="Ciudad:"
            name="destinationCity"
            rules={[
              {
                required: true,
                message: 'Por favor ingrese una ciudad',
              },
            ]}
          >
            <Select
              disabled
              placeholder="Selecciona una opción"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {citiesDestination.map((s) => (
                <Select.Option key={s.value} value={s.value}>
                  {s.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        {service === TERRESTRE_VALUE_ID && (
          <Col xs={12} sm={12} lg={6}>
            <Form.Item
              label="Código postal:"
              name="destinationZipCode"
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese un código postal',
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (
                      getFieldValue('DestinationCountry') === CANADA_VALUE_ID
                    ) {
                      if (
                        value &&
                        value.toString().length >= 3 &&
                        value.toString().length <= 6
                      ) {
                        return Promise.resolve();
                      }
                    } else {
                      if (
                        value === undefined ||
                        value === '' ||
                        (value.toString().length === 5 && !isNaN(value))
                      ) {
                        return Promise.resolve();
                      }
                    }
                    return Promise.reject(
                      'Por favor ingrese un código postal válido'
                    );
                  },
                }),
              ]}
            >
              <Input
                maxLength={5}
                disabled={disableAllInputs || isRfq}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
        )}

        {service === MARITIMO_VALUE_ID && (
          <Col xs={12} sm={12} lg={6}>
            <Form.Item
              label="Puerto:"
              name="destinationPort"
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese un puerto',
                },
              ]}
            >
              <Select
                placeholder="Selecciona una opción"
                disabled={disableAllInputs || isRfq}
                style={{
                  minWidth: width > 480 ? 220 : '100%',
                  width: width > 480 ? 220 : '100%',
                }}
              >
                {destinationPorts.map((s) => (
                  <Select.Option key={s.value} value={s.value}>
                    {s.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        )}

        {service === AEREO_VALUE_ID && (
          <Col xs={12} sm={12} lg={6}>
            <Form.Item
              label="Aeropuerto:"
              name="destinationAirport"
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese un aeropuerto',
                },
              ]}
            >
              <Select
                placeholder="Selecciona una opción"
                disabled={disableAllInputs || isRfq}
                style={{
                  minWidth: width > 480 ? 220 : '100%',
                  width: width > 480 ? 220 : '100%',
                }}
              >
                {destinationAirports.map((s) => (
                  <Select.Option key={s.value} value={s.value}>
                    {s.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        )}

        {service === TERRESTRE_VALUE_ID && (
          <Col xs={24} sm={24} lg={24}>
            <Form.Item
              label="Dirección:"
              name="destinationAddress"
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese una dirección',
                },
              ]}
            >
              <Input disabled={disableAllInputs || isRfq} />
            </Form.Item>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default RouteTab;
