import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Spin,
  Form,
  Input,
  Button,
  notification,
  Modal as ModalAntd,
} from 'antd';
import './CompanyDataDetail.scss';
import {
  createCompanyApi,
  getCompanyApi,
  getCompanyIdApi,
  updateCompanyApi,
} from '../../../../api/company';
import { getAccessTokenApi } from '../../../../api/auth';
import {
  logOutError,
  messageSuccessEditGeneral,
} from '../../../../utils/feedbackMessages';
import { ExclamationCircleOutlined } from '@ant-design/icons';
const CompanyDataDetail = () => {
  const [loadingForm, setLoadingForm] = useState(false);
  const [form] = Form.useForm();
  const [isUpdate, setIsUpdate] = useState(true);
  const companyId = getCompanyIdApi();
  const token = getAccessTokenApi();
  const { confirm } = ModalAntd;

  useEffect(() => {
    if (companyId == null) {
      logOutError();
    } else {
      getCompanyApi(token, companyId)
        .then((response) => {
          if (response) {
            if (response.statusCode === 409) {
              if (response.result.message === 'Registro no encontrado.') {
                setIsUpdate(false);
              }
            }

            if (response.statusCode === 200) {
              const result = response.result;
              form.setFieldsValue({
                Name: result.name,
                RFC: result.rfc,
                Phone: result.phone,
                Address: result.address,
                Email: result.email,
              });
            }
          }
        })
        .catch((err) => console.log(err));
    }
  }, [companyId]);

  const handleEditCompany = (values) => {
    confirm({
      title: 'Editar compañia',
      icon: <ExclamationCircleOutlined />,
      content: '¿Estás seguro que deseas guardar datos modificados?',
      okText: 'Si',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        updateCompany(values);
      },
      onCancel() {},
    });
  };

  const updateCompany = async (values) => {
    setLoadingForm(true);
    values['CompanyId'] = companyId;

    if (isUpdate) {
      const result = await updateCompanyApi(token, values);
      // console.log(result, 'result');
      setLoadingForm(false);
      if (result?.statusCode === 200) {
        form.setFieldsValue({
          CompanyId: result.result.companyId,
          Name: result.result.name,
          RFC: result.result.rfc,
          Phone: result.result.phone,
          Address: result.result.address,
          Email: result.result.email,
        });
        notification['success']({
          message: 'Compañia editada con éxito',
        });
      } else {
        notification['error']({
          message: result.description,
        });
        return false;
      }
    } else {
      const result = await createCompanyApi(values);
      setLoadingForm(false);
      if (result?.statusCode === 200) {
        form.setFieldsValue({
          Name: result.result.name,
          RFC: result.result.rfc,
          Phone: result.result.phone,
          Address: result.result.address,
          Email: result.result.email,
        });
        notification['success']({
          message: messageSuccessEditGeneral('la compañia'),
        });
      } else {
        notification['error']({
          message: result.description,
        });
        return false;
      }
    }
  };

  return (
    <Spin spinning={loadingForm} tip="Guardando...">
      <Form
        name="add-promo"
        layout="vertical"
        form={form}
        onFinish={handleEditCompany}
      >
        <div>
          <Row xs={24} className="table-company">
            <Col xs={20}>
              <Form.Item
                label="Compañia:"
                name="Name"
                rules={[{ required: true }]}
              >
                <Input placeholder="Nombre" />
              </Form.Item>
              <Form.Item label="RFC:" name="RFC" rules={[{ required: true }]}>
                <Input placeholder="RFC" />
              </Form.Item>
              <Form.Item
                label="Teléfono:"
                name="Phone"
                rules={[{ required: true }]}
              >
                <Input placeholder="6622233455" />
              </Form.Item>
              <Form.Item
                label="Dirección:"
                name="Address"
                rules={[{ required: true }]}
              >
                <Input placeholder="Calle San Angel" />
              </Form.Item>
              <Form.Item
                label="Correo electrónico:"
                name="Email"
                rules={[
                  {
                    type: 'email',
                    message: 'El correo electrónico no es válido',
                  },
                  {
                    required: true,
                    message: 'Por favor ingrese un correo electrónico',
                  },
                ]}
              >
                <Input placeholder="correo@ejemplo.com" />
              </Form.Item>
              <Row justify="end">
                {isUpdate && (
                  <Button className="btn-enviar" htmlType="submit">
                    Editar
                  </Button>
                )}
                {!isUpdate && (
                  <Button className="btn-enviar" htmlType="submit">
                    Guardar
                  </Button>
                )}
              </Row>
            </Col>
          </Row>
        </div>
      </Form>
    </Spin>
  );
};

export default CompanyDataDetail;
