import React from 'react';
import { Col, Layout, Row, Typography } from 'antd';
import GreetingsTop from '../../../components/Admin/GreetingsTop/GreetingsTop';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import RFQResponsesTable from '../../../components/Logistic/RfqTable/RFQResponsesTable/RFQResponsesTable';
import BackButton from '../../../components/General/BackButton/BackButton';

export default function SuplierContactsPage() {
  let { rfqId, quoteId } = useParams();

  return (
    <Layout>
      <GreetingsTop />
      <HeaderTop rfqId={rfqId} quoteId={quoteId} />
      <div className="clients__container">
        <RFQResponsesTable />
      </div>
    </Layout>
  );
}

function HeaderTop({ rfqId, quoteId }) {
  const { Title } = Typography;
  return (
    <div className="clients__container">
      <Row xs={23} className="header-top">
        <Col xs={19} className="header-top__left">
          <Title
            level={3}
            style={{
              // position: 'absolute',
              top: 0,
              bottom: 0,
              height: '78%',
              margin: 'auto',
            }}
          >
            Respuestas RFQ #{rfqId} - Cotización #{quoteId}
          </Title>
        </Col>
        <Col xs={5}>
          <Row
            style={{
              marginBottom: 30,
              justifyContent: 'right',
            }}
          >
            <BackButton routeName={'/pricing/rfq'}></BackButton>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
