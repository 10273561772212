import React from 'react';
import { Col, Layout, Row, Typography } from 'antd';
import GreetingsTop from '../../../components/Admin/GreetingsTop/GreetingsTop';
import Quote from '../../../components/Logistic/Quote';
import UnUsedQuotesTable from '../../../components/Logistic/UnUsedQuotes/UnUsedQuotesTable';

export default function UnUsedQuotesPage() {
  return (
    <Layout>
      <GreetingsTop />

      <HeaderTop />

      <div className="billing__container">
        <UnUsedQuotesTable/>
      </div>
    </Layout>
  );
}

function HeaderTop() {
  const { Title } = Typography;
  return (
    <div className="billing__container">
      <Row className="header-top">
        <Col xs={24} md={12} className="header-top__left">
          <Title level={3}>Cotizaciones no utilizadas</Title>
        </Col>
        <Col
          xs={20}
          md={12}
          style={{
            display: 'grid',
            alignItems: 'center',
            justifyContent: 'end',
          }}
        >
        </Col>
      </Row>
    </div>
  );
}
