import React, { useEffect, useReducer, useState } from 'react';
import {
  Button,
  Col,
  Input,
  Row,
  Select,
  Form,
  Modal as ModalAntd,
  Tooltip,
  InputNumber,
  message,
  DatePicker,
  Checkbox,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { catalogQouteReducer } from '../../../../../reducers/catalogsQoute/catalogQouteReducer';
import { getCatalogValueReducerHandle } from '../../../../../helpers/handlerFunctionApi';

import {
  UserSwitchOutlined,
  ClearOutlined,
  ExclamationCircleOutlined,
  FileSearchOutlined,
} from '@ant-design/icons';
import {
  ADUANA_VALUE_ID,
  AEREO_VALUE_ID,
  CITY_CATALOG_ID,
  COORDLOG_VALUE_ID,
  HAZMAT_CATALOG_ID,
  HAZMAT_VALUE_ID,
  INCOTERM_CATALOG_ID,
  INSURANCE_CATALOG_ID,
  MARITIMO_VALUE_ID,
  OPERACIONES_CATALOG_ID,
  SERVICES_CATALOG_ID,
  STATE_CATALOG_ID,
  TERRESTRE_VALUE_ID,
  TYPE_OF_LOAD_CATALOG_ID,
  TYPE_OF_SERVICE_CATALOG_ID,
  UNIT_TYPE_CATALOG_ID,
} from '../../../../../helpers/constants';
import { types } from '../../../../../types/types';
import { METHOD_GET, ROL_MANAGER } from '../../../../../utils/constants';
import { API_URL_CATALOG_VALUES } from '../../../../../helpers/urls';
import { fetchApi } from '../../../../../helpers/fetch';
import { getAccessTokenApi } from '../../../../../api/auth';
import useUser from '../../../../../hooks/useUser';
import Route from '../../../Quote/AddQuoteForm/LineForm/Route/Route';

const initialStateQT = {
  typeOfLoad: [],
  operations: [],
  hazMat: [],
  typeOfService: [],
  unit: [],
  incoterm: [],
  insurance: [],
};

const OperationHeader = (props) => {
  const {
    service,
    setService,
    disableAllInputs,
    handleSaveOperation,
    validationOperationField,
    showModal,
    generalOperationForm,
    quoteSelected,
    type,
    disabledInputs,
    setDisabledInputs,
    clearFilterOperation,
    clearHeader,
    operationId,
    hazMatAvailable,
    routes,
    clientSelectedOperation,
    allRoutes,
    setRouteSelected,
    delivered,
    statusSelected,
    setStatusSelected,
    statusInitial,
    routeCity,
    operationValues,
  } = props;
  const [typeOfload, setTypeOfload] = useState(0);
  const [filterQuote, setFilterQuote] = useState({});
  const [catalogs, dispatch] = useReducer(catalogQouteReducer, initialStateQT);
  const [loadingForm, setLoadingForm] = useState(false);
  const [serviceCatalog, setServiceCatalog] = useState([]);
  const [availableUnitChecked, setAvailableUnitChecked] = useState(false);
  const token = getAccessTokenApi();
  const [disableInputsSelected, setDisableInputsSelected] = useState(
    quoteSelected === null ? false : true
  );
  const [isCancelled, SetIsCancelled] = useState(false);
  const { confirm } = ModalAntd;
  let [statusCatalog, setStatusCatalog] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  console.log(operationValues,'operationValues');
  useEffect(() => {
    if (service === TERRESTRE_VALUE_ID || service === COORDLOG_VALUE_ID) {
      setStatusCatalog([
        { value: 0, label: 'PROGRAMADO', enabled: [0] },
        { value: 4, label: 'CARGANDO', enabled: [0, 4] },
        { value: 1, label: 'EN TRÁNSITO', enabled: [1, 4] },
        { value: 5, label: 'DESCARGADO', enabled: [1, 5] },
        { value: 2, label: 'ENTREGADO', enabled: [2, 5] },
        { value: 3, label: 'CANCELADO', enabled: [0, 3, 4] },
      ]);
    } else if (service !== TERRESTRE_VALUE_ID) {
      setStatusCatalog([
        { value: 0, label: 'PROGRAMADO', enabled: [0] },
        { value: 7, label: 'RESERVADO', enabled: [0, 7] },
        { value: 1, label: 'EN TRÁNSITO', enabled: [1, 7] },
        { value: 6, label: 'ARRIVADO', enabled: [1, 6] },
        { value: 2, label: 'ENTREGADO', enabled: [2, 6] },
        { value: 3, label: 'CANCELADO', enabled: [1, 7] },
      ]);
    }
  }, [service]);
  const { person } = useUser();
  const [formm] = Form.useForm();
  const success = () => {
    message.success('This is a success message');
  };
  const onFinish = (values) => {
    console.log('Success:', values);
    success();
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  const onChangeSelect = (value, type) => {
    setFilterQuote({
      ...filterQuote,
      [type]: value,
    });
  };
  useEffect(() => {
    setLoadingForm(true);
    const config = {
      method: METHOD_GET,
      url: `${API_URL_CATALOG_VALUES}/GetAll/${STATE_CATALOG_ID}`,
      data: null,
      token,
      // showNotification: true
    };
    fetchApi(config)
      .then((response) => {
        if (response) {
          let calogsValues = response.result;

          if (calogsValues) {
            calogsValues.map((cat, index) => {
              cat['key'] = index;
              cat['label'] = cat.description;
              cat['value'] = cat.id;
              return cat;
            });
          }
          setStates(calogsValues);
        }
        setLoadingForm(false);
      })
      .catch((err) => {
        console.log(err);
      });

    //   getStateCatalogHandle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setLoadingForm(true);
    const config = {
      method: METHOD_GET,
      url: `${API_URL_CATALOG_VALUES}/GetAll/${CITY_CATALOG_ID}`,
      data: null,
      token,
      // showNotification: true
    };
    fetchApi(config)
      .then((response) => {
        if (response) {
          let calogsValues = response.result;

          if (calogsValues) {
            calogsValues.map((cat, index) => {
              cat['key'] = index;
              cat['label'] = cat.description;
              cat['value'] = cat.id;
              return cat;
            });
          }
          setCities(calogsValues);
        }
        setLoadingForm(false);
      })
      .catch((err) => {
        console.log(err);
      });

    //   getStateCatalogHandle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    SetIsCancelled(statusSelected === 3);
  }, [statusSelected]);
  useEffect(() => {
    setFilterQuote({});
    setTypeOfload(0);
    setDisableInputsSelected(false);
  }, [clearHeader]);
  useEffect(() => {
    if (quoteSelected !== null) {
      setDisableInputsSelected(true);
    } else {
      setDisableInputsSelected(false);
    }
  }, [quoteSelected]);
  useEffect(() => {
    getCatalogValueReducerHandle(
      INCOTERM_CATALOG_ID,
      dispatch,
      setLoadingForm,
      0,
      types.getIncoterm
    );
    getCatalogValueReducerHandle(
      INSURANCE_CATALOG_ID,
      dispatch,
      setLoadingForm,
      0,
      types.getInsurance
    );
    getCatalogValueReducerHandle(
      TYPE_OF_LOAD_CATALOG_ID,
      dispatch,
      setLoadingForm,
      0,
      types.getTypeOfLoad
    );
    getCatalogValueReducerHandle(
      HAZMAT_CATALOG_ID,
      dispatch,
      setLoadingForm,
      0,
      types.getHazMat
    );
    getCatalogValueReducerHandle(
      OPERACIONES_CATALOG_ID,
      dispatch,
      setLoadingForm,
      0,
      types.getOperations
    );
    getCatalogValueReducerHandle(
      TYPE_OF_SERVICE_CATALOG_ID,
      dispatch,
      setLoadingForm,
      0,
      types.getTypeOfService
    );
    getCatalogValueReducerHandle(
      UNIT_TYPE_CATALOG_ID,
      dispatch,
      setLoadingForm,
      0,
      types.getUnity
    );
  }, []);
  useEffect(() => {
    setLoadingForm(true);
    const config = {
      method: METHOD_GET,
      url: `${API_URL_CATALOG_VALUES}/GetAll/${SERVICES_CATALOG_ID}`,
      data: null,
      token,
      // showNotification: true
    };
    fetchApi(config)
      .then((response) => {
        if (response) {
          let calogsValues = response.result;

          if (calogsValues) {
            calogsValues.map((cat, index) => {
              cat['key'] = index;
              cat['label'] = cat.description;
              cat['value'] = cat.id;
            });
          }
          setServiceCatalog(calogsValues);
        }
        setLoadingForm(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  function showConfirm(type) {
    let title = '';
    switch (type) {
      case 'all':
        title = 'Estás seguro que deseas limpiar las opciones';
        break;

      case 'supplier':
        title = 'Estás seguro que deseas limpiar la operación seleccionada';
        break;

      default:
        break;
    }
    confirm({
      title: title,
      icon: <ExclamationCircleOutlined />,
      content: 'No podrá deshacer esta opción',
      okText: 'Si, limpiar',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk() {
        clearFilterOperation();
      },
    });
  }
  const onstatusChange = (value) => {
    setStatusSelected(value);
    SetIsCancelled(value === 3);
  };
  return (
    <div>
      <Form
        name="add-quote"
        layout="vertical"
        form={generalOperationForm}
        onFinish={handleSaveOperation}
        onFinishFailed={validationOperationField}
      >
        <div>
          <Row gutter={16} justify="space-between">
            <Col md={14} xs={24}>
              {/* {type && ( */}
              <Form.Item label="Id. operación:" name="operationIdStr">
                <Input disabled />
              </Form.Item>
              <Form.Item label="Id. operación:" name="id" hidden>
                <Input disabled />
              </Form.Item>
              {/* )} */}
            </Col>

            <Col md={8} xs={24}>
              {/* {JSON.stringify(statusSelected)} */}
              <Form.Item label="Estatus:" name="status">
                <Select
                  disabled={disableAllInputs || delivered || type !== 'Edit'}
                  placeholder="Selecciona una opción"
                  showSearch
                  optionFilterProp="children"
                  initialvalues={type !== 'Edit' ? 0 : ''}
                  onChange={(value) => {
                    onstatusChange(value);
                  }}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {statusCatalog.map((s) => (
                    <Select.Option
                      key={s.value}
                      value={s.value}
                      disabled={!s.enabled.includes(statusInitial)}
                    >
                      {s.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          {/* ROW2 */}
          <Row
            gutter={16}
            style={{
              marginBottom: 10,
            }}
            justify={'space-between'}
          >
            <Col md={4} lg={4} xs={24}>
              <Button
                style={{ marginBottom: 5 }}
                type="primary"
                icon={<UserSwitchOutlined />}
                disabled={
                  disableAllInputs || disableInputsSelected || operationId
                }
                onClick={() => showModal('Clients')}
              >
                Cliente
              </Button>
            </Col>
            <Col md={20} xs={24}>
              <Form.Item
                name="customer"
                rules={[
                  {
                    required: true,
                    message: 'Por favor ingrese un cliente',
                  },
                ]}
              >
                <Input placeholder="Ingrese información" disabled />
              </Form.Item>
              <Form.Item
                name="customerId"
                hidden
                rules={[
                  {
                    required: true,
                    message: 'Por favor ingrese un cliente',
                  },
                ]}
              >
                <Input placeholder="Ingrese información" disabled />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col md={6} xs={24}>
              <Form.Item
                label="Operación:"
                name="operation"
                // hidden={service !== TERRESTRE_VALUE_ID}
                rules={[
                  {
                    required: true,
                    message: 'Por favor ingrese una operación',
                  },
                ]}
              >
                <Select
                  disabled={disableAllInputs || delivered}
                  placeholder="Selecciona una opción"
                  showSearch
                  optionFilterProp="children"
                  onChange={(value) => {
                    onChangeSelect(value, 'operation');
                  }}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {catalogs.operations.map((s) => (
                    <Select.Option key={s.value} value={s.value}>
                      {s.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col md={6} xs={24}>
              <Form.Item
                label="Tipo de carga:"
                name="loadType"
                rules={[
                  {
                    required: true,
                    message: 'Por favor ingrese tipo de carga',
                  },
                ]}
              >
                <Select
                  placeholder="Selecciona una opción"
                  disabled={disableInputsSelected || disableAllInputs}
                  // onChange={(value) => setTypeOfload(value)}
                  onChange={(value) => {
                    setTypeOfload(value);
                    onChangeSelect(value, 'typeOfLoad');
                  }}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {catalogs.typeOfLoad.map((s) => (
                    <Select.Option key={s.value} value={s.value}>
                      {s.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {service === MARITIMO_VALUE_ID &&
              statusSelected !== 0 &&
              statusSelected !== 7 && (
                <>
                  <Col md={6} xs={24}>
                    <Form.Item
                      label="VESSEL:"
                      name="Vessel"
                      rules={[
                        {
                          required: true,
                          message: 'Por favor ingrese VESSEL',
                        },
                      ]}
                    >
                      <Input
                        maxLength={15}
                        style={{ width: '100%' }}
                        placeholder="Ingrese información"
                        disabled={disableAllInputs || delivered}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={6} xs={24}>
                    <Form.Item
                      label="VOYAGE:"
                      name="Voyage"
                      rules={[
                        {
                          required: true,
                          message: 'Por favor ingrese VOYAGE',
                        },
                      ]}
                    >
                      <Input
                        maxLength={15}
                        style={{ width: '100%' }}
                        placeholder="Ingrese información"
                        disabled={disableAllInputs || delivered}
                      />
                    </Form.Item>
                  </Col>
                </>
              )}
            {service === AEREO_VALUE_ID && (
              <>
                <Col md={6} xs={24}>
                  <Form.Item
                    label="MAWB:"
                    name="MAWB"
                    rules={[
                      {
                        required: true,
                        message: 'Por favor ingrese MAWB',
                      },
                    ]}
                  >
                    <Input
                      placeholder="Ingrese información"
                      disabled={disableAllInputs || delivered}
                    />
                  </Form.Item>
                </Col>
                {statusSelected !== 0 && statusSelected !== 7 && (
                  <Col md={6} xs={24}>
                    <Form.Item
                      label="MBL:"
                      name="MBL"
                      rules={[
                        {
                          required: true,
                          message: 'Por favor ingrese MBL',
                        },
                      ]}
                    >
                      <Input
                        placeholder="Ingrese información"
                        disabled={disableAllInputs || delivered}
                      />
                    </Form.Item>
                  </Col>
                )}
              </>
            )}
            {(typeOfload === HAZMAT_VALUE_ID || hazMatAvailable) && (
              <Col xl={10} lg={12} md={12} xs={24}>
                <Row gutter={16}>
                  <Col xs={24} md={16}>
                    <Form.Item
                      label="HAZ MAT:"
                      name="hazMat"
                      rules={[
                        {
                          required: typeOfload === HAZMAT_VALUE_ID,
                          message: 'Por favor ingrese HAZ MAT',
                        },
                      ]}
                    >
                      <Select
                        disabled={
                          typeOfload !== HAZMAT_VALUE_ID ||
                          disableInputsSelected
                        }
                        placeholder=""
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(value) => {
                          onChangeSelect(value, 'hazMat');
                        }}
                      >
                        {catalogs.hazMat.map((s) => (
                          <Select.Option key={s.value} value={s.value}>
                            {s.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={8}>
                    <Form.Item
                      name="hazMatDescription"
                      label="Descripción:"
                      rules={[
                        {
                          required: typeOfload === HAZMAT_VALUE_ID,
                          message: 'Por favor ingrese HAZ MAT',
                        },
                      ]}
                    >
                      <Input
                        disabled={
                          typeOfload !== HAZMAT_VALUE_ID ||
                          disableInputsSelected
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
          {statusSelected === 2 && (service === MARITIMO_VALUE_ID || service === AEREO_VALUE_ID) && (
            <Row gutter={16} justify="end">
              <Col md={1} lg={1} xs={2}>
                <Button
                  style={{ marginTop: 30 }}
                  type="primary"
                  icon={<FileSearchOutlined />}
                  disabled={disableAllInputs || delivered}
                  onClick={() => showModal('Operations')}
                ></Button>
              </Col>
              <Col md={6} xs={22}>
                <Form.Item
                  label="Referencia terrestre:"
                  name="inlandReference"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor ingrese Referencia terrestre',
                    },
                  ]}
                >
                  <Input placeholder="Ingrese información" disabled />
                </Form.Item>
              </Col>
            </Row>
          )}
          {service === MARITIMO_VALUE_ID && (
            <Row gutter={16}>
              {statusSelected !== 0 && (
                <>
                  <Col md={6} xs={24}>
                    <Form.Item
                      label="No. Reservación:"
                      name="NoReservation"
                      rules={[
                        {
                          required: true,
                          message: 'Por favor ingrese No. Reservación',
                        },
                      ]}
                    >
                      <InputNumber
                        style={{ width: '100%' }}
                        placeholder="Ingrese información"
                        disabled={disableAllInputs || delivered}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={10} lg={6}>
                    <Form.Item
                      label="Fecha de reservación:"
                      name="reservationDate"
                      rules={[
                        {
                          required: true,
                          message: 'Por favor ingresar Fecha de reservación:',
                        },
                      ]}
                    >
                      <DatePicker
                        format={'DD/MM/YYYY'}
                        style={{ width: '100%' }}
                        disabled={disableAllInputs || delivered}
                      />
                    </Form.Item>
                  </Col>
                </>
              )}

              {statusSelected !== 0 && statusSelected !== 7 && (
                <Col md={6} xs={24}>
                  <Form.Item
                    label="MBL:"
                    name="MBL"
                    rules={[
                      {
                        required: true,
                        message: 'Por favor ingrese MBL',
                      },
                    ]}
                  >
                    <Input
                      placeholder="Ingrese información"
                      disabled={disableAllInputs || delivered}
                    />
                  </Form.Item>
                </Col>
              )}
            </Row>
          )}
          {operationValues.availableUnitCreated !== true && service === TERRESTRE_VALUE_ID &&(
             <Row gutter={16}>
             {statusSelected !== 0 && statusSelected !== 4 && statusSelected !== 7 && (
                     <Col xs={4} md={4}>
                       <Form.Item name="availableUnit" valuePropName="checked">
                         <Checkbox
                           onChange={(e) => setAvailableUnitChecked(e.target.checked)}
                           disabled={disableAllInputs || delivered}
                           checked={availableUnitChecked}
                         >
                           Disponible:
                         </Checkbox>
                       </Form.Item>
                     </Col>
                   )}
             {availableUnitChecked === true && (
               <>
               <Col xs={12} md={6} lg={6}>
                       <Form.Item
                         label="Estado:"
                         name="availableUnitDestinationStateId"
                         rules={[
                           {
                             required: true,
                             message: 'Por favor ingrese un estado',
                           },
                         ]}
                       >
                         <Select
                           disabled={disableAllInputs || delivered}
                           placeholder="Selecciona ruta"
                           showSearch
                           optionFilterProp="children"
                           filterOption={(input, option) =>
                             option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                           }
                         >
                           {states.map((s) => (
                             <Select.Option key={s.value} value={s.value}>
                               {s.label}
                             </Select.Option>
                           ))}
                         </Select>
                       </Form.Item>
                     </Col>
                     <Col xs={12} md={6} lg={6}>
                       <Form.Item
                         label="Ciudad:"
                         name="availableUnitDestinationCityId"
                         rules={[
                           {
                             required: true,
                             message: 'Por favor ingrese una ciudad',
                           },
                         ]}
                       >
                         <Select
                           disabled={disableAllInputs || delivered}
                           placeholder="Selecciona ruta"
                           showSearch
                           optionFilterProp="children"
                           filterOption={(input, option) =>
                             option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                           }
                         >
                           {cities.map((s) => (
                             <Select.Option key={s.value} value={s.value}>
                               {s.label}
                             </Select.Option>
                           ))}
                         </Select>
                       </Form.Item>
                     </Col>
               </>
             )}
             </Row>
          )}
         
          
          <Row gutter={16}>
            <Col md={8} xs={24}>
              <Form.Item
                label="Servicio:"
                name="service"
                rules={[
                  {
                    required: true,
                    message: 'Por favor ingrese servicio',
                  },
                ]}
              >
                <Select
                  disabled={disableAllInputs || disableInputsSelected}
                  placeholder="Selecciona una opción"
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(value) => {
                    setService(value);
                    onChangeSelect(value, 'service');
                  }}
                >
                  {serviceCatalog.map((s) => (
                    <Select.Option key={s.value} value={s.value}>
                      {s.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {service !== ADUANA_VALUE_ID && (
              <Col md={8} xs={24}>
                <Form.Item
                  label="Tipo de servicio:"
                  name="typeOfService"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor ingrese un tipo de servicio',
                    },
                  ]}
                >
                  <Select
                    disabled={disableAllInputs || disableInputsSelected}
                    placeholder="Selecciona una opción"
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(value) => {
                      onChangeSelect(value, 'typeOfService');
                    }}
                  >
                    {catalogs.typeOfService.map((s) => (
                      <Select.Option key={s.value} value={s.value}>
                        {s.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
            <Col md={8} xs={24}>
              <Form.Item label="Incoterm:" name="incoterm">
                <Select
                  disabled={disableAllInputs || delivered}
                  placeholder="Selecciona una opción"
                  showSearch
                  optionFilterProp="children"
                  onChange={(value) => {}}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {catalogs.incoterm.map((s) => (
                    <Select.Option key={s.value} value={s.value}>
                      {s.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {service === ADUANA_VALUE_ID && (
              <Col md={8} xs={24}>
                <Row>
                  <Form.Item
                    label="Aduana:"
                    name="type"
                    rules={[
                      {
                        required: true,
                        message: 'Por favor ingrese aduana',
                      },
                    ]}
                  >
                    <Select
                      placeholder="Selecciona una opción"
                      disabled={disableAllInputs || disableInputsSelected}
                    />
                  </Form.Item>
                </Row>
              </Col>
            )}
            <Form.Item name="quoteLineId" hidden></Form.Item>
          </Row>
          {/* ROW3 */}
          <Route
            disableAllInputs={disableAllInputs}
            service={service}
            setLoadingForm={setLoadingForm}
            form={generalOperationForm}
            type="show"
            isOperation
            disableInputsSelected={disableInputsSelected}
            routes={routes}
            clientSelectedQuote={clientSelectedOperation}
            allRoutes={allRoutes}
            setRouteSelected={setRouteSelected}
            delivered={delivered}
            typeEdit={type}
            statusSelected={statusSelected}
            routeCity={routeCity}
          />
          {/* <Rute
            disableAllInputs={disableAllInputs}
            service={service}
            setLoadingForm={setLoadingForm}
            form={generalOperationForm}
            type="show"
            isOperation
            disableInputsSelected={disableInputsSelected}
          /> */}

          <Row>
            <Col md={14} xs={24}>
              <Row gutter={16} style={{ width: '100%' }}>
                <Col md={8} lg={6} xs={24}>
                  <Button
                    style={{ marginBottom: 5 }}
                    type="primary"
                    icon={<UserSwitchOutlined />}
                    disabled={disableAllInputs || disableInputsSelected}
                    onClick={() => showModal('Quotes')}
                  >
                    Cotización
                  </Button>
                </Col>

                <Col md={8} xs={24}>
                  <Form.Item
                    hidden
                    name="quoteId"
                    rules={[
                      {
                        required: true,
                        message: 'Por favor ingrese una cotización',
                      },
                    ]}
                  >
                    <Input placeholder="Ingrese información" disabled />
                  </Form.Item>
                  <Form.Item
                    name="quoteIdStr"
                    rules={[
                      {
                        required: true,
                        message: 'Por favor ingrese una cotización',
                      },
                    ]}
                  >
                    <Input placeholder="Ingrese información" disabled />
                  </Form.Item>
                </Col>
                <Col md={2} xs={24}>
                  <Tooltip title={'Limpiar opciones'}>
                    <Button
                      style={{
                        marginBottom: 5,
                        backgroundColor: '#316578',
                        color: 'white',
                      }}
                      icon={<ClearOutlined />}
                      disabled={disableAllInputs || delivered}
                      onClick={() => showConfirm('all')}
                    ></Button>
                  </Tooltip>
                </Col>
              </Row>
              <Col md={15} xs={24}>
                <Form.Item label="Comentarios:" name="comments">
                  <TextArea
                    placeholder="Ingresa comentario"
                    disabled={disableAllInputs}
                  />
                </Form.Item>
              </Col>
              {isCancelled && (
                <Col md={15} xs={24}>
                  <Form.Item
                    label="Motivo de la cancelación :"
                    name="cancellationreason"
                    rules={[
                      {
                        required: true,
                        message:
                          'Por favor ingrese el Motivo de la  cancelación ',
                      },
                    ]}
                  >
                    <TextArea
                      style={{
                        backgroundColor: '#ffd7d7',
                      }}
                      placeholder="Ingresa el motivo"
                      disabled={disableAllInputs}
                    />
                  </Form.Item>
                </Col>
              )}
            </Col>
            <Col md={10} xs={24}>
              {type && (
                <Form.Item label="Ejecutivo:" name="lastUpdateBy">
                  <Input
                    placeholder="Ingrese información"
                    disabled={disableAllInputs || delivered}
                  />
                </Form.Item>
              )}
              <Form.Item label="Seguro:" name="insurance">
                <Select
                  disabled={disableAllInputs || delivered}
                  placeholder="Selecciona una opción"
                  showSearch
                  optionFilterProp="children"
                  onChange={(value) => {
                    setDisabledInputs(false);
                  }}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {catalogs.insurance.map((s) => (
                    <Select.Option key={s.value} value={s.value}>
                      {s.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label="Factura:" name="invoice">
                <Input
                  placeholder="Ingrese información"
                  disabled={disabledInputs || disableAllInputs || delivered}
                />
              </Form.Item>

              <Form.Item label="Monto asegurado:" name="insuranceAmount">
                <InputNumber
                  style={{ width: '100%' }}
                  placeholder="Ingrese información"
                  min={0}
                  formatter={(value) =>
                    `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                  disabled={
                    disabledInputs ||
                    (type === 'Edit' &&
                      ![ROL_MANAGER].some((r) => person?.roles.includes(r))) ||
                    disableAllInputs
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Form>
    </div>
  );
};

export default OperationHeader;
