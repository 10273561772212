import React, { useState } from 'react';
import { Form, Button, Row, Col } from 'antd';
import { FileAddOutlined, FileExcelOutlined } from '@ant-design/icons';
import Modal from '../../../General/Modal';
import UploadFileForm from '../UploadFileForm';
import AddPaymentForm from '../AddPaymentForm/AddPaymentForm';
import FileProducts from '../../FileProducts/FileProducts';
import useUser from '../../../../hooks/useUser';
import { ROL_LAYOUT, ROL_SUPPLIER } from '../../../../utils/constants';

import './PaymentFilters.scss';

export default function PaymentFilters() {
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [titleModal, setTitleModal] = useState(false);
  const [contextModal, setContextModal] = useState(null);
  const { person } = useUser();

  const layout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 14 },
  };
  const tailLayout = {
    wrapperCol: { offset: 0, span: 24 },
  };

  const [form] = Form.useForm();

  const uploadExcel = () => {
    setModalIsVisible(true);
    setTitleModal('Subir Layout - Complemento de pago');
    setContextModal(
      <FileProducts setIsVisible={setModalIsVisible} type="payment" />
    );
  };

  const showModal = (
    type,
    billingFields,
    fileXml,
    filePdf,
    fileCfdi,
    nameXml
  ) => {
    if (type === 'addPayment') {
      setModalIsVisible(true);
      setTitleModal('Complemento de Pago');
      setContextModal(
        <UploadFileForm
          setModalIsVisible={setModalIsVisible}
          showModal={showModal}
          type={type}
        />
      );
    } else if (type === 'addExcel') {
      uploadExcel();
    } else {
      setModalIsVisible(true);
      setTitleModal('');
      setContextModal(
        <AddPaymentForm
          setModalVisible={setModalIsVisible}
          billingFields={billingFields}
          fileXml={fileXml}
          filePdf={filePdf}
          fileCfdi={fileCfdi}
          nameXml={nameXml}
          // setReloadBills={setReloadBills}
        ></AddPaymentForm>
      );
    }
  };

  return (
    <>
      <Form form={form} {...layout} name="PaymentFilters">
        {/* <Col xs={24} md={16}>
                        <Row>
                            <Col xs={24} md={24} lg={12}>
                                <Form.Item label="Fecha de recepción">
                                    <DatePicker style={{ width: '100%' }} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col> */}
        {/* <Row>
                            <Col xs={24}>
                                <Form.Item
                                    style={{ textAlign: 'right' }}
                                    {...tailLayout}
                                >
                                    <Button
                                        className="biller-btn"
                                        icon={<SearchOutlined />}
                                    >
                                        Buscar
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row> */}

        <Row
          style={{
            // display: '-webkit-inline-box',
            alignItems: 'center',
            justifyContent: 'end',
          }}
        >
          {[ROL_SUPPLIER].some((r) => person.roles.includes(r)) && (
            <Col md={24} xs={24}>
              <Form.Item
                // label="Agregar factura o nota de crédito:"
                style={{ textAlign: 'right' }}
                {...tailLayout}
              >
                <Button
                  onClick={() => showModal('addPayment')}
                  className="payment-btn__add"
                  icon={<FileAddOutlined />}
                >
                  Agregar
                </Button>
              </Form.Item>
            </Col>
          )}
          {[ROL_LAYOUT].some((r) => person.roles.includes(r)) && (
            <Col md={24} xs={24}>
              <Form.Item
                // label="Subir Excel"
                {...tailLayout}
                style={{ textAlign: 'right' }}
              >
                <Button
                  onClick={() => showModal('addExcel')}
                  className="biller-btn"
                  icon={<FileExcelOutlined />}
                >
                  Subir Excel
                </Button>
              </Form.Item>
            </Col>
          )}
        </Row>

        {/* <Row
                    style={{
                        display: '-webkit-inline-box',
                        alignItems: 'center',
                        justifyContent: 'end',
                    }}
                >
                    <Col xs={24}>
                        <Form.Item
                            style={{ textAlign: 'right' }}
                            {...tailLayout}
                        >
                            <Button
                                onClick={() => showModal('addPayment')}
                                className="payment-btn__add"
                                icon={<FileAddOutlined />}
                            >
                                Agregar
                            </Button>
                        </Form.Item>
                    </Col>
                </Row> */}
        <Modal
          title={titleModal}
          visible={modalIsVisible}
          setIsVisible={setModalIsVisible}
          width={1000}
        >
          {contextModal}
        </Modal>
      </Form>
    </>
  );
}
