import React from 'react';
import { Button, Col, Layout, Row, Typography } from 'antd';
import GreetingsTop from '../../../components/Admin/GreetingsTop/GreetingsTop';
import { useHistory } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import CustomersAdminTable from '../../../components/Admin/Customers/CustomersAdminTable/CustomersAdminTable';

export default function CustomersAdmin() {
  return (
    <Layout>
      <GreetingsTop />

      <HeaderTop />

      <div className="clients__container">
        <CustomersAdminTable />
      </div>
    </Layout>
  );
}

function HeaderTop() {
  const { Title } = Typography;
  let history = useHistory();

  return (
    <div className="clients__container">
      <Row xs={23} className="header-top">
        <Col xs={19} className="header-top__left">
          <Title level={3}>Clientes</Title>
        </Col>
        <Col xs={1}>
          <Button type="link" onClick={() => history.goBack()}>
            <ArrowLeftOutlined /> Atrás
          </Button>
        </Col>
      </Row>
    </div>
  );
}
