import React from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.css';
import './index.css';
import App from './App';
// import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register();

// serviceWorkerRegistration.register({
//     onSuccess: () => console.log('instalacion correcta'),
//     onUpdate: (reg) => updateServiceWorker(reg),
// });

// const updateServiceWorker = (reg) => {
//     const registrationWaiting = reg.waiting;
//     if (registrationWaiting) {
//         registrationWaiting.postMessage({ type: 'SKIP_WAITING' });
//         registrationWaiting.addEventListener('statechange', (e) => {
//             if (e.target.state === 'activated') {
//                 window.location.reload();
//             }
//         });
//     }
// };

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
