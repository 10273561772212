import React from 'react';
import { Col, Layout, Row, Typography } from 'antd';
import GreetingsTop from '../../../../components/Admin/GreetingsTop/GreetingsTop';
import useUser from '../../../../hooks/useUser';
import SupplierOperationsTable from '../../../../components/Admin/Suppliers/SupplierOperations/SupplierOperations';

export default function SuplierOperations() {
  const { person } = useUser();

  return (
    <Layout>
      <GreetingsTop />

      <HeaderTop person={person} />

      <div className="clients__container">
        <SupplierOperationsTable />
      </div>
    </Layout>
  );
}

function HeaderTop({ person }) {
  const { Title } = Typography;

  return (
    <div className="clients__container">
      <Row xs={23} className="header-top">
        <Col xs={19} className="header-top__left">
          <Title level={3}>Operaciones {person.name}</Title>
        </Col>
      </Row>
    </div>
  );
}
