import React from 'react';
import { Col, Layout, Row, Typography } from 'antd';
import GreetingsTop from '../../../components/Admin/GreetingsTop/GreetingsTop';
// import { useParams } from 'react-router-dom';
import AvailableUnitsTable from '../../../components/Logistic/AvailableUnits/AvailableUnitsTable';

export default function AvailableUnitsPage() {
//   let { invoiceId, type, folio } = useParams();

  return (
    <Layout>
      <GreetingsTop />
      <HeaderTop />
      <div className="billing__container">
        <AvailableUnitsTable/>
      </div>
    </Layout>
  );
}

function HeaderTop() {
  const { Title } = Typography;

  return (
    <div className="billing__container">
    <Row className="header-top">
      <Col xs={24} md={12} className="header-top__left">
        <Title level={3}>Unidades disponibles</Title>
      </Col>
    </Row>
  </div>
  );
}
