import { basePath } from './config';


//GET DOCUMENTS OPERATIONS
export function getDocumentsInfoApi(token, data) {
    const url = `${basePath}/Documents/GetDocumentsInfo`;
    const params = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {});
}

//POST DOCUMENT OPERATIONS
export function addDocumentsApi(token, data) {
    const url = `${basePath}/APInvoice/AttachInvoiceFiles`;
    const params = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {});
}
//POST DOCUMENT OPERATIONS
export function deleteDocumentApi(token, data) {
    const url = `${basePath}/Documents/DeleteDocument`;
    const params = {
        method: 'DELETE',
        body: JSON.stringify(data),
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {});
}
//POST COMMENT OPERATIONS
export function addCommentApi(token, data) {
    const url = `${basePath}/comments`;
    const params = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {});
}
//GET COMMENT OPERATIONS
export function getCommentsApi(token, id) {
    const url = `${basePath}/comments?documentId=${id}`;
    const params = {
        method: 'GET',
        // body: JSON.stringify(data),
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {});
}

//CLARIFICATIONS DOCUMENTS

export function addDocumentsClarificationApi(token, data) {
    const url = `${basePath}/note-details/attach-files`;
    const params = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {});
}