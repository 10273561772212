/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  Form,
  Input,
  Col,
  Row,
  Button,
  Divider,
  DatePicker,
  Modal as ModalAntd,
  notification,
  Spin,
  Select,
  Alert,
  Checkbox,
  Typography,
  Tag,
} from 'antd';
import moment from 'moment';
import {
  UserSwitchOutlined,
  ExclamationCircleOutlined,
  SendOutlined,
  FileAddOutlined,
  InfoCircleOutlined,
  SaveOutlined,
  DiffOutlined,
} from '@ant-design/icons';
import { pdf, PDFDownloadLink } from '@react-pdf/renderer';
import { useHistory } from 'react-router-dom';
import TextArea from 'antd/lib/input/TextArea';
import Modal from '../../../General/Modal';
import LineForm from './LineForm/LineForm';
import CustomersTable from '../../../PortalClients/Customers/CustomersTable';
import {
  METHOD_GET,
  METHOD_POST,
  METHOD_PUT,
} from '../../../../utils/constants';
import { getAccessTokenApi } from '../../../../api/auth';
import { fetchApi } from '../../../../helpers/fetch';
import SuppliersTable from '../../../Admin/Suppliers/SuppliersTable/SuppliersTable';
import RFQResponsesTable from '../../../Logistic/RfqTable/RFQResponsesTable/RFQResponsesTable';
import AdditionalServicesTable from '../../../Logistic/Operations/AddEditOperationForm/OperationLine/AdditionalServicesTable';
import PricingTable from '../../PricingTable/PricingTable';
import {
  API_URL_QUOTE_VALIDATE_LINE,
  API_URL_QUOTE_PDF,
  API_URL_QUOTE,
  API_SEND_QUOTE_EMAIL,
} from '../../../../helpers/urls';
import useUser from '../../../../hooks/useUser';
import { formatMoney, isMobile } from '../../../../utils/general';

import './AddQuoteForm.scss';
import { QuotePdf } from '../QuotePdf/QuotePdf';
import AddAdditionalService from '../../Operations/AddEditOperationForm/OperationLine/AddAdditionalService/AddAdditionalService';
import FilterAdditionalServices from './FilterAdditionalServices';

const { Text } = Typography;

const AddQuoteForm = (props) => {
  const {
    setModalIsVisible: setModalQuoteIsVisible,
    type,
    quoteId,
    setReloadTable,
  } = props;
  const [loadingForm, setLoadingForm] = useState(false);
  const [generalForm] = Form.useForm();
  const [linesForm] = Form.useForm();
  const [newLineForm] = Form.useForm();
  const { RangePicker } = DatePicker;
  const [pieces, setPieces] = useState([]);
  const [additionalServices, setAdditionalServices] = useState([]);
  const [disableAllInputs, setDisableAllInputs] = useState(
    type === 'watch' ? true : false
  );
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [titleModal, setTitleModal] = useState(false);
  const [contextModal, setContextModal] = useState(null);
  const { confirm } = ModalAntd;
  // const [quoteState, setQuoteState] = useState([]);
  const [linesData, setLinesData] = useState([]);
  const [saveForm, setSaveForm] = useState([]);
  const [banderita, setBanderita] = useState(true);
  const [statusQuote, setStatusQuote] = useState('active');
  const [saveKey, setSaveKey] = useState('nl');
  const [clientSelectedQuote, setClientSelectedQuote] = useState(null);
  const [customerContacts, setCustomerContacts] = useState({
    data: [],
    loading: true,
  });
  const [supplierSelected, setSupplierSelected] = useState(null);
  const [rateSelected, setRateSelected] = useState(null);
  const [filterSupplier, setFilterSupplier] = useState({});
  const [textType, setTextType] = useState('');
  const [textDescription, setTextDescription] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [showAllValuesNL, setShowAllValuesNL] = useState(true);
  const [clearIsRFQ, setClearIsRFQ] = useState(false);
  const [sumatoriaChecked, setSumatoriaChecked] = useState(false);
  // const [clientChecked, setClientChecked] = useState(false);
  // const [statusChecked, setStatusChecked] = useState(false);
  const [customerRoutes, setCustomerRoutes] = useState([]);
  const [allRoutes, setAllRoutes] = useState([]);
  const [granTotal, setGranTotal] = useState(0);
  const [quotePdf, setQuotePdf] = useState(null);
  const [currencyLine, setCurrencyLine] = useState(0);
  const [routeSelected, setRouteSelected] = useState(0);
  const [quoteIdPdf, setQuoteIdPdf] = useState(quoteId);
  const [idVisible, setIdVisible] = useState(false);
  const { person } = useUser();
  const token = getAccessTokenApi();
  const history = useHistory();
  const [isRFQ, setIsRFQ] = useState(false);
  const [unitCost, setUnitCost] = useState(0);

  const rangeConfig = {
    rules: [
      {
        type: 'array',
        required: true,
        message: 'Por favor selecciona un rango de fecha',
      },
    ],
  };

  useEffect(() => {
    generalForm.setFieldsValue({
      lastUpdateBy: person.userName,
    });
  }, [person]);

  useEffect(() => {
    let countSales = 0;
    linesData?.forEach((element) => {
      countSales += element.sale;
    });
    additionalServices?.forEach((element) => {
      countSales += element.cost;
    });
    setGranTotal(countSales);
  }, [additionalServices, linesData]);

  useEffect(() => {
    //edit or watch
    if (quoteId) {
      setLoadingForm(true);
      const config = {
        method: METHOD_GET,
        url: `${API_URL_QUOTE}/${quoteId}`,
        data: null,
        token,
        showNotificationError: true,
      };
      fetchApi(config)
        .then((response) => {
          // console.log(response, 'response');
          if (response.statusCode === 200) {
            const result = response.result.quote;
            setAdditionalServices(result.quoteAS);
            setLinesData(response.result.quote.quoteLines);
            // const lineCurrency = response.result.quote.quoteLines[0]['hasRFQ']
            //   ? 0
            //   : response.result.quote.quoteLines[0]['currency']
            //   ? response.result.quote.quoteLines[0]['currency']
            //   : 0;
            // setCurrencyLine(lineCurrency);
            const userName = response.result.createdBy;
            const clientName = response.result.quote.customerName || '';
            const quote = {
              id: result.id,
              rateId: result.rateId,
              rateIdStr: result.rateIdStr,
              validity: [
                moment.unix(result.startDate),
                moment.unix(result.endDate),
              ],
              startDate: result.startDate,
              endDate: result.endDate,
              isCompleted: result.isCompleted,
              contactIds: result.contactIds,
              customerId: result.customerId,
              comments: result.comments,
              lastUpdateBy: userName,
              customer: clientName,
              quoteIdStr: result.quoteIdStr,
              status: result.status,
              insurancePolicy: result.insurancePolicy,
            };
            if (result.quoteIdStr) {
              setIdVisible(true);
            }
            generalForm.setFieldsValue(quote);
            statusQuoteHandle(quote.validity);
            // generalForm.setFieldsValue(rate);
            // setService(result.service);
            // setIsRFQ(result.isRFQ);

            setClientSelectedQuote({
              id: result.customerId,
              name: clientName, //PENDING
            });
            let countSales = 0;
            response.result.quote.quoteLines?.forEach((element) => {
              countSales += element.sale;
            });
            response.result.quote.quoteAS?.forEach((element) => {
              countSales += element.cost;
            });
            setGranTotal(countSales);
            generatePdfHandle(quoteId);
            // console.log(response, 'response');
            if (result.quoteLines && type === 'watch') {
              const formValues = result.quoteLines[0];
              linesForm.setFieldsValue({ ...formValues });
              if (formValues.pieces) {
                setPieces([...formValues.pieces]);
              }
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
      setLoadingForm(false);
    } else {
      // form.setFieldsValue({
      //    service: TERRESTRE_VALUE_ID,
      //    user: person.userName,
      // });
    }
  }, []);

  useEffect(() => {
    if (clientSelectedQuote) {
      const config = {
        method: METHOD_GET,
        url: `Customer/${clientSelectedQuote.id}/Contacts`,
        data: null,
        token,
      };
      fetchApi(config)
        .then((response) => {
          if (response) {
            const supContacts = response.result;
            if (supContacts) {
              supContacts.map((cat, index) => {
                cat['key'] = index;
                cat['value'] = cat.id;
                cat[
                  'label'
                ] = `${cat.name} ${cat.lastName} - ${cat.rolesDescription}`;

                return cat;
              });
            }
            // console.log(supContacts, 'supContacts');
            supContacts.sort((a, b) => a.label.localeCompare(b.label));
            setCustomerContacts({
              data: supContacts,
              loading: false,
            });
            if (!clientSelectedQuote.key) {
              generalForm.setFieldsValue({
                customerId: clientSelectedQuote.id,
                customer: `${clientSelectedQuote.name}`,
              });
            } else {
              generalForm.setFieldsValue({
                customerId: clientSelectedQuote.id,
                customer: `${clientSelectedQuote.name}`,
                contactIds: [],
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
          setCustomerContacts({
            data: [],
            loading: false,
          });
        });
    } else {
      setCustomerContacts({
        data: [],
        loading: false,
      });
      generalForm.setFieldsValue({
        customerId: null,
        // contactIds: null,
        customer: null,
      });
    }
  }, [clientSelectedQuote, token]);

  useEffect(() => {
    if (clientSelectedQuote) {
      const config = {
        method: METHOD_GET,
        url: `Route/GetByCustomerAndSupplier?customerId=${encodeURIComponent(
          clientSelectedQuote.id
        )}&supplierId=${
          supplierSelected
            ? encodeURIComponent(supplierSelected.supplierId)
            : ''
        }`,
        data: null,
        token,
      };
      fetchApi(config)
        .then((response) => {
          if (response) {
            let clientRoutes = response.result;
            let routesCustomer = [];
            if (clientRoutes) {
              routesCustomer = clientRoutes.map((e, index) => {
                return e;
              });
            }
            let routesC = routesCustomer.map((e) => ({
              value: e.routeId,
              label: `${e.originCityStr} ${e.originStateStr} - ${e.destinationCityStr} ${e.destinationStateStr}`,
            }));
            routesC.sort((a, b) => a.label.localeCompare(b.label));
            setCustomerRoutes(routesC);
            setAllRoutes(routesCustomer);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [clientSelectedQuote, token, supplierSelected]);

  const clearSupplier = () => {
    setSupplierSelected(null);
  };
  const changeTab = (key) => {
    setSaveKey(key);
    if (key !== 'nl' && banderita) {
      const valuesForm = newLineForm.getFieldsValue();
      setSaveForm(valuesForm);
      setBanderita(false);
      valuesForm['pieces'] = pieces;
    }
    if (key !== 'nl') {
      const i = parseInt(key);
      const formValues = linesData[i];
      linesForm.setFieldsValue({ ...formValues });
      if (formValues.pieces) {
        setPieces([...formValues.pieces]);
      }
    }
    if (key === 'nl') {
      // console.log(saveForm,"saveform");
      newLineForm.setFieldsValue({ ...saveForm });
      setBanderita(true);
      if (pieces.length > 0) {
        setPieces([...saveForm.pieces]);
      }
      setShowAllValuesNL(true);
      // setFilterSupplier({});
      setFilterSupplier({
        typeOfLoad: saveForm.loadType,
        service: saveForm.service,
        typeOfService: saveForm.typeOfService,
        unit: saveForm.unitType,
      });
    }
  };
  const showModal = (type, asSelected) => {
    const parsekey = parseInt(saveKey);
    const line = linesData[parsekey];

    if (type === 'Clients') {
      setModalIsVisible(true);
      setTitleModal('Clientes');
      setContextModal(
        <CustomersTable
          setModalIsVisibleQuote={setModalIsVisible}
          pickCustomer
          setClientSelectedQuote={setClientSelectedQuote}
          // type={type}
        ></CustomersTable>
      );
    } else if (type === 'Suppliers') {
      setTitleModal('Proveedores');
      setContextModal(
        <SuppliersTable
          pickSupplier
          setSupplierSelected={setSupplierSelected}
          form={generalForm}
          setModalIsVisible={setModalIsVisible}
          filterRate={filterSupplier}
          clientSelected={clientSelectedQuote}
          routeSelected={routeSelected}
        />
      );
      setModalIsVisible(true);
    } else if (type === 'Rate') {
      setTitleModal('Tarifa');
      setContextModal(
        <PricingTable
          pickRate
          setRateSelected={setRateSelected}
          form={generalForm}
          newLineForm={newLineForm}
          setModalIsVisibleRate={setModalIsVisible}
          filterRate={filterSupplier}
          supplierSelected={supplierSelected}
          setSupplierSelected={setSupplierSelected}
          routeSelected={routeSelected}
        />
      );
      setModalIsVisible(true);
    } else if (type === 'Filter') {
      setTitleModal('Filtro servicio adicional');
      setContextModal(
        <FilterAdditionalServices
          setModalIsVisible={setModalIsVisible}
          showModal={showModal}
        />
      );
      setModalIsVisible(true);
    } else if (type === 'Services') {
      setTitleModal('Agregar servicio adicional');
      setContextModal(
        <AddAdditionalService
          setModalIsVisible={setModalIsVisible}
          asSelected={asSelected}
          additionalServices={additionalServices}
          setAdditionalServices={setAdditionalServices}
          quoteId={quoteId}
        />
      );
      setModalIsVisible(true);
    } else if (type === 'Responses') {
      setTitleModal('Seleccionar respuesta');
      setContextModal(
        <RFQResponsesTable
          pickResponse
          setModalIsVisible={setModalIsVisible}
          form={generalForm}
          setSupplierSelected={setSupplierSelected}
          linesForm={linesForm}
          rate={line.rateId}
          setUnitCost={setUnitCost}
          setLinesData={setLinesData}
          line={line}
          linesData={linesData}
        />
      );
      setModalIsVisible(true);
    }
  };
  const handleSaveLine = (values) => {
    const parsekey = parseInt(saveKey);
    let found = linesData.find((_, index) => index === parsekey);
    confirm({
      title:
        saveKey === 'nl'
          ? `Guardar línea ${linesData.length + 1}`
          : `Editar línea ${found.lineNumber}`,
      icon: <ExclamationCircleOutlined />,
      content:
        saveKey === 'nl'
          ? `¿Estás seguro que deseas guardar línea ${linesData.length + 1}? `
          : `¿Estás seguro que deseas editar línea ${found.lineNumber}?`,
      okText: 'Si',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        addLineFunction(values);
      },
      onCancel() {},
    });
  };

  function DescriptioError({ fields }) {
    return (
      <>
        <Text>
          Para la línea que desea crear los siguientes campos no coinciden con
          la tarifa seleccionada:
        </Text>
        <ul>
          {fields.map((field, index) => (
            <li key={index}>
              <Text strong>{field}</Text>
            </li>
          ))}
        </ul>
        {/* <Text>no coincide con la tarifa seleccionada</Text> */}
      </>
    );
  }

  const addLineFunction = async (values) => {
    values['pieces'] = pieces;
    values['lineNumber'] = linesData.length + 1;
    // values['routeId'] = routeSelected;
    if (saveKey === 'nl') {
      //ADD NEW LINE

      //Save the currency allow in lines
      if (linesData.length + 1 === 1) {
        if (!values.hasRFQ) {
          setCurrencyLine(values.rateCurrency);
        }
      } else {
        // console.log(currencyLine, 'currencyLine');
        if (currencyLine !== 0 && !values.hasRFQ) {
          if (currencyLine !== values.rateCurrency) {
            notification.error({
              message: 'Línea invalida por el tipo de moneda de tarifa',
              description:
                'La línea que desea agregar no tiene el mismo tipo de moneda que las demas',
            });
            return;
          }
        }
      }
      setLoadingForm(true);
      if (values.iva === true) {
        values['taxRate'] = 16;
      }
      console.log(values,'values');

      const config = {
        method: METHOD_POST,
        url: `${API_URL_QUOTE_VALIDATE_LINE}`,
        data: values,
        token,
      };
      const response = await fetchApi(config);
      setLoadingForm(false);

      // console.log(response);
      if (response?.statusCode === 200) {
        if (response.result.isValid) {
          if (type === 'Edit') {
            values['quoteId'] = quoteId;
          }
          setLinesData([...linesData, values]);
          newLineForm.resetFields();
          setPieces([]);
          notification['success']({
            message: `Línea agregada exitosamente `,
          });
          generalForm.scrollToField('LoadType');
          // generalForm.scrollToField('comment');
          setShowAllValuesNL(true);
          setFilterSupplier({});
          setSupplierSelected(null);
          setRateSelected(null);
          setClearIsRFQ(true);
        } else {
          const errors = response?.result?.fields || [];

          notification['error']({
            message: 'Datos Incorrectos en la línea',
            description: <DescriptioError fields={errors} />,
            duration: 6,
          });
        }
      } else {
        if (response?.statusCode === 400) {
          notification['error']({
            message: response.message,
            description: `${response.description}`,
          });
        } else {
          notification['error']({
            message: 'Ocurrió un error',
            description: 'Error al validar la línea, inténtelo mas tarde',
          });
        }
      }
    } else {
      const valuesForm = linesForm.getFieldsValue();
      const parsekey = parseInt(saveKey);
      let found = linesData.findIndex((_, index) => index === parsekey);
      let copy = [...linesData];
      copy[found] = { ...valuesForm };
      copy[found]['id'] = linesData[parsekey].id;
      copy[found]['rateUnitPrice'] = linesData[parsekey].rateUnitPrice;
      // copy[found]['rateUnitPrice'] = linesData[parsekey].rateUnitPrice;
      if (type === 'Edit') {
        copy[found]['quoteId'] = quoteId;
      }
      copy[parsekey]['pieces'] = linesData[parsekey].pieces;
      setLinesData(copy);
      notification['success']({
        message: `Línea editada exitosamente `,
      });
    }
  };
  const validationQuoteField = ({ values, errorFields, outOfDate }) => {
    if (!values.validity || !values.customerId || !values.contactIds) {
      setTextType('Datos incompletos');
      setTextDescription('Complete todos los datos de la cotización.');
      setShowAlert(true);

      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }
  };
  const handleSaveQuote = (values) => {
    confirm({
      title:
        values.isCompleted === true
          ? 'La cotización se guardara como completa y ya no se podrá editar.'
          : !quoteId
          ? 'Guardar cotización'
          : 'Editar cotización',
      icon: <ExclamationCircleOutlined />,
      content: !quoteId
        ? '¿Estás seguro que deseas guardar cotización?'
        : '¿Estás seguro que deseas editar cotización?',
      okText: 'Aceptar',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk() {
        addQuoteFunction(values);
      },
      onCancel() {},
    });
  };
  const handleDeleteLine = (line) => {
    confirm({
      title: 'Eliminar línea',
      icon: <ExclamationCircleOutlined />,
      content: `¿Estás seguro que deseas eliminar línea ${line}?`,
      okText: 'Si',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteLine(line);
        if (linesData.length === 1) {
          linesForm.resetFields();
          setPieces([]);
          setSaveKey('nl');
        }
      },
      onCancel() {},
    });
  };
  const deleteLine = async (line) => {
    setLoadingForm(true);
    // borrar elemento de array
    setLinesData((lineas) => lineas.filter((e) => e.lineNumber !== line));
    notification['success']({
      message: `Línea ${line} eliminada exitosamente `,
    });
    setLoadingForm(false);

    return false;
  };
  const statusQuoteHandle = (value, dateString) => {
    if (value) {
      const today = moment();
      const startDate = value[0];
      const endDate = value[1];

      if (
        moment(startDate).isBefore(today, 'day') &&
        moment(endDate).isBefore(today, 'day')
      ) {
        setStatusQuote('inactive');
      } else {
        if (moment(today, 'day').isBetween(startDate, endDate)) {
          setStatusQuote('active');
        } else {
          if (
            moment(startDate).isSame(today, 'day') ||
            moment(endDate).isSame(today, 'day')
          ) {
            setStatusQuote('active');
            return;
          }
          setStatusQuote('pending');
        }
      }
    }
  };
  const onChangeSelect = (value, type) => {
    setFilterSupplier({
      ...filterSupplier,
      [type]: value,
    });
  };

  const generatePdfHandle = async (quoteId, goToPage = false) => {
    const config = {
      method: METHOD_GET,
      url: `${API_URL_QUOTE_PDF(quoteId)}`,
      data: null,
      token,
      // showNotification: true,
    };
    const response = await fetchApi(config);

    if (response?.statusCode === 200) {
      setQuotePdf(response.result);
      // if (goToPage) {
      //   history.push(`/cotizacion/${quoteId}/pdf`);
      // }
    }
  };
  const addQuoteFunction = async (values) => {
    // setLoadingForm(true);
    if (linesData.length < 1) {
      notification['error']({
        message: 'Debes de tener mínimo 1 línea',
      });
      setLoadingForm(false);
      return false;
    }
    values['startDate'] = moment(values.validity[0]).startOf('day').unix();
    values['endDate'] = moment(values.validity[1]).endOf('day').unix();
    values['status'] = statusQuote;

    if (quoteId) {
      values['id'] = quoteId;
      if (quoteId.isCompleted) {
        notification['error']({
          description: 'No puede modificar esta cotización',
        });
        return;
      }
    }
    values['quoteLines'] = linesData;
    let as = additionalServices?.map((p, i) => ({ ...p, number: i }));
    values['quoteAS'] = as;

    // if (values.contactIds && values.contactIds.length > 0) {
    //   values.contactIds = values.contactIds
    //     .filter((v) => v !== null)
    //     .map((v) => v.value);
    // }
    const config = {
      method: quoteId ? METHOD_PUT : METHOD_POST,
      url: `${API_URL_QUOTE}${quoteId ? '/' + quoteId : ''}`,
      data: values,
      token,
      // showNotification: true,
    };
    fetchApi(config)
      .then((response) => {
        setLoadingForm(false);
        if (response) {
          if (response.statusCode === 200) {
            if (!quoteId) {
              notification['success']({
                message: response.message,
                description: response.description,
              });
            } else {
              notification['success']({
                message: response.message,
                description: response.description,
              });
            }

            generalForm.resetFields();
            setCustomerContacts({
              data: [],
              loading: false,
            });
            linesForm.resetFields();
            newLineForm.resetFields();
            setPieces([]);
            setLinesData([]);
            setAdditionalServices([]);
            // GENERATE PDF
            setQuoteIdPdf(response.result.id);
            generatePdfHandle(response.result.id, true);
          }
        }else{
          notification['error']({
            message: response.message,
            description: response.description,
          });
          return;
        }
        if (type === 'Edit') {
          setModalQuoteIsVisible(false);
          setReloadTable(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  const sendPdfToClient = async (quoteIdPdf) => {
    setLoadingForm(true);
    const blob = await pdf(
      <QuotePdf user={person} quote={quotePdf} />
    ).toBlob();
    const result = await blobToBase64(blob);
    // console.log(result, 'result');

    let posData64 = result.indexOf(',') + 1;

    const data = {
      file: result.substr(posData64),
      id: quoteIdPdf,
    };

    const config = {
      method: METHOD_POST,
      url: `${API_SEND_QUOTE_EMAIL}`,
      data,
      token,
      // showNotification: true,
    };
    const response = await fetchApi(config);

    if (response?.statusCode === 200) {
      notification['success']({
        message: 'Cotización enviada al cliente exitosamente',
      });

      setTimeout(() => {
        history.push(`/cotizacion/${quoteIdPdf}/pdf`);
      }, 1500);
    } else {
      notification['error']({
        message: 'Ocurrió un error al enviar el correo electrónico.',
        description: 'Inténtelo mas tarde.',
      });
    }
    setLoadingForm(false);
  };
  useEffect(() => {
    const findLine = linesData[parseInt(saveKey)];
    if (findLine) {
      if (type === 'Edit') {
        if (findLine.hasRFQ === true && findLine.id) {
          setIsRFQ(true);
        } else {
          setIsRFQ(false);
        }
      }
    }
  }, [saveKey]);
  return (
    <Spin spinning={loadingForm} tip="Cargando...">
      <div>
        <Row>
          <Col xs={24}>
            <Form
              name="add-quote"
              layout="horizontal"
              form={generalForm}
              onFinish={handleSaveQuote}
              onFinishFailed={validationQuoteField}
            >
              <div>
                <Row gutter={16}>
                  <Col md={10} xs={24}>
                    {!idVisible && (
                      <Form.Item label="Cotización:" name="id">
                        <Input disabled />
                      </Form.Item>
                    )}

                    {idVisible && (
                      <Form.Item label="Cotización:" name="quoteIdStr">
                        <Input disabled />
                      </Form.Item>
                    )}
                  </Col>
                  <Col md={10} xs={24}>
                    <Row>
                      <Form.Item
                        label="Vigencia:"
                        name="validity"
                        {...rangeConfig}
                      >
                        <RangePicker
                          disabled={disableAllInputs}
                          onChange={statusQuoteHandle}
                        />
                      </Form.Item>
                    </Row>
                  </Col>
                  <Col md={4} xs={12}>
                    <Row justify="end">
                      <Col
                        xs={24}
                        md={24}
                        style={{
                          display: 'flex',
                          justifyContent: 'start',
                          marginBottom: 20,
                        }}
                      >
                        <Button
                          disabled={disableAllInputs}
                          className={`btn-vigencia ${
                            statusQuote === 'active'
                              ? 'btn-vigencia-green'
                              : statusQuote === 'inactive'
                              ? 'btn-vigencia-red'
                              : 'btn-vigencia-yellow'
                          }`}
                        >
                          {statusQuote === 'active'
                            ? 'Vigente'
                            : statusQuote === 'inactive'
                            ? 'Vencido'
                            : ' Próxima'}
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {/* ROW2 */}
                <Row
                  gutter={16}
                  style={{
                    marginBottom: 10,
                  }}
                >
                  <Col md={3} xs={24}>
                    <Button
                      style={{ marginBottom: 5 }}
                      type="primary"
                      icon={<UserSwitchOutlined />}
                      disabled={disableAllInputs || type !== undefined}
                      onClick={() => showModal('Clients')}
                    >
                      Cliente
                    </Button>
                  </Col>
                  <Col md={21} xs={24}>
                    <Form.Item
                      name="customer"
                      rules={[
                        {
                          required: true,
                          message: 'Por favor ingrese un cliente',
                        },
                      ]}
                    >
                      <Input placeholder="Ingrese información" disabled />
                    </Form.Item>
                    <Form.Item
                      name="customerId"
                      hidden
                      rules={[
                        {
                          required: true,
                          message: 'Por favor ingrese un cliente',
                        },
                      ]}
                    >
                      <Input placeholder="Ingrese información" disabled />
                    </Form.Item>
                  </Col>
                  <Col md={24} xs={24}>
                    <Form.Item
                      label="Contacto:"
                      name="contactIds"
                      rules={[
                        {
                          required: true,
                          message: 'Por favor ingrese un contacto',
                        },
                      ]}
                    >
                      <Select
                        placeholder="Selecciona una opción"
                        showSearch
                        allowClear
                        optionFilterProp="children"
                        mode={'multiple'}
                        // labelInValue
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        // options={customerContacts.data}
                        disabled={customerContacts.loading || disableAllInputs}
                      >
                        {customerContacts.data.map((s) => (
                          <Select.Option key={s.value} value={s.value}>
                            {s.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                {/* ROW3 */}
                <Row>
                  <Col span={24}>
                    <Form.Item label="Comentarios:" name="comments">
                      <TextArea
                        placeholder="Ingresa comentario"
                        disabled={disableAllInputs}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col md={12} xs={24}>
                    <Form.Item
                      label="Estatus "
                      name="isCompleted"
                      valuePropName="checked"
                      style={{
                        marginBottom: 15,
                        marginTop: 0,
                      }}
                      tooltip={{
                        title:
                          'Si se selecciona esta casilla ya no se podrá modificar después la cotización.',
                        icon: <InfoCircleOutlined />,
                      }}
                    >
                      <Checkbox disabled={disableAllInputs}>Completa</Checkbox>
                    </Form.Item>
                  </Col>

                  <Col
                    md={12}
                    xs={24}
                    style={{
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Form.Item label="Usuario:" name="lastUpdateBy">
                      <Input disabled placeholder="Usuario" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row justify="space-between">
                  <Col md={9} xs={24}>
                  <Form.Item label="Póliza de seguro:" name="insurancePolicy">
                      <Input/>
                    </Form.Item>
                  </Col>
                  {/* <Col md={8} lg={6} xs={24}> */}
                    {/* <Col> */}
                      {/* <Form.Item
                        name="emailToCustomer"
                        valuePropName="checked"
                        style={{
                          marginBottom: 5,
                          marginTop: 0,
                        }}
                      >
                        <Checkbox
                          // disabled={disableAllInputs}
                          onChange={(e) => setClientChecked(e.target.checked)}
                          checked={clientChecked}
                        >
                          Enviar correo a cliente
                        </Checkbox>
                      </Form.Item> */}
                    {/* </Col> */}
                  {/* </Col> */}
                  {/* {linesData.length > 1 && (
                    <> */}
                  {granTotal > 0 && (
                    <>
                      <Col md={2} xs={24}>
                        <Row>
                          <Form.Item
                            name="sumatoria"
                            valuePropName="checked"
                            style={{
                              marginBottom: 5,
                              marginTop: 0,
                            }}
                          >
                            <Checkbox
                              // disabled={disableAllInputs}
                              onChange={(e) =>
                                setSumatoriaChecked(e.target.checked)
                              }
                              checked={sumatoriaChecked}
                            >
                              Sumatoria
                            </Checkbox>
                          </Form.Item>
                        </Row>
                      </Col>
                      <Col md={6} xs={24}>
                        {sumatoriaChecked && (
                          <Form.Item label="Gran total:" name="grantotal">
                            <Tag color="green" style={{ padding: 5 }}>
                              <Text
                                className="text-label"
                                strong
                                style={{
                                  marginRight: 5,
                                }}
                              ></Text>
                              <Text className="text-label">
                                ${formatMoney(granTotal)}
                              </Text>
                            </Tag>
                          </Form.Item>
                        )}
                      </Col>
                    </>
                  )}

                  {/* </>
                  )} */}
                  <Col
                    md={6}
                    xs={24}
                    // lg={6}
                    style={{
                      justifyContent: 'flex-end',
                      display: 'flex',
                    }}
                  >
                    <Button
                      style={{ marginLeft: 0, width: 190 }}
                      className="btn-download"
                      block
                      size="small"
                      icon={<DiffOutlined />}
                      onClick={() => showModal('Filter')}
                      disabled={disableAllInputs}
                    >
                      Servicios adicionales
                    </Button>
                  </Col>
                </Row>
              </div>

              {additionalServices.length > 0 && (
                <Col md={24} lg={24} xs={24}>
                  <AdditionalServicesTable
                    disableAllInputs={disableAllInputs}
                    additionalServices={additionalServices}
                    setAdditionalServices={setAdditionalServices}
                    type={type}
                  ></AdditionalServicesTable>
                </Col>
              )}
            </Form>
            <Divider>Líneas</Divider>
            <LineForm
              linesForm={linesForm}
              newLineForm={newLineForm}
              handleSaveLine={handleSaveLine}
              changeTab={changeTab}
              disableAllInputs={disableAllInputs}
              showModal={showModal}
              linesData={linesData}
              handleDeleteLine={handleDeleteLine}
              pieces={pieces}
              setPieces={setPieces}
              setLinesData={setLinesData}
              saveKey={saveKey}
              onChangeFilterSupplier={onChangeSelect}
              supplierSelected={supplierSelected}
              setFilterSupplier={setFilterSupplier}
              showAllValuesNL={showAllValuesNL}
              setShowAllValuesNL={setShowAllValuesNL}
              rateSelected={rateSelected}
              type={type}
              setRateSelected={setRateSelected}
              clearSupplier={clearSupplier}
              setSupplierSelected={setSupplierSelected}
              clearIsRFQ={clearIsRFQ}
              setClearIsRFQ={setClearIsRFQ}
              routes={customerRoutes}
              clientSelectedQuote={clientSelectedQuote}
              allRoutes={allRoutes}
              setRouteSelected={setRouteSelected}
              routeSelected={routeSelected}
              unitCost={unitCost}
              setUnitCost={setUnitCost}
            ></LineForm>
            {showAlert && (
              <Alert
                message={textType}
                description={textDescription}
                type="warning"
                showIcon
                style={{ marginBottom: 20 }}
              />
            )}
            <Row justify="end">
              {saveKey === 'nl' && !disableAllInputs && (
                <Col md={6} xs={24}>
                  <Row style={{ justifyContent: 'center' }}>
                    <Form.Item>
                      <Button
                        disabled={disableAllInputs}
                        className="btn-download"
                        style={{ width: 150 }}
                        block
                        onClick={() => newLineForm.submit()}
                        icon={<FileAddOutlined />}
                      >
                        Guardar línea {linesData.length + 1}
                      </Button>
                    </Form.Item>
                  </Row>
                </Col>
              )}
              {saveKey !== 'nl' && (
                <Col md={6} xs={24}>
                  <Row style={{ justifyContent: 'center' }}>
                    <Form.Item>
                      <Button
                        disabled={disableAllInputs}
                        className="btn-download"
                        style={{ width: 150 }}
                        block
                        onClick={() => linesForm.submit()}
                        icon={<FileAddOutlined />}
                      >
                        Editar línea
                      </Button>
                    </Form.Item>
                  </Row>
                </Col>
              )}

              <Col md={6} xs={24}>
                <Row style={{ justifyContent: 'center' }}>
                  <Form.Item>
                    <Button
                      disabled={
                        disableAllInputs || (linesData.length === 0 && !quoteId)
                      }
                      className="btn-enviar"
                      style={{ width: 170 }}
                      block
                      onClick={() => generalForm.submit()}
                      icon={<SaveOutlined />}
                    >
                      Guardar cotización
                    </Button>
                  </Form.Item>
                </Row>
              </Col>

              {isMobile() && (
                <Col md={6} xs={24}>
                  <Row style={{ justifyContent: 'center' }}>
                    <Form.Item>
                      <Button
                        disabled={quoteIdPdf ? false : true}
                        className="btn-enviar"
                        style={{ width: 160 }}
                        block
                        onClick={() => sendPdfToClient(quoteIdPdf)}
                        icon={<SendOutlined />}
                      >
                        {'Enviar cotización'}
                      </Button>
                    </Form.Item>
                  </Row>
                </Col>
              )}

              {/* {!isMobile() && type === 'Edit' && (
                <Col md={6} xs={24}>
                  <Row style={{ justifyContent: 'center' }}>
                    <Form.Item>
                      <Button
                        disabled={quoteIdPdf ? false : true}
                        type="primary"
                        className="btn-enviar"
                        style={{
                          backgroundColor: '#1890ff',
                        }}
                        block
                        onClick={() =>
                          history.push(`/cotizacion/${quoteIdPdf}/pdf`)
                        }
                        icon={<FilePdfOutlined />}
                        size="large"
                      >
                        Ver PDF
                      </Button>
                    </Form.Item>
                  </Row>
                </Col>
              )} */}

              {type === 'watch' && isMobile() && (
                <Col md={6} xs={24}>
                  <Row style={{ justifyContent: 'center' }}>
                    <PDFDownloadLink
                      style={{
                        backgroundColor: ' #1890ff',
                        color: 'white',
                        borderRadius: 4,
                        width: 200,
                        height: 40,
                        fontSize: 16,
                        lineHeight: 1.5715,
                        position: 'relative',
                        // display: inline-block,
                        fontWeight: 400,
                        whiteSpace: 'nowrap',
                        marginLeft: 20,
                        textAlign: 'center',
                        paddingTop: 5,
                      }}
                      document={<QuotePdf user={person} quote={quotePdf} />}
                      fileName="cotizacion.pdf"
                    >
                      {({ blob, url, loading, error }) =>
                        loading ? 'Cargar documento...' : 'Descargar Cotización'
                      }
                    </PDFDownloadLink>
                  </Row>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </div>
      <Modal
        title={titleModal}
        visible={modalIsVisible}
        setIsVisible={setModalIsVisible}
        // width={1000}
        width={800}
      >
        {contextModal}
      </Modal>
    </Spin>
  );
};

export default AddQuoteForm;
