import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Select, Spin } from 'antd';
import { getCatalogValueHandle } from '../../../../../../helpers/handlerFunctionApi';
import {
  // CURRENCY_CATALOG_ID,
  PAYMENT_TERMS_CATALOG_ID,
} from '../../../../../../helpers/constants';

const Catalog = ({ disableAllInputs }) => {
  // const [currency, setCurrency] = useState([]);
  const [paymentTerms, setPaymentTerms] = useState([]);
  const [loadingCatalog, setLoadingCatalog] = useState();

  useEffect(() => {
    // getCatalogValueHandle(CURRENCY_CATALOG_ID, setCurrency, setLoadingCatalog);
    getCatalogValueHandle(
      PAYMENT_TERMS_CATALOG_ID,
      setPaymentTerms,
      setLoadingCatalog
    );
  }, []);

  return (
    <Spin spinning={loadingCatalog} className="catalog-form-client">
      <Row gutter={16} className="table-company">
        {/* <Col xs={24} md={12} lg={8}>
          <Form.Item label="Moneda:" name="currency">
            <Select
              placeholder="Selecciona una opción"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              disabled={disableAllInputs}
            >
              {currency.map((s) => (
                <Select.Option key={s.value} value={s.value}>
                  {s.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col> */}

        <Col xs={24} md={12} lg={8}>
          <Form.Item label="Términos de pago:" name="paymentTerms">
            <Select
              placeholder="Selecciona una opción"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              disabled={disableAllInputs}
            >
              {paymentTerms.map((s) => (
                <Select.Option key={s.value} value={s.value}>
                  {s.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Spin>
  );
};

export default Catalog;
