import { basePath } from './config';

//GET INICIO TABLA CUENTAS
export function getAllOperationsApi(token, filters) {
  const url = `${basePath}/Operations/GetOperationsInfo${filters}`;
  const params = {
    method: 'POST',
    body: JSON.stringify({}),
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      Accept: '*/*',
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {});
}

//GET DETAIL OPERATIONS
export function getOperationDetailsInfo(token, data) {
  const url = `${basePath}/Operations/GetOperationDetailsInfo`;
  const params = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      Accept: '*/*',
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {});
}

//GET INICIO TABLA OPERACIONES
export function getOperationNoticesApi(token) {
  const url = `${basePath}/Operations/GetNotices`;
  const params = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      Accept: '*/*',
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {});
}

//GET OPERATION CHART
export function getOperationsByMonthApi(token, filters) {
  const url = `${basePath}/Customer/GetOperationsSummary${filters}`;
  const params = {
    method: 'GET',
    // body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      Accept: '*/*',
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {});
}

//GET DOCUMENTS OPERATIONS
export function getDocumentsInfoApi(token, data) {
  const url = `${basePath}/Documents/GetDocumentsInfo`;
  const params = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      Accept: '*/*',
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {});
}
//POST DOCUMENT OPERATIONS
export function addDocumentsApi(token, data) {
  const url = `${basePath}/Operations/AttachOperationFiles`;
  const params = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      Accept: '*/*',
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {});
}
//POST DOCUMENT OPERATIONS
export function deleteDocumentApi(token, data) {
  const url = `${basePath}/Documents/DeleteDocument`;
  const params = {
    method: 'DELETE',
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      Accept: '*/*',
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {});
}
//POST COMMENT OPERATIONS
export function addCommentApi(token, data) {
  const url = `${basePath}/comments`;
  const params = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      Accept: '*/*',
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {});
}
//GET COMMENT OPERATIONS
export function getCommentsApi(token, id) {
  const url = `${basePath}/comments?documentId=${id}`;
  const params = {
    method: 'GET',
    // body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      Accept: '*/*',
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {});
}
//CLIENTE CON OPERADOR
export function getCustomersOperationsApi(token, id) {
  const url = `${basePath}/operaciones/${id}/customers`;
  const params = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {});
}
export function deleteCustomersOperationsApi(token, idFinancial, idCustomer) {
  const url = `${basePath}/operaciones/${idFinancial}/customers/${idCustomer}/remove-from-list`;
  const params = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {});
}

//GET TOTAL OPERACIONES
export function getDocumentsCountApi(token, idOperation) {
  const url = `${basePath}/Operations/${idOperation}/DocumentsCount`;
  const params = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      Accept: '*/*',
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {});
}

//POST EDIT OPERATIONS
export function changeStatusOperation(token, data) {
  const url = `${basePath}/Operations/ChangeStatus`;
  const params = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      Accept: '*/*',
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {});
}
