import React, { useEffect, useState } from 'react';
import { Col, Layout, Row, Typography, notification } from 'antd';
import GreetingsTop from '../../../../components/Admin/GreetingsTop/GreetingsTop';
import { useParams } from 'react-router-dom';
import { getDocumentsInfoApi } from '../../../../api/documents';
import { getAccessTokenApi } from '../../../../api/auth';
import { messageErrorGeneral } from '../../../../utils/general';
import OperationFilesTable from '../../../../components/Logistic/Operations/OperationFilesTable/OperationFilesTable';
import { fetchApi } from '../../../../helpers/fetch';
import { METHOD_GET } from '../../../../utils/constants';
import { API_URL_OPERATION } from '../../../../helpers/urls';
import BackButton from '../../../../components/General/BackButton/BackButton';

export default function OperationDocuments() {
  const { id } = useParams();
  const [infoOperation, setInfoOperation] = useState(null);
  const [laodingData, setLaodingData] = useState(false);
  const [idString, setIdString] = useState('');
  const token = getAccessTokenApi();

  useEffect(() => {
    if (token && id) {
      const data = {
        reference: id,
        documentType: 'Evidence',
      };
      setLaodingData(true);
      getDocumentsInfoApi(token, data).then((response) => {
        if (response) {
          if (response.statusCode === 409) {
            notification['error']({
              message: response.message,
            });

            setTimeout(() => {
              window.location.href = '/operaciones';
            }, 1500);
          }

          if (response.statusCode === 200) {
            const result = response.result;
            setInfoOperation(result[0]);
          }
        } else {
          notification['error']({
            message: messageErrorGeneral(),
          });

          setTimeout(() => {
            window.location.href = '/operaciones';
          }, 1500);
        }
        setLaodingData(false);
      });
    }
  }, [token, id]);
  useEffect(() => {
    //edit or watch
    if (id) {
      const config = {
        method: METHOD_GET,
        url: `${API_URL_OPERATION}/${id}`,
        data: null,
        token,
        showNotificationError: true,
      };
      fetchApi(config)
        .then((response) => {
          if (response.statusCode === 200) {
            let result = response.result.operation;
            let idLabel = result.operationIdStr
              ? result.operationIdStr
              : result.id;
            setIdString(idLabel);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      // setLoadingForm(false);
    }
  }, [id, token]);
  return (
    <Layout>
      <GreetingsTop />
      <HeaderTop idOperation={id} idString={idString} />
      <div className="billing__container">
        <OperationFilesTable idOperation={id} />
      </div>
    </Layout>
  );
}
function HeaderTop({ idOperation, idString }) {
  const { Title } = Typography;
  return (
    <div className="billing__container">
      <Row xs={24} className="header-top">
        <Col xs={20} md={20} className="header-top__left">
          <Row>
            <Title
              level={3}
              style={{
                position: 'absolute',
                top: 0,
                bottom: 0,
                height: '78%',
                margin: 'auto',
              }}
            >
              Documentos operación {idString}
            </Title>
          </Row>
        </Col>
        <Col xs={4} md={4} className="header-top__right">
          <Row
            style={{
              marginBottom: 30,
              justifyContent: 'right',
            }}
          >
            <BackButton routeName={'/operaciones'}></BackButton>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
