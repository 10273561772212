import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Spin,
  Form,
  Input,
  Button,
  Select,
  Typography,
  notification,
  Tooltip,
} from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import {
  CloseCircleFilled,
  LockOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { registerUserApi } from '../../../../../api/users';
import { getAllSuppliers } from '../../../../../api/supplier';
import { getCompanyIdApi } from '../../../../../api/company';
import { getAccessTokenApi, logout } from '../../../../../api/auth';
import {
  validatePassword,
  validateTextHasCapitalize,
  validateTextHasLowercase,
  validateTextHasNumber,
  validateTextHasCharacter,
} from '../../../../../utils/formValidation';
import { getAllClients } from '../../../../../api/clients';

const AddUserForm = ({ setModalIsVisible, setReloadUsers }) => {
  const [loadingForm, setLoadingForm] = useState(false);
  const [form] = Form.useForm();
  const [lenghtCorrect, setLenghtCorrect] = useState(true);
  const [hasCharacter, setHasCharacter] = useState(true);
  const { Text } = Typography;
  const [rol, setRol] = useState([]);
  const [proveedorSelected, setProveedorSelected] = useState(false);
  const [clientSelected, setClientSelected] = useState(false);
  const [clients, setClients] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const companyId = getCompanyIdApi();
  const [lenghtMaxCorrect, setLenghtMaxCorrect] = useState(true);
  const [hasUpperCase, setHasUpperCase] = useState(true);
  const [hasLowerCase, setHasLowerCase] = useState(true);
  const [hasNumber, setHasNumber] = useState(true);
  const token = getAccessTokenApi();

  const portalTypeOption = [
    {
      label: 'Portal cliente',
      value: 0,
    },
    {
      label: 'Portal proveedor',
      value: 1,
    },
    {
      label: 'Portal proveedor y cliente',
      value: 2,
    },
  ];

  useEffect(() => {
    getAllSuppliers(token, '')
      .then((response) => {
        // console.log(response, 'suppliers');
        if (response.statusCode === 200) {
          let supp = response.result
            .filter((s) => s.active)
            .map((s) => ({
              value: s.supplierId,
              label: s.name,
            }));
          supp.sort((a, b) => a.label.localeCompare(b.label));
          setSuppliers(supp);
        }
      })
      .catch((err) => {});
  }, [token]);

  useEffect(() => {
    getAllClients(token)
      .then((response) => {
        if (response.statusCode === 200) {
          let clients = response.result.map((s) => ({
            value: s.id,
            label: s.name,
            active: s.active,
          }));
          clients.sort((a, b) => a.label.localeCompare(b.label));
          setClients(clients);
        }
      })
      .catch((err) => {});
  }, [token]);

  const checkPassword = (text) => {
    if (validateTextHasCapitalize(text)) {
      setHasUpperCase(true);
    } else {
      setHasUpperCase(false);
    }

    if (validateTextHasLowercase(text)) {
      setHasLowerCase(true);
    } else {
      setHasLowerCase(false);
    }

    if (validateTextHasNumber(text)) {
      setHasNumber(true);
    } else {
      setHasNumber(false);
    }
    if (validateTextHasCharacter(text)) {
      setHasCharacter(true);
    } else {
      setHasCharacter(false);
    }
    if (text.length >= 6) {
      setLenghtCorrect(true);
    } else {
      setLenghtCorrect(false);
    }
    if (text.length <= 10) {
      setLenghtMaxCorrect(true);
    } else {
      setLenghtMaxCorrect(false);
    }
  };

  const onRoledSelected = (role) => {
    if (role === 'SUPPLIER') {
      setProveedorSelected(true);
      form.setFieldsValue({
        portalType: 1,
      });
    } else {
      setProveedorSelected(false);
    }
    if (role === 'CUSTOMER') {
      setClientSelected(true);
      form.setFieldsValue({
        portalType: 0,
      });
    } else {
      setClientSelected(false);
    }

    let copyTmp = [...rol];
    if (rol.includes(role)) {
      copyTmp = copyTmp.filter((e) => e !== role);
    } else {
      copyTmp.push(role);
    }
    if (
      role === 'ADMIN' ||
      role === 'OPERACIONES' ||
      role === 'MANAGER' ||
      role === 'PRICING' ||
      role === 'VENTAS'
    ) {
      copyTmp = copyTmp.filter(
        (e) => e !== 'CUSTOMER' && e !== 'SUPPLIER' && e !== 'FINANCIAL'
      );
    } else if (
      role === 'CUSTOMER' ||
      role === 'SUPPLIER' ||
      role === 'FINANCIAL'
    ) {
      copyTmp = copyTmp.filter(
        (e) =>
          e !== 'ADMIN' &&
          e !== 'OPERACIONES' &&
          e !== 'MANAGER' &&
          e !== 'PRICING' &&
          e !== 'VENTAS' &&
          e !== (role === 'CUSTOMER' ? 'SUPPLIER' : 'CUSTOMER') &&
          e !== (role === 'FINANCIAL' ? 'SUPPLIER' : 'FINANCIAL')
      );
    }
    setRol(copyTmp);
  };
  const addUserFunction = async (values) => {
    setLoadingForm(true);
    let data = values;
    data['roles'] = rol;
    data['company'] = companyId;

    if (proveedorSelected) {
      data['portalType'] = 1;
    }

    if (rol[0] !== 'CUSTOMER') {
      data['customerId'] = '';
    }

    if (rol[0] === 'CUSTOMER') {
      data['portalType'] = 0;
    }

    if (rol.length === 0) {
      notification['error']({
        message: 'Debes seleccionar un perfil',
      });
      setLoadingForm(false);
    } else {
      const response = await registerUserApi(data, token);

      if (response === undefined) {
        notification['error']({
          message: 'Ocurrió un error, Inténtelo más tarde',
        });
        return false;
      }
      if (response.statusCode === 401) {
        notification['error']({
          message: 'Usuario no autorizado.',
        });
        setTimeout(() => {
          logout();
          window.location.reload();
        }, 1500);
        return false;
      }
      if (response.statusCode === 200) {
        setModalIsVisible(false);
        setReloadUsers(true);
        notification['success']({
          message: 'Usuario agregado con éxito',
        });
        setLoadingForm(false);
        return;
      }
      if (response.statusCode === 400) {
        notification['error']({
          message: response.description,
        });
        setLoadingForm(false);
        return;
      } else {
        let messageError = 'Ocurrió un error, Inténtelo otra vez';
        if (response.statusCode === 409) {
          messageError = response.description;
        }

        notification['error']({
          message: messageError,
        });
      }
      setLoadingForm(false);
    }
  };
  return (
    <Spin spinning={loadingForm} tip="Guardando...">
      <Form
        name="add-promo"
        layout="vertical"
        form={form}
        onFinish={addUserFunction}
        initialValues={{ receiveEmails: true, active: true }}
      >
        <Row className="table-company">
          <Col xs={20}>
            <Form.Item
              label="Usuario:"
              name="userName"
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese un usuario',
                },
              ]}
            >
              <Input placeholder="Ingresa tu información" />
            </Form.Item>

            <Form.Item
              label="Usuario estatus:"
              name="active"
              valuePropName="checked"
            >
              <Checkbox>Activo</Checkbox>
            </Form.Item>

            <Form.Item
              hidden={!proveedorSelected}
              label="Proveedor:"
              name="supplierId"
              rules={[
                {
                  required: proveedorSelected,
                  message: 'Por favor ingrese un proveedor',
                },
              ]}
            >
              <Select
                disabled={proveedorSelected === false}
                placeholder="Selecciona una opción"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                showSearch
              >
                {suppliers.map((s) => (
                  <Select.Option key={s.value} value={s.value}>
                    {s.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="Cliente:"
              name="customerId"
              hidden={!clientSelected}
              rules={[
                {
                  required: clientSelected,
                  message: 'Por favor ingrese un cliente',
                },
              ]}
            >
              <Select
                disabled={clientSelected === false}
                placeholder="Selecciona una opción"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                showSearch
              >
                {clients
                  .filter((client) => client.active === true)
                  .map((s) => (
                    <Select.Option key={s.value} value={s.value}>
                      {s.label}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="Contraseña:"
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese una contraseña',
                },
                () => ({
                  validator(rule, value) {
                    if (
                      validatePassword(value) &&
                      lenghtCorrect &&
                      lenghtMaxCorrect
                    ) {
                      return Promise.resolve();
                    } else if (!validatePassword(value)) {
                      return Promise.reject();
                    }
                  },
                }),
              ]}
              hasFeedback
              onChange={(e) => checkPassword(e.target.value)}
            >
              <Input.Password
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Ingresa tu información"
              />
            </Form.Item>
            {!hasUpperCase && (
              <Text type="danger">
                <CloseCircleFilled style={{ marginRight: 8 }} />
                Una mayúscula{' '}
              </Text>
            )}
            <Row>
              {!hasLowerCase && (
                <Text type="danger">
                  <CloseCircleFilled style={{ marginRight: 8 }} />
                  Una minúscula{' '}
                </Text>
              )}
            </Row>
            <Row>
              {!hasNumber && (
                <Text type="danger">
                  <CloseCircleFilled style={{ marginRight: 8 }} />
                  Un número{' '}
                </Text>
              )}
            </Row>
            {!hasCharacter && (
              <Col>
                <Text type="danger">
                  <CloseCircleFilled style={{ marginRight: 8 }} />
                  Un carácter: {'?()!@#$%^&*[]"\';:_-<>. =+/'}
                </Text>
              </Col>
            )}
            {!lenghtCorrect && (
              <Col>
                <Text type="danger">
                  <CloseCircleFilled
                    style={{
                      marginRight: 8,
                      marginBottom: 10,
                    }}
                  />
                  Tiene 6 o más caracteres{' '}
                </Text>
              </Col>
            )}
            {!lenghtMaxCorrect && (
              <Col>
                <Text type="danger">
                  <CloseCircleFilled style={{ marginRight: 8 }} />
                  Máximo 10 caracteres{' '}
                </Text>
              </Col>
            )}
            <Form.Item
              label="Confirmar contraseña:"
              name="confirmPassword"
              dependencies={['password']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Por favor confirme la contraseña',
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      'Las dos contraseñas ingresadas no son iguales'
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Ingresa tu información"
              />
            </Form.Item>
            <Form.Item
              label="Nombre(s):"
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese un nombre',
                },
              ]}
            >
              <Input placeholder="Ingresa tu información" />
            </Form.Item>
            <Form.Item
              label="Apellido paterno:"
              name="lastName"
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese apellido paterno',
                },
              ]}
            >
              <Input placeholder="Ingresa tu información" />
            </Form.Item>
            <Form.Item
              label="Apellido materno:"
              name="mothersLastName"
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese apellido materno',
                },
              ]}
            >
              <Input placeholder="Ingresa tu información" />
            </Form.Item>
            <Form.Item
              label={
                <span>
                  Teléfono:&nbsp;
                  <Tooltip title="El número telefónico debe contener 10 dígitos">
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
              }
              name="phoneNumber"
              rules={[
                {
                  required: true,
                  message: 'Por favor ingresa número telefónico',
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || value.toString().length === 10) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      'Por favor ingresa número telefónico a 10 dígitos'
                    );
                  },
                }),
              ]}
            >
              <Input placeholder="Ingresa tu información" />
            </Form.Item>
            <Form.Item
              label="Correo electrónico:"
              name="email"
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese un email',
                },
              ]}
            >
              <Input type="email" placeholder="Ingresa tu información" />
            </Form.Item>

            <Col style={{ marginLeft: 10, marginTop: 8 }}>
              {/* <Form.Item label="Perfiles:" name="profiles"> */}
              <Checkbox
                onChange={() => onRoledSelected('ADMIN')}
                checked={rol.includes('ADMIN')}
              >
                ADMIN
              </Checkbox>
              <Checkbox
                onChange={() => onRoledSelected('MANAGER')}
                checked={rol.includes('MANAGER')}
              >
                GERENTE
              </Checkbox>
              <Checkbox
                onChange={() => onRoledSelected('OPERACIONES')}
                checked={rol.includes('OPERACIONES')}
              >
                OPERACIONES
              </Checkbox>
              <Checkbox
                onChange={() => onRoledSelected('FINANCIAL')}
                checked={rol.includes('FINANCIAL')}
              >
                FINANCIERO
              </Checkbox>
              <Checkbox
                onChange={() => onRoledSelected('SUPPLIER')}
                checked={rol.includes('SUPPLIER')}
              >
                PROVEEDOR
              </Checkbox>
              <Checkbox
                onChange={() => onRoledSelected('CUSTOMER')}
                checked={rol.includes('CUSTOMER')}
              >
                CLIENTE
              </Checkbox>
              <Checkbox
                onChange={() => onRoledSelected('PRICING')}
                checked={rol.includes('PRICING')}
              >
                PRICING
              </Checkbox>
              <Checkbox
                onChange={() => onRoledSelected('VENTAS')}
                checked={rol.includes('VENTAS')}
                style={{ marginTop: 10, marginBottom: 10 }}
              >
                VENTAS
              </Checkbox>
              {/* </Form.Item> */}
            </Col>

            <Form.Item
              label="Portal:"
              name="portalType"
              hidden={proveedorSelected || clientSelected}
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese la seccion que veria',
                },
              ]}
            >
              <Select disabled={false} placeholder="Selecciona una opción">
                {portalTypeOption.map((s) => (
                  <Select.Option key={s.value} value={s.value}>
                    {s.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="Permiso de recibir correo de notificación de aclaraciones:"
              name="receiveEmails"
              valuePropName="checked"
            >
              <Checkbox>Recibir notificación</Checkbox>
            </Form.Item>

            <Row justify="end">
              <Form.Item>
                <Button htmlType="submit" className="btn-enviar" block>
                  Guardar
                </Button>
              </Form.Item>
            </Row>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default AddUserForm;
