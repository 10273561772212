import React, { useState, useEffect } from 'react';
import { Button, Modal as ModalAntd, Space, Table, Tooltip, Form } from 'antd';
import {
  ReloadOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
  ExclamationCircleOutlined,
  EditOutlined,
} from '@ant-design/icons';
import Modal from '../../../General/Modal';
import { fetchApi } from '../../../../helpers/fetch';
import { getAccessTokenApi } from '../../../../api/auth';
import {
  METHOD_DELETE,
  METHOD_GET,
  ROL_ADMIN,
  ROL_LAYOUT,
  ROL_MANAGER,
  ROL_VENTAS,
} from '../../../../utils/constants';
import {
  API_URL_CUSTOMER_CONTACTS,
  API_URL_SUPPLIER_CONTACTS,
} from '../../../../helpers/urls';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import AddCustomerContact from '../CustomerContactsTable/AddCustomerContact';
import EditCustomerContact from '../CustomerContactsTable/EditCustomerContact';
import { formatPhoneNumber } from '../../../../utils/general';
import useUser from '../../../../hooks/useUser';
const CustomerContactsTable = ({ client }) => {
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [reloadContacts, setReloadContacts] = useState(false);
  const [titleModal, setTitleModal] = useState(false);
  const [contextModal, setContextModal] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const hasSelected = selectedRowKeys.length > 0;
  const { confirm } = ModalAntd;
  const token = getAccessTokenApi();
  let { clientId } = useParams();
  const [customerContacts, setCustomerContacts] = useState([]);
  const { person } = useUser();

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  useEffect(() => {
    const config = {
      method: METHOD_GET,
      url: `Customer/${clientId}/Contacts`,
      data: null,
      token,
    };
    fetchApi(config)
      .then((response) => {
        if (response) {
          let supContacts = response.result;

          if (supContacts) {
            supContacts.map((cat, index) => {
              cat['key'] = index;
            });
          }
          setCustomerContacts(supContacts);
        }
        setReloadContacts(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [reloadContacts, clientId, token]);

  const showModal = (type, contactId) => {
    if (type === 'Add') {
      setModalIsVisible(true);
      setTitleModal(`Agregar contacto cliente ${clientId}`);
      setContextModal(
        <AddCustomerContact
          setModalIsVisible={setModalIsVisible}
          setReloadContacts={setReloadContacts}
          clientId={clientId}
        ></AddCustomerContact>
      );
    } else if (type === 'Edit') {
      setModalIsVisible(true);
      setTitleModal(`Editar contacto cliente ${clientId}`);
      setContextModal(
        <EditCustomerContact
          setModalIsVisible={setModalIsVisible}
          setReloadContacts={setReloadContacts}
          contactId={contactId}
        ></EditCustomerContact>
      );
    } else if (type === 'Watch') {
      setModalIsVisible(true);
      setTitleModal('Contacto #123');
      setContextModal();
      // <AddClientContact
      //     setModalIsVisible={setModalIsVisible}
      //     setReloadContacts={setReloadContacts}
      //     clientId={clientId}
      //     type="watch"
      // ></AddClientContact>
    }
  };

  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
      width: 100,
      align: 'center',
      fixed: 'left',
    },
    {
      title: 'Apellido paterno',
      dataIndex: 'lastName',
      key: 'lastName',
      width: 100,
      align: 'center',
    },
    {
      title: 'Apellido materno',
      dataIndex: 'mothersLastName',
      key: 'mothersLastName',
      width: 100,
      align: 'center',
    },
    {
      title: 'Teléfono casa/oficina',
      dataIndex: 'phone',
      key: 'phone',
      width: 100,
      align: 'center',
      render: (phone) => <span>{formatPhoneNumber(phone)}</span>,
    },
    {
      title: 'Celular 1',
      dataIndex: 'cellPhone1',
      key: 'cellPhone1',
      width: 100,
      align: 'center',
      render: (cellPhone1) => <span>{formatPhoneNumber(cellPhone1)}</span>,
    },
    {
      title: 'Celular 2',
      dataIndex: 'cellPhone2',
      key: 'cellPhone2',
      width: 100,
      align: 'center',
      render: (cellPhone2) => <span>{formatPhoneNumber(cellPhone2)}</span>,
    },
    {
      title: 'Correo electrónico',
      dataIndex: 'email',
      key: 'email',
      width: 200,
      align: 'center',
    },
    {
      title: 'Acción',
      key: 'action',
      width: 100,
      render: (text, record) => (
        <>
          {[ROL_ADMIN, ROL_VENTAS, ROL_LAYOUT].some((r) =>
            person.roles.includes(r)
          ) && (
            <>
              <Space size="middle">
                <Tooltip title="Editar">
                  <Button
                    onClick={() => showModal('Edit', record.id)}
                    type="primary"
                  >
                    <EditOutlined />
                  </Button>
                </Tooltip>

                <Tooltip title="Eliminar">
                  <Button
                    danger
                    type="primary"
                    onClick={() => handleDeleteContact(record.id)}
                  >
                    <DeleteOutlined />
                  </Button>
                </Tooltip>
              </Space>
            </>
          )}
        </>
      ),
      align: 'center',
    },
  ];

  const handleDeleteContact = (id) => {
    confirm({
      title: 'Eliminar contacto',
      icon: <ExclamationCircleOutlined />,
      content: '¿Estás seguro que deseas eliminar el contacto seleccionado?',
      okText: 'Si',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteContact(id);
      },
      onCancel() {},
    });
  };

  const deleteContact = async (id) => {
    let values = {};
    values['customerId'] = clientId;
    values['id'] = id;

    const config = {
      method: METHOD_DELETE,
      url: `${API_URL_CUSTOMER_CONTACTS}`,
      data: values,
      token,
      showNotification: true,
    };
    const result = await fetchApi(config);

    if (result) {
      if (result.statusCode === 200) {
        setReloadContacts(true);
      }
    }
  };

  return (
    <div>
      {[ROL_ADMIN, ROL_VENTAS, ROL_LAYOUT].some((r) =>
        person.roles.includes(r)
      ) && (
        <Button
          className="btn-download"
          onClick={() => showModal('Add', clientId)}
          icon={<PlusCircleOutlined />}
        >
          Agregar
        </Button>
      )}
      {/* <Button
                disabled={!hasSelected}
                className="btn-delete"
                onClick={(e) => handleDeleteContact(rowSelection)}
                icon={<DeleteOutlined />}
            >
                Eliminar
            </Button> */}
      <Button
        className="btn-download"
        icon={<ReloadOutlined />}
        onClick={(e) => setReloadContacts(true)}
      >
        Refrescar
      </Button>
      {selectedRowKeys.length > 0 && (
        <p>Seleccionados: {selectedRowKeys.length} elementos</p>
      )}
      {/* <Button className="btn-download" onClick={() => showModal('Edit')}>
                Clientes
            </Button> */}
      <Table
        // rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
        dataSource={customerContacts}
        columns={columns}
        bordered
        size="middle"
        scroll={{ x: 1200 }}
        // setSelectedRowKeys
        className="table-striped-rows"
        // rowSelection={rowSelection}
        loading={reloadContacts}
      />

      <Modal
        title={titleModal}
        visible={modalIsVisible}
        setIsVisible={setModalIsVisible}
        width={1000}
      >
        {contextModal}
      </Modal>
    </div>
  );
};

export default CustomerContactsTable;
