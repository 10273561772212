import React, { useState } from 'react';
import {
  Typography,
  Button,
  Upload,
  Row,
  Col,
  message,
  Divider,
  notification,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { validateCfdiApi } from '../../../../api/billing';
import toBase64 from '../../../../utils/toBase64';
import { getCompanyIdApi } from '../../../../api/company';
import { getAccessTokenApi } from '../../../../api/auth';
import useDataGeneral from '../../../../hooks/useDataGeneral';

import './UploadFileForm.scss';

export default function UploadFileForm({
  setModalIsVisible,
  showModal,
  type,
  operation,
  operationId,
}) {
  const [fileListPdf, setFileListPdf] = useState([]);
  const [fileListXML, setFileListXML] = useState([]);
  const [fileListCfdi, setFileListCfdi] = useState([]);
  const [fileXML, setFileXML] = useState();
  const [filePDF, setFilePDF] = useState();
  const [uploading, setUploading] = useState(false);
  const companyId = getCompanyIdApi();
  const token = getAccessTokenApi();
  const { Title, Text } = Typography;
  const { setStatusData } = useDataGeneral();
  const handleUpload = async () => {
    if (fileListPdf.length === 0 || fileListXML.length === 0) {
      message.error('Debe de subir todos los archivos.');
      return;
    }

    if (
      fileListPdf[0].name.split('.')[0] !== fileListXML[0].name.split('.')[0]
    ) {
      notification['error']({
        message: 'Los nombres de los archivos no son iguales.',
      });
      return;
    }
    setUploading(true);
    let nameXml = fileListXML[0].name;

    const cfdiFileXML = await toBase64(fileListXML[0]);
    const cfdiFilePdf = await toBase64(fileListPdf[0]);
    // let cfdiFileCfdi = [];
    let cfdiFileCfdi = {
      extension: '',
      stringFilecontent: '',
      contentType: '',
      fileName: '',
    };

    if (fileListCfdi.length !== 0) {
      let stringContent = '';
      // if(fileListCfdi[0].type === 'image/jpeg'){
      //     stringContent = fileListCfdi[0].thumbUrl.substr(
      //         fileListCfdi[0].thumbUrl.indexOf(',') + 1);
      // } else {
      //     stringContent = await toBase64(fileListCfdi[0])
      //     stringContent = stringContent.split(',')[1];
      // }

      stringContent = await toBase64(fileListCfdi[0]);
      stringContent = stringContent.split(',')[1];
      cfdiFileCfdi.stringFilecontent = stringContent;
      // cfdiFileCfdi.stringFilecontent = fileListCfdi[0].type === 'image/jpeg' ? fileListCfdi[0].thumbUrl.substr(
      //     fileListCfdi[0].thumbUrl.indexOf(',') + 1
      // ) : await toBase64(fileListCfdi[0]);
      cfdiFileCfdi.extension = fileListCfdi[0].type.substr(
        fileListCfdi[0].type.indexOf('/') + 1
      );
      cfdiFileCfdi.contentType = fileListCfdi[0].type;
      cfdiFileCfdi.fileName = fileListCfdi[0].name;
    }
    let data = {};
    if (operation) {
      data = {
        company: companyId,
        cfdiFileXML: cfdiFileXML.split(',')[1],
        cfdiFilePdf: cfdiFilePdf.split(',')[1],
        validateAr: true,
        // cfdiFileCfdi:
        //     cfdiFileCfdi.length !== 0 ? cfdiFileCfdi.split(',')[1] : [],
      };
    } else {
      data = {
        company: companyId,
        cfdiFileXML: cfdiFileXML.split(',')[1],
        cfdiFilePdf: cfdiFilePdf.split(',')[1],
        // cfdiFileCfdi:
        //     cfdiFileCfdi.length !== 0 ? cfdiFileCfdi.split(',')[1] : [],
      };
    }

    const response = await validateCfdiApi(token, data);
    // console.log(response, 'validare');
    if (response === undefined) {
      let messageError = 'Ocurrio un problema, inténtelo más tarde';

      notification['error']({
        message: messageError,
      });

      setUploading(false);
      return;
    }
    if (response.statusCode === 434) {
      notification['error']({
        message: response.description,
      });

      setUploading(false);
      return;
    }

    if (response.status === 200) {
      notification['success']({
        message: 'Documentos validados con éxito, proceda a guardar factura.',
      });
      setModalIsVisible(false);
      if (operation) {
        showModal(
          'Invoice',
          operation,
          operation.invoiceId,
          response,
          nameXml,
          fileXML,
          filePDF
        );
      } else {
        showModal(
          'addBillForm',
          response,
          cfdiFileXML,
          cfdiFilePdf,
          fileListCfdi.length !== 0 ? cfdiFileCfdi : [],
          nameXml,
          '',
          operationId
        );
      }
    } else {
      let messageError =
        'Ocurrio un problema al validar documentos, Inténtelo otra vez';

      if (response.status === 400) {
        messageError = response.description;
      }
      if (response.status === 404) {
        messageError = response.description;
      }

      if (response.statusCode === 409) {
        messageError = response.description;
      }

      notification['error']({
        message: messageError,
      });

      setUploading(false);
    }
    setUploading(false);
  };

  const xmlChange = (info) => {
    let file = [...info.fileList];
    setFileXML(file);
  };
  const pdfChange = (info) => {
    let file = [...info.fileList];
    setFilePDF(file);
  };
  const propsXml = {
    onRemove: (file) => {
      const index = fileListXML.indexOf(file);
      const newFileList = fileListXML.slice();
      newFileList.splice(index, 1);
      setFileListXML(newFileList);
    },
    beforeUpload: (file) => {
      setFileListXML([file]);
      return false;
    },
    onChange: xmlChange,
  };

  const propsPdf = {
    onRemove: (file) => {
      const indexPdf = fileListPdf.indexOf(file);
      const newFileListPdf = fileListPdf.slice();
      newFileListPdf.splice(indexPdf, 1);
      setFileListPdf(newFileListPdf);
    },
    beforeUpload: (file) => {
      setFileListPdf([file]);
      return false;
    },
    onChange: pdfChange,
  };
  const propsCfdi = {
    onRemove: (file) => {
      const indexCfdi = fileListCfdi.indexOf(file);
      const newFileListCfdi = fileListCfdi.slice();
      newFileListCfdi.splice(indexCfdi, 1);
      setFileListCfdi(newFileListCfdi);
    },
    beforeUpload: (file) => {
      setFileListCfdi([file]);
      return false;
    },
  };

  return (
    <div className="upload-file-form">
      <Row>
        <Col xs={24} lg={10}>
          <Title level={5} type="secondary">
            Seleccionar su archivo CFDi (XML)
            <Text type="danger">*</Text>
          </Title>
        </Col>

        <Col xs={24} lg={14} className="upload-file-form__container">
          <Upload
            {...propsXml}
            accept={'.xml'}
            multiple={false}
            maxCount={1}
            listType="picture"
          >
            <Button
              className="upload-file-btn"
              icon={<UploadOutlined />}
              size="large"
            >
              Subir Archivo
            </Button>
          </Upload>
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col xs={24} lg={10}>
          <Title level={5} type="secondary">
            {'Seleccionar su archivo CFDi (PDF)'}
            <Text type="danger">*</Text>
          </Title>
        </Col>
        <Col xs={24} lg={14} className="upload-file-form__container">
          <Upload {...propsPdf} accept={'.pdf'} maxCount={1} listType="picture">
            <Button
              className="upload-file-btn"
              icon={<UploadOutlined />}
              size="large"
              // disabled={fileListXML.length === 1}
            >
              Subir Archivo
            </Button>
          </Upload>
        </Col>
      </Row>
      <Divider />
      {type === 'addFile' && !operation && (
        <>
          <Row>
            <Col xs={24} lg={10}>
              <Title level={5} type="secondary">
                {
                  'Evidencias '
                  // 'Seleccionar su archivo CFDi_Acuse de recibo'
                }
              </Title>
            </Col>
            <Col xs={24} lg={14} className="upload-file-form__container">
              <Upload
                {...propsCfdi}
                // accept={'.pdf'}
                maxCount={1}
                listType="picture"
              >
                <Button
                  className="upload-file-btn"
                  icon={<UploadOutlined />}
                  size="large"
                  // disabled={fileListXML.length === 0}
                >
                  Subir Archivo
                </Button>
              </Upload>
            </Col>
          </Row>
        </>
      )}

      <Row justify="end" style={{ marginRight: 20, marginTop: 20 }}>
        <Col xs={5}>
          <Button
            onClick={handleUpload}
            className="btn-enviar"
            size="large"
            loading={uploading}
            style={{ width: '100%' }}
          >
            Validar Documentos
          </Button>
        </Col>
      </Row>
    </div>
  );
}
