import React, { useEffect, useState } from 'react';
import { Row, Col, Spin, Form, Input, Button, Select } from 'antd';
import { getAccessTokenApi } from '../../../../../api/auth';
import { METHOD_GET, METHOD_POST } from '../../../../../utils/constants';
import { API_URL_CATALOG_VALUES } from '../../../../../helpers/urls';
import { fetchApi } from '../../../../../helpers/fetch';
import {
  CITY_CATALOG_ID,
  COUNTRY_CATALOG_ID,
  STATE_CATALOG_ID,
} from '../../../../../helpers/constants';
import { getCatalogValueHandle } from '../../../../../helpers/handlerFunctionApi';

const AddSupllierContact = ({
  setModalIsVisible,
  setReloadContainersYard,
  supplierId,
}) => {
  const [loadingForm, setLoadingForm] = useState(false);
  const [form] = Form.useForm();
  const token = getAccessTokenApi();
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [countrySelected, setCountrySelected] = useState(0);

  useEffect(() => {
    setLoadingForm(true);
    const config = {
      method: METHOD_GET,
      url: `${API_URL_CATALOG_VALUES}/GetAll/${COUNTRY_CATALOG_ID}`,
      data: null,
      token,
      // showNotification: true
    };
    fetchApi(config)
      .then((response) => {
        if (response) {
          let calogsValues = response.result;

          if (calogsValues) {
            calogsValues.map((cat, index) => {
              cat['key'] = index;
              cat['label'] = cat.description;
              cat['value'] = cat.id;
            });
          }
          setCountries(calogsValues);
        }
        setLoadingForm(false);
      })
      .catch((err) => {
        console.log(err);
      });
    //   getStateCatalogHandle();
  }, []);

  const onChangeCountryOrigin = (value) => {
    if (value !== countrySelected) {
      form.setFieldsValue({
        state: '',
        city: '',
      });
    }
    setCountrySelected(value);
    getCatalogValueHandle(STATE_CATALOG_ID, setStates, setLoadingForm, value);
    getCatalogValueHandle(CITY_CATALOG_ID, setCities, setLoadingForm, value);
  };
  const onChangeState = (value) => {
    getCatalogValueHandle(CITY_CATALOG_ID, setCities, setLoadingForm, value);
    form.setFieldsValue({
      city: '',
    });
  };

  const onFinish = async (values) => {
    setLoadingForm(true);
    values['supplierId'] = supplierId;

    const config = {
      method: METHOD_POST,
      url: `CY`,
      data: values,
      token,
      showNotification: true,
    };
    const result = await fetchApi(config);
    if (result) {
      if (result.statusCode === 200) {
        // form.resetFields();
        setModalIsVisible(false);
        setReloadContainersYard(true);
      }
    }

    setLoadingForm(false);
  };

  return (
    <Spin spinning={loadingForm} tip="Cargando...">
      <Form
        name="add-promo"
        layout="vertical"
        form={form}
        onFinish={onFinish}
        initialValues={{ active: true }}
      >
        <div>
          <Row xs={24} className="table-company">
            <Col xs={20}>
              <Form.Item
                label="Nombre:"
                name="name"
                rules={[
                  {
                    required: true,
                    message: 'Por favor ingrese nombre',
                  },
                ]}
              >
                <Input placeholder="Ingresa tu información" />
              </Form.Item>
              <Form.Item label="Dirección:" name="address">
                <Input placeholder="Ingresa tu información" />
              </Form.Item>
              <Form.Item label="País" name="country">
                <Select
                  // disabled={disableAllInputs}
                  // disabled={disableAllInputs || service === 'land'}
                  placeholder="Selecciona una opción"
                  onChange={onChangeCountryOrigin}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  // style={{minWidth: width > 480 ? 220 : '100%', width: width > 480 ? 220 : '100%'}}
                >
                  {countries.map((s) => (
                    <Select.Option key={s.value} value={s.value}>
                      {s.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label="Estado:" name="state">
                <Select
                  // disabled={disableAllInputs}
                  placeholder="Selecciona una opción"
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={onChangeState}
                  // style={{minWidth: width > 480 ? 220 : '100%', width: width > 480 ? 220 : '100%'}}
                >
                  {states.map((s) => (
                    <Select.Option key={s.value} value={s.value}>
                      {s.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label="Ciudad:" name="city">
                <Select
                  // disabled={disableAllInputs}
                  placeholder="Selecciona una opción"
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  // style={{minWidth: width > 480 ? 220 : '100%', width: width > 480 ? 220 : '100%'}}
                >
                  {cities.map((s) => (
                    <Select.Option key={s.value} value={s.value}>
                      {s.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Col span={24}>
                <Row justify="end">
                  <Form.Item>
                    <Button htmlType="submit" className="btn-enviar" block>
                      Guardar
                    </Button>
                  </Form.Item>
                </Row>
              </Col>
            </Col>
          </Row>
        </div>
      </Form>
    </Spin>
  );
};

export default AddSupllierContact;
