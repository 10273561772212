import React from 'react';
import { Col, Layout, Row, Typography } from 'antd';
import GreetingsTop from '../../../components/Admin/GreetingsTop/GreetingsTop';
import OperationsTable from '../../../components/Logistic/Operations/OperationsTable';
import CallLogTable from '../../../components/Logistic/CallLog/CallLogTable';

export default function CallLogPage() {
  
  return (
    <Layout>
      <GreetingsTop />
      <HeaderTop />

      <div className="billing__container">
        <CallLogTable />
      </div>
    </Layout>
  );
}

function HeaderTop() {
  const { Title } = Typography;
  return (
    <div className="billing__container">
      <Row className="header-top">
        <Col xs={24} md={12} className="header-top__left">
          <Title level={3}>Llamadas</Title>
        </Col>
      </Row>
    </div>
  );
}
