import React, { useEffect, useState } from 'react';
import {
  Table,
  notification,
  Button,
  Col,
  Row,
  Modal as ModalAntd,
  Input,
  Space,
  Tag,
} from 'antd';
import {
  SearchOutlined,
  FileExcelFilled,
  FilePdfFilled,
} from '@ant-design/icons';
import { getAllSuppliers, deleteSupplierApi } from '../../../../api/supplier';
import { getAccessTokenApi, logout } from '../../../../api/auth';
import AddSupplierForm from '../SuppliersTable/AddSupplierForm';
import EditSupplierForm from '../SuppliersTable/EditSupplierForm';
import Modal from '../../../General/Modal';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { ROL_ADMIN, ROL_LAYOUT } from '../../../../utils/constants';
import useUser from '../../../../hooks/useUser';
import XLSX from 'xlsx';
import jsPDF from 'jspdf';
import moment from 'moment';
import { getFilteredDataFromLocalStorage, saveFilteredDataToLocalStorage } from '../../../../helpers/saveFilterData';

export default function SuppliersTable() {
  const [reloadSuppliers, setReloadSuppliers] = useState(true);
  const [supplierSelected, setSupplierSelected] = useState(null);
  const [suppliersTable, setSuppliersTable] = useState([]);
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [titleModal, setTitleModal] = useState(false);
  const [contextModal, setContextModal] = useState(null);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const { person } = useUser();
  const [disabledButton, setDisabledButton] = useState(false);
  const { confirm } = ModalAntd;
  useEffect(() => {
    if (reloadSuppliers) {
      const token = getAccessTokenApi();
      let filters = '';
      getAllSuppliers(token, filters).then((response) => {
        // console.log(response, 'suppliers');
        if (response === undefined) {
          notification['error']({
            message: 'Ocurrió un error, Inténtelo más tarde',
          });
          return false;
        }
        if (response.statusCode === 200) {
          response.result.forEach((element, index) => {
            element['key'] = index;
          });
          setSuppliersTable(response.result);
        } else {
          notification['error']({
            message: 'Usuario no autorizado',
          });

          setTimeout(() => {
            logout();
            window.location.reload();
          }, 1500);
          return;
        }
        setReloadSuppliers(false);
      });
    }
  }, [reloadSuppliers]);
  useEffect(() => {
    const datosObtenidos = getFilteredDataFromLocalStorage('suppliers');
    if (datosObtenidos !== null) {
    setFilteredInfo(datosObtenidos.filters);
    }
  },[])
  const checkUser = (id, supplier) => {
    if (supplier.active === false) {
      setDisabledButton(true);
    } else {
      setDisabledButton(false);
    }
    if (supplierSelected === id) {
      setSupplierSelected(null);
    } else {
      setSupplierSelected(id);
    }
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            // this.searchInput = node;
          }}
          placeholder={`Buscar`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => {
              handleReset(clearFilters);
              handleSearch(selectedKeys, confirm, dataIndex);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Limpiar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    filteredValue: filteredInfo[dataIndex] || null,
    onFilterDropdownVisibleChange: (visible) => {},
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  const columns = [
    {
      title: 'Selecciona',
      width: 100,
      dataIndex: 'supplierId',
      key: 'company',
      render: (supplierId, supplier) => (
        <Checkbox
          // type="primary"                    {sectionContacts?.find((sc) => sc.sectionId === id)?.name}

          // disabled={
          //     !suppliersTable?.find(
          //         (st) => st.supplierId === supplierId
          //     )?.active
          // }
          onChange={() => checkUser(supplierId, supplier)}
          checked={supplierSelected === supplierId}
          className="button-detail"
        ></Checkbox>
      ),
      ellipsis: true,
    },
    {
      title: 'Id. proveedor',
      width: 100,
      dataIndex: 'supplierId',
      key: 'supplierId',
      ...getColumnSearchProps('supplierId'),
      ellipsis: true,
    },
    {
      title: 'Proveedor',
      dataIndex: 'name',
      key: 'name',
      width: 150,
      ...getColumnSearchProps('name'),
      ellipsis: true,
    },
    {
      title: 'Estatus',
      dataIndex: 'active',
      key: 'active',
      filters: [
        { text: 'Activo', value: true },
        { text: 'Inactivo', value: false },
      ],
      filteredValue: filteredInfo.active || null,
      onFilter: (value, record) => record.active === value,
      render: (active) => (
        <Tag color={active === true ? 'green' : 'red'}>
          {active ? 'Activo'.toUpperCase() : 'inactivo'.toUpperCase()}
        </Tag>
      ),
      width: 150,
      ellipsis: true,
    },
    {
      title: 'RFC',
      dataIndex: 'fiscalID',
      key: 'fiscalID',
      width: 150,
      ...getColumnSearchProps('fiscalID'),
      ellipsis: true,
    },
    {
      title: 'Dirección',
      dataIndex: 'address',
      key: 'address',
      width: 150,
      ...getColumnSearchProps('address'),
      ellipsis: true,
    },
    {
      title: 'Teléfono',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      width: 150,
      ...getColumnSearchProps('phoneNumber'),
      ellipsis: true,
    },
    {
      title: 'Correo electrónico',
      dataIndex: 'emailAddress',
      key: 'emailAddress',
      width: 150,
      ...getColumnSearchProps('emailAddress'),
      ellipsis: true,
    },
    // {
    //     title: 'Ejecutivo CTA',
    //     dataIndex: 'email',
    //     key: 'email',
    //     width: 150,
    // },
  ];
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const handleChange = (pagination, filters, sorter, extra) => {
    saveFilteredDataToLocalStorage('suppliers', pagination, sorter, filters);
    setFilteredInfo(filters);
  };
  const showModal = (type) => {
    if (type === 'Add') {
      setModalIsVisible(true);
      setTitleModal('Agregar proveedor');
      setContextModal(
        <AddSupplierForm
          setModalIsVisible={setModalIsVisible}
          setReloadSuppliers={setReloadSuppliers}
        ></AddSupplierForm>
      );
    } else if (type === 'Edit') {
      if (supplierSelected !== null) {
        setModalIsVisible(true);

        setTitleModal('Editar proveedor');
        setContextModal(
          <EditSupplierForm
            setModalIsVisible={setModalIsVisible}
            setReloadSuppliers={setReloadSuppliers}
            idSupplier={supplierSelected}
          ></EditSupplierForm>
        );
      } else if (supplierSelected === null) {
        notification['error']({
          message: 'Debes seleccionar un proveedor',
        });
      }
    }
  };
  const handleDeleteSupplier = (id) => {
    if (supplierSelected !== null) {
      confirm({
        title: 'Desactivar proveedor',
        icon: <ExclamationCircleOutlined />,
        content:
          '¿Estás seguro que deseas desactivar el proveedor seleccionado?, no se podrá reactivar después',
        okText: 'Si',
        okType: 'danger',
        cancelText: 'No',
        onOk() {
          deleteSupplier(id);
        },
        onCancel() {},
      });
    } else {
      notification['error']({
        message: 'Debes seleccionar un proveedor a desactivar',
      });
    }
  };
  const deleteSupplier = async (id) => {
    const accessToken = getAccessTokenApi();
    const result = await deleteSupplierApi(accessToken, id);
    if (result?.statusCode === 409) {
      notification['error']({
        message: result.description,
      });
    }
    if (result?.statusCode === 405) {
      notification['error']({
        message: result.description,
      });
    }
    if (result?.statusCode === 404) {
      notification['error']({
        message: result.description,
      });
    }
    if (result?.statusCode === 200) {
      notification['success']({
        message: 'Proveedor desactivado exitosamente.',
      });
      setSupplierSelected(null);
    }
    setReloadSuppliers(true);
  };
  const exportPdf = (suppliers, startDate, endDate) => {
    let mstrTitulo = '';
    let mstrPdf = '';
    mstrTitulo = `Proveedores`;
    mstrPdf = `Proveedores-${moment().format('L')}.pdf`;

    let registros = [];
    suppliers.forEach((element) => {
      registros.push([
        element.supplierId,
        element.name,
        element.active ? 'Activo'.toUpperCase() : 'inactivo'.toUpperCase(),
        element.fiscalID,
        element.phoneNumber,
        element.emailAddress,
      ]);
    });

    let fecha = moment().format('LLL');
    let doc = new jsPDF('landscape');
    doc.setFontSize(12);

    let xFecha = 220;
    let yFecha = 10;

    let totalPagesExp = '{total_pages_count_string}';

    let pageContent = function (data) {
      //header
      doc.text(15, 10, mstrTitulo);
      doc.text(-10, -10, mstrTitulo);
      doc.text(fecha, xFecha, yFecha);
      // FOOTER
      let str = 'Página ' + data.pageCount;
      //Total page number plugin only available in jspdf v1.0+
      if (typeof doc.putTotalPages === 'function') {
        str = str + ' de ' + totalPagesExp;
      }
      doc.setFontSize(10);
      doc.text(
        str,
        data.settings.margin.left,
        doc.internal.pageSize.height - 10
      );
    };
    doc.autoTable({
      A3: { v: 'Id. proveedor', t: 's' },
      B3: { v: 'Nombre(s)', t: 's' },
      C3: { v: 'Estatus', t: 's' },
      D3: { v: 'RFC', t: 's' },
      E3: { v: 'Teléfono', t: 's' },
      F3: { v: 'Correo electrónico', t: 's' },
      head: [
        [
          'Id. proveedor',
          'Nombre(s)',
          'Estatus',
          'RFC',
          'Teléfono',
          'Correo electrónico',
        ],
      ],
      didDrawPage: pageContent,
      theme: 'grid',
      headStyles: {
        valign: 'middle',
        halign: 'center',
        fillColor: [230, 81, 0],
        textColor: [255, 255, 255],
        // cellWidth: { 3: 200 },
      },
      body: registros,
      columnStyles: {
        text: {
          cellWidth: 'wrap',
        },
      },
    });
    // call footer() after each doc.addPage()
    // and before doc.save() do not forget put
    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(totalPagesExp);
    }
    //spinner.style.display = 'none';
    doc.save(mstrPdf);
  };
  const generateExcel = (entries) => {
    // setExcelLoading(true);
    let filename = '';
    let mstrTitulo = '';
    filename = `Proveedores-${moment().format('L')}.xlsx`;
    mstrTitulo = `Proveedores`;

    let fecha = moment().format('LLL');
    const ws_name = 'Hoja1';
    let wb = {
      SheetNames: [],
      Sheets: {},
    };
    let ws = {};
    let renInicial = 4;

    // var mstrTitulo = `Facturación y notas de crédito del ${dateRange.startDate} al: ${dateRange.endDate}`;

    ws = {
      A1: { v: mstrTitulo, t: 's' },
      G1: { v: fecha, t: 's' },

      A3: { v: 'Id. proveedor', t: 's' },
      B3: { v: 'Nombre(s)', t: 's' },
      C3: { v: 'Estatus', t: 's' },
      D3: { v: 'RFC', t: 's' },
      E3: { v: 'Teléfono', t: 's' },
      F3: { v: 'Correo electrónico', t: 's' },
    };
    for (let i in entries) {
      const { supplierId, name, active, fiscalID, phoneNumber, emailAddress } =
        entries[i];
      ws['A' + renInicial] = { v: supplierId, t: 's' };
      ws['B' + renInicial] = { v: name, t: 's' };
      ws['C' + renInicial] = {
        v: active ? 'Activo'.toUpperCase() : 'inactivo'.toUpperCase(),
        t: 's',
      };
      ws['D' + renInicial] = { v: fiscalID !== null ? fiscalID : '', t: 's' };
      ws['E' + renInicial] = {
        v: phoneNumber !== null ? phoneNumber : '',
        t: 's',
      };
      ws['F' + renInicial] = {
        v: emailAddress !== null ? emailAddress : '',
        t: 's',
      };
      renInicial++;
    }
    // var wscols = [{ wch: 30 }, { wch: 40 }, { wch: 35 }, { wch: 35 }];
    // ws['!cols'] = wscols;
    ws['!ref'] = XLSX.utils.encode_range({
      s: { c: 0, r: 0 },
      e: { c: 15, r: renInicial },
    });
    wb.SheetNames.push(ws_name);
    wb.Sheets[ws_name] = ws;
    XLSX.writeFile(wb, filename, { cellStyles: true });
    // setExcelLoading(false);
  };
  return (
    <>
      {[ROL_ADMIN, ROL_LAYOUT].some((r) => person.roles.includes(r)) && (
        <Row>
          <Col span={24}>
            <Button className="btn-download" onClick={() => showModal('Add')}>
              Agregar
            </Button>

            <Button className="btn-download" onClick={() => showModal('Edit')}>
              Editar
            </Button>
            <Button
              className="btn-delete"
              disabled={disabledButton || supplierSelected === null}
              onClick={() => handleDeleteSupplier(supplierSelected)}
            >
              Desactivar
            </Button>
          </Col>
        </Row>
      )}
      <Table
        onChange={handleChange}
        columns={columns}
        dataSource={suppliersTable}
        scroll={{ x: 3200 }}
        loading={reloadSuppliers}
        // pagination={{ defaultPageSize: 5 }}
      />
      <Row>
        <Col span={24} style={{ textAlign: 'right' }}>
          <Button
            icon={<FilePdfFilled style={{ fontSize: 18 }} />}
            className="btn-download"
            onClick={() => exportPdf(suppliersTable)}
            disabled={suppliersTable.length === 0}
            style={{ width: 150 }}
          >
            Descargar Pdf
          </Button>
          <Button
            icon={<FileExcelFilled style={{ fontSize: 18 }} />}
            className="btn-download"
            onClick={() => generateExcel(suppliersTable)}
            disabled={suppliersTable.length === 0}
            style={{ width: 160 }}
          >
            Descargar Excel
          </Button>
        </Col>
      </Row>
      <Modal
        title={titleModal}
        visible={modalIsVisible}
        setIsVisible={setModalIsVisible}
        width={1000}
      >
        {contextModal}
      </Modal>
    </>
  );
}
