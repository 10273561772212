import React, { useEffect, useState } from 'react';
import {
  Table,
  Row,
  Col,
  Button,
  notification,
  Spin,
  Modal as ModalAntd,
} from 'antd';
import Modal from '../../../General/Modal';
import AddSerieForm from '../../Configuration/Series/AddSerieForm';
import EditSerieForm from '../../Configuration/Series/EditSerieForm';
import { getAccessTokenApi, logout } from '../../../../api/auth';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { deleteSerieApi, getAllInvoiceSeriesApi } from '../../../../api/series';
// import './BillingTable.scss';

export default function SeriesTable() {
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [reloadSeries, setReloadSeries] = useState(true);
  const [titleModal, setTitleModal] = useState(false);
  const [contextModal, setContextModal] = useState(null);
  const [serieSelected, setSerieSelected] = useState(undefined);
  const [seriesTable, setSeriesTable] = useState([]);
  const [loadingForm, setLoadingForm] = useState(false);
  const { confirm } = ModalAntd;
  const token = getAccessTokenApi();

  useEffect(() => {
    if (reloadSeries) {
      getAllInvoiceSeriesApi(token).then((response) => {
        // console.log(response, 'series');
        if (response) {
          if (response.statusCode === 401) {
            notification['error']({
              message: 'Usuario no autorizado',
            });

            setTimeout(() => {
              logout();
              window.location.reload();
            }, 1500);
            return;
          }
          if (response.statusCode === 200) {
            response.result.forEach((element, index) => {
              element['key'] = index;
            });
            setSeriesTable(response.result);
          }
          setReloadSeries(false);
        }
      });
    }
  }, [reloadSeries]);

  const columns = [
    {
      title: 'Selecciona',
      width: 100,
      dataIndex: 'id',
      key: 'concurrencyStamp',
      render: (id) => (
        <Checkbox
          // type="primary"
          onChange={() => setSerieSelected(id)}
          checked={serieSelected === id}
          className="button-detail"
        ></Checkbox>
      ),
    },
    {
      title: 'Serie',
      width: 100,
      dataIndex: 'serie',
      key: 'serie',
    },
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
      width: 150,
    },
  ];

  const showModal = (type) => {
    if (type === 'Add') {
      setModalIsVisible(true);
      setTitleModal('Agregar serie');
      setContextModal(
        <AddSerieForm
          setModalIsVisible={setModalIsVisible}
          setReloadSeries={setReloadSeries}
        ></AddSerieForm>
      );
    } else if (type === 'Edit') {
      if (serieSelected !== undefined) {
        setModalIsVisible(true);

        setTitleModal('Editar serie');
        setContextModal(
          <EditSerieForm
            setModalIsVisible={setModalIsVisible}
            setReloadSeries={setReloadSeries}
            idSerie={serieSelected}
          ></EditSerieForm>
        );
      } else if (serieSelected === undefined) {
        notification['error']({
          message: 'Debes seleccionar serie',
        });
      }
    }
  };

  const handleDeleteUser = (id) => {
    if (serieSelected !== undefined) {
      confirm({
        title: 'Eliminar serie',
        icon: <ExclamationCircleOutlined />,
        content:
          '¿Estás seguro que deseas borrar la serie seleccionado?, no podrá recuperarla después',
        okText: 'Si',
        okType: 'danger',
        cancelText: 'No',
        onOk() {
          deleteUser(id);
        },
        onCancel() {},
      });
    } else {
      notification['error']({
        message: 'Debes seleccionar serie',
      });
    }
  };

  const deleteUser = async (id) => {
    const accessToken = getAccessTokenApi();
    const result = await deleteSerieApi(accessToken, id);
    if (result?.statusCode === 409) {
      notification['error']({
        message: result.description,
      });
    }
    if (result?.statusCode === 405) {
      notification['error']({
        message: result.description,
      });
    }
    if (result?.statusCode === 404) {
      notification['error']({
        message: result.description,
      });
    }
    if (result?.statusCode === 200) {
      notification['success']({
        message: 'Serie eliminado exitosamente.',
      });
      setSerieSelected(undefined);
    }
    setReloadSeries(true);
  };
  return (
    <>
      <Row>
        <Col span={24}>
          <Button className="btn-download" onClick={() => showModal('Add')}>
            Agregar
          </Button>

          <Button className="btn-download" onClick={() => showModal('Edit')}>
            Editar
          </Button>
          <Button
            className="btn-download"
            onClick={() => handleDeleteUser(serieSelected)}
          >
            Eliminar
          </Button>
        </Col>
      </Row>
      <Spin spinning={loadingForm} tip="Cargando...">
        <Table
          columns={columns}
          dataSource={seriesTable}
          scroll={{ x: 1200 }}
          loading={reloadSeries}
          pagination={{ defaultPageSize: 5 }}
        />
      </Spin>

      <Modal
        title={titleModal}
        visible={modalIsVisible}
        setIsVisible={setModalIsVisible}
        width={1000}
      >
        {contextModal}
      </Modal>
    </>
  );
}
