import React, { useState } from 'react';
import { Col, DatePicker, Layout, Row, Typography } from 'antd';
import GreetingsTop from '../../../components/Admin/GreetingsTop/GreetingsTop';
import CustomerPromotionTable from '../../../components/Logistic/CustomerPromotionTable';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

export default function CustomerPromotionPage() {
  const [date, setDate] = useState("");
  let { dateParams } = useParams();

  return (
    <Layout>
      <GreetingsTop />

      <HeaderTop setDate={setDate}/>

      {(date !== "" || dateParams !== undefined )&& (
      <div className="billing__container">
      <CustomerPromotionTable date={dateParams !== undefined ? dateParams : date}/>
      </div>
      )}
     
    </Layout>
  );
}


function HeaderTop({setDate}) {
  const { Title } = Typography;
  
  const onChange = (date, dateString) => {
    setDate(dateString)
  };
  return (
    <div className="billing__container">
      <Row className="header-top">
        <Col xs={24} md={18} className="header-top__left">
        <Title level={3}>Análisis - Clientes Ascenso y Descenso</Title>
        </Col>
        <Col
          xs={20}
          md={6}
          style={{
            alignItems: 'end',
            justifyContent: 'end',
          }}
        >
          <DatePicker style={{width: '100%'}} onChange={onChange} picker="month" />
        </Col>
      </Row>
    </div>
  );
}
