import React, { useState, useEffect } from 'react';
import { Button, Modal as ModalAntd, Space, Table, Tooltip } from 'antd';
import {
  ReloadOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import Modal from '../../../General/Modal';
import { fetchApi } from '../../../../helpers/fetch';
import { getAccessTokenApi } from '../../../../api/auth';
import {
  METHOD_DELETE,
  METHOD_GET,
  ROL_ADMIN,
  ROL_LAYOUT,
  ROL_VENTAS,
} from '../../../../utils/constants';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import AddCustomerRoute from '../CustomerRoutesTable/AddCustomerRoute';
import useUser from '../../../../hooks/useUser';
const CustomerRoutesTable = ({ client }) => {
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [reloadRoutes, setReloadRoutes] = useState(false);
  const [titleModal, setTitleModal] = useState(false);
  const [contextModal, setContextModal] = useState(null);
  const { confirm } = ModalAntd;
  const token = getAccessTokenApi();
  let { clientId } = useParams();
  const [customerRoutes, setCustomerRoutes] = useState([]);
  const { person } = useUser();

  useEffect(() => {
    const config = {
      method: METHOD_GET,
      url: `Route/GetByCustomer/${clientId}`,
      data: null,
      token,
    };
    fetchApi(config)
      .then((response) => {
        if (response) {
          let clientRoutes = response.result;

          if (clientRoutes) {
            clientRoutes.map((cat, index) => {
              cat['key'] = index;
            });
          }
          setCustomerRoutes(clientRoutes);
        }
        setReloadRoutes(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [reloadRoutes, clientId, token]);

  const showModal = (type, contactId) => {
    if (type === 'Add') {
      setModalIsVisible(true);
      setTitleModal(`Agregar ruta cliente ${clientId}`);
      setContextModal(
        <AddCustomerRoute
          setModalIsVisible={setModalIsVisible}
          setReloadRoutes={setReloadRoutes}
          clientId={clientId}
        ></AddCustomerRoute>
      );
    }
  };

  const columns = [
    {
      title: 'Origen',
      children: [
        {
          title: 'País',
          dataIndex: 'originCountry',
          key: 'originCountry',
          width: 100,
          align: 'center',
        },
        {
          title: 'Estado',
          dataIndex: 'originState',
          key: 'originState',
          width: 100,
          align: 'center',
        },
        {
          title: 'Ciudad',
          dataIndex: 'originCity',
          key: 'originCity',
          width: 100,
          align: 'center',
        },
      ],
    },
    {
      title: 'Destino',
      children: [
        {
          title: 'País',
          dataIndex: 'destinationCountry',
          key: 'destinationCountry',
          width: 100,
          align: 'center',
        },
        {
          title: 'Estado',
          dataIndex: 'destinationState',
          key: 'destinationState',
          width: 100,
          align: 'center',
        },
        {
          title: 'Ciudad',
          dataIndex: 'destinationCity',
          key: 'destinationCity',
          width: 100,
          align: 'center',
        },
      ],
    },
    {
      title: 'Acción',
      key: 'action',
      width: 100,
      render: (text, record) => (
        <>
          {[ROL_ADMIN, ROL_VENTAS, ROL_LAYOUT].some((r) =>
            person.roles.includes(r)
          ) && (
            <>
              <Space size="middle">
                <Tooltip title="Eliminar">
                  <Button
                    danger
                    type="primary"
                    onClick={() => handleDeleteRoute(record.routeId)}
                  >
                    <DeleteOutlined />
                  </Button>
                </Tooltip>
              </Space>
            </>
          )}
        </>
      ),
      align: 'center',
    },
  ];

  const handleDeleteRoute = (id) => {
    confirm({
      title: 'Eliminar ruta',
      icon: <ExclamationCircleOutlined />,
      content: '¿Estás seguro que deseas eliminar la ruta seleccionada?',
      okText: 'Si',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteRoute(id);
      },
      onCancel() {},
    });
  };

  const deleteRoute = async (id) => {
    let values = {};
    values['customerId'] = clientId;
    values['routeId'] = id;

    const config = {
      method: METHOD_DELETE,
      url: `Route/DeleteByCustomer`,
      data: values,
      token,
      showNotification: true,
    };
    const result = await fetchApi(config);

    if (result) {
      if (result.statusCode === 200) {
        setReloadRoutes(true);
      }
    }
  };

  return (
    <div>
      {[ROL_ADMIN, ROL_VENTAS, ROL_LAYOUT].some((r) =>
        person.roles.includes(r)
      ) && (
        <Button
          className="btn-download"
          onClick={() => showModal('Add', clientId)}
          icon={<PlusCircleOutlined />}
        >
          Agregar
        </Button>
      )}
      <Button
        className="btn-download"
        icon={<ReloadOutlined />}
        onClick={(e) => setReloadRoutes(true)}
      >
        Refrescar
      </Button>

      <Table
        dataSource={customerRoutes}
        columns={columns}
        bordered
        size="middle"
        scroll={{ x: 1200 }}
        className="table-striped-rows"
        loading={reloadRoutes}
      />
      <Modal
        title={titleModal}
        visible={modalIsVisible}
        setIsVisible={setModalIsVisible}
        width={1000}
      >
        {contextModal}
      </Modal>
    </div>
  );
};

export default CustomerRoutesTable;
