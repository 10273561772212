import React, { useEffect, useState } from 'react';
import { Col, Layout, Row, Typography, notification, Button } from 'antd';
import { FileExcelOutlined } from '@ant-design/icons';
import GreetingsTop from '../../../components/Admin/GreetingsTop/GreetingsTop';
import BillingClarifications from '../../../components/Admin/Clarifications/BillingClarifications';
import DatePickerOptions from '../../../components/General/DatePickerOptions/DatePickerOptions';
import { getAccessTokenApi, logout } from '../../../api/auth';
import moment from 'moment';
import { messageError } from '../../../utils/general';
import { getAllClarificationsApi } from '../../../api/clarifications';
import FileProducts from '../../../components/Admin/FileProducts/FileProducts';
import Modal from '../../../components/General/Modal';
import useUser from '../../../hooks/useUser';
import { ROL_CUSTOMER, ROL_LAYOUT } from '../../../utils/constants';
import ClientClarification from '../../PortalCliente/Clarifications';

export default function Clarifications() {
    const [reloadClarifications, setReloadClarifications] = useState(true);
    const [clarifications, setClarifications] = useState([]);
    const token = getAccessTokenApi();
    const [date, setDate] = useState([moment().subtract(7, 'days'), moment()]);
    const [dateRange, setDateRange] = useState(null);
    const { person } = useUser();

    useEffect(() => {
        if (reloadClarifications) {
            filterDate(date);
        }
    }, []);

    function filterDate(date, dateString) {
        setReloadClarifications(true);

        if (date) {
            let date2 = {
                startDate: moment
                    .utc(date[0].hour(0).minute(0))
                    .format('YYYY-MM-DD HH:mm'),
                endDate: moment
                    .utc(date[1].hour(23).minute(59))
                    .format('YYYY-MM-DD HH:mm'),
            };
            let date3 = {
                startDate: moment(date[0]).format('DD-MM-YYYY'),
                endDate: moment(date[1]).format('DD-MM-YYYY'),
            };
            setDateRange(date3);
            let filters = `?startDate=${date2.startDate}&endDate=${date2.endDate}`;
            getAllClarificationsApi(token, filters).then((response) => {
                // console.log(response, 'get aclaracion date');
                if (response === undefined) {
                    const msg = messageError();

                    notification['error']({
                        message: msg,
                    });

                    return;
                }
                if (response?.statusCode === 401) {
                    notification['error']({
                        message: 'Usuario no autorizado.',
                    });
                    setTimeout(() => {
                        logout();
                        window.location.reload();
                    }, 1000);
                    return;
                }
                if (response?.statusCode === 404) {
                    notification['error']({
                        message: response.description,
                    });

                    return;
                } else if (response?.statusCode === 200) {
                    response.result.forEach((element, index) => {
                        element['key'] = index;
                    });
                    setClarifications(response.result);
                }
            });
        }
    }

    if([ROL_CUSTOMER].some((r) => person.roles.includes(r))){
        return <ClientClarification />
    }

    return (
        <Layout>
            <GreetingsTop />

            <HeaderTop filterDate={filterDate} />

            <div className="billing__container">
                <BillingClarifications
                    clarifications={clarifications}
                    reloadClarifications={reloadClarifications}
                    dateRange={dateRange}
                    setReloadClarifications={setReloadClarifications}
                />
            </div>
        </Layout>
    );
}

function HeaderTop({ filterDate }) {
    const { Title } = Typography;
    const [titleModal, setTitleModal] = useState(false);
    const [contextModal, setContextModal] = useState(null);
    const [modalIsVisible, setModalIsVisible] = useState(false);
    const { person } = useUser();

    const uploadExcel = () => {
        setModalIsVisible(true);
        setTitleModal('Subir Layout- Aclaraciones');
        setContextModal(
            <FileProducts setIsVisible={setModalIsVisible} type="" />
        );
    };

    const showModal = (type) => {
        if (type === 'addExcel') {
            uploadExcel();
        }
    };

    return (
        <div className="billing__container">
            <Row xs={24} className="header-top">
                <Col xs={20} md={12} className="header-top__left">
                    <Row>
                        <Title level={3} style={{ marginRight: 20 }}>
                            Aclaraciones
                        </Title>
                        {/* <Search
                            placeholder="Búsqueda..."
                            onSearch={onSearch}
                            style={{ width: 200, marginBottom: 10 }}
                        /> */}
                    </Row>
                </Col>
                <Col xs={24} md={12} className="header-top__right">
                    <DatePickerOptions filterDate={filterDate} />
                </Col>
                {[ROL_LAYOUT].some((r) => person.roles.includes(r)) && (
                    <Col xs={10} md={24} className="header-top__right">
                        <Button
                            onClick={() => showModal('addExcel')}
                            className="biller-btn"
                            icon={<FileExcelOutlined />}
                            style={{ marginBottom: 20 }}
                        >
                            Subir Excel
                        </Button>
                    </Col>
                )}
                
            </Row>
            <Modal
                title={titleModal}
                visible={modalIsVisible}
                setIsVisible={setModalIsVisible}
                // width={1000}
            >
                {contextModal}
            </Modal>
        </div>
    );
}
