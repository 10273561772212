import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Input,
  Modal as ModalAntd,
  Row,
  Space,
  Table,
  Tag,
  Tooltip,
} from 'antd';
import {
  ExclamationCircleOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
  ReloadOutlined,
  EditOutlined,
  FileOutlined,
  SearchOutlined,
  EyeOutlined,
  FileDoneOutlined,
  CopyOutlined,
  FileExcelFilled,
  FilePdfFilled,
} from '@ant-design/icons';
import Modal from '../../General/Modal';
import { useHistory } from 'react-router';
import {
  METHOD_DELETE,
  METHOD_GET,
  METHOD_POST,
  ROL_FINANCIAL,
  ROL_LAYOUT,
  ROL_OPERACIONES,
} from '../../../utils/constants';
import { getAccessTokenApi } from '../../../api/auth';
import { fetchApi } from '../../../helpers/fetch';
import { API_URL_OPERATION } from '../../../helpers/urls';
import moment from 'moment';
import XLSX from 'xlsx';
import AddEditOperationForm from '../Operations/AddEditOperationForm';
import AddEditInvoiceOperation from '../Operations/AddEditInvoiceOperation';
import {
  SERVICES_CATALOG_ID,
  OPERACIONES_CATALOG_ID,
} from '../../../helpers/constants';
import { getCatalogValueHandle } from '../../../helpers/handlerFunctionApi';
import Highlighter from 'react-highlight-words';
import useUser from '../../../hooks/useUser';
import jsPDF from 'jspdf';
import UploadFileForm from '../../Admin/Payment/UploadFileForm';
import { formatMoney, isMobile } from '../../../utils/general';
import { getFilteredDataFromLocalStorage, saveFilteredDataToLocalStorage } from '../../../helpers/saveFilterData';

const OperationsTable = ({
  dateRange,
  isFinancial,
  pickOperation,
  form,
  setModalIsVisible: setModalOperation,
}) => {
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [titleModal, setTitleModal] = useState(false);
  const [contextModal, setContextModal] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const { confirm } = ModalAntd;
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const token = getAccessTokenApi();
  const [reloadTable, setReloadTable] = useState(false);
  const [operations, setOperations] = useState([]);
  const textModal = '¿Estás seguro que deseas borrar esta operación';
  const [service, setService] = useState([]);
  const [operaciones, setOperaciones] = useState([]);
  const [filterService, setFilterService] = useState([]);
  const [filterOperaciones, setFilterOperaciones] = useState([]);
  const [searchedColumn, setSearchedColumn] = useState('');
  const [filteredInfo, setFilteredInfo] = useState({});
  const [searchText, setSearchText] = useState('');
  const [operationSelected, setOperationSelected] = useState(null);
  const [operationDuplicate, setOperationDuplicate] = useState(null);
  const [inlandOperation, setInlandOperation] = useState(null);
  const classButton = isMobile() ? 'btn-download-mobile' : 'btn-download';
  const classButtonDelete = isMobile() ? 'btn-delete-mobile' : 'btn-delete';
  const [startDate, setStartDate] = useState({
    createdAt: '',
    etd: '',
    eta: '',
  });
  const [endDate, setEndDate] = useState({
    createdAt: '',
    etd: '',
    eta: '',
  });
  const x = React.createRef();
  const { person } = useUser();
  const onSelectChange = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
  };
  useEffect(() => {
    form?.setFieldsValue({
      inlandReference: inlandOperation,
    });
  }, [inlandOperation, form]);
  useEffect(() => {
    getCatalogValueHandle(SERVICES_CATALOG_ID, setService, setIsLoading, 0);
    getCatalogValueHandle(
      OPERACIONES_CATALOG_ID,
      setOperaciones,
      setIsLoading,
      0
    );
  }, []);
  useEffect(() => {
    setIsLoading(true);
    // let filters = `?startDate=${dateRange.startDate}&endDate=${dateRange.endDate}`;

    const config = {
      method: METHOD_GET,
      url: `${API_URL_OPERATION}`,
      // url: `${API_URL_OPERATION}`,
      data: null,
      token,
      showNotificationError: true,
    };
    fetchApi(config)
      .then((response) => {
        if (response.statusCode === 200) {
          const allOperations = response.result;
          // console.log(allOperations);
          allOperations.map((operation) => (operation['key'] = operation.id));
          if (pickOperation) {
            let inlandOperations = allOperations.filter(
              (e) => e.service === 'TERRESTRE' && e.status === 'Entregado'
            );
            setOperations(inlandOperations);
          } else {
            setOperations(allOperations);
          }
        }
        setIsLoading(false);
        setReloadTable(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        setReloadTable(false);
      });
  }, [reloadTable, dateRange, token]);
  useEffect(() => {
    let filters = [];
    service.forEach((element) => {
      const json = { text: element.label, value: element.label };
      filters.push(json);
    });
    setFilterService(filters);
  }, [service]);
  useEffect(() => {
    let filters = [];
    operaciones.forEach((element) => {
      const json = { text: element.label, value: element.label };
      filters.push(json);
    });
    setFilterOperaciones(filters);
  }, [operaciones]);
  useEffect(() => {
    const datosObtenidos = getFilteredDataFromLocalStorage('operations');
    if (datosObtenidos !== null && !pickOperation) {
      setFilteredInfo(datosObtenidos.filters);
      setStartDate(datosObtenidos.dateFilters.startDates)
      setEndDate(datosObtenidos.dateFilters.endDates)
    }
  },[])
  const handleReset = React.useCallback(
    (clearFilters, selectedKeys, confirm, dataIndex) => {
      clearFilters();
      setSearchText('');
      startDate[dataIndex] = '';
      endDate[dataIndex] = '';
    },
    [endDate, startDate]
  );
  const getColumnSearchProps = React.useCallback(
    (dataIndex) => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              // this.searchInput = node;
            }}
            placeholder={`Buscar`}
            value={selectedKeys[0]}
            onChange={(e) =>{
              setSelectedKeys(e.target.value ? [e.target.value] : [])
             } }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Buscar
            </Button>
            <Button
              onClick={() => {
                handleReset(clearFilters);
                handleSearch(selectedKeys, confirm, dataIndex);
              }}
              size="small"
              style={{ width: 90 }}
            >
              Limpiar
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      onFilter: (value, record) =>
        record[dataIndex]
          ? record[dataIndex]
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
          : '',
      filteredValue: filteredInfo[dataIndex] || null,
      onFilterDropdownVisibleChange: (visible) => {},
      render: (text) =>
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        ) : (
          text
        ),
    }),
    [filteredInfo, handleReset, searchText, searchedColumn]
  );
  const getColumnSearchDateProps = React.useCallback(
    (dataIndex) => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Col>
            <DatePicker
              ref={x}
              id={'startDate' + dataIndex}
              placeholder={'Fecha inicial'}
              style={{ marginRight: '10px' }}
              format="DD/MM/YYYY"
              allowClear={false}
              value={
                startDate[dataIndex] !== ''
                  ? moment.unix(startDate[dataIndex])
                  : null
              }
              onChange={(e) => {
                // setStartDate(moment(e).unix());
                startDate[dataIndex] = moment(e).startOf('day').unix();
                setSelectedKeys(
                  operations
                    .filter(
                      (op) =>
                        moment(e)
                          .hours(0)
                          .minutes(0)
                          .seconds(0)
                          .milliseconds(0)
                          .unix() <= op[dataIndex]
                    )
                    .map((op) => op.key)
                );
                // setSelectedKeys(e !== null ? [e.format('DD/MM/YYYY')] : [])
              }}
            />
            <DatePicker
              placeholder={'Fecha final'}
              format="DD/MM/YYYY"
              allowClear={false}
              value={
                endDate[dataIndex] !== ''
                  ? moment.unix(endDate[dataIndex])
                  : null
              }
              onChange={(e) => {
                // setEndDate(moment(e).unix());
                endDate[dataIndex] = moment(e).endOf('day').unix();
                setSelectedKeys(
                  operations
                    .filter(
                      (op) =>
                        op[dataIndex] <=
                        moment(e)
                          .hours(23)
                          .minutes(59)
                          .seconds(59)
                          .milliseconds(0)
                          .unix()
                    )
                    .map((op) => op.key)
                );
              }}
            />
          </Col>
          <Row justify="end" gutter={16}>
            <Col>
              <Button
              disabled={endDate[dataIndex] === ''}
                type="primary"
                onClick={() => {
                  handleSearchDate(selectedKeys, confirm);
                  setSelectedKeys(
                    operations
                      .filter(
                        (d) =>
                          startDate[dataIndex] <= d[dataIndex] &&
                          d[dataIndex] <= endDate[dataIndex]
                      )
                      .map((d) => d.key)
                  );
                }}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Buscar
              </Button>
              <Button
                onClick={() => {
                  handleReset(clearFilters, selectedKeys, confirm, dataIndex);
                  handleSearchDate(selectedKeys, confirm, dataIndex);
                }}
                size="small"
                style={{ width: 90 }}
              >
                Limpiar
              </Button>
            </Col>
          </Row>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      onFilter: (value, record) =>
        startDate[dataIndex] <= record[dataIndex] &&
        record[dataIndex] <= endDate[dataIndex],
      filteredValue: filteredInfo[dataIndex] || null,
      onFilterDropdownVisibleChange: (visible) => {},
      render: (text) =>
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? moment.unix(text).format('DD/MM/YYYY') : ''}
          />
        ) : (
          moment.unix(text).format('DD/MM/YYYY')
        ),
    }),
    [
      endDate,
      filteredInfo,
      handleReset,
      operations,
      searchText,
      searchedColumn,
      startDate,
      x,
    ]
  );
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleSearchDate = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };
  const handleChange = (pagination, filters, sorter, extra) => {
    const dateFilters = { startDates: startDate, endDates: endDate}
    saveFilteredDataToLocalStorage('operations', pagination, sorter, filters, dateFilters);
    setFilteredInfo(filters);
  };
  const rowSelection = {
    selectedRowKeys,
    type: 'checkbox',
    onChange: onSelectChange,
  };
  const showModal = React.useCallback(
    (type, operation, invoiceId, response, nameXml, fileXML, filePDF) => {
      if (type === 'Add') {
        setModalIsVisible(true);
        setTitleModal('Agregar operación');
        setContextModal(
          <AddEditOperationForm
            setModalIsVisible={setModalIsVisible}
            type={type}
            setReloadTable={setReloadTable}
          ></AddEditOperationForm>
        );
      } else if (type === 'Edit') {
        setModalIsVisible(true);
        setTitleModal(`Editar operación #${operation.operationIdStr}`);
        setContextModal(() => (
          <AddEditOperationForm
            setModalIsVisible={setModalIsVisible}
            type={type}
            operationId={operation.id}
            setReloadTable={setReloadTable}
          />
        ));
      } else if (type === 'Watch') {
        setModalIsVisible(true);
        setTitleModal(`Operación #${operation.operationIdStr}`);
        setContextModal(
          <AddEditOperationForm
            type="watch"
            setModalIsVisible={setModalIsVisible}
            operationId={operation.id}
            isFinancial={isFinancial}
            // setReloadQuote={setReloadQuote}
          ></AddEditOperationForm>
        );
      } else if (type === 'Invoice') {
        setModalIsVisible(true);
        setTitleModal(`Facturación operación #${operation.operationIdStr}`);
        setContextModal(
          <AddEditInvoiceOperation
            setModalIsVisible={setModalIsVisible}
            operationId={operation.id}
            invoiceId={invoiceId}
            operationStatus={operation.invoiceStatusName}
            setReloadTable={setReloadTable}
            response={response}
            nameXml={nameXml}
            fileXML={fileXML ? fileXML[0] : null}
            filePDF={filePDF ? filePDF[0] : null}
          ></AddEditInvoiceOperation>
        );
      } else if (type === 'addFile') {
        setModalIsVisible(true);
        setTitleModal(`Operación #${operation.operationIdStr} a facturar`);
        setContextModal(
          <UploadFileForm
            setModalIsVisible={setModalIsVisible}
            showModal={showModal}
            type={type}
            operation={operation}
            invoiceId={invoiceId}
          ></UploadFileForm>
        );
      }
      //  else if (type === 'Status') {
      //   setModalIsVisible(true);
      //   setTitleModal(`Estatus operación #${operation.operationIdStr}`);
      //   setContextModal(
      //     <StatusOperation
      //       setModalIsVisible={setModalIsVisible}
      //       operationId={operation.id}
      //       invoiceId={operation.invoiceId}
      //       operationStatus={operation.invoiceStatusName}
      //       setReloadTable={setReloadTable}
      //     ></StatusOperation>
      //   );
      // }
    },
    [setModalIsVisible, setReloadTable, setContextModal]
  );
  const checkOperation = React.useCallback(
    (operation) => {
      if (operationSelected === operation.id) {
        setOperationSelected(null);
        setInlandOperation(null);
      } else {
        setOperationSelected(operation.id);
        setInlandOperation(operation.operationIdStr);
      }
    },
    [operationSelected, setInlandOperation]
  );
  const duplicateOperation = React.useCallback(
    async (id) => {
      const values = {};
      values['operationId'] = id;
      const config = {
        method: METHOD_POST,
        url: `${API_URL_OPERATION}/DuplicateOperation`,
        data: values,
        token,
        showNotification: true,
      };
      const result = await fetchApi(config);
      if (result) {
        if (result.statusCode === 200) {
          setReloadTable(true);
          setSelectedRowKeys([]);
          setOperationDuplicate(result.result);
        }
      }
    },
    [token]
  );
  useEffect(() => {
    if (operationDuplicate) {
      showModal('Edit', operationDuplicate);
    }
  }, [operationDuplicate, showModal]);
  const duplicateOperationHandle = React.useCallback(
    (id, operationStr) => {
      confirm({
        title: `Deseas duplicar la operación ${operationStr}?`,
        icon: <ExclamationCircleOutlined />,
        content: 'No podrá deshacer esta acción después..',
        okText: 'Si',
        okType: 'danger',
        cancelText: 'No',
        onOk() {
          duplicateOperation(id);
        },
        onCancel() {},
      });
    },
    [duplicateOperation, confirm]
  );
  const columns = React.useMemo(
    () => [
      {
        title: '',
        ellipsis: true,
        width: 50,
        // width: 100,
        dataIndex: 'id',
        key: 'company',
        render: (operation, record) => (
          <Checkbox
            onChange={() => checkOperation(record)}
            checked={operationSelected === operation}
            style={{ padding: 10 }}
            className="button-detail"
          ></Checkbox>
        ),
      },
      {
        title: 'Id. operación',
        dataIndex: 'operationIdStr',
        key: 'operationIdStr',
        width: 100,
        align: 'center',
        ...getColumnSearchProps('operationIdStr'),
      },
      {
        title: 'Fecha operación',
        dataIndex: 'createdAt',
        key: 'createdAt',
        width: 100,
        align: 'center',
        render: (createdAt) => (
          <>{moment.unix(createdAt).format('DD/MM/YYYY')}</>
        ),
        filteredValue: filteredInfo.createdAt || null,
        ...getColumnSearchDateProps('createdAt'),
      },
      {
        title: 'Cliente',
        dataIndex: 'name',
        key: 'name',
        width: 150,
        ellipsis: true,
        align: 'center',
        ...getColumnSearchProps('name'),
      },
      {
        title: 'Proveedor',
        dataIndex: 'supplier',
        key: 'supplier',
        width: 150,
        align: 'center',
        ...getColumnSearchProps('supplier'),
      },
      {
        title: 'Orden de compra',
        dataIndex: 'purchaseOrder',
        key: 'purchaseOrder',
        width: 160,
        align: 'center',
        ellipsis: true,
        ...getColumnSearchProps('purchaseOrder'),
      },
      {
        title: 'Servicio',
        dataIndex: 'service',
        key: 'service',
        width: 100,
        align: 'center',
        filters: filterService,
        filteredValue: filteredInfo.service || null,
        onFilter: (value, record) => record.service.includes(value),
      },

      {
        title: 'Operación',
        dataIndex: 'operation',
        key: 'operation',
        width: 100,
        align: 'center',
        filters: filterOperaciones,
        filteredValue: filteredInfo.operation || null,
        onFilter: (value, record) => record.operation === value,
      },
      {
        title: 'Origen',
        dataIndex: 'originCity',
        key: 'originCity',
        width: 150,
        align: 'center',
        ...getColumnSearchProps('originCity'),
      },
      {
        title: 'Destino',
        dataIndex: 'destinationCity',
        key: 'destinationCity',
        width: 150,
        align: 'center',
        ...getColumnSearchProps('destinationCity'),
      },
      {
        title: 'ETD',
        dataIndex: 'etd',
        key: 'etd',
        width: 100,
        align: 'center',
        render: (etd) => <>{moment.unix(etd).format('DD/MM/YYYY')}</>,
        ...getColumnSearchDateProps('etd'),
      },
      {
        title: 'ETA',
        dataIndex: 'eta',
        key: 'eta',
        width: 100,
        align: 'center',
        render: (eta) => (
          <>{eta && <>{moment.unix(eta).format('DD/MM/YYYY')}</>}</>
        ),
        ...getColumnSearchDateProps('eta'),
      },
      {
        title: 'Estatus',
        dataIndex: 'status',
        key: 'status',
        width: 100,
        align: 'center',
        filters: [
          { text: 'Programado', value: 'Programado' },
          { text: 'Cargando', value: 'Cargando' },
          { text: 'En transito', value: 'En transito' },
          { text: 'Descargado', value: 'Descargado' },
          { text: 'Entregado', value: 'Entregado' },
          { text: 'Cancelado', value: 'Cancelado' },
        ],
        filteredValue: filteredInfo.status || null,
        onFilter: (value, record) => record.status.includes(value),
        render: (status) => (
          <Tag
            color={
              status === 'Programado'
                ? '#c2c0c0'
                : status === 'En transito'
                ? 'yellow'
                : status === 'Entregado'
                ? 'green'
                : status === 'Descargado'
                ? 'blue'
                : status === 'Cargando'
                ? 'orange'
                : 'red'
            }
          >
            {status}
          </Tag>
        ),
      },
      {
        title: 'Estatus facturación',
        dataIndex: 'invoiceStatusName',
        key: 'invoiceStatusName',
        width: 100,
        align: 'center',
        filters: [
          { text: 'Por facturar', value: 'Por facturar' },
          { text: 'Facturado', value: 'Facturado' },
          { text: 'Pagado', value: 'Pagado' },
        ],
        filteredValue: filteredInfo.invoiceStatusName || null,
        onFilter: (value, record) => record.invoiceStatusName === value,
        render: (invoiceStatusName, operation) => (
          <Tag
            color={
              invoiceStatusName === 'Vigente'
                ? 'green'
                : invoiceStatusName === 'Pagado'
                ? 'green'
                : invoiceStatusName === 'Facturado'
                ? 'blue'
                : invoiceStatusName === 'Por facturar' &&
                  operation.orderStatus === 3
                ? 'white'
                : 'red'
            }
          >
            {operation.orderStatus !== 3 ? invoiceStatusName : ''}
          </Tag>
        ),
      },
      {
        title: 'Acciones',
        key: 'id',
        fixed: !pickOperation ? 'right' : '',
        width: !pickOperation ? 160 : 70,
        align: 'center',
        render: (operation, record) => (
          <>
            {!pickOperation && (
              <>
                {[ROL_OPERACIONES, ROL_LAYOUT].some((r) =>
                  person.roles.includes(r)
                ) &&
                  !isFinancial && (
                    <Tooltip title="Editar">
                      <Button
                        style={{ marginLeft: 3 }}
                        type="primary"
                        shape="default"
                        disabled={
                          operation.invoiceStatus === true ||
                          operation.orderStatus === 3
                        }
                        icon={<EditOutlined />}
                        onClick={() => showModal('Edit', operation)}
                      />
                    </Tooltip>
                  )}
                {!isFinancial && (
                  <Tooltip title="Documentos">
                    <Button
                      style={{ marginLeft: 3 }}
                      type="primary"
                      ghost
                      disabled={operation.orderStatus === 3}
                      shape="default"
                      icon={<FileOutlined />}
                      onClick={() => {
                        history.push(`/operacion/documentos/${operation.id}`);
                      }}
                    />
                  </Tooltip>
                )}
                {[ROL_OPERACIONES, ROL_LAYOUT].some((r) =>
                  person.roles.includes(r)
                ) &&
                  !isFinancial && (
                    <Tooltip title="Duplicar operación">
                      <Button
                        style={{ marginLeft: 3 }}
                        type="primary"
                        ghost
                        shape="default"
                        icon={<CopyOutlined />}
                        onClick={() =>
                          duplicateOperationHandle(
                            operation.id,
                            operation.operationIdStr
                          )
                        }
                      />
                    </Tooltip>
                  )}
                <Tooltip title="Ver más información">
                  <Button
                    style={{ marginLeft: 3 }}
                    type="primary"
                    ghost
                    shape="default"
                    icon={<EyeOutlined />}
                    onClick={() => showModal('Watch', operation)}
                  />
                </Tooltip>
                {[ROL_FINANCIAL, ROL_LAYOUT].some((r) =>
                  person.roles.includes(r)
                ) &&
                  isFinancial && (
                    <Tooltip title="Facturación">
                      <Button
                        style={{ marginLeft: 3 }}
                        type="primary"
                        disabled={
                          operation.orderStatus !== 2 ||
                          operation.orderStatus === 3
                        }
                        ghost
                        shape="default"
                        icon={<FileDoneOutlined />}
                        onClick={() =>
                          showModal(
                            record.invoiceStatusName === 'Por facturar'
                              ? 'addFile'
                              : 'Invoice',
                            operation,
                            operation.invoiceId
                          )
                        }
                      />
                    </Tooltip>
                  )}
              </>
            )}
          </>
        ),
      },
    ],
    [
      checkOperation,
      duplicateOperationHandle,
      filterOperaciones,
      filterService,
      filteredInfo,
      getColumnSearchDateProps,
      getColumnSearchProps,
      history,
      isFinancial,
      operationSelected,
      person,
      showModal,
      pickOperation,
    ]
  );
  const financialColumns = React.useMemo(
    () => [
      {
        title: '',
        ellipsis: true,
        width: 50,
        // width: 100,
        dataIndex: 'id',
        key: 'company',
        render: (operation, record) => (
          <Checkbox
            onChange={() => checkOperation(record)}
            checked={operationSelected === operation}
            style={{ padding: 10 }}
            className="button-detail"
          ></Checkbox>
        ),
      },
      {
        title: 'Id. operación',
        dataIndex: 'operationIdStr',
        key: 'operationIdStr',
        width: 100,
        align: 'center',
        ...getColumnSearchProps('operationIdStr'),
      },
      {
        title: 'Fecha operación',
        dataIndex: 'createdAt',
        key: 'createdAt',
        width: 100,
        align: 'center',
        render: (createdAt) => (
          <>{moment.unix(createdAt).format('DD/MM/YYYY')}</>
        ),
        ...getColumnSearchDateProps('createdAt'),
      },
      {
        title: 'Cliente',
        dataIndex: 'name',
        key: 'name',
        width: 150,
        ellipsis: true,
        align: 'center',
        ...getColumnSearchProps('name'),
      },
      {
        title: 'Proveedor',
        dataIndex: 'supplier',
        key: 'supplier',
        width: 150,
        align: 'center',
        ...getColumnSearchProps('supplier'),
      },
      {
        title: 'Orden de compra',
        dataIndex: 'purchaseOrder',
        key: 'purchaseOrder',
        width: 160,
        align: 'center',
        ellipsis: true,
        ...getColumnSearchProps('purchaseOrder'),
      },
      {
        title: 'Servicio',
        dataIndex: 'service',
        key: 'service',
        width: 100,
        align: 'center',
        filters: filterService,
        filteredValue: filteredInfo.service || null,
        onFilter: (value, record) => record.service.includes(value),
      },

      {
        title: 'Operación',
        dataIndex: 'operation',
        key: 'operation',
        width: 100,
        align: 'center',
        filters: filterOperaciones,
        filteredValue: filteredInfo.operation || null,
        onFilter: (value, record) => record.operation === value,
      },
      {
        title: 'Origen',
        dataIndex: 'originCity',
        key: 'originCity',
        width: 150,
        align: 'center',
        ...getColumnSearchProps('originCity'),
      },
      {
        title: 'Destino',
        dataIndex: 'destinationCity',
        key: 'destinationCity',
        width: 150,
        align: 'center',
        ...getColumnSearchProps('destinationCity'),
      },
      {
        title: 'ETD',
        dataIndex: 'etd',
        key: 'etd',
        width: 100,
        align: 'center',
        render: (etd) => <>{moment.unix(etd).format('DD/MM/YYYY')}</>,
        ...getColumnSearchDateProps('etd'),
      },
      {
        title: 'ETA',
        dataIndex: 'eta',
        key: 'eta',
        width: 100,
        align: 'center',
        render: (eta) => (
          <>{eta && <>{moment.unix(eta).format('DD/MM/YYYY')}</>}</>
        ),
        ...getColumnSearchDateProps('eta'),
      },
      {
        title: 'Costos totales',
        dataIndex: 'quoteTotal',
        key: 'quoteTotal',
        width: 150,
        align: 'center',
        render: (quoteTotal) => `$${formatMoney(quoteTotal)}`,
      },
      {
        title: 'Servicios adicionales',
        dataIndex: 'additionalServicesTotal',
        key: 'additionalServicesTotal',
        width: 150,
        align: 'center',
        render: (additionalServicesTotal) =>
          `$${formatMoney(additionalServicesTotal)}`,
      },
      {
        title: 'Estatus',
        dataIndex: 'status',
        key: 'status',
        width: 100,
        align: 'center',
        filters: [
          { text: 'Programado', value: 'Programado' },
          { text: 'Cargando', value: 'Cargando' },
          { text: 'En transito', value: 'En transito' },
          { text: 'Descargado', value: 'Descargado' },
          { text: 'Entregado', value: 'Entregado' },
          { text: 'Cancelado', value: 'Cancelado' },
        ],
        filteredValue: filteredInfo.status || null,
        onFilter: (value, record) => record.status.includes(value),
        render: (status) => (
          <Tag
            color={
              status === 'Programado'
                ? '#c2c0c0'
                : status === 'En transito'
                ? 'yellow'
                : status === 'Entregado'
                ? 'green'
                : status === 'Descargado'
                ? 'blue'
                : status === 'Cargando'
                ? 'orange'
                : 'red'
            }
          >
            {status}
          </Tag>
        ),
      },
      {
        title: 'Estatus facturación',
        dataIndex: 'invoiceStatusName',
        key: 'invoiceStatusName',
        width: 100,
        align: 'center',
        filters: [
          { text: 'Por facturar', value: 'Por facturar' },
          { text: 'Facturado', value: 'Facturado' },
          { text: 'Pagado', value: 'Pagado' },
        ],
        filteredValue: filteredInfo.invoiceStatusName || null,
        onFilter: (value, record) => record.invoiceStatusName === value,
        render: (invoiceStatusName, operation) => (
          <Tag
            color={
              invoiceStatusName === 'Vigente'
                ? 'green'
                : invoiceStatusName === 'Pagado'
                ? 'green'
                : invoiceStatusName === 'Facturado'
                ? 'blue'
                : invoiceStatusName === 'Por facturar' &&
                  operation.orderStatus === 3
                ? 'white'
                : 'red'
            }
          >
            {operation.orderStatus !== 3 ? invoiceStatusName : ''}
          </Tag>
        ),
      },
      {
        title: 'Acciones',
        key: 'id',
        fixed: !pickOperation ? 'right' : '',
        width: !pickOperation ? 160 : 70,
        align: 'center',
        render: (operation, record) => (
          <>
            {!pickOperation && (
              <>
                {[ROL_OPERACIONES, ROL_LAYOUT].some((r) =>
                  person.roles.includes(r)
                ) &&
                  !isFinancial && (
                    <Tooltip title="Editar">
                      <Button
                        style={{ marginLeft: 3 }}
                        type="primary"
                        shape="default"
                        disabled={
                          operation.invoiceStatus === true ||
                          operation.orderStatus === 3
                        }
                        icon={<EditOutlined />}
                        onClick={() => showModal('Edit', operation)}
                      />
                    </Tooltip>
                  )}
                {!isFinancial && (
                  <Tooltip title="Documentos">
                    <Button
                      style={{ marginLeft: 3 }}
                      type="primary"
                      ghost
                      disabled={operation.orderStatus === 3}
                      shape="default"
                      icon={<FileOutlined />}
                      onClick={() => {
                        history.push(`/operacion/documentos/${operation.id}`);
                      }}
                    />
                  </Tooltip>
                )}
                {[ROL_OPERACIONES, ROL_LAYOUT].some((r) =>
                  person.roles.includes(r)
                ) &&
                  !isFinancial && (
                    <Tooltip title="Duplicar operación">
                      <Button
                        style={{ marginLeft: 3 }}
                        type="primary"
                        ghost
                        shape="default"
                        icon={<CopyOutlined />}
                        onClick={() =>
                          duplicateOperationHandle(
                            operation.id,
                            operation.operationIdStr
                          )
                        }
                      />
                    </Tooltip>
                  )}
                <Tooltip title="Ver más información">
                  <Button
                    style={{ marginLeft: 3 }}
                    type="primary"
                    ghost
                    shape="default"
                    icon={<EyeOutlined />}
                    onClick={() => showModal('Watch', operation)}
                  />
                </Tooltip>
                {[ROL_FINANCIAL, ROL_LAYOUT].some((r) =>
                  person.roles.includes(r)
                ) &&
                  isFinancial && (
                    <Tooltip title="Facturación">
                      <Button
                        style={{ marginLeft: 3 }}
                        type="primary"
                        disabled={
                          operation.orderStatus !== 2 ||
                          operation.orderStatus === 3
                        }
                        ghost
                        shape="default"
                        icon={<FileDoneOutlined />}
                        onClick={() =>
                          showModal(
                            record.invoiceStatusName === 'Por facturar'
                              ? 'addFile'
                              : 'Invoice',
                            operation,
                            operation.invoiceId
                          )
                        }
                      />
                    </Tooltip>
                  )}
              </>
            )}
          </>
        ),
      },
    ],
    [
      checkOperation,
      duplicateOperationHandle,
      filterOperaciones,
      filterService,
      filteredInfo,
      getColumnSearchDateProps,
      getColumnSearchProps,
      history,
      isFinancial,
      operationSelected,
      person,
      showModal,
      pickOperation,
    ]
  );
  const deleteOperation = async (id) => {
    const config = {
      method: METHOD_DELETE,
      url: `${API_URL_OPERATION}/${id}`,
      data: null,
      token,
      showNotification: true,
    };
    const result = await fetchApi(config);

    if (result) {
      if (result.statusCode === 200) {
        setReloadTable(true);
        setSelectedRowKeys([]);
      }
    }
  };
  const showDeleteConfirm = (id, description = '') => {
    confirm({
      title: `${textModal}?`,
      icon: <ExclamationCircleOutlined />,
      content: 'No podrá deshacer esta acción después..',
      okText: 'Si',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteOperation(id);
      },
      onCancel() {},
    });
  };
  const generateExcel = (entries) => {
    // setExcelLoading(true);
    let filename = '';
    let mstrTitulo = '';
    filename = `Operaciones${moment().format('L')}.xlsx`;
    mstrTitulo = `Operaciones`;
    let fecha = moment().format('LLL');
    const ws_name = 'Hoja1';
    let wb = {
      SheetNames: [],
      Sheets: {},
    };
    let ws = {};
    let renInicial = 4;

    // var mstrTitulo = `Facturación y notas de crédito del ${dateRange.startDate} al: ${dateRange.endDate}`;

    ws = {
      A1: { v: mstrTitulo, t: 's' },
      I1: { v: fecha, t: 's' },

      A3: { v: 'Id. operación', t: 's' },
      B3: { v: 'Fecha operación', t: 's' },
      C3: { v: 'Cliente', t: 's' },
      D3: { v: 'Proveedor', t: 's' },
      E3: { v: 'Orden de compra', t: 's' },
      F3: { v: 'Servicio', t: 's' },
      G3: { v: 'Operación', t: 's' },
      H3: { v: 'Origen', t: 's' },
      I3: { v: 'Destino', t: 's' },
      J3: { v: 'ETD', t: 's' },
      K3: { v: 'ETA', t: 's' },
      L3: { v: 'Estatus', t: 's' },
      M3: { v: 'Estatus facturación', t: 's' },
    };
    for (let i in entries) {
      const {
        operationIdStr,
        createdAt,
        name,
        supplier,
        purchaseOrder,
        service,
        operation,
        originCity,
        destinationCity,
        etd,
        eta,
        status,
        invoiceStatusName,
      } = entries[i];
      ws['A' + renInicial] = { v: operationIdStr, t: 's' };
      ws['B' + renInicial] = {
        v: moment.unix(createdAt).format('DD/MM/YYYY'),
        t: 's',
      };
      ws['C' + renInicial] = { v: name, t: 's' };
      ws['D' + renInicial] = { v: supplier, t: 's' };
      ws['E' + renInicial] = { v: purchaseOrder, t: 's' };
      ws['F' + renInicial] = { v: service, t: 's' };
      ws['G' + renInicial] = { v: operation, t: 's' };
      ws['H' + renInicial] = { v: originCity, t: 's' };
      ws['I' + renInicial] = { v: destinationCity, t: 's' };
      ws['J' + renInicial] = {
        v: moment.unix(etd).format('DD/MM/YYYY'),
        t: 's',
      };
      ws['K' + renInicial] = {
        v: moment.unix(eta).format('DD/MM/YYYY'),
        t: 's',
      };
      ws['L' + renInicial] = { v: status, t: 's' };
      ws['M' + renInicial] = { v: invoiceStatusName, t: 's' };

      renInicial++;
    }
    // var wscols = [{ wch: 30 }, { wch: 40 }, { wch: 35 }, { wch: 35 }];
    // ws['!cols'] = wscols;
    ws['!ref'] = XLSX.utils.encode_range({
      s: { c: 0, r: 0 },
      e: { c: 15, r: renInicial },
    });
    wb.SheetNames.push(ws_name);
    wb.Sheets[ws_name] = ws;
    XLSX.writeFile(wb, filename, { cellStyles: true });
    // setExcelLoading(false);
  };
  const generateFinancialExcel = (entries) => {
    // setExcelLoading(true);
    let filename = '';
    let mstrTitulo = '';
    filename = `Facturación-cuentas-por-cobrar${moment().format('L')}.xlsx`;
    mstrTitulo = `Facturación cuentas por cobrar`;
    let fecha = moment().format('LLL');
    const ws_name = 'Hoja1';
    let wb = {
      SheetNames: [],
      Sheets: {},
    };
    let ws = {};
    let renInicial = 4;

    // var mstrTitulo = `Facturación y notas de crédito del ${dateRange.startDate} al: ${dateRange.endDate}`;

    ws = {
      A1: { v: mstrTitulo, t: 's' },
      I1: { v: fecha, t: 's' },

      A3: { v: 'Id. operación', t: 's' },
      B3: { v: 'Fecha operación', t: 's' },
      C3: { v: 'Cliente', t: 's' },
      D3: { v: 'Proveedor', t: 's' },
      E3: { v: 'Orden de compra', t: 's' },
      F3: { v: 'Servicio', t: 's' },
      G3: { v: 'Operación', t: 's' },
      H3: { v: 'Origen', t: 's' },
      I3: { v: 'Destino', t: 's' },
      J3: { v: 'ETD', t: 's' },
      K3: { v: 'ETA', t: 's' },
      L3: { v: 'Costos totales', t: 's' },
      M3: { v: 'Servicios adicionales', t: 's' },
      N3: { v: 'Estatus', t: 's' },
      O3: { v: 'Estatus facturación', t: 's' },
    };
    for (let i in entries) {
      const {
        operationIdStr,
        createdAt,
        name,
        supplier,
        purchaseOrder,
        service,
        operation,
        originCity,
        destinationCity,
        etd,
        eta,
        quoteTotal,
        additionalServicesTotal,
        status,
        invoiceStatusName,
      } = entries[i];
      ws['A' + renInicial] = { v: operationIdStr, t: 's' };
      ws['B' + renInicial] = {
        v: moment.unix(createdAt).format('DD/MM/YYYY'),
        t: 's',
      };
      ws['C' + renInicial] = { v: name, t: 's' };
      ws['D' + renInicial] = { v: supplier, t: 's' };
      ws['E' + renInicial] = { v: purchaseOrder, t: 's' };
      ws['F' + renInicial] = { v: service, t: 's' };
      ws['G' + renInicial] = { v: operation, t: 's' };
      ws['H' + renInicial] = { v: originCity, t: 's' };
      ws['I' + renInicial] = { v: destinationCity, t: 's' };
      ws['J' + renInicial] = {
        v: moment.unix(etd).format('DD/MM/YYYY'),
        t: 's',
      };
      ws['K' + renInicial] = {
        v: moment.unix(eta).format('DD/MM/YYYY'),
        t: 's',
      };
      ws['L' + renInicial] = {
        v: `$ ${formatMoney(quoteTotal)}`,
        t: 's',
      };
      ws['M' + renInicial] = {
        v: `$ ${formatMoney(additionalServicesTotal)}`,
        t: 's',
      };
      ws['N' + renInicial] = { v: status, t: 's' };
      ws['O' + renInicial] = { v: invoiceStatusName, t: 's' };

      renInicial++;
    }
    // var wscols = [{ wch: 30 }, { wch: 40 }, { wch: 35 }, { wch: 35 }];
    // ws['!cols'] = wscols;
    ws['!ref'] = XLSX.utils.encode_range({
      s: { c: 0, r: 0 },
      e: { c: 15, r: renInicial },
    });
    wb.SheetNames.push(ws_name);
    wb.Sheets[ws_name] = ws;
    XLSX.writeFile(wb, filename, { cellStyles: true });
    // setExcelLoading(false);
  };
  const exportPdf = (operations, startDate, endDate) => {
    let mstrTitulo = '';
    let mstrPdf = '';
    if (isFinancial) {
      mstrTitulo = `Facturación cuentas por cobrar`;
      mstrPdf = `Facturación_cuentas_por_cobrar${moment().format('L')}.pdf`;
    } else if (!isFinancial) {
      mstrTitulo = `Operaciones`;
      mstrPdf = `Operaciones${moment().format('L')}.pdf`;
    }
    var registros = [];
    if (isFinancial) {
      operations.forEach((element) => {
        registros.push([
          element.operationIdStr,
          moment.unix(element.createdAt).format('DD/MM/YYYY'),
          element.name,
          element.supplier,
          element.purchaseOrder,
          element.service,
          element.operation,
          element.originCity,
          element.destinationCity,
          moment.unix(element.etd).format('DD/MM/YYYY'),
          moment.unix(element.eta).format('DD/MM/YYYY'),
          '$ ' + formatMoney(element.quoteTotal),
          '$ ' + formatMoney(element.additionalServicesTotal),
          element.status,
          element.status === 'Cancelado' ? null : element.invoiceStatusName,
        ]);
      });
    } else {
      operations.forEach((element) => {
        registros.push([
          element.operationIdStr,
          moment.unix(element.createdAt).format('DD/MM/YYYY'),
          element.name,
          element.supplier,
          element.purchaseOrder,
          element.service,
          element.operation,
          element.originCity,
          element.destinationCity,
          moment.unix(element.etd).format('DD/MM/YYYY'),
          moment.unix(element.eta).format('DD/MM/YYYY'),
          element.status,
          element.status === 'Cancelado' ? null : element.invoiceStatusName,
        ]);
      });
    }

    let fecha = moment().format('LLL');
    let doc = new jsPDF('landscape');
    doc.setFontSize(12);

    let xFecha = 220;
    let yFecha = 10;

    let totalPagesExp = '{total_pages_count_string}';

    let pageContent = function (data) {
      //header
      doc.text(15, 10, mstrTitulo);
      doc.text(-10, -10, mstrTitulo);
      doc.text(fecha, xFecha, yFecha);
      // FOOTER
      let str = 'Página ' + data.pageCount;
      //Total page number plugin only available in jspdf v1.0+
      if (typeof doc.putTotalPages === 'function') {
        str = str + ' de ' + totalPagesExp;
      }
      doc.setFontSize(10);
      doc.text(
        str,
        data.settings.margin.left,
        doc.internal.pageSize.height - 10
      );
    };
    if (isFinancial) {
      doc.autoTable({
        A3: { v: 'Id. operación', t: 's' },
        B3: { v: 'Fecha operación', t: 's' },
        C3: { v: 'Cliente', t: 's' },
        D3: { v: 'Proveedor', t: 's' },
        E3: { v: 'Orden de compra', t: 's' },
        F3: { v: 'Servicio', t: 's' },
        G3: { v: 'Operación', t: 's' },
        H3: { v: 'Origen', t: 's' },
        I3: { v: 'Destino', t: 's' },
        J3: { v: 'ETD', t: 's' },
        K3: { v: 'ETA', t: 's' },
        L3: { v: 'Costos totales', t: 's' },
        M3: { v: 'Servicios adicionales', t: 's' },
        N3: { v: 'Estatus', t: 's' },
        O3: { v: 'Estatus facturación', t: 's' },
        head: [
          [
            'Id. operación',
            'Fecha operación',
            'Cliente',
            'Proveedor',
            'Orden de compra',
            'Servicio',
            'Operación',
            'Origen',
            'Destino',
            'ETD',
            'ETA',
            'Costos totales',
            'Servicios adicionales',
            'Estatus',
            'Estatus facturación',
          ],
        ],
        didDrawPage: pageContent,
        theme: 'grid',
        headStyles: {
          valign: 'middle',
          halign: 'center',
          fillColor: [230, 81, 0],
          textColor: [255, 255, 255],
          // cellWidth: { 3: 200 },
        },
        body: registros,
        columnStyles: {
          0: { halign: 'center', cellWidth: 22, minCellWidth: 10 },
          1: { halign: 'center', cellWidth: 22, minCellWidth: 10 },
          2: { halign: 'center', cellWidth: 22, minCellWidth: 10 },
          3: { halign: 'center', cellWidth: 25, minCellWidth: 10 },
          4: { halign: 'center', cellWidth: 15, minCellWidth: 10 },
        },

        // columnStyles: {
        //   text: {
        //     cellWidth: 'wrap',

        //   },
        // },
      });
    } else {
      doc.autoTable({
        A3: { v: 'Id. operación', t: 's' },
        B3: { v: 'Fecha operación', t: 's' },
        C3: { v: 'Cliente', t: 's' },
        D3: { v: 'Proveedor', t: 's' },
        E3: { v: 'Orden de compra', t: 's' },
        F3: { v: 'Servicio', t: 's' },
        G3: { v: 'Operación', t: 's' },
        H3: { v: 'Origen', t: 's' },
        I3: { v: 'Destino', t: 's' },
        J3: { v: 'ETD', t: 's' },
        K3: { v: 'ETA', t: 's' },
        L3: { v: 'Estatus', t: 's' },
        M3: { v: 'Estatus facturación', t: 's' },
        head: [
          [
            'Id. operación',
            'Fecha operación',
            'Cliente',
            'Proveedor',
            'Orden de compra',
            'Servicio',
            'Operación',
            'Origen',
            'Destino',
            'ETD',
            'ETA',
            'Estatus',
            'Estatus facturación',
          ],
        ],
        didDrawPage: pageContent,
        theme: 'grid',
        headStyles: {
          valign: 'middle',
          halign: 'center',
          fillColor: [230, 81, 0],
          textColor: [255, 255, 255],
          // cellWidth: { 3: 200 },
        },
        body: registros,
        columnStyles: {
          0: { halign: 'center', cellWidth: 22, minCellWidth: 10 },
          1: { halign: 'center', cellWidth: 22, minCellWidth: 10 },
          2: { halign: 'center', cellWidth: 22, minCellWidth: 10 },
          3: { halign: 'center', cellWidth: 25, minCellWidth: 10 },
          4: { halign: 'center', cellWidth: 15, minCellWidth: 10 },
        },

        // columnStyles: {
        //   text: {
        //     cellWidth: 'wrap',

        //   },
        // },
      });
    }

    // call footer() after each doc.addPage()
    // and before doc.save() do not forget put
    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(totalPagesExp);
    }
    //spinner.style.display = 'none';
    doc.save(mstrPdf);
  };
  return (
    <div>
      {[ROL_OPERACIONES, ROL_LAYOUT].some((r) => person.roles.includes(r)) &&
        !isFinancial &&
        !pickOperation && (
          <>
            <Button
              className={classButton}
              onClick={() => history.push('/operacion/nueva')}
              icon={<PlusCircleOutlined />}
            >
              Agregar
            </Button>
            <Button
              // disabled={!hasSelected}
              disabled={!operationSelected}
              className={classButtonDelete}
              style={{ marginBottom: 10 }}
              onClick={() => showDeleteConfirm(operationSelected)}
              icon={<DeleteOutlined />}
            >
              Eliminar
            </Button>
          </>
        )}
      {!pickOperation && (
        <Button
          className={classButton}
          icon={<ReloadOutlined />}
          onClick={() => setReloadTable(true)}
        >
          Refrescar
        </Button>
      )}

      {selectedRowKeys.length > 0 && (
        <p>Seleccionados: {selectedRowKeys.length} elementos</p>
      )}
      <Table
        // rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
        dataSource={operations}
        columns={isFinancial ? financialColumns : columns}
        bordered
        size="middle"
        scroll={{ x: 1200 }}
        // setSelectedRowKeys
        className="table-striped-rows"
        // rowSelection={rowSelection}
        loading={isLoading || reloadTable}
        onChange={handleChange}
        footer={() => {
          return (
            <FooterTable
              pickOperation={pickOperation}
              setModalOperation={setModalOperation}
              // clearSupplier={clearSupplier}
            />
          );
        }}
      />
      {!pickOperation && (
        <Row>
          <Col span={24} style={{ textAlign: 'right' }}>
            <Button
              icon={<FilePdfFilled style={{ fontSize: 18 }} />}
              className="btn-download"
              onClick={() => exportPdf(operations)}
              disabled={operations.length === 0}
              style={{ width: 150 }}
            >
              Descargar Pdf
            </Button>
            <Button
              icon={<FileExcelFilled style={{ fontSize: 18 }} />}
              className="btn-download"
              onClick={() =>
                isFinancial
                  ? generateFinancialExcel(operations)
                  : generateExcel(operations)
              }
              disabled={operations.length === 0}
              style={{ width: 160 }}
            >
              Descargar Excel
            </Button>
          </Col>
        </Row>
      )}

      <Modal
        title={titleModal}
        visible={modalIsVisible}
        setIsVisible={setModalIsVisible}
        width={1000}
      >
        {contextModal}
      </Modal>
    </div>
  );
};

function FooterTable({ pickOperation, setModalOperation }) {
  return (
    <div className="footer-table">
      {pickOperation && (
        <>
          <Button
            type="primary"
            onClick={() => {
              setModalOperation(false);
            }}
          >
            Aceptar
          </Button>
        </>
      )}
    </div>
  );
}
export default OperationsTable;
