import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Input,
  Modal as ModalAntd,
  Row,
  Space,
  Table,
  Tag,
  Tooltip,
  notification,
} from 'antd';
import {
  ExclamationCircleOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
  ReloadOutlined,
  EditOutlined,
  SearchOutlined,
  FileExcelFilled,
  FilePdfFilled,
  EyeOutlined,
} from '@ant-design/icons';
import Modal from '../../General/Modal';
import {
  METHOD_DELETE,
  METHOD_GET,
  ROL_LAYOUT,
  ROL_OPERACIONES,
  ROL_PRICING,
  ROL_VENTAS,
} from '../../../utils/constants';
import { getAccessTokenApi } from '../../../api/auth';
import { fetchApi, fetchNoContent } from '../../../helpers/fetch';
import { API_URL_CALLS } from '../../../helpers/urls';
import moment from 'moment';
import XLSX from 'xlsx';
import AddEditCallForm from '../CallLog/AddEditCallForm';
import Highlighter from 'react-highlight-words';
import useUser from '../../../hooks/useUser';
import jsPDF from 'jspdf';
import { formatMoney, isMobile } from '../../../utils/general';
import { getFilteredDataFromLocalStorage, saveFilteredDataToLocalStorage } from '../../../helpers/saveFilterData';
import { CALLTYPE_VALUE_ID } from '../../../helpers/constants';
import { getCatalogValueHandle } from '../../../helpers/handlerFunctionApi';

const CallLogTable = ({isFinancial}) => {
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [titleModal, setTitleModal] = useState(false);
  const [contextModal, setContextModal] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const { confirm } = ModalAntd;
  const [isLoading, setIsLoading] = useState(true);
  const token = getAccessTokenApi();
  const [reloadTable, setReloadTable] = useState(false);
  const [calls, setCalls] = useState([]);
  const textModal = '¿Estás seguro que deseas borrar esta llamada';
  const [searchedColumn, setSearchedColumn] = useState('');
  const [filteredInfo, setFilteredInfo] = useState({});
  const [searchText, setSearchText] = useState('');
  const [callSelected, setCallSelected] = useState(null);
  const classButton = isMobile() ? 'btn-download-mobile' : 'btn-download';
  const classButtonDelete = isMobile() ? 'btn-delete-mobile' : 'btn-delete';
  const [filterCallType, setFilterCallType] = useState([]);
  const [callType, setCallType] = useState([]);
  const [startDate, setStartDate] = useState({
    createdAt: '',
    etd: '',
    eta: '',
  });
  const [endDate, setEndDate] = useState({
    createdAt: '',
    etd: '',
    eta: '',
  });
  const x = React.createRef();
  const { person } = useUser();
 
  useEffect(() => {
    getCatalogValueHandle(CALLTYPE_VALUE_ID, setCallType, setIsLoading, 0, 'TIPOLLAMADA');
  }, []);
  useEffect(() => {
    let filters = [];
    callType.forEach((element) => {
      const json = { text: element.label, value: element.label };
      filters.push(json);
    });
    setFilterCallType(filters);
  }, [callType]);

  useEffect(() => {
    setIsLoading(true);
    let url = ''
    if (person.roles.includes('Layout','Admin')) {
        url = `${API_URL_CALLS}`
    }else{
        url = `${API_URL_CALLS}?userId=${person.idUser}`
    }
    const config = {
      method: METHOD_GET,
      url: url,
      data: null,
      token,
      showNotificationError: false,
    };
    fetchApi(config)
      .then((response) => {
        if (response.statusCode === 200) {
          const allCalls = response.result;
          allCalls.map((call) => (call['key'] = call.id));
          setCalls(allCalls);
        }
        setIsLoading(false);
        setReloadTable(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        setReloadTable(false);
      });
  }, [reloadTable, token, person]);
  
  useEffect(() => {
    const datosObtenidos = getFilteredDataFromLocalStorage('callLog');
    if (datosObtenidos !== null) {
      setFilteredInfo(datosObtenidos.filters);
      setStartDate(datosObtenidos.dateFilters.startDates)
      setEndDate(datosObtenidos.dateFilters.endDates)
    }
  },[])
  const handleReset = React.useCallback(
    (clearFilters, selectedKeys, confirm, dataIndex) => {
      clearFilters();
      setSearchText('');
      startDate[dataIndex] = '';
      endDate[dataIndex] = '';
    },
    [endDate, startDate]
  );
  const getColumnSearchProps = React.useCallback(
    (dataIndex) => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              // this.searchInput = node;
            }}
            placeholder={`Buscar`}
            value={selectedKeys[0]}
            onChange={(e) =>{
              setSelectedKeys(e.target.value ? [e.target.value] : [])
             } }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Buscar
            </Button>
            <Button
              onClick={() => {
                handleReset(clearFilters);
                handleSearch(selectedKeys, confirm, dataIndex);
              }}
              size="small"
              style={{ width: 90 }}
            >
              Limpiar
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      onFilter: (value, record) =>
        record[dataIndex]
          ? record[dataIndex]
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
          : '',
      filteredValue: filteredInfo[dataIndex] || null,
      onFilterDropdownVisibleChange: (visible) => {},
      render: (text) =>
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        ) : (
          text
        ),
    }),
    [filteredInfo, handleReset, searchText, searchedColumn]
  );
  const getColumnSearchDateProps = React.useCallback(
    (dataIndex) => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Col>
            <DatePicker
              ref={x}
              id={'startDate' + dataIndex}
              placeholder={'Fecha inicial'}
              style={{ marginRight: '10px' }}
              format="DD/MM/YYYY"
              allowClear={false}
              value={
                startDate[dataIndex] !== ''
                  ? moment.unix(startDate[dataIndex])
                  : null
              }
              onChange={(e) => {
                // setStartDate(moment(e).unix());
                startDate[dataIndex] = moment(e).startOf('day').unix();
                setSelectedKeys(
                  calls
                    .filter(
                      (op) =>
                        moment(e)
                          .hours(0)
                          .minutes(0)
                          .seconds(0)
                          .milliseconds(0)
                          .unix() <= op[dataIndex]
                    )
                    .map((op) => op.key)
                );
                // setSelectedKeys(e !== null ? [e.format('DD/MM/YYYY')] : [])
              }}
            />
            <DatePicker
              placeholder={'Fecha final'}
              format="DD/MM/YYYY"
              allowClear={false}
              value={
                endDate[dataIndex] !== ''
                  ? moment.unix(endDate[dataIndex])
                  : null
              }
              onChange={(e) => {
                // setEndDate(moment(e).unix());
                endDate[dataIndex] = moment(e).endOf('day').unix();
                setSelectedKeys(
                  calls
                    .filter(
                      (op) =>
                        op[dataIndex] <=
                        moment(e)
                          .hours(23)
                          .minutes(59)
                          .seconds(59)
                          .milliseconds(0)
                          .unix()
                    )
                    .map((op) => op.key)
                );
              }}
            />
          </Col>
          <Row justify="end" gutter={16}>
            <Col>
              <Button
              disabled={endDate[dataIndex] === ''}
                type="primary"
                onClick={() => {
                  handleSearchDate(selectedKeys, confirm);
                  setSelectedKeys(
                    calls
                      .filter(
                        (d) =>
                          startDate[dataIndex] <= d[dataIndex] &&
                          d[dataIndex] <= endDate[dataIndex]
                      )
                      .map((d) => d.key)
                  );
                }}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Buscar
              </Button>
              <Button
                onClick={() => {
                  handleReset(clearFilters, selectedKeys, confirm, dataIndex);
                  handleSearchDate(selectedKeys, confirm, dataIndex);
                }}
                size="small"
                style={{ width: 90 }}
              >
                Limpiar
              </Button>
            </Col>
          </Row>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      onFilter: (value, record) =>
        startDate[dataIndex] <= record[dataIndex] &&
        record[dataIndex] <= endDate[dataIndex],
      filteredValue: filteredInfo[dataIndex] || null,
      onFilterDropdownVisibleChange: (visible) => {},
      render: (text) =>
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? moment.unix(text).format('DD/MM/YYYY') : ''}
          />
        ) : (
          moment.unix(text).format('DD/MM/YYYY')
        ),
    }),
    [
      endDate,
      filteredInfo,
      handleReset,
      calls,
      searchText,
      searchedColumn,
      startDate,
      x,
    ]
  );
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleSearchDate = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };
  const handleChange = (pagination, filters, sorter, extra) => {
    const dateFilters = { startDates: startDate, endDates: endDate}
    saveFilteredDataToLocalStorage('callLog', pagination, sorter, filters, dateFilters);
    setFilteredInfo(filters);
  };
  const showModal = React.useCallback(
    (type, call) => {
      if (type === 'add') {
        setModalIsVisible(true);
        setTitleModal('Agregar llamada');
        setContextModal(
          <AddEditCallForm
            setModalIsVisible={setModalIsVisible}
            type={type}
            setReloadTable={setReloadTable}
          ></AddEditCallForm>
        );
      } else if (type === 'edit') {
        setModalIsVisible(true);
        setTitleModal(`Editar llamada`);
        setContextModal(() => (
          <AddEditCallForm
            setModalIsVisible={setModalIsVisible}
            type={type}
            setReloadTable={setReloadTable}
            call={call}
          />
        ));
      }else if (type === 'watch') {
        setModalIsVisible(true);
        setTitleModal(`Registro de llamada`);
        setContextModal(() => (
          <AddEditCallForm
            setModalIsVisible={setModalIsVisible}
            type={type}
            setReloadTable={setReloadTable}
            call={call}
          />
        ));
      }
    },
    [setModalIsVisible, setReloadTable, setContextModal]
  );
  const checkCallSelected = React.useCallback(
    (call) => {
      if (callSelected === call.id) {
        setCallSelected(null);
      } else {
        setCallSelected(call.id);
      }
    },
    [callSelected]
  );
  const columns = React.useMemo(
    () => [
      {
        title: 'Nombre ejecutivo',
        dataIndex: 'createdByName',
        key: 'createdByName',
        width: 150,
        align: 'center',
        ...getColumnSearchProps('createdByName'),
      },
      {
        title: 'Apellido ejecutivo',
        dataIndex: 'createdByLastName',
        key: 'createdByLastName',
        width: 150,
        align: 'center',
        ...getColumnSearchProps('createdByLastName'),
      },
      {
        title: 'Tipo de llamada',
        dataIndex: 'callTypeDescription',
        key: 'callTypeDescription',
        width: 150,
        align: 'center',
        filters: filterCallType,
        filteredValue: filteredInfo.callTypeDescription || null,
        onFilter: (value, record) => record.callTypeDescription === value,
      },
    {
        title: 'Duración',
        dataIndex: 'duration',
        key: 'duration',
        width: 100,
        align: 'center',
        render: (duration) => `${duration} minutos`,
      },
      {
        title: 'Contacto',
        dataIndex: 'contact',
        key: 'contact',
        width: 150,
        align: 'center',
        ...getColumnSearchProps('contact'),
      },
      {
        title: 'Fecha llamada',
        dataIndex: 'createdAt',
        key: 'createdAt',
        width: 100,
        align: 'center',
        render: (createdAt) => (
          <>{moment.unix(createdAt).format('DD/MM/YYYY')}</>
        ),
        filteredValue: filteredInfo.createdAt || null,
        ...getColumnSearchDateProps('createdAt'),
      },
      {
        title: 'Cliente',
        dataIndex: 'customerName',
        key: 'customerName',
        width: 150,
        ellipsis: true,
        align: 'center',
        ...getColumnSearchProps('customerName'),
      },
      {
        title: 'Proveedor',
        dataIndex: 'supplierName',
        key: 'supplierName',
        width: 150,
        align: 'center',
        ...getColumnSearchProps('supplierName'),
      },
      {
        title: 'Estatus',
        dataIndex: 'missed',
        key: 'missed',
        width: 120,
        align: 'center',
        filters: [
          { text: 'Con respuesta', value: false },
          { text: 'Sin respuesta', value: true },
        ],
        filteredValue: filteredInfo.missed || null,
        onFilter: (value, record) => record.missed.includes(value),
        render: (missed) => (
          <Tag
            color={
                missed === false
                ? 'green'
                : 'red'
            }
          >
            {missed === false ? 'Con respuesta' : 'Sin respuesta'}
          </Tag>
        ),
      },
      {
        title: 'Acciones',
        key: 'id',
        fixed: 'right',
        width:  80,
        align: 'center',
        render: (call, record) => (
          <>
              <>
                {[ROL_LAYOUT].some((r) =>
                  person.roles.includes(r)
                ) &&(
                    <Tooltip title="Ver más información">
                  <Button
                    style={{ marginLeft: 3 }}
                    type="primary"
                    ghost
                    shape="default"
                    icon={<EyeOutlined />}
                    onClick={() => showModal('watch', call)}
                  />
                </Tooltip>
                  )}
              </>
          </>
        ),
      },
    ],
    [
      filteredInfo,
      getColumnSearchDateProps,
      getColumnSearchProps,
      filterCallType,
      person,
      showModal,
    ]
  );
  const financialColumns = React.useMemo(
    () => [
        {
          title: '',
          ellipsis: true,
          width: 50,
          // width: 100,
          dataIndex: 'id',
          key: 'company',
          render: (call, record) => (
            <Checkbox
              onChange={() => checkCallSelected(record)}
              checked={callSelected === call}
              style={{ padding: 10 }}
              className="button-detail"
            ></Checkbox>
          ),
        },
        {
            title: 'Tipo de llamada',
            dataIndex: 'callTypeDescription',
            key: 'callTypeDescription',
            width: 150,
            align: 'center',
            filters: filterCallType,
            filteredValue: filteredInfo.callTypeDescription || null,
            onFilter: (value, record) => record.callTypeDescription === value,
          },
        {
            title: 'Duración',
            dataIndex: 'duration',
            key: 'duration',
            width: 100,
            align: 'center',
            render: (duration) => `${duration} minutos`,
          },
          {
            title: 'Contacto',
            dataIndex: 'contact',
            key: 'contact',
            width: 150,
            align: 'center',
            ...getColumnSearchProps('contact'),
          },
        {
          title: 'Fecha llamada',
          dataIndex: 'createdAt',
          key: 'createdAt',
          width: 100,
          align: 'center',
          render: (createdAt) => (
            <>{moment.unix(createdAt).format('DD/MM/YYYY')}</>
          ),
          filteredValue: filteredInfo.createdAt || null,
          ...getColumnSearchDateProps('createdAt'),
        },
        {
          title: 'Cliente',
          dataIndex: 'customerName',
          key: 'customerName',
          width: 150,
          ellipsis: true,
          align: 'center',
          ...getColumnSearchProps('customerName'),
        },
        {
          title: 'Proveedor',
          dataIndex: 'supplierName',
          key: 'supplierName',
          width: 150,
          align: 'center',
          ...getColumnSearchProps('supplierName'),
        },
        {
          title: 'Estatus',
          dataIndex: 'missed',
          key: 'missed',
          width: 120,
          align: 'center',
          filters: [
            { text: 'Con respuesta', value: false },
            { text: 'Sin respuesta', value: true },
          ],
          filteredValue: filteredInfo.missed || null,
          onFilter: (value, record) => record.missed.includes(value),
          render: (missed) => (
            <Tag
              color={
                  missed === false
                  ? 'green'
                  : 'red'
              }
            >
              {missed === false ? 'Con respuesta' : 'Sin respuesta'}
            </Tag>
          ),
        },
        {
          title: 'Acciones',
          key: 'id',
          fixed: 'right',
          width:  80,
          align: 'center',
          render: (call, record) => (
            <>
                      <Tooltip title="Editar">
                        <Button
                          style={{ marginLeft: 3 }}
                          type="primary"
                          shape="default"
                          icon={<EditOutlined />}
                          onClick={() => showModal('edit', record)}
                        />
                      </Tooltip>
            </>
          ),
        },
      ],
      [
        checkCallSelected,
        filteredInfo,
        filterCallType,
        getColumnSearchDateProps,
        getColumnSearchProps,
        callSelected,
        showModal,
      ]
  );
  const deleteCall = async (id) => {
    const config = {
      method: METHOD_DELETE,
      url: `${API_URL_CALLS}/${id}`,
      data: null,
      token,
      showNotification: false,
    };
    const result = await fetchNoContent(config);
    if (result) {
        console.log(result,'result');
      if (result.status === 204) {
        notification['success']({
            message: 'Llamada eliminada exitosamente.',
          });
        setReloadTable(true);
        setSelectedRowKeys([]);
      }else{
        notification['error']({
            message: 'Ocurrió un error, inténtelo más tarde',
          });
      }
    }
  };
  const showDeleteConfirm = (id, description = '') => {
    confirm({
      title: `${textModal}?`,
      icon: <ExclamationCircleOutlined />,
      content: 'No podrá deshacer esta acción después..',
      okText: 'Si',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteCall(id);
      },
      onCancel() {},
    });
  };
  const generateExcel = (entries) => {
    // setExcelLoading(true);
    let filename = '';
    let mstrTitulo = '';
    filename = `Llamadas${moment().format('L')}.xlsx`;
    mstrTitulo = `Llamadas`;
    let fecha = moment().format('LLL');
    const ws_name = 'Hoja1';
    let wb = {
      SheetNames: [],
      Sheets: {},
    };
    let ws = {};
    let renInicial = 4;

    ws = {
      A1: { v: mstrTitulo, t: 's' },
      I1: { v: fecha, t: 's' },

      A3: { v: 'Nombre ejecutivo', t: 's' },
      B3: { v: 'Apellido ejecutivo', t: 's' },
      C3: { v: 'Tipo de llamada', t: 's' },
      D3: { v: 'Duración', t: 's' },
      E3: { v: 'Contacto', t: 's' },
      F3: { v: 'Fecha llamada', t: 's' },
      G3: { v: 'Cliente', t: 's' },
      H3: { v: 'Proveedor', t: 's' },
      I3: { v: 'Estatus', t: 's' },
    };
    for (let i in entries) {
      const {
        createdByName,createdByLastName,callTypeDescription,duration,contact,createdAt,customerName,
    supplierName,missed,
      } = entries[i];
      ws['A' + renInicial] = { v: createdByName, t: 's' };
      ws['B' + renInicial] = { v: createdByLastName, t: 's' };
      ws['C' + renInicial] = { v: callTypeDescription, t: 's' };
      ws['D' + renInicial] = { v: (`${duration} minutos`), t: 's' };
      ws['E' + renInicial] = { v: contact, t: 's' };
      ws['F' + renInicial] = {
        v: moment.unix(createdAt).format('DD/MM/YYYY'),
        t: 's',
      };
      ws['G' + renInicial] = { v: customerName ? customerName : '', t: 's' };
      ws['H' + renInicial] = { v: supplierName ? supplierName : '', t: 's' };
      ws['I' + renInicial] = { v: missed === true ? 'Sin respuesta' : 'Con respuesta', t: 's' };

      renInicial++;
    }
    // var wscols = [{ wch: 30 }, { wch: 40 }, { wch: 35 }, { wch: 35 }];
    // ws['!cols'] = wscols;
    ws['!ref'] = XLSX.utils.encode_range({
      s: { c: 0, r: 0 },
      e: { c: 15, r: renInicial },
    });
    wb.SheetNames.push(ws_name);
    wb.Sheets[ws_name] = ws;
    XLSX.writeFile(wb, filename, { cellStyles: true });
    // setExcelLoading(false);
  };
  const generateFinancialExcel = (entries) => {
    // setExcelLoading(true);
    let filename = '';
    let mstrTitulo = '';
    filename = `Llamadas${moment().format('L')}.xlsx`;
    mstrTitulo = `Llamadas`;
    let fecha = moment().format('LLL');
    const ws_name = 'Hoja1';
    let wb = {
      SheetNames: [],
      Sheets: {},
    };
    let ws = {};
    let renInicial = 4;

    // var mstrTitulo = `Facturación y notas de crédito del ${dateRange.startDate} al: ${dateRange.endDate}`;

    ws = {
      A1: { v: mstrTitulo, t: 's' },
      I1: { v: fecha, t: 's' },

      A3: { v: 'Tipo de llamada', t: 's' },
      B3: { v: 'Duración', t: 's' },
      C3: { v: 'Contacto', t: 's' },
      D3: { v: 'Fecha llamada', t: 's' },
      E3: { v: 'Cliente', t: 's' },
      F3: { v: 'Proveedor', t: 's' },
      G3: { v: 'Estatus', t: 's' },
    };
    for (let i in entries) {
        const {
        callTypeDescription,duration,contact,createdAt,customerName,
      supplierName,missed,
        } = entries[i];
        ws['A' + renInicial] = { v: callTypeDescription, t: 's' };
        ws['B' + renInicial] = { v: (`${duration} minutos`), t: 's' };
        ws['C' + renInicial] = { v: contact, t: 's' };
        ws['D' + renInicial] = {
          v: moment.unix(createdAt).format('DD/MM/YYYY'),
          t: 's',
        };
        ws['E' + renInicial] = { v: customerName ? customerName : '', t: 's' };
        ws['F' + renInicial] = { v: supplierName ? supplierName : '', t: 's' };
        ws['G' + renInicial] = { v: missed === true ? 'Sin respuesta' : 'Con respuesta', t: 's' };
  
        renInicial++;
      }
    // var wscols = [{ wch: 30 }, { wch: 40 }, { wch: 35 }, { wch: 35 }];
    // ws['!cols'] = wscols;
    ws['!ref'] = XLSX.utils.encode_range({
      s: { c: 0, r: 0 },
      e: { c: 15, r: renInicial },
    });
    wb.SheetNames.push(ws_name);
    wb.Sheets[ws_name] = ws;
    XLSX.writeFile(wb, filename, { cellStyles: true });
    // setExcelLoading(false);
  };
  const exportPdf = (calls, startDate, endDate) => {
    let mstrTitulo = '';
    let mstrPdf = '';
      mstrTitulo = `Llamadas`;
      mstrPdf = `Llamadas${moment().format('L')}.pdf`;
    var registros = [];
    if (person.roles.includes('Layout','Admin')) {
      calls.forEach((element) => {
        registros.push([
          element.createdByName,
          element.createdByLastName,
          element.callTypeDescription,
          `${element.duration} minutos`,
          element.contact,
          moment.unix(element.createdAt).format('DD/MM/YYYY'),
          element.customerName,
          element.supplierName,
          element.missed === true ? 'Sin respuesta' : 'Con respuesta',
        ]);
      });
    } else {
      calls.forEach((element) => {
        registros.push([
            element.callTypeDescription,
            `${element.duration} minutos`,
            element.contact,
            moment.unix(element.createdAt).format('DD/MM/YYYY'),
            element.customerName,
            element.supplierName,
            element.missed === true ? 'Sin respuesta' : 'Con respuesta',
        ]);
      });
    }

    let fecha = moment().format('LLL');
    let doc = new jsPDF('landscape');
    doc.setFontSize(12);

    let xFecha = 220;
    let yFecha = 10;

    let totalPagesExp = '{total_pages_count_string}';

    let pageContent = function (data) {
      //header
      doc.text(15, 10, mstrTitulo);
      doc.text(-10, -10, mstrTitulo);
      doc.text(fecha, xFecha, yFecha);
      // FOOTER
      let str = 'Página ' + data.pageCount;
      //Total page number plugin only available in jspdf v1.0+
      if (typeof doc.putTotalPages === 'function') {
        str = str + ' de ' + totalPagesExp;
      }
      doc.setFontSize(10);
      doc.text(
        str,
        data.settings.margin.left,
        doc.internal.pageSize.height - 10
      );
    };
    if (person.roles.includes('Layout','Admin')) {
      doc.autoTable({
      A3: { v: 'Nombre ejecutivo', t: 's' },
      B3: { v: 'Apellido ejecutivo', t: 's' },
      C3: { v: 'Tipo de llamada', t: 's' },
      D3: { v: 'Duración', t: 's' },
      E3: { v: 'Contacto', t: 's' },
      F3: { v: 'Fecha llamada', t: 's' },
      G3: { v: 'Cliente', t: 's' },
      H3: { v: 'Proveedor', t: 's' },
      I3: { v: 'Estatus', t: 's' },
        head: [
          [
            'Nombre ejecutivo',
            'Apellido ejecutivo',
            'Tipo de llamada',
            'Duración',
            'Contacto',
            'Fecha llamada',
            'Cliente',
            'Proveedor',
            'Estatus',
          ],
        ],
        didDrawPage: pageContent,
        theme: 'grid',
        headStyles: {
          valign: 'middle',
          halign: 'center',
          fillColor: [230, 81, 0],
          textColor: [255, 255, 255],
          // cellWidth: { 3: 200 },
        },
        body: registros,
        // columnStyles: {
        //   0: { halign: 'center', cellWidth: 22, minCellWidth: 10 },
        //   1: { halign: 'center', cellWidth: 22, minCellWidth: 10 },
        //   2: { halign: 'center', cellWidth: 22, minCellWidth: 10 },
        //   3: { halign: 'center', cellWidth: 25, minCellWidth: 10 },
        //   4: { halign: 'center', cellWidth: 15, minCellWidth: 10 },
        // },

        // columnStyles: {
        //   text: {
        //     cellWidth: 'wrap',

        //   },
        // },
      });
    } else {
      doc.autoTable({
      A3: { v: 'Tipo de llamada', t: 's' },
      B3: { v: 'Duración', t: 's' },
      C3: { v: 'Contacto', t: 's' },
      D3: { v: 'Fecha llamada', t: 's' },
      E3: { v: 'Cliente', t: 's' },
      F3: { v: 'Proveedor', t: 's' },
      G3: { v: 'Estatus', t: 's' },
        head: [
          [
            'Tipo de llamada',
            'Duración',
            'Contacto',
            'Fecha llamada',
            'Cliente',
            'Proveedor',
            'Estatus',
          ],
        ],
        didDrawPage: pageContent,
        theme: 'grid',
        headStyles: {
          valign: 'middle',
          halign: 'center',
          fillColor: [230, 81, 0],
          textColor: [255, 255, 255],
          // cellWidth: { 3: 200 },
        },
        body: registros,
        // columnStyles: {
        //   0: { halign: 'center', cellWidth: 22, minCellWidth: 10 },
        //   1: { halign: 'center', cellWidth: 22, minCellWidth: 10 },
        //   2: { halign: 'center', cellWidth: 22, minCellWidth: 10 },
        //   3: { halign: 'center', cellWidth: 25, minCellWidth: 10 },
        //   4: { halign: 'center', cellWidth: 15, minCellWidth: 10 },
        // },

        // columnStyles: {
        //   text: {
        //     cellWidth: 'wrap',

        //   },
        // },
      });
    }

    // call footer() after each doc.addPage()
    // and before doc.save() do not forget put
    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(totalPagesExp);
    }
    //spinner.style.display = 'none';
    doc.save(mstrPdf);
  };
  return (
    <div>
      {[ROL_OPERACIONES, ROL_VENTAS, ROL_PRICING].some((r) => person.roles.includes(r)) && (
          <>
            <Button
              className={classButton}
              onClick={() => showModal('add')}
              icon={<PlusCircleOutlined />}
            >
              Agregar
            </Button>
            <Button
              // disabled={!hasSelected}
              disabled={!callSelected}
              className={classButtonDelete}
              style={{ marginBottom: 10 }}
              onClick={() => showDeleteConfirm(callSelected)}
              icon={<DeleteOutlined />}
            >
              Eliminar
            </Button>
          </>
        )}
        <Button
          className={classButton}
          icon={<ReloadOutlined />}
          onClick={() => setReloadTable(true)}
        >
          Refrescar
        </Button>

      {selectedRowKeys.length > 0 && (
        <p>Seleccionados: {selectedRowKeys.length} elementos</p>
      )}
      <Table
        // rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
        dataSource={calls}
        columns={person.roles.includes('Layout','Admin') ? columns : financialColumns}
        bordered
        size="middle"
        scroll={{ x: 1200 }}
        // setSelectedRowKeys
        className="table-striped-rows"
        // rowSelection={rowSelection}
        loading={isLoading || reloadTable}
        onChange={handleChange}
      />
        <Row>
          <Col span={24} style={{ textAlign: 'right' }}>
            <Button
              icon={<FilePdfFilled style={{ fontSize: 18 }} />}
              className="btn-download"
              onClick={() => exportPdf(calls)}
              disabled={calls.length === 0}
              style={{ width: 150 }}
            >
              Descargar Pdf
            </Button>
            <Button
              icon={<FileExcelFilled style={{ fontSize: 18 }} />}
              className="btn-download"
              onClick={() => person.roles.includes('Layout','Admin') ? generateExcel(calls)
                  : generateFinancialExcel(calls)
              }
              disabled={calls.length === 0}
              style={{ width: 160 }}
            >
              Descargar Excel
            </Button>
          </Col>
        </Row>
      <Modal
        title={titleModal}
        visible={modalIsVisible}
        setIsVisible={setModalIsVisible}
        width={1000}
      >
        {contextModal}
      </Modal>
    </div>
  );
};


export default CallLogTable;
