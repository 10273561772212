import React, { useState, useEffect } from 'react';
import {
  Table,
  Row,
  Col,
  Button,
  Tag,
  Modal as ModalAntd,
  Input,
  Space,
  Tooltip,
} from 'antd';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import {
  FileExcelFilled,
  FilePdfFilled,
  FileMarkdownOutlined,
  FilePdfOutlined,
  PlusCircleOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import './PaymentTable.scss';
import FileForm from '../../Billling/FileForm/FileForm';
import Modal from '../../../General/Modal';
import XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Highlighter from 'react-highlight-words';
import { formatMoney } from '../../../../utils/general';
import {
  ROL_FINANCIAL,
  ROL_LAYOUT,
  ROL_MANAGER,
} from '../../../../utils/constants';
import useUser from '../../../../hooks/useUser';
import { getFilteredDataFromLocalStorage, saveFilteredDataToLocalStorage } from '../../../../helpers/saveFilterData';

export default function PaymentTable({
  paymentComplements,
  reloadComplements,
  dateRange,
  setReloadComplements,
}) {
  let history = useHistory();
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [titleModal, setTitleModal] = useState(false);
  const [contextModal, setContextModal] = useState(null);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [paymentExport, setPaymentExport] = useState(paymentComplements);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const { person } = useUser();

  useEffect(() => {
    setPaymentExport(paymentComplements);
    setReloadComplements(false);
  }, [paymentComplements]);

  useEffect(() => {
    setFilteredInfo({});
  }, [dateRange]);

  useEffect(() => {
    const datosObtenidos = getFilteredDataFromLocalStorage('adminPayments');
    if (datosObtenidos !== null) {
    setFilteredInfo(datosObtenidos.filters);
    }
  },[])
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            // this.searchInput = node;
          }}
          placeholder={`Buscar`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => {
              handleReset(clearFilters);
              handleSearch(selectedKeys, confirm, dataIndex);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Limpiar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    filteredValue: filteredInfo[dataIndex] || null,
    onFilterDropdownVisibleChange: (visible) => {},
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  const columns = [
    {
      title: 'Fecha recepción',
      dataIndex: 'issueDate',
      key: 'issueDate',
      width: 150,
      sorter: (a, b) => a.issueDate - b.issueDate,
      render: (issueDate) => (
        <>{moment.utc(issueDate).local().format('DD/MM/YYYY')}</>
      ),
      ellipsis: true,
    },
    {
      title: 'Fecha documento',
      dataIndex: 'documentDate',
      key: 'documentDate',
      width: 160,
      sorter: (a, b) => a.documentDate - b.documentDate,
      render: (documentDate) => (
        <>{moment.utc(documentDate).local().format('DD/MM/YYYY')}</>
      ),
      ellipsis: true,
    },
    {
      title: 'Tipo documento',
      dataIndex: 'documentType',
      key: 'documentType',
      width: 120,
      ellipsis: true,
    },
    {
      title: 'Serie',
      dataIndex: 'serie',
      ...getColumnSearchProps('serie'),
      key: 'serie',
      width: 120,
      ellipsis: true,
    },
    {
      title: 'Folio',
      dataIndex: 'paymentRecNum',
      key: 'paymentRecNum',
      ...getColumnSearchProps('paymentRecNum'),
      width: 120,
      ellipsis: true,
    },
    {
      title: 'Importe',
      dataIndex: 'total',
      key: 'total',
      width: 120,
      ellipsis: true,
      render: (total) => <>${formatMoney(total)}</>,
    },
    {
      title: 'Moneda',
      dataIndex: 'currencyId',
      key: 'currencyId',
      width: 150,
      ellipsis: true,
      filters: [
        { text: 'MXN', value: 'MXN' },
        { text: 'USD', value: 'USD' },
      ],
      filteredValue: filteredInfo.currencyId || null,
      onFilter: (value, record) => record.currencyId.includes(value),
    },
    {
      title: 'Archivos',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      width: 150,
      ellipsis: true,
      render: (id, invoice) => (
        <Row>
          <Tooltip title="Xml">
            <Tag color={'green'}>
              <FileMarkdownOutlined onClick={() => showModal('xml', id)} />
            </Tag>
          </Tooltip>
          <Tooltip title="PDF">
            <Tag color={'red'}>
              <FilePdfOutlined onClick={() => showModal('pdf', id)} />
            </Tag>
          </Tooltip>
        </Row>
      ),
    },
    {
      title: 'Aclaraciones',
      key: 'hasOpenNote',
      dataIndex: 'hasOpenNote',
      align: 'center',
      width: 130,
      ellipsis: true,
      filters: [
        { text: 'Nuevo', value: 'new' },
        { text: 'Abierto', value: 'open' },
        { text: 'Cerrado', value: 'close' },
      ],
      filteredValue: filteredInfo.hasOpenNote || null,
      onFilter: (value, record) => {
        //cerrada : hasOpenNote === false && noteid !== null
        if (value === 'new') {
          return (
            (!record.hasOpenNote && record.noteId === 0) ||
            (!record.hasOpenNote && record.noteId !== 0)
          );
        }
        if (value === 'open') {
          return record.hasOpenNote && record.noteId != null;
        }
        if (value === 'close') {
          return !record.hasOpenNote && record.noteId != null;
        }
      },
      render: (hasOpenNote, invoice) => (
        <>
          {invoice.noteId !== null && (
            <Tooltip
              placement="topLeft"
              title={`${hasOpenNote ? 'Abierta' : 'Cerrada'}`}
            >
              <Tag
                onClick={() =>
                  history.push(`/aclaraciones/detail/${invoice.noteId}`)
                }
                color={hasOpenNote ? 'green' : 'gray'}
              >
                {invoice.noteId}
              </Tag>
            </Tooltip>
          )}
          {[ROL_FINANCIAL, ROL_LAYOUT, ROL_MANAGER].some((r) =>
            person.roles.includes(r)
          ) &&
            !hasOpenNote && (
              <Tooltip placement="topLeft" title={'Nueva'}>
                <Tag
                  onClick={() =>
                    history.push(
                      `/aclaraciones/nueva/cp/${invoice.paymentRecNum}`
                    )
                  }
                  color={'green'}
                >
                  <PlusCircleOutlined />
                </Tag>
              </Tooltip>
            )}
        </>
      ),
    },
  ];
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const handleChange = (pagination, filters, sorter, extra) => {
    saveFilteredDataToLocalStorage('adminPayments', pagination, sorter, filters);
    setFilteredInfo(filters);
    setPaymentExport(extra.currentDataSource);
  };

  const showModal = (type, reference) => {
    setModalIsVisible(true);

    if (type === 'xml') {
      setTitleModal('Archivo XML');
    } else if (type === 'pdf') {
      setTitleModal('Archivo PDF');
    }
    setContextModal(
      <FileForm
        setModalIsVisible={setModalIsVisible}
        type={type}
        reference={reference}
      ></FileForm>
    );
  };
  const generateExcel = (entries) => {
    const filename = `Complementos-de-pago${moment().format('L')}.xlsx`;

    const ws_name = 'Hoja1';
    let wb = {
      SheetNames: [],
      Sheets: {},
    };
    var mstrTitulo = `Complementos de pago del: ${dateRange.startDate} al: ${dateRange.endDate}`;
    let fecha = moment().format('LLL');

    let ws = {
      A1: { v: mstrTitulo, t: 's' },
      F1: { v: fecha, t: 's' },
      A2: { v: 'Fecha de recepción', t: 's' },
      B2: { v: 'Fecha documento', t: 's' },
      C2: { v: 'Tipo documento', t: 's' },
      D2: { v: 'Serie', t: 's' },
      E2: { v: 'Folio', t: 's' },
      F2: { v: 'Importe', t: 's' },
      G2: { v: 'Moneda', t: 's' },
    };
    let renInicial = 3;
    for (let i in entries) {
      const {
        issueDate,
        documentDate,
        documentType,
        serie,
        paymentRecNum,
        total,
        currencyId,
      } = entries[i];
      ws['A' + renInicial] = {
        v: moment.utc(issueDate).local().format('DD/MM/YYYY'),
        t: 's',
      };
      ws['B' + renInicial] = {
        v: moment.utc(documentDate).local().format('DD/MM/YYYY'),
        t: 's',
      };
      ws['C' + renInicial] = { v: documentType, t: 's' };
      ws['D' + renInicial] = { v: serie, t: 's' };
      ws['E' + renInicial] = { v: paymentRecNum, t: 's' };
      ws['F' + renInicial] = { v: `$${formatMoney(total)}`, t: 's' };
      ws['G' + renInicial] = { v: currencyId, t: 's' };
      renInicial++;
    }
    // var wscols = [{ wch: 30 }, { wch: 40 }, { wch: 35 }, { wch: 35 }];
    // ws['!cols'] = wscols;
    ws['!ref'] = XLSX.utils.encode_range({
      s: { c: 0, r: 0 },
      e: { c: 15, r: renInicial },
    });
    wb.SheetNames.push(ws_name);
    wb.Sheets[ws_name] = ws;
    XLSX.writeFile(wb, filename, { cellStyles: true });
  };

  const exportPdf = (bills, startDate, endDate) => {
    var mstrTitulo = `Complementos de pago del: ${dateRange.startDate} al: ${dateRange.endDate}`;

    // var mstrTitulo = `CORTE DE CAJA - Reporte de Ventas del: ${moment
    //     .unix(startDate)
    //     .format('LLL')} al: ${moment.unix(endDate).format('LLL')}`;
    var mstrPdf = `Complementos-de-pago${moment().format('L')}.pdf`;

    var registros = [];
    // let total_Sell = bills.length;

    bills.forEach((element) => {
      registros.push([
        moment.utc(element.issueDate).local().format('DD/MM/YYYY'),
        moment.utc(element.documentDate).local().format('DD/MM/YYYY'),
        element.documentType === 'I' ? 'Ingreso' : 'Egreso',
        element.serie,
        element.paymentRecNum,
        '$ ' + formatMoney(element.total),
        element.currencyId,
      ]);
    });

    let fecha = moment().format('LLL');

    let doc = new jsPDF('landscape');
    doc.setFontSize(12);

    let xFecha = 220;
    let yFecha = 10;

    let totalPagesExp = '{total_pages_count_string}';

    let pageContent = function (data) {
      //header
      doc.text(15, 10, mstrTitulo);
      doc.text(-10, -10, mstrTitulo);
      doc.text(fecha, xFecha, yFecha);
      // FOOTER
      let str = 'Página ' + data.pageCount;
      //Total page number plugin only available in jspdf v1.0+
      if (typeof doc.putTotalPages === 'function') {
        str = str + ' de ' + totalPagesExp;
      }
      doc.setFontSize(10);
      doc.text(
        str,
        data.settings.margin.left,
        doc.internal.pageSize.height - 10
      );
    };

    doc.autoTable({
      head: [
        [
          'Fecha recepción',
          'Fecha documento',
          'Tipo de documento',
          'serie',
          'Folio',
          'Importe',
          'Moneda',
        ],
      ],
      didDrawPage: pageContent,
      theme: 'grid',
      headStyles: {
        valign: 'middle',
        halign: 'center',
        fillColor: [49, 101, 120],
        textColor: [255, 255, 255],
      },
      body: registros,
      columnStyles: {
        text: {
          cellWidth: 'wrap',
        },
      },
    });

    // call footer() after each doc.addPage()

    // and before doc.save() do not forget put
    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(totalPagesExp);
    }
    //spinner.style.display = 'none';
    doc.save(mstrPdf);
  };

  return (
    <>
      <Table
        onChange={handleChange}
        columns={columns}
        dataSource={paymentComplements}
        scroll={{ x: 1000 }}
        loading={reloadComplements}
        pagination={{
          pageSize: 10,
        }}
      />

      <Row>
        <Col span={24} style={{ textAlign: 'right' }}>
          <Button
            icon={<FilePdfFilled style={{ fontSize: 18 }} />}
            className="btn-download"
            onClick={() => exportPdf(paymentExport)}
            disabled={paymentExport.length === 0}
            style={{ width: 150 }}
          >
            Descargar Pdf
          </Button>
          <Button
            icon={<FileExcelFilled style={{ fontSize: 18 }} />}
            className="btn-download"
            onClick={() => generateExcel(paymentExport)}
            disabled={paymentExport.length === 0}
            style={{ width: 160 }}
          >
            Descargar Excel
          </Button>
        </Col>
      </Row>
      <Modal
        title={titleModal}
        visible={modalIsVisible}
        setIsVisible={setModalIsVisible}
        width={1000}
      >
        {contextModal}
      </Modal>
    </>
  );
}
