import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Input,
  Modal as ModalAntd,
  Row,
  Space,
  Table,
  Tag,
  Tooltip,
} from 'antd';
import {
  CommentOutlined,
  ReloadOutlined,
  SearchOutlined,
  FileExcelFilled,
  FilePdfFilled,
  EyeOutlined,
} from '@ant-design/icons';
import Modal from '../../General/Modal';
import {
  METHOD_GET, ROL_ADMIN, ROL_LAYOUT, ROL_OPERACIONES, ROL_PRICING, ROL_VENTAS,
} from '../../../utils/constants';
import { getAccessTokenApi } from '../../../api/auth';
import { fetchApi } from '../../../helpers/fetch';
import { API_URL_OPERATION } from '../../../helpers/urls';
import moment from 'moment-timezone';
import Highlighter from 'react-highlight-words';
import { isMobile } from '../../../utils/general';
import XLSX from 'xlsx';
import jsPDF from 'jspdf';
import { getFilteredDataFromLocalStorage, saveFilteredDataToLocalStorage } from '../../../helpers/saveFilterData';
import AddEditComment from './AddEditComment/AddEditComment';
import useUser from '../../../hooks/useUser';

const SupplierPromotionByDate = ({date}) => {
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [titleModal, setTitleModal] = useState(false);
  const [contextModal, setContextModal] = useState(null);
  const { person } = useUser();
  const [isLoadingTable, setIsLoadingTable] = useState(true);
  const token = getAccessTokenApi();
  const [reloadTable, setReloadTable] = useState(false);
  const [quotes, setQuotes] = useState([]);
  const [startDate, setStartDate] = useState({
    createdAt: '',
    startDate: '',
    endDate: '',
  });
  const [endDate, setEndDate] = useState({
    createdAt: '',
    startDate: '',
    endDate: '',
  });
  const [searchedColumn, setSearchedColumn] = useState('');
  const [filteredInfo, setFilteredInfo] = useState({});
  const [searchText, setSearchText] = useState('');
  const classButtonDownload = isMobile()
    ? 'btn-download-mobile'
    : 'btn-download';

  useEffect(() => {
    setIsLoadingTable(true);

    const config = {
      method: METHOD_GET,
      url: `${API_URL_OPERATION}/CompareSupplierOperations?DateToCompare=${date}`,
      data: null,
      token,
      showNotificationError: true,
    };
    fetchApi(config)
      .then((response) => {
        if (response.statusCode === 200 && response.result) {
          const allQuotes = response.result;
          allQuotes.map((quote) => (quote['key'] = quote.id));
            setQuotes(allQuotes);
        }
        setIsLoadingTable(false);
        setReloadTable(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoadingTable(false);
        setReloadTable(false);
      });
  }, [reloadTable, token,date]);
  useEffect(() => {
    const datosObtenidos = getFilteredDataFromLocalStorage('promotionSupplier');
    if (datosObtenidos !== null) {
      setFilteredInfo(datosObtenidos.filters);
      setStartDate(datosObtenidos.dateFilters.startDates)
      setEndDate(datosObtenidos.dateFilters.endDates)
    }
  },[])
  
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            // this.searchInput = node;
          }}
          placeholder={`Buscar`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => {
              handleReset(clearFilters);
              handleSearch(selectedKeys, confirm, dataIndex);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Limpiar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    filteredValue: filteredInfo[dataIndex] || null,
    onFilterDropdownVisibleChange: (visible) => {},
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  const showModal = (type, data) => {
    if (type === 'add') {
      setModalIsVisible(true);
      setTitleModal('Agregar comentario');
      setContextModal(
        <AddEditComment
          setModalIsVisible={setModalIsVisible}
          type={type}
          data={data}
          setReloadTable={setReloadTable}
          date={date}
        ></AddEditComment>
      );
    } else if (type === 'edit') {
      setModalIsVisible(true);
      setTitleModal( `Editar comentario` );
      setContextModal(
        <AddEditComment
          setModalIsVisible={setModalIsVisible}
          type={type}
          data={data}
          setReloadTable={setReloadTable}
          date={date}
        ></AddEditComment>
      );
    } else if (type === 'watch') {
      setModalIsVisible(true);
      setTitleModal(`Comentario`);
      setContextModal(
        <AddEditComment
          setModalIsVisible={setModalIsVisible}
          type="watch"
          data={data}
        ></AddEditComment>
      );
    }
  };
  const columns = [
    {
      title: 'Proveedor',
      dataIndex: 'supplierId',
      key: 'supplierId',
      width: 130,
      align: 'center',
      ...getColumnSearchProps('supplierId'),
    },
    {
    title: 'Mes anterior',
      children: [
    {
      title: 'Rango de fecha inicial',
      dataIndex: 'firstRangeStartDate',
      key: 'firstRangeStartDate',
      width: 100,
      align: 'center',
      render: (firstRangeStartDate) => <>{moment.unix(firstRangeStartDate).tz('GMT').format('DD/MM/YYYY')}</>,
    },
    {
      title: 'Rango de fecha final',
      dataIndex: 'firstRangeEndDate',
      key: 'firstRangeEndDate',
      width: 100,
      align: 'center',
      render: (firstRangeStartDate) => <>{moment.unix(firstRangeStartDate).tz('GMT').format('DD/MM/YYYY')}</>,
    },
    {
      title: 'Movimientos realizados',
      dataIndex: 'firstRangeTotalOperations',
      key: 'firstRangeTotalOperations',
      width: 80,
      align: 'center',
      ...getColumnSearchProps('firstRangeTotalOperations'),

    },
  ]
  },
  {
    title: 'Mes seleccionado',
      children: [
    {
      title: 'Rango de fecha inicial',
      dataIndex: 'secondRangeStartDate',
      key: 'secondRangeStartDate',
      width: 100,
      align: 'center',
      render: (firstRangeStartDate) => <>{moment.unix(firstRangeStartDate).tz('GMT').format('DD/MM/YYYY')}</>,
    },
    {
      title: 'Rango de fecha final',
      dataIndex: 'secondRangeEndDate',
      key: 'secondRangeEndDate',
      width: 100,
      align: 'center',
      render: (firstRangeStartDate) => <>{moment.unix(firstRangeStartDate).tz('GMT').format('DD/MM/YYYY')}</>,
    },
    {
      title: 'Movimientos realizados',
      dataIndex: 'secondRangeTotalOperations',
      key: 'secondRangeTotalOperations',
      width: 80,
      align: 'center',
      ...getColumnSearchProps('secondRangeTotalOperations'),

    },
  ]
  },
    {
      title: 'Estatus',
      dataIndex: 'status',
      key: 'status',
      width: 100,
      align: 'center',
      filters: [
        { text: 'Descenso', value: 'Descenso' },
        { text: 'Ascenso', value: 'Ascenso' },
      ],
      filteredValue: filteredInfo.status || null,
      onFilter: (value, record) => record.status === value,
      render: (status) => (
        <Tag
          color={
            status === 'Ascenso'
              ? 'green'
              : status === 'Descenso'
              ? 'red'
              : 'yellow'
          }
        >
          {status}
        </Tag>
      ),
    },
    {
      title: 'Acciones',
      key: 'commentId',
      fixed: 'right',
      width: 70,
      align: 'center',
      render: (record) => (
        <>
            {[ROL_VENTAS, ROL_OPERACIONES,ROL_PRICING].some((r) => person.roles.includes(r)) && (
            <>
              <Tooltip title="Comentarios">
                <Button
                  // disabled={record.commentId === 0}
                  style={{ marginLeft: 2 }}
                  type="primary"
                  shape="default"
                  icon={<CommentOutlined />}
                  onClick={() => showModal( record.commentId === 0 ? 'add' :  'edit', record)}
                />
              </Tooltip>
            </>
            )}
            {[ROL_LAYOUT, ROL_ADMIN].some((r) => person.roles.includes(r)) && (
            <>
              <Tooltip title="Ver Comentario">
                <Button
                  disabled={record.commentId === 0}
                  style={{ marginLeft: 2 }}
                  type="primary"
                  shape="default"
                  icon={<EyeOutlined />}
                  onClick={() => showModal('watch', record)}
                />
              </Tooltip>
            </>
            )}
        </>
      ),
    },
  ];
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters, selectedKeys, confirm, dataIndex) => {
    clearFilters();
    setSearchText('');
    startDate[dataIndex] = '';
    endDate[dataIndex] = '';
  };
  const handleChange = (pagination, filters, sorter, extra) => {
    const dateFilters = { startDates: startDate, endDates: endDate}
    saveFilteredDataToLocalStorage('promotionSupplier', pagination, sorter, filters, dateFilters);
    setFilteredInfo(filters);
  };
  const exportPdf = (quotes, startDate, endDate) => {
    let mstrTitulo = '';
    let mstrPdf = '';
    mstrTitulo = `Proveedores Ascenso y Descenso`;
    mstrPdf = `Proveedores-Ascenso-Descenso-${moment().format('L')}.pdf`;

    let registros = [];
    quotes.forEach((element) => {
      registros.push([
        element.supplierId,
        moment.unix(element.firstRangeStartDate).tz('GMT').format('DD/MM/YYYY'),
        moment.unix(element.firstRangeEndDate).tz('GMT').format('DD/MM/YYYY'),
        element.firstRangeTotalOperations,
        moment.unix(element.secondRangeStartDate).tz('GMT').format('DD/MM/YYYY'),
        moment.unix(element.secondRangeEndDate).tz('GMT').format('DD/MM/YYYY'),
        element.secondRangeTotalOperations,
        element.status,
      ]);
    });

    let fecha = moment().format('LLL');
    let doc = new jsPDF('landscape');
    doc.setFontSize(12);

    let xFecha = 220;
    let yFecha = 10;

    let totalPagesExp = '{total_pages_count_string}';

    let pageContent = function (data) {
      //header
      doc.text(15, 10, mstrTitulo);
      doc.text(-10, -10, mstrTitulo);
      doc.text(fecha, xFecha, yFecha);
      // FOOTER
      let str = 'Página ' + data.pageCount;
      //Total page number plugin only available in jspdf v1.0+
      if (typeof doc.putTotalPages === 'function') {
        str = str + ' de ' + totalPagesExp;
      }
      doc.setFontSize(10);
      doc.text(
        str,
        data.settings.margin.left,
        doc.internal.pageSize.height - 10
      );
    };
    doc.autoTable({
      A3: { v: 'Proveedor', t: 's' },
      C3: { v: 'Rango de fecha inicial', t: 's' },
      B3: { v: 'Rango de fecha final', t: 's' },
      D3: { v: 'Movimientos realizados', t: 's' },
      E3: { v: 'Rango de fecha inicial', t: 's' },
      F3: { v: 'Rango de fecha final', t: 's' },
      G3: { v: 'Movimientos realizados', t: 's' },
      H3: { v: 'Estatus', t: 's' },
      head: [
        [
          'Proveedor',
          'Rango de fecha inicial',
          'Rango de fecha final',
          'Movimientos realizados',
          'Rango de fecha inicial',
          'Rango de fecha final',
          'Movimientos realizados',
          'Estatus',
        ],
      ],
      didDrawPage: pageContent,
      theme: 'grid',
      headStyles: {
        valign: 'middle',
        halign: 'center',
        fillColor: [230, 81, 0],
        textColor: [255, 255, 255],
        // cellWidth: { 3: 200 },
      },
      body: registros,
      columnStyles: {
        text: {
          cellWidth: 'wrap',
        },
      },
    });
    // call footer() after each doc.addPage()
    // and before doc.save() do not forget put
    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(totalPagesExp);
    }
    //spinner.style.display = 'none';
    doc.save(mstrPdf);
  };
  const generateExcel = (entries) => {
    // setExcelLoading(true);
    let filename = '';
    let mstrTitulo = '';
    filename = `Proveedores-Ascenso-Descenso-${moment().format('L')}.xlsx`;
    mstrTitulo = `Proveedores Ascenso y Descenso`;

    let fecha = moment().format('LLL');
    const ws_name = 'Hoja1';
    let wb = {
      SheetNames: [],
      Sheets: {},
    };
    let ws = {};
    let renInicial = 4;

    // var mstrTitulo = `Facturación y notas de crédito del ${dateRange.startDate} al: ${dateRange.endDate}`;

    ws = {
      A1: { v: mstrTitulo, t: 's' },
      I1: { v: fecha, t: 's' },

      A3: { v: 'Proveedor', t: 's' },
      B3: { v: 'Rango de fecha inicial', t: 's' },
      C3: { v: 'Rango de fecha final', t: 's' },
      D3: { v: 'Movimientos realizados', t: 's' },
      E3: { v: 'Rango de fecha inicial', t: 's' },
      F3: { v: 'Rango de fecha final', t: 's' },
      G3: { v: 'Movimientos realizados', t: 's' },
      H3: { v: 'Estatus', t: 's' },
    };
    for (let i in entries) {

      const {
        supplierId,
        firstRangeStartDate,
        firstRangeEndDate,
        firstRangeTotalOperations,
        secondRangeStartDate,
        secondRangeEndDate,
        secondRangeTotalOperations,
        status,
      } = entries[i];
      ws['A' + renInicial] = { v: supplierId, t: 's' };
      ws['B' + renInicial] = { v: moment.unix(firstRangeStartDate).tz('GMT').format('DD/MM/YYYY'), t: 's' };
      ws['C' + renInicial] = { v: moment.unix(firstRangeEndDate).tz('GMT').format('DD/MM/YYYY'), t: 's' };
      ws['D' + renInicial] = { v: firstRangeTotalOperations, t: 's' };
      ws['E' + renInicial] = { v: moment.unix(secondRangeStartDate).tz('GMT').format('DD/MM/YYYY'),t: 's',};
      ws['F' + renInicial] = { v: moment.unix(secondRangeEndDate).tz('GMT').format('DD/MM/YYYY'),t: 's',};
      ws['G' + renInicial] = { v: secondRangeTotalOperations, t: 's' };
      ws['H' + renInicial] = { v: status, t: 's' };
     

      renInicial++;
    }
    // var wscols = [{ wch: 30 }, { wch: 40 }, { wch: 35 }, { wch: 35 }];
    // ws['!cols'] = wscols;
    ws['!ref'] = XLSX.utils.encode_range({
      s: { c: 0, r: 0 },
      e: { c: 15, r: renInicial },
    });
    wb.SheetNames.push(ws_name);
    wb.Sheets[ws_name] = ws;
    XLSX.writeFile(wb, filename, { cellStyles: true });
    // setExcelLoading(false);
  };
  return (
    <div>
          <Button
            className={classButtonDownload}
            icon={<ReloadOutlined />}
            onClick={() => setReloadTable(true)}
          >
            Refrescar
          </Button>
       
      <Table
        dataSource={quotes}
        columns={columns}
        bordered
        size="middle"
        scroll={{ x: 1200 }}
        // setSelectedRowKeys
        className="table-striped-rows"
        // rowSelection={rowSelection}
        loading={isLoadingTable || reloadTable}
        onChange={handleChange}
      />
      <Row>
        <Col span={24} style={{ textAlign: 'right' }}>
          <Button
            icon={<FilePdfFilled style={{ fontSize: 18 }} />}
            className="btn-download"
            onClick={() => exportPdf(quotes)}
            disabled={quotes.length === 0}
            style={{ width: 150 }}
          >
            Descargar Pdf
          </Button>
          <Button
            icon={<FileExcelFilled style={{ fontSize: 18 }} />}
            className="btn-download"
            onClick={() => generateExcel(quotes)}
            disabled={quotes.length === 0}
            style={{ width: 160 }}
          >
            Descargar Excel
          </Button>
        </Col>
      </Row>
      <Modal
        title={titleModal}
        visible={modalIsVisible}
        setIsVisible={setModalIsVisible}
        width={1000}
      >
        {contextModal}
      </Modal>
    </div>
  );
};

export default SupplierPromotionByDate;
