import { basePath } from './config';

export function registerUserApi(data, token) {
    const url = `${basePath}/Account/Register`;
    const params = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {});
}
export function getUsersApi(token, filters) {
    const url = `${basePath}/users${filters ? filters: ''}`;

    const params = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {});
}
export function getUserApi(token, idUser) {
    const url = `${basePath}/users/${idUser}`;

    const params = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {});
}
export function deleteUserApi(token, id) {
    const url = `${basePath}/Administration/DeleteUser?id=${id}`;
    const params = {
        method: 'DELETE',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {});
}
export function editUserApi(token, data) {
    const url = `${basePath}/Administration/EditUser`;
    const params = {
        method: 'POST',
        body: JSON.stringify(data),

        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {});
}
