import { Tabs, Form, Input, Col, Row, Button, Table } from 'antd';
import React from 'react';
import { UserOutlined, DiffOutlined } from '@ant-design/icons';

const ClientForm = () => {
    const { TabPane } = Tabs;
    const [form1, form2] = Form.useForm();

    const dataSource = [
        {
            key: '1',
            name: 'No.',
            age: 'Ganada/Perdida',
            address: 'Razón',
        },
        {
            key: '2',
            name: 'No.',
            age: 'Ganada/Perdida',
            address: 'Razón',
        },
    ];

    const columns = [
        {
            title: 'No.',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Ganada/Perdida',
            dataIndex: 'age',
            key: 'age',
        },
        {
            title: 'Razón',
            dataIndex: 'address',
            key: 'address',
        },
    ];

    return (
        <div>
            <Tabs defaultActiveKey="1">
                <TabPane
                    tab={
                        <span>
                            <UserOutlined />
                            Info
                        </span>
                    }
                    key="1"
                >
                    <Form name="add-promo" layout="vertical" form={form1}>
                        <div>
                            <Row xs={24} className="table-company">
                                <Col xs={20}>
                                    <Form.Item
                                        label="Nombre:"
                                        name="name"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Por favor ingrese un nombre',
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Ingresa tu información" />
                                    </Form.Item>
                                    <Form.Item
                                        label="Id:"
                                        name="supplierId"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Por favor ingrese un Id',
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Ingresa tu información" />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                    </Form>
                </TabPane>
                <TabPane
                    tab={
                        <span>
                            <DiffOutlined />
                            Cotización
                        </span>
                    }
                    key="2"
                >
                    <Form name="add-promo" layout="vertical" form={form2}>
                        <div>
                            <Row xs={24} className="table-company">
                                <Col xs={20}>
                                    <Table
                                        dataSource={dataSource}
                                        columns={columns}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </Form>
                </TabPane>
            </Tabs>
            <Form.Item>
                <Button htmlType="submit" className="btn-enviar" block>
                    Guardar
                </Button>
            </Form.Item>
        </div>
    );
};

export default ClientForm;
