import React from 'react';
import { Col, Layout, Row, Typography, Button } from 'antd';
import GreetingsTop from '../../../components/Admin/GreetingsTop/GreetingsTop';
import { useHistory } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import AddClarification from '../../../components/Admin/Clarifications/AddClarification/AddClarification';
import BackButton from '../../../components/General/BackButton/BackButton';

export default function AddClarificationPage() {
  return (
    <Layout>
      <GreetingsTop />

      <HeaderTop />

      <div className="billing__container">
        <AddClarification />
      </div>
    </Layout>
  );
}

function HeaderTop() {
  let history = useHistory();
  const { Title } = Typography;
  return (
    <div className="billing__container">
      <Row className="header-top">
        <Col xs={23} className="header-top__left">
          <Row>
            <Col xs={20}>
              <Title
                level={3}
                style={{
                  position: 'absolute',
                  top: 0,
                  bottom: 0,
                  height: '78%',
                  margin: 'auto',
                }}
              >
                Aclaraciones
              </Title>
            </Col>

            <Col xs={2}>
              <Row
                style={{
                  marginBottom: 30,
                  justifyContent: 'right',
                }}
              >
                <BackButton
                  routeName={'/cxp/facturacion/proveedores'}
                ></BackButton>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
