import React, { useEffect, useState } from 'react';
import {
  Table,
  Row,
  Tag,
  Button,
  notification,
  Modal as ModalAntd,
} from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import Modal from '../../../General/Modal';
import AddAlertForm from '../../../Admin/Administration/Alerts/AddAlertForm';
import { deleteAlertApi, getAlertsApi } from '../../../../api/alerts';
import { getAccessTokenApi, logout } from '../../../../api/auth';
import { messageError } from '../../../../utils/feedbackMessages';
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import EditAlertForm from './EditAlertForm';
export default function Alerts() {
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const token = getAccessTokenApi();
  const [alerts, setAlerts] = useState([]);
  const [reloadAlerts, setReloadAlerts] = useState(true);
  const [titleModal, setTitleModal] = useState(false);
  const [contextModal, setContextModal] = useState(null);
  const { confirm } = ModalAntd;

  useEffect(() => {
    if (reloadAlerts) {
      getAlertsApi(token).then((response) => {
        if (response === undefined) {
          const msg = messageError();

          notification['error']({
            message: msg,
          });
          return;
        }
        if (response?.statusCode === 401) {
          notification['error']({
            message: 'Usuario no autorizado.',
          });
          setTimeout(() => {
            logout();
            window.location.reload();
          }, 1000);
          return;
        } else if (response?.statusCode === 200) {
          response.result.forEach((element, index) => {
            element['key'] = index;
          });
          setAlerts(response.result);
          setReloadAlerts(false);
        }
      });
    }
  }, [reloadAlerts, token]);

  const columns = [
    {
      title: 'PIN',
      dataIndex: 'pinColor',
      key: 'pinColor',
      render: (pinColor) => (
        <>
          {pinColor === 'verde' && <div id="circulo"></div>}
          {pinColor === 'rojo' && <div id="circulo-false"></div>}
          {pinColor === 'amarillo' && <div id="circulo-yellow"></div>}
        </>
      ),
    },
    {
      title: 'Alerta',
      dataIndex: 'alertType',
      key: 'alertType',
      render: (alertType) => (
        <p>
          {alertType === 0
            ? 'Operación cambio de status'
            : 'Vencimiento de facturas'}
        </p>
      ),
    },
    {
      title: 'Perfil',
      dataIndex: 'userProfile',
      key: 'userProfile',
      render: (userProfile) => {
        let color = '';
        switch (userProfile) {
          case 'Customer':
            color = 'blue';
            break;
          case 'Supplier':
            color = 'purple';
            break;
          case 'Admin':
            color = 'red';
            break;

          case 'Operaciones':
            color = 'green';
            break;
          case 'Manager':
            color = 'orange';
            break;
          case 'Layout':
            color = 'gold';
            break;
          case 'Financial':
            color = 'yellow';
            break;

          default:
            break;
        }
        let name = '';

        switch (userProfile) {
          case 'Customer':
            name = 'Cliente';
            break;
          case 'Supplier':
            name = 'Proveedor';
            break;
          case 'Financial':
            name = 'Financiero';
            break;
          case 'Manager':
            name = 'Gerente';
            break;
          default:
            break;
        }

        return (
          <Tag color={color} key={userProfile}>
            {name}
          </Tag>
        );
      },
    },
    {
      title: 'Activa',
      dataIndex: 'active',
      key: 'active',
      render: (active) => (
        <Checkbox
          checked={active}
          // type="primary"
          className="button-detail"
        ></Checkbox>
      ),
    },
    {
      title: 'Alerta',
      dataIndex: 'id',
      key: '3',
      render: (id) => (
        <Row gutter={[10, 10]}>
          <Button
            icon={<EditOutlined />}
            type="primary"
            size="medium"
            onClick={() => showModal('Edit', id)}
            className="btn-download-doc"
          />
          <Button
            icon={<DeleteOutlined />}
            danger
            onClick={() => handlerDeleteAlert(id)}
            type="primary"
            size="medium"
            className="login-form-regresar"
            style={{ marginLeft: 20 }}
          />
        </Row>
      ),
    },
  ];
  const handlerDeleteAlert = (id) => {
    confirm({
      title: 'Eliminar alerta',
      icon: <ExclamationCircleOutlined />,
      content: `¿Estás seguro que desea eliminar la alerta ${id}?, no se podrá recuperar después`,
      okText: 'Si',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteAlert(id);
      },
      onCancel() {},
    });
  };
  const deleteAlert = async (id) => {
    const result = await deleteAlertApi(token, id);

    if (result === undefined) {
      notification['error']({
        message: messageError(),
      });
    } else {
      if (result.statusCode === 401) {
        notification['error']({
          message: 'Usuario no autorizado.',
        });

        setTimeout(() => {
          logout();
          window.location.reload();
        }, 1200);

        return false;
      }

      if (result.statusCode === 200) {
        notification['success']({
          message: 'Alerta eliminada exitosamente',
        });
        setReloadAlerts(true);
      } else {
        let msg = 'Lo sentimos, ocurrió un error vuelva a intertarlo.';

        if (
          result.statusCode === 400 ||
          result.statusCode === 409 ||
          result.statusCode === 404
        ) {
          msg = result.description;
        }
        notification['error']({
          message: msg,
        });
      }
    }
  };

  const showModal = (type, id) => {
    if (type === 'addAlert') {
      setModalIsVisible(true);
      setTitleModal('Agregar alerta');
      setContextModal(
        <AddAlertForm
          setModalIsVisible={setModalIsVisible}
          setReloadAlerts={setReloadAlerts}
        ></AddAlertForm>
      );
    } else if (type === 'Edit') {
      setModalIsVisible(true);

      setTitleModal('Editar alerta');
      setContextModal(
        <EditAlertForm
          setModalIsVisible={setModalIsVisible}
          setReloadAlerts={setReloadAlerts}
          idAlert={id}
        ></EditAlertForm>
      );
    }
  };

  return (
    <>
      <Row>
        <Button className="btn-download" onClick={() => showModal('addAlert')}>
          Agregar alerta
        </Button>
        {/* <Button
                    className="btn-download"
                    onClick={() => showModal('addAlertType')}
                >
                    Agregar tipos de alertas
                </Button> */}
      </Row>
      <Table
        columns={columns}
        dataSource={alerts}
        scroll={{ x: 800 }}
        pagination={{ defaultPageSize: 5 }}
        loading={reloadAlerts}
      />
      <Modal
        title={titleModal}
        visible={modalIsVisible}
        setIsVisible={setModalIsVisible}
        width={1000}
      >
        {contextModal}
      </Modal>
    </>
  );
}
