import React from 'react';
import { Col, Layout, Row, Typography } from 'antd';
import GreetingsTop from '../../../components/Admin/GreetingsTop/GreetingsTop';
import CardsBiling from '../../../components/Logistic/CardsBilling/CardsBilling';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

export default function Administration() {
  const { userType } = useParams();
  return (
    <Layout>
      <GreetingsTop />

      <HeaderTop userType={userType} />

      <div className="catalog__container">
        <CardsBiling userType={userType} />
      </div>
    </Layout>
  );
}

function HeaderTop({ userType }) {
  const { Title } = Typography;

  return (
    <div className="catalog__container">
      <Row className="header-top">
        <Col md={12} xs={24} className="header-top__left">
          <Title level={3}>{userType === 'cliente' ? 'CxC' : 'CxP'}</Title>
        </Col>
      </Row>
    </div>
  );
}
