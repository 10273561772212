import React, { useEffect, useState } from 'react';
import { Row, Col, Spin, Form, Button, Select } from 'antd';
import { getAccessTokenApi } from '../../../../../api/auth';
import { METHOD_GET, METHOD_POST } from '../../../../../utils/constants';
import { API_URL_CATALOG_VALUES } from '../../../../../helpers/urls';
import { fetchApi } from '../../../../../helpers/fetch';
import { CITY_CATALOG_ID } from '../../../../../helpers/constants';

const AddCustomerRoute = ({ setModalIsVisible, setReloadRoutes, clientId }) => {
  const [loadingForm, setLoadingForm] = useState(false);
  const [form] = Form.useForm();
  const token = getAccessTokenApi();
  const [cities, setCities] = useState([]);

  useEffect(() => {
    setLoadingForm(true);
    const config = {
      method: METHOD_GET,
      url: `${API_URL_CATALOG_VALUES}/GetAll/${CITY_CATALOG_ID}`,
      data: null,
      token,
      // showNotification: true
    };
    fetchApi(config)
      .then((response) => {
        if (response) {
          let calogsValues = response.result;

          if (calogsValues) {
            calogsValues.map((cat, index) => {
              cat['key'] = index;
              cat['label'] = cat.description;
              cat['value'] = cat.id;
            });
          }
          setCities(calogsValues);
        }
        setLoadingForm(false);
      })
      .catch((err) => {
        console.log(err);
      });
    //   getStateCatalogHandle();
  }, []);

  const onFinish = async (values) => {
    setLoadingForm(true);
    values['customerId'] = clientId;

    const config = {
      method: METHOD_POST,
      url: `Route/addByCustomer`,
      data: values,
      token,
      showNotification: true,
    };
    const result = await fetchApi(config);
    if (result) {
      if (result.statusCode === 200) {
        // form.resetFields();
        setModalIsVisible(false);
        setReloadRoutes(true);
      }
    }

    setLoadingForm(false);
  };

  return (
    <Spin spinning={loadingForm} tip="Cargando...">
      <Form
        name="add-promo"
        layout="vertical"
        form={form}
        onFinish={onFinish}
        initialValues={{ active: true }}
      >
        <div>
          <Row xs={24} className="table-company">
            <Col xs={20}>
              <Form.Item
                label="Ciudad origen:"
                name="originCityId"
                rules={[
                  {
                    required: true,
                    message: 'Por favor seleccione ciudad origen',
                  },
                ]}
              >
                <Select
                  // disabled={disableAllInputs}
                  placeholder="Selecciona una opción"
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  // style={{minWidth: width > 480 ? 220 : '100%', width: width > 480 ? 220 : '100%'}}
                >
                  {cities.map((s) => (
                    <Select.Option key={s.value} value={s.value}>
                      {s.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Ciudad destino:"
                name="destinationCityId"
                rules={[
                  {
                    required: true,
                    message: 'Por favor seleccione ciudad destino',
                  },
                ]}
              >
                <Select
                  // disabled={disableAllInputs}
                  placeholder="Selecciona una opción"
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  // style={{minWidth: width > 480 ? 220 : '100%', width: width > 480 ? 220 : '100%'}}
                >
                  {cities.map((s) => (
                    <Select.Option key={s.value} value={s.value}>
                      {s.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Row justify="end">
                <Form.Item>
                  <Button htmlType="submit" className="btn-enviar" block>
                    Guardar
                  </Button>
                </Form.Item>
              </Row>
            </Col>
          </Row>
        </div>
      </Form>
    </Spin>
  );
};

export default AddCustomerRoute;
