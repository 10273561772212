import React from 'react';
import { Col, Layout, Row, Typography } from 'antd';
import GreetingsTop from '../../../components/Admin/GreetingsTop/GreetingsTop';
import RentabilityTable from '../../../components/Admin/RentabilityTable/RentabilityTable';

export default function Rentability() {
  return (
    <Layout>
      <GreetingsTop />

      <HeaderTop />

      <div className="billing__container">
        <RentabilityTable />
      </div>
    </Layout>
  );
}

function HeaderTop({ filterDate, dateReceive }) {
  const { Title } = Typography;

  return (
    <div className="billing__container">
      <Row className="header-top">
        <Col xs={24} md={16} className="header-top__left">
          <Title level={3}>Rentabilidad</Title>
        </Col>
      </Row>
    </div>
  );
}
