import { basePath } from './config';

export function getAllSuppliers(token, filter) {
  const url = `${basePath}/supplier${filter}`;

  const params = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      Accept: '*/*',
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {});
}
export function getSuppliersByRoutes(token, data) {
  const url = `${basePath}/Supplier/GetByRoutes`;

  const params = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      Accept: '*/*',
    },
  };

  return fetch(url, params)
    .then((response) => {
      // return response.text();
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {});
}
export function getSupplierApi(token, id) {
  const url = `${basePath}/Supplier/${id}`;

  const params = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      Accept: '*/*',
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {});
}
export function registerSupplierApi(token, data) {
  const url = `${basePath}/Supplier`;
  const params = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {});
}
export function editSupplierApi(token, data, id) {
  const url = `${basePath}/Supplier/${id}`;
  const params = {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {});
}
export function deleteSupplierApi(token, id) {
  const url = `${basePath}/Supplier/${id}`;
  const params = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {});
}
