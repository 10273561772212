import React, { useState, useEffect } from 'react';
import { Col, Layout, Row, Typography, Button, Tabs } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import GreetingsTop from '../../../components/Admin/GreetingsTop/GreetingsTop';
import Modules from '../../../components/Logistic/Modules';
import Modal from '../../../components/General/Modal';
import AddCatalogForm from '../../../components/Admin/Catalog/AddCatalogForm';
import { METHOD_GET, ROL_ADMIN, ROL_LAYOUT } from '../../../utils/constants';
import { API_URL_CATALOG } from '../../../helpers/urls';
import { getAccessTokenApi } from '../../../api/auth';
import { fetchApi } from '../../../helpers/fetch';

import './Catalog.scss';
import useUser from '../../../hooks/useUser';

export default function Catalog() {
  const [catalogs, setCatalogs] = useState([]);
  const [inactiveCatalogs, setInactiveCatalogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [reloadCatalogs, setReloadCatalogs] = useState(false);
  const token = getAccessTokenApi();
  const { TabPane } = Tabs;
  const { person } = useUser();
  const [keyTabActive, setKeyTabActive] = useState('active');

  const customCatalog = [
    {
      id: 'additional_services', // SIEMPRE QUE SEAN STRING
      name: 'Servicio Adicionales',
      status: 1,
    },
  ];

  useEffect(() => {
    setLoading(true);
    const config = {
      method: METHOD_GET,
      url: API_URL_CATALOG,
      data: null,
      token,
    };
    fetchApi(config)
      .then((response) => {
        // console.log(response);
        if (response) {
          if (response.statusCode === 200) {
            const results = response.result;
            const catalogsActive = results.filter((cat) => cat.status === 1);
            const catalogsInactive = results.filter((cat) => cat.status === 0);
            setCatalogs(catalogsActive);
            setInactiveCatalogs(catalogsInactive);
          }
          setReloadCatalogs(false);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [reloadCatalogs]);

  function callback(key) {
    setKeyTabActive(key);
  }
  return (
    <Layout>
      <GreetingsTop />

      <HeaderTop
        setReloadCatalogs={setReloadCatalogs}
        person={person}
        keyTabActive={keyTabActive}
      />

      <div className="catalog__container">
        <Tabs defaultActiveKey="active" onChange={callback}>
          <TabPane tab="Activos" key="active">
            <Modules catalogs={catalogs} loading={loading} />
          </TabPane>
          <TabPane tab="Inactivos" key="inactive">
            <Modules catalogs={inactiveCatalogs} loading={loading} />
          </TabPane>
          <TabPane tab="Personalizados" key="custom">
            <Modules catalogs={customCatalog} loading={loading} />
          </TabPane>
        </Tabs>
      </div>
    </Layout>
  );
}

function HeaderTop({ setReloadCatalogs, person, keyTabActive }) {
  const { Title } = Typography;
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [titleModal, setTitleModal] = useState(false);
  const [contextModal, setContextModal] = useState(null);

  const showModal = (type) => {
    if (type === 'Add') {
      setModalIsVisible(true);
      setTitleModal('Agregar catálogo');
      setContextModal(
        <AddCatalogForm
          setModalIsVisible={setModalIsVisible}
          setReloadCatalogs={setReloadCatalogs}
        />
      );
    }
  };

  return (
    <div className="catalog__container">
      <Row className="header-top">
        <Col md={12} xs={24} className="header-top__left">
          <Title level={3}>Catálogos</Title>
        </Col>
        {[ROL_ADMIN, ROL_LAYOUT].some((r) => person.roles.includes(r)) && (
          <Col
            md={12}
            xs={24}
            style={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            {keyTabActive === 'active' && (
              <Button
                className="btn-download"
                onClick={() => showModal('Add')}
                icon={<PlusCircleOutlined />}
              >
                Agregar
              </Button>
            )}
          </Col>
        )}
      </Row>
      <Modal
        title={titleModal}
        visible={modalIsVisible}
        setIsVisible={setModalIsVisible}
        width={1000}
      >
        {contextModal}
      </Modal>
    </div>
  );
}
