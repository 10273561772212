import React, { useState, createContext } from 'react';

export const StatusFilterContext = createContext(null);

export default function StatusFilterProvider(props) {
    const { children } = props;

    const [statusFilter, setStatusFilter] = useState({
        type: "",
        dateStart: "",
        dateEnd: "",
        dateStartMoment: null,
        dateEndMoment: null
    });

    const reset = () => {
       setStatusFilter({
           type: "",
            dateStart: "",
            dateEnd: "",
            dateStartMoment: null,
            dateEndMoment: null
        });
    };

    return (
        <StatusFilterContext.Provider value={{ statusFilter, setStatusFilter, reset }}>
            {children}
        </StatusFilterContext.Provider>
    );
}
