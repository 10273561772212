import React, { useState, createContext } from 'react';

export const GlobalContext = createContext(null);

export default function GlobalProvider(props) {
    const { children } = props;

    const [statusData, setStatusData] = useState({});

    const reset = () => {
        setStatusData({});
    };

    return (
        <GlobalContext.Provider value={{ statusData, setStatusData, reset }}>
            {children}
        </GlobalContext.Provider>
    );
}
