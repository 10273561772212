import React, { useEffect, useState } from 'react';
import { Table, Tag } from 'antd';
import { getAccessTokenApi } from '../../../../api/auth';
import { getSupplierContactApi } from '../../../../api/sections';

export default function Contact({ idSupplier }) {
  const [reloadContacts, setReloadContacts] = useState(true);
  const [users, setUsers] = useState([]);
  const token = getAccessTokenApi();
  // const sections = [
  //   { name: 'Complementos de pago', key: 0 },
  //   { name: 'Facturación', key: 1 },
  // ];
  useEffect(() => {
    setReloadContacts(true);
    getSupplierContactApi(token, idSupplier)
      .then((response) => {
        // console.log(response, 'response');
        if (response?.statusCode === 200) {
          response.result.forEach((element, index) => {
            element['key'] = index;
          });
          setUsers(response.result);
        } else {
          setUsers([]);
        }
      })
      .finally(() => setReloadContacts(false));
  }, [token, idSupplier]);

  const columns = [
    // {
    //   title: 'Secciones',
    //   dataIndex: 'rolesDescription',
    //   key: 'rolesDescription',
    //   render: () =>
    //     sections.map((s, index) => (
    //       <Tag key={index} color={s.key === 0 ? 'blue' : 'green'}>
    //         {s.name}
    //       </Tag>
    //     )),
    // },
    {
      title: 'Rol',
      dataIndex: 'rolesDescription',
      key: 'rolesDescription',
      render: (rolesDescription, contact) => (
        <>
          {rolesDescription && (
            <Tag
              color={
                contact.roles === '152'
                  ? 'blue'
                  : contact.roles === '153'
                  ? 'orange'
                  : 'green'
              }
            >
              {rolesDescription}
            </Tag>
          )}
        </>
      ),
    },
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Apellido',
      dataIndex: 'lastName',
      key: 'lastName',
    },

    {
      title: 'Correo',
      dataIndex: 'email',
      key: '4',
    },
    {
      title: 'Teléfono',
      dataIndex: 'cellPhone1',
      key: 'cellPhone1',
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={users}
        scroll={{ x: 500 }}
        loading={reloadContacts}
        // pagination={{ defaultPageSize: 5 }}
      />
    </>
  );
}
