import { Button, Tooltip } from 'antd';
import React from 'react';
import { LeftOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';

const BackButton = ({ routeName }) => {
  const history = useHistory();

  return (
    <Tooltip title="Atrás">
      <Button
        style={{ color: 'gray', fontSize: 20, height: 0, width: 0 }}
        type="link"
        onClick={() => history.push(routeName)}
      >
        <LeftOutlined />
      </Button>
    </Tooltip>
  );
};

export default BackButton;
