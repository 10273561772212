import React, { useState, useEffect } from 'react';
import { Row, Form, Select, Col, Input, Divider, DatePicker } from 'antd';
import { METHOD_GET } from '../../../../../../utils/constants';
import { API_URL_CATALOG_VALUES } from '../../../../../../helpers/urls';
import {
  CITY_CATALOG_ID,
  COUNTRY_CATALOG_ID,
  STATE_CATALOG_ID,
  TERRESTRE_VALUE_ID,
  MARITIMO_VALUE_ID,
  AEREO_VALUE_ID,
  CANADA_VALUE_ID,
  PORT_CATALOG_ID,
  AIRPORT_CATALOG_ID,
} from '../../../../../../helpers/constants';
import { fetchApi } from '../../../../../../helpers/fetch';
import { getAccessTokenApi } from '../../../../../../api/auth';
import { getCatalogValueHandle } from '../../../../../../helpers/handlerFunctionApi';

const Route = (props) => {
  const {
    line,
    disableAllInputs,
    service,
    setLoadingForm,
    form,
    type = null,
    isOperation,
    disableInputsSelected,
    isRFQ,
    routes,
    clientSelectedQuote,
    allRoutes,
    setRouteSelected,
    typeEdit,
    setRateSelected,
    disabledRoute,
    delivered,
    statusSelected,
    routeCity,
  } = props;
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [ports, setPorts] = useState([]);
  const [airports, setAirports] = useState([]);
  const [countriesDestination, setCountriesDestination] = useState([]);
  const [statesDestination, setStatesDestination] = useState([]);
  const [citiesDestination, setCitiesDestination] = useState([]);
  const [originPorts, setOriginPorts] = useState([]);
  const [originAirports, setOriginAirports] = useState([]);
  const [destinationPorts, setDestinationPorts] = useState([]);
  const [destinationAirports, setDestinationAirports] = useState([]);
  const [routeFindSelected, setRouteFindSelected] = useState({});
  useState(0);
  const token = getAccessTokenApi();
  const dateFormat = 'DD/MM/YYYY';
  useEffect(() => {
    setLoadingForm(true);
    const config = {
      method: METHOD_GET,
      url: `${API_URL_CATALOG_VALUES}/GetAll/${COUNTRY_CATALOG_ID}`,
      data: null,
      token,
      // showNotification: true
    };
    fetchApi(config)
      .then((response) => {
        if (response) {
          let calogsValues = response.result;

          if (calogsValues) {
            calogsValues.map((cat, index) => {
              cat['key'] = index;
              cat['label'] = cat.description;
              cat['value'] = cat.id;
              return cat;
            });
          }
          setCountries(calogsValues);
          setCountriesDestination(calogsValues);
        }
        setLoadingForm(false);
      })
      .catch((err) => {
        console.log(err);
      });

    //   getStateCatalogHandle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setLoadingForm(true);
    const config = {
      method: METHOD_GET,
      url: `${API_URL_CATALOG_VALUES}/GetAll/${STATE_CATALOG_ID}`,
      data: null,
      token,
      // showNotification: true
    };
    fetchApi(config)
      .then((response) => {
        if (response) {
          let calogsValues = response.result;

          if (calogsValues) {
            calogsValues.map((cat, index) => {
              cat['key'] = index;
              cat['label'] = cat.description;
              cat['value'] = cat.id;
              return cat;
            });
          }
          setStates(calogsValues);
          setStatesDestination(calogsValues);
        }
        setLoadingForm(false);
      })
      .catch((err) => {
        console.log(err);
      });

    //   getStateCatalogHandle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setLoadingForm(true);
    const config = {
      method: METHOD_GET,
      url: `${API_URL_CATALOG_VALUES}/GetAll/${CITY_CATALOG_ID}`,
      data: null,
      token,
      // showNotification: true
    };
    fetchApi(config)
      .then((response) => {
        if (response) {
          let calogsValues = response.result;

          if (calogsValues) {
            calogsValues.map((cat, index) => {
              cat['key'] = index;
              cat['label'] = cat.description;
              cat['value'] = cat.id;
              return cat;
            });
          }
          setCities(calogsValues);
          setCitiesDestination(calogsValues);
        }
        setLoadingForm(false);
      })
      .catch((err) => {
        console.log(err);
      });

    //   getStateCatalogHandle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (!typeEdit) {
      setRouteSelected(null);
      form.setFieldsValue({
        routeId: null,
        originZipCode: null,
        originCountry: null,
        destinationCountry: null,
        originCity: null,
        destinationCity: null,
        originState: null,
        destinationState: null,
        originAddress: null,
        destinationZipCode: null,
        destinationAddress: null,
      });
    }
  }, [clientSelectedQuote]);
  useEffect(() => {
    getCatalogValueHandle(PORT_CATALOG_ID, setPorts, setLoadingForm);
    getCatalogValueHandle(AIRPORT_CATALOG_ID, setAirports, setLoadingForm);
  }, []);
  useEffect(() => {
    let puertosorigen = ports.filter(
      (e) =>
        e.fatherId ===
        (Object.keys(routeFindSelected).length !== 0
          ? routeFindSelected.originCity
          : line
          ? line.originCity
          : routeCity?.originCity)
    );
    let puertosdestino = ports.filter(
      (e) =>
        e.fatherId ===
        (Object.keys(routeFindSelected).length !== 0
          ? routeFindSelected.destinationCity
          : line
          ? line.destinationCity
          : routeCity?.destinationCity)
    );
    let aeropuertosorigen = airports.filter(
      (e) =>
        e.fatherId ===
        (Object.keys(routeFindSelected).length !== 0
          ? routeFindSelected.originCity
          : line
          ? line.originCity
          : routeCity?.originCity)
    );
    let aeropuertosdestino = airports.filter(
      (e) =>
        e.fatherId ===
        (Object.keys(routeFindSelected).length !== 0
          ? routeFindSelected.destinationCity
          : line
          ? line.destinationCity
          : routeCity?.destinationCity)
    );
    setOriginPorts(puertosorigen);
    setDestinationPorts(puertosdestino);
    setOriginAirports(aeropuertosorigen);
    setDestinationAirports(aeropuertosdestino);
  }, [routeFindSelected, line, airports, ports, routeCity]);

  const onChangeRoute = (value) => {
    if (!isOperation) {
      setRateSelected(null);
    }
    let find = allRoutes.find((e) => e.routeId === value);
    setRouteFindSelected(find);
    setRouteSelected(value);
    setRouteSelected({
      routeId: value,
      routeStr: `${find.originCityStr} ${find.originStateStr} - ${find.destinationCityStr} ${find.destinationStateStr}`,
    });
    form.setFieldsValue({ ...find });
    const clearRoute = {
      originZipCode: null,
      originAddress: null,
      destinationZipCode: null,
      destinationAddress: null,
    };
    form.setFieldsValue(clearRoute);
  };

  return (
    <div className="rute-tab">
      <Col xs={24} sm={24} lg={24}>
        <Form.Item
          label="Ruta:"
          name="routeId"
          rules={[
            {
              required: true,
              message: `Por favor ingrese una ruta`,
            },
          ]}
        >
          <Select
            disabled={
              disableAllInputs ||
              disabledRoute ||
              disableInputsSelected ||
              !clientSelectedQuote ||
              typeEdit === 'Edit' ||
              typeEdit === 'show'
            }
            placeholder={`${
              clientSelectedQuote === null
                ? 'Seleccione cliente'
                : 'Selecciona una opción'
            }`}
            showSearch
            onChange={onChangeRoute}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {routes.map((s) => (
              <Select.Option key={s.value} value={s.value}>
                {s.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Divider style={{ marginTop: 0, marginBottom: 0 }}>Origen</Divider>

      <Row gutter={[16, 16]}>
        <Col xs={12} md={6} lg={6}>
          <Form.Item
            label="País:"
            name="originCountry"
            rules={[
              {
                required: true,
                message: 'Por favor ingrese un país',
              },
            ]}
          >
            <Select
              disabled
              placeholder="Selecciona ruta"
              // onChange={onChangeCountryOrigin}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {countries.map((s) => (
                <Select.Option key={s.value} value={s.value}>
                  {s.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={12} md={6} lg={6}>
          <Form.Item
            label="Estado:"
            name="originState"
            rules={[
              {
                required: true,
                message: 'Por favor ingrese un estado',
              },
            ]}
          >
            <Select
              disabled
              placeholder="Selecciona ruta"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {states.map((s) => (
                <Select.Option key={s.value} value={s.value}>
                  {s.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={12} md={6} lg={6}>
          <Form.Item
            label="Ciudad:"
            name="originCity"
            rules={[
              {
                required: true,
                message: 'Por favor ingrese una ciudad',
              },
            ]}
          >
            <Select
              disabled
              placeholder="Selecciona ruta"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {cities.map((s) => (
                <Select.Option key={s.value} value={s.value}>
                  {s.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        {service === TERRESTRE_VALUE_ID && (
          <Col xs={12} md={6} lg={6}>
            <Form.Item
              label="Código postal:"
              name="originZipCode"
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese un código postal',
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (getFieldValue('originCountry') === CANADA_VALUE_ID) {
                      if (
                        value &&
                        value.toString().length >= 3 &&
                        value.toString().length <= 6
                      ) {
                        return Promise.resolve();
                      }
                    } else {
                      if (
                        value === undefined ||
                        value === '' ||
                        (value.toString().length === 5 && !isNaN(value))
                      ) {
                        return Promise.resolve();
                      }
                    }
                    return Promise.reject(
                      'Por favor ingrese un código postal válido'
                    );
                  },
                }),
              ]}
            >
              <Input
                maxLength={5}
                // minLength={5}
                disabled={disableAllInputs || delivered}
              />
            </Form.Item>
          </Col>
        )}

        {service === MARITIMO_VALUE_ID && (
          <Col xs={12} md={6} lg={6}>
            <Form.Item
              label="Puerto:"
              name="originPort"
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese un puerto',
                },
              ]}
            >
              <Select
                placeholder="Selecciona una opción"
                disabled={!isRFQ || type === 'show'}
              >
                {originPorts.map((s) => (
                  <Select.Option key={s.value} value={s.value}>
                    {s.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        )}

        {service === AEREO_VALUE_ID && (
          <Col xs={12} md={6} lg={6}>
            <Form.Item
              label="Aeropuerto:"
              name="originAirport"
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese un aeropuerto',
                },
              ]}
            >
              <Select
                placeholder="Selecciona una opción"
                disabled={!isRFQ || type === 'show'}
              >
                {originAirports.map((s) => (
                  <Select.Option key={s.value} value={s.value}>
                    {s.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        )}

        {service === TERRESTRE_VALUE_ID && !isOperation && (
          <Col xs={24} sm={24} lg={24}>
            <Form.Item
              label="Dirección:"
              name="originAddress"
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese una dirección',
                },
              ]}
            >
              <Input disabled={disableAllInputs || delivered} />
            </Form.Item>
          </Col>
        )}
        {isOperation && service === TERRESTRE_VALUE_ID && (
          <Col xs={24} sm={24} lg={24}>
            <Row gutter={16}>
              <Col xs={24} md={14} lg={18}>
                <Form.Item
                  label="Dirección:"
                  name="originAddress"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor ingrese una dirección',
                    },
                  ]}
                >
                  <Input disabled={disableAllInputs || isRFQ || delivered} />
                </Form.Item>
              </Col>
              <Col xs={24} md={10} lg={6}>
                <Form.Item
                  label="ETD:"
                  name="etd"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor ingresar ETD:',
                    },
                  ]}
                >
                  <DatePicker
                    format={dateFormat}
                    style={{ width: '100%' }}
                    disabled={
                      disableAllInputs || disableInputsSelected || isRFQ
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        )}
      </Row>

      <Divider style={{ marginTop: 0, marginBottom: 0 }}>Destino</Divider>

      <Row gutter={[16, 16]}>
        <Col xs={12} md={6} lg={6}>
          <Form.Item
            label="País:"
            name={!isOperation ? 'destinationCountry' : 'destinationCountry'}
            rules={[
              {
                required: true,
                message: 'Por favor ingrese un país',
              },
            ]}
          >
            <Select
              disabled
              placeholder="Selecciona ruta"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {countriesDestination.map((s) => (
                <Select.Option key={s.value} value={s.value}>
                  {s.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={12} md={6} lg={6}>
          <Form.Item
            label="Estado:"
            name={!isOperation ? 'destinationState' : 'destinationState'}
            rules={[
              {
                required: true,
                message: 'Por favor ingrese un estado',
              },
            ]}
          >
            <Select
              disabled
              placeholder="Selecciona ruta"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {statesDestination.map((s) => (
                <Select.Option key={s.value} value={s.value}>
                  {s.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={12} md={6} lg={6}>
          <Form.Item
            label="Ciudad:"
            name={!isOperation ? 'destinationCity' : 'destinationCity'}
            rules={[
              {
                required: true,
                message: 'Por favor ingrese una ciudad',
              },
            ]}
          >
            <Select
              disabled
              placeholder="Selecciona ruta"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {citiesDestination.map((s) => (
                <Select.Option key={s.value} value={s.value}>
                  {s.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        {service === TERRESTRE_VALUE_ID && (
          <Col xs={12} md={6} lg={6}>
            <Form.Item
              label="Código postal:"
              name={!isOperation ? 'destinationZipCode' : 'destinationZipCode'}
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese un código postal',
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (
                      getFieldValue('destinationCountry') === CANADA_VALUE_ID
                    ) {
                      if (
                        value &&
                        value.toString().length >= 3 &&
                        value.toString().length <= 6
                      ) {
                        return Promise.resolve();
                      }
                    } else {
                      if (
                        value === undefined ||
                        value === '' ||
                        (value.toString().length === 5 && !isNaN(value))
                      ) {
                        return Promise.resolve();
                      }
                    }
                    return Promise.reject(
                      'Por favor ingrese un código postal válido'
                    );
                  },
                }),
              ]}
            >
              <Input
                maxLength={5}
                disabled={disableAllInputs || delivered}
              />
            </Form.Item>
          </Col>
        )}
        {service === MARITIMO_VALUE_ID && (
          <Col xs={12} md={6} lg={6}>
            <Form.Item
              label="Puerto:"
              name={!isOperation ? 'destinationPort' : 'destinationPort'}
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese un puerto',
                },
              ]}
            >
              <Select
                placeholder="Selecciona una opción"
                disabled={!isRFQ || type === 'show'}
              >
                {destinationPorts.map((s) => (
                  <Select.Option key={s.value} value={s.value}>
                    {s.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        )}

        {service === AEREO_VALUE_ID && (
          <Col xs={12} md={6} lg={6}>
            <Form.Item
              label="Aeropuerto:"
              name={!isOperation ? 'destinationAirport' : 'destinationAirport'}
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese un aeropuerto',
                },
              ]}
            >
              <Select
                placeholder="Selecciona una opción"
                disabled={!isRFQ || type === 'show'}
              >
                {destinationAirports.map((s) => (
                  <Select.Option key={s.value} value={s.value}>
                    {s.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        )}

        {service === TERRESTRE_VALUE_ID && !isOperation && (
          <Col xs={24} sm={24} lg={24}>
            <Form.Item
              label="Dirección:"
              name="destinationAddress"
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese una dirección',
                },
              ]}
            >
              <Input disabled={disableAllInputs  || delivered} />
            </Form.Item>
          </Col>
        )}
        {isOperation && service === TERRESTRE_VALUE_ID && (
          <Col xs={24} sm={24} lg={24}>
            <Row gutter={16}>
              <Col xs={24} md={14} lg={18}>
                <Form.Item
                  label="Dirección:"
                  name="destinationAddress"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor ingrese una dirección',
                    },
                  ]}
                >
                  <Input disabled={disableAllInputs || isRFQ || delivered} />
                </Form.Item>
              </Col>
              <Col xs={24} md={10} lg={6}>
                <Form.Item
                  label="ETA:"
                  name="eta"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <DatePicker
                    format={dateFormat}
                    style={{ width: '100%' }}
                    disabled={disableAllInputs || delivered}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        )}
        {isOperation && service !== TERRESTRE_VALUE_ID && (
          <Col xs={24} sm={24} lg={24}>
            <Row gutter={16} justify={'end'}>
              {statusSelected !== 0 &&
                statusSelected !== 7 &&
                statusSelected !== 1 && (
                  <Col xs={24} md={10} lg={6}>
                    <Form.Item
                      label="Fecha de llegada:"
                      name="arrivalDate"
                      rules={[
                        {
                          required: true,
                          message: 'Por favor ingresar Fecha de llegada:',
                        },
                      ]}
                    >
                      <DatePicker
                        format={dateFormat}
                        style={{ width: '100%' }}
                        disabled={disableAllInputs || delivered}
                      />
                    </Form.Item>
                  </Col>
                )}

              <Col xs={24} md={10} lg={6}>
                <Form.Item
                  label="ETD:"
                  name="etd"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor ingresar ETD:',
                    },
                  ]}
                >
                  <DatePicker
                    format={dateFormat}
                    style={{ width: '100%' }}
                    disabled={
                      disableAllInputs || disableInputsSelected || isRFQ
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={10} lg={6}>
                <Form.Item
                  label="ETA:"
                  name="eta"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <DatePicker
                    format={dateFormat}
                    style={{ width: '100%' }}
                    disabled={disableAllInputs || delivered}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default Route;
