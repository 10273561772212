import {
  Button,
  Col,
  Row,
  Form,
  Modal as ModalAntd,
  Select,
  notification,
  Input,
  InputNumber,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { METHOD_GET } from '../../../../../../utils/constants';
import { API_URL_CATALOG_VALUES } from '../../../../../../helpers/urls';
import {
  SERVICES_CATALOG_ID,
  TYPE_OF_SERVICE_CATALOG_ID,
} from '../../../../../../helpers/constants';
import { getAccessTokenApi } from '../../../../../../api/auth';
import { fetchApi } from '../../../../../../helpers/fetch';
import { useReducer } from 'react';
import { catalogQouteReducer } from '../../../../../../reducers/catalogsQoute/catalogQouteReducer';
import { getCatalogValueReducerHandle } from '../../../../../../helpers/handlerFunctionApi';
import { types } from '../../../../../../types/types';
import { formatMoney } from '../../../../../../utils/general';

const initialStateQT = {
  typeOfService: [],
};

const AddAdditionalService = ({
  setModalIsVisible,
  asSelected,
  quoteId,
  additionalServices,
  setAdditionalServices,
  lineId,
  lines,
  type,
}) => {
  const [servicesForm] = Form.useForm();
  const { confirm } = ModalAntd;
  const [loadingForm, setLoadingForm] = useState(false);
  const token = getAccessTokenApi();
  const [serviceCatalag, setServiceCatalag] = useState([]);
  const [catalogs, dispatch] = useReducer(catalogQouteReducer, initialStateQT);
  const [additionalServiceName, setAdditionalServiceName] = useState(
    type !== 'edit' ? asSelected?.name : asSelected?.additionalServiceName
  );
  const [cost, setCost] = useState(type !== 'edit' ? asSelected?.cost : '');
  const [supplierName, setSupplierName] = useState(
    asSelected?.supplierName ?? ''
  );
  const [serviceLabel, setServiceLabel] = useState(
    type === 'edit' ? asSelected.serviceStr : ''
  );
  const [typeOfServiceLabel, setTypeOfServiceLabel] = useState(
    type === 'edit' ? asSelected.typeOfServiceStr : ''
  );

  useEffect(() => {
    if (type === 'edit') {
      const additionalServiceSelected = {
        service: asSelected.service,
        cost: `$${formatMoney(asSelected.cost)}`,
        typeOfService: asSelected.typeOfService,
        line: asSelected.operationLineNumber
          ? asSelected.operationLineNumber
          : null,
        profit: asSelected.profit,
      };
      servicesForm.setFieldsValue(additionalServiceSelected);
    }
  }, [asSelected, servicesForm, type, lines]);

  useEffect(() => {
    setLoadingForm(true);
    const config = {
      method: METHOD_GET,
      url: `${API_URL_CATALOG_VALUES}/GetAll/${SERVICES_CATALOG_ID}`,
      data: null,
      token,
      // showNotification: true
    };
    fetchApi(config)
      .then((response) => {
        if (response) {
          let calogsValues = response.result;

          if (calogsValues) {
            calogsValues.map((cat, index) => {
              cat['key'] = index;
              cat['label'] = cat.description;
              cat['value'] = cat.id;
              return cat;
            });
          }
          setServiceCatalag(calogsValues);
        }
        setLoadingForm(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [token]);
  useEffect(() => {
    getCatalogValueReducerHandle(
      TYPE_OF_SERVICE_CATALOG_ID,
      dispatch,
      setLoadingForm,
      0,
      types.getTypeOfService
    );
  }, []);
  const handleSaveService = (values) => {
    confirm({
      title: 'Guardar servicio adicional',
      icon: <ExclamationCircleOutlined />,
      content: '¿Estás seguro que deseas guardar servicio adicional?',
      okText: 'Si',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        addServiceFunction(values);
      },
      onCancel() {},
    });
  };
  const addServiceFunction = async (values) => {
    if (quoteId) {
      values['quoteId'] = quoteId;
    }
    if (lineId) {
      values['operationLineNumber'] = lineId;
    }

    values['supplierId'] = asSelected?.supplierId;
    values['supplierName'] = supplierName;
    values['additionalServiceId'] = asSelected?.additionalServiceId;
    values['additionalServiceName'] = additionalServiceName;
    values['cost'] = asSelected?.cost;
    values['typeOfServiceStr'] = typeOfServiceLabel;
    values['serviceStr'] = serviceLabel;

    if (type === 'edit') {
      values['operationLineNumber'] = values.line;
      values['id'] = asSelected.id;

      let newAdditionalServiceList = additionalServices;
      const index = additionalServices.findIndex(
        (item, index) => index === asSelected.key
      );

      newAdditionalServiceList[index] = values;
      setAdditionalServices(newAdditionalServiceList);
      notification.success({
        message: 'Servicio adicional editado exitosamente',
      });
    } else {
      let copy = [...additionalServices, values];
      setAdditionalServices(copy);
      notification.success({
        message: 'Servicio adicional agregado exitosamente',
      });
    }

    setModalIsVisible(false);
  };
  return (
    <div>
      <Form
        name="add-service"
        layout="vertical"
        form={servicesForm}
        onFinish={handleSaveService}
      >
        <Row gutter={16}>
          {type === 'edit' && (
            <>
              <Col md={6} xs={24}>
                <Form.Item
                  label="Línea:"
                  name="line"
                  rules={[
                    {
                      required: false,
                      message: 'Por favor ingrese línea',
                    },
                  ]}
                >
                  <Select
                    allowClear
                    placeholder="Selecciona una opción"
                    // onChange={(value, label) => {
                    //   setServiceLabel(label.children);
                    // }}
                  >
                    {lines?.map((l) => (
                      <Select.Option key={l.number} value={l.number}>
                        {l.number}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col md={6} xs={24}>
                <Form.Item label="Servicio adicional:" name="additionalService">
                  <Select
                    value={additionalServiceName}
                    disabled
                    initialvalues={additionalServiceName}
                    defaultValue={additionalServiceName}
                  />
                </Form.Item>
              </Col>
            </>
          )}

          <Col md={6} xs={24}>
            <Form.Item
              label="Servicio:"
              name="service"
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese servicio',
                },
              ]}
            >
              <Select
                // disabled={disableAllInputs}
                placeholder="Selecciona una opción"
                onChange={(value, label) => {
                  setServiceLabel(label.children);
                }}
              >
                {serviceCatalag.map((s) => (
                  <Select.Option key={s.value} value={s.value}>
                    {s.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col md={6} xs={24}>
            <Form.Item
              label="Tipo de servicio:"
              name="typeOfService"
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese tipo de servicio',
                },
              ]}
            >
              <Select
                // disabled={disableAllInputs}
                placeholder="Selecciona una opción"
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                onChange={(value, label) => {
                  setTypeOfServiceLabel(label.children);
                }}
              >
                {catalogs.typeOfService.map((s) => (
                  <Select.Option key={s.value} value={s.value}>
                    {s.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          {type !== 'edit' && (
            <>
              <Col md={6} xs={24}>
                <Form.Item label="Servicio adicional:" name="additionalService">
                  <Select
                    value={additionalServiceName}
                    initialvalues={additionalServiceName}
                    defaultValue={additionalServiceName}
                  />
                </Form.Item>
              </Col>
              <Col md={6} xs={24}>
                <Form.Item label="Proveedor:" name="supplier">
                  <Select
                    value={supplierName}
                    initialvalues={supplierName}
                    defaultValue={supplierName}
                    onChange={(value, label) => {
                      setSupplierName(label.children);
                    }}
                  ></Select>
                </Form.Item>
              </Col>
            </>
          )}
        </Row>
        <Row gutter={16} justify="end">
          <Col md={6} xs={24}>
            <Form.Item label="Costo:" name="cost">
              <InputNumber
                value={cost}
                defaultValue={`$${formatMoney(cost)}`}
                style={{ width: '100%' }}
                disabled
              ></InputNumber>
            </Form.Item>
          </Col>
          <Col md={6} xs={24}>
            <Form.Item
              label="Profit:"
              name="profit"
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese un monto',
                  type: 'number',
                },
              ]}
            >
              <InputNumber
                style={{ width: '100%' }}
                min={0}
                formatter={(value) =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              ></InputNumber>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col
            span={24}
            style={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <Form.Item>
              <Button htmlType="submit" className="btn-enviar" block>
                Guardar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default AddAdditionalService;
