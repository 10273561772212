import { notification } from 'antd';

const messageSuccessDefault = 'Operación exitosa';
const descriptionSuccessDefault = '';

const messageErrorDefault = 'Ocurrió un error';
const descriptionErrorDefault = 'Inténtelo de nuevo.';

export const feedbackSuccess = (
  message = messageSuccessDefault,
  description = descriptionSuccessDefault
) => {
  notification['success']({
    message: message,
    description: description,
  });
};

export const feedbackError = (
  message = messageErrorDefault,
  description = descriptionErrorDefault
) => {
  notification['error']({
    message: message,
    description: description,
  });
};
