import React, { useEffect } from 'react';
import { Row, Col, Typography, Button } from 'antd';
import OperationIcon from '../../../assets/img/png/operation.png';
import FacturaIcon from '../../../assets/img/png/factura.png';
import BalanceIcon from '../../../assets/img/png/balance.png';

import { useHistory } from 'react-router';
import useUser from '../../../hooks/useUser';
import {
  ROL_ADMIN,
  ROL_FINANCIAL,
  ROL_LAYOUT,
  ROL_MANAGER,
} from '../../../utils/constants';

export default function CardsBiling({ userType }) {
  const { person } = useUser();
  let catalog = [];

  if (userType === 'cliente') {
    catalog = [
      {
        name: 'operation',
      },
      {
        name: 'accountBalance',
      },
      {
        name: 'billingClient',
      },
    ];
  } else if (userType === 'proveedor') {
    catalog = [
      {
        name: 'billing',
      },
      {
        name: 'purchaseOrder',
      },
    ];
  }

  return (
    <div className="modules">
      <Row gutter={[0, 15]}>
        {[ROL_ADMIN, ROL_LAYOUT, ROL_FINANCIAL, ROL_MANAGER].some((r) =>
          person.roles.includes(r)
        ) && (
          <>
            {catalog.map((cat, index) => {
              return (
                <Col
                  span={12}
                  key={index}
                  className="modules__container animate__animated  animate__bounceInLeft"
                >
                  <ButtonAction type={cat.name} />
                </Col>
              );
            })}
          </>
        )}
      </Row>
    </div>
  );
}

function ButtonAction(props) {
  const { type } = props;
  const urlImage =
    type === 'operation'
      ? OperationIcon
      : type === 'accountBalance' || type === 'billing'
      ? BalanceIcon
      : FacturaIcon;
  const title =
    type === 'operation'
      ? 'Operación'
      : type === 'accountBalance'
      ? 'Estado de cuenta'
      : type === 'billingClient'
      ? 'Facturación cliente'
      : type === 'billing'
      ? 'Facturación'
      : 'Orden de compra';
  const { Title } = Typography;
  const history = useHistory();

  const handleRedirect = () => {
    if (type === 'operation') {
      history.push('/cxc/operaciones/clientes');
    } else if (type === 'accountBalance') {
      history.push('/cxc/estado/cuentas');
    } else if (type === 'billingClient') {
      history.push('/cxc/facturacion/clientes');
    } else if (type === 'billing') {
      history.push('/cxp/facturacion/proveedores');
    } else if (type === 'purchaseOrder') {
      history.push('/cxp/ordenes/proveedores');
    }
  };

  return (
    <Button
      className="modules__button"
      style={{ padding: 10 }}
      onClick={() => handleRedirect()}
    >
      <img width={60} src={urlImage} />
      <Title style={{ color: 'white', marginTop: 10 }} level={5}>
        {title}
      </Title>
    </Button>
  );
}
