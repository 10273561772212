import React, { useState, useEffect } from 'react';
import {
  Form,
  Upload,
  Button,
  Spin,
  message,
  notification,
  Row,
  Col,
  Divider,
} from 'antd';
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import { getAccessTokenApi } from '../../../api/auth';
import { userLogOut } from '../../../utils/general';
import PlantillaFacturacion from '../../../assets/excel/Clientes_Formato_de_facturas.xlsx';
import PlantillaAclaraciones from '../../../assets/excel/Clientes_Formato_de_aclaraciones.xlsx';
import PlantillaOperaciones from '../../../assets/excel/Clientes_Formato_de_operaciones.xlsx';
import PlantillaOperacionesDetalles from '../../../assets/excel/Clientes_Formato_de_operaciones_detalles.xlsx';
import {
  uploadInvoicesApi,
  uploadNotesApi,
  uploadOperationsApi,
  uploadOperationsDetailsApi,
} from '../../../api/files';
import './FileProducts.scss';

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

const FileProducts = (props) => {
  const { setIsVisible, type } = props;
  const token = getAccessTokenApi();
  const [loadingForm, setLoadingForm] = useState(false);
  //   const [file, setFile] = useState(null);
  const [form] = Form.useForm();
  const [filesList, setFileList] = useState({});

  useEffect(() => {
    if (token == null) {
      userLogOut();
    }
    // setTitle('Agregar productos');
  }, [token]);

  function Cancel() {
    form.resetFields();
    setIsVisible(false);
  }

  const uploadDocuments = async (values) => {
    if (
      values.file[0].type !==
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ) {
      message.error('¡Solo puedes subir archivos excel!');
      return false;
    }
    if (values.file === undefined) {
      notification['error']({
        message: 'Debes agregar un documento',
      });
    } else {
      setLoadingForm(true);
      let result;
      if (type === 'billing') {
        result = await uploadInvoicesApi(token, values);
      } else if (type === 'clarification') {
        result = await uploadNotesApi(token, values);
      } else if (type === 'operation') {
        result = await uploadOperationsApi(token, values);
      } else {
        result = await uploadOperationsDetailsApi(token, values);
      }
      // const result = await uploadInvoices(token, values);
      if (result === undefined) {
        notification['error']({
          message:
            'Verifique su conexión a internet, si no se resuelve intente más tarde.',
        });
      } else {
        if (result.statusCode !== 200) {
          let msg = 'Ocurrio un problema, Intente mas tarde por favor.';

          if (result.statusCode === 409 || result.statusCode === 400) {
            msg = result.description;
          }
          notification['error']({
            message: msg,
          });
        } else {
          //recreo correctamenta
          notification['success']({
            message: result.description,
          });
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      }
    }

    setLoadingForm(false);
  };

  function beforeUpload(file) {
    const isJpgOrPng =
      file.type ===
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    if (!isJpgOrPng) {
      message.error('¡Solo puedes subir archivos excel!');
    }
    // const isLt2M = file.size / 1024 / 1024 < 3;
    // if (!isLt2M) {
    //     message.warning('¡La imagen supera los 3MB recomendados!');
    // }
    //   return isJpgOrPng && isLt2M;
    return false;
  }

  const handleChange = (info) => {
    let fileList = [...info.fileList];

    if (Array.isArray(fileList) && fileList.length) {
      fileList = fileList.slice(-1);
    }
    setFileList({ fileList });
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList.slice(-1);
  };

  return (
    <Spin tip="Guardando..." spinning={loadingForm} size="large">
      <Form
        {...layout}
        className="groupName"
        form={form}
        onFinish={uploadDocuments}
      >
        <Col>
          <h4 className="alerta-text">*La primera fila no se puede editar*</h4>
          {/* <p>Activo = mostrar en el menú de cliente</p>
                    <p>Inactivo = no mostrar en el menú de cliente</p> */}
        </Col>

        <Col xs={22}>
          <Button
            icon={<DownloadOutlined />}
            type="primary"
            href={
              type === 'billing'
                ? PlantillaFacturacion
                : type === 'clarification'
                ? PlantillaAclaraciones
                : type === 'operation'
                ? PlantillaOperaciones
                : PlantillaOperacionesDetalles
            }
            download={
              type === 'billing'
                ? 'Plantilla_de_facturacion'
                : type === 'clarification'
                ? 'Plantilla_de_Aclaraciones'
                : type === 'operation'
                ? 'Plantilla_de_Operaciones'
                : 'Plantilla_de_Operaciones_Detalles'
            }
            ghost
          >
            Descargar plantilla
          </Button>
        </Col>
        <Form.Item
          name="file"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          hidden={loadingForm}
          // rules={[
          //     {
          //         message: 'Suba una archivo',
          //     },
          // ]}
        >
          <Upload
            name="template"
            beforeUpload={beforeUpload}
            listType="picture"
            multiple={false}
            onChange={handleChange}
            fileList={filesList}
          >
            <Button
              type="primary"
              style={{ marginTop: 20 }}
              icon={<UploadOutlined />}
            >
              Click para cargar plantilla
            </Button>
          </Upload>
        </Form.Item>
        {/* <Row>
                    <div>
                        <p className="alerta-text">
                            <span className="nota-text">NOTA: </span>*Después de
                            que hayas subido el documento, te notificaremos
                            cuando se termine de importar la carga de productos
                            en el sistema*
                        </p>
                    </div>
                </Row> */}

        <Divider></Divider>

        <Row>
          <Col span={12}>
            <Button type="ghost" onClick={Cancel}>
              Cancelar
            </Button>
          </Col>
          <Col span={12}>
            <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit">
                Guardar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default FileProducts;
