import React, { useState, useEffect } from 'react';
import {
  Button,
  Modal as ModalAntd,
  Space,
  Table,
  Tooltip,
  Col,
  DatePicker,
  Row,
  Checkbox,
  notification,
} from 'antd';
import {
  ReloadOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
  ExclamationCircleOutlined,
  EditOutlined,
  SearchOutlined,
  SendOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons';
import Modal from '../../../General/Modal';
import { fetchApi } from '../../../../helpers/fetch';
import { getAccessTokenApi } from '../../../../api/auth';
import {
  METHOD_DELETE,
  METHOD_GET,
  METHOD_PUT,
  ROL_ADMIN,
  ROL_LAYOUT,
  ROL_PRICING,
} from '../../../../utils/constants';
import { API_URL_RFQ } from '../../../../helpers/urls';
import AddSupplierForm from '../../../../components/Admin/Administration/SuppliersTable/AddSupplierForm/AddSupplierForm';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { formatMoney } from '../../../../utils/general';
import useUser from '../../../../hooks/useUser';
import moment from 'moment';
import Highlighter from 'react-highlight-words';
import AddEditPricingForm from '../../AddEditPricingForm/AddEditPricingForm';
import { getCatalogValueHandle } from '../../../../helpers/handlerFunctionApi';
import {
  CURRENCY_CATALOG_ID,
  MEASUREMENT_UNIT_CATALOG_ID,
  OPERACIONES_CATALOG_ID,
  PACKAGING_UNIT_CATALOG_ID,
  TYPE_OF_LOAD_CATALOG_ID,
  UNIT_TYPE_CATALOG_ID,
} from '../../../../helpers/constants';
import './RFQResponsesTable.scss';
import { getFilteredDataFromLocalStorage, saveFilteredDataToLocalStorage } from '../../../../helpers/saveFilterData';

const RFQResponsesTable = ({
  pickResponse,
  setModalIsVisible: setModalResponsesIsVisible,
  form,
  linesForm,
  setSupplierSelected,
  rate,
  setUnitCost,
  setLinesData,
  line,
  linesData,
}) => {
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [reloadTable, setReloadTable] = useState(true);
  const [titleModal, setTitleModal] = useState(false);
  const [contextModal, setContextModal] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  // const hasSelected = selectedRowKeys.length > 0;
  const { confirm } = ModalAntd;
  const token = getAccessTokenApi();
  let { rfqId } = useParams();
  const [rfqResponses, setRfqResponses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const { person } = useUser();
  const [startDate, setStartDate] = useState({
    createdAt: '',
    startDate: '',
    endDate: '',
  });
  const [endDate, setEndDate] = useState({
    createdAt: '',
    startDate: '',
    endDate: '',
  });
  const x = React.createRef();
  const [searchedColumn, setSearchedColumn] = useState('');
  const [filteredInfo, setFilteredInfo] = useState({});
  const [searchText, setSearchText] = useState('');
  const [operationFilter, setOperationFilter] = useState([]);
  const [operation, setOperation] = useState([]);
  const [typeOfLoadFilter, setTypeOfLoadFilter] = useState([]);
  const [typeOfLoad, setTypeOfLoad] = useState([]);
  const [unitFilter, setUnitFilter] = useState([]);
  const [unit, setUnit] = useState([]);
  const [measurementUnitFilter, setMeasurementUnitFilter] = useState([]);
  const [measurementUnit, setMeasurementUnit] = useState([]);
  const [packagingFilter, setPackagingFilter] = useState([]);
  const [packaging, setPackaging] = useState([]);
  const [currencyFilter, setCurrencyFilter] = useState([]);
  const [currency, setCurrency] = useState([]);
  const [responseSelectedCheck, setResponseSelectedCheck] = useState(null);
  const [supplierNull, setSupplierNull] = useState(false);

  // const onSelectChange = (selectedRowKeys) => {
  //   setSelectedRowKeys(selectedRowKeys);
  // };
  // const rowSelection = {
  //   selectedRowKeys,
  //   onChange: onSelectChange,
  // };

  useEffect(() => {
    if (!pickResponse) {
      const config = {
        method: METHOD_GET,
        url: `${API_URL_RFQ}/${rfqId}/GetLines`,
        data: null,
        token,
      };
      fetchApi(config)
        .then((response) => {
          if (response) {
            let rfqRes = response.result;

            if (rfqRes) {
              rfqRes.map((e, index) => {
                e['key'] = index;
              });
            }
            setRfqResponses(rfqRes);
          }
          setReloadTable(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [rfqId, token, pickResponse, rate, reloadTable]);

  useEffect(() => {
    if (pickResponse) {
      const config = {
        method: METHOD_GET,
        url: `${API_URL_RFQ}/${rate}/GetLinesToQuote`,
        data: null,
        token,
      };
      fetchApi(config)
        .then((response) => {
          if (response.statusCode === 200) {
            let rfqRes = response.result;

            if (rfqRes) {
              rfqRes.map((e, index) => {
                e['key'] = index;
              });
            }
            setRfqResponses(rfqRes);
          }
          if (response.statusCode === 404) {
            notification['error']({
              message: response.description,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setReloadTable(false);
        });
    }
  }, [rfqId, token, pickResponse, rate]);
  useEffect(() => {
    getCatalogValueHandle(
      OPERACIONES_CATALOG_ID,
      setOperation,
      setIsLoading,
      0
    );
    getCatalogValueHandle(
      TYPE_OF_LOAD_CATALOG_ID,
      setTypeOfLoad,
      setIsLoading,
      0
    );
    getCatalogValueHandle(UNIT_TYPE_CATALOG_ID, setUnit, setIsLoading, 0);
    getCatalogValueHandle(
      MEASUREMENT_UNIT_CATALOG_ID,
      setMeasurementUnit,
      setIsLoading,
      0
    );
    getCatalogValueHandle(
      PACKAGING_UNIT_CATALOG_ID,
      setPackaging,
      setIsLoading,
      0
    );
    getCatalogValueHandle(CURRENCY_CATALOG_ID, setCurrency, setIsLoading, 0);
  }, []);
  useEffect(() => {
    let filtersOperation = [];
    let filtersTypeOfLoad = [];
    let filtersUnit = [];
    let filtersMeasurementUnit = [];
    let filtersPackaging = [];
    let filtersCurrency = [];
    operation.forEach((element) => {
      const json = { text: element.label, value: element.label };
      filtersOperation.push(json);
    });
    setOperationFilter(filtersOperation);
    typeOfLoad.forEach((element) => {
      const json = { text: element.label, value: element.label };
      filtersTypeOfLoad.push(json);
    });
    setTypeOfLoadFilter(filtersTypeOfLoad);
    unit.forEach((element) => {
      const json = { text: element.label, value: element.label };
      filtersUnit.push(json);
    });
    setUnitFilter(filtersUnit);
    measurementUnit.forEach((element) => {
      const json = { text: element.label, value: element.label };
      filtersMeasurementUnit.push(json);
    });
    setMeasurementUnitFilter(filtersMeasurementUnit);
    packaging.forEach((element) => {
      const json = { text: element.label, value: element.label };
      filtersPackaging.push(json);
    });
    setPackagingFilter(filtersPackaging);
    currency.forEach((element) => {
      const json = { text: element.label, value: element.label };
      filtersCurrency.push(json);
    });
    setCurrencyFilter(filtersCurrency);
  }, [operation, typeOfLoad, unit, measurementUnit, packaging, currency]);
  useEffect(() => {
    const datosObtenidos = getFilteredDataFromLocalStorage('rfqResponses');
    if (datosObtenidos !== null && !pickResponse) {
      setFilteredInfo(datosObtenidos.filters);
      setStartDate(datosObtenidos.dateFilters.startDates)
      setEndDate(datosObtenidos.dateFilters.endDates)
    }
  },[])
  const showModal = (type, id, lineNumber, quoteId, supplierResponse) => {
    if (type === 'Add') {
      setModalIsVisible(true);
      setTitleModal(`Agregar respuesta RFQ #${rfqId}`);
      setContextModal(
        <AddEditPricingForm
          type="Add"
          setModalIsVisible={setModalIsVisible}
          rateId={rfqId}
          setReloadTable={setReloadTable}
          isRfq={true}
          isResponse={true}
        ></AddEditPricingForm>
      );
    } else if (type === 'Edit') {
      setModalIsVisible(true);
      setTitleModal(
        `Editar RFQ #${rfqId} respuesta #${lineNumber} - Cotización #${quoteId}`
      );
      setContextModal(
        <AddEditPricingForm
          type="Edit"
          setModalIsVisible={setModalIsVisible}
          rateId={id}
          setReloadTable={setReloadTable}
          isRfq={true}
          isResponse={true}
        ></AddEditPricingForm>
      );
    } else if (type === 'Watch') {
      setModalIsVisible(true);
      setTitleModal(`RFQ #${rfqId}`);
      setContextModal(
        <AddEditPricingForm
          type="watch"
          setModalIsVisible={setModalIsVisible}
          rateId={rfqId}
          setReloadTable={setReloadTable}
          isRfq={true}
        ></AddEditPricingForm>
      );
    } else if (type === 'Supplier') {
      setModalIsVisible(true);
      setTitleModal(`Agregar proveedor`);
      setContextModal(
        <AddSupplierForm
          setModalIsVisible={setModalIsVisible}
          supplierResponse={supplierResponse}
          setReloadTable={setReloadTable}
        ></AddSupplierForm>
      );
    }
  };
  const getColumnSearchDateProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Col>
          <DatePicker
            ref={x}
            id={'startDate' + dataIndex}
            placeholder={'Fecha inicial'}
            style={{ marginRight: '10px' }}
            allowClear={false}
            format="DD/MM/YYYY"
            value={
              startDate[dataIndex] !== ''
                ? moment.unix(startDate[dataIndex])
                : null
            }
            onChange={(e) => {
              // setStartDate(moment(e).unix());
              startDate[dataIndex] = moment(e).startOf('day').unix();
              setSelectedKeys(
                rfqResponses
                  .filter(
                    (op) =>
                      moment(e)
                        .hours(0)
                        .minutes(0)
                        .seconds(0)
                        .milliseconds(0)
                        .unix() <= op[dataIndex]
                  )
                  .map((op) => op.key)
              );

              // setSelectedKeys(e !== null ? [e.format('DD/MM/YYYY')] : [])
            }}
          />
          <DatePicker
            placeholder={'Fecha final'}
            format="DD/MM/YYYY"
            allowClear={false}
            value={
              endDate[dataIndex] !== '' ? moment.unix(endDate[dataIndex]) : null
            }
            onChange={(e) => {
              // setEndDate(moment(e).unix());
              endDate[dataIndex] = moment(e).endOf('day').unix();
              setSelectedKeys(
                rfqResponses
                  .filter(
                    (op) =>
                      op[dataIndex] <=
                      moment(e)
                        .hours(23)
                        .minutes(59)
                        .seconds(59)
                        .milliseconds(0)
                        .unix()
                  )
                  .map((op) => op.key)
              );
            }}
          />
        </Col>
        <Row justify="end" gutter={16}>
          <Col>
            <Button
            disabled={endDate[dataIndex] === ''}
              type="primary"
              onClick={() => {
                handleSearchDate(selectedKeys, confirm);
                setSelectedKeys(
                  rfqResponses
                    .filter(
                      (d) =>
                        startDate[dataIndex] <= d[dataIndex] &&
                        d[dataIndex] <= endDate[dataIndex]
                    )
                    .map((d) => d.key)
                );
              }}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Buscar
            </Button>
            <Button
              onClick={() => {
                handleReset(clearFilters, selectedKeys, confirm, dataIndex);
                handleSearchDate(selectedKeys, confirm, dataIndex);
              }}
              size="small"
              style={{ width: 90 }}
            >
              Limpiar
            </Button>
          </Col>
        </Row>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      startDate[dataIndex] <= record[dataIndex] &&
      record[dataIndex] <= endDate[dataIndex],
    filteredValue: filteredInfo[dataIndex] || null,
    onFilterDropdownVisibleChange: (visible) => {},
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? moment.unix(text).format('DD/MM/YYYY') : ''}
        />
      ) : text ? (
        moment.unix(text).format('DD/MM/YYYY')
      ) : (
        ''
      ),
  });
  function handlersupplierNull() {
    confirm({
      title: 'Proveedor temporal',
      icon: <ExclamationCircleOutlined />,
      content: 'Agregue este proveedor para poder seleccionarlo.',
      okText: 'Aceptar',
      cancelText: '',
      // onOk() {
      //   clearFilterRate();
      // },
    });
  }
  const checkResponse = (id, response) => {
    if (responseSelectedCheck === id) {
      setResponseSelectedCheck(null);
    } else {
      setResponseSelectedCheck(id);
    }
    if (response.supplierId === null) {
      handlersupplierNull();
      setSupplierNull(true);
      return;
    } else {
      setSupplierNull(false);
    }
    if (pickResponse) {
      // setSupplierSelected(response.supplier);
      setUnitCost(
        response.unitPrice !== 0 ? response.unitPrice : response.cost
      );
      let found = linesData.findIndex((e, index) => e.id === line.id);

      let copy = [...linesData];
      copy[found] = { ...line };
      copy[found]['measurementUnit'] = response.measurementUnitId;
      copy[found]['unitPrice'] =
        response.unitPrice !== 0 ? response.unitPrice : response.cost;
      copy[found]['rateCurrency'] = response.currencyId;
      copy[found]['currency'] = response.currencyId;
      copy[found]['rateUnitPrice'] =
        response.unitPrice !== 0 ? response.unitPrice : response.cost;
      copy[found]['supplierId'] = response.supplierId;
      copy[found]['supplierName'] = response.supplierName;
      setLinesData(copy);

      linesForm.setFieldsValue({
        RFQLineId: response.id,
        RFQNumber: response.number,
        supplierId: response.supplierId,
        supplierName: response.supplierName,
        rateCurrency: response.currencyId,
        measurementUnit: response.measurementUnitId,
        rateUnitPrice:
          response.unitPrice !== 0 ? response.unitPrice : response.cost,
      });
    }
  };
  const columnsCheck = [
    {
      title: '',
      ellipsis: true,
      width: 50,
      // width: 100,
      dataIndex: 'id',
      key: 'company',
      render: (id, response) => (
        <Checkbox
          onChange={() => checkResponse(id, response)}
          checked={responseSelectedCheck === id}
          style={{ padding: 10 }}
          className="button-detail"
        ></Checkbox>
      ),
    },
    {
      title: 'No. Respuesta',
      dataIndex: 'number',
      key: 'number',
      width: 80,
      align: 'center',
      // fixed: 'left',
    },
    {
      title: 'Proveedor',
      dataIndex: 'supplierName',
      key: 'supplierName',
      width: 80,
      align: 'center',
      // fixed: 'left',
    },
    {
      title: 'Operación',
      dataIndex: 'operation',
      key: 'operation',
      width: 100,
      align: 'center',

      // fixed: 'left',
      filters: operationFilter,
      filteredValue: filteredInfo.operation || null,
      onFilter: (value, record) => record.operation === value,
    },
    {
      title: 'Tipo de carga',
      dataIndex: 'typeOfLoad',
      key: 'typeOfLoad',
      width: 100,
      // fixed: 'left',
      align: 'center',
      filters: typeOfLoadFilter,
      filteredValue: filteredInfo.typeOfLoad || null,
      onFilter: (value, record) => record.typeOfLoad === value,
    },
    {
      title: 'Tipo de equipo',
      dataIndex: 'equipmentType',
      key: 'equipmentType',
      width: 100,
      // fixed: 'left',
      align: 'center',
      filters: unitFilter,
      filteredValue: filteredInfo.equipmentType || null,
      onFilter: (value, record) => record.equipmentType === value,
    },
    {
      title: 'Unidad',
      dataIndex: 'unit',
      key: 'unit',
      width: 100,
      // fixed: 'left',
      align: 'center',
      filters: unitFilter,
      filteredValue: filteredInfo.unit || null,
      onFilter: (value, record) => record.unit === value,
    },
    {
      title: 'Unidad de medida',
      dataIndex: 'measurementUnit',
      key: 'measurementUnit',
      width: 100,
      // fixed: 'left',
      align: 'center',
      filters: measurementUnitFilter,
      filteredValue: filteredInfo.measurementUnit || null,
      onFilter: (value, record) => record.measurementUnit === value,
    },
    {
      title: 'Embalaje',
      dataIndex: 'packaging',
      key: 'packaging',
      width: 100,
      // fixed: 'left',
      align: 'center',
      filters: packagingFilter,
      filteredValue: filteredInfo.packaging || null,
      onFilter: (value, record) => record.packaging === value,
    },
    {
      title: 'Costo',
      dataIndex: 'unitPrice',
      key: 'unitPrice',
      width: 100,
      // fixed: 'right',
      align: 'center',
      render: (unitPrice, record) => (
        <span>${formatMoney(unitPrice === 0 ? record.cost : unitPrice)}</span>
      ),
    },
    {
      title: 'Moneda',
      dataIndex: 'currency',
      key: 'currency',
      width: 100,
      // fixed: 'left',
      align: 'center',
      filters: currencyFilter,
      filteredValue: filteredInfo.currency || null,
      onFilter: (value, record) => record.currency === value,
    },
    {
      title: 'Fecha inicial',
      dataIndex: 'startDate',
      key: 'startDate',
      width: 100,
      align: 'center',
      render: (startDate) => (
        <>
          {startDate !== null
            ? moment.unix(startDate).format('DD/MM/YYYY')
            : null}
        </>
      ),
      ...getColumnSearchDateProps('startDate'),
    },
    {
      title: 'Fecha final',
      dataIndex: 'endDate',
      key: 'endDate',
      width: 100,
      // fixed: 'right',
      align: 'center',
      render: (endDate) => (
        <>
          {endDate !== null ? moment.unix(endDate).format('DD/MM/YYYY') : null}
        </>
      ),
      ...getColumnSearchDateProps('endDate'),
    },
    // {
    //   title: 'Acción',
    //   key: 'action',
    //   width: 120,
    //   render: (text, record) => (
    //     <>
    //       {[ROL_PRICING, ROL_ADMIN, ROL_LAYOUT].some((r) =>
    //         person.roles.includes(r)
    //       ) && (
    //         <>
    //           <Space size="middle">
    //             <Tooltip title="Editar">
    //               <Button
    //                 onClick={() => showModal('Edit', record.id, record.number)}
    //                 type="primary"
    //                 disabled={pickResponse}
    //               >
    //                 <EditOutlined />
    //               </Button>
    //             </Tooltip>

    //             <Tooltip title="Eliminar">
    //               <Button
    //                 danger
    //                 type="primary"
    //                 disabled={pickResponse}
    //                 onClick={() => handleDeleteResponse(record.id)}
    //               >
    //                 <DeleteOutlined />
    //               </Button>
    //             </Tooltip>
    //           </Space>
    //         </>
    //       )}
    //     </>
    //   ),
    //   align: 'center',
    // },
  ];
  const columns = [
    {
      title: 'No. Respuesta',
      dataIndex: 'number',
      key: 'number',
      width: 80,
      align: 'center',
      // fixed: 'left',
    },
    {
      title: 'Proveedor',
      dataIndex: 'supplierName',
      key: 'supplierName',
      width: 80,
      align: 'center',
      // fixed: 'left',
    },
    {
      title: 'Operación',
      dataIndex: 'operation',
      key: 'operation',
      width: 100,
      align: 'center',

      // fixed: 'left',
      filters: operationFilter,
      filteredValue: filteredInfo.operation || null,
      onFilter: (value, record) => record.operation === value,
    },
    {
      title: 'Tipo de carga',
      dataIndex: 'typeOfLoad',
      key: 'typeOfLoad',
      width: 100,
      // fixed: 'left',
      align: 'center',
      filters: typeOfLoadFilter,
      filteredValue: filteredInfo.typeOfLoad || null,
      onFilter: (value, record) => record.typeOfLoad === value,
    },
    {
      title: 'Tipo de equipo',
      dataIndex: 'equipmentType',
      key: 'equipmentType',
      width: 100,
      // fixed: 'left',
      align: 'center',
      filters: unitFilter,
      filteredValue: filteredInfo.equipmentType || null,
      onFilter: (value, record) => record.equipmentType === value,
    },
    {
      title: 'Unidad',
      dataIndex: 'unit',
      key: 'unit',
      width: 100,
      // fixed: 'left',
      align: 'center',
      filters: unitFilter,
      filteredValue: filteredInfo.unit || null,
      onFilter: (value, record) => record.unit === value,
    },
    {
      title: 'Unidad de medida',
      dataIndex: 'measurementUnit',
      key: 'measurementUnit',
      width: 100,
      // fixed: 'left',
      align: 'center',
      filters: measurementUnitFilter,
      filteredValue: filteredInfo.measurementUnit || null,
      onFilter: (value, record) => record.measurementUnit === value,
    },
    {
      title: 'Embalaje',
      dataIndex: 'packaging',
      key: 'packaging',
      width: 100,
      // fixed: 'left',
      align: 'center',
      filters: packagingFilter,
      filteredValue: filteredInfo.packaging || null,
      onFilter: (value, record) => record.packaging === value,
    },
    {
      title: 'Costo',
      dataIndex: 'unitPrice',
      key: 'unitPrice',
      width: 100,
      // fixed: 'right',
      align: 'center',
      render: (unitPrice, record) => (
        <span>${formatMoney(unitPrice === 0 ? record.cost : unitPrice)}</span>
      ),
    },
    {
      title: 'Moneda',
      dataIndex: 'currency',
      key: 'currency',
      width: 100,
      // fixed: 'left',
      align: 'center',
      filters: currencyFilter,
      filteredValue: filteredInfo.currency || null,
      onFilter: (value, record) => record.currency === value,
    },
    {
      title: 'Fecha inicial',
      dataIndex: 'startDate',
      key: 'startDate',
      width: 100,
      align: 'center',
      render: (startDate) => (
        <>
          {startDate !== null
            ? moment.unix(startDate).format('DD/MM/YYYY')
            : null}
        </>
      ),
      ...getColumnSearchDateProps('startDate'),
    },
    {
      title: 'Fecha final',
      dataIndex: 'endDate',
      key: 'endDate',
      width: 100,
      // fixed: 'right',
      align: 'center',
      render: (endDate) => (
        <>
          {endDate !== null ? moment.unix(endDate).format('DD/MM/YYYY') : null}
        </>
      ),
      ...getColumnSearchDateProps('endDate'),
    },
    {
      title: 'Acción',
      key: 'action',
      width: 80,
      render: (text, record) => (
        <>
          {[ROL_PRICING, ROL_ADMIN, ROL_LAYOUT].some((r) =>
            person.roles.includes(r)
          ) && (
            <>
              <Space size="small">
                <Tooltip title="Editar">
                  <Button
                    onClick={() =>
                      showModal(
                        'Edit',
                        record.id,
                        record.number,
                        record.quoteId
                      )
                    }
                    type="primary"
                    icon={<EditOutlined />}
                    disabled={pickResponse}
                  ></Button>
                </Tooltip>
                <Tooltip title="Agregar proveedor">
                  <Button
                    icon={<UsergroupAddOutlined />}
                    onClick={() => showModal('Supplier', '', '', '', record)}
                    type="primary"
                    disabled={pickResponse || record.supplierId !== null}
                  ></Button>
                </Tooltip>

                <Tooltip title="Eliminar">
                  <Button
                    danger
                    icon={<DeleteOutlined />}
                    type="primary"
                    disabled={pickResponse}
                    onClick={() => handleDeleteResponse(record.id)}
                  ></Button>
                </Tooltip>
              </Space>
            </>
          )}
        </>
      ),
      align: 'center',
    },
  ];
  const handleSearchDate = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };
  const handleReset = (clearFilters, selectedKeys, confirm, dataIndex) => {
    clearFilters();
    setSearchText('');
    startDate[dataIndex] = '';
    endDate[dataIndex] = '';
  };
  const handleChange = (pagination, filters, sorter, extra) => {
    const dateFilters = { startDates: startDate, endDates: endDate}
    saveFilteredDataToLocalStorage('rfqResponses', pagination, sorter, filters,dateFilters);
    setFilteredInfo(filters);
  };
  const handleDeleteResponse = (id) => {
    confirm({
      title: 'Eliminar respuesta',
      icon: <ExclamationCircleOutlined />,
      content: '¿Estás seguro que deseas eliminar la respuesta seleccionada?',
      okText: 'Si',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteResponse(id);
      },
      onCancel() {},
    });
  };
  const deleteResponse = async (id) => {
    const config = {
      method: METHOD_DELETE,
      url: `RFQLine/${id}`,
      data: null,
      token,
      showNotification: true,
    };
    const result = await fetchApi(config);

    if (result) {
      if (result.statusCode === 200) {
        setReloadTable(true);
      }
    }
  };
  const handleSendResponse = (id) => {
    confirm({
      title: 'Enviar respuestas',
      icon: <ExclamationCircleOutlined />,
      content: '¿Estás seguro que deseas enviar las respuestas?',
      okText: 'Si',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        sendResponses(id);
      },
      onCancel() {},
    });
  };
  const sendResponses = async () => {
    let values = {};
    values['id'] = parseInt(rfqId);
    values['showResponses'] = true;

    const config = {
      method: METHOD_PUT,
      url: `${API_URL_RFQ}/${rfqId}`,
      data: values,
      token,
      showNotification: true,
    };
    const result = await fetchApi(config);

    if (result) {
      if (result.statusCode === 200) {
        setReloadTable(true);
      }
    }
  };

  return (
    <div>
      {!pickResponse && (
        <>
          <Row>
            {[ROL_PRICING, ROL_ADMIN, ROL_LAYOUT].some((r) =>
              person.roles.includes(r)
            ) && (
              <Button
                className="btn-download"
                onClick={() => showModal('Add')}
                icon={<PlusCircleOutlined />}
              >
                Agregar
              </Button>
            )}
            <Button
              className="btn-download"
              icon={<ReloadOutlined />}
              onClick={(e) => setReloadTable(true)}
            >
              Refrescar
            </Button>
            <Button
              className="btn-enviar"
              style={{ width: 170 }}
              icon={<SendOutlined />}
              onClick={(e) => handleSendResponse()}
            >
              Enviar respuestas
            </Button>
          </Row>
        </>
      )}
      <Row gutter={16}>
        <Button className="btn-row-color" type="primary">
          {' '}
        </Button>
        <p className="p-font">Proveedores temporales</p>
      </Row>

      <Table
        dataSource={rfqResponses}
        columns={pickResponse ? columnsCheck : columns}
        bordered
        size="middle"
        onChange={handleChange}
        scroll={{ x: 1500 }}
        rowClassName={(record) =>
          record.supplierId === null ? 'table-row-blue' : 'table-row-white'
        }
        // className="table-striped-rows"
        loading={reloadTable}
        footer={() => {
          return (
            <FooterTable
              pickResponse={pickResponse}
              setModalResponsesIsVisible={setModalResponsesIsVisible}
              supplierNull={supplierNull}
            />
          );
        }}
      />

      <Modal
        title={titleModal}
        visible={modalIsVisible}
        setIsVisible={setModalIsVisible}
        width={1000}
      >
        {contextModal}
      </Modal>
    </div>
  );
};

function FooterTable({
  pickResponse,
  setModalResponsesIsVisible,
  supplierNull,
}) {
  return (
    <div className="footer-table">
      {pickResponse && (
        <>
          <Button
            type="primary"
            disabled={supplierNull}
            onClick={() => {
              setModalResponsesIsVisible(false);
            }}
          >
            Aceptar
          </Button>
        </>
      )}
    </div>
  );
}

export default RFQResponsesTable;
