import React from 'react';
import { Row, Col, Typography, Button } from 'antd';
import CompanyIcon from '../../../assets/img/png/companyIcon.png';
import ContactIcon from '../../../assets/img/png/contactoIcon.png';
import SeriesIcon from '../../../assets/img/png/archivos.png';
import { useHistory } from 'react-router';

export default function Configuration() {
    return (
        <div className="modules">
            <Row gutter={[0, 15]}>
                <Col
                    span={12}
                    className="modules__container animate__animated  animate__bounceInLeft"
                >
                    <ButtonAction type="company" />
                </Col>
                <Col
                    span={12}
                    className="modules__container animate__animated  animate__bounceInLeft"
                >
                    <ButtonAction type="contact" />
                </Col>
                {/* <Col
                    span={12}
                    className="modules__container animate__animated  animate__bounceInLeft"
                >
                    <ButtonAction type="series" />
                </Col> */}
            </Row>
        </div>
    );
}

function ButtonAction(props) {
    const { type } = props;
    const urlImage =
        type === 'company'
            ? CompanyIcon
            : type === 'contact'
            ? ContactIcon
            : SeriesIcon;
    const title =
        type === 'company'
            ? 'Compañia'
            : type === 'contact'
            ? 'Contacto'
            : 'Series';
    const { Title } = Typography;
    const history = useHistory();

    const handleRedirect = () => {
        if (type === 'company') {
            history.push('/configuracion/compañia');
        } else if (type === 'contact') {
            history.push('/configuracion/contacto');
        } else if (type === 'series') {
            history.push('/configuracion/series');
        }
    };

    return (
        <Button className="modules__button" onClick={() => handleRedirect()}>
            <img width={60} src={urlImage} />
            <Title style={{ color: 'white', marginTop: 10 }} level={5}>
                {title}
            </Title>
        </Button>
    );
}
