import {
    Button,
    Col,
    Form,
    Row,
    Spin,
    Modal as ModalAntd,
    Select,
    Input,
    DatePicker,
  } from 'antd';
import React, { useEffect, useReducer, useState } from 'react';
import {
    FileAddOutlined,
    ExclamationCircleOutlined,
  } from '@ant-design/icons';
import {
    METHOD_GET,
    METHOD_POST,
    METHOD_PUT,
  } from '../../../../utils/constants';
import { API_URL_INVOICE } from '../../../../helpers/urls';
import { getAccessTokenApi } from '../../../../api/auth';
import { fetchApi } from '../../../../helpers/fetch';
import { catalogQouteReducer } from '../../../../reducers/catalogsQoute/catalogQouteReducer';
import { getCatalogValueHandle, getCatalogValueReducerHandle } from '../../../../helpers/handlerFunctionApi';
import { HAZMAT_CATALOG_ID, HAZMAT_VALUE_ID, SERVICES_CATALOG_ID, TYPE_OF_LOAD_CATALOG_ID, UNIT_TYPE_CATALOG_ID } from '../../../../helpers/constants';
import RouteTab from '../RouteTab/RouteTab';
import { getAllSuppliers } from '../../../../api/supplier';
import { types } from '../../../../types/types';
import TextArea from 'antd/lib/input/TextArea';
  
const initialStateQT = {
  typeOfLoad: [],
  operations: [],
  hazMat: [],
  typeOfService: [],
  typeOfEquipment: [],
  unit: [],
  measurementUnit: [],
  currency: [],
  packaging: [],
  frecuency: [],
  container: [],
  modality: [],
};
  const AddEditAvailableUnit = (props) => {
    const {
      setModalIsVisible,
      setReloadTable,
      type,
      data,
    } = props;
    const [availableUnitForm] = Form.useForm();
    const [loadingForm, setLoadingForm] = useState(false);
    const token = getAccessTokenApi();
    const { confirm } = ModalAntd;
    const [catalogs, dispatch] = useReducer(catalogQouteReducer, initialStateQT);
    const [service, setService] = useState([]);
    const [servicesCatalog, setServicesCatalog] = useState([]);
    const [unitTypeCatalog, setUnitTypeCatalog] = useState([]);
    const [typeOfLoadCatalog, setTypeOfLoadCatalog] = useState([]);
    const [suppliers, setSuppliers] = useState([]);
    const [statusValue, setStatusValue] = useState('');
  const [typeOfload, setTypeOfload] = useState(0);

    const statusCatalog = ([
        { value: 0, label: 'DISPONIBLE' },
        { value: 1, label: 'APARTADO', },
        { value: 2, label: 'EXPIRADO',  },
      ]);
  
    useEffect(() => {
      //edit or watch
      if (data) {
        setLoadingForm(true);
        availableUnitForm.setFieldsValue({
            serviceId: data.serviceId,
            equipmentTypeId: data.equipmentTypeId,
            loadTypeId: data.loadTypeId,
            status: data.status,
            supplierId: data.supplierId,
            locationCityId: data.locationCityId,
            locationStateId: data.locationStateId,
            destinationCityId: data.destinationCityId,
            destinationStateId: data.destinationStateId,
            comment: data.comment,
        })
        setStatusValue(data.status)
      }
    }, [data, token,availableUnitForm]);
    useEffect(() => {
        getAllSuppliers(token, '')
          .then((response) => {
            if (response.statusCode === 200) {
              let supp = response.result
                .filter((s) => s.active)
                .map((s) => ({
                  value: s.supplierId,
                  label: s.name,
                }));
              supp.sort((a, b) => a.label.localeCompare(b.label));
              setSuppliers(supp);
            }
          })
          .catch((err) => {});
      }, [token]);

    useEffect(() => {
        setLoadingForm(true);
        getCatalogValueHandle(
          UNIT_TYPE_CATALOG_ID,
          setUnitTypeCatalog,
          setLoadingForm
        );
        getCatalogValueHandle(
          SERVICES_CATALOG_ID,
          setServicesCatalog,
          setLoadingForm
        );
        getCatalogValueHandle(
          TYPE_OF_LOAD_CATALOG_ID,
          setTypeOfLoadCatalog,
          setLoadingForm
        );
        getCatalogValueReducerHandle(
            HAZMAT_CATALOG_ID,
            dispatch,
            setLoadingForm,
            0,
            types.getHazMat
          );
      }, [token]);

    const handleSaveInvoiceOperation = (values) => {
      confirm({
        title: !data ? 'Guardar unidad disponible' : 'Editar unidad disponible',
        icon: <ExclamationCircleOutlined />,
        content: !data
          ? '¿Estás seguro que deseas guardar unidad disponible?'
          : '¿Estás seguro que deseas editar unidad disponible?',
        okText: 'Si',
        okType: 'danger',
        cancelText: 'No',
        onOk() {
          setLoadingForm(true);
          addEditAvailableUnit(values, data);
        },
        onCancel() {},
      });
      // }
    };
    const addEditAvailableUnit = (values, dataImg) => {
      if (type === 'edit') {
          values['id'] = data.id;
      }
      const config = {
        method: type === 'edit' ? METHOD_PUT : METHOD_POST,
        url: `AvailableUnits${type === 'edit' ? '/' + data.id : ''}`,
        data: values,
        token,
        showNotification: true,
      };
      fetchApi(config)
        .then((response) => {
          setLoadingForm(false);
          if (response) {
            if (response.statusCode === 200) {
              setModalIsVisible(false);
              setReloadTable(true);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
  
    return (
      <div>
        <Spin spinning={loadingForm} tip="Cargando...">
        <Form
        name="add-available-unit"
        layout="vertical"
        form={availableUnitForm}
        onFinish={handleSaveInvoiceOperation}
        scrollToFirstError
        >
      <div>
        <Row gutter={16}>
        <Col xs={12} sm={12} lg={6}>
          <Form.Item
            label="Servicio:"
            name="serviceId"
            rules={[
              {
                required: true,
                message: 'Por favor ingrese un servicio',
              },
            ]}
          >
            <Select
              placeholder="Selecciona una opción"
              onChange={(value) => {
                setService(value);
              }}
              disabled={type === 'watch'}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {servicesCatalog.map((s) => (
                <Select.Option key={s.value} value={s.value}>
                  {s.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>          
         <Col xs={12} sm={12} lg={6}>
            <Form.Item
              label="Unidad:"
              name="equipmentTypeId"
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese unidad',
                },
              ]}
            >
              <Select
                disabled={type === 'watch'}
                // disabled={disableAllInputs || isRFQ ? true : false}
                placeholder="Selecciona una opción"
                showSearch
                optionFilterProp="children"
                // onChange={(value) => {
                //   onChangeSelect(value, 'unit');
                // }}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {unitTypeCatalog.map((s) => (
                  <Select.Option key={s.value} value={s.value}>
                    {s.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
        </Col>
        {/* <Col xs={12} sm={12} lg={6}>
        <Form.Item
              label="Tipo de equipo:"
              name="EquipmentType"
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese un tipo de equipo',
                },
              ]}
            >
              <Select
                disabled={type === 'watch' && statusValue !== 0}
                // disabled={disableAllInputs || isRFQ ? true : false}
                placeholder="Selecciona una opción"
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {catalogs.typeOfEquipment.map((s) => (
                  <Select.Option key={s.value} value={s.value}>
                    {s.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
        </Col> */}
        
        <Col xs={24} md={12} lg={6}>
                  <Form.Item label="Tipo de carga:" name="loadTypeId">
                    <Select
                      placeholder="Selecciona una opción"
                      allowClear
                      showArrow
                      onChange={(value) => setTypeOfload(value)}
                      disabled={type === 'watch'}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      showSearch
                    >
                      {typeOfLoadCatalog.map((s) => (
                        <Select.Option key={s.value} value={s.value}>
                          {s.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
        </Col>
        {/* {typeOfload === HAZMAT_VALUE_ID && (
                  <Col xl={10} lg={12} md={12} xs={24}>
                    <Row gutter={16}>
                      <Col xs={24} md={16}>
                        <Form.Item
                          label="HAZ MAT:"
                          name="hazMat"
                          rules={[
                            {
                              required: false,
                              message: 'Por favor ingrese HAZ MAT',
                            },
                          ]}
                        >
                          <Select
                            disabled={type === 'watch'}
                            placeholder=""
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {catalogs.hazMat.map((s) => (
                              <Select.Option key={s.value} value={s.value}>
                                {s.label}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={8}>
                        <Form.Item
                          name="hazMatDescription"
                          label="Descripción:"
                          rules={[
                            {
                              required: false,
                              message: 'Por favor ingrese HAZ MAT',
                            },
                          ]}
                        >
                          <Input
                            disabled={
                              type === 'watch'
                            }
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                )} */}
        {type !== 'add' && (
            <Col xs={24} md={12} lg={6}>
            <Form.Item label="Estatus:" name="status">
                    <Select
                      disabled={type === 'watch'}
                      placeholder="Selecciona una opción"
                      showSearch
                      onChange={(value) => {
                        setStatusValue(value);
                      }}
                      optionFilterProp="children"
                      initialvalues={type !== 'Edit' ? 0 : ''}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {statusCatalog.map((s) => (
                        <Select.Option
                          key={s.value}
                          value={s.value}
                        //   disabled={!s.enabled.includes(statusInitial)}
                        >
                          {s.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
            </Col>
        )}
        
        {suppliers && (
        <Col xs={24} md={12} lg={6}>
          <Form.Item label="Proveedor:" name="supplierId">
            <Select
              placeholder="Selecciona una opción"
              allowClear
              showArrow
              disabled={type === 'watch'}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {suppliers.map((e) => (
                <Select.Option key={e.value} value={e.value}>
                  {e.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      )}
        </Row>
        
        <RouteTab type={type} statusValue={statusValue}/>
        {statusValue !== 0 && (
             <Row>
             <Col xs={24}>   
                 <Form.Item
                     label="Comentarios:"
                     name="comment"
                     rules={[
                         {
                             required: true,
                             message: 'Por favor ingrese una descripción',
                         },
                     ]}
                 >
                     <TextArea placeholder="Comentario" maxLength={150} disabled={type === 'watch'} />
                 </Form.Item>
             </Col>
         </Row>
            )}
           
        <Row style={{ marginTop: 10 }} justify="end">
          <Col md={6} xs={24}>
            <Form.Item>
              <Button
                disabled={type === 'watch' || loadingForm}
                className="btn-download"
                style={{ width: 170 }}
                type="primary"
                block
                htmlType="submit"
                icon={<FileAddOutlined />}
              >
                Guardar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </div>
    </Form>
        </Spin>
      </div>
    );
  };
  
  export default AddEditAvailableUnit;
  