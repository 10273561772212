import React, { useState } from 'react';
import { Form, Button, Row, Col } from 'antd';
import { FileAddOutlined } from '@ant-design/icons';
import Modal from '../../../General/Modal';
import UploadFileForm from '../../Payment/UploadFileForm';
import AddBillingForm from '../../Billling/AddBillingForm';
import FileProducts from '../../FileProducts/FileProducts';
import useUser from '../../../../hooks/useUser';
import { ROL_SUPPLIER } from '../../../../utils/constants';

import './BillerFilter.scss';

export default function BillingFilter() {
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [titleModal, setTitleModal] = useState(false);
  const [contextModal, setContextModal] = useState(null);
  const { person } = useUser();

  const layout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 20,
      },
      md: {
        span: 8,
      },
      lg: {
        span: 20,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 8,
      },
      lg: {
        span: 4,
      },
    },
  };
  // const tailLayout = {
  //   wrapperCol: {
  //     xs: {
  //       span: 24,
  //       offset: 0,
  //     },
  //     sm: {
  //       span: 0,
  //       offset: 0,
  //     },
  //     lg: {
  //       span: 0,
  //       offset: 0,
  //     },
  //   },
  // };

  const [form] = Form.useForm();
  // const options = [
  //     { value: 'Todos' },
  //     { value: 'Vigente' },
  //     { value: 'Pagado' },
  //     { value: 'Vencido' },
  // ];

  // function tagRender(props) {
  //     const { label, value, closable, onClose } = props;
  //     let color = '';

  //     switch (value) {
  //         case 'Todos':
  //             color = 'blue';
  //             break;
  //         case 'Vigente':
  //             color = 'orange';
  //             break;
  //         case 'Pagado':
  //             color = 'green';
  //             break;

  //         case 'Vencido':
  //             color = 'red';
  //             break;

  //         default:
  //             break;
  //     }

  //     return (
  //         <Tag
  //             color={color}
  //             closable={closable}
  //             onClose={onClose}
  //             style={{ marginRight: 3 }}
  //         >
  //             {label}
  //         </Tag>
  //     );
  // }

  // const onFinish = (values) => {
  //     console.log('Success:', values);
  // };

  const uploadExcel = () => {
    setModalIsVisible(true);
    setTitleModal('Subir Layout - Fcaturación');
    setContextModal(
      <FileProducts setIsVisible={setModalIsVisible} type="billing" />
    );
  };
  const showModal = (
    type,
    billingFields,
    fileXml,
    filePdf,
    fileCfdi,
    nameXml
  ) => {
    if (type === 'addFile') {
      setModalIsVisible(true);
      setTitleModal('Facturación');
      setContextModal(
        <UploadFileForm
          setModalIsVisible={setModalIsVisible}
          showModal={showModal}
          type={type}
        />
      );
    } else if (type === 'addExcel') {
      uploadExcel();
    } else {
      setModalIsVisible(true);
      setTitleModal('');
      setContextModal(
        <AddBillingForm
          setModalVisible={setModalIsVisible}
          billingFields={billingFields}
          fileXml={fileXml}
          filePdf={filePdf}
          fileCfdi={fileCfdi}
          nameXml={nameXml}
          // setReloadBills={setReloadBills}
        ></AddBillingForm>
      );
    }
  };

  return (
    <>
      <Form form={form} {...layout} name="billingFilter">
        {/* <Row>
                    <Col xs={24} md={12}>
                        <Form.Item label="Documentos">
                            <Select>
                                <Select.Option value="all">Todos</Select.Option>
                                <Select.Option value="demo">Demo</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                        <Form.Item label="Folio">
                            <Input placeholder="Folio" />
                        </Form.Item>
                    </Col>
                </Row> */}

        {/* <Row>
                    <Col xs={24} md={12}>
                        <Form.Item label="Estatus">
                            <Select >
                                <Select.Option value="Todos">
                                    Todos
                                </Select.Option>
                                <Select.Option value="Vigente">
                                    Vigente
                                </Select.Option>
                                <Select.Option value="Pagado">
                                    Pagado
                                </Select.Option>
                                <Select.Option value="Vencido">
                                    Vencido
                                </Select.Option>
                            </Select>
                            <Select
                                tagRender={tagRender}
                                showArrow
                                style={{ width: '100%' }}
                                options={options}
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                        <Form.Item label="Orden de compra">
                            <Input placeholder="Orden de compra" />
                        </Form.Item>
                    </Col>
                </Row> */}

        <Row
          style={{
            // display: '-webkit-inline-box',
            alignItems: 'center',
            justifyContent: 'end',
          }}
        >
          {/* {[ROL_SUPPLIER].some((r) => person.roles.includes(r)) && (
            <Col md={24} xs={24} className="container-fecha">
              <Row>
                <p style={{ marginRight: 5 }}>
                  Agregar factura o nota de crédito:
                </p>

                <Form.Item style={{ textAlign: 'right' }}>
                  <Button
                    onClick={() => showModal('addFile')}
                    className="payment-btn__add"
                    icon={<FileAddOutlined />}
                  >
                    Agregar
                  </Button>
                </Form.Item>
              </Row>
            </Col>
          )} */}
          {/* {[ROL_LAYOUT].some((r) => person.roles.includes(r)) && (
            <Col md={24} xs={24}>
              <Form.Item
                // label="Subir Excel"
                {...tailLayout}
                style={{ textAlign: 'right' }}
              >
                <Button
                  onClick={() => showModal('addExcel')}
                  className="biller-btn"
                  icon={<FileExcelOutlined />}
                >
                  Subir Excel
                </Button>
              </Form.Item>
            </Col>
          )} */}
        </Row>
      </Form>
      <Modal
        title={titleModal}
        visible={modalIsVisible}
        setIsVisible={setModalIsVisible}
        width={1000}
      >
        {contextModal}
      </Modal>
    </>
  );
}
