/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useEffect, useState, useReducer, useCallback } from 'react';
import {
  Row,
  Form,
  Select,
  Col,
  Input,
  Button,
  DatePicker,
  Checkbox,
  Tooltip,
  InputNumber,
} from 'antd';
import {
  UserSwitchOutlined,
  InfoCircleOutlined,
  ClearOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import { METHOD_GET } from '../../../../utils/constants';
import {
  API_URL_CATALOG_SUPPLIER,
  API_URL_CATALOG_VALUES,
} from '../../../../helpers/urls';
import {
  HAZMAT_VALUE_ID,
  OPERACIONES_CATALOG_ID,
  SERVICES_CATALOG_ID,
  TERRESTRE_VALUE_ID,
  AEREO_VALUE_ID,
  TYPE_OF_LOAD_CATALOG_ID,
  HAZMAT_CATALOG_ID,
  TYPE_OF_SERVICE_CATALOG_ID,
  UNIT_TYPE_CATALOG_ID,
  MEASUREMENT_UNIT_CATALOG_ID,
  CURRENCY_CATALOG_ID,
  PACKAGING_UNIT_CATALOG_ID,
  MODALITY_CATALOG_ID,
  CREDIT_TIME_CATALOG_ID,
  LOAD_UNLOAD_REQ_CATALOG_ID,
} from '../../../../helpers/constants';
import { getAccessTokenApi } from '../../../../api/auth';
import { fetchApi } from '../../../../helpers/fetch';
import SuppliersTable from '../../../Admin/Suppliers/SuppliersTable/SuppliersTable';
import Modal from '../../../General/Modal';
import { getCatalogValueReducerHandle } from '../../../../helpers/handlerFunctionApi';
import { catalogsReducer } from '../../../../reducers/catalogs/catalogsReducer';
import { types } from '../../../../types/types';

const initialState = {
  typeOfLoad: [],
  operations: [],
  hazMat: [],
  typeOfService: [],
  typeOfEquipment: [],
  unit: [],
  measurementUnit: [],
  currency: [],
  packaging: [],
  modality: [],
  rdcyd: [],
  tdc: [],
};

const BasicPricingTab = ({
  disableAllInputs,
  service,
  setService,
  setLoadingForm,
  setLoadingCatalog,
  form,
  rate,
  setShowAllValues,
  showAllValues,
  setFilterSupplier,
  filterSupplier,
  isRFQ,
  supplierSelected,
  setSupplierSelected,
  routeSelected,
}) => {
  const [serviceCatalog, setServiceCatalog] = useState([]);
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [titleModal, setTitleModal] = useState(false);
  const [contextModal, setContextModal] = useState(null);
  const [statusRate, setStatusRate] = useState('active');
  const [supplierContacts, setSupplierContacts] = useState([]);
  const [typeOfload, setTypeOfload] = useState(0);
  const [catalogs, dispatch] = useReducer(catalogsReducer, initialState);
  // const [filterSupplier, setFilterSupplier] = useState({});
  const token = getAccessTokenApi();
  const { Option } = Select;
  const { RangePicker } = DatePicker;
  const rangeConfig = {
    rules: [
      {
        type: 'array',
        required: true,
        message: 'Por favor selecciona un rango de fecha',
      },
    ],
  };
  function updateReducerCatalog(arrayCatalog, type) {
    if (arrayCatalog) {
      arrayCatalog.map((cat, index) => {
        cat['key'] = index;
        cat['label'] = cat.description;
        cat['value'] = cat.id;
      });
      if (type === 'service') {
        setServiceCatalog(arrayCatalog);
      } else {
        dispatch({ type: type, payload: arrayCatalog });
      }
    }
  }
  useEffect(() => {
    let ignore = false;
    const getCatalogsSupplier = () => {
      const config = {
        method: METHOD_GET,
        url: `${API_URL_CATALOG_SUPPLIER}/${supplierSelected.supplierId}`,
        data: null,
        token,
      };
      fetchApi(config)
        .then((response) => {
          if (response) {
            if (response.statusCode === 200) {
              const catalogSupplierResult = response.result;
              const loadTypeArray = catalogSupplierResult.loadType;
              const hazMatArray = catalogSupplierResult.hazMat;
              //  const measurementUnitsArray =
              //     catalogSupplierResult.measurementUnits;
              const operationsArray = catalogSupplierResult.operations;
              //  const packagingArray = catalogSupplierResult.packaging;
              const typeOfServicesArray = catalogSupplierResult.typeOfServices;
              const unitTypeArray = catalogSupplierResult.unitType;
              const servicesArray = catalogSupplierResult.services;
              const currencyArray = catalogSupplierResult.currency;
              //Update catalog
              if (!ignore) {
                updateReducerCatalog(loadTypeArray, types.getTypeOfLoad);

                updateReducerCatalog(hazMatArray, types.getHazMat);
                // updateReducerCatalog(
                //    measurementUnitsArray,
                //    types.getMeasurementUnit
                // );
                updateReducerCatalog(operationsArray, types.getOperations);
                // updateReducerCatalog(
                //    packagingArray,
                //    types.getPackagingUnit
                // );
                updateReducerCatalog(
                  typeOfServicesArray,
                  types.getTypeOfService
                );
                updateReducerCatalog(unitTypeArray, types.getUnity);
                updateReducerCatalog(unitTypeArray, types.getEquipmentType);
                updateReducerCatalog(currencyArray, types.getCurrency);
                // if (currencyArray !== null) {
                //   updateReducerCatalog([currencyArray], types.getCurrency);
                // } else {
                //   updateReducerCatalog([], types.getCurrency);
                // }
                updateReducerCatalog(servicesArray, 'service');
              }
            }
            // if (!ignore) setLoadingCatalog(false);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoadingCatalog(false);
        });
    };

    if (supplierSelected) {
      setLoadingCatalog(true);
      setShowAllValues(false);
      getCatalogsSupplier();
    }
    return () => {
      ignore = true;
    };
  }, [supplierSelected]);

  useEffect(() => {
    if (showAllValues) {
      setLoadingForm(true);
      const config = {
        method: METHOD_GET,
        url: `${API_URL_CATALOG_VALUES}/GetAll/${SERVICES_CATALOG_ID}`,
        data: null,
        token,
        // showNotification: true
      };
      fetchApi(config)
        .then((response) => {
          if (response) {
            let calogsValues = response.result;

            if (calogsValues) {
              calogsValues.map((cat, index) => {
                cat['key'] = index;
                cat['label'] = cat.description;
                cat['value'] = cat.id;
              });
            }
            setServiceCatalog(calogsValues);
          }
          setLoadingForm(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [showAllValues]);
  useEffect(() => {
    if (showAllValues) {
      getCatalogValueReducerHandle(
        TYPE_OF_LOAD_CATALOG_ID,
        dispatch,
        setLoadingForm,
        0,
        types.getTypeOfLoad
      );
      getCatalogValueReducerHandle(
        OPERACIONES_CATALOG_ID,
        dispatch,
        setLoadingForm,
        0,
        types.getOperations
      );
      getCatalogValueReducerHandle(
        HAZMAT_CATALOG_ID,
        dispatch,
        setLoadingForm,
        0,
        types.getHazMat
      );
      getCatalogValueReducerHandle(
        TYPE_OF_SERVICE_CATALOG_ID,
        dispatch,
        setLoadingForm,
        0,
        types.getTypeOfService
      );
      getCatalogValueReducerHandle(
        UNIT_TYPE_CATALOG_ID,
        dispatch,
        setLoadingForm,
        0,
        types.getUnity
      );
      getCatalogValueReducerHandle(
        UNIT_TYPE_CATALOG_ID,
        dispatch,
        setLoadingForm,
        0,
        types.getEquipmentType
      );
      getCatalogValueReducerHandle(
        MEASUREMENT_UNIT_CATALOG_ID,
        dispatch,
        setLoadingForm,
        0,
        types.getMeasurementUnit
      );
      getCatalogValueReducerHandle(
        CURRENCY_CATALOG_ID,
        dispatch,
        setLoadingForm,
        0,
        types.getCurrency
      );
      getCatalogValueReducerHandle(
        PACKAGING_UNIT_CATALOG_ID,
        dispatch,
        setLoadingForm,
        0,
        types.getPackagingUnit
      );
      getCatalogValueReducerHandle(
        MODALITY_CATALOG_ID,
        dispatch,
        setLoadingForm,
        0,
        types.getModality
      );
      getCatalogValueReducerHandle(
        LOAD_UNLOAD_REQ_CATALOG_ID,
        dispatch,
        setLoadingForm,
        0,
        types.getRdcyd,
        'RDCYD'
      );
      getCatalogValueReducerHandle(
        CREDIT_TIME_CATALOG_ID,
        dispatch,
        setLoadingForm,
        0,
        types.getTdc,
        'TDC'
      );
    }
  }, [showAllValues]);

  useEffect(() => {
    getContactSupplier();
    if (supplierSelected) {
      form.setFieldsValue({
        SupplierId: supplierSelected.supplierId,
        // ContactId: null,
      });
    } else {
      form.setFieldsValue({
        SupplierId: null,
        // ContactId: null,
      });
    }
  }, [supplierSelected]);

  useEffect(() => {
    setFilterSupplier(rate);
    if (rate && rate.supplierId) {
      setFilterSupplier(rate);
      setTypeOfload(rate.typeOfLoad);
      const config = {
        method: METHOD_GET,
        url: `Supplier/${rate.supplierId}/Contacts`,
        data: null,
        token,
      };
      fetchApi(config)
        .then((response) => {
          if (response) {
            let supContacts = response.result;
            if (supContacts) {
              supContacts.map((user, index) => {
                user['key'] = index;
                user[
                  'label'
                ] = `${user.name} ${user.lastName} - ${user.rolesDescription}`;
                user['value'] = user.id;
              });
            }
            supContacts.sort((a, b) => a.label.localeCompare(b.label));
            setSupplierContacts(supContacts);
          }
          // setReloadContacts(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [rate]);

  const getContactSupplier = useCallback(() => {
    if (supplierSelected) {
      const config = {
        method: METHOD_GET,
        url: `Supplier/${supplierSelected.supplierId}/Contacts`,
        data: null,
        token,
      };
      fetchApi(config)
        .then((response) => {
          if (response) {
            let supContacts = response.result;

            if (supContacts) {
              supContacts.map((user, index) => {
                user['key'] = index;
                user[
                  'label'
                ] = `${user.name} ${user.lastName} - ${user.rolesDescription}`;
                user['value'] = user.id;
              });
            }
            supContacts.sort((a, b) => a.label.localeCompare(b.label));
            setSupplierContacts(supContacts);
          }
          // setReloadContacts(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setSupplierContacts([]);
    }
  }, [supplierSelected]);

  const showModalSupplier = () => {
    setTitleModal('Seleccionar proveedor.');
    setModalIsVisible(true);
    setContextModal(
      <SuppliersTable
        pickSupplier
        setSupplierSelected={setSupplierSelected}
        form={form}
        setModalIsVisible={setModalIsVisible}
        filterRate={filterSupplier}
        routeSelectedPricing={routeSelected}
        isRFQ={isRFQ}
        supplierActive={true}
      />
    );
  };

  const statusRateHandle = (value, dateString) => {
    if (value) {
      const today = moment();
      const startDate = value[0];
      const endDate = value[1];

      if (
        moment(startDate).isBefore(today, 'day') &&
        moment(endDate).isBefore(today, 'day')
      ) {
        setStatusRate('inactive');
      } else {
        if (moment(today, 'day').isBetween(startDate, endDate)) {
          setStatusRate('active');
        } else {
          if (
            moment(startDate).isSame(today, 'day') ||
            moment(endDate).isSame(today, 'day')
          ) {
            setStatusRate('active');
            return;
          }
          setStatusRate('pending');
        }
        // if (moment(endDate).isSame(today, 'day')) {
        //     console.log('hey');
        //     setStatusRate('pending');
        // } else {
        //     setStatusRate('active');
        // }
      }
    }
  };

  const onChangeSelect = (value, type) => {
    setFilterSupplier({
      ...filterSupplier,
      [type]: value,
    });
  };

  const cleanSupplier = (value, type) => {
    setSupplierSelected(null);
    setSupplierContacts([]);
    form.setFieldsValue({
      supplier: null,
      ContactId: null,
      SupplierId: null,
    });
  };
  return (
    <div className="basic-pricing-tab">
      <Row gutter={[16, 0]}>
        <Col xs={24} md={6} lg={6}>
          <Form.Item
            label="Id. tarifa:"
            name="RateId"
            rules={[
              {
                required: false,
                message: 'Por favor ingrese una ruta',
              },
            ]}
          >
            <Input disabled />
          </Form.Item>
          <Form.Item name="number" hidden>
            <Input disabled />
          </Form.Item>
        </Col>

        <Col xs={20} md={8} lg={8}>
          <Form.Item label="Vigencia:" name="validity" {...rangeConfig}>
            <RangePicker
              disabled={disableAllInputs}
              onChange={statusRateHandle}
            />
          </Form.Item>
        </Col>

        {!isRFQ && (
          <Col xs={12} md={4} lg={4}>
            <Form.Item
              label="Estatus:"
              name="IsCompleted"
              valuePropName="checked"
              style={{ marginBottom: 5, marginTop: 0 }}
              tooltip={{
                title: `Si se selecciona esta casilla ya no se podrá modificar después ${
                  isRFQ ? 'el RFQ' : 'la tarifa'
                }.`,
                icon: <InfoCircleOutlined />,
              }}
            >
              <Checkbox disabled={disableAllInputs}>Completa</Checkbox>
            </Form.Item>
          </Col>
        )}

        <Col xs={4} md={4} lg={4}>
          <Row justify="end">
            <Col
              xs={24}
              md={24}
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                disabled={disableAllInputs}
                className={`btn-vigencia ${
                  statusRate === 'active'
                    ? 'btn-vigencia-green'
                    : statusRate === 'inactive'
                    ? 'btn-vigencia-red'
                    : 'btn-vigencia-yellow'
                }`}
              >
                {statusRate === 'active'
                  ? 'Vigente'
                  : statusRate === 'inactive'
                  ? 'Vencido'
                  : ' Próxima'}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={10} md={4} lg={4}>
          <Tooltip title="Seleccionar un proveedor">
            <Button
              disabled={disableAllInputs}
              onClick={showModalSupplier}
              type="primary"
              icon={<UserSwitchOutlined />}
              style={{ marginTop: 30 }}
            >
              Proveedor
            </Button>
          </Tooltip>
        </Col>
        {isRFQ && (
          <Col xs={4} md={2} lg={2}>
            <Tooltip title="Limpiar proveedor">
              <Button
                type="danger"
                icon={<ClearOutlined />}
                style={{ marginTop: 30 }}
                onClick={cleanSupplier}
                disabled={disableAllInputs || supplierSelected === null}
              ></Button>
            </Tooltip>
          </Col>
        )}

        <Col xs={14} md={8} lg={10}>
          <Form.Item
            label="Nombre del proveedor:"
            name="supplier"
            rules={[
              {
                required: true,
                message: 'Por favor ingrese un proveedor',
              },
            ]}
          >
            <Input
              placeholder="Nombre del proveedor"
              disabled={
                !isRFQ || supplierSelected !== null || disableAllInputs
                  ? true
                  : false
              }
            />
          </Form.Item>

          <Form.Item
            label=""
            name="SupplierId"
            hidden
            rules={[
              {
                required: !isRFQ ? true : false,
                message: 'Por favor ingrese un proveedor',
              },
            ]}
          >
            <Input disabled placeholder="Nombre del proveedor" />
          </Form.Item>
        </Col>
        <Col xs={24} md={10} lg={8}>
          <Form.Item
            label="Contacto:"
            name="ContactId"
            rules={[
              {
                required: !isRFQ ? true : false,
                message: 'Por favor ingrese un contacto',
              },
            ]}
          >
            <Select
              disabled={
                (supplierSelected === null && isRFQ) || disableAllInputs
                  ? true
                  : false
              }
              placeholder="Selecciona una opción"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {supplierContacts.map((s) => (
                <Select.Option key={s.value} value={s.value}>
                  {s.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col xs={12} sm={12} lg={6}>
          <Form.Item
            label="Tipo de carga:"
            name="TypeOfLoad"
            rules={[
              {
                required: true,
                message: 'Por favor ingrese un tipo de carga',
              },
            ]}
          >
            <Select
              disabled={disableAllInputs || isRFQ ? true : false}
              placeholder="Selecciona una opción"
              // allowClear
              onChange={(value) => {
                setTypeOfload(value);
                onChangeSelect(value, 'typeOfLoad');
              }}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {catalogs.typeOfLoad.map((s) => (
                <Select.Option key={s.value} value={s.value}>
                  {s.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        {typeOfload === HAZMAT_VALUE_ID && (
          <Col xs={24} sm={12} lg={12}>
            <Row gutter={16}>
              <Col xs={12} md={12}>
                <Form.Item
                  label="HAZ MAT:"
                  name="HazMat"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor ingrese un HAZ MAT',
                    },
                  ]}
                >
                  <Select
                    disabled={disableAllInputs}
                    placeholder=""
                    showSearch
                    optionFilterProp="children"
                    onChange={(value) => {
                      onChangeSelect(value, 'hazMat');
                    }}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {catalogs.hazMat.map((s) => (
                      <Select.Option key={s.value} value={s.value}>
                        {s.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={12} md={12}>
                <Form.Item
                  label=" "
                  name="HazMatDescription"
                  rules={[
                    {
                      required: false,
                      message: 'Por favor ingrese un HAZ MAT',
                    },
                  ]}
                >
                  <Input
                    placeholder="Descripción"
                    disabled={disableAllInputs}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        )}
        <Col xs={12} sm={12} lg={6}>
          <Form.Item
            label="Operación:"
            name="Operation"
            // hidden={service !== TERRESTRE_VALUE_ID}
            rules={[
              {
                required: true,
                message: 'Por favor ingrese una operación',
              },
            ]}
          >
            <Select
              disabled={disableAllInputs}
              placeholder="Selecciona una opción"
              showSearch
              optionFilterProp="children"
              onChange={(value) => {
                onChangeSelect(value, 'operation');
              }}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {catalogs.operations.map((s) => (
                <Select.Option key={s.value} value={s.value}>
                  {s.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col xs={12} sm={12} lg={6}>
          <Form.Item
            label="Servicio:"
            name="service"
            rules={[
              {
                required: true,
                message: 'Por favor ingrese un servicio',
              },
            ]}
          >
            <Select
              disabled={disableAllInputs || rate ? true : false}
              placeholder="Selecciona una opción"
              onChange={(value) => {
                setService(value);
                onChangeSelect(value, 'service');
              }}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {serviceCatalog.map((s) => (
                <Select.Option key={s.value} value={s.value}>
                  {s.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={12} sm={12} lg={6}>
          <Form.Item
            label="Tipo servicio:"
            name="TypeOfService"
            rules={[
              {
                required: true,
                message: 'Por favor ingrese un tipo de servicio',
              },
            ]}
          >
            <Select
              disabled={disableAllInputs || rate ? true : false}
              placeholder="Selecciona una opción"
              showSearch
              optionFilterProp="children"
              onChange={(value) => {
                onChangeSelect(value, 'typeOfService');
              }}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {catalogs.typeOfService.map((s) => (
                <Select.Option key={s.value} value={s.value}>
                  {s.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={12} sm={12} lg={6}>
          {service !== TERRESTRE_VALUE_ID && (
            <Form.Item
              label="Unidad:"
              name="Unit"
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese unidad',
                },
              ]}
            >
              <Select
                disabled={disableAllInputs || isRFQ ? true : false}
                placeholder="Selecciona una opción"
                showSearch
                optionFilterProp="children"
                onChange={(value) => {
                  onChangeSelect(value, 'unit');
                }}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {catalogs.unit.map((s) => (
                  <Select.Option key={s.value} value={s.value}>
                    {s.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
          {/* PREGUNATR A JESUS */}
          {service === TERRESTRE_VALUE_ID && (
            <Form.Item
              label="Tipo de equipo:"
              name="EquipmentType"
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese un tipo de equipo',
                },
              ]}
            >
              <Select
                disabled={disableAllInputs || isRFQ ? true : false}
                placeholder="Selecciona una opción"
                showSearch
                optionFilterProp="children"
                onChange={(value) => {
                  onChangeSelect(value, 'equipmentType');
                }}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {catalogs.typeOfEquipment.map((s) => (
                  <Select.Option key={s.value} value={s.value}>
                    {s.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
        </Col>
        <Col xs={12} sm={12} lg={6}>
          <Form.Item label="Modalidad:" name="modalityId">
            <Select
              disabled={disableAllInputs}
              placeholder="Selecciona una opción"
              showSearch
              allowClear
              optionFilterProp="children"
              onChange={(value) => {
                onChangeSelect(value, 'modality');
              }}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {catalogs.modality.map((s) => (
                <Select.Option key={s.value} value={s.value}>
                  {s.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[16, 0]}>
        {/* {service === TERRESTRE_VALUE_ID && ( */}
        <Col xs={12} sm={12} md={8} lg={6}>
          <Form.Item
            label="Unidad de medida:"
            name="MeasurementUnit"
            rules={[
              {
                required: true,
                message: 'Por favor ingrese unidad de medida',
              },
            ]}
          >
            <Select
              disabled={disableAllInputs}
              placeholder="Unidad de medida"
              // style={{ width: 150 }}
              showSearch
              optionFilterProp="children"
              // onChange={(value) => {
              //    onChangeSelect(value, 'measurementUnit');
              // }}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {catalogs.measurementUnit.map((s) => (
                <Select.Option key={s.value} value={s.value}>
                  {s.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        {/* )} */}
        <Col xs={12} sm={12} md={8} lg={6}>
          <Form.Item
            label="Embalaje:"
            name="Packaging"
            rules={[
              {
                required: true,
                message: 'Por favor ingrese embalaje',
              },
            ]}
          >
            <Select
              disabled={disableAllInputs}
              placeholder="Selecciona una opción"
              showSearch
              // onChange={(value) => {
              //    onChangeSelect(value, 'packaging');
              // }}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {catalogs.packaging.map((s) => (
                <Select.Option key={s.value} value={s.value}>
                  {s.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        {service === TERRESTRE_VALUE_ID && (
          <Col xs={12} sm={12} md={8} lg={6}>
            <Form.Item
              label="Precio unitario:"
              name="UnitPrice"
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese un costo',
                  type: 'number',
                },
              ]}
            >
              <InputNumber
                disabled={disableAllInputs}
                placeholder="Ingresa un costo"
                style={{ width: '100%' }}
                min={0}
                formatter={(value) =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              />
            </Form.Item>
          </Col>
        )}
        {service !== TERRESTRE_VALUE_ID && (
          <Col xs={12} sm={12} md={8} lg={6}>
            <Form.Item
              label="Costo:"
              name="cost"
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese un costo',
                },
              ]}
            >
              <InputNumber
                disabled={disableAllInputs}
                placeholder="Ingresa un costo"
                style={{ width: '100%' }}
                min={0}
                formatter={(value) =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              />
            </Form.Item>
          </Col>
        )}
        {/* {service !== AEREO_VALUE_ID && ( */}
        <Col xs={12} sm={12} md={8} lg={6}>
          <Form.Item
            label="Moneda:"
            name="Currency"
            rules={[
              {
                required: true,
                message: 'Por favor ingrese una moneda',
              },
            ]}
          >
            <Select
              disabled={disableAllInputs}
              placeholder="Selecciona una opción"
              showSearch
              onChange={(value) => {
                onChangeSelect(value, 'currency');
              }}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {catalogs.currency.map((s) => (
                <Select.Option key={s.value} value={s.value}>
                  {s.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        {/* )} */}
      </Row>
      <Row gutter={16}>
                {/* //nuevos catalogos */}
                <Col xs={24} md={6}>
                    <Form.Item label="Requisito de carga:" name="loadUnloadRequirementIds">
                      <Select
                        disabled={disableAllInputs || isRFQ ? true : false}
                        placeholder="Selecciona"
                        showSearch
                        allowClear
                        mode='multiple'
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {catalogs.rdcyd.map((s) => (
                          <Select.Option key={s.value} value={s.value}>
                            {s.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                      <Form.Item
                        label="Tiempo de carga/descarga:"
                        name="loadUnloadTime"
                        tooltip={{
                          title:
                            'Tiempo en horas.',
                          icon: <InfoCircleOutlined />,
                        }}
                      >
                        <InputNumber
                          disabled={disableAllInputs || isRFQ ? true : false}
                          placeholder="Tiempo en horas"
                          style={{ width: '100%' }}
                          min={0}
                          formatter={(value) =>
                            ` ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                          }
                          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        />
                      </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                      <Form.Item
                        label="Volumen de carga:"
                        name="cargoVolume"
                      >
                        <InputNumber
                         disabled={disableAllInputs || isRFQ ? true : false}
                         min={0}
                         style={{ width: '100%' }}
                          formatter={(value) =>
                            ` ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                          }
                          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        />
                      </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                    <Form.Item label="Tiempo de crédito:" name="creditTimeId">
                      <Select
                        disabled={disableAllInputs || isRFQ ? true : false}
                        placeholder="Selecciona una opción"
                        showSearch
                        allowClear
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {catalogs.tdc.map((s) => (
                          <Select.Option key={s.value} value={s.value}>
                            {s.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                    <Form.Item label="Tipo de evidencia:" name="evidence">
                    <Select
                    disabled={disableAllInputs || isRFQ ? true : false}
                    allowClear
                    style={{ width: '100%', marginBottom: 20 }}
                    placeholder="Seleccione una opción"
                  >
                    <Option value={0}>
                      Físico
                    </Option>
                    <Option value={1}>
                      Virtual
                    </Option>
                    <Option value={2}>
                      Ambas
                    </Option>
                  </Select>
                    </Form.Item>
                </Col>
              </Row>
      <Row>
        <Col xs={24} md={12}>
          <Form.Item
            label="Comentarios:"
            name="Comments"
            rules={[
              {
                required: false,
                message: 'Por favor ingrese un costo',
              },
            ]}
          >
            <Input.TextArea
              disabled={disableAllInputs}
              showCount
              maxLength={300}
            />
          </Form.Item>
        </Col>

        <Col xs={12} push={6} md={6} lg={6}>
          <Form.Item
            label="Usuario:"
            hidden
            name="user"
            // rules={[
            //     {
            //         required: false,
            //         message: 'Por favor ingrese un ',
            //     },
            // ]}
          >
            <Input disabled />
          </Form.Item>
        </Col>
      </Row>
      <Modal
        title={titleModal}
        visible={modalIsVisible}
        setIsVisible={setModalIsVisible}
        width={800}
      >
        {contextModal}
      </Modal>
    </div>
  );
};

export default BasicPricingTab;
