import React, { useEffect, useState } from 'react';
import { PDFViewer, pdf } from '@react-pdf/renderer';
import { useParams, useHistory } from 'react-router-dom';
import { Button, notification, Row, Spin } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { QuotePdf } from './QuotePdf';
import useUser from '../../../../hooks/useUser';
import { METHOD_GET, METHOD_POST } from '../../../../utils/constants';
import {
  API_SEND_QUOTE_EMAIL,
  API_URL_QUOTE,
  API_URL_QUOTE_PDF,
} from '../../../../helpers/urls';
import { getAccessTokenApi } from '../../../../api/auth';
import { fetchApi } from '../../../../helpers/fetch';
import BackButton from '../../../General/BackButton/BackButton';

const ViewPdf = () => {
  const { quoteId } = useParams();
  const { person } = useUser();
  const token = getAccessTokenApi();
  const [quotePdf, setQuotePdf] = useState(null);
  const [loadingForm, setLoadingForm] = useState(false);
  const [loadingPdf, setLoadingPdf] = useState(true);
  const [buttonVisible, setButtonVisible] = useState(false);
  let history = useHistory();

  useEffect(() => {
    const generatePdfHandle = async (id) => {
      const config = {
        method: METHOD_GET,
        url: `${API_URL_QUOTE_PDF(id)}`,
        data: null,
        token,
        // showNotification: true,
      };
      const response = await fetchApi(config);
      if (response) {
        if (response?.statusCode === 200) {
          setQuotePdf(response.result);
        }
        setLoadingPdf(false);
      }
    };
    generatePdfHandle(quoteId);
  }, [quoteId, token]);
  useEffect(() => {
    if (quoteId) {
      setLoadingForm(true);
      const config = {
        method: METHOD_GET,
        url: `${API_URL_QUOTE}/${quoteId}`,
        data: null,
        token,
        showNotificationError: true,
      };
      fetchApi(config)
        .then((response) => {
          if (response.statusCode === 200) {
            if (response.result.quote.isCompleted === true) {
              setButtonVisible(true);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
      setLoadingForm(false);
    }
  }, [quoteId, token]);

  function blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  const sendPdfToClient = async (quoteIdPdf) => {
    setLoadingForm(true);
    const blob = await pdf(
      <QuotePdf user={person} quote={quotePdf} />
    ).toBlob();
    const result = await blobToBase64(blob);
    let posData64 = result.indexOf(',') + 1;

    const data = {
      file: result.substr(posData64),
      id: quoteIdPdf,
    };

    const config = {
      method: METHOD_POST,
      url: `${API_SEND_QUOTE_EMAIL}`,
      data,
      token,
      // showNotification: true,
    };
    const response = await fetchApi(config);

    if (response?.statusCode === 200) {
      notification['success']({
        message: 'Cotización enviada al cliente exitosamente',
      });
    } else {
      notification['error']({
        message: 'Ocurrió un error al enviar el correo electrónico.',
        description: 'Inténtelo mas tarde.',
      });
    }
    setLoadingForm(false);
  };

  return (
    <>
      {/* <HeaderTop /> */}

      <Row style={{ marginBottom: 40 }}>
        <BackButton routeName={'/cotizacion'}></BackButton>
      </Row>

      {/* <Button
        // disabled={quoteIdPdf ? false : true}
        loading={loadingForm}
        disabled={!buttonVisible}
        className="btn-enviar"
        style={{ width: 160 }}
        block
        onClick={() => sendPdfToClient(quotePdf.id)}
        icon={<SendOutlined />}
      >
        {'Enviar cotización'}
      </Button> */}
      {(!quotePdf || !person) && (
        <Spin spinning={loadingPdf} tip="Cargando PDF..."></Spin>
      )}

      {quotePdf && person && (
        <PDFViewer width={'100%'} height={'100%'}>
          <QuotePdf quote={quotePdf} />
        </PDFViewer>
      )}
    </>
  );
};

export default ViewPdf;

// function HeaderTop() {
//   const { Title } = Typography;
//   let history = useHistory();
//   let { quoteId } = useParams();

//   return (
//     <div className='clients__container'>
//       <Row xs={23} className='header-top'>
//         <Col xs={19} className='header-top__left'>
//           <Title level={3}>Lineas Cotización {quoteId}</Title>
//         </Col>
//         <Col xs={1}>
//           <Button type='link' onClick={() => history.push('/cotizacion')}>
//             <ArrowLeftOutlined /> Atrás
//           </Button>
//         </Col>
//       </Row>
//     </div>
//   );
// }
