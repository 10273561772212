import {
  Col,
  Row,
  Form,
  Modal as ModalAntd,
  Input,
  Button,
  notification,
  InputNumber,
  Select,
  Typography,
  Tag,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { METHOD_GET } from '../../../../../../utils/constants';
import { PACKAGING_UNIT_CATALOG_ID } from '../../../../../../helpers/constants';
import { API_URL_CATALOG_VALUES } from '../../../../../../helpers/urls';
import { getAccessTokenApi } from '../../../../../../api/auth';
import { fetchApi } from '../../../../../../helpers/fetch';

const AddDimensionsOperation = ({
  setModalIsVisible,
  setPieces,
  pieces,
  lines,
  setLines,
  type,
  dimensionRow,
  line,
  saveKey,
  lineId,
  keySelected,
}) => {
  const [partsForm] = Form.useForm();
  const { confirm } = ModalAntd;
  const [editDimensionsForm, setEditDimensionsForm] = useState([]);
  const [loadingForm, setLoadingForm] = useState(false);
  const [packagingCatalog, setPackagingCatalog] = useState([]);
  const [packagingLabel, setPackagingLabel] = useState('');
  const [cbm, setCbm] = useState(0);
  const [volumen, setVolumen] = useState(0);
  const [lengthPiece, setLengthPiece] = useState(
    dimensionRow !== undefined ? dimensionRow.length : 1
  );
  const [heightPiece, setHeightPiece] = useState(
    dimensionRow !== undefined ? dimensionRow.height : 1
  );
  const [widthPiece, setWidthPiece] = useState(
    dimensionRow !== undefined ? dimensionRow.width : 1
  );
  const token = getAccessTokenApi();
  const { Text } = Typography;
  useEffect(() => {
    let volumenCount = lengthPiece * heightPiece * widthPiece;
    setVolumen(volumenCount);
  }, [lengthPiece, heightPiece, widthPiece]);

  useEffect(() => {
    if (type === 'Edit') {
      setEditDimensionsForm([
        {
          name: ['pieces'],
          value: dimensionRow.pieces,
        },
        {
          name: ['packaging'],
          value: dimensionRow.packaging,
        },
        {
          name: ['length'],
          value: dimensionRow.length,
        },
        {
          name: ['width'],
          value: dimensionRow.width,
        },
        {
          name: ['height'],
          value: dimensionRow.height,
        },
        {
          name: ['weight'],
          value: dimensionRow.weight,
        },
        {
          name: ['cbm'],
          value: dimensionRow.cbm,
        },
        {
          name: ['description'],
          value: dimensionRow.description,
        },
        {
          name: ['volume'],
          value: dimensionRow.volume,
        },
        {
          name: ['netWeight'],
          value: dimensionRow.netWeight,
        },
        {
          name: ['tareWeight'],
          value: dimensionRow.tareWeight,
        },
        {
          name: ['grossWeight'],
          value: dimensionRow.grossWeight,
        },
      ]);
      setCbm(dimensionRow.cbm);
      setVolumen(dimensionRow.volume);
    }
    // return () => {
    //     cleanup
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dimensionRow]);

  useEffect(() => {
    setLoadingForm(true);
    const config = {
      method: METHOD_GET,
      url: `${API_URL_CATALOG_VALUES}/GetAll/${PACKAGING_UNIT_CATALOG_ID}`,
      data: null,
      token,
      // showNotification: true
    };
    fetchApi(config)
      .then((response) => {
        if (response) {
          let calogsValues = response.result;

          if (calogsValues) {
            calogsValues.map((cat, index) => {
              cat['key'] = index;
              cat['label'] = cat.description;
              cat['value'] = cat.id;
            });
          }
          setPackagingCatalog(calogsValues);
        }
        setLoadingForm(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [token]);

  const handleSaveDimensions = (values) => {
    confirm({
      title: 'Guardar dimensiones',
      icon: <ExclamationCircleOutlined />,
      content: '¿Estás seguro que deseas guardar dimensiones?',
      okText: 'Si',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        addDimensionsFunction(values);
      },
      onCancel() {},
    });
  };

  const addDimensionsFunction = React.useCallback(
    async (values) => {
      values['volume'] = volumen;
      values['packagingLabel'] = packagingLabel;
      const parsekey = parseInt(keySelected);
      if (type === 'Add') {
        if (saveKey === 'single') {
          values['key'] = pieces.length;
          setPieces([...pieces, values]);
          setModalIsVisible(false);
        } else {
          if (pieces !== undefined) {
            // values['quoteLineId'] = line.id;
            let copy = [...pieces, values];
            setPieces(copy);
            const linesDataCopy = [...lines];
            linesDataCopy[parsekey].releases = copy;
            setLines(linesDataCopy);
            setModalIsVisible(false);
          }
        }
        notification['success']({
          message: `Dimensiones agregadas exitosamente `,
        });
      } else {
        let copy = [...pieces];
        let i = copy.findIndex((_, i) => i === dimensionRow.key);
        copy[i] = { ...values };
        copy[i]['quoteLineId'] = line.id;
        if (copy[i].packagingLabel === '') {
          copy[i]['packagingLabel'] = pieces[i].packagingLabel;
        }
        setPieces(copy);
        if (saveKey === 'multiple') {
          const linesDataCopy = [...lines];
          linesDataCopy[parsekey].releases = copy;
          setLines(linesDataCopy);
        }
        notification['success']({
          message: `Dimensiones editadas exitosamente `,
        });
        setModalIsVisible(false);
      }
    },
    [
      dimensionRow,
      keySelected,
      line,
      lines,
      packagingLabel,
      pieces,
      saveKey,
      setLines,
      setModalIsVisible,
      setPieces,
      type,
      volumen,
    ]
  );
  return (
    <div>
      <Form
        name="add-line"
        layout="vertical"
        form={partsForm}
        onFinish={handleSaveDimensions}
        fields={editDimensionsForm}
      >
        <Row gutter={16}>
          <Col md={6} xs={24}>
            <Form.Item
              label="Piezas:"
              name="pieces"
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese numero de piezas',
                },
              ]}
            >
              <InputNumber
                style={{ width: '100%' }}
                min={0}
                placeholder="Ingrese información"
              />
            </Form.Item>
          </Col>
          <Col md={6} xs={24}>
            <Form.Item
              label="Alto(mts):"
              name="height"
              initialValue={1}
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese alto',
                },
              ]}
            >
              <InputNumber
                style={{ width: '100%' }}
                min={0}
                placeholder="Ingrese información"
                onChange={(e) => setHeightPiece(e)}
              />
            </Form.Item>
          </Col>

          <Col md={6} xs={24}>
            <Form.Item
              label="Largo(mts):"
              name="length"
              initialValue={1}
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese largo',
                },
              ]}
            >
              <InputNumber
                style={{ width: '100%' }}
                min={0}
                placeholder="Ingrese información"
                onChange={(e) => setLengthPiece(e)}
              />
            </Form.Item>
          </Col>
          <Col md={6} xs={24}>
            <Form.Item
              label="Ancho(mts):"
              name="width"
              initialValue={1}
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese ancho',
                },
              ]}
            >
              <InputNumber
                style={{ width: '100%' }}
                min={0}
                placeholder="Ingrese información"
                onChange={(e) => setWidthPiece(e)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col md={6} xs={24} style={{ marginBottom: 20 }}>
            <p style={{ marginBottom: 7 }}>Volumen:</p>
            <Tag color="blue" style={{ padding: 5, width: '100%' }}>
              <Text className="text-label">{volumen}</Text>
            </Tag>
          </Col>
          {/* <Col md={6} xs={24}>
            <Form.Item
              label='Peso(kg):'
              name='weight'
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese peso',
                },
              ]}
            >
              <InputNumber
                style={{ width: '100%' }}
                min={0}
                placeholder='Ingrese información'
              />
            </Form.Item>
          </Col> */}
          <Col md={6} xs={24}>
            <Form.Item
              label="Peso Neto(kg):"
              name="netWeight"
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese peso Neto(kg)',
                },
              ]}
            >
              <InputNumber
                style={{ width: '100%' }}
                min={0}
                placeholder="Ingrese información"
              />
            </Form.Item>
          </Col>
          <Col md={6} xs={24}>
            <Form.Item
              label="Peso Tara(kg):"
              name="tareWeight"
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese peso Tara(kg)',
                },
              ]}
            >
              <InputNumber
                style={{ width: '100%' }}
                min={0}
                placeholder="Ingrese información"
              />
            </Form.Item>
          </Col>
          <Col md={6} xs={24}>
            <Form.Item
              label="Peso Bruto(kg):"
              name="grossWeight"
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese peso Bruto(kg)',
                },
              ]}
            >
              <InputNumber
                style={{ width: '100%' }}
                min={0}
                placeholder="Ingrese información"
              />
            </Form.Item>
          </Col>
          {/* <Col md={6} xs={24} style={{marginBottom: 20}}>
          <p style={{ marginBottom: 7 }}>CBM:</p>
          <Tag color='blue' style={{ padding: 5, width: '100%' }}>
                    <Text className='text-label'>
                      {cbm}
                    </Text>
          </Tag>
          </Col> */}
        </Row>
        <Row gutter={16}>
          <Col md={6} xs={24}>
            <Form.Item label="Embalaje:" name="packaging">
              <Select
                placeholder="Pallet"
                onChange={(value, label) => {
                  setPackagingLabel(label.children);
                }}
              >
                {packagingCatalog.map((s) => (
                  <Select.Option key={s.value} value={s.value}>
                    {s.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col md={18} xs={24}>
            <Form.Item label="Descripción:" name="description">
              <Input placeholder="Ingrese información" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col
            span={24}
            style={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <Form.Item>
              <Button htmlType="submit" className="btn-enviar" block>
                Guardar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default AddDimensionsOperation;
