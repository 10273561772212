/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Form, Input, Button, Spin, Row } from 'antd';
// import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { METHOD_POST } from '../../../../../../utils/constants';
import { fetchApi } from '../../../../../../helpers/fetch';
import { getAccessTokenApi } from '../../../../../../api/auth';
import { API_URL_ADDITIONAL_SERVICE } from '../../../../../../helpers/urls';

// import './AddValuesForm.scss';

const AddValuesASForm = ({ setReloadCatalogValues, setModalIsVisible }) => {
  const [loading, setLoading] = useState(false);
  const token = getAccessTokenApi();
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    setLoading(true);

    const config = {
      method: METHOD_POST,
      url: `${API_URL_ADDITIONAL_SERVICE}`,
      data: values,
      token,
      showNotification: true,
    };
    const result = await fetchApi(config);

    if (result) {
      if (result.statusCode === 200) {
        // form.resetFields();
        setModalIsVisible(false);
        setReloadCatalogValues(true);
      }
    }

    setLoading(false);
  };

  return (
    <div className="add-catalog-form">
      <Spin spinning={loading} tip="Cargando...">
        <Form
          name="AddValueAdditionalService"
          form={form}
          onFinish={onFinish}
          layout="vertical"
        >
          <Form.Item
            label="Nombre"
            name="name"
            rules={[{ required: true, message: 'Por favor ingresa un nombre' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Código"
            name="Code"
            rules={[
              { required: false, message: 'Por favor ingresa un código' },
            ]}
          >
            <Input />
          </Form.Item>

          <Row justify="end">
            <Form.Item>
              <Button className="btn-enviar" htmlType="submit">
                Guardar
              </Button>
            </Form.Item>
          </Row>
        </Form>
      </Spin>
    </div>
  );
};

export default AddValuesASForm;
