/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Row, Form, Select, Col, Divider } from 'antd';
import { METHOD_GET } from '../../../../utils/constants';
import { API_URL_CATALOG_VALUES } from '../../../../helpers/urls';
import {
  CITY_CATALOG_ID,
  STATE_CATALOG_ID,
} from '../../../../helpers/constants';
import { getAccessTokenApi } from '../../../../api/auth';
import { fetchApi } from '../../../../helpers/fetch';

const RouteTab = (props) => {
  const {
    type,
    statusValue
  } = props;
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [statesDestination, setStatesDestination] = useState([]);
  const [citiesDestination, setCitiesDestination] = useState([]);
  const token = getAccessTokenApi();

  useEffect(() => {
    const config = {
      method: METHOD_GET,
      url: `${API_URL_CATALOG_VALUES}/GetAll/${STATE_CATALOG_ID}`,
      data: null,
      token,
    };
    fetchApi(config)
      .then((response) => {
        if (response) {
          let calogsValues = response.result;

          if (calogsValues) {
            calogsValues.map((cat, index) => {
              cat['key'] = index;
              cat['label'] = cat.description;
              cat['value'] = cat.id;
              return cat;
            });
          }
          setStates(calogsValues);
          setStatesDestination(calogsValues);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    const config = {
      method: METHOD_GET,
      url: `${API_URL_CATALOG_VALUES}/GetAll/${CITY_CATALOG_ID}`,
      data: null,
      token,
    };
    fetchApi(config)
      .then((response) => {
        if (response) {
          let calogsValues = response.result;

          if (calogsValues) {
            calogsValues.map((cat, index) => {
              cat['key'] = index;
              cat['label'] = cat.description;
              cat['value'] = cat.id;
              return cat;
            });
          }
          setCities(calogsValues);
          setCitiesDestination(calogsValues);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="rute-tab">

      <Divider style={{ marginTop: 0, marginBottom: 0 }}>Ubicación</Divider>

      <Row gutter={16}>
        {/* <Col xs={12} sm={12} lg={6}>
          <Form.Item
            label="País:"
            name="originCountry"
            rules={[
              {
                required: true,
                message: 'Por favor ingrese un país',
              },
            ]}
          >
            <Select
              disabled={type === 'watch'}
              placeholder="Selecciona una opción"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {countries.map((s) => (
                <Select.Option key={s.value} value={s.value}>
                  {s.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col> */}
        <Col xs={12} sm={12} lg={12}>
          <Form.Item
            label="Estado:"
            name="locationStateId"
            rules={[
              {
                required: true,
                message: 'Por favor ingrese un estado',
              },
            ]}
          >
            <Select
              disabled={type === 'watch'}
              placeholder="Selecciona una opción"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {states.map((s) => (
                <Select.Option key={s.value} value={s.value}>
                  {s.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={12} sm={12} lg={12}>
          <Form.Item
            label="Ciudad:"
            name="locationCityId"
            rules={[
              {
                required: true,
                message: 'Por favor ingrese una ciudad',
              },
            ]}
          >
            <Select
              disabled={type === 'watch'}
              placeholder="Selecciona una opción"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {cities.map((s) => (
                <Select.Option key={s.value} value={s.value}>
                  {s.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Divider style={{ marginTop: 0, marginBottom: 0 }}>Destino</Divider>

      <Row gutter={[16, 16]}>
        <Col xs={12} sm={12} lg={12}>
          <Form.Item
            label="Estado:"
            name="destinationStateId"
            rules={[
              {
                required: true,
                message: 'Por favor ingrese un estado',
              },
            ]}
          >
            <Select
              disabled={type === 'watch'}
              placeholder="Selecciona una opción"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {statesDestination.map((s) => (
                <Select.Option key={s.value} value={s.value}>
                  {s.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={12} sm={12} lg={12}>
          <Form.Item
            label="Ciudad:"
            name="destinationCityId"
            rules={[
              {
                required: true,
                message: 'Por favor ingrese una ciudad',
              },
            ]}
          >
            <Select
              disabled={type === 'watch'}
              placeholder="Selecciona una opción"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {citiesDestination.map((s) => (
                <Select.Option key={s.value} value={s.value}>
                  {s.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default RouteTab;
