/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useReducer, useState } from 'react';
import {
  Col,
  Row,
  Form,
  InputNumber,
  Button,
  Select,
  Divider,
  Input,
  Modal as ModalAntd,
  Tag,
} from 'antd';
import {
  PlusOutlined,
  FileAddOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { getCatalogValueReducerHandle } from '../../../../../../helpers/handlerFunctionApi';
import {
  TYPE_OF_EQUIPMENT_CATALOG_ID,
  CONTAINER_CATALOG_ID,
  UNIT_TYPE_CATALOG_ID,
  MODALITY_CATALOG_ID,
  AEREO_VALUE_ID,
  TERRESTRE_VALUE_ID,
} from '../../../../../../helpers/constants';
import { types } from '../../../../../../types/types';
import { catalogQouteReducer } from '../../../../../../reducers/catalogsQoute/catalogQouteReducer';
import Modal from '../../../../../General/Modal';
import DimensionsOperationTable from '../DimensionsOperationTable/DimensionsOperationTable';
import AddAdditionalService from '../AddAdditionalService/AddAdditionalService';
import FilterAdditionalServices from '../../../../Quote/AddQuoteForm/FilterAdditionalServices';
import Text from 'antd/lib/typography/Text';

const initialStateQT = {
  typeOfLoad: [],
  operations: [],
  hazMat: [],
  typeOfService: [],
  typeOfEquipment: [],
  unit: [],
  measurementUnit: [],
  currency: [],
  packaging: [],
  frecuency: [],
  container: [],
  modality: [],
};

const LinesDetail = (props) => {
  const {
    service,
    type,
    lines,
    setLines,
    quoteSelected,
    linesDetailForm,
    clearHeader,
    setAdditionalServices,
    additionalServices,
    tabKey,
    onChangeLineSelected,
    disableAllInputs,
    lineSelected,
    keySelected,
    handleLineSave,
    pieces,
    setPieces,
    totalNetWeight,
    totalTareWeight,
    totalGrossWeight,
    volume,
    deleteLine,
    loadingForm,
    setLoadingForm,
    typeWatch,
    operationId,
    delivered,
  } = props;
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [titleModal, setTitleModal] = useState(false);
  const [contextModal, setContextModal] = useState(null);
  const [catalogs, dispatch] = useReducer(catalogQouteReducer, initialStateQT);
  const [filterSupplier, setFilterSupplier] = useState({});
  const [line, setLine] = useState([]);
  const { confirm } = ModalAntd;
  const [disableInputs, setDisableInputs] = useState(
    typeWatch === 'watch' ? true : false
  );
  useEffect(() => {
    getCatalogValueReducerHandle(
      TYPE_OF_EQUIPMENT_CATALOG_ID,
      dispatch,
      setLoadingForm,
      0,
      types.getEquipmentType
    );
    getCatalogValueReducerHandle(
      CONTAINER_CATALOG_ID,
      dispatch,
      setLoadingForm,
      0,
      types.getContainer
    );
    getCatalogValueReducerHandle(
      UNIT_TYPE_CATALOG_ID,
      dispatch,
      setLoadingForm,
      0,
      types.getUnity
    );
    getCatalogValueReducerHandle(
      MODALITY_CATALOG_ID,
      dispatch,
      setLoadingForm,
      0,
      types.getModality
    );
  }, []);

  useEffect(() => {
    let num = Math.max.apply(
      Math,
      lines.map((line) => {
        return line.number;
      })
    );
    linesDetailForm.setFieldsValue({
      number: num + 1,
    });
  }, [lines]);

  const onChangeSelect = (value, type) => {
    setFilterSupplier({
      ...filterSupplier,
      [type]: value,
    });
  };

  const showModal = (type, asSelected) => {
    setModalIsVisible(true);
    setTitleModal('Agregar servicios adicioanales');
    setContextModal(
      <FilterAdditionalServices
        setModalIsVisible={setModalIsVisible}
        showModal={showModal}
      />
    );
    if (type === 'Services') {
      setTitleModal('Agregar servicio adicional');
      setContextModal(
        <AddAdditionalService
          setModalIsVisible={setModalIsVisible}
          asSelected={asSelected}
          additionalServices={additionalServices}
          setAdditionalServices={setAdditionalServices}
          lineId={lineSelected}
          keySelected={keySelected}
          disableInputs={disableInputs}
        />
      );
      setModalIsVisible(true);
    }
  };

  const handleDeleteLine = (line) => {
    confirm({
      title: 'Eliminar línea',
      icon: <ExclamationCircleOutlined />,
      content: `¿Estás seguro que deseas eliminar línea ${line}?`,
      okText: 'Si',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteLine(line);
        // if (lines.length === 1) {
        //   linesForm.resetFields();
        //   setPieces([]);
        // }
      },
      onCancel() {},
    });
  };
  const popupHawb = (id, name) => {
    confirm({
      title: 'Documento HAWB',
      icon: <ExclamationCircleOutlined />,
      content: `Al finalizar, por favor proceda a agregar documento HAWB y asignarlo a la linea en la tabla de documentos de la operación.`,
      okText: 'Aceptar',
      okType: 'primary',
      cancelButtonProps: { hidden: true },
      onOk() {},
    });
  };
  return (
    <Form
      name="add-quote"
      layout="vertical"
      form={linesDetailForm}
      onFinish={handleLineSave}
      scrollToFirstError
      // onFinishFailed={validationOperationField}
    >
      <div>
        <Row gutter={16}>
          <Col xs={12} sm={12} md={3}>
            <Form.Item label="Línea:" name="number" hidden={tabKey === 1}>
              <InputNumber style={{ width: '100%' }} disabled />
            </Form.Item>
            <Form.Item name="id" hidden>
              <InputNumber disabled />
            </Form.Item>
            {tabKey === 1 && (
              <Form.Item label="Lineas:" name="linesId">
                <Select
                  disabled={lines.length === 0}
                  placeholder="Seleccione"
                  onChange={(e) => onChangeLineSelected(e)}
                  // showSearch
                  optionFilterProp="children"
                >
                  {lines.map((l, index) => (
                    <Select.Option key={index} value={[l.number, index]}>
                      {l.number}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          </Col>
          {service === TERRESTRE_VALUE_ID && (
            <Col xs={12} sm={12} md={6}>
              <Form.Item
                label="BOL:"
                name="bol"
                rules={[
                  {
                    required: true,
                    message: 'Por favor ingrese el campo BOL',
                  },
                ]}
              >
                <Input
                  placeholder="1"
                  disabled={disableAllInputs || disableInputs || delivered}
                />
              </Form.Item>
            </Col>
          )}
          {service === AEREO_VALUE_ID && (
            <Col md={6} xs={24}>
              <Form.Item
                label="HAWB:"
                name="hawb"
                rules={[
                  {
                    required: true,
                    message: 'Por favor ingrese HAWB',
                  },
                ]}
              >
                <Input
                  placeholder="Ingrese información"
                  onBlur={popupHawb}
                  disabled={disableAllInputs || disableInputs || delivered}
                />
              </Form.Item>
            </Col>
          )}
          {service === TERRESTRE_VALUE_ID && (
            <Col xs={12} sm={12} md={6}>
              <Form.Item label="Modalidad:" name="modalityId">
                <Select
                  placeholder="Seleccione"
                  // onChange={(value) => setTypeOfload(value)}
                  disabled={disableAllInputs || disableInputs || delivered}
                  onChange={(value) => {
                    onChangeSelect(value, 'modality');
                  }}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {catalogs.modality.map((s) => (
                    <Select.Option key={s.value} value={s.value}>
                      {s.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
          {tabKey === 1 && (
            <>
              <Col xs={24} sm={2} md={2} lg={2} flex="end">
                <Button
                  style={{ marginLeft: 0, marginBottom: 10, width: 40 }}
                  className="btn-delete"
                  icon={<DeleteOutlined />}
                  disabled={disableAllInputs || disableInputs || delivered}
                  onClick={() => handleDeleteLine(lineSelected)}
                ></Button>
              </Col>
              <Col xs={24} sm={12} md={6} flex="end">
                <Button
                  style={{ marginLeft: 0, marginBottom: 10, width: 190 }}
                  className="btn-download"
                  icon={<PlusOutlined />}
                  onClick={() => showModal('AddService')}
                  disabled={disableAllInputs || disableInputs || delivered}
                >
                  Servicios adicionales
                </Button>
              </Col>
            </>
          )}
        </Row>
        <Row gutter={16}>
          <Col xs={12} md={6}>
            <Form.Item
              label="Orden de compra:"
              name="purchaseOrder"
              // rules={[
              //   {
              //     required: true,
              //     message: 'Por favor ingrese una orden de compra',
              //   },
              // ]}
            >
              <Input
                disabled={disableAllInputs || disableInputs || delivered}
              />
            </Form.Item>
          </Col>
          {service === TERRESTRE_VALUE_ID && (
            <Col xs={12} md={6}>
              <Form.Item
                label="Tipo de equipo:"
                name="equipmentType"
                rules={[
                  {
                    required: true,
                    message: 'Por favor ingrese tipo de equipo',
                  },
                ]}
              >
                <Select
                  placeholder="Seleccione"
                  // onChange={(value) => setTypeOfload(value)}
                  disabled={disableAllInputs || disableInputs || delivered}
                  onChange={(value) => {
                    onChangeSelect(value, 'unit');
                  }}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {catalogs.unit.map((s) => (
                    <Select.Option key={s.value} value={s.value}>
                      {s.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
          {service !== AEREO_VALUE_ID && (
            <>
              <Col xs={24} md={6} flex="end">
                <Form.Item
                  label="Contenedor:"
                  name="container"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: 'Por favor ingrese contenedor ',
                  //   },
                  // ]}
                >
                  <Select
                    placeholder="Seleccione"
                    // onChange={(value) => setTypeOfload(value)}
                    disabled={disableAllInputs || disableInputs || delivered}
                    onChange={(value) => {
                      onChangeSelect(value, 'typeOfEquipment');
                    }}
                    allowClear
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {catalogs.container.map((s) => (
                      <Select.Option key={s.value} value={s.value}>
                        {s.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={12} md={6}>
                <Form.Item
                  label="No. de Contenedor o caja:"
                  name="containerNum"
                >
                  <Input
                    disabled={disableAllInputs || disableInputs || delivered}
                  />
                </Form.Item>
              </Col>
            </>
          )}
          <Col xs={12} md={6}>
            <Form.Item
              label="Pedimento:"
              name="petition"
              rules={[
                {
                  required:
                    quoteSelected?.destinationCountry !==
                    quoteSelected?.originCountry
                      ? true
                      : false,
                  message: 'Por favor ingrese pedimento ',
                },
              ]}
            >
              <InputNumber
                maxLength={15}
                minLength={15}
                style={{ width: '100%' }}
                disabled={disableAllInputs || disableInputs || delivered}
              />
            </Form.Item>
          </Col>

          <Col xs={12} md={6}>
            <Form.Item label="Marca:" name="mark">
              <Input
                disabled={disableAllInputs || disableInputs || delivered}
              />
            </Form.Item>
          </Col>
          {service !== AEREO_VALUE_ID && (
            <>
              <Col xs={12} md={6}>
                <Form.Item label="Sello:" name="stamp">
                  <Input
                    disabled={disableAllInputs || disableInputs || delivered}
                  />
                </Form.Item>
              </Col>
              {service === TERRESTRE_VALUE_ID && (
                <Col xs={12} md={6}>
                  <Form.Item label="Placa:" name="plate">
                    <Input
                      disabled={disableAllInputs || disableInputs || delivered}
                    />
                  </Form.Item>
                </Col>
              )}
            </>
          )}
        </Row>
        <Divider>Releases</Divider>
        <DimensionsOperationTable
          type={type}
          lines={lines}
          setLines={setLines}
          line={line}
          pieces={pieces}
          setPieces={setPieces}
          lineId={lineSelected}
          keySelected={keySelected}
          disableAllInputs={disableAllInputs}
          clearHeader={clearHeader}
          disableInputs={disableInputs}
          linesDetailForm={linesDetailForm}
          operationId={operationId}
          delivered={delivered}
        ></DimensionsOperationTable>
        <Row gutter={16} justify="end" style={{ marginTop: 5 }}>
          <Col xs={24} md={6} lg={4}>
            <Form.Item label="Volumen:" name="volume">
              <Tag color={'green'} style={{ padding: 5, width: '100%' }}>
                <Text className="text-label">{volume?.toFixed(2)}</Text>
              </Tag>
            </Form.Item>
          </Col>
          <Col xs={24} md={6} lg={4}>
            <Form.Item label="Peso Neto (kg):" name="totalNetWeight">
              <Tag color={'blue'} style={{ padding: 5, width: '100%' }}>
                <Text className="text-label">{totalNetWeight?.toFixed(2)}</Text>
              </Tag>
            </Form.Item>
          </Col>
          <Col xs={24} md={6} lg={4}>
            <Form.Item label="Peso Tara (kg):" name="totalTareWeight">
              <Tag color={'blue'} style={{ padding: 5, width: '100%' }}>
                <Text className="text-label">
                  {totalTareWeight?.toFixed(2)}
                </Text>
              </Tag>
            </Form.Item>
          </Col>
          <Col xs={24} md={6} lg={4}>
            <Form.Item label="Peso Bruto (kg):" name="totalGrossWeight">
              <Tag color={'blue'} style={{ padding: 5, width: '100%' }}>
                <Text className="text-label">
                  {totalGrossWeight?.toFixed(2)}
                </Text>
              </Tag>
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ marginTop: 10 }} justify="end">
          <Col md={6} xs={24}>
            <Form.Item>
              <Button
                disabled={disableAllInputs || disableInputs || delivered}
                className="btn-download"
                style={{ width: 170 }}
                type="primary"
                block
                htmlType="submit"
                icon={<FileAddOutlined />}
              >
                {`${type === 'single' ? 'Guardar' : 'Editar'} línea`}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </div>
      <Modal
        title={titleModal}
        visible={modalIsVisible}
        setIsVisible={setModalIsVisible}
        width={1000}
      >
        {contextModal}
      </Modal>
    </Form>
  );
};

export default LinesDetail;
