import React from 'react';
import { Modal as ModalAntd } from 'antd';

const Modal = (props) => {
    const {
        children,
        title,
        isVisible,
        setIsVisible,
        isCentered,
        ...other
    } = props;

    return (
        <ModalAntd
            title={title}
            centered={isCentered}
            visible={isVisible}
            onCancel={() => setIsVisible(false)}
            footer={false}
            destroyOnClose
            {...other}
        >
            {children}
        </ModalAntd>
    );
};

export default Modal;
