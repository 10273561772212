import React, { useEffect, useState } from 'react';
import { Row, Col, Spin, Form, Button, Typography, Divider } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { getAccessTokenApi } from '../../../../../api/auth';
import {
  METHOD_GET,
  METHOD_POST,
  ROL_MANAGER,
  ROL_OPERACIONES,
} from '../../../../../utils/constants';
import useUser from '../../../../../hooks/useUser';
import { API_URL_COMMENTS } from '../../../../../helpers/urls';
import { fetchApi } from '../../../../../helpers/fetch';

const AddCommentOperationForm = ({
  setModalIsVisible,
  documentId,
  setReloadDocuments,
}) => {
  const [loadingForm, setLoadingForm] = useState(true);
  const [reloadComments, setReloadComments] = useState(true);
  const [comments, setComments] = useState([]);
  const [form] = Form.useForm();
  const token = getAccessTokenApi();
  const { Title } = Typography;
  const { person } = useUser();

  useEffect(() => {
    if (reloadComments) {
      const config = {
        method: METHOD_GET,
        url: `${API_URL_COMMENTS}?fileId=${documentId}`,
        data: null,
        token,
        showNotificationError: true,
      };
      fetchApi(config)
        .then((response) => {
          if (response.statusCode === 200) {
            const allComments = response.result;
            allComments.forEach((element, index) => {
              element['key'] = index;
            });
            setComments(allComments);
          }
          setReloadComments(false);
          setLoadingForm(false);
        })
        .catch((err) => {
          console.log(err);
        });
      // setReloadDocuments(false);
    }
  }, [reloadComments, token, documentId]);

  const addCommentFunction = async (values) => {
    setLoadingForm(true);
    let data = values;
    data['OperationFileId'] = documentId;
    const config = {
      method: METHOD_POST,
      url: `${API_URL_COMMENTS}`,
      data: values,
      token,
      showNotification: true,
    };
    fetchApi(config)
      .then((response) => {
        setLoadingForm(false);
        if (response) {
          if (response.statusCode === 200) {
            setReloadDocuments(true);
            setModalIsVisible(false);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setLoadingForm(false);
  };
  return (
    <Spin spinning={loadingForm} tip="Guardando...">
      <Form
        name="add-comment"
        layout="vertical"
        form={form}
        onFinish={addCommentFunction}
        loading={loadingForm}
      >
        <div>
          <Row xs={24} className="table-company">
            <Col xs={20}>
              {comments.length > 0 && (
                <>
                  <Title level={5}>Comentarios:</Title>
                  {comments?.map((c) => (
                    <Row key={c.id}>
                      <p
                        style={{
                          textTransform: 'capitalize',
                          fontWeight: 'bold',
                        }}
                      >
                        {c.createdBy}
                      </p>
                      <p
                        style={{
                          textTransform: 'capitalize',
                        }}
                      >
                        : {c.text}
                      </p>
                    </Row>
                  ))}
                  <Divider />
                </>
              )}

              {comments.length === 0 && (
                <p style={{ textAlign: 'center' }}>No hay comentarios</p>
              )}

              {[ROL_OPERACIONES, ROL_MANAGER].some((r) =>
                person.roles.includes(r)
              ) && (
                <Form.Item
                  label="Agregar comentarios:"
                  name="text"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor ingrese comentario',
                    },
                  ]}
                >
                  <TextArea
                    placeholder="Ingresa tu información"
                    style={{ height: 150 }}
                  />
                </Form.Item>
              )}

              {[ROL_OPERACIONES, ROL_MANAGER].some((r) =>
                person.roles.includes(r)
              ) && (
                <Row>
                  <Col span={24}>
                    <Form.Item>
                      <Button htmlType="submit" className="btn-enviar" block>
                        Guardar
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </div>
      </Form>
    </Spin>
  );
};

export default AddCommentOperationForm;
