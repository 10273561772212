import { logout } from '../api/auth';
import { notification } from 'antd';

export function messageError() {
    return 'Verifique su conexión a internet, si no se resuelve intente más tarde.';
}
export function messageErrorGeneral() {
    return 'Ocurrió un error, inténtelo  más tarde y si el problema persiste comuníquese con soporte.';
}

export function messageSuccessCreateGeneral(module) {
    return `Se ha creado exitosamente  ${module}.`;
}

export function messageSuccessEditGeneral(module) {
    return `Se ha modificado exitosamente  ${module}.`;
}

export function logOutError() {
    notification['error']({
        message: 'Ocurrio un error, inténtelo de nuevo.',
    });

    setTimeout(() => {
        logout();
        window.location.reload();
    }, 1200);
}
