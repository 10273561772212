import React from 'react';
import { Col, Layout, Row, Typography } from 'antd';
import GreetingsTop from '../../../../components/Admin/GreetingsTop/GreetingsTop';
import CompanyDataDetail from '../../../../components/Admin/Configuration/CompanyDataDetail';
import BackButton from '../../../../components/General/BackButton/BackButton';

export default function CompanyData() {
  return (
    <Layout>
      <GreetingsTop />

      <HeaderTop />

      <div className="billing__container">
        <CompanyDataDetail />
      </div>
    </Layout>
  );
}

function HeaderTop() {
  const { Title } = Typography;
  return (
    <div className="billing__container">
      <Row className="header-top" justify="space-between">
        <Col xs={23} className="header-top__left">
          <Row>
            <Col xs={20}>
              <Title
                level={3}
                style={{
                  position: 'absolute',
                  top: 0,
                  bottom: 0,
                  height: '78%',
                  margin: 'auto',
                }}
              >
                Datos de la compañia
              </Title>
            </Col>

            <Col xs={4}>
              <Row
                style={{
                  marginBottom: 30,
                  justifyContent: 'right',
                }}
              >
                <BackButton routeName={'/configuracion'}></BackButton>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
