import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Row, Col } from 'antd';
// import MenuHeader from '../components/Web/MenuHeader';
// import Footer from '../components/Web/Footer';
const LayoutBasic = (props) => {
    const { routes } = props;

    return (
        <div>
            {/* <MenuHeader /> */}
            <LoadRoutes routes={routes} />
            {/* <Footer>Ana Iloki</Footer> */}
        </div>
    );
};

function LoadRoutes({ routes }) {
    return (
        <Switch>
            {routes.map((route, index) => (
                <Route
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    component={route.component}
                />
            ))}
        </Switch>
    );
}

export default LayoutBasic;
