import { Button, Table, Modal as ModalAntd, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { getAccessTokenApi } from '../../../../../../api/auth';
import { fetchApi } from '../../../../../../helpers/fetch';
import { API_URL_QUOTE } from '../../../../../../helpers/urls';
import { METHOD_GET } from '../../../../../../utils/constants';
import { formatMoney } from '../../../../../../utils/general';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const ImportAdditionalService = ({
  quoteSelected,
  quoteId,
  setModalIsVisible,
  additionalServices,
  setAdditionalServices,
}) => {
  const token = getAccessTokenApi();
  const [loadingForm, setLoadingForm] = useState(true);
  const [additionalServicesArray, setAdditionalServicesArray] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [disabledButton, setDisabledButton] = useState(true);
  const [additionalServiceSelected, setAdditionalServiceSelected] = useState(
    []
  );

  useEffect(() => {
    setLoadingForm(true);
    let quote = quoteSelected ? quoteSelected.quoteId : quoteId;
    const config = {
      method: METHOD_GET,
      url: `${API_URL_QUOTE}/${quote}/AdditionalServices`,
      data: null,
      token,
      showNotificationError: true,
    };
    fetchApi(config)
      .then((response) => {
        if (response.statusCode === 200) {
          const allAs = response.result;
          allAs.forEach((element, index) => {
            element['key'] = index;
          });
          setAdditionalServicesArray(allAs);
        }
        setLoadingForm(false);
      })
      .catch((err) => {
        console.log(err);
      });
    // setReloadDocuments(false);
  }, [token, quoteId, quoteSelected]);
  const onSelectChange = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
    setAdditionalServiceSelected(selectedRows[0]);
    setDisabledButton(false);
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
    onChange: onSelectChange,
  };
  const columns = [
    {
      title: 'No.',
      dataIndex: 'key',
      key: 'key',
      render: (key) => <>{<p>{key + 1}</p>}</>,
    },
    {
      title: 'Servicio',
      dataIndex: 'serviceStr',
      key: 'serviceStr',
    },
    {
      title: 'Tipo de servicio',
      dataIndex: 'typeOfServiceStr',
      key: 'typeOfServiceStr',
    },
    {
      title: 'Servicio adicional',
      dataIndex: 'additionalServiceName',
      key: 'additionalServiceName',
    },
    {
      title: 'Proveedor',
      dataIndex: 'supplierName',
      key: 'supplierName',
    },
    {
      title: 'Costo',
      dataIndex: 'cost',
      key: 'cost',
      render: (cost) => <p>${formatMoney(cost)}</p>,
    },
  ];

  return (
    <div>
      <Table
        dataSource={additionalServicesArray}
        columns={columns}
        bordered
        size="middle"
        scroll={{ x: 500 }}
        setSelectedRowKeys
        className="table-striped-rows"
        rowSelection={rowSelection}
        footer={() => {
          return (
            <FooterTable
              additionalServiceSelected={additionalServiceSelected}
              setModalIsVisible={setModalIsVisible}
              additionalServices={additionalServices}
              setAdditionalServices={setAdditionalServices}
              disabledButton={disabledButton}
            />
          );
        }}
      />
    </div>
  );
};

export default ImportAdditionalService;

function FooterTable({
  showModal,
  additionalServiceSelected,
  setModalIsVisible,
  additionalServices,
  setAdditionalServices,
  disabledButton,
}) {
  const { confirm } = ModalAntd;

  const handleSaveService = (values) => {
    confirm({
      title: 'Guardar servicio adicional',
      icon: <ExclamationCircleOutlined />,
      content: '¿Estás seguro que deseas guardar servicio adicional?',
      okText: 'Si',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        addServiceFunction(values);
      },
      onCancel() {},
    });
  };
  const addServiceFunction = async (values) => {
    // values['typeOfServiceStr'] = values;
    values['id'] = 0;
    let copy = [...additionalServices, values];
    setAdditionalServices(copy);
    notification.success({
      message: 'Servicio adicional agregado exitosamente',
    });

    setModalIsVisible(false);
  };
  return (
    <div className="footer-table">
      <Button
        type="primary"
        onClick={() => {
          handleSaveService(additionalServiceSelected);
          setModalIsVisible(false);
        }}
        disabled={disabledButton}
      >
        Aceptar
      </Button>
    </div>
  );
}
