import React from 'react';
import { Col, Layout, Row, Typography } from 'antd';
import GreetingsTop from '../../../components/Admin/GreetingsTop/GreetingsTop';
import Modules from '../../../components/Admin/Administration/Modules';

import './Administration.scss';

export default function Administration() {
    return (
        <Layout>
            <GreetingsTop />

            <HeaderTop />

            <div className="catalog__container">
                <Modules />
            </div>
        </Layout>
    );
}

function HeaderTop() {
    const { Title } = Typography;

    return (
        <div className="catalog__container">
            <Row className="header-top">
                <Col md={12} xs={24} className="header-top__left">
                    <Title level={3}>Administración</Title>
                </Col>
            </Row>
        </div>
    );
}
