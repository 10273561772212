/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Button, Col, Layout, Row, Typography, Spin, Tag } from 'antd';
import { EditOutlined, AppstoreAddOutlined } from '@ant-design/icons';
import GreetingsTop from '../../../../components/Admin/GreetingsTop';
import Modal from '../../../../components/General/Modal';
import ModuleTable from '../../../../components/Logistic/Modules/ModuleTable';
import TableCustome from '../../../../components/Logistic/Modules/Custom/TableCustome';
import {
  API_URL_CATALOG_VALUES,
  API_URL_CATALOG,
  API_URL_ADDITIONAL_SERVICE,
} from '../../../../helpers/urls';
import { METHOD_GET, ROL_ADMIN, ROL_LAYOUT } from '../../../../utils/constants';
import { getAccessTokenApi } from '../../../../api/auth';
import { fetchApi } from '../../../../helpers/fetch';
import { toTitleCase } from '../../../../utils/general';
import EditCatalogForm from '../../../../components/Admin/Catalog/EditCatalogForm/EditCatalogForm';
import AddValuesForm from '../../../../components/Admin/Catalog/AddValuesForm';
import AddValuesASForm from '../../../../components/Admin/Catalog/Custom/AdditionalServices/AddValuesASForm';
import {
  SERVICES_CATALOG_ID,
  MEASUREMENT_UNIT_CATALOG_ID,
} from '../../../../helpers/constants';
import useUser from '../../../../hooks/useUser';
import BackButton from '../../../../components/General/BackButton/BackButton';
// const disableAddCatalog = [SERVICES_CATALOG_ID, MEASUREMENT_UNIT_CATALOG_ID];
const disableAddCatalog = [MEASUREMENT_UNIT_CATALOG_ID];

export default function Modules() {
  let { moduleID } = useParams();
  const [moduleTitle, setModuleTitle] = useState('');
  const [catalog, setCatalog] = useState(null);
  const [valuesCatalog, setValuesCatalog] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingValues, setLoadingValues] = useState(true);
  const [reloadCatalog, setReloadCatalog] = useState(false);
  const [reloadCatalogValues, setReloadCatalogValues] = useState(false);
  const token = getAccessTokenApi();
  const { person } = useUser();

  const customCatalogs = [
    {
      id: 'additional_services',
      name: 'Servicio Adicionales',
      status: 1,
      custom: true,
    },
  ];

  const getCatalogValues = (cat) => {
    if (cat.id === 'additional_services') {
      const config = {
        method: METHOD_GET,
        url: `${API_URL_ADDITIONAL_SERVICE}`,
        data: null,
        token,
      };
      fetchApi(config)
        .then((response) => {
          if (response) {
            let calogsValues = response.result;

            if (calogsValues) {
              calogsValues.map((cat, index) => {
                cat['key'] = index;
                return cat;
              });
            }
            setValuesCatalog(calogsValues);
          }
          setLoadingValues(false);
          setReloadCatalogValues(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    if (!isNaN(moduleID)) {
      // ID QUE SON NUMEROS ( CATALOGOS API)
      const config = {
        method: METHOD_GET,
        url: `${API_URL_CATALOG_VALUES}/GetAll/${moduleID}`,
        data: null,
        token,
      };
      fetchApi(config)
        .then((response) => {
          if (response) {
            let calogsValues = response.result;

            if (calogsValues) {
              calogsValues.map((cat, index) => {
                cat['key'] = index;
                return cat;
              });
            }
            setValuesCatalog(calogsValues);
          }
          setLoadingValues(false);
          setReloadCatalogValues(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      const _catalog = customCatalogs.find((c) => c.id === moduleID);
      setCatalog(_catalog);
      setModuleTitle(_catalog.name);
      getCatalogValues(_catalog);
      setLoading(false);
    }

    // console.log(isNaN(parseInt(moduleTitle)));
  }, [moduleID, reloadCatalogValues, token]);

  useEffect(() => {
    if (!isNaN(moduleID)) {
      getCatalog();
    }
  }, [reloadCatalog]);

  const getCatalog = async () => {
    setLoading(true);
    const config = {
      method: METHOD_GET,
      url: `${API_URL_CATALOG}/${moduleID}`,
      data: null,
      token,
    };
    const result = await fetchApi(config);

    if (result) {
      if (result.statusCode === 200) {
        setModuleTitle(result.result.name);
        setCatalog(result.result);
      }
    }
    setLoading(false);
    setReloadCatalog(false);
  };

  return (
    <Layout>
      <GreetingsTop />
      {loading && <Spin spinning={loading} />}

      {!loading && (
        <HeaderTop
          moduleTitle={toTitleCase(moduleTitle)}
          catalog={catalog}
          setReloadCatalog={setReloadCatalog}
          setReloadCatalogValues={setReloadCatalogValues}
          moduleID={moduleID}
          person={person}
        />
      )}

      {isNaN(moduleID) ? (
        <TableCustome
          catalog={catalog}
          data={valuesCatalog}
          loading={loadingValues}
          setReloadCatalogValues={setReloadCatalogValues}
        />
      ) : (
        <ModuleTable
          catalog={catalog}
          data={valuesCatalog}
          loading={loadingValues}
          setReloadCatalogValues={setReloadCatalogValues}
        />
      )}
    </Layout>
  );
}

function HeaderTop({
  moduleTitle,
  catalog,
  setReloadCatalog,
  setReloadCatalogValues,
  moduleID,
  person,
}) {
  const { Title } = Typography;
  let history = useHistory();
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [titleModal, setTitleModal] = useState(false);
  const [contextModal, setContextModal] = useState(null);

  const showModal = (type) => {
    if (type === 'Edit') {
      setModalIsVisible(true);
      setTitleModal('Editar catálogo');
      setContextModal(
        <EditCatalogForm
          setModalIsVisible={setModalIsVisible}
          catalog={catalog}
          setReloadCatalog={setReloadCatalog}
        />
      );
    } else if (type === 'Add') {
      setModalIsVisible(true);
      setTitleModal('Agregar valores');
      if (catalog.custom) {
        setContextModal(
          <AddValuesASForm
            setModalIsVisible={setModalIsVisible}
            setReloadCatalogValues={setReloadCatalogValues}
          />
        );
      } else {
        setContextModal(
          <AddValuesForm
            setModalIsVisible={setModalIsVisible}
            catalog={catalog}
            setReloadCatalogValues={setReloadCatalogValues}
          />
        );
      }
    }
  };

  return (
    <div className="billing__container">
      <Row xs={24} className="header-top">
        <Col xs={18} className="header-top__left">
          <Title
            level={3}
            style={{
              // position: 'absolute',
              top: 0,
              bottom: 0,
              height: '78%',
              margin: 'auto',
              textTransform: 'capitalize',
            }}
          >
            {moduleTitle}
          </Title>
        </Col>
        <Col xs={6}>
          <Row
            style={{
              marginBottom: 30,
              justifyContent: 'right',
            }}
          >
            <BackButton routeName={'/catalog'}></BackButton>
          </Row>
        </Col>
      </Row>
      <Row gutter={16} style={{ justifyContent: 'right' }}>
        <Col
          xs={24}
          style={{
            display: 'grid',
            alignItems: 'center',
            justifyContent: 'end',
          }}
        >
          <Row xs={24} md={12} style={{ marginBottom: 10 }}>
            {/* <p xs={24} style={{ marginRight: 20 }}>
                            Fecha:
                        </p>
                        <DatePickerOptions
                            xs={24}
                            // filterDate={filterDate}
                            typeDoc={'Documento'}
                            // dateDisplay={dateReceive}
                        /> */}

            <Col xs={1} md={24}>
              <Tag
                style={{ marginBottom: 10 }}
                color={catalog.status === 1 ? 'green' : 'red'}
              >
                {catalog.status === 1
                  ? 'Activo'.toUpperCase()
                  : 'Inactivo'.toUpperCase()}
              </Tag>
              {[ROL_ADMIN, ROL_LAYOUT].some((r) =>
                person.roles.includes(r)
              ) && (
                <>
                  {!catalog.custom && (
                    <Button
                      className="btn-download"
                      onClick={() => showModal('Edit')}
                      style={{ width: 150 }}
                      icon={<EditOutlined />}
                    >
                      Editar catálogo
                    </Button>
                  )}

                  {!disableAddCatalog.find((e) => e == moduleID) && (
                    <Button
                      className="btn-download"
                      style={{ width: 150 }}
                      onClick={() => showModal('Add')}
                      icon={<AppstoreAddOutlined />}
                    >
                      Agregar valores
                    </Button>
                  )}
                </>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      <Modal
        title={titleModal}
        visible={modalIsVisible}
        setIsVisible={setModalIsVisible}
        // width={1000}
      >
        {contextModal}
      </Modal>
    </div>
  );
}
