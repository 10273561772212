import React, { useState } from 'react';
import {
    Form,
    Input,
    Button,
    Checkbox,
    Row,
    Col,
    Divider,
    Spin,
    notification,
} from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import {
    ACCESS_TOKEN,
    COMPANY_ID,
    PORTAL_TYPE,
    ROL_ADMIN,
} from '../../../utils/constants';
import { signInApi } from '../../../api/user';
// import Modal from '../../../components/General/Modal';
// import ForgotPasswordForm from '../../../components/General/ForgotPasswordForm';
import { useHistory } from 'react-router';

import './LoginForm.scss';

export default function LoginForm() {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    const onFinish = async (values) => {
        setLoading(true);

        // let result = {};
        // result.statusCode = 200;
        const result = await signInApi(values);
        if (result === undefined) {
            setLoading(false);

            notification['error']({
                message: 'Inténtelo más tarde',
            });

            return true;
        }
        if (result.statusCode === 401) {
            setLoading(false);

            notification['error']({
                message: result.description,
            });

            return true;
        }

        if (result.statusCode !== 200) {
            setLoading(false);

            notification['error']({
                message: 'Usuario o contraseña incorrecta.',
            });

            return true;
        } else {
            form.resetFields();
            // console.log(result.result.portalType);
            const companyId = result.result.company;
            localStorage.setItem(ACCESS_TOKEN, result.result.jwtToken);
            localStorage.setItem(COMPANY_ID, companyId);
            localStorage.setItem(PORTAL_TYPE, result.result.portalType);
            notification['success']({
                message: 'Login Correcto',
            });

            setLoading(false);

            if (
                result.result.roles.length === 1 &&
                result.result.roles[0] === ROL_ADMIN
            ) {
                window.location.href = '/configuracion';
            } else {
                window.location.href = '/';
            }
        }
    };

    return(
        <Spin tip="Cargando..." spinning={loading}>
            <Form
                className="login-form"
                name="normal_login"
                initialValues={{ rememberMe: true }}
                onFinish={onFinish}
                requiredMark={false}
                form={form}
                layout="vertical"
            >
                <Form.Item
                    label="Correo electrónico"
                    name="email"
                    rules={[
                        {
                            type: 'email',
                            message: 'El correo electrónico no es válido',
                        },
                        {
                            required: true,
                            message: 'Por favor ingresa un correo electrónico',
                        },
                    ]}
                >
                    <Input
                        prefix={
                            <UserOutlined className="site-form-item-icon" />
                        }
                        placeholder="Correo electrónico"
                    />
                </Form.Item>
                <Form.Item
                    label="Contraseña"
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingresa una contraseña',
                        },
                    ]}
                >
                    <Input.Password
                        prefix={
                            <LockOutlined className="site-form-item-icon" />
                        }
                        type="password"
                        placeholder="Contraseña"
                    />
                </Form.Item>
                <Divider></Divider>
                <Form.Item className="login-form__content">
                    <Row gutter={2}>
                        <Col span={12}>
                            <Form.Item
                                name="rememberMe"
                                valuePropName="checked"
                                noStyle
                            >
                                <Checkbox>Recuérdame</Checkbox>
                            </Form.Item>
                        </Col>
                        <Col span={12} className="login-form__content-right">
                            <Button
                                type="link"
                                // onClick={() => setIsModalVisible(true)}
                                onClick={() =>
                                    history.push('/login/recuperar-contraseña')
                                }
                            >
                                ¿Olvidó su contraseña?
                            </Button>
                        </Col>
                    </Row>
                </Form.Item>

                <Form.Item>
                    <Button
                        danger
                        type="primary"
                        htmlType="submit"
                        size="large"
                        shape="round"
                        className="login-form-login"
                    >
                        Ingresar
                    </Button>
                </Form.Item>
            </Form>

            {/* <Modal
                isVisible={isModalVisible}
                setIsVisible={setIsModalVisible}
                title={'Recuperar Contraseña'}
                footer={false}
            >
                <ForgotPasswordForm setIsModalVisible={setIsModalVisible} />
            </Modal> */}
        </Spin>
    );
}
