import React, { useEffect, useState } from 'react';
import {
  Table,
  Spin,
  Button,
  Modal as ModalAntd,
  Input,
  Space,
  Image,
  Row,
  Col,
  Form,
  Popconfirm,
  InputNumber,
  Select,
} from 'antd';
import {
  DownloadOutlined,
  DeleteOutlined,
  CommentOutlined,
  FileOutlined,
  ExclamationCircleOutlined,
  SearchOutlined,
  EditOutlined,
} from '@ant-design/icons';
import AddCommentOperationForm from './AddCommentOperationForm';
import AddFilesOperationForm from './AddFilesOperationForm';
import Modal from '../../../General/Modal';
import { getAccessTokenApi } from '../../../../api/auth';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import Highlighter from 'react-highlight-words';
import {
  METHOD_DELETE,
  METHOD_GET,
  METHOD_PUT,
  ROL_LAYOUT,
  ROL_MANAGER,
  ROL_OPERACIONES,
} from '../../../../utils/constants';
import useUser from '../../../../hooks/useUser';
import { fetchApi, fetchBlob } from '../../../../helpers/fetch';
import { API_URL_FILES, API_URL_OPERATION } from '../../../../helpers/urls';
import { getFilteredDataFromLocalStorage, saveFilteredDataToLocalStorage } from '../../../../helpers/saveFilterData';

export default function OperationFilesTable() {
  const [loadingForm, setLoadingForm] = useState(false);
  const [reloadDocuments, setReloadDocuments] = useState(true);
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [titleModal, setTitleModal] = useState(false);
  const [contextModal, setContextModal] = useState(null);
  const [documents, setDocuments] = useState([]);
  let { id } = useParams();
  const token = getAccessTokenApi();
  const { confirm } = ModalAntd;
  const [filteredInfo, setFilteredInfo] = useState({});
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const { person } = useUser();
  const [editingKey, setEditingKey] = useState('');
  const [lines, setLines] = useState([]);
  const [form] = Form.useForm();
  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      name: '',
      age: '',
      address: '',
      ...record,
    });
    setEditingKey(record.key);
  };
  const cancel = () => {
    setEditingKey('');
  };
  useEffect(() => {
    if (reloadDocuments) {
      const config = {
        method: METHOD_GET,
        url: `${API_URL_FILES}/${id}/GetFiles`,
        data: null,
        token,
        showNotificationError: true,
      };
      fetchApi(config)
        .then((response) => {
          if (response.statusCode === 200) {
            const allFiles = response.result;
            allFiles.forEach((element, index) => {
              element['key'] = index;
            });
            setDocuments(allFiles);
          }
          setReloadDocuments(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [reloadDocuments, token, id]);
  useEffect(() => {
    const config = {
      method: METHOD_GET,
      url: `${API_URL_OPERATION}/${id}`,
      data: null,
      token,
      showNotificationError: true,
    };
    fetchApi(config)
      .then((response) => {
        if (response.statusCode === 200) {
          // console.log(response, 'response');
          const allLines = response.result.operation.operationLines;
          allLines.forEach((element, index) => {
            element['key'] = index;
          });
          setLines([{ number: '', id: 0 }, ...allLines]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [token, id]);
  useEffect(() => {
    const datosObtenidos = getFilteredDataFromLocalStorage('operationFiles');
    if (datosObtenidos !== null) {
    setFilteredInfo(datosObtenidos.filters);
    }
  },[])
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            // this.searchInput = node;
          }}
          placeholder={`Buscar`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => {
              handleReset(clearFilters);
              handleSearch(selectedKeys, confirm, dataIndex);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Limpiar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    filteredValue: filteredInfo[dataIndex] || null,
    onFilterDropdownVisibleChange: (visible) => {},
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `Seleccione linea`,
              },
            ]}
          >
            <Select>
              {lines.map((s) => (
                <Select.Option key={s.id} value={s.id}>
                  {s.id === 0 ? 'Sin asignar' : `Linea ${s.number}`}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };
  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...documents];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        let values = row;
        values['operationFileId'] = item.id;

        const config = {
          method: METHOD_PUT,
          url: `${API_URL_OPERATION}/${id}/UpdateFile`,
          data: values,
          token,
          showNotificationError: true,
        };
        fetchApi(config).then((response) => {
          setReloadDocuments(true);
          // console.log(response, 'response');
        });

        setDocuments(newData);
        setEditingKey('');
      } else {
        newData.push(row);
        setDocuments(newData);
        setEditingKey('');
      }
    } catch (errInfo) {
      console.log('Validar campo:', errInfo);
    }
  };
  const columns = [
    {
      title: 'Referencia',
      dataIndex: 'operationModelId',
      key: 'operationModelId',
      align: 'center',
      render: (operationModelId, operation) => (
        <p>{`${operationModelId} - ${operation.key + 1}`}</p>
      ),
    },
    {
      title: 'Imagen',
      // dataIndex: 'description',
      key: '3',
      align: 'center',
      render: (fileName, image) => (
        <>
          {(image.extension === 'jpeg' || image.extension === 'png') && (
            <Image
              src={`${image.fileLocation}`}
              alt="doc"
              style={{ width: 100, marginLeft: 20 }}
            />
          )}
          {image.extension !== 'jpeg' && image.extension !== 'png' && (
            <FileOutlined style={{ fontSize: 50, color: 'gray' }} />
          )}
        </>
      ),
    },
    {
      title: 'Nombre del archivo',
      dataIndex: 'fileName',
      key: 'fileName',
      align: 'center',
      ...getColumnSearchProps('fileName'),
      render: (fileName, record) => (
        <a href={`${record.fileLocation}`} target="_blank">
          {fileName}
        </a>
      ),
    },
    {
      title: 'Fecha de alta de archivo',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      render: (createdAt) => <>{moment.unix(createdAt).format('DD/MM/YYYY')}</>,
    },
    {
      title: 'Linea',
      dataIndex: 'operationLineModelId',
      key: 'operationLineModelId',
      width: 120,
      render: (operationLineModelId, record) => (
        <>
          {operationLineModelId === null || operationLineModelId === 0
            ? 'Sin asignar'
            : `Linea ${record.lineNumber !== null ? record.lineNumber : ''} `}
        </>
      ),
      editable: true,
    },
    {
      title: 'Usuario',
      dataIndex: 'createdBy',
      key: 'createdBy',
      align: 'center',
      ...getColumnSearchProps('createdBy'),
    },
    {
      title: 'Acción',
      dataIndex: 'extension',
      key: 'extension',
      align: 'center',
      // width: 200,
      render: (extension, data) => (
        <Row gutter={16}>
          <Col xs={24} lg={12}>
            <Button
              icon={<DownloadOutlined />}
              type="primary"
              size="small"
              onClick={() => downloadFile(data)}
              // onClick={(window.location.href = `${data.fileLocation}`)}
              // href={'data:image/png;base64,' + data.stringFileContent}
              // download={data.fileName}
              style={{
                marginBottom: 11,
                justifyContent: 'center',
                alignItems: 'center',
                display: 'inline-flex',
              }}
            ></Button>
          </Col>
          {[ROL_OPERACIONES, ROL_LAYOUT].some((r) =>
            person.roles.includes(r)
          ) && (
            <Col xs={24} md={12}>
              <Button
                icon={<DeleteOutlined />}
                danger
                onClick={() =>
                  handleDeleteDoc(data.id, data.fileName, data.operationModelId)
                }
                type="primary"
                size="small"
                className="login-form-regresar"
              />
            </Col>
          )}
        </Row>
      ),
    },
    {
      title: 'Asignar linea',
      align: 'center',
      dataIndex: 'operation',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <Col style={{ marginLeft: 10 }}>
            <Row justify="center">
              <a onClick={() => save(record.key)}>Guardar</a>
            </Row>
            <Row justify="center">
              <Popconfirm title="Desea cancelar?" onConfirm={cancel}>
                <a>Cancelar</a>
              </Popconfirm>
            </Row>
          </Col>
        ) : (
          <Button
            icon={<EditOutlined />}
            type="primary"
            size="small"
            disabled={editingKey !== ''}
            onClick={() => edit(record)}
            style={{
              marginBottom: 11,
              justifyContent: 'center',
              alignItems: 'center',
              display: 'inline-flex',
            }}
          ></Button>
        );
      },
    },
    {
      title: 'Comentarios',
      dataIndex: 'hasComments',
      align: 'center',
      key: 'hasComments',
      render: (hasComments, docs) => (
        <>
          {hasComments === false && (
            <CommentOutlined
              style={{ fontSize: 18 }}
              onClick={() => showModal(docs.id)}
            />
          )}
          {hasComments === true && (
            <CommentOutlined
              style={{ fontSize: 18, color: 'green' }}
              onClick={() => showModal(docs.id)}
            />
          )}
        </>
      ),
    },
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === 'operationLineModelId' ? 'select' : '',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  const downloadFile = async (data) => {
    let a = document.createElement('a');
    document.body.appendChild(a);
    a.style = 'display: none';
    const config = {
      method: METHOD_GET,
      url: `${API_URL_OPERATION}/${data.operationModelId}/DownloadFile/${data.id}`,
      data: null,
      token,
      showNotificationError: false,
    };
    fetchBlob(config)
      .then((response) => {
        let url = window.URL.createObjectURL(response);
        a.href = url;
        a.download = data.fileName;
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleDeleteDoc = (id, name, idOperation) => {
    confirm({
      title: 'Eliminar documento',
      icon: <ExclamationCircleOutlined />,
      content: `¿Estás seguro que desea eliminar el documento ${name}?, no se podrá recuperar después`,
      okText: 'Si',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteDocument(id, idOperation);
      },
      onCancel() {},
    });
  };
  const deleteDocument = async (id, idOperation) => {
    setLoadingForm(true);
    const data = {
      fileId: id,
    };
    const config = {
      method: METHOD_DELETE,
      url: `${API_URL_OPERATION}/${idOperation}/DeleteFile`,
      data: data,
      token,
      showNotification: true,
    };
    const result = await fetchApi(config);
    if (result) {
      if (result.statusCode === 200) {
        setReloadDocuments(true);
      }
    }
    setLoadingForm(false);
  };
  const showModal = (id) => {
    setTitleModal('Agregar comentario');
    setModalIsVisible(true);
    setContextModal(
      <AddCommentOperationForm
        setModalIsVisible={setModalIsVisible}
        documentId={id}
        setReloadDocuments={setReloadDocuments}
      ></AddCommentOperationForm>
    );
  };
  const addDocumentModal = (id) => {
    setTitleModal('Agregar documentos');
    setModalIsVisible(true);
    setContextModal(
      <AddFilesOperationForm
        setModalIsVisible={setModalIsVisible}
        operationId={id}
        setReloadDocuments={setReloadDocuments}
      ></AddFilesOperationForm>
    );
  };
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const handleChange = (pagination, filters, sorter, extra) => {
    saveFilteredDataToLocalStorage('operationfiles', pagination, sorter, filters);
    setFilteredInfo(filters);
    // setbillsExport(extra.currentDataSource);
  };

  const clearAll = () => {
    setFilteredInfo({});
    // setSortedInfo({});
  };
  return (
    <Spin spinning={loadingForm} tip="Guardando...">
      <div>
        <Form form={form} component={false}>
          <Table
          onChange={handleChange}
            // columns={columns}
            dataSource={documents}
            loading={reloadDocuments}
            scroll={{ x: 800 }}
            pagination={{ defaultPageSize: 5 }}
            columns={mergedColumns}
            // rowClassName="editable-row"
            components={{
              body: {
                cell: EditableCell,
              },
            }}
          />
          {[ROL_OPERACIONES, ROL_LAYOUT].some((r) =>
            person.roles.includes(r)
          ) && (
            <Row style={{ marginTop: 20 }}>
              <Col span={24}>
                <Button
                  className="btn-download"
                  icon={<DownloadOutlined />}
                  style={{ width: 180 }}
                  onClick={() => addDocumentModal(id)}
                >
                  Agregar documento
                </Button>
              </Col>
            </Row>
          )}
        </Form>
      </div>
      <Modal
        title={titleModal}
        visible={modalIsVisible}
        setIsVisible={setModalIsVisible}
      >
        {contextModal}
      </Modal>
    </Spin>
  );
}
