import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import es_ES from 'antd/lib/locale/es_ES';
import { ConfigProvider } from 'antd';
import routes from './config/routes';
import 'moment/locale/es-mx';
import AuthProvider from './providers/AuthProvider';
import UserProvider from './providers/UserProvider';
import MenuCollapseProvider from './providers/MenuCollapseProvider';
import StatusFilterProvider from './providers/StatusFilterProvider';
import GlobalProvider from './providers/GlobalProvider';

function App() {
  return (
    <ConfigProvider locale={es_ES}>
      <AuthProvider>
        <UserProvider>
          <MenuCollapseProvider>
            <StatusFilterProvider>
              <GlobalProvider>
                <Router>
                  <Switch>
                    {routes.map((route, index) => (
                      <RouterWithSubRoutes key={index} {...route} />
                    ))}
                  </Switch>
                </Router>
              </GlobalProvider>
            </StatusFilterProvider>
          </MenuCollapseProvider>
        </UserProvider>
      </AuthProvider>
    </ConfigProvider>
  );
}

function RouterWithSubRoutes(route) {
  return (
    <Route
      path={route.path}
      exact={route.exact}
      render={(props) => <route.component routes={route.routes} {...props} />}
    />
  );
}

export default App;
