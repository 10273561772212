import React, { useEffect, useState } from 'react';
import { Col, Layout, Row, Typography, notification, Button } from 'antd';
import GreetingsTop from '../../../components/Admin/GreetingsTop/GreetingsTop';
import BillingFilter from '../../../components/Admin/Billling/BillingFilters/BillingFilter';
import useUser from '../../../hooks/useUser';
import moment from 'moment';
import useStatusFilter from '../../../hooks/useStatusFilter';
import BillingTable from '../../../components/Admin/Billling/BillingTable';
import {
  ROL_CUSTOMER,
  ROL_FINANCIAL,
  ROL_LAYOUT,
  ROL_MANAGER,
  ROL_OPERACIONES,
  ROL_SUPPLIER,
  ROL_VENTAS,
} from '../../../utils/constants';
import { getAccessTokenApi, logout } from '../../../api/auth';
import { getAllApiInvoices } from '../../../api/billing';
import { messageError } from '../../../utils/general';
import useDataGeneral from '../../../hooks/useDataGeneral';
import BillingClient from '../../../pages/PortalCliente/Billing';
import { ContainerOutlined } from '@ant-design/icons';

import './Billing.scss';
import Modal from '../../../components/General/Modal';
import AddPurchaseOrder from '../../../components/Admin/Billling/AddPurchaseOrder/AddPurchaseOrder';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { ArrowLeftOutlined } from '@ant-design/icons';
import BackButton from '../../../components/General/BackButton/BackButton';

export default function Billing() {
  const [reloadBills, setReloadBills] = useState(true);
  const [bills, setBills] = useState([]);
  const token = getAccessTokenApi();
  const { statusData, reset } = useDataGeneral();
  const [dateRange, setDateRange] = useState(null);

  const date = [moment().subtract(7, 'days'), moment()];
  const { statusFilter } = useStatusFilter();
  const [dateReceive, setDateReceive] = useState([]);
  const { person } = useUser();
  const [keysSelected, setKeysSelected] = useState([]);
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [titleModal, setTitleModal] = useState(false);
  const [contextModal, setContextModal] = useState(null);
  const [purchaseOrders, setPurchaseOrders] = useState([]);

  useEffect(() => {
    if (reloadBills) {
      filterDate(date, 'Documento');
    }
  }, [reloadBills]);

  useEffect(() => {
    //filtar dependiendo el estatus de los amount
    const statusReceive = statusFilter.type;
    if (statusReceive != '') {
      // 'Vigente', 'Vencido', //TODO -> Status
      let dayToSearch = [moment().subtract(7, 'days'), moment()];
      if (statusFilter.dateStart != '' && statusFilter.dateEnd != '') {
        dayToSearch = [
          moment(statusFilter.dateStart),
          moment(statusFilter.dateEnd),
        ];
      }
      setDateReceive(dayToSearch);
      filterDate(dayToSearch, 'Documento', statusFilter.type);
    }
  }, []);

  useEffect(() => {
    if (statusData.reloadTable) {
      // console.log(statusData, 'statusData');
      filterDate(date, 'Documento');
      reset();
    }
  }, [statusData]);

  function filterDate(date, dateString, status) {
    setReloadBills(true);
    if (date) {
      let filters = '';
      getAllApiInvoices(token, filters).then((response) => {
        if (response === undefined) {
          const msg = messageError();
          notification['error']({
            message: msg,
          });
          return;
        }
        if (response?.statusCode === 401) {
          notification['error']({
            message: 'Usuario no autorizado.',
          });
          setTimeout(() => {
            logout();
            window.location.reload();
          }, 1000);
          return;
        } else if (response?.statusCode === 200) {
          response.result.forEach((element, index) => {
            element['key'] = index;
            element['paymentAmount'] = element.balance;
          });
          setBills(response.result);
          setReloadBills(false);
        }
      });
    }
  }

  const showModal = () => {
    setModalIsVisible(true);
    setTitleModal('Generar orden de compra');
    setContextModal(
      <AddPurchaseOrder
        setModalIsVisible={setModalIsVisible}
        purchaseOrders={purchaseOrders}
        setReloadBills={setReloadBills}
        setKeysSelected={setKeysSelected}
      ></AddPurchaseOrder>
    );
  };

  useEffect(() => {
    const res = keysSelected.map((e) => bills[e]);
    setPurchaseOrders(res);
  }, [bills, keysSelected]);
  if ([ROL_CUSTOMER].some((r) => person.roles.includes(r))) {
    return <BillingClient />;
  }

  return (
    <Layout>
      <GreetingsTop />

      <HeaderTop showModal={showModal} keysSelected={keysSelected} />

      <div className="billing__container">
        <BillingTable
          bills={bills}
          reloadBills={reloadBills}
          dateRange={dateRange}
          keysSelected={keysSelected}
          setKeysSelected={setKeysSelected}
          setReloadBills={setReloadBills}
        />
      </div>
      <Modal
        title={titleModal}
        visible={modalIsVisible}
        setIsVisible={setModalIsVisible}
        width={1000}
      >
        {contextModal}
      </Modal>
    </Layout>
  );
}

function HeaderTop({ showModal, keysSelected }) {
  const { Title } = Typography;
  const { person } = useUser();

  return (
    <div className="billing__container">
      <Row className="header-top" justify="space-between">
        <Col xs={18} md={20} className="header-top__left">
          <Title
            level={3}
            style={{
              // position: 'absolute',
              top: 0,
              bottom: 0,
              height: '78%',
              margin: 'auto',
            }}
          >
            Facturación
          </Title>
        </Col>

        <Col xs={1}>
          <Row
            style={{
              marginBottom: 30,
              justifyContent: 'right',
            }}
          >
            {[
              ROL_LAYOUT,
              ROL_FINANCIAL,
              ROL_MANAGER,
              ROL_OPERACIONES,
              ROL_VENTAS,
            ].some((r) => person.roles.includes(r)) && (
              <BackButton routeName={'/cxp/proveedor'}></BackButton>
            )}
          </Row>
        </Col>
        {[ROL_SUPPLIER].some((r) => person.roles.includes(r)) && (
          <Col xs={24} md={4}>
            <div>
              <BillingFilter />
            </div>
          </Col>
        )}
      </Row>
      {[
        ROL_LAYOUT,
        ROL_FINANCIAL,
        ROL_MANAGER,
        ROL_OPERACIONES,
        ROL_VENTAS,
      ].some((r) => person.roles.includes(r)) && (
        <Row justify="end">
          <Col>
            <Button
              onClick={() => showModal('addFile')}
              className="btn-download"
              disabled={keysSelected.length === 0}
              icon={<ContainerOutlined />}
              style={{ width: 220 }}
            >
              Nueva orden de compra
            </Button>
          </Col>
        </Row>
      )}
    </div>
  );
}
