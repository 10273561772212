import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Spin,
  Form,
  Input,
  Button,
  notification,
  Select,
  InputNumber,
  DatePicker,
  Radio,
} from 'antd';
import { getAllSuppliers } from '../../../../api/supplier';
import { getAccessTokenApi } from '../../../../api/auth';
import TextArea from 'antd/lib/input/TextArea';
import { getCatalogValueHandle } from '../../../../helpers/handlerFunctionApi';
import { CALLTYPE_VALUE_ID } from '../../../../helpers/constants';
import { fetchApi } from '../../../../helpers/fetch';
import { METHOD_POST, METHOD_PUT} from '../../../../utils/constants';
import { API_URL_CALLS } from '../../../../helpers/urls';
import { getAllClients } from '../../../../api/clients';
import moment from 'moment';

const AddEditCallForm = ({
  setModalIsVisible,
  type,
  setReloadTable,
  call,
}) => {
  const [loadingForm, setLoadingForm] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [form] = Form.useForm();
  const token = getAccessTokenApi();
  const [callTypes, setCallTypes] = useState([]);
  const [loadingCatalog, setLoadingCatalog] = useState();

  useEffect(() => {
    getCatalogValueHandle(
      CALLTYPE_VALUE_ID,
      setCallTypes,
      setLoadingCatalog,
      null,
      'TIPOLLAMADA'
    );
  }, []);
  useEffect(() => {
    getAllClients(token)
      .then((response) => {
        if (response.statusCode === 200) {
          let clients = response.result.map((s) => ({
            value: s.id,
            label: s.name,
            active: s.active,
          }));
          clients.sort((a, b) => a.label.localeCompare(b.label));
          setCustomers(clients);
        }
      })
      .catch((err) => {});
  }, [token]);
  useEffect(() => {
    getAllSuppliers(token, '')
      .then((response) => {
        // console.log(response, 'suppliers');
        if (response.statusCode === 200) {
          let supp = response.result
            .filter((s) => s.active)
            .map((s) => ({
              value: s.supplierId,
              label: s.name,
            }));
          supp.sort((a, b) => a.label.localeCompare(b.label));
          setSuppliers(supp);
        }
      })
      .catch((err) => {});
  }, [token]);

  useEffect(() => {
    if (type !== 'add' && call) {
      form.setFieldsValue({
        missed: call.missed,
        customerId: call.customerId !== null ? call.customerId : undefined,
        supplierId: call.supplierId !== null ? call.supplierId : undefined,
        callDate: moment.unix(call.callDate),
        callTypeId: call.callTypeId,
        comments: call.comments,
        contact: call.contact,
        duration: call.duration,
      });
    }
  }, [type, call, form]);

  const addSupplierFunction = async (values) => {
    setLoadingForm(true);
    let data = values;
    if (data.supplierId === undefined && data.customerId === undefined ) {
      notification['error']({
        message: 'Debes seleccionar cliente ó proveedor',
      });
      setLoadingForm(false);
      return false;
    }
    if (data.supplierId !== undefined && data.customerId !== undefined) {
      notification['error']({
        message: 'Debes seleccionar una opción: cliente ó proveedor',
      });
      setLoadingForm(false);
      return false;
    }
    data['callDate'] = values.callDate.unix();

    let config = {
      method: call !== undefined ? METHOD_PUT : METHOD_POST,
      url: `${API_URL_CALLS}${call !== undefined ? '/' + call.id : ''}`,
      data: data,
      token,
      showNotification: false,
    };
    fetchApi(config)
    .then((response) => {
      setLoadingForm(false);
      if (response) {
        if (response.statusCode === 200 ||response.statusCode === 201 ) {
          setReloadTable(true);
          setModalIsVisible(false);
          notification['success']({
            description: response.message,
          });
        }
        if (response.statusCode === 400 || response.statusCode === 404 || response.statusCode === 500) {
          notification['error']({
            description: response.message,
          });
        }
      }
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {
      setLoadingForm(false);
    });
    
  };
  
  return (
    <Spin spinning={loadingForm || loadingCatalog} tip="Cargando...">
      <Form
        name="add-promo"
        layout="vertical"
        form={form}
        onFinish={addSupplierFunction}
      >
        <div>
        <div>
        <Row gutter={16}>
                  <Col xs={24} md={12} lg={12}>
                    <Form.Item
                      label="Cliente:"
                      name="customerId"
                    >
                      <Select
                        placeholder="Selecciona una opción"
                        allowClear
                        disabled={type === 'watch'}
                        showArrow
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {customers.map((f) => (
                          <Select.Option key={f.value} value={f.value}>
                            {f.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  {suppliers.length !== 0 &&(
                  <Col xs={24} md={12} lg={12}>
                    <Form.Item label="Proveedor:" name="supplierId">
                      <Select
                      disabled={type === 'watch'}
                        placeholder="Selecciona una opción"
                        allowClear
                        showArrow
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {suppliers.map((f) => (
                          <Select.Option key={f.value} value={f.value}>
                            {f.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  )}
                  
                </Row>
                <Row gutter={16}>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label="Contacto:"
                      name="contact"
                    >
                    <Input disabled={type === 'watch'} style={{width: "100%"}} />
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={12} lg={12}>
                    <Form.Item
                    label="Fecha:"
                    name="callDate"
                    rules={[
                        {
                        required: true,
                        message: 'Por favor ingrese fecha',
                        },
                    ]}
                    >
                    <DatePicker
                    disabled={type === 'watch'}
                      format={'DD/MM/YYYY'}
                      style={{ width: '100%' }}
                    />                
                    </Form.Item>
                  </Col>
                </Row>
               
                <Row gutter={16}>
                <Col xs={24} md={4}>
                    <Form.Item
                      label="Estatus:"
                      name="missed"
                      rules={[
                        {
                          required: true,
                          message: 'Por favor ingrese estatus',
                        },
                      ]}
                    >
                     <Radio.Group disabled={type === 'watch'}>
                      <Radio value={false}>Con respuesta</Radio>
                      <Radio value={true}>Sin respuesta</Radio>
                     
                    </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={8}>
                    <Form.Item
                      label="Duración:"
                      name="duration"
                      rules={[
                        {
                        required: true,
                        message: 'Por favor ingrese duración',
                        },
                    ]}
                    >
                    <InputNumber disabled={type === 'watch'} placeholder="En minutos" style={{width: "100%"}} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12} lg={12}>
                    <Form.Item
                      label="Tipo de llamada:"
                      name="callTypeId"
                      rules={[
                        {
                        required: true,
                        message: 'Por favor seleccione una opción',
                        },
                    ]}
                    >
                      <Select
                      disabled={type === 'watch'}
                          placeholder="Selecciona una opción"
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {callTypes.map((s) => (
                            <Select.Option key={s.value} value={s.value}>
                              {s.label}
                            </Select.Option>
                          ))}
                        </Select>
                    </Form.Item>
                  </Col>
                </Row>
                  
                <Form.Item label="Comentarios:" name="comments">
                  <TextArea disabled={type === 'watch'} placeholder="Ingresa tu información" />
                </Form.Item>
              </div>
        {type !== 'watch' && (
          <Row>
            <Col span={24}>
              <Form.Item>
                <Row justify="end">
                  <Button htmlType="submit" className="btn-enviar" block>
                    Guardar
                  </Button>
                </Row>
              </Form.Item>
            </Col>
          </Row>
        )}
        </div>
      </Form>
    </Spin>
  );
};

export default AddEditCallForm;
