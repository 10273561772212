import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Spin, Select, Row } from 'antd';
import { METHOD_GET, METHOD_PUT } from '../../../../utils/constants';
import { API_URL_CATALOG_VALUES } from '../../../../helpers/urls';
import { fetchApi } from '../../../../helpers/fetch';
import { getAccessTokenApi } from '../../../../api/auth';
import {
  AIRPORT_CATALOG_ID,
  CITY_CATALOG_ID,
  COUNTRY_CATALOG_ID,
  PORT_CATALOG_ID,
  STATE_CATALOG_ID,
} from '../../../../helpers/constants';

import './EditValuesForm.scss';

const EditValuesForm = ({
  setReloadCatalogValues,
  setModalIsVisible,
  valueField,
}) => {
  const catalogRelational = [
    STATE_CATALOG_ID,
    CITY_CATALOG_ID,
    PORT_CATALOG_ID,
    AIRPORT_CATALOG_ID,
  ];
  const needRelationalId = catalogRelational.find(
    (e) => e === valueField.catalogId
  )
    ? true
    : false;
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [labelDynamic, setLabelDynamic] = useState('ID relacional');
  const token = getAccessTokenApi();
  const [form] = Form.useForm();

  const getOption = (idRelational) => {
    setLoading(true);
    const config = {
      method: METHOD_GET,
      url: `${API_URL_CATALOG_VALUES}/GetAll/${idRelational}`,
      data: null,
      token,
      // showNotification: true
    };
    fetchApi(config)
      .then((response) => {
        if (response) {
          let calogsValues = response.result;
          if (calogsValues) {
            calogsValues.map((cat, index) => {
              cat['key'] = index;
              cat['label'] = cat.description;
              cat['value'] = cat.id;
              // cat["value"] = cat.code;
            });
          }
          setOptions(calogsValues);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getIDCatalogRelational = () => {
    let catalogID = 0;
    switch (valueField.catalogId) {
      case 10:
        catalogID = COUNTRY_CATALOG_ID;
        break;
      case 11:
        catalogID = STATE_CATALOG_ID;
        break;
      case 16:
        catalogID = CITY_CATALOG_ID;
        break;
      case 24:
        catalogID = CITY_CATALOG_ID;
        break;

      default:
        break;
    }

    return catalogID;
  };

  useEffect(() => {
    if (valueField.catalogId === STATE_CATALOG_ID) {
      setLabelDynamic('País relacional');
    }

    if (valueField.catalogId === CITY_CATALOG_ID) {
      setLabelDynamic('Estado relacional');
    }

    if (
      valueField.catalogId === PORT_CATALOG_ID ||
      valueField.catalogId === AIRPORT_CATALOG_ID
    ) {
      setLabelDynamic('Ciudad relacional');
    }
    // console.log(valueField);
    const idRelational = getIDCatalogRelational();
    if (needRelationalId && idRelational > 0) {
      getOption(idRelational);
    }
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      Description: valueField.description,
      Code: valueField.code,
      SatId: valueField.satId,
      fatherId: valueField.fatherId,
    });
  }, []);

  const onFinish = async (values) => {
    setLoading(true);

    let fieldValues = [];
    valueField.fieldValues?.forEach((field) => {
      const JSONfields = { name: field.name, Value: values[field.name] };
      fieldValues.push(JSONfields);
    });

    values['FieldValues'] = fieldValues;
    values['id'] = valueField.id;

    const config = {
      method: METHOD_PUT,
      url: `${API_URL_CATALOG_VALUES}/${valueField.id}`,
      data: values,
      token,
      showNotification: true,
    };
    const result = await fetchApi(config);

    if (result) {
      if (result.statusCode === 200) {
        // form.resetFields();
        setModalIsVisible(false);
        setReloadCatalogValues(true);
      }
    }

    setLoading(false);
  };

  return (
    <div className="add-catalog-form">
      <Spin spinning={loading} tip="Cargando...">
        <Form
          name="AddCatalog"
          form={form}
          // labelCol={{ span: 10 }}
          // wrapperCol={{ span: 14 }}
          initialValues={{ Catalog: valueField.catalogId }}
          onFinish={onFinish}
          layout="vertical"
          // autoComplete="off"
        >
          <Form.Item
            label="Descripción"
            name="Description"
            rules={[
              {
                required: true,
                message: 'Por favor ingresa una descripción',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Código"
            name="Code"
            rules={[
              {
                required: false,
                message: 'Por favor ingresa un código',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="SAT ID"
            name="SatId"
            rules={[
              {
                required: false,
                message: 'Por favor ingresa un SAT ID',
              },
            ]}
          >
            <Input />
          </Form.Item>

          {needRelationalId && (
            <Form.Item
              label={labelDynamic}
              name="fatherId"
              rules={[
                {
                  required: false,
                  message: 'Por favor ingresa un valor',
                },
              ]}
            >
              {catalogRelational.find((e) => e === valueField.catalogId) ? (
                <Select
                  placeholder="Selecciona una opción"
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {options.map((s) => (
                    <Select.Option key={s.value} value={s.value}>
                      {s.label}
                    </Select.Option>
                  ))}
                </Select>
              ) : (
                <Input />
              )}
            </Form.Item>
          )}

          {valueField.fieldValues &&
            valueField.fieldValues.map((field, index) => (
              <Form.Item
                key={index}
                label={field.name}
                name={field.name}
                rules={[
                  {
                    required: false,
                    message: `Por favor ingresa un ${field}`,
                  },
                ]}
                initialValue={field.value}
              >
                <Input />
              </Form.Item>
            ))}
          <Row justify="end">
            <Form.Item>
              <Button className="btn-enviar" htmlType="submit">
                Guardar
              </Button>
            </Form.Item>
          </Row>
        </Form>
      </Spin>
    </div>
  );
};

export default EditValuesForm;
