import React, { useState, useEffect } from 'react';
import { Button, Modal as ModalAntd, Space, Table, Tooltip } from 'antd';
import {
  ReloadOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import AddSupplierRoute from '../SupplierRoutesTable/AddSupplierRoute';
import Modal from '../../../General/Modal';
import { fetchApi } from '../../../../helpers/fetch';
import { getAccessTokenApi } from '../../../../api/auth';
import {
  METHOD_DELETE,
  METHOD_GET,
  ROL_ADMIN,
  ROL_LAYOUT,
  ROL_PRICING,
} from '../../../../utils/constants';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import useUser from '../../../../hooks/useUser';

const SupplierRoutesTable = ({ supplier }) => {
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [reloadRoutes, setReloadRoutes] = useState(true);
  const [titleModal, setTitleModal] = useState(false);
  const [contextModal, setContextModal] = useState(null);
  const { confirm } = ModalAntd;
  const token = getAccessTokenApi();
  let { supplierId } = useParams();
  const [routesSupplier, setRoutesSupplier] = useState([]);
  const { person } = useUser();

  useEffect(() => {
    const config = {
      method: METHOD_GET,
      url: `Route/GetBySupplier/${supplierId}`,
      data: null,
      token,
    };
    fetchApi(config)
      .then((response) => {
        if (response) {
          let supContacts = response.result;

          if (supContacts) {
            supContacts.map((cat, index) => {
              cat['key'] = index;
            });
          }
          setRoutesSupplier(supContacts);
        }
        setReloadRoutes(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [reloadRoutes, supplierId, token]);

  const showModal = (type) => {
    if (type === 'Add') {
      setModalIsVisible(true);
      setTitleModal(`Agregar ruta proveedor ${supplier.name}`);
      setContextModal(
        <AddSupplierRoute
          setModalIsVisible={setModalIsVisible}
          setReloadRoutes={setReloadRoutes}
          supplierId={supplierId}
        ></AddSupplierRoute>
      );
    }
  };

  const columns = [
    {
      title: 'Origen',
      children: [
        {
          title: 'País',
          dataIndex: 'originCountryStr',
          key: 'originCountry',
          width: 100,
          align: 'center',
        },
        {
          title: 'Estado',
          dataIndex: 'originStateStr',
          key: 'originState',
          width: 100,
          align: 'center',
        },
        {
          title: 'Ciudad',
          dataIndex: 'originCityStr',
          key: 'originCity',
          width: 100,
          align: 'center',
        },
      ],
    },
    {
      title: 'Destino',
      children: [
        {
          title: 'País',
          dataIndex: 'destinationCountryStr',
          key: 'destinationCountry',
          width: 100,
          align: 'center',
        },
        {
          title: 'Estado',
          dataIndex: 'destinationStateStr',
          key: 'destinationState',
          width: 100,
          align: 'center',
        },
        {
          title: 'Ciudad',
          dataIndex: 'destinationCityStr',
          key: 'destinationCity',
          width: 100,
          align: 'center',
        },
      ],
    },
    {
      title: 'Acción',
      key: 'action',
      width: 100,
      render: (text, record) => (
        <>
          {[ROL_PRICING, ROL_ADMIN, ROL_LAYOUT].some((r) =>
            person.roles.includes(r)
          ) && (
            <>
              <Space size="middle">
                {/* <Tooltip title="Editar">
                  <Button
                    onClick={() => showModal('Edit', record.id)}
                    type="primary"
                  >
                    <EditOutlined />
                  </Button>
                </Tooltip> */}

                <Tooltip title="Eliminar">
                  <Button
                    danger
                    type="primary"
                    onClick={() => handleDeleteRoute(record.routeId)}
                  >
                    <DeleteOutlined />
                  </Button>
                </Tooltip>
              </Space>
            </>
          )}
        </>
      ),
      align: 'center',
    },
  ];

  const handleDeleteRoute = (id) => {
    confirm({
      title: 'Eliminar ruta',
      icon: <ExclamationCircleOutlined />,
      content: '¿Estás seguro que deseas eliminar la ruta seleccionada?',
      okText: 'Si',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteRoute(id);
      },
      onCancel() {},
    });
  };

  const deleteRoute = async (id) => {
    let values = {};
    values['supplierId'] = supplierId;
    values['routeId'] = id;

    const config = {
      method: METHOD_DELETE,
      url: `Route/deleteBySupplier`,
      data: values,
      token,
      showNotification: true,
    };
    const result = await fetchApi(config);

    if (result) {
      if (result.statusCode === 200) {
        setReloadRoutes(true);
      }
    }
  };

  return (
    <div>
      {[ROL_PRICING, ROL_ADMIN, ROL_LAYOUT].some((r) =>
        person.roles.includes(r)
      ) && (
        <Button
          className="btn-download"
          onClick={() => showModal('Add')}
          icon={<PlusCircleOutlined />}
        >
          Agregar
        </Button>
      )}
      {/* <Button
                disabled={!hasSelected}
                className="btn-delete"
                onClick={(e) => handleDeleteContact(rowSelection)}
                icon={<DeleteOutlined />}
            >
                Eliminar
            </Button> */}
      <Button
        className="btn-download"
        icon={<ReloadOutlined />}
        onClick={(e) => setReloadRoutes(true)}
      >
        Refrescar
      </Button>
      <Table
        // rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
        dataSource={routesSupplier}
        columns={columns}
        bordered
        size="middle"
        scroll={{ x: 1200 }}
        className="table-striped-rows"
        loading={reloadRoutes}
      />

      <Modal
        title={titleModal}
        visible={modalIsVisible}
        setIsVisible={setModalIsVisible}
        width={1000}
      >
        {contextModal}
      </Modal>
    </div>
  );
};

export default SupplierRoutesTable;
