import { Button, Table, Modal as ModalAntd, Col, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import Modal from '../../../../../General/Modal';
import AddDimensionsOperation from '../AddDimensionsOperation';
import {
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';

const DimensionsOperationTable = ({
  disableAllInputs,
  pieces,
  setPieces,
  lines,
  setLines,
  line,
  type,
  lineId,
  keySelected,
  clearHeader,
  disableInputs,
  handleLineSave,
  linesDetailForm,
  operationId,
  delivered,
}) => {
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [titleModal, setTitleModal] = useState(false);
  const [contextModal, setContextModal] = useState(null);
  const [reloadTable, setReloadTable] = useState(false);
  const { confirm } = ModalAntd;
  useEffect(() => {
    setPieces([]);
  }, [clearHeader]);
  useEffect(() => {
    setReloadTable(false);
  }, []);
  const columns = [
    {
      title: 'Piezas',
      dataIndex: 'pieces',
      key: 'pieces',
    },
    {
      title: 'Embalaje',
      dataIndex: 'packagingLabel',
      key: 'packagingLabel',
    },
    {
      title: 'Descripción',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Largo(mts)',
      dataIndex: 'length',
      key: 'length',
    },
    {
      title: 'Ancho(mts)',
      dataIndex: 'width',
      key: 'width',
    },
    {
      title: 'Alto(mts)',
      dataIndex: 'height',
      key: 'height',
    },
    {
      title: 'Volumen',
      dataIndex: 'volume',
      key: 'volume',
      render: (volume) => <p>{volume?.toFixed(2)}</p>,
    },
    {
      title: 'Peso Neto(kg)',
      dataIndex: 'netWeight',
      key: 'netWeight',
      render: (netWeight) => <p>{netWeight?.toFixed(2)}</p>,
    },
    {
      title: 'Peso Tara(kg)',
      dataIndex: 'tareWeight',
      key: 'tareWeight',
      render: (tareWeight) => <p>{tareWeight?.toFixed(2)}</p>,
    },
    {
      title: 'Peso Bruto(kg)',
      dataIndex: 'grossWeight',
      key: 'grossWeight',
      render: (grossWeight) => <p>{grossWeight?.toFixed(2)}</p>,
    },
    {
      title: 'Acciones',
      key: 'acc',
      fixed: 'right',
      width: 100,
      align: 'center',
      render: (dimensionRow) => (
        <>
          <>
            <Tooltip title="Editar">
              <Button
                style={{ marginLeft: 2 }}
                type="primary"
                shape="default"
                icon={<EditOutlined />}
                disabled={disableAllInputs || disableInputs || delivered}
                onClick={() => showModal('Edit', dimensionRow)}
              />
            </Tooltip>
            {/* <Tooltip title="Eliminar">
                        <Button style={{marginLeft: 10}} type="primary" danger shape="default" icon={<DeleteOutlined /> } />
                    </Tooltip> */}
            <Tooltip title="Eliminar">
              <Button
                style={{ marginLeft: 10 }}
                type="primary"
                ghost
                shape="default"
                disabled={
                  disableAllInputs ||
                  type === 'single' ||
                  disableInputs ||
                  delivered
                }
                icon={<DeleteOutlined />}
                onClick={() => handleDeletRow(dimensionRow)}
              />
            </Tooltip>
          </>
        </>
      ),
    },
  ];

  const saveData = React.useCallback(() => {
    // debugger;
    let newLines = [...lines];
    const valuesForm = linesDetailForm.getFieldsValue();
    const index = lines.findIndex((item) => item.number === valuesForm.linesId);
    valuesForm['number'] = valuesForm.linesId;
    if (lines[keySelected].releases) {
      valuesForm['releases'] = lines[keySelected].releases;
    }
    newLines[index] = valuesForm;
    setLines(() => newLines);
  }, [keySelected, lines, linesDetailForm, setLines]);

  const showModal = React.useCallback(
    (modalType, dimensionRow) => {
      let newLines = [...lines];

      if (linesDetailForm !== undefined) {
        const valuesForm = linesDetailForm.getFieldsValue();
        const index = lines.findIndex(
          (item) => item.number === valuesForm.linesId
        );
        valuesForm['number'] = valuesForm.linesId;
        valuesForm['operationModelId'] = operationId;
        if (lines[keySelected].releases) {
          valuesForm['releases'] = lines[keySelected].releases;
        }
        newLines[index] = valuesForm;
        setLines(() => newLines);
      }

      setModalIsVisible(true);
      setTitleModal(modalType === 'Add' ? 'Agregar piezas' : 'Editar piezas');
      setContextModal(() => (
        <AddDimensionsOperation
          setModalIsVisible={setModalIsVisible}
          setPieces={setPieces}
          pieces={pieces}
          lines={newLines}
          setLines={setLines}
          type={modalType}
          dimensionRow={dimensionRow}
          line={line}
          saveKey={type}
          lineId={lineId}
          keySelected={keySelected}
        ></AddDimensionsOperation>
      ));
    },
    [
      keySelected,
      line,
      lineId,
      lines,
      pieces,
      setLines,
      setPieces,
      type,
      linesDetailForm,
    ]
  );
  const onOkCallback = React.useCallback(
    (dimensionRow) => {
      if (type === 'multiple') {
        saveData();
        const parsekey = parseInt(keySelected);
        const { releases } = lines[parsekey];
        const copy = [...releases];
        const found = copy.findIndex((_, i) => i === dimensionRow.key);
        copy.splice(found, 1);
        setPieces(copy);

        const linesDataCopy = [...lines];
        linesDataCopy[parsekey].releases = copy;
        setLines(linesDataCopy);
      } else {
        const copy = [...pieces];
        const found = copy.findIndex((_, i) => i === dimensionRow.key);
        copy.splice(found, 1);
        setPieces(copy);
      }
    },
    [lines, keySelected, pieces, saveData, setLines, setPieces, type]
  );
  const handleDeletRow = React.useCallback(
    (dimensionRow) => {
      confirm({
        title: 'Eliminar línea de piezas',
        icon: <ExclamationCircleOutlined />,
        content:
          '¿Estás seguro que deseas borrar la línea de piezas seleccionada?, no podrá recuperarla después',
        okText: 'Si',
        okType: 'danger',
        cancelText: 'No',
        onOk() {
          onOkCallback(dimensionRow);
        },

        onCancel() {},
      });
    },
    [confirm, onOkCallback]
  );

  return (
    <div>
      {type === 'multiple' && (
        <Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
          {/* {type !== 'rfq' && ( */}
          <Button
            onClick={() => showModal('Add')}
            type="primary"
            style={{
              marginBottom: 16,
            }}
            disabled={disableAllInputs || disableInputs || delivered}
          >
            Nuevo release
          </Button>
          {/* )} */}
        </Col>
      )}

      <Table
        // onChange={handleChange}
        columns={columns}
        dataSource={pieces?.map((p, i) => ({ ...p, key: i }))}
        // scroll={{ x: 500 }}
        bordered
        size="middle"
        scroll={{ x: 1200 }}
        setSelectedRowKeys
        className="table-striped-rows"
        loading={reloadTable}
        // loading={reloadClients}
      />
      <Modal
        title={titleModal}
        visible={modalIsVisible}
        setIsVisible={setModalIsVisible}
        width={1000}
      >
        {contextModal}
      </Modal>
    </div>
  );
};

export default DimensionsOperationTable;
