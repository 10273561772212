import React, { useEffect, useState } from 'react';
import {
  Button,
  Table,
  Tooltip,
  Tag,
  Col,
  DatePicker,
  Row,
  Space,
  Input,
} from 'antd';
import {
  MailOutlined,
  EyeOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  SearchOutlined,
  UnorderedListOutlined,
  FileExcelFilled,
  FilePdfFilled,
} from '@ant-design/icons';
import AddEditPricingForm from '../AddEditPricingForm/AddEditPricingForm';
import Modal from '../../General/Modal';
import { METHOD_GET, ROL_LAYOUT, ROL_PRICING } from '../../../utils/constants';
import { API_URL_RFQ } from '../../../helpers/urls';
import { getAccessTokenApi } from '../../../api/auth';
import { fetchApi } from '../../../helpers/fetch';
import moment from 'moment';
import { getCatalogValueHandle } from '../../../helpers/handlerFunctionApi';
import {
  SERVICES_CATALOG_ID,
  TYPE_OF_LOAD_CATALOG_ID,
  TYPE_OF_SERVICE_CATALOG_ID,
} from '../../../helpers/constants';
import Highlighter from 'react-highlight-words';
import useUser from '../../../hooks/useUser';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import SendEmailForm from './SendEmailForm/SendEmailForm';
import XLSX from 'xlsx';
import jsPDF from 'jspdf';
import { getFilteredDataFromLocalStorage, saveFilteredDataToLocalStorage } from '../../../helpers/saveFilterData';

const RfqTable = ({ dateRange }) => {
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [titleModal, setTitleModal] = useState(false);
  const [contextModal, setContextModal] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [rfqs, setRfqs] = useState([]);
  const token = getAccessTokenApi();
  const [isLoadingTable, setIsLoadingTable] = useState(true);
  const [reloadTable, setReloadTable] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [serviceType, setServiceType] = useState([]);
  const [service, setService] = useState([]);
  const [typeOfLoad, setTypeOfLoad] = useState([]);
  const [filterService, setFilterService] = useState([]);
  const [filterServiceType, setFilterServiceType] = useState([]);
  const { person } = useUser();
  const [startDate, setStartDate] = useState({
    createdAt: '',
    startDate: '',
    endDate: '',
  });
  const [endDate, setEndDate] = useState({
    createdAt: '',
    startDate: '',
    endDate: '',
  });
  const x = React.createRef();
  const [searchedColumn, setSearchedColumn] = useState('');
  const [filteredInfo, setFilteredInfo] = useState({});
  const [searchText, setSearchText] = useState('');
  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };
  let history = useHistory();

  useEffect(() => {
    setIsLoadingTable(true);
    const config = {
      method: METHOD_GET,
      url: `${API_URL_RFQ}`,
      data: null,
      token,
      showNotificationError: true,
    };
    fetchApi(config)
      .then((response) => {
        // console.log(response, 'response');
        if (response.statusCode === 200) {
          const allRfqs = response.result;
          allRfqs.map((e) => (e['key'] = e.id));
          setRfqs(allRfqs);
        }
        setIsLoadingTable(false);
        setReloadTable(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoadingTable(false);
        setReloadTable(false);
      });
  }, [reloadTable, token, dateRange]);
  useEffect(() => {
    getCatalogValueHandle(
      TYPE_OF_SERVICE_CATALOG_ID,
      setServiceType,
      setIsLoading,
      0
    );
    getCatalogValueHandle(SERVICES_CATALOG_ID, setService, setIsLoading, 0);
    getCatalogValueHandle(
      TYPE_OF_LOAD_CATALOG_ID,
      setTypeOfLoad,
      setIsLoading,
      0
    );
  }, []);
  useEffect(() => {
    let filters = [];
    service.forEach((element) => {
      const json = { text: element.label, value: element.label };
      filters.push(json);
    });
    setFilterService(filters);
  }, [service]);
  useEffect(() => {
    let filters = [];
    serviceType.forEach((element) => {
      const json = { text: element.label, value: element.label };
      filters.push(json);
    });
    setFilterServiceType(filters);
  }, [serviceType]);
  useEffect(() => {
    const datosObtenidos = getFilteredDataFromLocalStorage('rfq');
    if (datosObtenidos !== null) {
      setFilteredInfo(datosObtenidos.filters);
      setStartDate(datosObtenidos.dateFilters.startDates)
      setEndDate(datosObtenidos.dateFilters.endDates)
    }
  },[])
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const showModal = (type, rfq) => {
    if (type === 'Add') {
      setModalIsVisible(true);
      setTitleModal('Agregar RFQ');
      setContextModal(
        <AddEditPricingForm
          type="Add"
          setModalIsVisible={setModalIsVisible}
          rateId={rfq.id}
          setReloadTable={setReloadTable}
          isRfq={true}
        ></AddEditPricingForm>
      );
    } else if (type === 'Edit') {
      setModalIsVisible(true);
      setTitleModal(`Editar RFQ #${rfq.id}`);
      setContextModal(
        <AddEditPricingForm
          type="Edit"
          setModalIsVisible={setModalIsVisible}
          rateId={rfq.id}
          setReloadTable={setReloadTable}
          isRfq={true}
        ></AddEditPricingForm>
      );
    } else if (type === 'Watch') {
      setModalIsVisible(true);
      setTitleModal(`RFQ #${rfq.id}`);
      setContextModal(
        <AddEditPricingForm
          type="watch"
          setModalIsVisible={setModalIsVisible}
          rateId={rfq.id}
          setReloadTable={setReloadTable}
          isRfq={true}
        ></AddEditPricingForm>
      );
    } else if (type === 'Email') {
      setModalIsVisible(true);
      setTitleModal(`Enviar correo RFQ #${rfq.id}`);
      setContextModal(
        <SendEmailForm
          setModalIsVisible={setModalIsVisible}
          rfq={rfq}
        ></SendEmailForm>
      );
    }
  };
  const getColumnSearchDateProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Col>
          <DatePicker
            ref={x}
            id={'startDate' + dataIndex}
            placeholder={'Fecha inicial'}
            style={{ marginRight: '10px' }}
            allowClear={false}
            format="DD/MM/YYYY"
            value={
              startDate[dataIndex] !== ''
                ? moment.unix(startDate[dataIndex])
                : null
            }
            onChange={(e) => {
              // setStartDate(moment(e).unix());
              startDate[dataIndex] = moment(e).startOf('day').unix();
              setSelectedKeys(
                rfqs
                  .filter(
                    (op) =>
                      moment(e)
                        .hours(0)
                        .minutes(0)
                        .seconds(0)
                        .milliseconds(0)
                        .unix() <= op[dataIndex]
                  )
                  .map((op) => op.key)
              );

              // setSelectedKeys(e !== null ? [e.format('DD/MM/YYYY')] : [])
            }}
          />
          <DatePicker
            placeholder={'Fecha final'}
            format="DD/MM/YYYY"
            allowClear={false}
            value={
              endDate[dataIndex] !== '' ? moment.unix(endDate[dataIndex]) : null
            }
            onChange={(e) => {
              // setEndDate(moment(e).unix());
              endDate[dataIndex] = moment(e).endOf('day').unix();
              setSelectedKeys(
                rfqs
                  .filter(
                    (op) =>
                      op[dataIndex] <=
                      moment(e)
                        .hours(23)
                        .minutes(59)
                        .seconds(59)
                        .milliseconds(0)
                        .unix()
                  )
                  .map((op) => op.key)
              );
            }}
          />
        </Col>
        <Row justify="end" gutter={16}>
          <Col>
            <Button
            disabled={endDate[dataIndex] === ''}
              type="primary"
              onClick={() => {
                handleSearchDate(selectedKeys, confirm);
                setSelectedKeys(
                  rfqs
                    .filter(
                      (d) =>
                        startDate[dataIndex] <= d[dataIndex] &&
                        d[dataIndex] <= endDate[dataIndex]
                    )
                    .map((d) => d.key)
                );
              }}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Buscar
            </Button>
            <Button
              onClick={() => {
                handleReset(clearFilters, selectedKeys, confirm, dataIndex);
                handleSearchDate(selectedKeys, confirm, dataIndex);
              }}
              size="small"
              style={{ width: 90 }}
            >
              Limpiar
            </Button>
          </Col>
        </Row>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      startDate[dataIndex] <= record[dataIndex] &&
      record[dataIndex] <= endDate[dataIndex],
    filteredValue: filteredInfo[dataIndex] || null,
    onFilterDropdownVisibleChange: (visible) => {},
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? moment.unix(text).format('DD/MM/YYYY') : ''}
        />
      ) : text ? (
        moment.unix(text).format('DD/MM/YYYY')
      ) : (
        ''
      ),
  });
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            // this.searchInput = node;
          }}
          placeholder={`Buscar`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => {
              handleReset(clearFilters);
              handleSearch(selectedKeys, confirm, dataIndex);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Limpiar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    filteredValue: filteredInfo[dataIndex] || null,
    onFilterDropdownVisibleChange: (visible) => {},
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  const columns = [
    {
      title: 'No. RFQ',
      dataIndex: 'id',
      key: 'id',
      width: 90,
      align: 'center',
      // fixed: 'left',
      ...getColumnSearchProps('operationIdStr'),
    },

    // {
    //   title: 'Estatus',
    //   dataIndex: 'isCompleted',
    //   key: 'isCompleted',
    //   width: 120,
    //   // fixed: 'left',
    //   filters: [
    //     {
    //       text: 'Completada',
    //       value: true,
    //     },
    //     { text: 'Incompleta', value: false },
    //   ],
    //   filteredValue: filteredInfo.isCompleted || null,
    //   onFilter: (value, record) => record.isCompleted === value,
    //   align: 'center',
    //   render: (isCompleted) => (
    //     <Tag
    //       icon={isCompleted ? <CheckCircleOutlined /> : <CloseCircleOutlined />}
    //       color={isCompleted ? 'green' : 'red'}
    //     >
    //       {isCompleted ? 'Completa' : 'Incompleta'}
    //     </Tag>
    //   ),
    // },
    {
      title: 'Respuestas',
      dataIndex: 'showResponses',
      key: 'showResponses',
      width: 120,
      // fixed: 'left',
      filters: [
        {
          text: 'Enviadas',
          value: true,
        },
        { text: 'No enviadas', value: false },
      ],
      filteredValue: filteredInfo.showResponses || null,
      onFilter: (value, record) => record.showResponses === value,
      align: 'center',
      render: (showResponses) => (
        <Tag
          icon={
            showResponses ? <CheckCircleOutlined /> : <CloseCircleOutlined />
          }
          color={showResponses ? 'green' : 'red'}
        >
          {showResponses ? 'Enviadas' : 'No enviadas'}
        </Tag>
      ),
    },

    {
      title: 'No. Cotización',
      dataIndex: 'quoteId',
      key: 'quoteId',
      width: 110,
      align: 'center',
      // fixed: 'left',
      ...getColumnSearchProps('quoteId'),
    },
    {
      title: 'Solicitado por:',
      dataIndex: 'requestedBy',
      key: 'requestedBy',
      width: 140,
      align: 'center',
      // fixed: 'left',
      ...getColumnSearchProps('requestedBy'),
    },
    {
      title: 'Servicio',
      dataIndex: 'service',
      key: 'service',
      width: 100,
      // fixed: 'left',
      filters: filterService,
      filteredValue: filteredInfo.service || null,
      onFilter: (value, record) => record.service === value,
    },
    {
      title: 'Tipo de servicio',
      dataIndex: 'typeOfService',
      key: 'typeOfService',
      width: 100,
      // fixed: 'left',
      align: 'center',
      filters: filterServiceType,
      filteredValue: filteredInfo.typeOfService || null,
      onFilter: (value, record) => record.typeOfService === value,
    },

    {
      title: 'Origen',
      children: [
        {
          title: 'País',
          dataIndex: 'originCountry',
          key: 'originCountry',
          width: 100,
          align: 'center',
        },
        {
          title: 'Ciudad',
          dataIndex: 'originCity',
          key: 'originCity',
          width: 100,
          align: 'center',
        },
        {
          title: 'CP',
          dataIndex: 'originZipCode',
          key: 'originZipCode',
          width: 100,
          align: 'center',
        },
        {
          title: 'Puerto',
          dataIndex: 'originPort',
          key: 'originPort',
          width: 100,
          align: 'center',
        },
        {
          title: 'Aeropuerto',
          dataIndex: 'originAirport',
          key: 'originAirport',
          width: 100,
          align: 'center',
        },
        {
          title: 'Dirección',
          dataIndex: 'originAddress',
          key: 'originAddress',
          width: 100,
          align: 'center',
        },
      ],
    },
    {
      title: 'Destino',
      children: [
        {
          title: 'País',
          dataIndex: 'destinationCountry',
          key: 'destinationCountry',
          width: 100,
          align: 'center',
        },
        {
          title: 'Ciudad',
          dataIndex: 'destinationCity',
          key: 'destinationCity',
          width: 100,
          align: 'center',
        },
        {
          title: 'CP',
          dataIndex: 'destinationZipCode',
          key: 'destinationZipCode',
          width: 100,
          align: 'center',
        },
        {
          title: 'Puerto',
          dataIndex: 'destinationPort',
          key: 'destinationPort',
          width: 100,
          align: 'center',
        },
        {
          title: 'Aeropuerto',
          dataIndex: 'destinationAirport',
          key: 'destinationAirport',
          width: 100,
          align: 'center',
        },
        {
          title: 'Dirección',
          dataIndex: 'destinationAddress',
          key: 'destinationAddress',
          align: 'center',
          width: 100,
        },
      ],
    },
    // {
    //   title: 'Costo',
    //   dataIndex: 'unitPrice',
    //   key: 'unitPrice',
    //   width: 100,
    //   // fixed: 'right',
    //   align: 'center',
    //   render: (unitPrice) => <span>${formatMoney(unitPrice)}</span>,
    // },
    {
      title: 'Fecha creación',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 100,
      align: 'center',
      render: (createdAt) => <>{moment.unix(createdAt).format('DD/MM/YYYY')}</>,
      ...getColumnSearchDateProps('createdAt'),
    },
    // {
    //   title: 'Fecha inicial',
    //   dataIndex: 'startDate',
    //   key: 'startDate',
    //   width: 100,
    //   align: 'center',
    //   render: (startDate) => (
    //     <>
    //       {startDate !== null
    //         ? moment.unix(startDate).format('DD/MM/YYYY')
    //         : null}
    //     </>
    //   ),
    //   ...getColumnSearchDateProps('startDate'),
    // },
    // {
    //   title: 'Fecha final',
    //   dataIndex: 'endDate',
    //   key: 'endDate',
    //   width: 100,
    //   // fixed: 'right',
    //   align: 'center',
    //   render: (endDate) => <>{moment.unix(endDate).format('DD/MM/YYYY')}</>,
    //   ...getColumnSearchDateProps('endDate'),
    // },
    {
      title: 'Acciones',
      key: 'operation',
      fixed: 'right',
      width: 140,
      align: 'center',
      render: (rfq) => (
        <>
          {[ROL_PRICING, ROL_LAYOUT].some((r) => person.roles.includes(r)) && (
            <>
              {/* {!rfq.isCompleted && ( */}
              {/* <Tooltip title="Editar">
                <Button
                  disabled={rfq.isCompleted}
                  style={{ marginLeft: 2 }}
                  type="primary"
                  shape="default"
                  icon={<EditOutlined />}
                  onClick={() => showModal('Edit', rfq)}
                />
              </Tooltip> */}
              {/* )} */}
            </>
          )}
          <Tooltip title="Respuestas">
            <Button
              style={{ marginLeft: 10 }}
              type="primary"
              shape="default"
              icon={<UnorderedListOutlined />}
              onClick={() =>
                history.push(`/pricing/respuestas/rfq/${rfq.id}/${rfq.quoteId}`)
              }
            />
          </Tooltip>
          <Tooltip title="Ver más información">
            <Button
              style={{ marginLeft: 10 }}
              type="primary"
              ghost
              shape="default"
              icon={<EyeOutlined />}
              onClick={() => showModal('Watch', rfq)}
            />
          </Tooltip>
          {[ROL_PRICING, ROL_LAYOUT].some((r) => person.roles.includes(r)) && (
            <Tooltip title="Enviar correo">
              <Button
                style={{ marginLeft: 10 }}
                type="primary"
                ghost
                shape="default"
                icon={<MailOutlined />}
                onClick={() => showModal('Email', rfq)}
              />
            </Tooltip>
          )}
        </>
      ),
    },
  ];
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleSearchDate = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };
  const handleReset = (clearFilters, selectedKeys, confirm, dataIndex) => {
    clearFilters();
    setSearchText('');
    startDate[dataIndex] = '';
    endDate[dataIndex] = '';
  };
  const handleChange = (pagination, filters, sorter, extra) => {
    const dateFilters = { startDates: startDate, endDates: endDate}
    saveFilteredDataToLocalStorage('rfq', pagination, sorter, filters,dateFilters);
    setFilteredInfo(filters);
  };

  const exportPdf = (quotes, startDate, endDate) => {
    let mstrTitulo = '';
    let mstrPdf = '';
    mstrTitulo = `RFQS`;
    mstrPdf = `RFQS-${moment().format('L')}.pdf`;

    var registros = [];
    quotes.forEach((element) => {
      registros.push([
        element.id,
        element.showResponses === true ? 'Enviadas' : 'No enviadas',
        element.quoteId,
        element.requestedBy,
        element.service,
        element.typeOfService,
        element.originCity,
        element.destinationCity,
        moment.unix(element.createdAt).format('DD/MM/YYYY'),
      ]);
    });

    let fecha = moment().format('LLL');
    let doc = new jsPDF('landscape');
    doc.setFontSize(12);

    let xFecha = 220;
    let yFecha = 10;

    let totalPagesExp = '{total_pages_count_string}';

    let pageContent = function (data) {
      //header
      doc.text(15, 10, mstrTitulo);
      doc.text(-10, -10, mstrTitulo);
      doc.text(fecha, xFecha, yFecha);
      // FOOTER
      let str = 'Página ' + data.pageCount;
      //Total page number plugin only available in jspdf v1.0+
      if (typeof doc.putTotalPages === 'function') {
        str = str + ' de ' + totalPagesExp;
      }
      doc.setFontSize(10);
      doc.text(
        str,
        data.settings.margin.left,
        doc.internal.pageSize.height - 10
      );
    };
    doc.autoTable({
      A3: { v: 'No. RFQ', t: 's' },
      B3: { v: 'Respuestas', t: 's' },
      C3: { v: 'No. Cotización', t: 's' },
      D3: { v: 'Solicitado por', t: 's' },
      E3: { v: 'Servicio', t: 's' },
      F3: { v: 'Tipo de servicio', t: 's' },
      G3: { v: 'Ciudad origen', t: 's' },
      H3: { v: 'Ciudad destino', t: 's' },
      I3: { v: 'Fecha creación', t: 's' },
      head: [
        [
          'No. RFQ',
          'Respuestas',
          'No. Cotización',
          'Solicitado por',
          'Servicio',
          'Tipo de servicio',
          'Ciudad origen',
          'Ciudad destino',
          'Fecha creación',
        ],
      ],
      didDrawPage: pageContent,
      theme: 'grid',
      headStyles: {
        valign: 'middle',
        halign: 'center',
        fillColor: [230, 81, 0],
        textColor: [255, 255, 255],
        // cellWidth: { 3: 200 },
      },
      body: registros,
      columnStyles: {
        text: {
          cellWidth: 'wrap',
        },
      },
    });
    // call footer() after each doc.addPage()
    // and before doc.save() do not forget put
    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(totalPagesExp);
    }
    //spinner.style.display = 'none';
    doc.save(mstrPdf);
  };
  const generateExcel = (entries) => {
    let filename = '';
    let mstrTitulo = '';
    filename = `RFQS-${moment().format('L')}.xlsx`;
    mstrTitulo = `RFQS`;

    let fecha = moment().format('LLL');
    const ws_name = 'Hoja1';
    let wb = {
      SheetNames: [],
      Sheets: {},
    };
    let ws = {};
    let renInicial = 4;

    ws = {
      A1: { v: mstrTitulo, t: 's' },
      I1: { v: fecha, t: 's' },

      A3: { v: 'No. RFQ', t: 's' },
      B3: { v: 'Respuestas', t: 's' },
      C3: { v: 'No. Cotización', t: 's' },
      D3: { v: 'Solicitado por', t: 's' },
      E3: { v: 'Servicio', t: 's' },
      F3: { v: 'Tipo de servicio', t: 's' },
      G3: { v: 'Ciudad origen', t: 's' },
      H3: { v: 'Ciudad destino', t: 's' },
      I3: { v: 'Fecha creación', t: 's' },
    };
    for (let i in entries) {
      const {
        id,
        showResponses,
        quoteId,
        requestedBy,
        service,
        typeOfService,
        originCity,
        destinationCity,
        createdAt,
      } = entries[i];
      ws['A' + renInicial] = { v: id, t: 's' };
      ws['B' + renInicial] = {
        v: showResponses === true ? 'Enviadas' : 'No enviadas',
        t: 's',
      };
      ws['C' + renInicial] = { v: quoteId, t: 's' };
      ws['D' + renInicial] = { v: requestedBy, t: 's' };
      ws['E' + renInicial] = { v: service, t: 's' };
      ws['F' + renInicial] = { v: typeOfService, t: 's' };
      ws['G' + renInicial] = { v: originCity, t: 's' };
      ws['H' + renInicial] = { v: destinationCity, t: 's' };
      ws['I' + renInicial] = {
        v: moment.unix(createdAt).format('DD/MM/YYYY'),
        t: 's',
      };
      renInicial++;
    }
    // var wscols = [{ wch: 30 }, { wch: 40 }, { wch: 35 }, { wch: 35 }];
    // ws['!cols'] = wscols;
    ws['!ref'] = XLSX.utils.encode_range({
      s: { c: 0, r: 0 },
      e: { c: 15, r: renInicial },
    });
    wb.SheetNames.push(ws_name);
    wb.Sheets[ws_name] = ws;
    XLSX.writeFile(wb, filename, { cellStyles: true });
    // setExcelLoading(false);
  };
  return (
    <div>
      {/* <Button
                className="btn-download"
                onClick={() => showModal('Add')}
                icon={<PlusCircleOutlined />}
            >
                Agregar
            </Button>
            <Button
                disabled={!hasSelected}
                className="btn-delete"
                onClick={() => showModal('Edit')}
                icon={<DeleteOutlined />}
            >
                Eliminar
            </Button>
            <Button className="btn-download" icon={<ReloadOutlined />}>
                Refrescar
            </Button>
            {selectedRowKeys.length > 0 && (
                <p>Seleccionados: {selectedRowKeys.length} elementos</p>
            )} */}

      <Table
        // rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}

        dataSource={rfqs}
        columns={columns}
        bordered
        size="middle"
        scroll={{ x: 1200 }}
        setSelectedRowKeys
        className="table-striped-rows"
        onChange={handleChange}
        loading={isLoadingTable}
        // rowSelection={rowSelection}
      />
      <Row>
        <Col span={24} style={{ textAlign: 'right' }}>
          <Button
            icon={<FilePdfFilled style={{ fontSize: 18 }} />}
            className="btn-download"
            onClick={() => exportPdf(rfqs)}
            disabled={rfqs.length === 0}
            style={{ width: 150 }}
          >
            Descargar Pdf
          </Button>
          <Button
            icon={<FileExcelFilled style={{ fontSize: 18 }} />}
            className="btn-download"
            onClick={() => generateExcel(rfqs)}
            disabled={rfqs.length === 0}
            style={{ width: 160 }}
          >
            Descargar Excel
          </Button>
        </Col>
      </Row>
      <Modal
        title={titleModal}
        visible={modalIsVisible}
        setIsVisible={setModalIsVisible}
        width={1000}
      >
        {contextModal}
      </Modal>
    </div>
  );
};

export default RfqTable;
