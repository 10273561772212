import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Spin, Row } from 'antd';
import { METHOD_GET, METHOD_PUT } from '../../../../../../utils/constants';
import { fetchApi } from '../../../../../../helpers/fetch';
import { getAccessTokenApi } from '../../../../../../api/auth';
import { API_URL_ADDITIONAL_SERVICE } from '../../../../../../helpers/urls';

const EditValuesASForm = ({
  setReloadCatalogValues,
  setModalIsVisible,
  valueField,
}) => {
  const [loading, setLoading] = useState(false);
  const token = getAccessTokenApi();
  const [form] = Form.useForm();

  const getValueAdditionService = () => {
    setLoading(true);
    const config = {
      method: METHOD_GET,
      url: `${API_URL_ADDITIONAL_SERVICE}/${valueField.id}`,
      data: null,
      token,
      // showNotification: true
    };
    fetchApi(config)
      .then((response) => {
        if (response?.statusCode === 200) {
          let resource = response.result;

          form.setFieldsValue({
            Name: resource.name,
            Code: resource.code,
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getValueAdditionService();
  }, []);

  const onFinish = async (values) => {
    setLoading(true);

    values['id'] = valueField.id;

    const config = {
      method: METHOD_PUT,
      url: `${API_URL_ADDITIONAL_SERVICE}/${valueField.id}`,
      data: values,
      token,
      showNotification: true,
    };
    const result = await fetchApi(config);

    if (result) {
      if (result.statusCode === 200) {
        // form.resetFields();
        setModalIsVisible(false);
        setReloadCatalogValues(true);
      }
    }

    setLoading(false);
  };

  return (
    <div className="add-catalog-form">
      <Spin spinning={loading} tip="Cargando...">
        <Form
          name="EditAdditionServiceCatalog"
          form={form}
          onFinish={onFinish}
          layout="vertical"
          // autoComplete="off"
        >
          <Form.Item
            label="Nombre"
            name="Name"
            rules={[{ required: true, message: 'Por favor ingresa un nombre' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Código"
            name="Code"
            rules={[
              { required: false, message: 'Por favor ingresa un código' },
            ]}
          >
            <Input />
          </Form.Item>

          <Row justify="end">
            <Form.Item>
              <Button className="btn-enviar" htmlType="submit">
                Guardar
              </Button>
            </Form.Item>
          </Row>
        </Form>
      </Spin>
    </div>
  );
};

export default EditValuesASForm;
