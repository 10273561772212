import React, { useState, useEffect } from 'react';
import { Button, Modal as ModalAntd, Space, Table, Tooltip } from 'antd';
import {
  ReloadOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
  ExclamationCircleOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import Modal from '../../../General/Modal';
import { fetchApi } from '../../../../helpers/fetch';
import { getAccessTokenApi } from '../../../../api/auth';
import {
  METHOD_DELETE,
  METHOD_GET,
  ROL_ADMIN,
  ROL_LAYOUT,
  ROL_PRICING,
} from '../../../../utils/constants';
import AddAdditionalService from './AddAdditionalService/AddAdditionalService';
import EditAdditionalService from './EditAdditionalService/EditAdditionalService';
import { API_URL_SYPPLIER_ADDITIONAL_SERVICE } from '../../../../helpers/urls';
import { formatMoney } from '../../../../utils/general';
import useUser from '../../../../hooks/useUser';

const SupplierAdditionalServices = ({ supplier }) => {
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [reloadAdditionalServices, setReloadAdditionalServices] =
    useState(true);
  const [titleModal, setTitleModal] = useState(false);
  const [contextModal, setContextModal] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  // const hasSelected = selectedRowKeys.length > 0;
  const { confirm } = ModalAntd;
  const token = getAccessTokenApi();
  let { supplierId } = useParams();
  const [additionalServices, setAdditionalServices] = useState([]);
  const { person } = useUser();

  // const onSelectChange = (selectedRowKeys) => {
  //   setSelectedRowKeys(selectedRowKeys);
  // };

  // const rowSelection = {
  //   selectedRowKeys,
  //   onChange: onSelectChange,
  // };

  useEffect(() => {
    const config = {
      method: METHOD_GET,
      url: `${API_URL_SYPPLIER_ADDITIONAL_SERVICE(supplierId)}`,
      data: null,
      token,
    };
    fetchApi(config)
      .then((response) => {
        if (response?.statusCode === 200) {
          let supServices = response.result;

          if (supServices) {
            supServices.map((cat, index) => {
              cat['key'] = index;
              return cat;
            });
          }
          setAdditionalServices(supServices);
        }
        setReloadAdditionalServices(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [reloadAdditionalServices, supplierId, token]);

  const showModal = (type, additionServiceId) => {
    if (type === 'Add') {
      setModalIsVisible(true);
      setTitleModal(
        `Agregar servicio adicional al proveedor:  ${supplier.name}`
      );
      setContextModal(
        <AddAdditionalService
          setModalIsVisible={setModalIsVisible}
          setReloadAdditionalServices={setReloadAdditionalServices}
          supplierId={supplierId}
          supplierName={supplier.name}
        ></AddAdditionalService>
      );
    } else if (type === 'Edit') {
      setModalIsVisible(true);
      setTitleModal(`Editar servicio adicional proveedor ${supplier.name}`);
      setContextModal(
        <EditAdditionalService
          setModalIsVisible={setModalIsVisible}
          setReloadAdditionalServices={setReloadAdditionalServices}
          additionServiceId={additionServiceId}
          supplierId={supplierId}
          supplierName={supplier.name}
        ></EditAdditionalService>
      );
    }
  };

  const columns = [
    {
      title: 'Servicio adicional',
      dataIndex: 'name',
      key: 'name',
      width: 100,
      align: 'center',
      fixed: 'left',
    },
    {
      title: 'Monto',
      dataIndex: 'cost',
      key: 'cost',
      width: 100,
      align: 'center',
      render: (cost) => <span>$ {formatMoney(cost)}</span>,
    },
    {
      title: 'Acción',
      key: 'action',
      width: 100,
      render: (text, record) => (
        <>
          {[ROL_PRICING, ROL_ADMIN, ROL_LAYOUT].some((r) =>
            person.roles.includes(r)
          ) && (
            <>
              <Space size="middle">
                <Tooltip title="Editar">
                  <Button
                    onClick={() => showModal('Edit', record.id)}
                    type="primary"
                  >
                    <EditOutlined />
                  </Button>
                </Tooltip>

                <Tooltip title="Eliminar">
                  <Button
                    danger
                    type="primary"
                    onClick={() => handleDeleteAdditionalService(record.id)}
                  >
                    <DeleteOutlined />
                  </Button>
                </Tooltip>
              </Space>
            </>
          )}
        </>
      ),
      align: 'center',
    },
  ];

  const handleDeleteAdditionalService = (id) => {
    confirm({
      title: 'Eliminar servicio adicional',
      icon: <ExclamationCircleOutlined />,
      content:
        '¿Estás seguro que deseas eliminar el servicio adicional seleccionado?',
      okText: 'Si',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteAdditionalService(id);
      },
      onCancel() {},
    });
  };

  const deleteAdditionalService = async (id) => {
    const config = {
      method: METHOD_DELETE,
      url: `${API_URL_SYPPLIER_ADDITIONAL_SERVICE(supplierId)}/${id}`,
      data: null,
      token,
      showNotification: true,
    };
    const result = await fetchApi(config);

    if (result) {
      if (result.statusCode === 200) {
        setReloadAdditionalServices(true);
      }
    }
  };

  return (
    <div>
      {[ROL_PRICING, ROL_ADMIN, ROL_LAYOUT].some((r) =>
        person.roles.includes(r)
      ) && (
        <Button
          className="btn-download"
          onClick={() => showModal('Add')}
          icon={<PlusCircleOutlined />}
        >
          Agregar
        </Button>
      )}
      {/* <Button
                disabled={!hasSelected}
                className="btn-delete"
                onClick={(e) => handleDeleteContact(rowSelection)}
                icon={<DeleteOutlined />}
            >
                Eliminar
            </Button> */}
      <Button
        className="btn-download"
        icon={<ReloadOutlined />}
        onClick={(e) => setReloadAdditionalServices(true)}
      >
        Refrescar
      </Button>
      {selectedRowKeys.length > 0 && (
        <p>Seleccionados: {selectedRowKeys.length} elementos</p>
      )}
      {/* <Button className="btn-download" onClick={() => showModal('Edit')}>
                Clientes
            </Button> */}
      <Table
        // rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
        dataSource={additionalServices}
        columns={columns}
        bordered
        size="middle"
        scroll={{ x: 1200 }}
        // setSelectedRowKeys
        className="table-striped-rows"
        // rowSelection={rowSelection}
        loading={reloadAdditionalServices}
      />

      <Modal
        title={titleModal}
        visible={modalIsVisible}
        setIsVisible={setModalIsVisible}
        width={1000}
      >
        {contextModal}
      </Modal>
    </div>
  );
};

export default SupplierAdditionalServices;
