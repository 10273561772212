import React from 'react';
import { Col, Layout, Row, Typography } from 'antd';
import GreetingsTop from '../../../components/Admin/GreetingsTop/GreetingsTop';
import DatePickerOptions from '../../../components/General/DatePickerOptions/DatePickerOptions';
import TrafficTable from '../../../components/Logistic/TrafficTable';
export default function Traffic() {
    return (
        <Layout>
            <GreetingsTop />

            <HeaderTop />

            <div className="billing__container">
                <TrafficTable />
            </div>
        </Layout>
    );
}

function HeaderTop() {
    const { Title } = Typography;
    return (
        <div className="billing__container">
            <Row className="header-top">
                <Col xs={24} md={12} className="header-top__left">
                    <Title level={3}>Tráfico</Title>
                </Col>
                <Col
                    xs={20}
                    md={12}
                    style={{
                        display: 'grid',
                        alignItems: 'center',
                        justifyContent: 'end',
                    }}
                >
                    <Row xs={24} md={12} style={{ marginBottom: 10 }}>
                        <p xs={24} style={{ marginRight: 20 }}>
                            Fecha:
                        </p>
                        <DatePickerOptions
                            xs={24}
                            // filterDate={filterDate}
                            typeDoc={'Documento'}
                            // dateDisplay={dateReceive}
                        />
                    </Row>
                </Col>
            </Row>
        </div>
    );
}
