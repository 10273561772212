/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Table,
  Button,
  notification,
  Spin,
  Input,
  Space,
  Modal as ModalAntd,
  Checkbox,
  Tooltip,
  Tag,
} from 'antd';
import {
  SearchOutlined,
  FileTextOutlined,
  TeamOutlined,
  EyeOutlined,
  CarOutlined,
  FileExcelFilled,
  FilePdfFilled,
} from '@ant-design/icons';
import { getAccessTokenApi, logout } from '../../../../api/auth';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { useHistory } from 'react-router-dom';
import { getCustomersFinancialApi } from '../../../../api/financial';
import useUser from '../../../../hooks/useUser';
import { deleteClientApi, getAllCustomersApi } from '../../../../api/clients';
import {
  ROL_ADMIN,
  ROL_LAYOUT,
  ROL_MANAGER,
  ROL_OPERACIONES,
  ROL_VENTAS,
} from '../../../../utils/constants';
import AddClientForm from '../../../Admin/Administration/ClientsTable/AddClientForm/AddClientForm';
import EditClientForm from '../../../Admin/Administration/ClientsTable/EditClientForm/EditClientForm';
import Modal from '../../../General/Modal';
import { isMobile, messageErrorGeneral } from '../../../../utils/general';
import moment from 'moment';
import XLSX from 'xlsx';
import jsPDF from 'jspdf';
import { getFilteredDataFromLocalStorage, saveFilteredDataToLocalStorage } from '../../../../helpers/saveFilterData';

export default function CustomersTable(props) {
  const { pickCustomer, setClientSelectedQuote, setModalIsVisibleQuote } =
    props;
  const [reloadCustomers, setReloadCustomers] = useState(true);
  const [usersTable, setUsersTable] = useState([]);
  const [loadingForm, setLoadingForm] = useState(false);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [titleModal, setTitleModal] = useState(false);
  const [contextModal, setContextModal] = useState(null);
  const [clientSelected, SetClientSelected] = useState(null);
  const [disabledButton, setDisabledButton] = useState(false);
  const { confirm } = ModalAntd;
  const history = useHistory();
  const { person } = useUser();
  const classButtonDownload = isMobile()
    ? 'btn-download-mobile'
    : 'btn-download';
  const classButtonDelete = isMobile() ? 'btn-delete-mobile' : 'btn-delete';
  useEffect(() => {
    if (reloadCustomers) {
      const token = getAccessTokenApi();
      setLoadingForm(true);
      if (
        [ROL_ADMIN, ROL_MANAGER, ROL_VENTAS, ROL_OPERACIONES, ROL_LAYOUT].some(
          (r) => person?.roles.includes(r)
        )
      ) {
        getAllCustomersApi(token).then((response) => {
          // console.log(response, 'response');
          if (response) {
            if (response.statusCode === 200) {
              const allClients = response.result;
              allClients.forEach((element, index) => {
                element['key'] = index;
              });
              if (pickCustomer) {
                const activeClients = allClients?.filter(
                  (client) => client.active
                );
                setUsersTable(activeClients);
              } else {
                setUsersTable(allClients);
              }
            }
          } else {
            if (response?.statusCode === 401) {
              notification['error']({
                message: 'Usuario no autorizado',
              });

              setTimeout(() => {
                logout();
                window.location.reload();
              }, 1500);
              return;
            }

            notification['error']({
              message: messageErrorGeneral(),
            });
          }
          setReloadCustomers(false);
          setLoadingForm(false);
        });
      } else {
        getCustomersFinancialApi(token, person.idUser).then((response) => {
          if (response) {
            if (response.statusCode === 401) {
              notification['error']({
                message: 'Usuario no autorizado',
              });

              setTimeout(() => {
                logout();
                window.location.reload();
              }, 1500);
              return;
            }
            if (response.statusCode === 200) {
              response.result.forEach((element, index) => {
                element['key'] = index;
              });
              setUsersTable(response.result);
            }
            setReloadCustomers(false);
            setLoadingForm(false);
          } else {
            notification['error']({
              message: messageErrorGeneral(),
            });
            setReloadCustomers(false);
            setLoadingForm(false);
          }
        });
      }
    }
  }, [reloadCustomers]);
  useEffect(() => {
    const datosObtenidos = getFilteredDataFromLocalStorage('customers');
    if (datosObtenidos !== null && !pickCustomer) {
    setFilteredInfo(datosObtenidos.filters);
    }
  },[])
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            // this.searchInput = node;
          }}
          placeholder={`Buscar`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => {
              handleReset(clearFilters);
              handleSearch(selectedKeys, confirm, dataIndex);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Limpiar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    filteredValue: filteredInfo[dataIndex] || null,
    onFilterDropdownVisibleChange: (visible) => {},
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  const checkClient = (id, client) => {
    if (client.active === false) {
      setDisabledButton(true);
    } else {
      setDisabledButton(false);
    }
    if (clientSelected === id) {
      SetClientSelected(null);
      if (pickCustomer) {
        setClientSelectedQuote(null);
      }
    } else {
      SetClientSelected(id);
      if (pickCustomer) {
        setClientSelectedQuote(client);
      }
    }
  };
  const columns = [
    {
      title: 'Selecciona',
      width: 100,
      dataIndex: 'id',
      key: 'company',
      render: (id, client) => (
        <Checkbox
          // disabled={!clientsTable?.find((st) => st.id === id)?.active}
          onChange={(val) => checkClient(id, client)}
          checked={clientSelected === id}
          className="button-detail"
        ></Checkbox>
      ),
    },
    {
      title: 'Id. cliente',
      width: 150,
      dataIndex: 'id',
      key: 'id',
      ...getColumnSearchProps('id'),
      render: (id) => id?.split('-')[0],
      ellipsis: true,
    },
    {
      title: 'Nombre(s)',
      dataIndex: 'name',
      key: 'name',
      ...getColumnSearchProps('name'),
      width: 250,
      ellipsis: true,
    },
    {
      title: 'Tipo',
      dataIndex: 'prospect',
      key: 'prospect',
      filters: [
        { text: 'Prospecto', value: true },
        { text: 'Cliente', value: false },
      ],
      filteredValue: filteredInfo.prospect || null,
      onFilter: (value, record) => record.prospect === value,
      render: (prospect) => (
        <Tag color={prospect === true ? 'red' : 'green'}>
          {prospect ? 'PROSPECTO' : 'CLIENTE'}
        </Tag>
      ),
      width: 150,
    },
    {
      title: 'Estatus',
      dataIndex: 'active',
      key: 'active',
      align: 'center',
      width: 100,
      // filters: [
      //    {
      //       text: 'Activo',
      //       value: true,
      //    },
      //    { text: 'Inactivo', value: false },
      // ],
      // onFilter: (value, record) => record.active == value,
      ellipsis: true,
      render: (active) => (
        <Tag color={active ? 'green' : 'red'}>
          {active ? 'ACTIVO' : 'INACTIVO'}
        </Tag>
      ),
    },
    {
      title: 'RFC',
      dataIndex: 'fiscalID',
      key: 'fiscalID',
      ...getColumnSearchProps('fiscalID'),
      width: 150,
      ellipsis: true,
    },
    {
      title: 'Contactos',
      key: 'contacts',
      fixed: isMobile() ? '' : 'right',
      ellipsis: true,
      width: pickCustomer ? 0 : 80,
      align: 'center',
      render: (contact, contactId) => (
        <>
          <Tooltip title="Contactos">
            <Button
              style={{ marginLeft: 2 }}
              type="primary"
              shape="default"
              icon={<TeamOutlined />}
              onClick={() => history.push(`/clientes/contactos/${contact.id}`)}
              disabled={pickCustomer}
            />
          </Tooltip>
        </>
      ),
    },
    {
      title: 'Rutas',
      key: 'routes',
      fixed: isMobile() ? '' : 'right',
      ellipsis: true,
      width: pickCustomer ? 0 : 50,
      align: 'center',
      render: (client, contactId) => (
        <>
          <Tooltip title="Rutas">
            <Button
              style={{
                marginLeft: 2,
                backgroundColor: client.hasRoutes ? '#1890ff' : '#e63241',
                borderColor: client.hasRoutes ? '#1890ff' : '#cf1322',
              }}
              type="primary"
              shape="default"
              icon={<CarOutlined />}
              onClick={() => history.push(`/clientes/rutas/${client.id}`)}
              disabled={pickCustomer}
            />
          </Tooltip>
        </>
      ),
    },
    {
      title: 'Docs',
      key: 'doc',
      fixed: isMobile() ? '' : 'right',
      ellipsis: true,
      width: pickCustomer ? 0 : 60,
      align: 'center',
      render: (client, contactId) => (
        <>
          <Tooltip title="Documentos">
            <Button
              style={{
                marginLeft: 2,
              }}
              type="primary"
              shape="default"
              icon={<FileTextOutlined />}
              onClick={() => history.push(`/clientes/documentos/${client.id}`)}
              disabled={pickCustomer}
            />
          </Tooltip>
        </>
      ),
    },
    {
      title: 'Ver',
      key: 'sup',
      fixed: 'right',
      width: pickCustomer ? 0 : 50,
      align: 'center',
      render: (client) => (
        <>
          <Tooltip title="Ver más información">
            <Button
              style={{ marginLeft: 10 }}
              type="primary"
              ghost
              shape="default"
              icon={<EyeOutlined />}
              onClick={() => showModal('Watch', client)}
            />
          </Tooltip>
        </>
      ),
    },
  ];
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const handleChange = (pagination, filters, sorter, extra) => {
    saveFilteredDataToLocalStorage('customers', pagination, sorter, filters);
    setFilteredInfo(filters);
  };
  const showModal = (type, client) => {
    if (type === 'Add') {
      setModalIsVisible(true);
      setTitleModal('Agregar cliente');
      setContextModal(
        <AddClientForm
          setModalIsVisible={setModalIsVisible}
          setReloadClients={setReloadCustomers}
        ></AddClientForm>
      );
    } else if (type === 'Edit') {
      if (clientSelected !== null) {
        setModalIsVisible(true);

        setTitleModal('Editar cliente');
        setContextModal(
          <EditClientForm
            setModalIsVisible={setModalIsVisible}
            setReloadClients={setReloadCustomers}
            idClient={clientSelected}
          ></EditClientForm>
        );
      } else if (clientSelected === null) {
        notification['error']({
          message: 'Debes seleccionar un cliente',
        });
      }
    } else if (type === 'Watch') {
      setModalIsVisible(true);
      setTitleModal(`Cliente ${client.name}`);
      setContextModal(
        <EditClientForm
          setModalIsVisible={setModalIsVisible}
          setReloadSuppliers={setReloadCustomers}
          idClient={client.id}
          type="watch"
        ></EditClientForm>
      );
    }
  };
  const handleDeleteClient = (id) => {
    if (clientSelected !== null) {
      confirm({
        title: 'Desactivar cliente',
        icon: <ExclamationCircleOutlined />,
        content:
          '¿Estás seguro que deseas desactivar el cliente seleccionado?, no podrá reactivarse después',
        okText: 'Si',
        okType: 'danger',
        cancelText: 'No',
        onOk() {
          deleteClient(id);
        },
        onCancel() {},
      });
    } else {
      notification['error']({
        message: 'Debes seleccionar un cliente a desactivar',
      });
    }
  };
  const deleteClient = async (id) => {
    const accessToken = getAccessTokenApi();
    const result = await deleteClientApi(accessToken, id);
    if (result?.statusCode === 409) {
      notification['error']({
        message: result.description,
      });
    }
    if (result?.statusCode === 405) {
      notification['error']({
        message: result.description,
      });
    }
    if (result?.statusCode === 404) {
      notification['error']({
        message: result.description,
      });
    }
    if (result?.statusCode === 200) {
      notification['success']({
        message: 'Usuario desactivado exitosamente.',
      });
      SetClientSelected(undefined);
    }
    setReloadCustomers(true);
  };
  const exportPdf = (clients, startDate, endDate) => {
    let mstrTitulo = '';
    let mstrPdf = '';
    mstrTitulo = `Clientes`;
    mstrPdf = `Clientes-${moment().format('L')}.pdf`;

    let registros = [];
    clients.forEach((element) => {
      registros.push([
        element.id,
        element.name,
        element.active ? 'Activo'.toUpperCase() : 'inactivo'.toUpperCase(),
        element.fiscalID,
      ]);
    });

    let fecha = moment().format('LLL');
    let doc = new jsPDF('landscape');
    doc.setFontSize(12);

    let xFecha = 220;
    let yFecha = 10;

    let totalPagesExp = '{total_pages_count_string}';

    let pageContent = function (data) {
      //header
      doc.text(15, 10, mstrTitulo);
      doc.text(-10, -10, mstrTitulo);
      doc.text(fecha, xFecha, yFecha);
      // FOOTER
      let str = 'Página ' + data.pageCount;
      //Total page number plugin only available in jspdf v1.0+
      if (typeof doc.putTotalPages === 'function') {
        str = str + ' de ' + totalPagesExp;
      }
      doc.setFontSize(10);
      doc.text(
        str,
        data.settings.margin.left,
        doc.internal.pageSize.height - 10
      );
    };
    doc.autoTable({
      A3: { v: 'Id. cliente', t: 's' },
      B3: { v: 'Nombre(s)', t: 's' },
      C3: { v: 'Estatus', t: 's' },
      D3: { v: 'RFC', t: 's' },
      head: [['Id. cliente', 'Nombre(s)', 'Estatus', 'RFC']],
      didDrawPage: pageContent,
      theme: 'grid',
      headStyles: {
        valign: 'middle',
        halign: 'center',
        fillColor: [230, 81, 0],
        textColor: [255, 255, 255],
        // cellWidth: { 3: 200 },
      },
      body: registros,
      columnStyles: {
        text: {
          cellWidth: 'wrap',
        },
      },
    });
    // call footer() after each doc.addPage()
    // and before doc.save() do not forget put
    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(totalPagesExp);
    }
    //spinner.style.display = 'none';
    doc.save(mstrPdf);
  };
  const generateExcel = (entries) => {
    // setExcelLoading(true);
    let filename = '';
    let mstrTitulo = '';
    filename = `Clientes-${moment().format('L')}.xlsx`;
    mstrTitulo = `Clientes`;

    let fecha = moment().format('LLL');
    const ws_name = 'Hoja1';
    let wb = {
      SheetNames: [],
      Sheets: {},
    };
    let ws = {};
    let renInicial = 4;

    // var mstrTitulo = `Facturación y notas de crédito del ${dateRange.startDate} al: ${dateRange.endDate}`;

    ws = {
      A1: { v: mstrTitulo, t: 's' },
      E1: { v: fecha, t: 's' },

      A3: { v: 'Id. cliente', t: 's' },
      B3: { v: 'Nombre(s)', t: 's' },
      C3: { v: 'Estatus', t: 's' },
      D3: { v: 'RFC', t: 's' },
    };
    for (let i in entries) {
      const { id, name, active, fiscalID } = entries[i];
      ws['A' + renInicial] = { v: id, t: 's' };
      ws['B' + renInicial] = { v: name, t: 's' };
      ws['C' + renInicial] = {
        v: active ? 'Activo'.toUpperCase() : 'inactivo'.toUpperCase(),
        t: 's',
      };
      ws['D' + renInicial] = { v: fiscalID !== null ? fiscalID : '', t: 's' };
      renInicial++;
    }
    // var wscols = [{ wch: 30 }, { wch: 40 }, { wch: 35 }, { wch: 35 }];
    // ws['!cols'] = wscols;
    ws['!ref'] = XLSX.utils.encode_range({
      s: { c: 0, r: 0 },
      e: { c: 15, r: renInicial },
    });
    wb.SheetNames.push(ws_name);
    wb.Sheets[ws_name] = ws;
    XLSX.writeFile(wb, filename, { cellStyles: true });
    // setExcelLoading(false);
  };
  return (
    <>
      <Spin spinning={loadingForm} tip="Cargando...">
        {[ROL_ADMIN, ROL_VENTAS, ROL_LAYOUT].some((r) =>
          person.roles.includes(r)
        ) && (
          <>
            {!pickCustomer && (
              <Row>
                <Col span={24}>
                  <Button
                    className={classButtonDownload}
                    onClick={() => showModal('Add')}
                  >
                    Agregar
                  </Button>

                  <Button
                    className={classButtonDownload}
                    onClick={() => showModal('Edit')}
                  >
                    Editar
                  </Button>
                  <Button
                    className={classButtonDelete}
                    style={{ marginBottom: 20 }}
                    disabled={disabledButton || clientSelected === null}
                    onClick={() => handleDeleteClient(clientSelected)}
                  >
                    Desactivar
                  </Button>
                </Col>
              </Row>
            )}
          </>
        )}

        <Table
          onChange={handleChange}
          columns={columns}
          dataSource={usersTable}
          loading={reloadCustomers}
          scroll={{ x: 500 }}
          // pagination={{ defaultPageSize: 5 }}
        />
        {pickCustomer && (
          <div className="footer-table">
            <Button
              onClick={() => setModalIsVisibleQuote(false)}
              type="primary"
            >
              Aceptar
            </Button>
          </div>
        )}
        {!pickCustomer && (
          <Row>
            <Col span={24} style={{ textAlign: 'right' }}>
              <Button
                icon={<FilePdfFilled style={{ fontSize: 18 }} />}
                className="btn-download"
                onClick={() => exportPdf(usersTable)}
                disabled={usersTable.length === 0}
                style={{ width: 150 }}
              >
                Descargar Pdf
              </Button>
              <Button
                icon={<FileExcelFilled style={{ fontSize: 18 }} />}
                className="btn-download"
                onClick={() => generateExcel(usersTable)}
                disabled={usersTable.length === 0}
                style={{ width: 160 }}
              >
                Descargar Excel
              </Button>
            </Col>
          </Row>
        )}
        <Modal
          title={titleModal}
          visible={modalIsVisible}
          setIsVisible={setModalIsVisible}
          width={1000}
        >
          {contextModal}
        </Modal>
      </Spin>
    </>
  );
}
