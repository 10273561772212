import React, { useEffect, useState } from 'react';
import {
  Typography,
  Button,
  Upload,
  Row,
  Col,
  message,
  Divider,
  Form,
  Input,
  DatePicker,
  InputNumber,
  notification,
  Spin,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import toBase64 from '../../../../utils/toBase64';
import { getAccessTokenApi, logout } from '../../../../api/auth';
import moment from 'moment';
import { METHOD_POST } from '../../../../utils/constants';
import { fetchApi } from '../../../../helpers/fetch';
import TextArea from 'antd/lib/input/TextArea';

// import './UploadFileForm.scss';

export default function UploadFileEUForm({
  setModalIsVisible,
  setReloadTable,
  operationId,
  showModal,
}) {
  const [fileListPdf, setFileListPdf] = useState([]);
  const [fileListCfdi, setFileListCfdi] = useState([]);
  const [filePDF, setFilePDF] = useState();
  const [uploading, setUploading] = useState(false);
  const { Title, Text } = Typography;
  const [form] = Form.useForm();
  const token = getAccessTokenApi();

  useEffect(() => {
    form.setFieldsValue({
        operationIdStr: operationId,
      });
  }, [form,operationId]);
  
  const pdfChange = (info) => {
    let file = [...info.fileList];
    setFilePDF(file);
  };
  const propsPdf = {
    onRemove: (file) => {
      const indexPdf = fileListPdf.indexOf(file);
      const newFileListPdf = fileListPdf.slice();
      newFileListPdf.splice(indexPdf, 1);
      setFileListPdf(newFileListPdf);
    },
    beforeUpload: (file) => {
      setFileListPdf([file]);
      return false;
    },
    onChange: pdfChange,
  };
  const propsCfdi = {
    onRemove: (file) => {
      const indexCfdi = fileListCfdi.indexOf(file);
      const newFileListCfdi = fileListCfdi.slice();
      newFileListCfdi.splice(indexCfdi, 1);
      setFileListCfdi(newFileListCfdi);
    },
    beforeUpload: (file) => {
      setFileListCfdi([file]);
      return false;
    },
  };
  const addDocFunction = async (values) => {
    setUploading(true);
    if (fileListPdf.length === 0) {
        message.error('Debe de subir todos los archivos.');
        return;
      }
    let data = values;
      setUploading(true);
      const cfdiFilePdf = await toBase64(fileListPdf[0]);
      let cfdiFileCfdi = {
        extension: '',
        stringFilecontent: '',
        contentType: '',
        fileName: '',
      };
      if (fileListCfdi.length !== 0) {
        let stringContent = '';
        stringContent = await toBase64(fileListCfdi[0]);
        stringContent = stringContent.split(',')[1];
        cfdiFileCfdi.stringFilecontent = stringContent;
        cfdiFileCfdi.extension = fileListCfdi[0].name.split('.').pop();
        cfdiFileCfdi.contentType = fileListCfdi[0].type;
        cfdiFileCfdi.fileName = fileListCfdi[0].name;
      }
      if (fileListCfdi.length !== 0) {
          data['cfdiAcuse'] = {
            extension: cfdiFileCfdi.extension,
            stringFilecontent: cfdiFileCfdi.stringFilecontent,
            contentType: cfdiFileCfdi.contentType,
            fileName: cfdiFileCfdi.fileName,
          };
      }

    const date = moment(values.documentDate).format('L')
    const sd = date.split('/');
    data['documentDate'] = `${sd[2]}/${sd[1]}/${sd[0]}`;
    data['cfdiFilePdf'] = cfdiFilePdf.split(',')[1];
    data['currencyId'] = 'USD';
    data['serie'] = '';
    data['documentType'] = 'I';
    data['status'] = 'VIGENTE';
    const config = {
      method: METHOD_POST,
      url: `APInvoice/PostAPInvoice`,
      data: data,
      token,
      showNotificationError: false,
    };
    fetchApi(config)
      .then((response) => {
        if (response.statusCode === 200) {
          notification['success']({
            message: response.description,
          });
        } else {
          notification['error']({
            message: response.description,
          });
        }
        if (response.statusCode === 401) {
          notification['error']({
            message: 'Usuario no autorizado.',
          });
          setTimeout(() => {
            logout();
            window.location.reload();
          }, 1500);
          return false;
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setModalIsVisible(false);
        setUploading(false);
        setReloadTable(true);
      });
  };
  return (
    <Spin spinning={uploading} tip="Guardando...">

    <Form
        name="add-doc"
        layout="vertical"
        form={form}
        onFinish={addDocFunction}
      >
    <div className="upload-file-form">
      <Row>
        <Col xs={24} lg={10}>
          <Title level={5} type="secondary">
            {'Seleccionar archivo (PDF)'}
            <Text type="danger">*</Text>
          </Title>
        </Col>
        <Col xs={24} lg={14} className="upload-file-form__container">
          <Upload {...propsPdf} accept={'.pdf'} maxCount={1} listType="picture">
            <Button
              className="upload-file-btn"
              icon={<UploadOutlined />}
              size="large"
            >
              Subir Archivo
            </Button>
          </Upload>
        </Col>
      </Row>
      <Divider />
          <Row>
            <Col xs={24} lg={10}>
              <Title level={5} type="secondary">
                {
                  'Evidencias '
                }
              </Title>
            </Col>
            <Col xs={24} lg={14} className="upload-file-form__container">
              <Upload
                {...propsCfdi}
                maxCount={1}
                listType="picture"
              >
                <Button
                  className="upload-file-btn"
                  icon={<UploadOutlined />}
                  size="large"
                >
                  Subir Archivo
                </Button>
              </Upload>
            </Col>
          </Row>
      <Divider />

          <Row gutter={16}>
          <Col xs={24} md={12} lg={12}>
                <Form.Item
                label="Id de operación:"
                name="operationIdStr"
                rules={[
                    {
                    required: true,
                    message: 'Por favor ingrese id de operación',
                    },
                ]}
                >
                    <Input placeholder="12589" disabled />
                </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={12}>
                <Form.Item
                label="Número de factura:"
                name="invoiceNum"
                rules={[
                    {
                    required: true,
                    message: 'Por favor ingrese número de factura',
                    },
                ]}
                >
                    <Input placeholder="Ingrese información"  />
                </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={12}>
                <Form.Item
                label="Fecha de documento:"
                name="documentDate"
                rules={[
                    {
                    required: true,
                    message: 'Por favor ingrese fecha',
                    },
                ]}
                >
                <DatePicker
                  format={'DD/MM/YYYY'}
                  style={{ width: '100%' }}
                />                
                </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={12}>
                <Form.Item
                label="Total:"
                name="total"
                rules={[
                    {
                    required: true,
                    message: 'Por favor ingrese total',
                    },
                ]}
                >
                    <InputNumber
                  placeholder="Ingrese información"
                  style={{ width: '100%' }}
                  min={1}
                  formatter={(value) =>
                    `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                />
                </Form.Item>
            </Col>
            <Col xs={24}>
            <Form.Item label="Descripción:" name="description">
                  <TextArea
                    placeholder="Ingresa tu información"
                  />
                </Form.Item>
            </Col>
          </Row>    
      <Row justify="end" style={{ marginRight: 20, marginTop: 20 }}>
        <Col xs={5}>
          <Button
             htmlType="submit"
            className="btn-enviar"
            size="large"
            loading={uploading}
            style={{ width: '100%' }}
          >
            Subir Documentos
          </Button>
        </Col>
      </Row>
    </div>
    </Form>
    </Spin>
  );
}
