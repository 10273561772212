import React, { useState } from 'react';
import { Col, Layout, Row, Typography } from 'antd';
import GreetingsTop from '../../../components/Admin/GreetingsTop/GreetingsTop';
import moment from 'moment';
import OperationsTable from '../../../components/Logistic/Operations/OperationsTable';

export default function OperationPage() {
  const date = [moment().subtract(7, 'days'), moment()];
  const [dateRange, setDateRange] = useState({
    startDate: moment(date[0]).unix(),
    endDate: moment(date[1]).unix(),
  });
  function filterDate(date, dateString) {
    if (date) {
      let date2 = {
        startDate: moment(date[0]).unix(),
        endDate: moment(date[1]).unix(),
      };
      setDateRange(date2);
    }
  }
  return (
    <Layout>
      <GreetingsTop />

      <HeaderTop filterDate={filterDate} />

      <div className="billing__container">
        <OperationsTable dateRange={dateRange} />
      </div>
    </Layout>
  );
}

function HeaderTop({ filterDate }) {
  const { Title } = Typography;
  return (
    <div className="billing__container">
      <Row className="header-top">
        <Col xs={24} md={12} className="header-top__left">
          <Title level={3}>Operaciones</Title>
        </Col>
        {/* <Col
          xs={20}
          md={12}
          style={{
            display: 'grid',
            alignItems: 'center',
            justifyContent: 'end',
          }}
        >
          <Row xs={24} md={12} style={{ marginBottom: 10 }}>
            <p xs={24} style={{ marginRight: 20 }}>
              Fecha:
            </p>
            <DatePickerOptions xs={24} filterDate={filterDate} />
          </Row>
        </Col> */}
      </Row>
    </div>
  );
}
