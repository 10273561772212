import React, { useEffect, useState } from 'react';
import { Button, Col, Modal as ModalAntd, Row, Table } from 'antd';
import {
  ExclamationCircleOutlined,
  DeleteOutlined,
  ReloadOutlined,
  FileExcelFilled,
  FilePdfFilled,
} from '@ant-design/icons';
import Modal from '../../../General/Modal';
import { formatMoney } from '../../../../utils/general';
import {
  METHOD_DELETE,
  METHOD_GET,
  ROL_VENTAS,
} from '../../../../utils/constants';
import { getAccessTokenApi } from '../../../../api/auth';
import { fetchApi } from '../../../../helpers/fetch';
import { API_URL_QUOTE } from '../../../../helpers/urls';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import {
  SERVICES_CATALOG_ID,
  TYPE_OF_LOAD_CATALOG_ID,
  TYPE_OF_SERVICE_CATALOG_ID,
  UNIT_TYPE_CATALOG_ID,
} from '../../../../helpers/constants';
import { getCatalogValueHandle } from '../../../../helpers/handlerFunctionApi';
import LineForm from '../AddQuoteForm/LineForm/LineForm';
import useUser from '../../../../hooks/useUser';
import XLSX from 'xlsx';
import jsPDF from 'jspdf';
import moment from 'moment';

const QuoteLinesTable = () => {
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [titleModal, setTitleModal] = useState(false);
  const [contextModal, setContextModal] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const hasSelected = selectedRowKeys.length > 0;
  const { confirm } = ModalAntd;
  const [isLoadingTable, setIsLoadingTable] = useState(true);
  const token = getAccessTokenApi();
  const [reloadTable, setReloadTable] = useState(false);
  const [lines, setLines] = useState([]);
  const textModal = '¿Estás seguro que deseas borrar esta línea';
  let { quoteId } = useParams();
  const [filterServiceType, setFilterServiceType] = useState([]);
  const [serviceType, setServiceType] = useState([]);
  const [service, setService] = useState([]);
  const [typeOfLoad, setTypeOfLoad] = useState([]);
  const [filtertypeOfLoad, setFilterTypeOfLoad] = useState([]);
  const [typeOfEquipment, setTypeOfEquipment] = useState([]);
  const [filterTypeOfEquipment, setFilterTypeOfEquipment] = useState([]);
  const [filterService, setFilterService] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { person } = useUser();

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };
  useEffect(() => {
    getCatalogValueHandle(
      TYPE_OF_SERVICE_CATALOG_ID,
      setServiceType,
      setIsLoading,
      0
    );
    getCatalogValueHandle(SERVICES_CATALOG_ID, setService, setIsLoading, 0);
    getCatalogValueHandle(
      TYPE_OF_LOAD_CATALOG_ID,
      setTypeOfLoad,
      setIsLoading,
      0
    );
    getCatalogValueHandle(
      UNIT_TYPE_CATALOG_ID,
      setTypeOfEquipment,
      setIsLoading,
      0
    );
  }, []);
  useEffect(() => {
    let filters = [];
    typeOfLoad.forEach((element) => {
      const json = { text: element.label, value: element.label };
      filters.push(json);
    });
    setFilterTypeOfLoad(filters);
  }, [typeOfLoad]);
  useEffect(() => {
    let filters = [];
    typeOfEquipment.forEach((element) => {
      const json = { text: element.label, value: element.label };
      filters.push(json);
    });
    setFilterTypeOfEquipment(filters);
  }, [typeOfEquipment]);
  useEffect(() => {
    let filters = [];
    serviceType.forEach((element) => {
      const json = { text: element.label, value: element.label };
      filters.push(json);
    });
    setFilterServiceType(filters);
  }, [serviceType]);
  useEffect(() => {
    let filters = [];
    service.forEach((element) => {
      const json = { text: element.label, value: element.label };
      filters.push(json);
    });
    setFilterService(filters);
  }, [service]);
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  useEffect(() => {
    setIsLoadingTable(true);
    const config = {
      method: METHOD_GET,
      url: `${API_URL_QUOTE}/${quoteId}/quotelines`,
      data: null,
      token,
      showNotificationError: true,
    };
    fetchApi(config)
      .then((response) => {
        // console.log(response,"response lines");
        if (response.statusCode === 200) {
          const allLines = response.result;
          allLines.map((line) => (line['key'] = line.id));
          setLines(allLines);
        }
        setIsLoadingTable(false);
        setReloadTable(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoadingTable(false);
        setReloadTable(false);
      });
  }, [reloadTable]);
  const showModal = (type, line) => {
    //    if (type === 'Edit') {
    //         setModalIsVisible(true);
    //         setTitleModal(`Editar cotización #${quote}`);
    //         setContextModal(
    //             <AddQuoteForm
    //                 type={type}
    //                 setModalIsVisible={setModalIsVisible}
    //                 quoteId={quote}
    //                 // setReloadQuote={setReloadQuote}
    //             ></AddQuoteForm>
    //         );
    //     } else if (type === 'Watch') {
    // console.log(line);
    setModalIsVisible(true);
    setTitleModal(`Cotización #${quoteId} línea ${line.lineNumber}`);
    setContextModal(
      <LineForm
        type="watch"
        setModalIsVisible={setModalIsVisible}
        quoteId={quoteId}
        // setReloadQuote={setReloadQuote}
      ></LineForm>
    );
    // }
  };
  const columns = [
    {
      title: 'No.Línea',
      dataIndex: 'lineNumber',
      key: 'lineNumber',
      width: 80,
      align: 'center',
    },
    {
      title: 'Tarifa',
      dataIndex: 'rateId',
      key: 'rateId',
      width: 100,
      // fixed: 'left',
      align: 'center',
    },
    {
      title: 'Servicio',
      dataIndex: 'service',
      key: 'service',
      width: 100,
      //   fixed: 'left',
      filters: filterService,
      onFilter: (value, record) => record.service === value,
    },
    {
      title: 'Tipo de servicio',
      dataIndex: 'typeOfService',
      key: 'typeOfService',
      width: 100,
      // fixed: 'left',
      align: 'center',
      filters: filterServiceType,
      onFilter: (value, record) => record.typeOfService === value,
    },
    {
      title: 'Tipo de carga',
      dataIndex: 'typeOfLoad',
      key: 'typeOfLoad',
      width: 100,
      // fixed: 'left',
      align: 'center',
      filters: filtertypeOfLoad,
      onFilter: (value, record) => record.typeOfLoad === value,
    },
    {
      title: 'Tipo de unidad',
      dataIndex: 'unit',
      key: 'unit',
      width: 100,
      // fixed: 'left',
      align: 'center',
      filters: filterTypeOfEquipment,
    },
    {
      title: 'Origen',
      children: [
        {
          title: 'País',
          dataIndex: 'originCountry',
          key: 'originCountry',
          width: 100,
          align: 'center',
        },
        {
          title: 'Ciudad',
          dataIndex: 'originCity',
          key: 'originCity',
          width: 100,
          align: 'center',
        },
        {
          title: 'Puerto',
          dataIndex: 'originPort',
          key: 'originPort',
          width: 100,
          align: 'center',
        },
        {
          title: 'Aeropuerto',
          dataIndex: 'originAirport',
          key: 'originAirport',
          width: 100,
          align: 'center',
        },
        {
          title: 'CP',
          dataIndex: 'originZipCode',
          key: 'originZipCode',
          width: 100,
          align: 'center',
        },
        {
          title: 'Dirección',
          dataIndex: 'originAddress',
          key: 'originAddress',
          width: 100,
          align: 'center',
          render: (originAddress) =>
            originAddress !== null ? originAddress.toUpperCase() : '',
        },
      ],
    },

    {
      title: 'Destino',
      children: [
        {
          title: 'País',
          dataIndex: 'destinationCountry',
          key: 'destinationCountry',
          width: 100,
          align: 'center',
        },
        {
          title: 'Ciudad',
          dataIndex: 'destinationCity',
          key: 'destinationCity',
          width: 100,
          align: 'center',
        },
        {
          title: 'Puerto',
          dataIndex: 'destinationPort',
          key: 'destinationPort',
          width: 100,
          align: 'center',
        },

        {
          title: 'Aeropuerto',
          dataIndex: 'destinationAirport',
          key: 'destinationAirport',
          width: 100,
          align: 'center',
        },
        {
          title: 'CP',
          dataIndex: 'destinationZipCode',
          key: 'destinationZipCode',
          width: 100,
          align: 'center',
        },
        {
          title: 'Dirección',
          dataIndex: 'destinationAddress',
          key: 'destinationAddress',
          align: 'center',
          width: 100,
          render: (destinationAddress) =>
            destinationAddress !== null ? destinationAddress.toUpperCase() : '',
        },
      ],
    },
    {
      title: 'Venta',
      dataIndex: 'sale',
      key: 'sale',
      width: 100,
      align: 'center',
      render: (sale) => <span>${formatMoney(sale)}</span>,
    },
    // {
    //    title: 'Costo',
    //    dataIndex: 'cost',
    //    key: 'cost',
    //    width: 100,
    //    align: 'center',
    //    render: (cost) => <span>{formatMoney(cost)}</span>,
    // },
    // {
    //    title: 'Precio unitario',
    //    dataIndex: 'unitPrice',
    //    key: 'unitPrice',
    //    width: 100,
    //    // fixed: 'right',
    //    align: 'center',
    //    render: (unitPrice) => <span>{`$ ${formatMoney(unitPrice)}`}</span>,
    // },
  ];
  const deleteQuote = async (id) => {
    const config = {
      method: METHOD_DELETE,
      url: `${API_URL_QUOTE}/${id}`,
      data: null,
      token,
      showNotification: true,
    };
    const result = await fetchApi(config);

    if (result) {
      if (result.statusCode === 200) {
        setReloadTable(true);
        setSelectedRowKeys([]);
      }
    }
  };
  const showDeleteConfirm = (id, description = '') => {
    confirm({
      title: `${textModal}?`,
      icon: <ExclamationCircleOutlined />,
      content: 'No podrá deshacer esta acción después..',
      okText: 'Si',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteQuote(id);
      },
      onCancel() {},
    });
  };
  const exportPdf = (quotes, startDate, endDate) => {
    let mstrTitulo = '';
    let mstrPdf = '';
    mstrTitulo = `Líneas Cotización ${quoteId}`;
    mstrPdf = `Líneas-Cotización-${quoteId}-${moment().format('L')}.pdf`;

    let registros = [];
    quotes.forEach((element) => {
      registros.push([
        element.lineNumber,
        element.rateId,
        element.service,
        element.typeOfService,
        element.typeOfLoad,
        element.unit,
        element.service === 'TERRESTRE'
          ? element.originCity +
            ' - ' +
            element.originAddress.toUpperCase() +
            ' C.P: ' +
            element.originZipCode
          : element.service === 'AEREO'
          ? element.originCity + ' - ' + element.originAirport
          : element.originCity + ' - ' + element.originPort,
        element.service === 'TERRESTRE'
          ? element.destinationCity +
            ' - ' +
            element.destinationAddress.toUpperCase() +
            ' C.P: ' +
            element.destinationZipCode
          : element.service === 'AEREO'
          ? element.destinationCity + ' - ' + element.destinationAirport
          : element.destinationCity + ' - ' + element.destinationPort,
        `$${formatMoney(element.sale)}`,
      ]);
    });

    let fecha = moment().format('LLL');
    let doc = new jsPDF('landscape');
    doc.setFontSize(12);

    let xFecha = 220;
    let yFecha = 10;

    let totalPagesExp = '{total_pages_count_string}';

    let pageContent = function (data) {
      //header
      doc.text(15, 10, mstrTitulo);
      doc.text(-10, -10, mstrTitulo);
      doc.text(fecha, xFecha, yFecha);
      // FOOTER
      let str = 'Página ' + data.pageCount;
      //Total page number plugin only available in jspdf v1.0+
      if (typeof doc.putTotalPages === 'function') {
        str = str + ' de ' + totalPagesExp;
      }
      doc.setFontSize(10);
      doc.text(
        str,
        data.settings.margin.left,
        doc.internal.pageSize.height - 10
      );
    };
    doc.autoTable({
      A3: { v: 'No.Línea', t: 's' },
      B3: { v: 'Tarifa', t: 's' },
      C3: { v: 'Servicio', t: 's' },
      D3: { v: 'Tipo de servicio', t: 's' },
      E3: { v: 'Tipo de carga', t: 's' },
      F3: { v: 'Tipo de unidad', t: 's' },
      G3: { v: 'Origen', t: 's' },
      H3: { v: 'Destino', t: 's' },
      I3: { v: 'Venta', t: 's' },
      head: [
        [
          'No.Línea',
          'Tarifa',
          'Servicio',
          'Tipo de servicio',
          'Tipo de carga',
          'Tipo de unidad',
          'Origen',
          'Destino',
          'Venta',
        ],
      ],
      didDrawPage: pageContent,
      theme: 'grid',
      headStyles: {
        valign: 'middle',
        halign: 'center',
        fillColor: [230, 81, 0],
        textColor: [255, 255, 255],
        // cellWidth: { 3: 200 },
      },
      body: registros,
      columnStyles: {
        text: {
          cellWidth: 'wrap',
        },
      },
    });
    // call footer() after each doc.addPage()
    // and before doc.save() do not forget put
    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(totalPagesExp);
    }
    //spinner.style.display = 'none';
    doc.save(mstrPdf);
  };
  const generateExcel = (entries) => {
    // setExcelLoading(true);
    let filename = '';
    let mstrTitulo = '';
    mstrTitulo = `Líneas Cotización ${quoteId}`;
    filename = `Líneas-Cotización-${quoteId}-${moment().format('L')}.xlsx`;

    let fecha = moment().format('LLL');
    const ws_name = 'Hoja1';
    let wb = {
      SheetNames: [],
      Sheets: {},
    };
    let ws = {};
    let renInicial = 4;

    // var mstrTitulo = `Facturación y notas de crédito del ${dateRange.startDate} al: ${dateRange.endDate}`;

    ws = {
      A1: { v: mstrTitulo, t: 's' },
      I1: { v: fecha, t: 's' },

      A3: { v: 'No.Línea', t: 's' },
      B3: { v: 'Tarifa', t: 's' },
      C3: { v: 'Servicio', t: 's' },
      D3: { v: 'Tipo de servicio', t: 's' },
      E3: { v: 'Tipo de carga', t: 's' },
      F3: { v: 'Tipo de unidad', t: 's' },
      G3: { v: 'Origen', t: 's' },
      H3: { v: 'Destino', t: 's' },
      I3: { v: 'Venta', t: 's' },
    };
    for (let i in entries) {
      const {
        lineNumber,
        rateId,
        service,
        typeOfService,
        typeOfLoad,
        unit,
        originCity,
        originPort,
        originAirport,
        originZipCode,
        originAddress,
        destinationCity,
        destinationPort,
        destinationAirport,
        destinationZipCode,
        destinationAddress,
        sale,
      } = entries[i];
      ws['A' + renInicial] = { v: lineNumber, t: 's' };
      ws['B' + renInicial] = { v: rateId, t: 's' };
      ws['C' + renInicial] = { v: service, t: 's' };
      ws['D' + renInicial] = { v: typeOfService, t: 's' };
      ws['E' + renInicial] = { v: typeOfLoad, t: 's' };
      ws['F' + renInicial] = { v: unit, t: 's' };
      ws['G' + renInicial] = {
        v:
          service === 'TERRESTRE'
            ? originCity +
              ' - ' +
              originAddress.toUpperCase() +
              ' C.P: ' +
              originZipCode
            : service === 'AEREO'
            ? originCity + ' - ' + originAirport
            : originCity + ' - ' + originPort,
        t: 's',
      };
      ws['H' + renInicial] = {
        v:
          service === 'TERRESTRE'
            ? destinationCity +
              ' - ' +
              destinationAddress.toUpperCase() +
              ' C.P: ' +
              destinationZipCode
            : service === 'AEREO'
            ? destinationCity + ' - ' + destinationAirport
            : destinationCity + ' - ' + destinationPort,
        t: 's',
      };
      ws['I' + renInicial] = { v: `$${formatMoney(sale)}`, t: 's' };
      renInicial++;
    }
    // var wscols = [{ wch: 30 }, { wch: 40 }, { wch: 35 }, { wch: 35 }];
    // ws['!cols'] = wscols;
    ws['!ref'] = XLSX.utils.encode_range({
      s: { c: 0, r: 0 },
      e: { c: 15, r: renInicial },
    });
    wb.SheetNames.push(ws_name);
    wb.Sheets[ws_name] = ws;
    XLSX.writeFile(wb, filename, { cellStyles: true });
    // setExcelLoading(false);
  };
  return (
    <div>
      {[ROL_VENTAS].some((r) => person.roles.includes(r)) && (
        <Button
          disabled={!hasSelected}
          // disabled={selectedRowKeys.length !== 1}
          className="btn-delete"
          onClick={() => showDeleteConfirm(selectedRowKeys[0])}
          icon={<DeleteOutlined />}
        >
          Eliminar
        </Button>
      )}
      <Button
        className="btn-download"
        icon={<ReloadOutlined />}
        onClick={() => setReloadTable(true)}
      >
        Refrescar
      </Button>
      {selectedRowKeys.length > 0 && (
        <p>Seleccionados: {selectedRowKeys.length} elementos</p>
      )}
      {/* <Button className="btn-download" onClick={() => showModal('Edit')}>
                Clientes
            </Button> */}
      <Table
        // rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
        dataSource={lines}
        columns={columns}
        bordered
        size="middle"
        scroll={{ x: 1200 }}
        setSelectedRowKeys
        className="table-striped-rows"
        rowSelection={rowSelection}
        loading={isLoadingTable || isLoading}
      />
      <Row>
        <Col span={24} style={{ textAlign: 'right' }}>
          <Button
            icon={<FilePdfFilled style={{ fontSize: 18 }} />}
            className="btn-download"
            onClick={() => exportPdf(lines)}
            disabled={lines.length === 0}
            style={{ width: 150 }}
          >
            Descargar Pdf
          </Button>
          <Button
            icon={<FileExcelFilled style={{ fontSize: 18 }} />}
            className="btn-download"
            onClick={() => generateExcel(lines)}
            disabled={lines.length === 0}
            style={{ width: 160 }}
          >
            Descargar Excel
          </Button>
        </Col>
      </Row>
      <Modal
        title={titleModal}
        visible={modalIsVisible}
        setIsVisible={setModalIsVisible}
        width={1000}
      >
        {contextModal}
      </Modal>
    </div>
  );
};

export default QuoteLinesTable;
