import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Spin,
  Form,
  Input,
  Button,
  notification,
  Modal as ModalAntd,
  Tabs,
  Tooltip,
  Checkbox,
  Select,
  Alert,
  InputNumber,
} from 'antd';
import { getAccessTokenApi } from '../../../../../api/auth';
import { getClientApi, editClientApi } from '../../../../../api/clients';
import { logout } from '../../../../../api/auth';
import { getCompanyIdApi } from '../../../../../api/company';
import {
  ExclamationCircleOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { getUsersApi } from '../../../../../api/users';
import { getCatalogValueHandle } from '../../../../../helpers/handlerFunctionApi';
import {
  CITY_CATALOG_ID,
  COUNTRY_CATALOG_ID,
  STATE_CATALOG_ID,
  TIME_OF_MOVEMENTS_CATALOG_ID,
} from '../../../../../helpers/constants';
import { METHOD_GET } from '../../../../../utils/constants';
import { API_URL_CATALOG_VALUES } from '../../../../../helpers/urls';
import { fetchApi } from '../../../../../helpers/fetch';
import Location from '../AddClientForm/Location/Location';
import Catalog from '../AddClientForm/Catalog/Catalog';
import { getSuppliersByRoutes } from '../../../../../api/supplier';

const EditClientForm = ({
  setModalIsVisible,
  setReloadClients,
  idClient,
  type,
}) => {
  const [loadingForm, setLoadingForm] = useState(true);
  const [form] = Form.useForm();
  const [editClientForm, setEditClientForm] = useState([]);
  const companyId = getCompanyIdApi();
  const token = getAccessTokenApi();
  const { confirm } = ModalAntd;
  const { TabPane } = Tabs;
  const [financiales, setFinanciales] = useState([]);
  const [operadores, setOperadores] = useState([]);
  const [vendedores, setVendedores] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [customerRoutes, setCustomerRoutes] = useState([]);
  const [countrySelected, setCountrySelected] = useState(0);
  const [countries, setCountries] = useState([]);
  const disableAllInputs = type === 'watch' ? true : false;
  const [textType, setTextType] = useState('');
  const [textDescription, setTextDescription] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [hasRoutes, setHasRoutes] = useState(false);
  const { Option } = Select;

  const [timeOfMovements, setTimeOfMovements] = useState([]);
  const [loadingCatalog, setLoadingCatalog] = useState();

  useEffect(() => {
    getCatalogValueHandle(
      TIME_OF_MOVEMENTS_CATALOG_ID,
      setTimeOfMovements,
      setLoadingCatalog
    );
  }, []);

  useEffect(() => {
    if (loadingForm) {
      getUsersApi(token)
        .then((response) => {
          if (response) {
            let financials = [];
            let operaciones = [];
            let ventas = [];
            response.result.forEach((element, index) => {
              if (element.roles.includes('Financial')) {
                financials.push(element);
              }
              if (element.roles.includes('Operaciones')) {
                operaciones.push(element);
              }
              if (element.roles.includes('Ventas')) {
                ventas.push(element);
              }
            });

            let financ = financials.map((f) => ({
              value: f.id,
              label: f.userName,
            }));
            financ.sort((a, b) => a.label.localeCompare(b.label));
            setFinanciales(financ);
            let opp = operaciones.map((f) => ({
              value: f.id,
              label: f.userName,
            }));
            opp.sort((a, b) => a.label.localeCompare(b.label));
            setOperadores(opp);
            let sales = ventas.map((f) => ({
              value: f.id,
              label: f.userName,
            }));
            sales.sort((a, b) => a.label.localeCompare(b.label));
            setVendedores(sales);
          }
          setLoadingForm(false);
        })
        .catch((err) => {});
    }
  }, [token, loadingForm]);

  useEffect(() => {
    const config = {
      method: METHOD_GET,
      url: `Route/GetByCustomer/${idClient}`,
      data: null,
      token,
    };
    fetchApi(config)
      .then((response) => {
        if (response) {
          let clientRoutes = response.result;
          let routesCustomer = [];
          if (clientRoutes) {
            clientRoutes.map((e, index) => {
              routesCustomer.push(e.routeId);
            });
          }
          setCustomerRoutes(routesCustomer);
          getSuppliersByRoutes(token, { routeIds: routesCustomer }).then(
            (response) => {
              if (response) {
                let suppliersArray = response.result;
                let uniqueChars = [];
                suppliersArray.forEach((c) => {
                  if (
                    !uniqueChars.map((c) => c.supplierId).includes(c.supplierId)
                  ) {
                    uniqueChars.push(c);
                  }
                });
                let supp = uniqueChars.map((e) => ({
                  value: e.supplierId,
                  label: e.name,
                }));

                setSuppliers(supp);
              }
            }
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [idClient, token]);

  useEffect(() => {
    setLoadingForm(true);
    const config = {
      method: METHOD_GET,
      url: `${API_URL_CATALOG_VALUES}/GetAll/${COUNTRY_CATALOG_ID}`,
      data: null,
      token,
      // showNotification: true
    };
    fetchApi(config)
      .then((response) => {
        if (response) {
          let calogsValues = response.result;

          if (calogsValues) {
            calogsValues.map((cat, index) => {
              cat['key'] = index;
              cat['label'] = cat.description;
              cat['value'] = cat.id;
            });
          }
          setCountries(calogsValues);
        }
        setLoadingForm(false);
      })
      .catch((err) => {
        console.log(err);
      });
    getCatalogValueHandle(
      STATE_CATALOG_ID,
      setStates,
      setLoadingForm,
      form.getFieldValue('country')
    );
    getCatalogValueHandle(
      CITY_CATALOG_ID,
      setCities,
      setLoadingForm,
      form.getFieldValue('state')
    );
    //   getStateCatalogHandle();
  }, [token, editClientForm]);

  useEffect(() => {
    getClientApi(token, encodeURIComponent(idClient)).then((response) => {
      setEditClientForm([
        {
          name: ['active'],
          value: response.result.active,
        },
        {
          name: ['name'],
          value: response.result.name,
        },
        {
          name: ['clientNum'],
          value: response.result.clientNum,
        },
        {
          name: ['id'],
          value: response.result.id,
        },
        {
          name: ['address'],
          value: response.result.address,
        },
        {
          name: ['city'],
          value: response.result.city !== 0 ? response.result.city : null,
        },
        {
          name: ['state'],
          value: response.result.state !== 0 ? response.result.state : null,
        },
        {
          name: ['zipCode'],
          value: response.result.zipCode,
        },
        {
          name: ['country'],
          value: response.result.country !== 0 ? response.result.country : null,
        },
        {
          name: ['emailAddress'],
          value:
            response.result.emailAddress !== ''
              ? response.result.emailAddress
              : null,
        },
        {
          name: ['contactName'],
          value: response.result.contactName,
        },
        {
          name: ['fiscalID'],
          value: response.result.fiscalID,
        },
        {
          name: ['phoneNumber'],
          value: response.result.phoneNumber,
        },
        {
          name: ['financialIds'],
          value: response.result.financialIds,
        },
        {
          name: ['supplierIds'],
          value: response.result.supplierIds,
        },
        {
          name: ['operacionesId'],
          value: response.result.operacionesId,
        },
        {
          name: ['salesPersonIds'],
          value: response.result.salesPersonIds,
        },
        {
          name: ['prospect'],
          value: response.result.prospect,
        },
        {
          name: ['references'],
          value: response.result.references,
        },
        {
          name: ['expectedMovements'],
          value: response.result.expectedMovements,
        },
        {
          name: ['movementTimeId'],
          value: response.result.movementTimeId,
        },
        {
          name: ['paymentTerms'],
          value:
            response.result.paymentTerms !== 0
              ? response.result.paymentTerms
              : null,
        },
      ]);
      setHasRoutes(response.result.hasRoutes);
    });
    // return () => {
    //     cleanup
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idClient]);

  const handleEditClient = (values) => {
    confirm({
      title: 'Editar cliente',
      icon: <ExclamationCircleOutlined />,
      content: '¿Estás seguro que deseas guardar datos modificados?',
      okText: 'Si',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        editClientFunction(values);
      },
      onCancel() {},
    });
  };

  const onChangeCountryOrigin = React.useCallback(
    async (value) => {
      if (value !== countrySelected) {
        form.setFieldsValue({
          state: null,
          city: null,
          address: null,
          zipCode: null,
        });
      }
      setCountrySelected(value);
      getCatalogValueHandle(STATE_CATALOG_ID, setStates, setLoadingForm, value);
      getCatalogValueHandle(CITY_CATALOG_ID, setCities, setLoadingForm, value);
    },
    [form, countrySelected]
  );

  const onChangeState = React.useCallback(
    async (value) => {
      getCatalogValueHandle(CITY_CATALOG_ID, setCities, setLoadingForm, value);
      form.setFieldsValue({
        city: null,
        address: null,
        zipCode: null,
      });
    },
    [form]
  );

  const editClientFunction = async (values) => {
    setLoadingForm(true);
    let data = values;
    data['ParentCustomerId'] = '';
    data['level'] = 0;
    data['company'] = companyId;
    let response = null;
    try {
      response = await editClientApi(token, data, idClient);
    } catch (error) {
    } finally {
      setLoadingForm(false);
    }
    // console.log(response, 'response edit');
    if (response === undefined) {
      notification['error']({
        message: 'Ocurrió un error, Inténtelo mas tarde',
      });
      return false;
    }
    if (response.statusCode === 400) {
      notification['error']({
        message: response.description,
      });
      return false;
    }
    if (response.statusCode === 401) {
      notification['error']({
        message: 'Usuario no autorizado.',
      });
      setTimeout(() => {
        logout();
        window.location.reload();
      }, 1500);
      return false;
    }
    if (response.statusCode === 200) {
      notification['success']({
        message: 'Cliente editado con éxito',
      });
      setModalIsVisible(false);
    } else {
      let messageError = 'Ocurrió un error, Inténtelo otra vez';
      if (response.statusCode === 409) {
        messageError = response.description;
      }
      if (response.statusCode === 400) {
        messageError = response.description;
      }
      notification['error']({
        message: messageError,
      });
    }
    setReloadClients(true);

    setLoadingForm(false);
  };
  const locationForm = React.useMemo(
    () => (
      <Location
        type={'edit'}
        onChangeCountryOrigin={onChangeCountryOrigin}
        countries={countries}
        onChangeState={onChangeState}
        states={states}
        cities={cities}
        disableAllInputs={disableAllInputs}
      />
    ),
    [
      cities,
      countries,
      states,
      onChangeState,
      disableAllInputs,
      onChangeCountryOrigin,
    ]
  );
  const errorFields = ({ values, errorFields, outOfDate }) => {
    if (
      !values.name ||
      !values.id ||
      !values.fiscalID ||
      !values.phoneNumber ||
      !values.financialId
    ) {
      setTextType('Datos incompletos');
      setTextDescription(
        'Complete los campos obligatorios de detalle de cliente'
      );
      setShowAlert(true);

      setTimeout(() => {
        setShowAlert(false);
      }, 10000);
      return;
    }
    if (!values.country || !values.city || !values.state || !values.address) {
      setTextType('Datos incompletos');
      setTextDescription('Complete todos los datos de la sección "Dirección"');
      setShowAlert(true);

      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }
  };
  return (
    <Spin spinning={loadingForm} tip="Cargando...">
      <Form
        name="add-promo"
        layout="vertical"
        form={form}
        onFinish={handleEditClient}
        fields={editClientForm}
        onFinishFailed={errorFields}
        // onFieldsChange={(changedFields, allFields) => {
        //   setEditClientForm(allFields);
        // }}
      >
        <div>
          <Tabs defaultActiveKey="1" type="card">
            <TabPane tab="Detalle" key="infoBasic" forceRender>
              <BasicInfo
                financiales={financiales}
                operadores={operadores}
                disableAllInputs={disableAllInputs}
                vendedores={vendedores}
                suppliers={suppliers}
                hasRoutes={hasRoutes}
                timeOfMovements={timeOfMovements}
              />
            </TabPane>

            <TabPane tab="Dirección" key="location" forceRender>
              {locationForm}
            </TabPane>
            <TabPane tab="Catálogos" key="catalog" forceRender>
              <Catalog disableAllInputs={disableAllInputs} />
            </TabPane>
          </Tabs>
          {showAlert && (
            <Alert
              message={textType}
              description={textDescription}
              type="warning"
              showIcon
              style={{ marginBottom: 20 }}
            />
          )}
          {type !== 'watch' && (
            <Row justify="end">
              <Form.Item>
                <Button htmlType="submit" className="btn-enviar" block>
                  Guardar
                </Button>
              </Form.Item>
            </Row>
          )}
        </div>
      </Form>
    </Spin>
  );
};

export default EditClientForm;

function BasicInfo({
  financiales,
  operadores,
  disableAllInputs,
  vendedores,
  suppliers,
  hasRoutes,
  timeOfMovements,
}) {
  return (
    <Row gutter={16}>
      <Col xs={24} md={12} lg={8}>
        <Form.Item
          label="Nombre de cliente:"
          name="name"
          rules={[
            {
              required: true,
              message: 'Por favor ingrese un nombre',
            },
          ]}
        >
          <Input
            placeholder="Ingresa tu información"
            disabled={disableAllInputs}
          />
        </Form.Item>
      </Col>
      <Col xs={24} md={12} lg={8}>
        <Form.Item
          label="Id. de cliente:"
          name="id"
          rules={[
            {
              required: true,
              message: 'Por favor ingrese un Id',
            },
          ]}
        >
          <Input disabled placeholder="Ingresa tu información" />
        </Form.Item>
      </Col>
      <Col xs={24} md={12} lg={8}>
        <Form.Item
          // label="Usuario estatus:"
          label={
            <span>
              Cliente estatus:&nbsp;
              <Tooltip title="Es necesario tener rutas para activar">
                <QuestionCircleOutlined />
              </Tooltip>
            </span>
          }
          name="active"
          valuePropName="checked"
        >
          <Checkbox disabled={disableAllInputs || !hasRoutes}>Activo</Checkbox>
        </Form.Item>
      </Col>
          <Col xs={24} md={12} lg={8}>
          <Form.Item
          label="Tipo de cliente:"
          name="prospect"
          >
          <Select
                  allowClear
                  style={{ width: '100%', marginBottom: 20 }}
                  placeholder="Seleccione una opción"
                >
                  <Option value={true}>
                    Prospecto
                  </Option>
                  <Option value={false}>
                    Cliente
                  </Option>
                </Select>
</Form.Item>
</Col>
      <Col xs={24} md={12} lg={8}>
        <Form.Item
          label="RFC:"
          name="fiscalID"
          rules={[
            {
              required: true,
              message: 'Por favor ingrese RFC',
            },
          ]}
        >
          <Input
            placeholder="Ingresa tu información"
            disabled={disableAllInputs}
          />
        </Form.Item>
      </Col>

      <Col xs={24} md={12} lg={8}>
        <Form.Item
          label={
            <span>
              Teléfono:&nbsp;
              <Tooltip title="El número telefónico debe contener 10 dígitos">
                <QuestionCircleOutlined />
              </Tooltip>
            </span>
          }
          name="phoneNumber"
          rules={[
            {
              required: true,
              message: 'Por favor ingresa número telefónico',
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || value.toString().length === 10) {
                  return Promise.resolve();
                }

                return Promise.reject(
                  'Por favor ingresa número telefónico a 10 dígitos'
                );
              },
            }),
          ]}
        >
          <Input
            placeholder="Ingresa tu información"
            disabled={disableAllInputs}
          />
        </Form.Item>
      </Col>
      <Col xs={24} md={12} lg={8}>
        <Form.Item
          label="Correo electrónico:"
          name="emailAddress"
          rules={[
            {
              required: false,
              message: 'Por favor ingrese un email',
            },
          ]}
        >
          <Input
            type={'email'}
            placeholder="Ingresa tu información"
            disabled={disableAllInputs}
          />
        </Form.Item>
      </Col>
      <Col xs={24} md={12} lg={8}>
        <Form.Item
          label="Referencia:"
          name="reference"
        >
          <Input placeholder="Ingresa tu información" />

        </Form.Item>
      </Col>
      {financiales && (
        <Col xs={24} md={12} lg={8}>
          <Form.Item label="Financiero:" name="financialIds">
            <Select
              placeholder="Selecciona una opción"
              mode="multiple"
              allowClear
              showArrow
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              disabled={disableAllInputs}
            >
              {financiales.map((f) => (
                <Select.Option key={f.value} value={f.value}>
                  {f.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      )}
      {operadores && (
        <Col xs={24} md={12} lg={8}>
          <Form.Item
            label="Tráfico:"
            name="operacionesId"
            rules={[
              {
                required: true,
                message: 'Por favor seleccione un operador',
              },
            ]}
          >
            <Select
              placeholder="Selecciona una opción "
              allowClear={true}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              disabled={disableAllInputs}
            >
              {operadores.map((o) => (
                <Select.Option key={o.value} value={o.value}>
                  {o.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      )}
      {vendedores && (
        <Col xs={24} md={12} lg={8}>
          <Form.Item
            label="Ventas:"
            name="salesPersonIds"
            rules={[
              {
                required: true,
                message: 'Por favor seleccione un usuario',
              },
            ]}
          >
            <Select
              placeholder="Selecciona una opción"
              allowClear={true}
              disabled={disableAllInputs}
              showSearch
              mode="multiple"
              showArrow
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {vendedores.map((o) => (
                <Select.Option key={o.value} value={o.value}>
                  {o.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      )}
      {suppliers && (
        <Col xs={24} md={12} lg={8}>
          <Form.Item label="Proveedor:" name="supplierIds">
            <Select
              placeholder="Selecciona una opción"
              mode="multiple"
              allowClear
              showArrow
              disabled={disableAllInputs}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {suppliers.map((e) => (
                <Select.Option key={e.value} value={e.value}>
                  {e.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      )}
      <Col xs={24} md={12} lg={8}>
        <Form.Item
          label="Movimientos esperados:"
          name="expectedMovements"
        >
        <InputNumber placeholder="Número de movimientos" style={{width: "100%"}} />

        </Form.Item>
      </Col>
      <Col xs={24} md={12} lg={8}>
        <Form.Item
          label="Tiempo de movimientos:"
          name="movementTimeId"
        >
           <Select
              placeholder="Selecciona una opción"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {timeOfMovements.map((s) => (
                <Select.Option key={s.value} value={s.value}>
                  {s.label}
                </Select.Option>
              ))}
            </Select>
        </Form.Item>
      </Col>
    </Row>
  );
}
