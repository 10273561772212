import React, { useEffect, useState } from 'react';
import {
  Table,
  Row,
  Col,
  Button,
  notification,
  Spin,
  Modal as ModalAntd,
  Space,
  Tag,
  Input,
} from 'antd';
import Modal from '../../../../General/Modal';
import AddUserForm from '../AddUserForm';
import EditUserForm from '../EditUserForm';
import { getUsersApi, deleteUserApi } from '../../../../../api/users';
import { getAccessTokenApi, logout } from '../../../../../api/auth';
import {
  ExclamationCircleOutlined,
  FileExcelFilled,
  FilePdfFilled,
} from '@ant-design/icons';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import './UsersTable.scss';
import { SearchOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';
import Highlighter from 'react-highlight-words';
import { formatPhoneNumber } from '../../../../../utils/general';
import moment from 'moment';
import XLSX from 'xlsx';
import jsPDF from 'jspdf';
import { getFilteredDataFromLocalStorage, saveFilteredDataToLocalStorage } from '../../../../../helpers/saveFilterData';

export default function UsersTable() {
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [reloadUsers, setReloadUsers] = useState(true);
  const [titleModal, setTitleModal] = useState(false);
  const [contextModal, setContextModal] = useState(null);
  const [userSelected, setUserSelected] = useState(undefined);
  const [usersTable, setUsersTable] = useState([]);
  const [loadingForm, setLoadingForm] = useState(false);
  //   const [pagination, setPagination] = useState({
  //     current: 1,
  //     pageSize: 10,
  //     total: 1,
  //   });
  const { confirm } = ModalAntd;
  let history = useHistory();
  const [filteredInfo, setFilteredInfo] = useState({});
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  useEffect(() => {
    if (reloadUsers) {
      const token = getAccessTokenApi();
      // const filters = `?pageNumber=${pagination.current}&pageSize=${pagination.pageSize}`
      // const filters = `?portalType=${1}`;
      getUsersApi(token).then((response) => {
        if (response) {
          if (response.statusCode === 401) {
            notification['error']({
              message: 'Usuario no autorizado',
            });

            setTimeout(() => {
              logout();
              window.location.reload();
            }, 1500);
            return;
          }
          if (response.statusCode === 200) {
            response.result.forEach((element, index) => {
              element['key'] = index;
            });
            setUsersTable(response.result);
          }
          setReloadUsers(false);
        }
      });
    }
  }, [reloadUsers]);
  useEffect(() => {
    const datosObtenidos = getFilteredDataFromLocalStorage('users');
    if (datosObtenidos !== null) {
    setFilteredInfo(datosObtenidos.filters);
    }
  },[])
  const checkUser = (id) => {
    if (userSelected === id) {
      setUserSelected(undefined);
    } else {
      setUserSelected(id);
    }
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            // this.searchInput = node;
          }}
          placeholder={`Buscar`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => {
              handleReset(clearFilters);
              handleSearch(selectedKeys, confirm, dataIndex);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Limpiar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    filteredValue: filteredInfo[dataIndex] || null,
    onFilterDropdownVisibleChange: (visible) => {},
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  const columns = [
    {
      title: 'Selecciona',
      width: 100,
      dataIndex: 'id',
      key: 'concurrencyStamp',
      render: (id) => (
        <Checkbox
          // type="primary"
          onChange={() => checkUser(id)}
          checked={userSelected === id}
          className="button-detail"
        ></Checkbox>
      ),
    },
    {
      title: 'Id. usuario',
      width: 100,
      dataIndex: 'shortId',
      key: 'shortId',
      ...getColumnSearchProps('shortId'),
    },
    {
      title: 'Usuario',
      dataIndex: 'userName',
      key: 'userName',
      width: 150,
      render: (userName, element) => (
        <>
          <p> {userName}</p>

          {element.roles.includes('Financial') && element.portalType === 0 && (
            <Col>
              <p
                onClick={() =>
                  history.push(`/administracion/proveedores/${element.id}`)
                }
                style={{
                  color: 'blue',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
              >
                {' '}
                Ver proveedores
              </p>
            </Col>
          )}

          {element.roles.includes('Financial') && element.portalType === 1 && (
            <Col>
              <p
                onClick={() =>
                  history.push(`/catalogo/clientes/${element.id}/financial`)
                }
                style={{
                  color: 'blue',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
              >
                {' '}
                Ver clientes financieros
              </p>
            </Col>
          )}
          {element.roles.includes('Operaciones') && (
            <Col>
              <p
                onClick={() =>
                  history.push(`/catalogo/clientes/${element.id}/operador`)
                }
                style={{
                  color: 'blue',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
              >
                {' '}
                Ver clientes operaciones
              </p>
            </Col>
          )}
        </>
      ),
    },
    {
      title: 'Nombre(s)',
      dataIndex: 'name',
      key: 'name',
      ...getColumnSearchProps('name'),
      width: 150,
    },
    {
      title: 'Apellido paterno',
      dataIndex: 'lastName',
      key: 'lastName',
      ...getColumnSearchProps('lastName'),
      width: 150,
    },
    {
      title: 'Apellido materno',
      dataIndex: 'mothersLastName',
      key: 'mothersLastName',
      ...getColumnSearchProps('mothersLastName'),
      width: 150,
    },
    {
      title: 'Rol',
      dataIndex: 'roles',
      key: 'roles',
      width: 190,
      filters: [
        { text: 'Cliente', value: 'Customer' },
        { text: 'Proveedor', value: 'Supplier' },
        { text: 'Admin', value: 'Admin' },
        { text: 'Operaciones', value: 'Operaciones' },
        { text: 'Gerente', value: 'Manager' },
        { text: 'Layout', value: 'Layout' },
        { text: 'Financiero', value: 'Financial' },
        { text: 'Ventas', value: 'Ventas' },
        { text: 'Pricing', value: 'Pricing' },
      ],
      filteredValue: filteredInfo.roles || null,
      onFilter: (value, record) =>
        record.roles.find((item) => item.includes(value)),
      render: (roles) => (
        <Row gutter={16} style={{ width: 190 }}>
          {roles.map((rol) => {
            let color = '';
            switch (rol) {
              case 'Customer':
                color = 'blue';
                break;
              case 'Supplier':
                color = 'purple';
                break;
              case 'Admin':
                color = 'red';
                break;
              case 'Operaciones':
                color = 'green';
                break;
              case 'Manager':
                color = 'orange';
                break;
              case 'Layout':
                color = 'gold';
                break;
              case 'Financial':
                color = 'yellow';
                break;
              case 'Ventas':
                color = 'green';
                break;
              case 'Pricing':
                color = 'red';
                break;

              default:
                break;
            }
            let name = rol;

            switch (rol) {
              case 'Customer':
                name = 'Cliente';
                break;
              case 'Supplier':
                name = 'Proveedor';
                break;
              case 'Financial':
                name = 'Financiero';
                break;
              case 'Manager':
                name = 'Gerente';
                break;
              default:
                break;
            }

            return (
              // <Col xs={24} md={12} lg={8}>
              <Tag color={color} key={rol} style={{ marginBottom: 10 }}>
                {name}
              </Tag>
              // </Col>
            );
          })}
        </Row>
      ),
    },
    {
      title: 'Portal',
      dataIndex: 'portalType',
      key: 'portalType',
      width: 150,
      filters: [
        { text: 'Cliente', value: 0 },
        { text: 'Proveedor', value: 1 },
        { text: 'Cliente y Proveedor', value: 2 },
      ],
      filteredValue: filteredInfo.portalType || null,
      onFilter: (value, record) => record.portalType === value,
      render: (portalType) => {
        let color = '';
        switch (portalType) {
          case 0:
            color = 'blue';
            break;
          case 1:
            color = 'red';
            break;

          case 2:
            color = 'purple';
            break;

          default:
            break;
        }
        let name = '';

        switch (portalType) {
          case 0:
            name = 'Cliente';
            break;
          case 1:
            name = 'Proveedor';
            break;
          case 2:
            name = 'Cliente - Proveedor';
            break;
          default:
            break;
        }

        return <Tag color={color}>{name}</Tag>;
      },
    },
    {
      title: 'Teléfono',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      ...getColumnSearchProps('phoneNumber'),
      width: 150,
      render: (phoneNumber) => <p>{formatPhoneNumber(phoneNumber)}</p>,
    },
    {
      title: 'Correo electrónico',
      dataIndex: 'email',
      key: 'email',
      ...getColumnSearchProps('email'),
      width: 150,
    },
  ];
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const handleChange = (pagination, filters, sorter, extra) => {
    saveFilteredDataToLocalStorage('users', pagination, sorter, filters);
    setFilteredInfo(filters);
  };
  const showModal = (type) => {
    if (type === 'Add') {
      setModalIsVisible(true);
      setTitleModal('Agregar usuario');
      setContextModal(
        <AddUserForm
          setModalIsVisible={setModalIsVisible}
          setReloadUsers={setReloadUsers}
        ></AddUserForm>
      );
    } else if (type === 'Edit') {
      if (userSelected !== undefined) {
        setModalIsVisible(true);

        setTitleModal('Editar usuario');
        setContextModal(
          <EditUserForm
            setModalIsVisible={setModalIsVisible}
            setReloadUsers={setReloadUsers}
            idUser={userSelected}
          ></EditUserForm>
        );
      } else if (userSelected === undefined) {
        notification['error']({
          message: 'Debes seleccionar un usuario',
        });
      }
    }
  };
  const handleDeleteUser = (id) => {
    if (userSelected !== undefined) {
      confirm({
        title: 'Eliminar usuario',
        icon: <ExclamationCircleOutlined />,
        content:
          '¿Estás seguro que deseas borrar el usuario seleccionado?, no podrá recuperarla después',
        okText: 'Si',
        okType: 'danger',
        cancelText: 'No',
        onOk() {
          deleteUser(id);
        },
        onCancel() {},
      });
    } else {
      notification['error']({
        message: 'Debes seleccionar un usuario a eliminar',
      });
    }
  };
  const deleteUser = async (id) => {
    const accessToken = getAccessTokenApi();
    const result = await deleteUserApi(accessToken, id);
    if (result?.statusCode === 409) {
      notification['error']({
        message: result.description,
      });
    }
    if (result?.statusCode === 405) {
      notification['error']({
        message: result.description,
      });
    }
    if (result?.statusCode === 404) {
      notification['error']({
        message: result.description,
      });
    }
    if (result?.statusCode === 200) {
      notification['success']({
        message: 'Usuario eliminado exitosamente.',
      });
      setUserSelected(undefined);
    }
    setReloadUsers(true);
  };
  // const onChangePagination = (page, pageSize) => {
  //     setPagination({ ...pagination, current: page, pageSize });
  //     setReloadUsers(true);
  // };
  const exportPdf = (clients, startDate, endDate) => {
    let mstrTitulo = '';
    let mstrPdf = '';
    mstrTitulo = `Usuarios`;
    mstrPdf = `Usuarios-${moment().format('L')}.pdf`;

    let registros = [];
    clients.forEach((element) => {
      registros.push([
        element.id.split('-')[0],
        element.userName,
        element.name,
        element.lastName,
        element.mothersLastName,
        element.roles,
        element.portalType === 0
          ? 'Cliente'
          : element.portalType === 1
          ? 'Proveedor'
          : 'Cliente - Proveedor',
        element.phoneNumber,
        element.email,
      ]);
    });

    let fecha = moment().format('LLL');
    let doc = new jsPDF('landscape');
    doc.setFontSize(12);

    let xFecha = 220;
    let yFecha = 10;

    let totalPagesExp = '{total_pages_count_string}';

    let pageContent = function (data) {
      //header
      doc.text(15, 10, mstrTitulo);
      doc.text(-10, -10, mstrTitulo);
      doc.text(fecha, xFecha, yFecha);
      // FOOTER
      let str = 'Página ' + data.pageCount;
      //Total page number plugin only available in jspdf v1.0+
      if (typeof doc.putTotalPages === 'function') {
        str = str + ' de ' + totalPagesExp;
      }
      doc.setFontSize(10);
      doc.text(
        str,
        data.settings.margin.left,
        doc.internal.pageSize.height - 10
      );
    };
    doc.autoTable({
      A3: { v: 'Id. usuario', t: 's' },
      B3: { v: 'Usuario', t: 's' },
      C3: { v: 'Nombre(s)', t: 's' },
      D3: { v: 'Apellido paterno', t: 's' },
      E3: { v: 'Apellido materno', t: 's' },
      F3: { v: 'Rol', t: 's' },
      G3: { v: 'Portal', t: 's' },
      H3: { v: 'Teléfono', t: 's' },
      I3: { v: 'Correo electrónico', t: 's' },
      head: [
        [
          'Id. usuario',
          'Usuario',
          'Nombre(s)',
          'Apellido paterno',
          'Apellido materno',
          'Rol',
          'Portal',
          'Teléfono',
          'Correo electrónico',
        ],
      ],
      didDrawPage: pageContent,
      theme: 'grid',
      headStyles: {
        valign: 'middle',
        halign: 'center',
        fillColor: [230, 81, 0],
        textColor: [255, 255, 255],
        // cellWidth: { 3: 200 },
      },
      body: registros,
      columnStyles: {
        text: {
          cellWidth: 'wrap',
        },
      },
    });
    // call footer() after each doc.addPage()
    // and before doc.save() do not forget put
    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(totalPagesExp);
    }
    //spinner.style.display = 'none';
    doc.save(mstrPdf);
  };
  const generateExcel = (entries) => {
    // setExcelLoading(true);
    let filename = '';
    let mstrTitulo = '';
    filename = `Usuarios-${moment().format('L')}.xlsx`;
    mstrTitulo = `Usuarios`;

    let fecha = moment().format('LLL');
    const ws_name = 'Hoja1';
    let wb = {
      SheetNames: [],
      Sheets: {},
    };
    let ws = {};
    let renInicial = 4;

    // var mstrTitulo = `Facturación y notas de crédito del ${dateRange.startDate} al: ${dateRange.endDate}`;

    ws = {
      A1: { v: mstrTitulo, t: 's' },
      I1: { v: fecha, t: 's' },

      A3: { v: 'Id. usuario', t: 's' },
      B3: { v: 'Usuario', t: 's' },
      C3: { v: 'Nombre(s)', t: 's' },
      D3: { v: 'Apellido paterno', t: 's' },
      E3: { v: 'Apellido materno', t: 's' },
      F3: { v: 'Rol', t: 's' },
      G3: { v: 'Portal', t: 's' },
      H3: { v: 'Teléfono', t: 's' },
      I3: { v: 'Correo electrónico', t: 's' },
    };

    for (let i in entries) {
      const {
        id,
        userName,
        name,
        lastName,
        mothersLastName,
        roles,
        portalType,
        phoneNumber,
        email,
      } = entries[i];
      ws['A' + renInicial] = { v: id.split('-')[0], t: 's' };
      ws['B' + renInicial] = { v: userName, t: 's' };
      ws['C' + renInicial] = { v: name, t: 's' };
      ws['D' + renInicial] = { v: lastName, t: 's' };
      ws['E' + renInicial] = { v: mothersLastName, t: 's' };
      ws['F' + renInicial] = { v: roles, t: 's' };
      ws['G' + renInicial] = {
        v:
          portalType === 0
            ? 'Cliente'
            : portalType === 1
            ? 'Proveedor'
            : 'Cliente - Proveedor',
        t: 's',
      };
      ws['H' + renInicial] = { v: phoneNumber, t: 's' };
      ws['I' + renInicial] = { v: email, t: 's' };

      renInicial++;
    }
    // var wscols = [{ wch: 30 }, { wch: 40 }, { wch: 35 }, { wch: 35 }];
    // ws['!cols'] = wscols;
    ws['!ref'] = XLSX.utils.encode_range({
      s: { c: 0, r: 0 },
      e: { c: 15, r: renInicial },
    });
    wb.SheetNames.push(ws_name);
    wb.Sheets[ws_name] = ws;
    XLSX.writeFile(wb, filename, { cellStyles: true });
    // setExcelLoading(false);
  };
  return (
    <>
      <Row>
        <Col span={24}>
          <Button className="btn-download" onClick={() => showModal('Add')}>
            Agregar
          </Button>

          <Button className="btn-download" onClick={() => showModal('Edit')}>
            Editar
          </Button>
          <Button
            className="btn-delete"
            onClick={() => handleDeleteUser(userSelected)}
            disabled={userSelected === undefined}
          >
            Eliminar
          </Button>
        </Col>
      </Row>
      <Spin spinning={loadingForm} tip="Cargando...">
        <Table
          onChange={handleChange}
          columns={columns}
          dataSource={
            usersTable?.map((usr) => ({
              ...usr,
              shortId: usr.id.split('-')[0],
            })) || []
          }
          scroll={{ x: 1200 }}
          loading={reloadUsers}
          // pagination={{
          //     defaultCurrent: pagination.current,
          //     total: pagination.total,
          //     onChange: onChangePagination,
          // }}
        />
      </Spin>
      <Row>
        <Col span={24} style={{ textAlign: 'right' }}>
          <Button
            icon={<FilePdfFilled style={{ fontSize: 18 }} />}
            className="btn-download"
            onClick={() => exportPdf(usersTable)}
            disabled={usersTable.length === 0}
            style={{ width: 150 }}
          >
            Descargar Pdf
          </Button>
          <Button
            icon={<FileExcelFilled style={{ fontSize: 18 }} />}
            className="btn-download"
            onClick={() => generateExcel(usersTable)}
            disabled={usersTable.length === 0}
            style={{ width: 160 }}
          >
            Descargar Excel
          </Button>
        </Col>
      </Row>
      <Modal
        title={titleModal}
        visible={modalIsVisible}
        setIsVisible={setModalIsVisible}
        width={1000}
      >
        {contextModal}
      </Modal>
    </>
  );
}
