import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Spin,
  Form,
  Input,
  Button,
  Select,
  notification,
  Modal as ModalAntd,
  Tooltip,
} from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { getAccessTokenApi } from '../../../../../api/auth';
import { getUserApi, editUserApi } from '../../../../../api/users';
import { logout } from '../../../../../api/auth';
import { getAllSuppliers } from '../../../../../api/supplier';
import { getCompanyIdApi } from '../../../../../api/company';
import {
  ExclamationCircleOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { getAllClients } from '../../../../../api/clients';

const EditUserForm = ({ setModalIsVisible, setReloadUsers, idUser }) => {
  const [loadingForm, setLoadingForm] = useState(true);
  const [form] = Form.useForm();
  const [editUserForm, setEditUserForm] = useState([]);
  const [rol, setRol] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [proveedorSelected, setProveedorSelected] = useState(false);
  const [clientSelected, setClientSelected] = useState(false);
  const [clients, setClients] = useState([]);
  // const [checkActive, setCheckActive] = useState(false);
  const companyId = getCompanyIdApi();
  const token = getAccessTokenApi();
  const { confirm } = ModalAntd;
  const portalTypeOption = [
    {
      label: 'Portal cliente',
      value: 0,
    },
    {
      label: 'Portal proveedor',
      value: 1,
    },
    {
      label: 'Portal proveedor - cliente',
      value: 2,
    },
  ];

  useEffect(() => {
    const token = getAccessTokenApi();

    getAllSuppliers(token, '')
      .then((response) => {
        if (response.statusCode === 200) {
          let supp = response.result
            .filter((s) => s.active)
            .map((s) => ({
              value: s.supplierId,
              label: s.name,
            }));
          supp.sort((a, b) => a.label.localeCompare(b.label));
          setSuppliers(supp);
        }
      })
      .catch((err) => {});
  }, [token]);

  useEffect(() => {
    getUserApi(token, idUser).then((response) => {
      setEditUserForm([
        {
          name: ['userName'],
          value: response.result.userName,
        },
        {
          name: ['supplierId'],
          value: response.result.supplierId,
        },
        {
          name: ['customerId'],
          value: response.result.customerId,
        },
        {
          name: ['name'],
          value: response.result.name,
        },
        {
          name: ['lastName'],
          value: response.result.lastName,
        },
        {
          name: ['mothersLastName'],
          value: response.result.mothersLastName,
        },
        {
          name: ['phoneNumber'],
          value: response.result.phoneNumber,
        },
        {
          name: ['profiles'],
          value: response.result.userRoles,
        },
        {
          name: ['active'],
          value: response.result.active,
        },
        {
          name: ['receiveEmails'],
          value: response.result.receiveEmails,
        },
        {
          name: ['portalType'],
          value: response.result.portalType,
        },
      ]);
      const { userRoles } = response.result;
      setRol(userRoles.map((r) => r.toUpperCase()));
      if (userRoles.includes('Supplier')) {
        setProveedorSelected(true);
      }
      if (userRoles.includes('Customer')) {
        setClientSelected(true);
      }
      setLoadingForm(false);
    });
    // return () => {
    //     cleanup
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idUser]);

  useEffect(() => {
    const token = getAccessTokenApi();

    getAllClients(token)
      .then((response) => {
        if (response.statusCode === 200) {
          let customers = response.result.map((s, index) => ({
            key: index,
            value: s.id,
            label: s.name,
          }));
          customers.sort((a, b) => a.label.localeCompare(b.label));
          setClients(customers);
        }
      })
      .catch((err) => {});
  }, [token]);

  const onRoledSelected = (role) => {
    if (role === 'SUPPLIER') {
      setProveedorSelected(true);
    } else {
      setProveedorSelected(false);
    }
    if (role === 'CUSTOMER') {
      setClientSelected(true);
      form.setFieldsValue({
        portalType: 0,
      });
    } else {
      setClientSelected(false);
    }
    let copyTmp = [...rol];
    if (rol.includes(role)) {
      copyTmp = copyTmp.filter((e) => e !== role);
    } else {
      copyTmp.push(role);
    }
    if (
      role === 'ADMIN' ||
      role === 'OPERACIONES' ||
      role === 'MANAGER' ||
      role === 'VENTAS' ||
      role === 'PRICING'
    ) {
      copyTmp = copyTmp.filter(
        (e) => e !== 'CUSTOMER' && e !== 'SUPPLIER' && e !== 'FINANCIAL'
      );
    } else if (
      role === 'CUSTOMER' ||
      role === 'SUPPLIER' ||
      role === 'FINANCIAL'
    ) {
      copyTmp = copyTmp.filter(
        (e) =>
          e !== 'ADMIN' &&
          e !== 'OPERACIONES' &&
          e !== 'MANAGER' &&
          e !== 'PRICING' &&
          e !== 'VENTAS' &&
          e !== (role === 'CUSTOMER' ? 'SUPPLIER' : 'CUSTOMER') &&
          e !== (role === 'FINANCIAL' ? 'SUPPLIER' : 'FINANCIAL')
      );
    }
    setRol(copyTmp);
  };
  // const changeCheck = (e) => {
  //     setCheckActive(e.target.checked);
  // };
  const handleEditUser = (values) => {
    confirm({
      title: 'Editar usuario',
      icon: <ExclamationCircleOutlined />,
      content: '¿Estás seguro que deseas guardar datos modificados?',
      okText: 'Si',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        editUserFunction(values);
      },
      onCancel() {},
    });
  };

  const editUserFunction = async (values) => {
    setLoadingForm(true);
    let data = values;
    data['roles'] = rol;
    data['company'] = companyId;
    // data['customerId'] = '';
    data['id'] = idUser;

    // data['active'] = true;
    // data['active'] = checkActive;
    if (rol.length === 0) {
      notification['error']({
        message: 'Debes seleccionar un perfil',
      });
      setLoadingForm(false);
    } else {
      let response = null;
      try {
        response = await editUserApi(token, data);
      } catch (error) {
      } finally {
        setLoadingForm(false);
      }
      if (response === undefined) {
        notification['error']({
          message: 'Ocurrió un error, Inténtelo más tarde',
        });
        return false;
      }
      if (response.statusCode === 401) {
        notification['error']({
          message: 'Usuario no autorizado.',
        });
        setTimeout(() => {
          logout();
          window.location.reload();
        }, 1500);
        return false;
      }
      if (response.statusCode === 200) {
        notification['success']({
          message: 'Usuario editado con éxito',
        });
        setModalIsVisible(false);
        setReloadUsers(true);
        return;
      }
      if (response.statusCode === 400) {
        notification['error']({
          message: response.description,
        });
      } else {
        let messageError = 'Ocurrió un error, Inténtelo otra vez';
        if (response.statusCode === 409) {
          messageError = response.description;
        }
        notification['error']({
          message: messageError,
        });
      }
      setReloadUsers(true);
    }
    setLoadingForm(false);
  };
  return (
    <Spin spinning={loadingForm} tip="Cargando...">
      <Form
        name="add-promo"
        layout="vertical"
        form={form}
        onFinish={handleEditUser}
        fields={editUserForm}
        onFieldsChange={(changedFields, allFields) => {
          setEditUserForm(allFields);
        }}
      >
        <div>
          <Row xs={24} className="table-company">
            <Col xs={20}>
              <Form.Item label="Nombre de usuario:" name="userName">
                <Input disabled placeholder="Ingresa tu información" />
              </Form.Item>
              <Form.Item
                label="Usuario estatus:"
                name="active"
                valuePropName="checked"
              >
                <Checkbox>Activo</Checkbox>
              </Form.Item>

              <Form.Item
                label="Proveedor:"
                name="supplierId"
                hidden={!proveedorSelected}
                rules={[
                  {
                    required: proveedorSelected,
                    message: 'Por favor ingrese un proveedor',
                  },
                ]}
              >
                <Select
                  disabled={proveedorSelected === false}
                  placeholder="Selecciona una opción"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  showSearch
                >
                  {suppliers.map((s) => (
                    <Select.Option key={s.value} value={s.value}>
                      {s.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label="Cliente:"
                name="customerId"
                hidden={!clientSelected}
                rules={[
                  {
                    required: clientSelected,
                    message: 'Por favor ingrese un cliente',
                  },
                ]}
              >
                <Select
                  disabled={clientSelected === false}
                  placeholder="Selecciona una opción"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  showSearch
                >
                  {clients.map((s) => (
                    <Select.Option key={s.value} value={s.value}>
                      {s.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label="Nombre(s):"
                name="name"
                rules={[
                  {
                    required: true,
                    message: 'Por favor ingrese nombre',
                  },
                ]}
              >
                <Input placeholder="Ingresa tu información" />
              </Form.Item>
              <Form.Item
                label="Apellido paterno:"
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: 'Por favor ingrese apellido paterno',
                  },
                ]}
              >
                <Input placeholder="Ingresa tu información" />
              </Form.Item>
              <Form.Item
                label="Apellido materno:"
                name="mothersLastName"
                rules={[
                  {
                    required: true,
                    message: 'Por favor ingrese apellido materno',
                  },
                ]}
              >
                <Input placeholder="Ingresa tu información" />
              </Form.Item>
              <Form.Item
                label={
                  <span>
                    Teléfono:&nbsp;
                    <Tooltip title="El número telefónico debe contener 10 dígitos">
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </span>
                }
                name="phoneNumber"
                rules={[
                  {
                    required: true,
                    message: 'Por favor ingresa número telefónico',
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || value.toString().length === 10) {
                        return Promise.resolve();
                      }

                      return Promise.reject(
                        'Por favor ingresa número telefónico a 10 dígitos'
                      );
                    },
                  }),
                ]}
              >
                <Input placeholder="Ingresa tu información" />
              </Form.Item>
              <Col style={{ marginLeft: 10, marginTop: 8 }}>
                {/* <Form.Item label="Perfiles:" name="profiles"> */}
                <Checkbox
                  onChange={() => onRoledSelected('ADMIN')}
                  checked={rol.includes('ADMIN')}
                >
                  ADMIN
                </Checkbox>
                <Checkbox
                  onChange={() => onRoledSelected('MANAGER')}
                  checked={rol.includes('MANAGER')}
                >
                  GERENTE
                </Checkbox>
                <Checkbox
                  onChange={() => onRoledSelected('OPERACIONES')}
                  checked={rol.includes('OPERACIONES')}
                >
                  OPERACIONES
                </Checkbox>
                <Checkbox
                  onChange={() => onRoledSelected('FINANCIAL')}
                  checked={rol.includes('FINANCIAL')}
                >
                  FINANCIERO
                </Checkbox>

                <Checkbox
                  onChange={() => onRoledSelected('SUPPLIER')}
                  checked={rol.includes('SUPPLIER')}
                >
                  PROVEEDOR
                </Checkbox>
                <Checkbox
                  onChange={() => onRoledSelected('CUSTOMER')}
                  checked={rol.includes('CUSTOMER')}
                >
                  CLIENTE
                </Checkbox>
                <Checkbox
                  onChange={() => onRoledSelected('PRICING')}
                  checked={rol.includes('PRICING')}
                >
                  PRICING
                </Checkbox>
                <Checkbox
                  onChange={() => onRoledSelected('VENTAS')}
                  checked={rol.includes('VENTAS')}
                  style={{ marginTop: 10, marginBottom: 10 }}
                >
                  VENTAS
                </Checkbox>
                {/* </Form.Item> */}
              </Col>

              <Form.Item
                hidden={proveedorSelected || clientSelected}
                label="Portal:"
                name="portalType"
                rules={[
                  {
                    required: true,
                    message: 'Por favor ingrese la seccion que veria',
                  },
                ]}
              >
                <Select disabled={false} placeholder="Selecciona una opción">
                  {portalTypeOption.map((s) => (
                    <Select.Option key={s.value} value={s.value}>
                      {s.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label="Permiso de recibir correo de notificación de aclaraciones:"
                name="receiveEmails"
                valuePropName="checked"
              >
                <Checkbox>Recibir notificación</Checkbox>
              </Form.Item>

              <Row justify="end">
                <Form.Item>
                  <Button htmlType="submit" className="btn-enviar" block>
                    Guardar
                  </Button>
                </Form.Item>
              </Row>
            </Col>
          </Row>
        </div>
      </Form>
    </Spin>
  );
};

export default EditUserForm;
