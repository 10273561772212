import React from 'react';
import { Layout } from 'antd';
import GreetingsTop from '../../../components/Admin/GreetingsTop/GreetingsTop';
import ProfilePage from '../../../components/Admin/Profile/Profile';

export default function Profile() {
    return (
        <Layout>
            <GreetingsTop />
            <div className="billing__container">
                <ProfilePage />
            </div>
        </Layout>
    );
}
