import React from 'react';
import { Col, Layout, Row, Typography } from 'antd';
import GreetingsTop from '../../../components/Admin/GreetingsTop/GreetingsTop';
import AdminConfiguration from '../../../components/Admin/Configuration';

export default function Configuration() {
    return (
        <Layout>
            <GreetingsTop />

            <HeaderTop />

            <div className="billing__container">
                <AdminConfiguration />
            </div>
        </Layout>
    );
}
function HeaderTop() {
    const { Title } = Typography;
    return (
        <div className="billing__container">
            <Row className="header-top">
                <Col xs={23} className="header-top__left">
                    <Row>
                        <Col xs={20}>
                            <Title level={3}>Configuración</Title>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
}
