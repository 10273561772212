import React, { useState } from 'react';
import { Col, Layout, Row, Typography } from 'antd';
import GreetingsTop from '../../../../components/Admin/GreetingsTop/GreetingsTop';
import moment from 'moment';
import OperationsTable from '../../../../components/Logistic/Operations/OperationsTable';
import BackButton from '../../../../components/General/BackButton/BackButton';
export default function OperationFinancial() {
  const date = [moment().subtract(7, 'days'), moment()];
  const [dateRange, setDateRange] = useState({
    startDate: moment(date[0]).unix(),
    endDate: moment(date[1]).unix(),
  });
  function filterDate(date, dateString) {
    if (date) {
      let date2 = {
        startDate: moment(date[0]).unix(),
        endDate: moment(date[1]).unix(),
      };
      setDateRange(date2);
    }
  }
  return (
    <Layout>
      <GreetingsTop />
      <HeaderTop filterDate={filterDate} />
      <div className="billing__container">
        <OperationsTable dateRange={dateRange} isFinancial={true} />
      </div>
    </Layout>
  );
}

function HeaderTop({ filterDate }) {
  const { Title } = Typography;
  return (
    <div className="billing__container">
      <Row className="header-top">
        <Col xs={20} md={20} className="header-top__left">
          <Title
            level={3}
            style={{
              // position: 'absolute',
              top: 0,
              bottom: 0,
              height: '78%',
              margin: 'auto',
            }}
          >
            Operaciones
          </Title>
        </Col>
        <Col xs={4}>
          <Row
            style={{
              marginBottom: 30,
              justifyContent: 'right',
            }}
          >
            <BackButton routeName={'/cxc/cliente'}></BackButton>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
