import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Spin,
  Form,
  Input,
  Button,
  notification,
  Tooltip,
  Select,
  Checkbox,
  Tabs,
  Alert,
  InputNumber,
} from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { registerClientApi } from '../../../../../api/clients';
import { getCompanyIdApi } from '../../../../../api/company';
import { getAccessTokenApi, logout } from '../../../../../api/auth';
import { getUsersApi } from '../../../../../api/users';
import { METHOD_GET } from '../../../../../utils/constants';
import { API_URL_CATALOG_VALUES } from '../../../../../helpers/urls';
import {
  CITY_CATALOG_ID,
  COUNTRY_CATALOG_ID,
  STATE_CATALOG_ID,
  TIME_OF_MOVEMENTS_CATALOG_ID,
  TIME_OF_MOVEMENT_CATALOG_ID,
} from '../../../../../helpers/constants';
import { fetchApi } from '../../../../../helpers/fetch';
import { getCatalogValueHandle } from '../../../../../helpers/handlerFunctionApi';
import Location from './Location/Location';
import Catalog from './Catalog/Catalog';
import { getAllSuppliers } from '../../../../../api/supplier';

const AddClientForm = ({ setModalIsVisible, setReloadClients }) => {
  const [loadingForm, setLoadingForm] = useState(true);
  const [form] = Form.useForm();
  const companyId = getCompanyIdApi();
  const [financiales, setFinanciales] = useState([]);
  const [operadores, setOperadores] = useState([]);
  const [vendedores, setVendedores] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const token = getAccessTokenApi();
  const [countrySelected, setCountrySelected] = useState(0);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const { TabPane } = Tabs;
  const [textType, setTextType] = useState('');
  const [textDescription, setTextDescription] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [timeOfMovements, setTimeOfMovements] = useState([]);
  const [loadingCatalog, setLoadingCatalog] = useState();

  useEffect(() => {
    getCatalogValueHandle(
      TIME_OF_MOVEMENTS_CATALOG_ID,
      setTimeOfMovements,
      setLoadingCatalog
    );
  }, []);
  
  useEffect(() => {
    if (loadingForm) {
      getUsersApi(token)
        .then((response) => {
          if (response) {
            let financials = [];
            let operaciones = [];
            let ventas = [];
            response.result.forEach((element, index) => {
              if (element.roles.includes('Financial')) {
                financials.push(element);
              }
              if (element.roles.includes('Operaciones')) {
                operaciones.push(element);
              }
              if (element.roles.includes('Ventas')) {
                ventas.push(element);
              }
            });
            let financ = financials.map((f) => ({
              value: f.id,
              label: f.userName,
            }));
            financ.sort((a, b) => a.label.localeCompare(b.label));
            setFinanciales(financ);
            let opp = operaciones.map((f) => ({
              value: f.id,
              label: f.userName,
            }));
            opp.sort((a, b) => a.label.localeCompare(b.label));
            setOperadores(opp);
            let sales = ventas.map((f) => ({
              value: f.id,
              label: f.userName,
            }));
            sales.sort((a, b) => a.label.localeCompare(b.label));
            setVendedores(sales);
          }
          setLoadingForm(false);
        })
        .catch((err) => {});
    }
  }, [token, loadingForm]);

  useEffect(() => {
    getAllSuppliers(token, '').then((response) => {
      if (response) {
        let sup = response.result;
        let supp = sup?.map((e) => ({
          value: e.supplierId,
          label: e.name,
        }));
        // console.log(supp, 'supp');
        setSuppliers(supp);
      }
    });
  }, [token]);
  useEffect(() => {
    setLoadingForm(true);
    const config = {
      method: METHOD_GET,
      url: `${API_URL_CATALOG_VALUES}/GetAll/${COUNTRY_CATALOG_ID}`,
      data: null,
      token,
      // showNotification: true
    };
    fetchApi(config)
      .then((response) => {
        if (response) {
          let calogsValues = response.result;

          if (calogsValues) {
            calogsValues.map((cat, index) => {
              cat['key'] = index;
              cat['label'] = cat.description;
              cat['value'] = cat.id;
            });
          }
          setCountries(calogsValues);
        }
        setLoadingForm(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [token]);

  const onChangeCountryOrigin = (value) => {
    if (value !== countrySelected) {
      form.setFieldsValue({
        state: '',
        city: '',
        address: '',
        zipCode: '',
      });
    }
    setCountrySelected(value);
    getCatalogValueHandle(STATE_CATALOG_ID, setStates, setLoadingForm, value);
    getCatalogValueHandle(CITY_CATALOG_ID, setCities, setLoadingForm, value);
  };
  const onChangeState = (value) => {
    getCatalogValueHandle(CITY_CATALOG_ID, setCities, setLoadingForm, value);
    form.setFieldsValue({
      city: '',
      address: '',
      zipCode: '',
    });
  };
  const addClientFunction = async (values) => {
    setLoadingForm(true);
    let data = values;
    data['parentCustomerId'] = '';
    data['level'] = 0;
    data['company'] = companyId;
    data['prospect'] = true;
    const response = await registerClientApi(token, data);

    if (response === undefined) {
      notification['error']({
        message: 'Ocurrió un error, Inténtelomas tarde',
      });
      return false;
    }
    if (response.statusCode === 401) {
      notification['error']({
        message: 'Usuario no autorizado.',
      });
      setTimeout(() => {
        logout();
        window.location.reload();
      }, 1500);
      return false;
    }
    if (response.statusCode === 200) {
      setModalIsVisible(false);
      setReloadClients(true);
      notification['success']({
        message: 'Cliente agregado con éxito',
      });
    } else {
      let messageError = 'Ocurrió un error, Inténtelo más tarde';
      if (response.statusCode === 409) {
        messageError = response.description;
      }
      if (response.statusCode === 400) {
        messageError = response.description;
      }
      notification['error']({
        message: messageError,
      });
    }
    setLoadingForm(false);
  };

  const errorFields = ({ values, errorFields, outOfDate }) => {
    if (
      !values.name ||
      !values.id ||
      !values.fiscalID ||
      !values.phoneNumber ||
      !values.financialId
    ) {
      setTextType('Datos incompletos');
      setTextDescription(
        'Complete los campos obligatorios de detalle de cliente'
      );
      setShowAlert(true);

      setTimeout(() => {
        setShowAlert(false);
      }, 10000);
      return;
    }
    if (!values.country || !values.city || !values.state || !values.address) {
      setTextType('Datos incompletos');
      setTextDescription('Complete todos los datos de la sección "Dirección"');
      setShowAlert(true);

      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }
  };
  return (
    <Spin spinning={loadingForm || loadingCatalog} tip="Cargando...">
      <Form
        name="add-promo"
        layout="vertical"
        form={form}
        onFinish={addClientFunction}
        onFinishFailed={errorFields}
        initialValues={{ active: false }}
      >
        <div className="add-client-form">
          <Tabs defaultActiveKey="1" type="card">
            <TabPane tab="Detalle" key="infoBasic">
              <BasicInfo
                financiales={financiales}
                operadores={operadores}
                vendedores={vendedores}
                suppliers={suppliers}
                timeOfMovements={timeOfMovements}
              />
            </TabPane>

            <TabPane tab="Dirección" key="location" forceRender>
              <Location
                type={'add'}
                onChangeCountryOrigin={onChangeCountryOrigin}
                countries={countries}
                onChangeState={onChangeState}
                states={states}
                cities={cities}
              />
            </TabPane>
            <TabPane tab="Catálogos" key="catalog">
              <Catalog />
            </TabPane>
          </Tabs>
          {showAlert && (
            <Alert
              message={textType}
              description={textDescription}
              type="warning"
              showIcon
              style={{ marginBottom: 20 }}
            />
          )}
          <Row justify="end">
            <Form.Item>
              <Button htmlType="submit" className="btn-enviar" block>
                Guardar
              </Button>
            </Form.Item>
          </Row>
        </div>
      </Form>
    </Spin>
  );
};

export default AddClientForm;

function BasicInfo({ financiales, operadores, vendedores, suppliers,timeOfMovements }) {
  return (
    <Row gutter={16}>
      <Col xs={24} md={12} lg={8}>
        <Form.Item
          label="Nombre de cliente:"
          name="name"
          rules={[
            {
              required: true,
              message: 'Por favor ingrese un nombre',
            },
          ]}
        >
          <Input placeholder="Ingresa tu información" />
        </Form.Item>
      </Col>
      <Col xs={24} md={12} lg={8}>
        <Form.Item
          label="Id. de cliente:"
          name="id"
          rules={[
            {
              required: true,
              message: 'Por favor ingrese un Id',
            },
          ]}
        >
          <Input placeholder="Ingresa tu información" />
        </Form.Item>
      </Col>
      
      <Col xs={24} md={12} lg={8}>
        <Form.Item
          label="RFC:"
          name="fiscalID"
          // rules={[
          //   {
          //     required: true,
          //     message: 'Por favor ingrese RFC',
          //   },
          // ]}
        >
          <Input placeholder="Ingresa tu información" />
        </Form.Item>
      </Col>
      <Col xs={24} md={12} lg={8}>
        <Form.Item
          label={
            <span>
              Teléfono:&nbsp;
              <Tooltip title="El número telefónico debe contener 10 dígitos">
                <QuestionCircleOutlined />
              </Tooltip>
            </span>
          }
          name="phoneNumber"
          rules={[
            {
              required: true,
              message: 'Por favor ingresa número telefónico',
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || value.toString().length === 10) {
                  return Promise.resolve();
                }

                return Promise.reject(
                  'Por favor ingresa número telefónico a 10 dígitos'
                );
              },
            }),
          ]}
        >
          <Input placeholder="Ingresa tu información" />
        </Form.Item>
      </Col>
      <Col xs={24} md={12} lg={8}>
        <Form.Item
          label="Correo electrónico:"
          name="emailAddress"
          rules={[
            {
              required: true,
              message: 'Por favor ingrese un email',
            },
          ]}
        >
          <Input type={'email'} placeholder="Ingresa tu información" />
        </Form.Item>
      </Col>
      <Col xs={24} md={12} lg={8}>
        <Form.Item
          label="Referencia:"
          name="reference"
        >
          <Input placeholder="Ingresa tu información" />

        </Form.Item>
      </Col>
    
      <Col xs={24} md={12} lg={8}>
        <Form.Item label="Financiero:" name="financialIds">
          <Select
            placeholder="Selecciona una opción"
            mode="multiple"
            allowClear
            showArrow
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {financiales.map((f) => (
              <Select.Option key={f.value} value={f.value}>
                {f.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col xs={24} md={12} lg={8}>
        {/* operaciones */}
        <Form.Item
          label="Tráfico:"
          name="operacionesId"
          // rules={[
          //   {
          //     required: true,
          //     message: 'Por favor seleccione un operador',
          //   },
          // ]}
        >
          <Select
            placeholder="Selecciona una opción"
            allowClear={true}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {operadores.map((o) => (
              <Select.Option key={o.value} value={o.value}>
                {o.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col xs={24} md={12} lg={8}>
        <Form.Item
          label="Ventas:"
          name="salesPersonIds"
        >
          <Select
            placeholder="Selecciona una opción"
            allowClear={true}
            showSearch
            mode="multiple"
            showArrow
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {vendedores.map((o) => (
              <Select.Option key={o.value} value={o.value}>
                {o.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col xs={24} md={12} lg={8}>
        <Form.Item
          label="Movimientos esperados:"
          name="expectedMovements"
        >
        <InputNumber placeholder="Número de movimientos" style={{width: "100%"}} />

        </Form.Item>
      </Col>
      <Col xs={24} md={12} lg={8}>
        <Form.Item
          label="Tiempo de movimientos:"
          name="movementTimeId"
        >
           <Select
              placeholder="Selecciona una opción"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {timeOfMovements.map((s) => (
                <Select.Option key={s.value} value={s.value}>
                  {s.label}
                </Select.Option>
              ))}
            </Select>
        </Form.Item>
      </Col>
      <Col xs={24} md={12} lg={8}>
        <Form.Item
          label="Usuario estatus:"
          name="active"
          hidden
          valuePropName="checked"
        >
          <Checkbox>Activo</Checkbox>
        </Form.Item>
      </Col>
      {/* <Col xs={24} md={12} lg={8}>
        <Form.Item
          label="Proveedor:"
          name="supplierIds"
          // rules={[
          //   {
          //     required: true,
          //     message: 'Por favor seleccione un financiero',
          //   },
          // ]}
        >
          <Select
            placeholder="Selecciona una opción"
            mode="multiple"
            allowClear
            showArrow
          >
            {suppliers.map((f) => (
              <Select.Option key={f.value} value={f.value}>
                {f.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col> */}
    </Row>
  );
}
