import React, { useState } from 'react';
import {
  Table,
  Space,
  Button,
  Tooltip,
  Row,
  Col,
  Modal as ModalAnt,
} from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import EditValuesASForm from '../../../Admin/Catalog/Custom/AdditionalServices/EditValuesASForm';
import Modal from '../../../General/Modal';
import { METHOD_DELETE } from '../../../../utils/constants';
import { API_URL_ADDITIONAL_SERVICE } from '../../../../helpers/urls';
import { getAccessTokenApi } from '../../../../api/auth';
import { fetchApi } from '../../../../helpers/fetch';

const TableCustome = (props) => {
  const { data = [], loading, setReloadCatalogValues } = props;
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [titleModal, setTitleModal] = useState(false);
  const [contextModal, setContextModal] = useState(null);
  const { confirm } = ModalAnt;
  const token = getAccessTokenApi();

  const textModal = '¿Estás seguro que deseas borrar este campo';

  const deleteFieldValue = async (id) => {
    const config = {
      method: METHOD_DELETE,
      url: `${API_URL_ADDITIONAL_SERVICE}/${id}`,
      data: null,
      token,
      showNotification: true,
    };
    const result = await fetchApi(config);

    if (result) {
      if (result.statusCode === 200) {
        setReloadCatalogValues(true);
      }
    }
  };

  const showDeleteConfirm = (id, description) => {
    confirm({
      title: `${textModal} ${description} ?`,
      icon: <ExclamationCircleOutlined />,
      content: 'No podrá deshacer esta acción después..',
      okText: 'Si',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteFieldValue(id);
      },
      onCancel() {},
    });
  };

  const columns = [
    {
      title: 'Código',
      dataIndex: 'code',
      key: 'code',
      align: 'center',
    },
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
    },

    {
      title: 'Acciones',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <Tooltip title="Editar">
            <Button onClick={() => showModal('Edit', record)} type="primary">
              <EditOutlined />
            </Button>
          </Tooltip>

          <Tooltip title="Eliminar">
            <Button
              danger
              type="primary"
              onClick={() => showDeleteConfirm(record.id, record.name)}
            >
              <DeleteOutlined />
            </Button>
          </Tooltip>
        </Space>
      ),
      align: 'center',
    },
  ];

  const showModal = (type, record) => {
    if (type === 'Edit') {
      setModalIsVisible(true);
      setTitleModal('Editar valores');
      setContextModal(
        <EditValuesASForm
          setModalIsVisible={setModalIsVisible}
          valueField={record}
          setReloadCatalogValues={setReloadCatalogValues}
        />
      );
    }
  };

  return (
    <div className="billing__container">
      <Table
        columns={columns}
        dataSource={data}
        loading={loading}
        scroll={{ x: 1200 }}
        // expandable={{
        //   expandedRowRender: (record) => {
        //     return <ExpandedInfo record={record} />;
        //   },
        //   rowExpandable: (record) => record.name !== 'Not Expandable',
        // }}
      />
      <Modal
        title={titleModal}
        visible={modalIsVisible}
        setIsVisible={setModalIsVisible}
        // width={1000}
      >
        {contextModal}
      </Modal>
    </div>
  );
};

export default TableCustome;

function ExpandedInfo({ record }) {
  return (
    <>
      <h4>Más Valores:</h4>
      <Row>
        {record?.fieldValues?.map((field, index) => (
          <Col md={6} key={index}>
            <p>{`${field.name.toUpperCase()} : ${
              field.value ? field.value : '-'
            }`}</p>
          </Col>
        ))}
      </Row>
    </>
  );
}
