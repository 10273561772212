/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, Spin, Row } from 'antd';
// import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { METHOD_GET, METHOD_POST } from '../../../../utils/constants';
import { API_URL_CATALOG_VALUES } from '../../../../helpers/urls';
import { fetchApi } from '../../../../helpers/fetch';
import { getAccessTokenApi } from '../../../../api/auth';
import {
  COUNTRY_CATALOG_ID,
  STATE_CATALOG_ID,
  CITY_CATALOG_ID,
  PORT_CATALOG_ID,
  TYPE_OF_SERVICE_CATALOG_ID,
  SERVICES_CATALOG_ID,
  AIRPORT_CATALOG_ID,
} from '../../../../helpers/constants';

import './AddValuesForm.scss';

const AddValuesForm = ({
  setReloadCatalogValues,
  setModalIsVisible,
  catalog,
}) => {
  const catalogRelational = [
    STATE_CATALOG_ID,
    CITY_CATALOG_ID,
    PORT_CATALOG_ID,
    TYPE_OF_SERVICE_CATALOG_ID,
    AIRPORT_CATALOG_ID,
  ];
  const [loading, setLoading] = useState(false);
  const token = getAccessTokenApi();
  const [form] = Form.useForm();
  const needRelationalId = catalog.hasRelation === 1 ? true : false;
  // const needRelationalId = catalogRelational.find(e=>e === catalog.id) ? true : false;
  const [labelDynamic, setLabelDynamic] = useState('ID relacional');
  const [option, setOption] = useState([]);

  const getOption = (idRelational) => {
    setLoading(true);
    const config = {
      method: METHOD_GET,
      url: `${API_URL_CATALOG_VALUES}/GetAll/${idRelational}`,
      data: null,
      token,
      // showNotification: true
    };
    fetchApi(config)
      .then((response) => {
        if (response) {
          let calogsValues = response.result;

          if (calogsValues) {
            calogsValues.map((cat, index) => {
              cat['key'] = index;
              cat['label'] = cat.description;
              cat['value'] = cat.id;
              // cat["value"] = cat.code;
              return cat;
            });
          }
          setOption(calogsValues);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getIDCatalogRelational = () => {
    let catalogID = 0;
    switch (catalog.id) {
      //estado
      case 10:
        catalogID = COUNTRY_CATALOG_ID;
        break;

      //ciudad
      case 11:
        catalogID = STATE_CATALOG_ID;
        break;

      // puerto
      case 16:
        catalogID = CITY_CATALOG_ID;
        break;
      // aeropuerto
      case 24:
        catalogID = CITY_CATALOG_ID;
        break;

      // tipo de servicio
      case 18:
        catalogID = SERVICES_CATALOG_ID;
        break;

      default:
        break;
    }

    return catalogID;
  };

  useEffect(() => {
    if (catalog.id === STATE_CATALOG_ID) {
      setLabelDynamic('País relacional');
    }

    if (catalog.id === CITY_CATALOG_ID) {
      setLabelDynamic('Estado relacional');
    }

    if (catalog.id === PORT_CATALOG_ID || AIRPORT_CATALOG_ID) {
      setLabelDynamic('Ciudad relacional');
    }
    if (catalog.id === TYPE_OF_SERVICE_CATALOG_ID) {
      setLabelDynamic('Servicio');
    }
    const idRelational = getIDCatalogRelational();
    if (needRelationalId && idRelational > 0) {
      getOption(idRelational);
    }
  }, []);

  function onSearch(val) {
    // console.log('search:', val);
  }

  const onFinish = async (values) => {
    setLoading(true);

    let fieldValues = [];

    catalog.fieldsArray.forEach((field) => {
      const JSONfields = { name: field, Value: values[field] };
      fieldValues.push(JSONfields);
    });

    values['FieldValues'] = fieldValues;
    values['CatalogId'] = catalog.id;

    const config = {
      method: METHOD_POST,
      url: `${API_URL_CATALOG_VALUES}`,
      data: values,
      token,
      showNotification: true,
    };
    const result = await fetchApi(config);

    if (result) {
      if (result.statusCode === 200) {
        // form.resetFields();
        setModalIsVisible(false);
        setReloadCatalogValues(true);
      }
    }

    setLoading(false);
  };

  return (
    <div className="add-catalog-form">
      <Spin spinning={loading} tip="Cargando...">
        <Form
          name="AddCatalog"
          form={form}
          // labelCol={{ span: 8 }}
          // wrapperCol={{ span: 16 }}
          initialValues={{ Catalog: catalog.catalogId }}
          onFinish={onFinish}
          layout="vertical"
          // autoComplete="off"
        >
          <Form.Item
            label="Catálogo Id"
            name="Catalog"
            rules={[
              { required: true, message: 'Por favor ingresa un Catálogo ID' },
            ]}
          >
            <Input disabled />
          </Form.Item>

          <Form.Item
            label="Descripción"
            name="Description"
            rules={[
              { required: true, message: 'Por favor ingresa una descripción' },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Código"
            name="Code"
            rules={[
              { required: false, message: 'Por favor ingresa un código' },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="SAT ID"
            name="satId"
            rules={[
              { required: false, message: 'Por favor ingresa un SAT ID' },
            ]}
          >
            <Input />
          </Form.Item>

          {needRelationalId && (
            <Form.Item
              label={labelDynamic}
              name="fatherId"
              rules={[
                { required: false, message: 'Por favor ingresa un valor' },
              ]}
            >
              {catalogRelational.find((e) => e === catalog.id) ? (
                <Select
                  placeholder="Selecciona una opción"
                  showSearch
                  optionFilterProp="children"
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {option.map((s) => (
                    <Select.Option key={s.value} value={s.value}>
                      {s.label}
                    </Select.Option>
                  ))}
                </Select>
              ) : (
                <Input />
              )}
            </Form.Item>
          )}

          {catalog.fieldsArray &&
            catalog.fieldsArray.map((field, index) => (
              <Form.Item
                key={index}
                label={field}
                name={field}
                rules={[
                  { required: false, message: `Por favor ingresa un ${field}` },
                ]}
              >
                <Input />
              </Form.Item>
            ))}
          <Row justify="end">
            <Form.Item>
              <Button className="btn-enviar" htmlType="submit">
                Guardar
              </Button>
            </Form.Item>
          </Row>
        </Form>
      </Spin>
    </div>
  );
};

export default AddValuesForm;
