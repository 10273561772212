import React, { useEffect, useState } from 'react';
import { notification, Tabs } from 'antd';
import AdditionalServicesTable from './AdditionalServicesTable';
import BasicInformationLine from './BasicInformationLine/BasicInformationLine';
import LinesDetail from './LinesDetail/LinesDetail';

const OperationLine = (props) => {
  const {
    service,
    type,
    quoteSelected,
    lines,
    setLines,
    basicInformationForm,
    clearHeader,
    setAdditionalServices,
    additionalServices,
    quoteId,
    linesDetailForm,
    operationId,
    delivered,
  } = props;
  const { TabPane } = Tabs;
  const [tabKey, setTabKey] = useState(0);
  const [disableAllInputs, setDisableAllInputs] = useState(
    tabKey === 1 ? true : false
  );
  const [lineSelected, setLineSelected] = useState(0);
  const [keySelected, setKeySelected] = useState(0);
  const [pieces, setPieces] = useState([]);
  const [totalNetWeight, setTotalNetWeight] = useState(0);
  const [totalTareWeight, setTotalTareWeight] = useState(0);
  const [totalGrossWeight, setTotalGrossWeight] = useState(0);
  const [volume, setVolume] = useState(0);
  const [loadingForm, setLoadingForm] = useState(false);
  const [storeLine, setStoreLine] = useState(true);
  const [saveForm, setSaveForm] = useState([]);

  const changeTab = React.useCallback(
    (value) => {
      let tabkey = parseInt(value);
      setTabKey(tabkey);
      if (value === '0') {
        basicInformationForm.setFieldsValue({ ...saveForm });
        setStoreLine(true);
        if (saveForm.pieces?.length > 0) {
          setPieces([...saveForm.pieces]);
        } else {
          setPieces([]);
        }
        if (lines.length === 0) {
          basicInformationForm.setFieldsValue({
            number: 1,
          });
        } else {
          let num = Math.max.apply(
            Math,
            lines.map((line) => {
              return line.number;
            })
          );
          basicInformationForm.setFieldsValue({
            number: num + 1,
          });
        }
      }

      if (value === '1' && storeLine) {
        const valuesForm = basicInformationForm.getFieldsValue();
        setSaveForm(valuesForm);
        setStoreLine(false);
        valuesForm['pieces'] = pieces;
        setPieces([]);
      }
      if (value === '0') {
        let newLines = [...lines];
        const valuesForm = linesDetailForm.getFieldsValue();
        const index = lines.findIndex(
          (item) => item.number === valuesForm.linesId
        );
        valuesForm['number'] = valuesForm.linesId;
        if (lines.length > 0 && lines[keySelected].releases) {
          valuesForm['releases'] = lines[keySelected].releases;
        }
        newLines[index] = valuesForm;
        setLines(() => newLines);
      }
      if (value === '1' && lines.length) {
        const formValues = lines[0];

        linesDetailForm.setFieldsValue({ ...formValues });
        setPieces([]);

        if (formValues.releases) {
          setPieces([...formValues.releases]);
        }
        let num = Math.min.apply(
          Math,
          lines.map((line) => {
            return line.number;
          })
        );
        setLineSelected(num);
        setKeySelected(0);
        if (lines.length > 0) {
          setPieces([lines[0].releases]);
        }
      }
    },
    [basicInformationForm, lines, linesDetailForm, pieces, saveForm, storeLine]
  );
  useEffect(() => {
    if (type === 'watch') {
      changeTab('1');
    }
  }, [type]);
  useEffect(() => {
    if (quoteSelected) {
      const lineFilledEdit = {
        id: 1,
        // equipmentType: quoteSelected.unitType,
        container: quoteSelected.container,
        containerNum: quoteSelected.containerNum,
        mark: quoteSelected.mark,
        stamp: quoteSelected.stamp,
        plate: quoteSelected.plate,
        totalNetWeight: 0,
        totalTareWeight: 0,
        totalGrossWeight: 0,
        volume: quoteSelected.volume,
        purchaseOrder: quoteSelected.purchaseOrder,
        bol: quoteSelected.bol,
        petition: quoteSelected.petition,
      };
      let pv = quoteSelected.pieces?.map((e, i) => ({
        volume: e.height * e.width * e.length,
        netWeight: 0,
        tareWeight: 0,
        grossWeight: 0,
        packaging: e.packaging,
        packagingLabel: e.packagingLabel,
        description: e.description,
        length: e.length,
        width: e.width,
        height: e.height,
        pieces: e.piecesNumber,
      }));
      setPieces(pv);
      // setVolume(pv.volume);
      basicInformationForm.setFieldsValue(lineFilledEdit);
    }
  }, [quoteSelected, basicInformationForm]);
  useEffect(() => {
    let countNetWeight = 0;
    let countTareWeight = 0;
    let countGrossWeight = 0;
    let countVolume = 0;
    pieces?.forEach((element) => {
      countNetWeight += element.netWeight;
      countTareWeight += element.tareWeight;
      countGrossWeight += element.grossWeight;
      countVolume += element.volume;
    });
    if (tabKey === 1) {
      linesDetailForm.setFieldsValue({
        totalGrossWeight: countGrossWeight,
        totalNetWeight: countNetWeight,
        totalTareWeight: countTareWeight,
        volume: countVolume,
      });
    } else if (tabKey === 0) {
    }
    basicInformationForm.setFieldsValue({
      totalGrossWeight: countGrossWeight,
      totalNetWeight: countNetWeight,
      totalTareWeight: countTareWeight,
      volume: countVolume,
    });
    setTotalNetWeight(() => countNetWeight);
    setTotalTareWeight(() => countTareWeight);
    setTotalGrossWeight(() => countGrossWeight);
    setVolume(() => countVolume);
  }, [pieces, basicInformationForm, linesDetailForm, tabKey]);

  useEffect(() => {
    if (tabKey === 1 && lines.length > 0) {
      const lineFind = lines.find((l) => l.number === lineSelected);
      if (lineFind) {
        const lineFilledEdit = {
          id: lineFind.id,
          linesId: lineFind.number,
          equipmentType: lineFind.equipmentType,
          container: lineFind.container,
          containerNum: lineFind.containerNum,
          mark: lineFind.mark,
          stamp: lineFind.stamp,
          plate: lineFind.plate,
          totalNetWeight: lineFind.totalNetWeight,
          totalTareWeight: lineFind.totalTareWeight,
          totalGrossWeight: lineFind.totalGrossWeight,
          volume: lineFind.volume,
          purchaseOrder: lineFind.purchaseOrder,
          bol: lineFind.bol,
          petition: lineFind.petition,
        };
        linesDetailForm.setFieldsValue(lineFilledEdit);
        setDisableAllInputs(false);
        setPieces(lineFind.releases);

        // setTotalNetWeight((v) => (v += lineFind.totalNetWeight));
        // setTotalTareWeight((v) => (v += lineFind.totalTareWeight));
        // setTotalGrossWeight((v) => (v += lineFind.totalGrossWeight));
        // setVolume((v) => (v += lineFind.volume));
      }
    } else {
      setDisableAllInputs(true);
    }
  }, [lineSelected, linesDetailForm, lines, tabKey]);
  const handleLineSave = React.useCallback(
    async (value) => {
      if (value.petition) {
        value['petition'] = value.petition.toString();
      }
      if (pieces.length > 0 && totalGrossWeight === 0) {
        notification.error({
          message: 'El peso total Bruto no puede ser 0',
        });
        return;
      } else if (pieces.length > 0 && totalNetWeight === 0) {
        notification.error({
          message: 'El peso total Neto no puede ser 0',
        });
        return;
      }
      if (tabKey === 0) {
        value['releases'] = pieces;
        if (type === 'Edit') {
          value['operationModelId'] = operationId;
        }
        setLines((prevState) => [...prevState, value]);
        basicInformationForm.resetFields();
        setPieces([]);
      } else {
        if (value.container === undefined) {
          value['container'] = null;
        }
        let newLines = lines;
        const index = lines.findIndex((item) => item.number === value.linesId);
        value['number'] = value.linesId;
        value['totalGrossWeight'] = totalGrossWeight;
        value['operationModelId'] = operationId;
        value['totalNetWeight'] = totalNetWeight;
        value['totalTareWeight'] = totalTareWeight;
        // ????
        value['releases'] = lines[keySelected].releases;
        newLines[index] = value;
        setLines(newLines);
      }
      const message = `Línea ${
        tabKey === 0 ? 'creada' : 'editada'
      } exitosamente`;

      notification.success({
        message,
      });
    },
    [
      basicInformationForm,
      keySelected,
      lines,
      pieces,
      setPieces,
      setLines,
      tabKey,
      totalGrossWeight,
      totalNetWeight,
      totalTareWeight,
      operationId,
      type,
    ]
  );
  const onChangeLineSelected = React.useCallback(
    (selected) => {
      // 0 numero de la lineas
      // 1 id de la linea
      setLineSelected(() => selected[0]);
      setKeySelected(() => selected[1]);
      linesDetailForm.setFieldsValue({
        linesId: selected[0],
      });
      setDisableAllInputs(false);
    },
    [setLineSelected, linesDetailForm]
  );

  const deleteLine = React.useCallback(
    async (line) => {
      setLoadingForm(true);
      // borrar elemento de array
      setLines((lineas) => lineas.filter((e) => e.number !== line));
      setAdditionalServices((ad) =>
        ad.filter((e) => e.operationLineNumber !== line)
      );
      notification['success']({
        message: `Línea ${line} eliminada exitosamente `,
      });
      basicInformationForm.resetFields();
      setPieces([]);
      setTotalGrossWeight(0);
      setTotalNetWeight(0);
      setTotalTareWeight(0);
      setVolume(0);
      setLoadingForm(false);
      setDisableAllInputs(true);
      return false;
    },
    [setAdditionalServices, basicInformationForm, setLines]
  );
  const tabsMemo = React.useMemo(
    () => (
      <Tabs
        defaultActiveKey={type !== 'watch' ? '0' : '1'}
        type="card"
        size="small"
        onTabClick={changeTab}
      >
        {type !== 'watch' && (
          <TabPane tab={'Línea'} key={'0'}>
            <BasicInformationLine
              service={service}
              type="single"
              lines={lines}
              setLines={setLines}
              quoteSelected={quoteSelected}
              basicInformationForm={basicInformationForm}
              clearHeader={clearHeader}
              tabKey={tabKey}
              handleLineSave={handleLineSave}
              pieces={pieces}
              setPieces={setPieces}
              totalNetWeight={totalNetWeight}
              totalTareWeight={totalTareWeight}
              totalGrossWeight={totalGrossWeight}
              volume={volume}
              setLoadingForm={setLoadingForm}
              // disableAllInputs={disableAllInputs}
              typeWatch={type === 'watch' ? 'watch' : null}
              delivered={delivered}
            />
          </TabPane>
        )}

        <TabPane tab={'Detalle de líneas'} key={'1'}>
          <LinesDetail
            service={service}
            type="multiple"
            lines={lines}
            setLines={setLines}
            quoteSelected={quoteSelected}
            linesDetailForm={linesDetailForm}
            clearHeader={clearHeader}
            setAdditionalServices={setAdditionalServices}
            additionalServices={additionalServices}
            tabKey={tabKey}
            onChangeLineSelected={onChangeLineSelected}
            disableAllInputs={disableAllInputs}
            lineSelected={lineSelected}
            keySelected={keySelected}
            setDisableAllInputs={setDisableAllInputs}
            handleLineSave={handleLineSave}
            pieces={pieces}
            setPieces={setPieces}
            totalNetWeight={totalNetWeight}
            totalTareWeight={totalTareWeight}
            totalGrossWeight={totalGrossWeight}
            volume={volume}
            loadingForm={loadingForm}
            setLoadingForm={setLoadingForm}
            deleteLine={deleteLine}
            typeWatch={type === 'watch' ? 'watch' : null}
            operationId={operationId}
            delivered={delivered}
          />
        </TabPane>
        <TabPane tab={'Servicios adicionales'} key={'2'}>
          <AdditionalServicesTable
            setAdditionalServices={setAdditionalServices}
            additionalServices={additionalServices}
            type={'operation'}
            quoteSelected={quoteSelected}
            quoteId={quoteId}
            lines={lines}
            disableAllInputs={disableAllInputs}
            typeWatch={type === 'watch' ? 'watch' : null}
            delivered={delivered}
          />
        </TabPane>
      </Tabs>
    ),
    [
      basicInformationForm,
      linesDetailForm,
      clearHeader,
      quoteSelected,
      tabKey,
      lines,
      setLines,
      setAdditionalServices,
      additionalServices,
      lineSelected,
      onChangeLineSelected,
      disableAllInputs,
      changeTab,
      handleLineSave,
      pieces,
      totalGrossWeight,
      totalNetWeight,
      totalTareWeight,
      volume,
      deleteLine,
      loadingForm,
      quoteId,
      type,
      keySelected,
      delivered,
      operationId,
    ]
  );
  return <div>{tabsMemo}</div>;
};

export default OperationLine;
