import React, { useState, useEffect } from 'react';
import {
  Button,
  Modal as ModalAntd,
  Space,
  Table,
  Tooltip,
  Col,
  DatePicker,
  Row,
  Input,
  Tag,
} from 'antd';
import {
  ReloadOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
  ExclamationCircleOutlined,
  EditOutlined,
  FileDoneOutlined,
  EyeOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import Modal from '../../General/Modal';
import { fetchApi } from '../../../helpers/fetch';
import { getAccessTokenApi } from '../../../api/auth';
import {
  METHOD_DELETE,
  METHOD_GET,
  ROL_ADMIN,
  ROL_FINANCIAL,
  ROL_LAYOUT,
  ROL_SUPPLIER
} from '../../../utils/constants';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import useUser from '../../../hooks/useUser';
import PaymentInvoice from '../../Admin/Billling/PaymentInvoiceForm/PaymentInvoice';
import { API_URL_PAYMENTS } from '../../../helpers/urls';
import { formatMoney } from '../../../utils/general';
import moment from 'moment';
import Highlighter from 'react-highlight-words';
import { getFilteredDataFromLocalStorage, saveFilteredDataToLocalStorage } from '../../../helpers/saveFilterData';
import PaymentComplement from '../../Admin/Billling/PaymentComplement';

const PaymentsTable = () => {
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [reloadPayments, setReloadPayments] = useState(true);
  const [titleModal, setTitleModal] = useState(false);
  const [contextModal, setContextModal] = useState(null);
  // const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  // const hasSelected = selectedRowKeys.length > 0;
  const { confirm } = ModalAntd;
  const token = getAccessTokenApi();
  let { invoiceId, type } = useParams();
  const [payments, setPayments] = useState([]);
  const { person } = useUser();
  const [startDate, setStartDate] = useState({
    paymentDate: '',
    lastUpdatedAt: '',
  });
  const [endDate, setEndDate] = useState({
    paymentDate: '',
    lastUpdatedAt: '',
  });
  const x = React.createRef();
  const [searchedColumn, setSearchedColumn] = useState('');
  const [filteredInfo, setFilteredInfo] = useState({});
  const [searchText, setSearchText] = useState('');
  const typeInvoice = type;

  useEffect(() => {
    if (type === 'ap') {
      const config = {
        method: METHOD_GET,
        url: `APInvoice/${invoiceId}/${API_URL_PAYMENTS}`,
        data: null,
        token,
      };
      fetchApi(config)
        .then((response) => {
          if (response.statusCode === 200) {
            const allPayments = response.result;
            allPayments.map((p) => (p['key'] = p.id));
            setPayments(allPayments);
          }
          setReloadPayments(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (type === 'ar') {
      const config = {
        method: METHOD_GET,
        url: `Invoice/${invoiceId}/${API_URL_PAYMENTS}`,
        data: null,
        token,
      };
      fetchApi(config)
        .then((response) => {
          if (response.statusCode === 200) {
            const allPayments = response.result;
            allPayments.map((p) => (p['key'] = p.id));
            setPayments(allPayments);
          }
          setReloadPayments(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [reloadPayments, invoiceId, token, type]);
  useEffect(() => {
    const datosObtenidos = getFilteredDataFromLocalStorage('payments');
    if (datosObtenidos !== null) {
        setFilteredInfo(datosObtenidos.filters);
        setStartDate(datosObtenidos.dateFilters.startDates)
        setEndDate(datosObtenidos.dateFilters.endDates)
    }
  },[])
  const showModal = (type, reference) => {
    if (type === 'Add') {
      setModalIsVisible(true);
      setTitleModal(`Factura #${invoiceId} a pagar`);
      setContextModal(
        <PaymentInvoice
          setModalIsVisible={setModalIsVisible}
          invoiceId={invoiceId}
          setReloadPayments={setReloadPayments}
          typeInvoice={typeInvoice}
        ></PaymentInvoice>
      );
    } else if (type === 'Edit') {
      setModalIsVisible(true);
      setTitleModal(`Editar pago #${reference.id} - factura #${invoiceId} `);
      setContextModal(
        <PaymentInvoice
          setModalIsVisible={setModalIsVisible}
          invoiceId={invoiceId}
          setReloadPayments={setReloadPayments}
          paymentId={reference.id}
          typeInvoice={typeInvoice}
        ></PaymentInvoice>
      );
    } else if (type === 'Watch') {
      setModalIsVisible(true);
      setTitleModal(`Pago #${reference.id} - factura #${invoiceId} `);
      setContextModal(
        <PaymentInvoice
          setModalIsVisible={setModalIsVisible}
          invoiceId={invoiceId}
          paymentId={reference.id}
          type={'watch'}
          typeInvoice={typeInvoice}
        ></PaymentInvoice>
      );
    }else if (type === 'AddComplement') {
      setModalIsVisible(true);
      setTitleModal(`Complemento de pago #${reference.id} - factura #${invoiceId} `);
      setContextModal(
        <PaymentComplement
          setModalIsVisible={setModalIsVisible}
          invoiceId={invoiceId}
          setReloadPayments={setReloadPayments}
          paymentId={reference.id}
          typeInvoice={typeInvoice}
          type={'addComplement'}
        ></PaymentComplement>
      );
    }
  };
  const getColumnSearchDateProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Col>
          <DatePicker
            ref={x}
            id={'startDate' + dataIndex}
            placeholder={'Fecha inicial'}
            style={{ marginRight: '10px' }}
            format="DD/MM/YYYY"
            allowClear={false}
            value={
              startDate[dataIndex] !== ''
                ? moment.unix(startDate[dataIndex])
                : null
            }
            onChange={(e) => {
              // setStartDate(moment(e).unix());
              startDate[dataIndex] = moment(e).startOf('day').unix();
              setSelectedKeys(
                payments
                  .filter(
                    (op) =>
                      moment(e)
                        .hours(0)
                        .minutes(0)
                        .seconds(0)
                        .milliseconds(0)
                        .unix() <= op[dataIndex]
                  )
                  .map((op) => op.key)
              );

              // setSelectedKeys(e !== null ? [e.format('DD/MM/YYYY')] : [])
            }}
          />
          <DatePicker
            placeholder={'Fecha final'}
            format="DD/MM/YYYY"
            allowClear={false}
            value={
              endDate[dataIndex] !== '' ? moment.unix(endDate[dataIndex]) : null
            }
            onChange={(e) => {
              // setEndDate(moment(e).unix());
              endDate[dataIndex] = moment(e).endOf('day').unix();
              setSelectedKeys(
                payments
                  .filter(
                    (op) =>
                      op[dataIndex] <=
                      moment(e)
                        .hours(23)
                        .minutes(59)
                        .seconds(59)
                        .milliseconds(0)
                        .unix()
                  )
                  .map((op) => op.key)
              );
            }}
          />
        </Col>
        <Row justify="end" gutter={16}>
          <Col>
            <Button
            disabled={endDate[dataIndex] === ''}
              type="primary"
              onClick={() => {
                // console.log(selectedKeys, 'selectedKeys');
                handleSearchDate(selectedKeys, confirm);
                setSelectedKeys(
                  payments
                    .filter(
                      (d) =>
                        startDate[dataIndex] <= d[dataIndex] &&
                        d[dataIndex] <= endDate[dataIndex]
                    )
                    .map((d) => d.key)
                );
              }}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Buscar
            </Button>
            <Button
              onClick={() => {
                handleReset(clearFilters, selectedKeys, confirm, dataIndex);
                handleSearchDate(selectedKeys, confirm, dataIndex);
              }}
              size="small"
              style={{ width: 90 }}
            >
              Limpiar
            </Button>
          </Col>
        </Row>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      startDate[dataIndex] <= record[dataIndex] &&
      record[dataIndex] <= endDate[dataIndex],
    filteredValue: filteredInfo[dataIndex] || null,
    onFilterDropdownVisibleChange: (visible) => {},
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? moment.unix(text).format('DD/MM/YYYY') : ''}
        />
      ) : (
        moment.unix(text).format('DD/MM/YYYY')
      ),
  });
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            // this.searchInput = node;
          }}
          placeholder={`Buscar`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => {
              handleReset(clearFilters);
              handleSearch(selectedKeys, confirm, dataIndex);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Limpiar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    filteredValue: filteredInfo[dataIndex] || null,
    onFilterDropdownVisibleChange: (visible) => {},
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 60,
      align: 'center',
    },
    {
      title: 'Fecha de pago',
      dataIndex: 'paymentDate',
      key: 'paymentDate',
      width: 100,
      align: 'center',
      render: (paymentDate) => (
        <>{moment.unix(paymentDate).format('DD/MM/YYYY')}</>
      ),
      ...getColumnSearchDateProps('paymentDate'),
    },
    {
      title: 'Monto',
      dataIndex: 'total',
      key: 'total',
      width: 100,
      align: 'center',
      render: (total) => '$' + formatMoney(total),
    },
    {
      title: 'Usuario',
      dataIndex: 'createdBy',
      key: 'createdBy',
      width: 100,
      align: 'center',
      ...getColumnSearchProps('createdBy'),
    },
    {
      title: 'Ultima modificación',
      dataIndex: 'lastUpdatedAt',
      key: 'lastUpdatedAt',
      width: 100,
      align: 'center',
      render: (lastUpdatedAt) => (
        <>{moment.unix(lastUpdatedAt).format('DD/MM/YYYY')}</>
      ),
      ...getColumnSearchDateProps('lastUpdatedAt'),
    },
    {
      title: 'Estatus',
      dataIndex: 'cancelled',
      key: 'cancelled',
      width: 100,
      align: 'center',
      filters: [
        { text: 'Cancelado', value: true },
        { text: 'Activo', value: false },
      ],
      filteredValue: filteredInfo.cancelled || null,
      onFilter: (value, record) => record.cancelled === value,

      render: (cancelled) => (
        <Tag color={cancelled === true ? 'red' : 'green'}>
          {cancelled === true ? 'CANCELADO' : 'ACTIVO'}
        </Tag>
      ),
    },
    {
      title: 'Acción',
      key: 'action',
      width: 100,
      render: (text, record) => (
        <>
          <Space size="middle">
            {[ROL_FINANCIAL, ROL_ADMIN, ROL_LAYOUT].some((r) =>
              person.roles.includes(r)
            ) && (
              <>
                <Tooltip title="Editar">
                  <Button
                    disabled={record.cancelled === true}
                    onClick={() => showModal('Edit', record)}
                    type="primary"
                    icon={<EditOutlined />}
                  >
                  </Button>
                </Tooltip>
                <Tooltip title="Cancelar pago">
                  <Button
                    danger
                    type="primary"
                    icon={<DeleteOutlined />}
                    onClick={() => handleDeletePayment(record.id)}
                  >
                  </Button>
                </Tooltip>
              </>
            )}
            <Tooltip title="Ver">
              <Button
                onClick={() => showModal('Watch', record)}
                type="primary"
                icon={<EyeOutlined />}
                ghost
              >
              </Button>
            </Tooltip>
            <Tooltip title="Complemento de pago">
              <Button
                onClick={() => showModal('AddComplement', record)}
                type="primary"
                icon={<FileDoneOutlined />}
                ghost
              >
              </Button>
            </Tooltip>
          </Space>
        </>
      ),
      align: 'center',
    },
  ];
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleSearchDate = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };
  const handleReset = (clearFilters, selectedKeys, confirm, dataIndex) => {
    clearFilters();
    setSearchText('');
    startDate[dataIndex] = '';
    endDate[dataIndex] = '';
  };
  const handleDeletePayment = (id) => {
    confirm({
      title: 'Cancelar pago',
      icon: <ExclamationCircleOutlined />,
      content: '¿Estás seguro que deseas cancelar el pago seleccionado?',
      okText: 'Si',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deletePayment(id);
      },
      onCancel() {},
    });
  };
  const deletePayment = async (id) => {
    const config = {
      method: METHOD_DELETE,
      url:
        typeInvoice === 'ap' ? `${API_URL_PAYMENTS}/${id}` : `ARPayments/${id}`,
      data: null,
      token,
      showNotification: true,
    };
    const result = await fetchApi(config);

    if (result) {
      if (result.statusCode === 200) {
        setReloadPayments(true);
      }
    }
  };
  const handleChange = (pagination, filters, sorter, extra) => {
    const dateFilters = { startDates: startDate, endDates: endDate}
    saveFilteredDataToLocalStorage('payments', pagination, sorter, filters, dateFilters);
    setFilteredInfo(filters);
  };
  return (
    <div>
      {[ROL_FINANCIAL, ROL_ADMIN, ROL_LAYOUT].some((r) =>
        person.roles.includes(r)
      ) && (
        <Button
          className="btn-download"
          onClick={() => showModal('Add')}
          icon={<PlusCircleOutlined />}
        >
          Agregar
        </Button>
      )}
      <Button
        className="btn-download"
        icon={<ReloadOutlined />}
        onClick={(e) => setReloadPayments(true)}
      >
        Refrescar
      </Button>
      <Table
        // rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
        dataSource={payments}
        columns={columns}
        bordered
        size="middle"
        scroll={{ x: 1200 }}
        setSelectedRowKeys
        className="table-striped-rows"
        loading={reloadPayments}
        onChange={handleChange}
      />

      <Modal
        title={titleModal}
        visible={modalIsVisible}
        setIsVisible={setModalIsVisible}
        width={1000}
      >
        {contextModal}
      </Modal>
    </div>
  );
};

export default PaymentsTable;
