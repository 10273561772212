import React, { useEffect, useState } from 'react';
import { Button, Col, Input, Layout, Row, Typography } from 'antd';
import GreetingsTop from '../../../components/Admin/GreetingsTop/GreetingsTop';
import AccountBalance from '../../../components/PortalClients/AccountBalance';
import { getAccessTokenApi } from '../../../api/auth';
import { formatMoney } from '../../../utils/general';
import { METHOD_GET } from '../../../utils/constants';

import { API_URL_INVOICE } from '../../../helpers/urls';
import { fetchApi } from '../../../helpers/fetch';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export default function CustomerBalance() {
  const [balance, setBalance] = useState([]);
  const [total, setTotal] = useState(null);
  const token = getAccessTokenApi();
  const [loadingForm, setLoadingForm] = useState(null);

  useEffect(() => {
    setLoadingForm(true);
    const config = {
      method: METHOD_GET,
      url: `Customer/GetARInvoices/?UnpaidBalance=true`,
      data: null,
      token,
      showNotificationError: true,
    };
    fetchApi(config)
      .then((response) => {
        if (response.statusCode === 200) {
          const result = response.result.invoices;
          const totalBlance = response.result.totalBalance;
          if (result) {
            result.map((cat, index) => {
              cat['key'] = index;
            });
          }
          setBalance(result);
          setTotal(totalBlance);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoadingForm(false);
      });
  }, [token]);

  return (
    <Layout>
      <GreetingsTop />

      <HeaderTop total={total} />

      <div className="billing__container">
        <AccountBalance
          balance={balance}
          loadingForm={loadingForm}
          setTotal={setTotal}
          total={total}
        />
      </div>
    </Layout>
  );
}
function HeaderTop({ total, setClient }) {
  const { Title } = Typography;
  let history = useHistory();

  return (
    <div className="billing__container">
      <Row className="header-top">
        <Col
          xs={24}
          md={20}
          //   style={{ marginRight: 10 }}
          className="header-top__left"
        >
          <Row>
            <Title level={3}>Estado de cuenta</Title>
          </Row>
        </Col>
        <Col xs={24} md={4} className="container-fecha">
          <Row xs={24} md={12} style={{ marginBottom: 10 }}>
            <p style={{ marginRight: 5 }}>Saldo total:</p>
            <Input
              style={{ width: 218 }}
              disabled
              xs={24}
              value={total ? `$${formatMoney(total)}` : 0}
            ></Input>
          </Row>
        </Col>
        {/* <Col xs={1} style={{ marginRight: 40 }}>
          <Button type="link" onClick={() => history.goBack()}>
            <ArrowLeftOutlined /> Atrás
          </Button>
        </Col> */}
      </Row>
      {/* <Row justify="end">
        <Col xs={20} md={11} className="container-fecha">
          <Row xs={24} md={12} style={{ marginBottom: 10 }}>
            <p style={{ marginRight: 5 }}>Saldo total:</p>
            <Input
              style={{ width: 218 }}
              disabled
              xs={24}
              value={total ? `$${formatMoney(total)}` : 0}
            ></Input>
          </Row>
        </Col>
      </Row> */}
    </div>
  );
}
