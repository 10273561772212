import React, { useEffect, useState } from 'react'
import Upload from 'antd/lib/upload';
import { Button, Col, Divider, Form, Row, Spin, Typography, notification } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { basePath } from '../../../../api/config';
import { getAccessTokenApi } from '../../../../api/auth';
import { fetchApi } from '../../../../helpers/fetch';
import { METHOD_GET } from '../../../../utils/constants';

const SupplierTermsAndConditions = ({setModalVisible,supplierId,supplierList}) => {
  const { Text } = Typography;
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [data, setData] = useState([]);
  const [type, setType] = useState('add');
  const [encodeUrl, setEncodeUrl] = useState('');
  const [isLoadingForm, setLoadingForm] = useState(true);
  const [cambioFile, setCambioFile] = useState(false);
  const token = getAccessTokenApi();


  useEffect(() => {
    setLoadingForm(true);
    const config = {
      method: METHOD_GET,
      url: `supplier/${supplierId}/contract`,
      data: null,
      token,
    };
    fetchApi(config)
      .then((response) => {
        if (response) {
            if (response.statusCode === 200) {
                setType('edit')
                setData(response.result)
            }
        }
        setLoadingForm(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [token,supplierId]);

  useEffect(() => {
    if (data && type !== 'add') {
      form.setFieldsValue({
        file: {
          uid: 'tmp',
          name: data.name,
          status: 'done',
          url: data.fileLocation,
        },
      });
      setFileList([
        { uid: 'tmp', name: data.name, status: 'done', url: data.fileLocation },
      ]);
      var encodedUrl = encodeURIComponent(data.fileLocation);
      var iFrameUrl = 'https://docs.google.com/viewer?url=' + encodedUrl + '&embedded=true';
      setEncodeUrl(iFrameUrl)
    }
  }, [data, form, type]);

  const addFileFunction = async (values) => {
    if (type === 'add') {
      let url = basePath + `/supplier/${supplierId}/contract`;
      
      const formData = new FormData();
      let file = values.file.file.originFileObj;
      formData.append('contractFile', file);
      formData.append('supplierId', supplierId.toString());
      let fetchParams = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        method: 'POST',
        body: formData,
      };
      let httpResponse = await fetch(url, fetchParams);
      let jsonText = await httpResponse.text();

      let json = JSON.parse(jsonText);
      if (json.statusCode === 200) {
        notification.success({
          message: 'Archivo subido correctamente',
        });
        setModalVisible(false);
      }
    } else if (type === 'edit' && data) {
      let url = basePath + `/supplier/${supplierId}/contract`;
      const formData = new FormData();
      let file = values.file.file.originFileObj;
      formData.append('contractFile', file);
      formData.append('supplierId', supplierId.toString());
      let fetchParams= {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        method: 'PUT',
        body: formData,
      };
      let httpResponse = await fetch(url, fetchParams);
      let jsonText = await httpResponse.text();
      let json = JSON.parse(jsonText);
      if (json.statusCode === 200) {
        notification.success({
          message: 'Archivo editado correctamente',
        });
        setModalVisible(false);
      }
    } 
  };    
  const propsUpload = {
    showUploadList: {
        showDownloadIcon: false,
        showRemoveIcon: false,
    },
  };
  return (
    <Spin spinning={isLoadingForm}>
      <Form name="file" form={form} onFinish={addFileFunction}>
        {!supplierList && (
         <Divider />
        )}
        <Row style={{ display: 'flex', justifyContent: 'center' }}>
          <Col xs={20}>
            <Row style={{ marginBottom: 20 }} justify='end'>
              <Col xs={24} md={16} style={{ marginBottom: 20 }}>
                <Text style={{ fontSize: 18, fontWeight: 500 }}>
                  Contrato:
                </Text>
              </Col>
              <Col xs={24} md={8} style={{display: 'flex', justifyContent:'end'}}>
                <Form.Item
                  name="file"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor seleccione un archivo',
                    },
                  ]}
                >
                  <Upload
                    fileList={fileList}
                    {...propsUpload}
                    maxCount={1}
                    style={{ width: '100% !important' }}
                    customRequest={({ file, onSuccess, onError }) => {
                      if (onSuccess) {
                        onSuccess(file);
                        setFileList([
                          { uid: 'tmp', name: file.name, status: 'done', url: '' },
                        ]);
                        setCambioFile(true);
                      }
                    }}
                  >
                    <Button
                      style={{ width: 195, marginLeft: 0 }}
                      disabled={supplierList}
                      className="btn-download"
                      icon={<UploadOutlined />}
                    >
                      Subir archivo
                    </Button>
                  </Upload>
                </Form.Item>
              </Col>
              {(data.length === 0 && fileList.length === 0) && (
                  <p>No hay archivo disponible</p>
              )}
            </Row>
            {!supplierList && (
                <Row justify="end">
              <Form.Item>
                <Button
                  htmlType="submit"
                  className="btn-enviar"
                >
                  Guardar
                </Button>
              </Form.Item>
            </Row>
            )}
            
            <Divider/>
            {(data.length !== 0 && fileList.length !== 0 && cambioFile === false) && (
              <Row>
                <iframe src={encodeUrl}  style={{width:'100%', height:400 ,marginBottom: 20}}/>
              </Row>
            )}
            
          </Col>
        </Row>
      </Form>
    </Spin>
  )
}

export default SupplierTermsAndConditions