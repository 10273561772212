import React, { useEffect, useState } from 'react';
import { Table, Row, Col, Button, Tag, Input, Space, DatePicker } from 'antd';
import moment from 'moment';
import {
  FileExcelFilled,
  FilePdfFilled,
  SearchOutlined,
} from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { formatMoney } from '../../../utils/general';
import useUser from '../../../hooks/useUser';
import { METHOD_GET } from '../../../utils/constants';

import { getAccessTokenApi } from '../../../api/auth';
import { fetchApi } from '../../../helpers/fetch';
import { getFilteredDataFromLocalStorage, saveFilteredDataToLocalStorage } from '../../../helpers/saveFilterData';
export default function RentabilityTable() {
  const [rentabilityList, setRentabilityList] = useState([]);
  const [rentabilityExport, setRentabilityExport] = useState([]);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const { person } = useUser();
  const [startDate, setStartDate] = useState({
    arDate: '',
    paymentDate: '',
    apDate: '',
  });
  const [endDate, setEndDate] = useState({
    arDate: '',
    paymentDate: '',
    apDate: '',
  });
  const x = React.createRef();
  const [loadingForm, setLoadingForm] = useState(false);
  const token = getAccessTokenApi();

  useEffect(() => {
    setLoadingForm(true);
    const config = {
      method: METHOD_GET,
      url: `Finantial/GetRentability`,
      data: null,
      token,
      showNotificationError: true,
    };
    fetchApi(config)
      .then((response) => {
        if (response.statusCode === 200) {
          const result = response.result;
          if (result) {
            result.map((cat, index) => {
              cat['key'] = index;
            });
          }
          setRentabilityList(result);
          setRentabilityExport(result);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoadingForm(false);
      });
  }, [token]);
  useEffect(() => {
    const datosObtenidos = getFilteredDataFromLocalStorage('rentability');
    if (datosObtenidos !== null) {
      setFilteredInfo(datosObtenidos.filters);
      setStartDate(datosObtenidos.dateFilters.startDates)
      setEndDate(datosObtenidos.dateFilters.endDates)
    }
  },[])
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            // this.searchInput = node;
          }}
          placeholder={`Buscar`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => {
              handleReset(clearFilters);
              handleSearch(selectedKeys, confirm, dataIndex);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Limpiar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    filteredValue: filteredInfo[dataIndex] || null,
    onFilterDropdownVisibleChange: (visible) => {},
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  const getColumnSearchDateProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Col>
          <DatePicker
            ref={x}
            id={'startDate' + dataIndex}
            placeholder={'Fecha inicial'}
            style={{ marginRight: '10px' }}
            format="DD/MM/YYYY"
            allowClear={false}
            value={
              startDate[dataIndex] !== ''
                ? moment.unix(startDate[dataIndex])
                : null
            }
            onChange={(e) => {
              // setStartDate(moment(e).unix());
              startDate[dataIndex] = moment(e).startOf('day').unix();
              setSelectedKeys(
                rentabilityList
                  .filter(
                    (op) =>
                      moment(e)
                        .hours(0)
                        .minutes(0)
                        .seconds(0)
                        .milliseconds(0)
                        .unix() <= op[dataIndex]
                  )
                  .map((op) => op.key)
              );

              // setSelectedKeys(e !== null ? [e.format('DD/MM/YYYY')] : [])
            }}
          />
          <DatePicker
            placeholder={'Fecha final'}
            format="DD/MM/YYYY"
            allowClear={false}
            value={
              endDate[dataIndex] !== '' ? moment.unix(endDate[dataIndex]) : null
            }
            onChange={(e) => {
              // setEndDate(moment(e).unix());
              endDate[dataIndex] = moment(e).endOf('day').unix();
              setSelectedKeys(
                rentabilityList
                  .filter(
                    (op) =>
                      op[dataIndex] <=
                      moment(e)
                        .hours(23)
                        .minutes(59)
                        .seconds(59)
                        .milliseconds(0)
                        .unix()
                  )
                  .map((op) => op.key)
              );
            }}
          />
        </Col>
        <Row justify="end" gutter={16}>
          <Col>
            <Button
            disabled={endDate[dataIndex] === ''}
              type="primary"
              onClick={() => {
                handleSearchDate(selectedKeys, confirm);
                setSelectedKeys(
                  rentabilityList
                    .filter(
                      (d) =>
                        startDate[dataIndex] <= d[dataIndex] &&
                        d[dataIndex] <= endDate[dataIndex]
                    )
                    .map((d) => d.key)
                );
              }}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Buscar
            </Button>
            <Button
              onClick={() => {
                handleReset(clearFilters, selectedKeys, confirm, dataIndex);
                handleSearchDate(selectedKeys, confirm, dataIndex);
              }}
              size="small"
              style={{ width: 90 }}
            >
              Limpiar
            </Button>
          </Col>
        </Row>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      startDate[dataIndex] <= record[dataIndex] &&
      record[dataIndex] <= endDate[dataIndex],
    filteredValue: filteredInfo[dataIndex] || null,
    onFilterDropdownVisibleChange: (visible) => {},
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? moment.unix(text).format('DD/MM/YYYY') : ''}
        />
      ) : text !== 0 && text !== null ? (
        moment.unix(text).format('DD/MM/YYYY')
      ) : null,
  });
  const columns = [
    {
      title: 'No. factura',
      dataIndex: 'number',
      key: 'number',
      render: (number) => <p>{number}</p>,
      ...getColumnSearchProps('number'),
      ellipsis: true,
      width: 100,
    },
    {
      title: 'Fecha',
      dataIndex: 'arDate',
      key: 'arDate',
      ellipsis: true,
      ...getColumnSearchDateProps('arDate'),
      width: 150,
    },
    {
      title: 'Folio',
      dataIndex: 'arFolio',
      key: 'arFolio',
      ...getColumnSearchProps('arFolio'),
      width: 200,
    },

    {
      title: 'Cliente',
      dataIndex: 'customerName',
      key: 'customerName',
      ...getColumnSearchProps('customerName'),
      width: 150,
    },
    {
      title: 'Concepto',
      dataIndex: 'concept',
      key: 'concept',
      width: 300,
    },

    {
      title: 'Subtotal',
      dataIndex: 'arSubtotal',
      key: 'arSubtotal',
      ...getColumnSearchProps('arSubtotal'),
      render: (arSubtotal) => `$ ${formatMoney(arSubtotal)}`,
      ellipsis: true,
      width: 120,
    },
    {
      title: 'IVA',
      dataIndex: 'artaxAmount',
      key: 'artaxAmount',
      ...getColumnSearchProps('artaxAmount'),
      render: (artaxAmount) => `$ ${formatMoney(artaxAmount)}`,
      ellipsis: true,
      width: 120,
    },
    {
      title: 'Total',
      dataIndex: 'arTotal',
      key: 'arTotal',
      ...getColumnSearchProps('arTotal'),
      render: (arTotal) => `$ ${formatMoney(arTotal)}`,
      ellipsis: true,
      width: 120,
    },
    {
      title: 'Estatus',
      dataIndex: 'arStatus',
      key: 'arStatus',
      render: (status) => <Tag color={'green'}>{status.toUpperCase()}</Tag>,
      ellipsis: true,
      width: 100,
    },

    {
      title: 'Fecha de pago',
      dataIndex: 'paymentDate',
      key: 'paymentDate',
      ellipsis: true,
      ...getColumnSearchDateProps('paymentDate'),
      width: 150,
    },
    {
      title: 'Proveedor',
      dataIndex: 'supplierName',
      key: 'supplierName',
      render: (supplierName) => <p>{supplierName}</p>,
      ...getColumnSearchProps('supplierName'),
      width: 100,
    },
    {
      title: 'Folio factura',
      dataIndex: 'apFolio',
      key: 'apFolio',
      render: (apFolio) => <p>{apFolio}</p>,
      ...getColumnSearchProps('apFolio'),
      width: 100,
    },
    {
      title: 'Fecha',
      dataIndex: 'apDate',
      key: 'apDate',
      ellipsis: true,
      ...getColumnSearchDateProps('apDate'),
      width: 150,
    },
    {
      title: 'Subtotal',
      dataIndex: 'apSubtotal',
      key: 'apSubtotal',
      ...getColumnSearchProps('apSubtotal'),
      render: (apSubtotal) => `$ ${formatMoney(apSubtotal)}`,
      ellipsis: true,
      width: 120,
    },
    {
      title: 'IVA',
      dataIndex: 'aptaxAmount',
      key: 'aptaxAmount',
      ...getColumnSearchProps('aptaxAmount'),
      render: (aptaxAmount) => `$ ${formatMoney(aptaxAmount)}`,
      ellipsis: true,
      width: 120,
    },
    {
      title: 'Total',
      dataIndex: 'apTotal',
      key: 'apTotal',
      ...getColumnSearchProps('apTotal'),
      render: (apTotal) => `$ ${formatMoney(apTotal)}`,
      ellipsis: true,
      width: 120,
    },
    {
      title: 'Estatus',
      dataIndex: 'apStatus',
      key: 'apStatus',
      render: (status) => <Tag color={'green'}>{status.toUpperCase()}</Tag>,
      ellipsis: true,
      width: 100,
    },
    {
      title: 'Utilidad',
      dataIndex: 'utility',
      key: 'utility',
      ...getColumnSearchProps('utility'),
      render: (utility) => `$ ${formatMoney(utility)}`,
      ellipsis: true,
      width: 120,
    },
  ];
  const handleSearchDate = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const handleChange = (pagination, filters, sorter, extra) => {
    const dateFilters = { startDates: startDate, endDates: endDate}
    saveFilteredDataToLocalStorage('rentability', pagination, sorter, filters, dateFilters);
    setFilteredInfo(filters);
    setRentabilityExport(extra.currentDataSource);
  };
  const generateExcel = (entries) => {
    const filename = `Reporte rentabilidad ${moment().format('L')}.xlsx`;
    let fecha = moment().format('LLL');
    const ws_name = 'Hoja1';
    let wb = {
      SheetNames: [],
      Sheets: {},
    };
    let ws = {};
    let renInicial = 4;
    var mstrTitulo = `Reporte rentabilidad`;
    ws = {
      A1: { v: mstrTitulo, t: 's' },
      G1: { v: fecha, t: 's' },

      A3: { v: 'No. factura', t: 's' },
      B3: { v: 'Fecha', t: 's' },
      C3: { v: 'Folio', t: 's' },
      D3: { v: 'Cliente', t: 's' },
      E3: { v: 'Concepto', t: 's' },
      F3: { v: 'Subtotal', t: 's' },
      G3: { v: 'IVA', t: 's' },
      H3: { v: 'Total', t: 's' },
      I3: { v: 'Estatus', t: 's' },
      J3: { v: 'Fecha de pago', t: 's' },
      K3: { v: 'Proveedor', t: 's' },
      L3: { v: 'Folio factura', t: 's' },
      M3: { v: 'Fecha', t: 's' },
      N3: { v: 'Subtotal', t: 's' },
      O3: { v: 'IVA', t: 's' },
      P3: { v: 'Total', t: 's' },
      Q3: { v: 'Estatus', t: 's' },
      R3: { v: 'Utilidad', t: 's' },
    };

    for (let i in entries) {
      const {
        number,
        arDate,
        arFolio,
        customerName,
        concept,
        arSubtotal,
        artaxAmount,
        arTotal,
        arStatus,
        paymentDate,
        supplierName,
        apFolio,
        apDate,
        apSubtotal,
        aptaxAmount,
        apTotal,
        apStatus,
        utility,
      } = entries[i];
      ws['A' + renInicial] = { v: number, t: 's' };
      ws['B' + renInicial] = {
        v: arDate ? moment.unix(arDate).format('DD/MM/YYYY') : '',
        t: 's',
      };
      ws['C' + renInicial] = { v: arFolio ? arFolio : '', t: 's' };
      ws['D' + renInicial] = { v: customerName ? customerName : '', t: 's' };
      ws['E' + renInicial] = { v: concept ? concept : '', t: 's' };
      ws['F' + renInicial] = { v: `$ ${formatMoney(arSubtotal)}`, t: 's' };
      ws['G' + renInicial] = { v: `$ ${formatMoney(arTotal)}`, t: 's' };
      ws['H' + renInicial] = { v: `$ ${formatMoney(artaxAmount)}`, t: 's' };
      ws['I' + renInicial] = { v: arStatus, t: 's' };
      ws['J' + renInicial] = {
        v: paymentDate ? moment.unix(paymentDate).format('DD/MM/YYYY') : '',
        t: 's',
      };
      ws['K' + renInicial] = { v: supplierName, t: 's' };
      ws['L' + renInicial] = { v: apFolio, t: 's' };
      ws['M' + renInicial] = {
        v: moment.unix(apDate).format('DD/MM/YYYY'),
        t: 's',
      };
      ws['N' + renInicial] = { v: `$ ${formatMoney(apSubtotal)}`, t: 's' };
      ws['O' + renInicial] = { v: `$ ${formatMoney(apTotal)}`, t: 's' };
      ws['P' + renInicial] = { v: `$ ${formatMoney(aptaxAmount)}`, t: 's' };
      ws['Q' + renInicial] = { v: apStatus, t: 's' };
      ws['R' + renInicial] = { v: `$ ${formatMoney(utility)}`, t: 's' };

      renInicial++;
    }

    // var wscols = [{ wch: 30 }, { wch: 40 }, { wch: 35 }, { wch: 35 }];
    // ws['!cols'] = wscols;
    ws['!ref'] = XLSX.utils.encode_range({
      s: { c: 0, r: 0 },
      e: { c: 15, r: renInicial },
    });
    wb.SheetNames.push(ws_name);
    wb.Sheets[ws_name] = ws;
    XLSX.writeFile(wb, filename, { cellStyles: true });
  };
  const exportPdf = (bills, startDate, endDate) => {
    var mstrTitulo = `Reporte rentabilidad`;
    // var mstrTitulo = `CORTE DE CAJA - Reporte de Ventas del: ${moment
    //     .unix(startDate)
    //     .format('LLL')} al: ${moment.unix(endDate).format('LLL')}`;
    var mstrPdf = `Reporte rentabilidad ${moment().format('L')}.pdf`;

    var registros = [];
    bills.forEach((element) => {
      registros.push([
        element.number,
        moment.unix(element.arDate).format('DD/MM/YYYY'),
        element.arFolio,
        element.customerName,
        element.concept,
        '$ ' + formatMoney(element.arSubtotal),
        '$ ' + formatMoney(element.artaxAmount),
        '$ ' + formatMoney(element.arTotal),
        element.arStatus,
        moment.unix(element.paymentDate).format('DD/MM/YYYY'),
        element.supplierName,
        element.apFolio,
        moment.unix(element.apDate).format('DD/MM/YYYY'),
        '$ ' + formatMoney(element.apSubtotal),
        '$ ' + formatMoney(element.aptaxAmount),
        '$ ' + formatMoney(element.apTotal),
        element.apStatus,
        '$ ' + formatMoney(element.utility),
      ]);
    });

    let fecha = moment().format('LLL');

    let doc = new jsPDF('landscape');
    doc.setFontSize(12);

    let xFecha = 220;
    let yFecha = 10;

    let totalPagesExp = '{total_pages_count_string}';

    let pageContent = function (data) {
      //header
      doc.text(15, 10, mstrTitulo);
      doc.text(-10, -10, mstrTitulo);
      doc.text(fecha, xFecha, yFecha);
      // FOOTER
      let str = 'Página ' + data.pageCount;
      //Total page number plugin only available in jspdf v1.0+
      if (typeof doc.putTotalPages === 'function') {
        str = str + ' de ' + totalPagesExp;
      }
      doc.setFontSize(10);
      doc.text(
        str,
        data.settings.margin.left,
        doc.internal.pageSize.height - 10
      );
    };
    doc.autoTable({
      head: [
        [
          'No. factura',
          'Fecha',
          'Folio',
          'Cliente',
          'Concepto',
          'Subtotal',
          'IVA',
          'Total',
          'Estatus',
          'Fecha de pago',
          'Proveedor',
          'Folio factura',
          'Fecha',
          'Subtotal',
          'IVA',
          'Total',
          'Estatus',
          'Utilidad',
        ],
      ],
      didDrawPage: pageContent,
      theme: 'grid',
      headStyles: {
        valign: 'middle',
        halign: 'center',
        fillColor: [230, 81, 0],
        textColor: [255, 255, 255],
      },
      body: registros,
      columnStyles: {
        2: { halign: 'center', cellWidth: 22, minCellWidth: 10 },
        4: { halign: 'center', cellWidth: 22, minCellWidth: 10 },
        10: { halign: 'center', cellWidth: 22, minCellWidth: 10 },
        text: {
          cellWidth: 'wrap',
        },
      },
    });

    // call footer() after each doc.addPage()

    // and before doc.save() do not forget put
    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(totalPagesExp);
    }
    //spinner.style.display = 'none';
    doc.save(mstrPdf);
  };

  return (
    <>
      <Table
        onChange={handleChange}
        columns={columns}
        dataSource={rentabilityList}
        scroll={{ x: 1200 }}
        loading={loadingForm}
      />

      <Row>
        <Col span={24} style={{ textAlign: 'right' }}>
          <Button
            icon={<FilePdfFilled style={{ fontSize: 18 }} />}
            className="btn-download"
            onClick={() => exportPdf(rentabilityExport)}
            disabled={rentabilityExport.length === 0}
            style={{ width: 150 }}
          >
            Descargar Pdf
          </Button>
          <Button
            icon={<FileExcelFilled style={{ fontSize: 18 }} />}
            className="btn-download"
            onClick={() => generateExcel(rentabilityExport)}
            disabled={rentabilityExport.length === 0}
            style={{ width: 160 }}
          >
            Descargar Excel
          </Button>
        </Col>
      </Row>
    </>
  );
}
