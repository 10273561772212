import React, { useEffect, useState } from 'react';
import {
    Button,
    Dropdown,
    Menu,
    Avatar,
    Badge,
    Image,
    notification,
    Row,
    Col,
} from 'antd';
import {
    PoweroffOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    DownOutlined,
    UserOutlined,
    NotificationFilled,
    CloseOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { getAccessTokenApi, logout } from '../../../api/auth';
import logoGPMET from '../../../assets/img/png/logoGPMET.png';
import logoGPMETPNG from '../../../assets/img/png/logoGPMETPNG.png';
import useUser from '../../../hooks/useUser';
import { logOutError } from '../../../utils/feedbackMessages';

import './MenuTop.scss';
import { getUserAlertsApi } from '../../../api/alerts';
import { METHOD_DELETE, METHOD_PUT, ROL_ADMIN, ROL_FINANCIAL, ROL_LAYOUT, ROL_MANAGER, ROL_OPERACIONES, ROL_PRICING, ROL_SUPPLIER, ROL_VENTAS } from '../../../utils/constants';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { fetchApi } from '../../../helpers/fetch';
import { saveFilteredDataToLocalStorage } from '../../../helpers/saveFilterData';

const MenuTop = (props) => {
    const { menuCollapsed, setMenuCollapsed } = props;
    const { person } = useUser();
    const [profilePicture, setProfilePicture] = useState(null);
    const token = getAccessTokenApi();
    const [alerts, setAlerts] = useState([]);
    const [numberAlerts, setNumberAlerts] = useState([]);
    // const [reloadAlerts, setReloadAlerts] = useState(true);
    const [primerGet, setPrimerGet] = useState(true);
    const history = useHistory();
   
    useEffect(() => {
        if (person) {
            const data = person.profilePicture;
            if (data !== '') {
                const imageProfile = `data:image/jpeg;base64, ${data}`;
                setProfilePicture(imageProfile);
            }
        } else {
            logOutError();
        }
    }, [person]);
    useEffect(() => {
        if (primerGet && person.idUser !== '' && token) {
            setPrimerGet(false)
            getUserAlertsApi(token,person.idUser).then((response) => {
                if (response?.statusCode === 401) {
                    notification['error']({
                        message: 'Usuario no autorizado.',
                    });
                    setTimeout(() => {
                        logout();
                        window.location.reload();
                    }, 1000);
                    return;
                } else if (response?.statusCode === 200) {
                    let numeroDeAlertas = 0;
                    response.result.forEach((element, index) => {
                        element['key'] = index;
                        if (element.isSeen === false) {
                            numeroDeAlertas++;
                        }
                    });
                    setAlerts(response.result);
                    setNumberAlerts(numeroDeAlertas);
                }
            });
        }
    }, [person,primerGet,token]);
    const menu = (
        <Menu>
            <Menu.Item key="pictureUser">
                <Image
                    src={profilePicture ? profilePicture : logoGPMET}
                    style={{ width: 150 }}
                />
            </Menu.Item>
            <Menu.Item key="/perfil">
                <Link to="/perfil">
                    <UserOutlined />
                    <span className="nav-text">Perfil</span>
                </Link>
            </Menu.Item>
            <Menu.Item
                key="nameUser"
                danger
                icon={<PoweroffOutlined />}
                onClick={() => logoutUser()}
            >
                Salir
            </Menu.Item>
        </Menu>
    );
    function handleMenuClick(e) {}
    function cambiarFormatoFecha(fechaOriginal) {
        var partesFecha = fechaOriginal.split('-');
        var mes = partesFecha[0];
        var año = partesFecha[1];
        var nuevaFecha = año + '-' + mes;
        return nuevaFecha;
      }
    const handleItemClick = (item) => {
        console.log(item,'item');

        const startDate = {
            createdAt: '',
            startDate: '',
            endDate: '',
          };
          const endDate ={
            createdAt: '',
            startDate: '',
            endDate: '',
          };
          const dateFilters = { startDates: startDate, endDates: endDate}

        if (item.isSeen === false) {
            showNotifications(item.id)
        }

        if (item.alertType === 0 ) {
            saveFilteredDataToLocalStorage('quote', {}, {}, {quoteIdStr:[item.quoteIdStr]}, dateFilters);
            history.push(`/analisis/cotizacion`) 
        }else if (item.alertType === 1) {
            var nuevaFecha = cambiarFormatoFecha(item.date);
            if (item.role === 'cliente') {
             history.push(`/analisis/ascenso-descenso/clientes/${nuevaFecha}`) 
            }else if(item.role === 'proveedor'){
             history.push(`/analisis/ascenso-descenso/proveedores/${nuevaFecha}`) 
            }
        }else if (item.alertType === 2) {
            // var nuevaFecha = cambiarFormatoFecha(item.date);
             history.push(`/cxc/operaciones/clientes`) 
        }

      };
    
      const handleDeleteItemClick = async (alert) => {
        const config = {
          method: METHOD_DELETE,
          url: `useralerts/${alert.id}`,
          token,
          showNotification: true,
        };
        const result = await fetchApi(config);
        if (result) {
          if (result.statusCode === 200) {
            getUserAlertsApi(token,person.idUser).then((response) => {
                if (response?.statusCode === 401) {
                    notification['error']({
                        message: 'Usuario no autorizado.',
                    });
                    setTimeout(() => {
                        logout();
                        window.location.reload();
                    }, 1000);
                    return;
                } else if (response?.statusCode === 200) {
                    let numeroDeAlertas = 0;
                    response.result.forEach((element, index) => {
                        element['key'] = index;
                        if (element.isSeen === false) {
                            numeroDeAlertas++;
                        }
                    });
                    setAlerts(response.result);
                    setNumberAlerts(numeroDeAlertas);
                }
            });
          }
        }
      };

   
    const logoutUser = () => {
        logout();
        window.location.reload();
    };
    const showNotifications = async (id) => {
        const data = {
            AlertId: id,
            IsSeen: true
          };
          const config = {
            method: METHOD_PUT,
            url: `UserAlerts/${id}`,
            data: data,
            token,
            showNotification: false,
          };
          const result = await fetchApi(config);
          if (result) {
            if (result.statusCode === 200) {
                setNumberAlerts(numberAlerts -1);
                getUserAlertsApi(token,person.idUser).then((response) => {
                    if (response?.statusCode === 401) {
                        notification['error']({
                            message: 'Usuario no autorizado.',
                        });
                        setTimeout(() => {
                            logout();
                            window.location.reload();
                        }, 1000);
                        return;
                    } else if (response?.statusCode === 200) {
                        let numeroDeAlertas = 0;
                        response.result.forEach((element, index) => {
                            element['key'] = index;
                            if (element.isSeen === false) {
                                numeroDeAlertas++;
                            }
                        });
                        setAlerts(response.result);
                        setNumberAlerts(numeroDeAlertas);
                    }
                });
            }
          }
    };
    return (
        <div className="menu-top">
            <div className="menu-top__left">
                <img
                    src={logoGPMETPNG}
                    alt="logoGPMETPNG"
                    style={{ width: 60, marginLeft: 20 }}
                />
                <Button
                    icon={
                        menuCollapsed ? (
                            <MenuUnfoldOutlined />
                        ) : (
                            <MenuFoldOutlined />
                        )
                    }
                    type="link"
                    size="large"
                    onClick={() => setMenuCollapsed(!menuCollapsed)}
                ></Button>
            </div>

            <div className="menu-top__right">
                {[ROL_FINANCIAL, ROL_ADMIN,ROL_LAYOUT,ROL_MANAGER,ROL_OPERACIONES,ROL_PRICING,ROL_VENTAS].some((r) =>
                    person?.roles?.includes(r)
                ) && (
                    <Dropdown
                        trigger={['click']}
                        overlay={<Menu
                            onClick={handleMenuClick}
                            className={alerts.length > 5 && 'active-scroll'}
                        >
                            {alerts.length === 0 && (
                                <Menu.Item>No hay alertas activas</Menu.Item>
                            )}
                            {alerts.map((alert, index) => (
                                <Menu.Item
                                
                                    key={index}
                                    icon={<p style={{ color: alert.pinColor }}>&#9679;</p>
                                    }
                                >
                                    <Row>
                                        <Col xs={20} onClick={() => handleItemClick(alert)}>
                                        <span>
                                        {alert.description}
                                    </span> 
                
                                        </Col>
                                        <Col xs={4}>
                                            <Row justify='end'>
                                                <Button type="link" icon={<CloseOutlined style={{height:24, color:"gray", fontSize:14}}/>} danger onClick={()=>handleDeleteItemClick(alert)}/>
                                            </Row>
                                        </Col>
                                    </Row>
                                    
                                </Menu.Item>
                            ))}
                        </Menu>}
                        // onClick={() => getNotifications()}
                        style={{ marginRight: 30 }}
                    >
                        <Badge count={numberAlerts} style={{ marginRight: 20 }}>
                            <NotificationFilled
                                style={{
                                    fontSize: 20,
                                    color: 'white',
                                    marginRight: 30,
                                }}
                            />
                        </Badge>
                    </Dropdown>
                )}

                <Dropdown overlay={menu}>
                    <a
                        href="javascript: void(0)"
                        className="ant-dropdown-link"
                        // onClick={(e) => e.preventDefault()
                        // }
                    >
                        <Avatar
                            src={profilePicture ? profilePicture : logoGPMET}
                            style={{ marginRight: 5 }}
                        />{' '}
                        {person.shortName}
                        <DownOutlined />
                    </a>
                </Dropdown>
            </div>
        </div>
    );
};

export default MenuTop;
