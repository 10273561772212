import { basePath } from './config';

export function getAllClients(token) {
   const url = `${basePath}/Customer/GetCustomers`;

   const params = {
      method: 'POST',
      headers: {
         Authorization: `Bearer ${token}`,
         'Content-Type': 'application/json',
         Accept: '*/*',
      },
   };

   return fetch(url, params)
      .then((response) => {
         return response.json();
      })
      .then((result) => {
         return result;
      })
      .catch((err) => {});
}

export function getAllCustomersApi(token) {
   const url = `${basePath}/Customer`;

   const params = {
      method: 'GET',
      headers: {
         Authorization: `Bearer ${token}`,
         'Content-Type': 'application/json',
         Accept: '*/*',
      },
   };

   return fetch(url, params)
      .then((response) => {
         return response.json();
      })
      .then((result) => {
         return result;
      })
      .catch((err) => {});
}

export function getClientApi(token, id) {
   const url = `${basePath}/Customer/GetCustomer?id=${id}`;

   const params = {
      method: 'GET',
      headers: {
         Authorization: `Bearer ${token}`,
         'Content-Type': 'application/json',
         Accept: '*/*',
      },
   };

   return fetch(url, params)
      .then((response) => {
         return response.json();
      })
      .then((result) => {
         return result;
      })
      .catch((err) => {});
}
export function registerClientApi(token, data) {
   const url = `${basePath}/Customer`;
   const params = {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
         Authorization: `Bearer ${token}`,
         'Content-Type': 'application/json',
      },
   };

   return fetch(url, params)
      .then((response) => {
         return response.json();
      })
      .then((result) => {
         return result;
      })
      .catch((err) => {});
}
export function editClientApi(token, data) {
   const url = `${basePath}/Customer/${data.id}`;
   const params = {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: {
         Authorization: `Bearer ${token}`,
         'Content-Type': 'application/json',
      },
   };

   return fetch(url, params)
      .then((response) => {
         return response.json();
      })
      .then((result) => {
         return result;
      })
      .catch((err) => {});
}
export function deleteClientApi(token, id) {
   const url = `${basePath}/Customer/${id}`;
   const params = {
      method: 'DELETE',
      headers: {
         Authorization: `Bearer ${token}`,
         'Content-Type': 'application/json',
      },
   };

   return fetch(url, params)
      .then((response) => {
         return response.json();
      })
      .then((result) => {
         return result;
      })
      .catch((err) => {});
}
