import React, { useState } from 'react';
import {
    Form,
    Input,
    Button,
    Spin,
    notification,
    Row,
    Col,
    Divider,
    Typography,
} from 'antd';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { useHistory } from 'react-router';
import { resetPasswordApi } from '../../../api/user';
import {
    validatePassword,
    validateTextHasCapitalize,
    validateTextHasLowercase,
    validateTextHasNumber,
    validateTextHasCharacter,
} from '../../../utils/formValidation';

import './ResetPasswordForm.scss';

const ResetPasswordForm = (props) => {
    const { user } = props;
    const { Text } = Typography;
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [hasUpperCase, setHasUpperCase] = useState(false);
    const [hasLowerCase, setHasLowerCase] = useState(false);
    const [hasNumber, setHasNumber] = useState(false);
    const [hasCharacter, setHasCharacter] = useState(false);
    const [lenghtCorrect, setLenghtCorrect] = useState(false);
    const [lenghtMaxCorrect, setLenghtMaxCorrect] = useState(true);

    const onFinish = async (values) => {
        setLoading(true);
        let data = values;
        data['email'] = user.email;
        data['token'] = user.token;

        const result = await resetPasswordApi(data);

        if (result === undefined) {
            setLoading(false);

            notification['error']({
                message: 'Ocurrió un error, Inténtelo más tarde',
            });

            setTimeout(() => {
                window.location.href = '/';
            }, 3000);

            return false;
        }

        if (result.statusCode === 200) {
            setLoading(false);

            notification['success']({
                message: 'Operación exitosa',
            });

            setTimeout(() => {
                window.location.href = '/';
            }, 3000);
        } else {
            let messageError = 'Ocurrió un error, Inténtelo otra vez';

            if (result.statusCode === 409) {
                messageError = 'El enlace ha expirado.';
            }

            if (result.statusCode === 400) {
                messageError = result.description;
            }

            setLoading(false);

            notification['error']({
                message: messageError,
            });
        }
    };

    const checkPassword = (text) => {
        if (validateTextHasCapitalize(text)) {
            setHasUpperCase(true);
        } else {
            setHasUpperCase(false);
        }

        if (validateTextHasLowercase(text)) {
            setHasLowerCase(true);
        } else {
            setHasLowerCase(false);
        }

        if (validateTextHasNumber(text)) {
            setHasNumber(true);
        } else {
            setHasNumber(false);
        }

        if (validateTextHasCharacter(text)) {
            setHasCharacter(true);
        } else {
            setHasCharacter(false);
        }

        if (text.length >= 6) {
            setLenghtCorrect(true);
        } else {
            setLenghtCorrect(false);
        }
        if (text.length <= 10) {
            setLenghtMaxCorrect(true);
        } else {
            setLenghtMaxCorrect(false);
        }
    };

    return (
        <Spin tip="Cargando..." spinning={loading}>
            <Form
                // {...layout}
                onFinish={onFinish}
                className="login-form"
                layout="vertical"
            >
                <Form.Item
                    name="password"
                    label="Contraseña nueva"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese una contraseña',
                        },
                        () => ({
                            validator(rule, value) {
                                if (
                                    validatePassword(value) &&
                                    lenghtCorrect &&
                                    lenghtMaxCorrect
                                ) {
                                    return Promise.resolve();
                                } else if (!validatePassword(value)) {
                                    return Promise.reject(
                                        'La contrase\u00F1a nueva debe de tener  mínimo 6 o máximo 10 caracteres, por lo menos una may\u00FAscula, una minúscula, un número  y un car\u00E1cter especial'
                                    );
                                }
                            },
                        }),
                    ]}
                    hasFeedback
                >
                    <Input.Password
                        onChange={(e) => checkPassword(e.target.value)}
                    />
                </Form.Item>

                <div className="validate-password">
                    <Row>
                        <Col xs={24}>
                            <Divider>La contraseña debe incluir:</Divider>
                            <Row>
                                {hasUpperCase && (
                                    <Text type="success">
                                        <CheckCircleFilled
                                            style={{ marginRight: 8 }}
                                        />
                                        Una mayúscula{' '}
                                    </Text>
                                )}
                            </Row>
                            <Row>
                                {!hasUpperCase && (
                                    <Text type="danger">
                                        <CloseCircleFilled
                                            style={{ marginRight: 8 }}
                                        />
                                        Una mayúscula{' '}
                                    </Text>
                                )}
                            </Row>
                            <Row>
                                {hasLowerCase && (
                                    <Text type="success">
                                        <CheckCircleFilled
                                            style={{ marginRight: 8 }}
                                        />
                                        Una minúscula{' '}
                                    </Text>
                                )}
                            </Row>
                            <Row>
                                {!hasLowerCase && (
                                    <Text type="danger">
                                        <CloseCircleFilled
                                            style={{ marginRight: 8 }}
                                        />
                                        Una minúscula{' '}
                                    </Text>
                                )}
                            </Row>
                            <Row>
                                {hasNumber && (
                                    <Text type="success">
                                        <CheckCircleFilled
                                            style={{ marginRight: 8 }}
                                        />
                                        Un número{' '}
                                    </Text>
                                )}
                            </Row>
                            <Row>
                                {!hasNumber && (
                                    <Text type="danger">
                                        <CloseCircleFilled
                                            style={{ marginRight: 8 }}
                                        />
                                        Un número{' '}
                                    </Text>
                                )}
                            </Row>
                            <Row>
                                {hasCharacter && (
                                    <Text type="success">
                                        <CheckCircleFilled
                                            style={{ marginRight: 8 }}
                                        />
                                        Un carácter:{' '}
                                        {'?()!@#$%^&*[]"\';:_-<>. =+/ '}{' '}
                                    </Text>
                                )}
                            </Row>
                            <Row>
                                {!hasCharacter && (
                                    <Text type="danger">
                                        <CloseCircleFilled
                                            style={{ marginRight: 8 }}
                                        />
                                        Un carácter:{' '}
                                        {'?()!@#$%^&*[]"\';:_-<>. =+/'}
                                    </Text>
                                )}
                            </Row>
                            <Row>
                                {lenghtCorrect && (
                                    <Text type="success">
                                        <CheckCircleFilled
                                            style={{ marginRight: 8 }}
                                        />
                                        Tiene 6 o más caracteres{' '}
                                    </Text>
                                )}
                            </Row>
                            <Row>
                                {!lenghtCorrect && (
                                    <Text type="danger">
                                        <CloseCircleFilled
                                            style={{ marginRight: 8 }}
                                        />
                                        Tiene 6 o más caracteres{' '}
                                    </Text>
                                )}
                            </Row>

                            <Row>
                                {!lenghtMaxCorrect && (
                                    <Text type="danger">
                                        <CloseCircleFilled
                                            style={{ marginRight: 8 }}
                                        />
                                        Máximo 10 caracteres{' '}
                                    </Text>
                                )}
                            </Row>
                        </Col>
                    </Row>
                </div>

                <Form.Item
                    name="confirmPassword"
                    label="Confirmar contraseña"
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese la contraseña anterior',
                        },
                        ({ getFieldValue }) => ({
                            validator(rule, value) {
                                if (
                                    !value ||
                                    getFieldValue('password') === value
                                ) {
                                    return Promise.resolve();
                                }

                                return Promise.reject(
                                    'Las dos contraseñas ingresadas no son iguales'
                                );
                            },
                        }),
                    ]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item>
                    <Button
                        danger
                        type="primary"
                        htmlType="submit"
                        size="large"
                        shape="round"
                        className="login-form-login"
                    >
                        ENVIAR
                    </Button>
                </Form.Item>
                <Form.Item>
                    <Button
                        danger
                        type="primary"
                        htmlType="submit"
                        size="large"
                        shape="round"
                        className="login-form-regresar"
                        onClick={() => history.push('/login')}
                    >
                        REGRESAR
                    </Button>
                </Form.Item>

                {/* <Form.Item
                    {...tailLayout}
                    className='reset-password-form__button'
                >
                    <Button type='primary' htmlType='submit'>
                        Enviar
                    </Button>
                </Form.Item> */}
            </Form>
        </Spin>
    );
};

export default ResetPasswordForm;
