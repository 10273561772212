import {
  Button,
  Col,
  Form,
  InputNumber,
  notification,
  Row,
  Select,
} from 'antd';
import confirm from 'antd/lib/modal/confirm';
import React, { useCallback, useEffect, useState } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
const EditLine = ({ setModalEditIsVisible, record, data, setData }) => {
  const [infoAmount, setInfoAmount] = useState(0);
  const [inputDisabled, setInputDisabled] = useState(false);
  const [formEdit] = Form.useForm();

  const menu = [
    { value: 'TOTAL', label: 'TOTAL' },
    { value: 'ANTICIPADO', label: 'ANTICIPADO' },
  ];
  useEffect(() => {
    formEdit.setFieldsValue({
      paymentType: record.paymentType,
      paymentAmount: record.paymentAmount,
    });
    if (record.paymentType === 'TOTAL') {
      setInputDisabled(true);
    } else {
      setInputDisabled(false);
    }
  }, [record, data, formEdit]);

  const onSelectedCallback = useCallback(
    (selected) => {
      if (selected === 'TOTAL') {
        setInfoAmount(() => record?.balance);
        formEdit.setFieldsValue({
          paymentAmount: record.balance,
        });
        setInputDisabled(true);
      } else if (selected === 'ANTICIPADO') {
        setInputDisabled(false);
      }
    },
    [record, formEdit]
  );
  const handleEditLine = (values) => {
    confirm({
      title: 'Editar monto',
      icon: <ExclamationCircleOutlined />,
      content: '¿Deseas guardar datos modificados?',
      okText: 'Si',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        editLinePurchaseOrder(values);
      },
      onCancel() {},
    });
  };
  const editLinePurchaseOrder = async (values) => {
    const parsekey = parseInt(record.key);
    let found = data.findIndex((e) => e.key === parsekey);
    let rows = [...data];
    let item = { ...rows[found] };
    item.paymentType = values.paymentType;
    item.paymentAmount = values.paymentAmount;
    rows[found] = item;
    setData(rows);
    notification['success']({
      message: `Línea editada exitosamente `,
    });
    setModalEditIsVisible(false);
  };
  return (
    <div>
      <Form form={formEdit} onFinish={handleEditLine}>
        <Row gutter={16}>
          <Col xs={24} md={12}>
            <Form.Item
              name="paymentType"
              style={{
                margin: 0,
              }}
              rules={[
                {
                  required: true,
                  message: `Por favor ingrese tipo de pago`,
                },
              ]}
            >
              <Select
                onSelect={onSelectedCallback}
                defaultActiveFirstOption={true}
              >
                {menu.map((s) => (
                  <Select.Option key={s.value} value={s.label}>
                    {s.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              name="paymentAmount"
              style={{
                margin: 0,
              }}
              rules={[
                {
                  required: true,
                  message: `Por favor ingrese monto`,
                },
              ]}
            >
              <InputNumber
                value={infoAmount}
                // ref={inputRef}
                disabled={inputDisabled}
                style={{ width: '100%' }}
                min={1}
                max={record.balance}
                formatter={(value) =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="end">
          <Button
            htmlType="submit"
            className="btn-download"
            style={{ width: 160, marginTop: 20 }}
          >
            Editar
          </Button>
        </Row>
      </Form>
    </div>
  );
};

export default EditLine;
