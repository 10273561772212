import React from 'react';
import { Col, Layout, Row, Typography } from 'antd';
import GreetingsTop from '../../../components/Admin/GreetingsTop/GreetingsTop';
import SuppliersTable from '../../../components/Admin/Suppliers/SuppliersTable';
import BackButton from '../../../components/General/BackButton/BackButton';
export default function SupplierPage() {
  return (
    <Layout>
      <GreetingsTop />
      <HeaderTop />
      <div className="clients__container">
        <SuppliersTable />
      </div>
    </Layout>
  );
}

function HeaderTop() {
  const { Title } = Typography;

  return (
    <div className="clients__container">
      <Row xs={23} className="header-top">
        <Col xs={19} className="header-top__left">
          <Title
            level={3}
            style={{
              // position: 'absolute',
              top: 0,
              bottom: 0,
              height: '78%',
              margin: 'auto',
            }}
          >
            Proveedores
          </Title>
        </Col>
        <Col xs={5}>
          <Row
            style={{
              marginBottom: 30,
              justifyContent: 'right',
            }}
          >
            <BackButton routeName={'/'}></BackButton>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
