import React, { useEffect, useState } from 'react';
import { Col, Layout, Row, Typography, notification } from 'antd';
import GreetingsTop from '../../../components/Admin/GreetingsTop/GreetingsTop';
import PaymentFilter from '../../../components/Admin/Payment/PaymentFilters';
import PaymentTable from '../../../components/Admin/Payment/PaymentTable';
import { ROL_LAYOUT, ROL_SUPPLIER } from '../../../utils/constants';
import useUser from '../../../hooks/useUser';
import { getAccessTokenApi, logout } from '../../../api/auth';
import moment from 'moment';
import { messageError } from '../../../utils/general';

import './Payment.scss';
import { getAllPaymentComplements } from '../../../api/payment';
import DatePickerOptions from '../../../components/General/DatePickerOptions/DatePickerOptions';

export default function Payment() {
    const [reloadComplements, setReloadComplements] = useState(true);
    const [paymentComplements, setPaymentComplements] = useState([]);
    const token = getAccessTokenApi();

    const [date, setDate] = useState([moment().subtract(7, 'days'), moment()]);
    const [dateRange, setDateRange] = useState(null);
    useEffect(() => {
        if (reloadComplements) {
            filterDate(date);
        }
    }, []);

    function filterDate(date, dateString) {
        setReloadComplements(true);

        if (date) {
            // setFilteredInfo({});
            // setSortedInfo({});
            let date2 = {
                startDate: moment
                    .utc(date[0].hour(0).minute(0))
                    .format('YYYY-MM-DD HH:mm'),
                endDate: moment
                    .utc(date[1].hour(23).minute(59))
                    .format('YYYY-MM-DD HH:mm'),
            };
            let date3 = {
                startDate: moment(date[0]).format('DD-MM-YYYY'),
                endDate: moment(date[1]).format('DD-MM-YYYY'),
            };
            setDateRange(date3);
            let filters = '';
            if (dateString === 'Documento') {
                filters = `?DocStartDate=${date2.startDate}&DocEndDate=${date2.endDate}`;
            } else if (dateString === 'Recepcion') {
                filters = `?IssueStartDate=${date2.startDate}&IssueEndDate=${date2.endDate}`;
            }

            getAllPaymentComplements(token, filters).then((response) => {
                // console.log(response, 'response');
                if (response === undefined) {
                    const msg = messageError();

                    notification['error']({
                        message: msg,
                    });
                    return;
                }
                if (response?.statusCode === 401) {
                    notification['error']({
                        message: 'Usuario no autorizado.',
                    });
                    setTimeout(() => {
                        logout();
                        window.location.reload();
                    }, 1000);
                    return;
                }
                if (response?.statusCode === 400) {
                    setReloadComplements(false);
                }
                if (response?.statusCode === 404) {
                    setReloadComplements(false);
                } else if (response?.statusCode === 200) {
                    response.result.forEach((element, index) => {
                        element['key'] = index;
                    });
                    setPaymentComplements(response.result);
                }
            });
        }
    }
    return (
        <Layout>
            <GreetingsTop />

            <HeaderTop filterDate={filterDate} />

            <div className="payment__container">
                <PaymentTable
                    paymentComplements={paymentComplements}
                    reloadComplements={reloadComplements}
                    dateRange={dateRange}
                    setReloadComplements={setReloadComplements}
                />
            </div>
        </Layout>
    );
}

function HeaderTop({ filterDate }) {
    const { Title } = Typography;
    const { person } = useUser();

    return (
        <div className="payment__container">
            <Row  className="header-top">
                <Col xs={24} md={12} className="header-top__left">
                    <Title level={3}>Complemento de Pago</Title>
                </Col>
                
                <Col
                    xs={20}
                    md={12}
                    style={{
                        display: 'grid',
                        alignItems: 'center',
                        justifyContent: 'end',
                    }}
                >
                    <Row xs={24} md={12} style={{ marginBottom: 10 }}>
                        <p xs={24} style={{ marginRight: 20 }}>
                            Fecha de documento:
                        </p>
                        <DatePickerOptions
                            xs={24}
                            filterDate={filterDate}
                            typeDoc={'Documento'}
                        />
                    </Row>
                {/* <Row xs={10} md={12}>
                    <p style={{ marginRight: 26 }}>Fecha de recepción:</p>
                    <DatePickerOptions
                        filterDate={filterDate}
                        typeDoc={'Recepcion'}
                    />
                </Row> */}
                </Col>
                {[ROL_SUPPLIER, ROL_LAYOUT].some((r) => person.roles.includes(r)) && (
                    <Col
                        span={24}
                        className="header-top__right"
                        style={{ marginTop: 20 }}
                    >
                        <div>
                            <PaymentFilter />
                        </div>
                    </Col>
                )}

            </Row>
            
        </div>
    );
}
