import React, { useState } from 'react';
import { Button, Col, Row, Spin, Form, notification } from 'antd';
import { getAccessTokenApi, logout } from '../../../../api/auth';
import TextArea from 'antd/lib/input/TextArea';
import { addClarificationDetailApi } from '../../../../api/clarifications';

const AddCommentForm = ({ setModalIsVisible, idClarification }) => {
    const [loadingForm, setLoadingForm] = useState(false);
    const token = getAccessTokenApi();

    const [form] = Form.useForm();
    const sendComment = async (values) => {
        setLoadingForm(true);
        let data = values;
        data['noteId'] = idClarification;

        const response = await addClarificationDetailApi(token, data);
        // console.log(response, 'response comment');
        if (response === undefined) {
            notification['error']({
                message: 'Ocurrió un error, Inténtelo más tarde',
            });
            setLoadingForm(false);
            return false;
        }
        if (response.statusCode === 401) {
            notification['error']({
                message: 'Usuario no autorizado.',
            });
            setTimeout(() => {
                logout();
                window.location.reload();
            }, 1500);
            return false;
        }
        if (response.statusCode === 200) {
            notification['success']({
                message: 'Aclaración agregada con éxito',
            });
            setModalIsVisible(false);
        } else {
            let messageError = 'Ocurrió un error, Inténtelo otra vez';
            if (response.statusCode === 409) {
                messageError = response.description;
            }
            if (response.status === 400) {
                messageError = response.errors;
            }
            notification['error']({
                message: messageError,
            });
        }
        setLoadingForm(false);
    };
    return (
        <Spin spinning={loadingForm} tip="Guardando...">
            <Form
                name="addClarification"
                layout="vertical"
                form={form}
                onFinish={sendComment}
            >
                <Col xs={21}>
                    <Form.Item
                        label="Comentarios:"
                        name="Comments"
                        rules={[
                            {
                                required: true,
                                message: 'Por favor ingrese una descripción',
                            },
                        ]}
                    >
                        <TextArea placeholder="Comentario" maxLength={150} />
                    </Form.Item>
                </Col>

                <Row>
                    <Col span={24}>
                        <Form.Item>
                            <Button
                                htmlType="submit"
                                className="btn-enviar"
                                block
                            >
                                Agregar
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Spin>
    );
};

export default AddCommentForm;
