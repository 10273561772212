import React, { useEffect, useState } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Layout, Spin, notification } from 'antd';
import moment from 'moment';
import useAuth from '../hooks/useAuth';
import useMenuCollapse from '../hooks/useMenuCollapse';
import MenuTop from '../components/Admin/MenuTop';
import MenuSider from '../components/Admin/MenuSider';
import Login from '../pages/Login';
import Perfil from '../pages/Admin/Profile';
import useUser from '../hooks/useUser';
import { useLocation } from 'react-router-dom';
import { isMobile } from '../utils/general';

import './LayoutAdmin.scss';

const LayoutAdmin = (props) => {
  const { routes } = props;
  // const [menuCollapsed, setMenuCollapsed] = useState(false);
  const { Header, Content, Footer } = Layout;
  const { user, isLoading } = useAuth();
  const { menuCollapsed, setMenuCollapsed } = useMenuCollapse();
  const actualYear = moment().format('YYYY');
  const { person } = useUser();
  const location = useLocation();
  // const user = true;
  // const isLoading = false;

  useEffect(() => {
    if (isMobile()) {
      setMenuCollapsed(true);
    }
  }, []);
  useEffect(() => {
    if (person && person.passwordChanged === false) {
      notification['error']({
        message: 'Debes realizar el cambio de contraseña ',
      });
    }
  }, [person]);

  if (!user && !isLoading) {
    return (
      <>
        <Route path="/login" component={Login} />
        <Redirect to="/login" />
      </>
    );
  }
  if (isLoading) {
    return (
      <Spin className="spinner" spinning={isLoading} tip="Cargando..."></Spin>
    );
  }
  if (user && !isLoading) {
    return (
      <Layout>
        <MenuSider menuCollapsed={menuCollapsed} roles={person?.roles} />
        <Layout
          className="layout-admin"
          style={{ marginLeft: menuCollapsed ? '20px' : '200px' }}
        >
          <Header className="layout-admin__header">
            <MenuTop
              menuCollapsed={menuCollapsed}
              setMenuCollapsed={setMenuCollapsed}
            />
          </Header>

          <Content className="layout-admin__content">
            {person && person.passwordChanged && (
              <LoadRoutes routes={routes} roles={person.roles} />
            )}
            {person && person.passwordChanged === false && (
              <>
                <Route path="/perfil" component={Perfil} />
                {location.pathname !== '/perfil' && (
                  <Route path="*" component={() => <Redirect to="/perfil" />} />
                )}
              </>
            )}
          </Content>

          <Footer className="layout-admin__footer">
            {`© plan B Consultoría de Sistemas. Monterrey, N.L. México ${actualYear}.`}
          </Footer>
        </Layout>
      </Layout>
    );
  }

  return null;
};

function LoadRoutes({ routes, roles = [] }) {
  return (
    <Switch>
      {routes
        .filter((route) => {
          if (route.roles && typeof route.roles.some === 'function')
            return route.roles.some((r) => roles.includes(r));
          return false;
        })
        .map((route, index) => (
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            component={() => <route.component />}
          />
        ))}
    </Switch>
  );
}

export default LayoutAdmin;
