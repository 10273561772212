import React, { useState } from 'react';
import { Row, Col, Spin, Form, Button, notification, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import toBase64 from '../../../../../utils/toBase64';
import { getAccessTokenApi } from '../../../../../api/auth';
import { fetchApi } from '../../../../../helpers/fetch';
import { METHOD_POST } from '../../../../../utils/constants';
import { API_URL_FILES } from '../../../../../helpers/urls';

const AddFilesOperationForm = ({
  setModalIsVisible,
  operationId,
  setReloadDocuments,
}) => {
  const [loadingForm, setLoadingForm] = useState(false);
  const [form] = Form.useForm();
  const token = getAccessTokenApi();

  const [filesListImages, setFileListImages] = useState([
    // {
    //     uid: '1',
    //     name: 'imagen1.png',
    //     status: 'done',
    //     url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
    //     thumbUrl:
    //         'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
    //     response: { status: 'success' },
    // },
  ]);
  const [loadingImage, setLoadingImage] = useState(false);

  const extraImagesChange = (info) => {
    let fileList = [...info.fileList];

    if (fileList.length > 5) {
      notification['warn']({
        message: 'No puede subir más de 5 documentos',
      });
    }

    // 1. Limit the number of uploaded files
    // Only to show two recent uploaded files, and old ones will be replaced by the new
    fileList = fileList.slice(0, 5); //toma solo las 5 primeras
    // fileList = fileList.slice(-2); // remplaza las pasada y siempre son 2

    // 2. Read from response and show file link
    // fileList = fileList.map((file) => {
    //     if (file.response) {
    //         // Component will show file.url as link
    //         file.url = file.response.url;
    //     }
    //     return file;
    // });
    setFileListImages(fileList);
  };
  const propsUpload = {
    onRemove: async (file) => {
      if (file.response?.status === 'success') {
        // showDeleteConfirm(file);
        return false;
      } else {
        const index = filesListImages.indexOf(file);
        const newFileListImage = filesListImages.slice();
        newFileListImage.splice(index, 1);
        setFileListImages(newFileListImage);
        return true;
      }
    },
    beforeUpload: (file) => {
      return false;
    },
    onChange: extraImagesChange,
  };
  const uploadExtraImageServe = async () => {
    setLoadingImage(true);
    setLoadingForm(true);

    if (filesListImages.length === 0) {
      notification['error']({
        message: 'Debes agregar un documento',
      });
      setLoadingImage(false);
      setLoadingForm(false);
    }

    if (filesListImages.length > 0) {
      let promesas = [];
      let imgs = [];
      filesListImages.forEach((img) =>
        promesas.push({
          type: '1',
          extension: img.type.substr(img.type.indexOf('/') + 1),
          stringFileContent: toBase64(img.originFileObj),
          fileName: img.name,
          contentType: img.type,
        })
      );
      let res = await Promise.all(promesas.map((p) => p.stringFileContent));
      let resultado = [
        ...imgs,
        ...promesas.map((r, i) => ({
          ...r,
          stringFileContent: res[i].split(',')[1],
        })),
      ];
      const data = {
        operationId: parseInt(operationId),
        files: resultado,
      };

      for (let i = 0; i < data.files.length; i++) {
        const e = data.files[i];
        if (e.extension === '' || !e.stringFileContent) {
          notification['error']({
            message: `Error, el archivo ${e.fileName} esta dañado.`,
          });
          setLoadingImage(false);
          setLoadingForm(false);
          return;
        }
      }

      const config = {
        method: METHOD_POST,
        url: `${API_URL_FILES}/AttachFiles`,
        data: data,
        token,
        showNotification: false,
      };
      fetchApi(config)
        .then((response) => {
          if (response) {
            if (response.statusCode === 200) {
              notification['success']({
                message: 'Documentos agregados exitosamente.',
              });
            }
            if (response.statusCode === 400) {
              notification['error']({
                message: response.result.errorMessages,
              });
            }
            if (setReloadDocuments) {
              setReloadDocuments(true);
            }
            setModalIsVisible(false);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoadingImage(false);
          setLoadingForm(false);
        });
    }
  };

  return (
    <Spin spinning={loadingForm} tip="Guardando...">
      <Form name="add-comment" layout="vertical" form={form}>
        <div>
          <Row xs={24}>
            <Col xs={20}>
              {/* <Form.Item
                                name="file"
                                valuePropName="fileList"
                                getValueFromEvent={normFile}
                                hidden={loadingForm}
                                // rules={[
                                //     {
                                //         message: 'Suba una archivo',
                                //     },
                                // ]}
                            >
                                <Upload
                                    name="template"
                                    // beforeUpload={beforeUpload}
                                    listType="picture"
                                    multiple={false}
                                    onChange={handleChange}
                                    fileList={filesList}
                                >
                                    <Button
                                        type="primary"
                                        style={{ marginTop: 20 }}
                                        icon={<UploadOutlined />}
                                    >
                                        Click para cargar documento
                                    </Button>
                                </Upload>
                            </Form.Item> */}

              <Upload
                {...propsUpload}
                // accept="image/png,image/jpg"
                multiple
                maxCount={5}
                listType="picture"
                fileList={filesListImages}
                defaultFileList={filesListImages}
              >
                <Button
                  style={{ width: 220 }}
                  className="btn-download"
                  icon={<UploadOutlined />}
                >
                  Seleccionar Documentos
                </Button>
              </Upload>
              {filesListImages.length === 5 && (
                <p style={{ marginLeft: 20, marginTop: 10, color: 'red' }}>
                  {'Máximo 5 documentos permitidos.'}
                </p>
              )}
              <Button
                loading={loadingImage}
                onClick={uploadExtraImageServe}
                // type="primary"
                className="btn-enviar"
                block
                style={{ marginTop: 20, width: 180 }}
              >
                Guardar documentos
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
    </Spin>
  );
};

export default AddFilesOperationForm;
