import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Spin, Row, Col, Select } from 'antd';
import { METHOD_GET, METHOD_PUT } from '../../../../../utils/constants';
import { API_URL_CATALOG_VALUES } from '../../../../../helpers/urls';
import { fetchApi } from '../../../../../helpers/fetch';
import { getAccessTokenApi } from '../../../../../api/auth';
import {
  CITY_CATALOG_ID,
  COUNTRY_CATALOG_ID,
  STATE_CATALOG_ID,
} from '../../../../../helpers/constants';
import { getCatalogValueHandle } from '../../../../../helpers/handlerFunctionApi';

const EditContainerYard = ({
  setModalIsVisible,
  setReloadContainersYard,
  containerYard,
}) => {
  const [loadingForm, setLoadingForm] = useState(false);
  const token = getAccessTokenApi();
  const [form] = Form.useForm();
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [countrySelected, setCountrySelected] = useState(0);
  const [cities, setCities] = useState([]);
  const [editCointainerForm, setEditContainerForm] = useState([]);

  useEffect(() => {
    setLoadingForm(true);
    const config = {
      method: METHOD_GET,
      url: `${API_URL_CATALOG_VALUES}/GetAll/${COUNTRY_CATALOG_ID}`,
      data: null,
      token,
      // showNotification: true
    };
    fetchApi(config)
      .then((response) => {
        if (response) {
          let calogsValues = response.result;

          if (calogsValues) {
            calogsValues.map((cat, index) => {
              cat['key'] = index;
              cat['label'] = cat.description;
              cat['value'] = cat.id;
            });
          }
          setCountries(calogsValues);
        }
        setLoadingForm(false);
      })
      .catch((err) => {
        console.log(err);
      });
    getCatalogValueHandle(
      STATE_CATALOG_ID,
      setStates,
      setLoadingForm,
      form.getFieldValue('country')
    );
    getCatalogValueHandle(
      CITY_CATALOG_ID,
      setCities,
      setLoadingForm,
      form.getFieldValue('state')
    );
    //   getStateCatalogHandle();
  }, [form, token]);
  useEffect(() => {
    setLoadingForm(true);
    const config = {
      method: METHOD_GET,
      url: `CY/${containerYard}`,
      data: null,
      token,
      // showNotification: true
    };
    fetchApi(config)
      .then((response) => {
        setEditContainerForm([
          {
            name: ['name'],
            value: response.result.name,
          },
          {
            name: ['address'],
            value: response.result.address,
          },
          {
            name: ['city'],
            value: response.result.city !== 0 ? response.result.city : null,
          },
          {
            name: ['state'],
            value: response.result.state !== 0 ? response.result.state : null,
          },
          {
            name: ['country'],
            value:
              response.result.country !== 0 ? response.result.country : null,
          },
        ]);
      })
      .catch((err) => {
        console.log(err);
      });

    //   getStateCatalogHandle();
  }, [containerYard, token]);

  const onChangeCountryOrigin = (value) => {
    // console.log(value);
    if (value !== countrySelected) {
      form.setFieldsValue({
        state: null,
        city: null,
      });
    }
    setCountrySelected(value);
    getCatalogValueHandle(STATE_CATALOG_ID, setStates, setLoadingForm, value);
    getCatalogValueHandle(CITY_CATALOG_ID, setCities, setLoadingForm, value);
  };
  const onChangeState = (value) => {
    getCatalogValueHandle(CITY_CATALOG_ID, setCities, setLoadingForm, value);
    form.setFieldsValue({
      city: null,
    });
  };
  const onFinish = async (values) => {
    setLoadingForm(true);
    values['id'] = containerYard;
    const config = {
      method: METHOD_PUT,
      url: `CY/${containerYard}`,
      data: values,
      token,
      showNotification: true,
    };
    const result = await fetchApi(config);
    if (result) {
      if (result.statusCode === 200) {
        // form.resetFields();
        setModalIsVisible(false);
        setReloadContainersYard(true);
      }
    }
    setLoadingForm(false);
  };

  return (
    <div className="add-catalog-form">
      <Spin spinning={loadingForm} tip="Cargando...">
        <Form
          name="add-promo"
          layout="vertical"
          form={form}
          onFinish={onFinish}
          fields={editCointainerForm}
          initialValues={{ active: true }}
        >
          <div>
            <Row xs={24} className="table-company">
              <Col xs={20}>
                <Form.Item
                  label="Nombre:"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor ingrese nombre',
                    },
                  ]}
                >
                  <Input placeholder="Ingresa tu información" />
                </Form.Item>
                <Form.Item label="Dirección" name="address">
                  <Input placeholder="Ingresa tu información" />
                </Form.Item>
                <Form.Item label="País:" name="country">
                  <Select
                    placeholder="Selecciona una opción"
                    onChange={onChangeCountryOrigin}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {countries.map((s) => (
                      <Select.Option key={s.value} value={s.value}>
                        {s.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item label="Estado:" name="state">
                  <Select
                    // disabled={disableAllInputs}
                    placeholder="Selecciona una opción"
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={onChangeState}
                    // style={{minWidth: width > 480 ? 220 : '100%', width: width > 480 ? 220 : '100%'}}
                  >
                    {states.map((s) => (
                      <Select.Option key={s.value} value={s.value}>
                        {s.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item label="Ciudad:" name="city">
                  <Select
                    // disabled={disableAllInputs}
                    placeholder="Selecciona una opción"
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    // style={{minWidth: width > 480 ? 220 : '100%', width: width > 480 ? 220 : '100%'}}
                  >
                    {cities.map((s) => (
                      <Select.Option key={s.value} value={s.value}>
                        {s.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <Col span={24}>
                  <Row justify="end">
                    <Form.Item>
                      <Button htmlType="submit" className="btn-enviar" block>
                        Guardar
                      </Button>
                    </Form.Item>
                  </Row>
                </Col>
              </Col>
            </Row>
          </div>
        </Form>
      </Spin>
    </div>
  );
};

export default EditContainerYard;
