import {
  Button,
  Col,
  Form,
  Row,
  Spin,
  Upload,
  notification,
  Modal as ModalAntd,
} from 'antd';
import React, { useEffect, useState } from 'react';
import {
  FileAddOutlined,
  ExclamationCircleOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import {
  METHOD_GET,
  METHOD_POST,
  METHOD_PUT,
  ROL_FINANCIAL,
  ROL_ADMIN,
  ROL_LAYOUT,
  ROL_SUPPLIER,
} from '../../../../utils/constants';
import { API_URL_INVOICE } from '../../../../helpers/urls';
import { getAccessTokenApi } from '../../../../api/auth';
import { fetchApi, fetchBlob } from '../../../../helpers/fetch';
import toBase64 from '../../../../utils/toBase64';
import useUser from '../../../../hooks/useUser';
import { formatMoney } from '../../../../utils/general';

const AddEditInvoiceOperation = (props) => {
  const {
    setModalIsVisible,
    operationId,
    invoiceId,
    setReloadTable,
    type,
    operationStatus,
    response,
    nameXml,
    fileXML,
    filePDF,
  } = props;
  const [invoiceOperationForm] = Form.useForm();
  const [loadingForm, setLoadingForm] = useState(false);
  const token = getAccessTokenApi();
  const { confirm } = ModalAntd;
  const [loadingImage, setLoadingImage] = useState(false);
  const [filesListImages, setFileListImages] = useState([]);
  const [filesList, setFileList] = useState([]);
  const [invoice, setInvoice] = useState(response);
  const { person } = useUser();
  const [statusCatalog, setStatusCatalog] = useState([
    { value: 0, label: 'FACTURADO' },
    { value: 1, label: 'PAGADO' },
  ]);
  useEffect(() => {
    if (fileXML) {
      setFileList((filesList) => [...filesList, ...[fileXML, filePDF]]);
    }
  }, []);

  const extraImagesChange = (info) => {
    let fileList = [...info.fileList];
    if (fileList.length > 5) {
      notification['warn']({
        message: 'No puede subir más de 5 documentos',
      });
    }
    fileList = fileList.slice(0, 5);
    setFileListImages(fileList);
  };
  const propsFiles = {
    showUploadList: {
      showDownloadIcon: true,
      showRemoveIcon: false,
    },
    onDownload: (data) => {
      let a = document.createElement('a');
      document.body.appendChild(a);
      a.style = 'display: none';
      const config = {
        method: METHOD_GET,
        url: `Invoice/${props.invoiceId}/DownloadFile/${data.id}`,
        data: null,
        token,
        showNotificationError: false,
      };
      fetchBlob(config)
        .then((response) => {
          let url = window.URL.createObjectURL(response);
          a.href = url;
          a.download = data.name;
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onPreview: (data) => {
      let a = document.createElement('a');
      document.body.appendChild(a);
      a.style = 'display: none';
      const config = {
        method: METHOD_GET,
        url: `Invoice/${props.invoiceId}/DownloadFile/${data.id}`,
        data: null,
        token,
        showNotificationError: false,
      };
      fetchBlob(config)
        .then((response) => {
          let url = window.URL.createObjectURL(response);
          a.href = url;
          a.download = data.name;
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  };
  const propsUpload = {
    onRemove: async (file) => {
      if (file.response?.status === 'success') {
        // showDeleteConfirm(file);
        return false;
      } else {
        const index = filesListImages.indexOf(file);
        const newFileListImage = filesListImages.slice();
        newFileListImage.splice(index, 1);
        setFileListImages(newFileListImage);
        return true;
      }
    },
    beforeUpload: (file) => {
      return false;
    },

    onChange: extraImagesChange,
    //type === 'watch'
    showUploadList: {
      showDownloadIcon:
        // props.type !== 'watch' &&
        [ROL_ADMIN, ROL_LAYOUT, ROL_SUPPLIER, ROL_FINANCIAL].some((r) =>
          person.roles.includes(r)
        ),

      showRemoveIcon:
        props.type !== 'watch' &&
        fileXML &&
        operationStatus !== 'Pagado' &&
        [ROL_ADMIN, ROL_LAYOUT, ROL_FINANCIAL].some((r) =>
          person.roles.includes(r)
        ),
    },

    onDownload: (data) => {
      let a = document.createElement('a');
      document.body.appendChild(a);
      a.style = 'display: none';
      const config = {
        method: METHOD_GET,
        url: `Invoice/${props.invoiceId}/DownloadFile/${data.id}`,
        data: null,
        token,
        showNotificationError: false,
      };
      fetchBlob(config)
        .then((response) => {
          let url = window.URL.createObjectURL(response);
          a.href = url;
          a.download = data.name;
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    onPreview: (data) => {
      let a = document.createElement('a');
      document.body.appendChild(a);
      a.style = 'display: none';
      const config = {
        method: METHOD_GET,
        url: `Invoice/${props.invoiceId}/DownloadFile/${data.id}`,
        data: null,
        token,
        showNotificationError: false,
      };
      fetchBlob(config)
        .then((response) => {
          let url = window.URL.createObjectURL(response);
          a.href = url;
          a.download = data.name;
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  };

  const uploadExtraImageServe = () => {
    setLoadingImage(true);
    setLoadingForm(true);
    // filesListImages.push(fileXML[0]);
    // filesListImages.push(filePDF[0]);

    if (filesListImages.length > 0 || filesList.length > 0) {
      let imgs = [];

      filesListImages.forEach((img) => {
        if (img.status === 'done') {
          imgs.push({
            extension: img.extension,
            base64Promise: '',
            stringFileContent: img.stringFileContent,
            fileName: img.name,
            contentType: img.type,
          });
        } else {
          imgs.push({
            extension: img.originFileObj.type.split('/')[1],
            base64Promise: toBase64(img.originFileObj),
            stringFileContent: '',
            fileName: img.name,
            contentType: img.type,
          });
        }
      });
      filesList.forEach((img) => {
        if (img.status === 'done') {
          imgs.push({
            extension: img.extension,
            base64Promise: '',
            stringFileContent: img.stringFileContent,
            fileName: img.name,
            contentType: img.type,
            canDelete: false,
          });
        } else {
          imgs.push({
            extension: img.originFileObj.type.split('/')[1],
            base64Promise: toBase64(img.originFileObj),
            stringFileContent: '',
            fileName: img.name,
            contentType: img.type,
            canDelete: false,
          });
        }
      });

      Promise.all(imgs.map((img) => img.base64Promise)).then((values) => {
        values.forEach((value, index) => {
          if (value) {
            imgs[index].stringFileContent = value.split(',')[1];
          }
        });
      });

      const data = {
        documents: imgs,
      };

      setLoadingImage(false);
      setLoadingForm(false);

      return data;
    }
  };

  useEffect(() => {
    //edit or watch
    if (invoiceId) {
      setLoadingForm(true);
      const config = {
        method: METHOD_GET,
        url: `${API_URL_INVOICE}/${invoiceId}`,
        data: null,
        token,
        showNotificationError: true,
      };
      fetchApi(config)
        .then((response) => {
          if (response.statusCode === 200) {
            const result = response.result;
            const date = moment.unix(result.date);
            const invoice = {
              invoiceDate: date,
              invoiceNumber: result.number,
            };
            invoiceOperationForm.setFieldsValue(invoice);
            setInvoice(result);
            setFileListImages(
              response.result.invoicefile.map((File, index) => {
                return {
                  uid: index,
                  name: File.fileName,
                  extension: File.extension,
                  stringFileContent: File.stringFileContent,
                  status: 'done',
                  url: File.fileLocation,
                  thumbUrl: File.fileLocation,
                  id: File.id,
                };
              })
            );
            setFileList(
              response.result.filesThatCantDelete.map((File, index) => {
                return {
                  uid: index,
                  name: File.fileName,
                  extension: File.extension,
                  stringFileContent: File.stringFileContent,
                  status: 'done',
                  url: File.fileLocation,
                  thumbUrl: File.fileLocation,
                  id: File.id,
                };
              })
            );
          }
          // setLoadingForm(false);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoadingForm(false);
        });
    }
  }, [invoiceId, token]);

  const handleSaveInvoiceOperation = (values) => {
    var dataImg = uploadExtraImageServe();

    // if (filesListImages.length === 0) {
    //   notification['error']({
    //     message: 'Agregar documento',
    //   });
    //   setLoadingImage(false);
    //   setLoadingForm(false);
    // } else {
    confirm({
      title: !invoiceId ? 'Guardar factura' : 'Editar factura',
      icon: <ExclamationCircleOutlined />,
      content: !invoiceId
        ? '¿Estás seguro que deseas guardar factura?'
        : '¿Estás seguro que deseas editar factura?',
      okText: 'Si',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        // return;
        addInvoiceOperation(values, dataImg);
      },
      onCancel() {},
    });
    // }
  };
  const addInvoiceOperation = (values, dataImg) => {
    setLoadingForm(true);
    // values['invoiceDate'] = moment(values.invoiceDate).unix();
    values['operationId'] = operationId;
    values['InvoiceFile'] = dataImg.documents;
    if (invoiceId) {
      values['invoiceId'] = invoiceId;
    }
    let SATDateFormatter = (dateStr) => {
      const dArr = dateStr.split('/'); // ex input: "2010-01-18"
      return dArr[1] + '/' + dArr[0] + '/' + dArr[2];
    };
    let invoiceDate = SATDateFormatter(invoice.fechaDocumento);
    values['InvoiceNumber'] = invoice.invoiceNumber;
    values['InvoiceDate'] = moment(invoiceDate).unix();
    values['Total'] = parseFloat(invoice.importe);
    values['Subtotal'] = parseFloat(invoice.subTotal);
    values['taxAmount'] = parseFloat(invoice.taxAmount);
    values['TaxRate'] = parseFloat(invoice.taxRate);
    values['Folio'] = invoice.folio;
    values['Currency'] = invoice.moneda;
    values['CustomerName'] = invoice.receptor;
    values['Concept'] = invoice.concept;

    const config = {
      method: invoiceId ? METHOD_PUT : METHOD_POST,
      url: `${API_URL_INVOICE}${invoiceId ? '/' + invoiceId : ''}`,
      data: values,
      token,
      showNotification: true,
    };
    fetchApi(config)
      .then((response) => {
        setLoadingForm(false);
        if (response) {
          if (response.statusCode === 200) {
            setModalIsVisible(false);
            setReloadTable(true);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const disabledDate = (current) => {
    return current && current > moment().endOf('day');
  };

  return (
    <div>
      <Spin spinning={loadingForm} tip="Cargando...">
        <Form
          name="add-quote"
          layout="vertical"
          form={invoiceOperationForm}
          onFinish={handleSaveInvoiceOperation}
          // onFinishFailed={validationOperationField}
        >
          <Row gutter={16} xs={24}>
            <Col
              xs={24}
              md={14}
              className="container-billing-gray"
              style={{ marginBottom: 10 }}
            >
              {fileXML && (
                <Row>
                  <Col xs={10}>
                    <p>Emisor</p>
                  </Col>
                  <Col xs={12}>
                    <p>{invoice?.emisor}</p>
                  </Col>
                </Row>
              )}

              <Row>
                <Col xs={10}>
                  <p>Receptor</p>
                </Col>
                <Col xs={12}>
                  <p>
                    {invoice?.receptor
                      ? invoice?.receptor
                      : invoice?.customerName}
                  </p>
                </Col>
              </Row>
              {fileXML && (
                <Row>
                  <Col xs={10}>
                    <p>Tipo de documento</p>
                  </Col>
                  <Col xs={12}>
                    <p>{invoice?.tipoDocumento}</p>
                  </Col>
                </Row>
              )}
              <Row>
                <Col xs={10}>
                  <p>Fecha de documento</p>
                </Col>
                <Col xs={12}>
                  <p>
                    {invoice
                      ? invoice?.fechaDocumento
                        ? invoice?.fechaDocumento
                        : moment.unix(invoice?.date).format('DD/MM/YYYY')
                      : ''}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs={10}>
                  <p>No. factura</p>
                </Col>
                <Col xs={12}>
                  <p>
                    {invoice?.invoiceNumber
                      ? invoice?.invoiceNumber
                      : invoice?.number}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs={10}>
                  <p>Folio</p>
                </Col>
                <Col xs={12}>
                  <p>{invoice?.folio}</p>
                </Col>
              </Row>
              {fileXML && (
                <>
                  <Row>
                    <Col xs={10}>
                      <p>Validación fiscal</p>
                    </Col>
                    <Col xs={12}>
                      <p>{invoice?.estatus}</p>
                    </Col>
                  </Row>
                  <Row xs={10}>
                    <Col xs={10}>
                      <p>Serie: </p>
                    </Col>
                    <Col xs={12}>
                      <p>{invoice?.serie}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={10}>
                      <p>Moneda</p>
                    </Col>
                    <Col xs={12}>
                      <p>{invoice?.moneda}</p>
                    </Col>
                  </Row>
                </>
              )}
              <Row>
                <Col xs={10}>
                  <p>Subtotal</p>
                </Col>
                <Col xs={12}>
                  <p>
                    ${invoice ? formatMoney(parseFloat(invoice?.subTotal)) : ''}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs={10}>
                  <p>Tasa o cuota</p>
                </Col>
                <Col xs={12}>
                  <p>
                    {invoice ? formatMoney(parseFloat(invoice?.taxRate)) : ''}%
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs={10}>
                  <p>Impuesto</p>
                </Col>
                <Col xs={12}>
                  <p>
                    $
                    {invoice ? formatMoney(parseFloat(invoice?.taxAmount)) : ''}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs={10}>
                  <p>Importe total</p>
                </Col>
                <Col xs={12}>
                  <p>
                    $
                    {invoice
                      ? formatMoney(
                          parseFloat(
                            invoice?.importe ? invoice?.importe : invoice?.total
                          )
                        )
                      : ''}
                  </p>
                </Col>
              </Row>
            </Col>
            <Col md={10}>
              <Form.Item>
                <Upload
                  {...propsFiles}
                  multiple
                  listType="picture"
                  fileList={filesList}
                  defaultFileList={filesList}
                ></Upload>
              </Form.Item>
              <Form.Item>
                <Upload
                  {...propsUpload}
                  // accept="image/png,image/jpg"
                  multiple
                  listType="picture"
                  fileList={filesListImages}
                  defaultFileList={filesListImages}
                >
                  {[ROL_ADMIN, ROL_LAYOUT, ROL_FINANCIAL].some((r) =>
                    person.roles.includes(r)
                  ) &&
                    fileXML && (
                      <Button
                        style={{ width: 250, marginLeft: 0 }}
                        className="btn-download"
                        icon={<UploadOutlined />}
                        disabled={
                          type === 'watch' || operationStatus === 'Pagado'
                        }
                      >
                        Comprobante de pago
                      </Button>
                    )}
                </Upload>
              </Form.Item>
            </Col>
          </Row>
          {fileXML && (
            <Row justify="end">
              <Col>
                <Form.Item>
                  <Button
                    className="btn-enviar"
                    block
                    disabled={operationStatus === 'Pagado'}
                    htmlType="submit"
                    icon={<FileAddOutlined />}
                  >
                    {invoiceId ? 'Guardar' : 'Guardar'}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          )}
        </Form>
      </Spin>
    </div>
  );
};

export default AddEditInvoiceOperation;
