import {
  Button,
  Col,
  Form,
  Row,
  Modal as ModalAntd,
  Select,
  notification,
  Spin,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { ExclamationCircleOutlined, SendOutlined } from '@ant-design/icons';
import { METHOD_POST } from '../../../../utils/constants';
import { API_URL_RFQ } from '../../../../helpers/urls';
import { getAccessTokenApi, logout } from '../../../../api/auth';
import { fetchApi } from '../../../../helpers/fetch';
import { getAllSuppliers } from '../../../../api/supplier';
import { messageErrorGeneral } from '../../../../utils/general';

const SendEmailForm = (props) => {
  const { setModalIsVisible, rfq } = props;
  const [invoiceOperationForm] = Form.useForm();
  const [loadingForm, setLoadingForm] = useState(false);
  const [suppliers, setSuppliers] = useState([]);
  const token = getAccessTokenApi();
  const { confirm } = ModalAntd;

  useEffect(() => {
    setLoadingForm(true);
    getAllSuppliers(token, '')
      .then((response) => {
        if (response === undefined) {
          notification['error']({
            message: 'Ocurrió un error, Inténtelo más tarde',
          });
          return false;
        }
        if (response.statusCode === 200) {
          let supp = response.result
            .filter((s) => s.active)
            .map((e, i) => ({ ...e, key: i }));
          setSuppliers(supp);
        } else {
          if (response.statusCode === 401) {
            notification['error']({
              message: 'Usuario no autorizado',
            });
            setTimeout(() => {
              logout();
              window.location.reload();
            }, 1500);
            return;
          }
          notification['error']({
            message: messageErrorGeneral(),
          });
          return;
        }
        //   setLoadingForm(false);
      })
      .finally(() => {
        setLoadingForm(false);
      });
  }, [token]);

  const handleSendEmail = (values) => {
    confirm({
      title: 'Enviar correo',
      icon: <ExclamationCircleOutlined />,
      content: '¿Estás seguro que deseas enviar correo?',
      okText: 'Si',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        // return;
        sendEmailFunction(values);
      },
      onCancel() {},
    });
  };
  const sendEmailFunction = (values) => {
    setLoadingForm(true);
    values['id'] = rfq.id;
    const config = {
      method: METHOD_POST,
      url: `${API_URL_RFQ}/SendRfqEmailToSupplier`,
      data: values,
      token,
      showNotification: true,
    };
    fetchApi(config)
      .then((response) => {
        setLoadingForm(false);
        if (response) {
          if (response.statusCode === 200) {
            setModalIsVisible(false);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div>
      <Spin spinning={loadingForm} tip="Cargando...">
        <Form
          name="add-quote"
          layout="vertical"
          form={invoiceOperationForm}
          onFinish={handleSendEmail}
        >
          <Row gutter={16} justify="center">
            <Col xs={24} md={12} lg={12}>
              <Form.Item
                label="Proveedores:"
                name="Emailrfq"
                rules={[
                  {
                    required: true,
                    message: 'Formato de correo electrónico inválido',
                    validator: (_, value) => {
                      let error = false;
                      value.forEach((element) => {
                        let isOK = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(
                          element
                        );
                        if (!isOK) error = true;
                      });
                      if (error) {
                        return Promise.reject(
                          'Formato de correo electrónico inválido'
                        );
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
              >
                <Select
                  placeholder="Selecciona una opción o escriba un correo electrónico"
                  optionFilterProp="children"
                  mode="tags"
                  filterOption={(input, option) => {
                    const children = option.children;
                    if (children) {
                      return (
                        children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      );
                    }
                    return false;
                  }}
                >
                  {suppliers?.map((s) => (
                    <Select.Option key={s.key} value={s.emailAddress}>
                      {s.emailAddress}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row justify="end">
            <Col>
              <Form.Item>
                <Button
                  className="btn-enviar"
                  block
                  htmlType="submit"
                  icon={<SendOutlined />}
                >
                  Enviar
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </div>
  );
};

export default SendEmailForm;
