/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  Row,
  Spin,
  Form,
  Alert,
  Col,
  Button,
  Tabs,
  notification,
  Modal as ModalAntd,
  Tooltip,
} from 'antd';
import { ClearOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import BasicPricingTab from './BasicPricingTab';
import DimensionsTable from '../Quote/AddQuoteForm/DimensionsTable/DimensionsTable';
import {
  AEREO_VALUE_ID,
  MARITIMO_VALUE_ID,
  TERRESTRE_VALUE_ID,
} from '../../../helpers/constants';
import { METHOD_GET, METHOD_POST, METHOD_PUT } from '../../../utils/constants';
import { API_URL_RATE, API_URL_RFQ } from '../../../helpers/urls';
import { fetchApi } from '../../../helpers/fetch';
import { getAccessTokenApi } from '../../../api/auth';
import useUser from '../../../hooks/useUser';

import './AddPricingForm.scss';
import RouteTab from './RouteTab/RouteTab';

const AddEditPricingForm = (props) => {
  const { type, setModalIsVisible, rateId, setReloadTable, isRfq, isResponse } =
    props;
  const [form] = Form.useForm();
  const [loadingForm, setLoadingForm] = useState(true);
  const [loadingCatalog, setLoadingCatalog] = useState(false);
  const [service, setService] = useState(TERRESTRE_VALUE_ID);
  const [validatedCPOrigin, setValidatedCPOrigin] = useState(false);
  const [validatedCPDestination, setValidatedCPDestination] = useState(false);
  const [disableAllInputs, setDisableAllInputs] = useState(
    type === 'watch' ? true : false
  );
  const [textType, setTextType] = useState('');
  const [textDescription, setTextDescription] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [isRFQ, setIsRFQ] = useState(false);
  const [rateApi, setRateApi] = useState(null);
  const [keyTabActive, setKeyTabActive] = useState('BasicPricingTab');
  const [showAllValues, setShowAllValues] = useState(true);
  const [pieces, setPieces] = useState([]);
  const [filterSupplier, setFilterSupplier] = useState({
    service: TERRESTRE_VALUE_ID,
  });
  const { confirm } = ModalAntd;
  const token = getAccessTokenApi();
  const { TabPane } = Tabs;
  const { person } = useUser();
  const [disabledSave, setDisableSave] = useState(false);
  const [supplierSelected, setSupplierSelected] = useState(null);
  const [supplierRoutes, setSupplierRoutes] = useState([]);
  const [allRoutes, setAllRoutes] = useState([]);
  const [routeSelected, setRouteSelected] = useState(0);

  useEffect(() => {
    //edit or watch
    if (rateId) {
      setLoadingForm(true);

      let config = {};
      config = {
        method: METHOD_GET,
        url: `${API_URL_RATE}/${rateId}`,
        data: null,
        token,
        showNotificationError: true,
      };
      if (isRfq) {
        config = {
          method: METHOD_GET,
          url:
            type !== 'Edit' ? `${API_URL_RFQ}/${rateId}` : `RFQLine/${rateId}`,
          data: null,
          token,
          showNotificationError: true,
        };
      }
      fetchApi(config)
        .then((response) => {
          if (response.statusCode === 200) {
            let result = [];
            let userName = '';
            let supplairName = '';

            if (!isRfq) {
              result = response.result[0].rate;
              userName = response.result[0].createdBy;
              supplairName = response.result[0].supplierName;
            }
            if (isResponse && type === 'Edit') {
              result = response.result.rfqLine;
              setPieces(response.result.pieces);
              supplairName = response.result.rfqLine.supplierName;
            } else if (isRfq) {
              result = response.result.rfq;
              userName = response.result.createdBy;
              setPieces(response.result.pieces);
              supplairName = response.result.supplierName;
            }
            if (isRfq) {
              result['routeStr'] = response.result.routeStr;
            } else {
              result['routeStr'] = response.result[0].routeStr;
            }
            setRateApi(result);
            const rate = {
              id: result.id,
              RateId: result.rateId,
              validity: result.startDate
                ? result.startDate !== null
                  ? [moment.unix(result.startDate), moment.unix(result.endDate)]
                  : null
                : null,
              StartDate: result.startDate ? result.startDate : null,
              EndDate: result.endDate ? result.endDate : null,
              IsCompleted: result.isCompleted,
              service: result.service,
              TypeOfService: result.typeOfService,
              HazMat: result.hazMat,
              HazMatDescription: result.hazMatDescription,
              Operation: result.operation !== 0 ? result.operation : null,
              EquipmentType:
                result.equipmentType !== 0 ? result.equipmentType : null,
              TypeOfLoad: result.typeOfLoad,
              originCountry: result.originCountry,
              originZipCode: result.originZipCode,
              originState: result.originState,
              originCity: result.originCity,
              originAddress: result.originAddress,
              originPort: result.originPort,
              originAirport: result.originAirport,
              destinationCountry: result.destinationCountry,
              destinationZipCode: result.destinationZipCode,
              destinationState: result.destinationState,
              destinationCity: result.destinationCity,
              destinationAddress: result.destinationAddress,
              destinationPort: result.destinationPort,
              destinationAirport: result.destinationAirport,
              routeId: result.routeId,
              modalityId: result.modalityId,
              UnitType: result.unitType,
              UnitPrice: result.unitPrice,
              cost: result.cost,
              number: result.number ? result.number : 0,
              Comments: result.comments,
              Currency: result.currency !== 0 ? result.currency : null,
              IsRFQ: result.isRFQ,
              OriginAirport: result.originAirport,
              OriginPort: result.originPort,
              DestinationAirport: result.destinationAirport,
              DestinationPort: result.destinationPort,
              Cost: result.cost,
              Unit: result.unit,
              Packaging: result.packaging !== 0 ? result.packaging : null,
              CreatedBy: result.createdBy,
              LastUpdatedBy: result.lastUpdatedBy,
              CreatedAt: result.createdAt,
              LastUpdatedAt: result.lastUpdatedAt,
              SupplierId: result.supplierId,
              ContactId: result.contactId,
              supplier: supplairName,
              MeasurementUnit:
                result.measurementUnit !== 0 ? result.measurementUnit : null,
              user: userName,
              cargoVolume: result.cargoVolume,
              loadUnloadRequirementIds: result.loadUnloadRequirementIds,
              loadUnloadTime: result.loadUnloadTime,
              evidence: result.evidence,
              creditTimeId: result.creditTimeId,
            };
            form.setFieldsValue(rate);
            setService(result.service);
            setIsRFQ(result.isRFQ);
            // setRouteSelected(result.routeId);
            if (result.supplierId && supplairName) {
              setSupplierSelected({
                supplierId: result.supplierId,
                supplierName: supplairName,
              });
            }
            if (isRfq) {
              let routesC = [
                {
                  value: result.routeId,
                  label: result.routeStr,
                },
              ];
              routesC.sort((a, b) => a.label.localeCompare(b.label));
              setSupplierRoutes(routesC);
            }
            setRouteSelected({
              routeId: result.routeId,
              routeStr: result.routeStr,
            });
          }
          // setLoadingForm(false);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoadingForm(false);
        });
    } else {
      form.setFieldsValue({
        service: TERRESTRE_VALUE_ID,
        user: person.userName,
      });
    }
  }, []);
  useEffect(() => {
    if (supplierSelected) {
      const config = {
        method: METHOD_GET,
        url: `Route/GetBySupplier/${supplierSelected.supplierId}`,
        data: null,
        token,
      };
      fetchApi(config)
        .then((response) => {
          // console.log(response, 'response');
          if (response) {
            let supRoutes = response.result;
            let routesSupplier = [];
            if (supRoutes) {
              routesSupplier = supRoutes.map((e, index) => {
                return e;
              });
            }
            let routesC = routesSupplier.map((e) => ({
              value: e.routeId,
              label: `${e.originCityStr} ${e.originStateStr} - ${e.destinationCityStr} ${e.destinationStateStr}`,
            }));
            routesC.sort((a, b) => a.label.localeCompare(b.label));

            setSupplierRoutes(routesC);
            setAllRoutes(routesSupplier);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [token, supplierSelected]);

  const addPricingFunction = async (values) => {
    setDisableSave(true);
    // setLoadingForm(true);
    values['StartDate'] = moment(values.validity[0]).unix();
    values['EndDate'] = moment(values.validity[1]).unix();
    if (rateId) {
      values['id'] = rateId;

      if (rateApi.isCompleted) {
        notification['error']({
          description: 'No puede modificar esta tarifa',
        });
        return;
      }
    }
    if (isResponse && type === 'Add') {
      values['RateId'] = parseInt(rateId);
      values['id'] = 0;
    }
    if (isResponse) {
      values['supplierName'] = values.supplier;
    }
    const continueValidation = validationRuteField(values);
    if (values['service'] === 0) {
      notification['error']({
        description: 'Agregue un servicio',
      });
      return;
    }
    if (!continueValidation) {
      return;
    }
    let config = {};

    config = {
      method: rateId ? METHOD_PUT : METHOD_POST,
      url: `${API_URL_RATE}${rateId ? '/' + rateId : ''}`,
      data: values,
      token,
      showNotification: false,
    };
    if (isRfq) {
      config = {
        method: type !== 'Add' ? METHOD_PUT : METHOD_POST,
        // url: !isResponse ? `${API_URL_RFQ}/${rateId}` : `RFQLine/${values.id}`,
        url: type === 'Add' ? `RFQLine` : `RFQLine/${values.id}`,
        data: values,
        token,
        showNotificationError: false,
      };
    }
    fetchApi(config)
      .then((response) => {
        // console.log(response, 'response edit rfq');
        setLoadingForm(false);
        if (response) {
          if (response.statusCode === 200) {
            setReloadTable(true);
            setModalIsVisible(false);
            // setDisableSave(false);
            notification['success']({
              description: response.description,
            });
          }
          if (response.statusCode === 400) {
            notification['error']({
              description: response.result,
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setDisableSave(false);
      });
  };

  const errorFields = ({ values, errorFields, outOfDate }) => {
    if (service === TERRESTRE_VALUE_ID) {
      if (
        !values.originCountry ||
        !values.originZipCode ||
        !values.originState ||
        !values.originCity ||
        !values.originAddress ||
        !values.destinationCountry ||
        !values.destinationZipCode ||
        !values.destinationState ||
        !values.destinationCity ||
        !values.destinationAddress
      ) {
        setTextType('Datos incompletos');
        setTextDescription('Complete todos los datos de la sección "Ruta"');
        setShowAlert(true);

        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
      }
    } else if (service === MARITIMO_VALUE_ID) {
      if (
        !values.originCountry ||
        !values.originState ||
        !values.originCity ||
        !values.originPort ||
        !values.destinationCountry ||
        !values.destinationState ||
        !values.destinationCity ||
        !values.destinationPort
      ) {
        setTextType('Datos incompletos');
        setTextDescription('Complete todos los datos de la sección "Ruta"');
        setShowAlert(true);

        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
      }
    } else if (service === AEREO_VALUE_ID) {
      if (
        !values.originCountry ||
        !values.originState ||
        !values.originCity ||
        !values.originAirport ||
        !values.destinationCountry ||
        !values.destinationState ||
        !values.destinationCity ||
        !values.destinationAirport
      ) {
        setTextType('Datos incompletos');
        setTextDescription('Complete todos los datos de la sección "Ruta"');
        setShowAlert(true);

        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
      }
    }

    if (
      !values.supplier ||
      !values.ContactId ||
      // !values.StartDate ||
      // !values.EndDate ||
      // !values.IsCompleted ||
      !values.service ||
      !values.TypeOfService ||
      // !values.HazMat ||
      // !values.HazMatDescription ||
      !values.Operation ||
      // !values.EquipmentType ||
      !values.TypeOfLoad ||
      !values.ContactId ||
      !values.validity ||
      !values.UnitPrice ||
      !values.MeasurementUnit
    ) {
      setTextType('Datos incompletos');
      setTextDescription('Complete todos los datos de la sección "Detalle"');
      setShowAlert(true);

      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }
  };

  const validationRuteField = (values) => {
    let pass = true;
    if (service === TERRESTRE_VALUE_ID) {
      if (
        !values.originCountry ||
        !values.originZipCode ||
        !values.originState ||
        !values.originCity ||
        !values.originAddress ||
        !values.destinationCountry ||
        !values.destinationZipCode ||
        !values.destinationState ||
        !values.destinationCity ||
        !values.destinationAddress
      ) {
        setTextType('Datos incompletos');
        setTextDescription('Complete todos los datos de la sección "Ruta"');
        setShowAlert(true);

        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
        pass = false;
      }
    } else if (service === MARITIMO_VALUE_ID) {
      if (
        !values.originCountry ||
        !values.originState ||
        !values.originCity ||
        !values.originPort ||
        !values.destinationCountry ||
        !values.destinationState ||
        !values.destinationCity ||
        !values.destinationPort
      ) {
        setTextType('Datos incompletos');
        setTextDescription('Complete todos los datos de la sección "Ruta"');
        setShowAlert(true);

        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
        pass = false;
      }
    } else if (service === AEREO_VALUE_ID) {
      if (
        !values.originCountry ||
        !values.originState ||
        !values.originCity ||
        !values.originAirport ||
        !values.destinationCountry ||
        !values.destinationState ||
        !values.destinationCity ||
        !values.destinationAirport
      ) {
        setTextType('Datos incompletos');
        setTextDescription('Complete todos los datos de la sección "Ruta"');
        setShowAlert(true);

        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
        pass = false;
      }
    }
    return pass;
  };

  function callback(key) {
    setKeyTabActive(key);
  }

  function clearFilterRate() {
    setFilterSupplier({});
    setRouteSelected(0);
    setSupplierRoutes([]);
    const rateEmpty = {
      service: null,
      TypeOfService: null,
      HazMat: null,
      HazMatDescription: null,
      Operation: null,
      EquipmentType: null,
      TypeOfLoad: null,
      UnitType: null,
      Unit: null,
      Packaging: null,
      ContactId: null,
      supplier: null,
      MeasurementUnit: null,
      Currency: null,
      originZipCode: null,
      originAddress: null,
      destinationZipCode: null,
      destinationAddress: null,
      routeId: null,
      originCountry: null,
      originState: null,
      originCity: null,
      originPort: null,
      originAirport: null,
      destinationCountry: null,
      destinationState: null,
      destinationCity: null,
      destinationPort: null,
      destinationAirport: null,
    };
    form.setFieldsValue(rateEmpty);
    setShowAllValues(true);
  }

  function showConfirm() {
    confirm({
      title: 'Estás seguro que deseas limpiar las opciones',
      icon: <ExclamationCircleOutlined />,
      content: 'No podrá deshacer esta opción',
      okText: 'Si, limpiar',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk() {
        clearFilterRate();
      },
    });
  }

  return (
    <Spin spinning={loadingForm || loadingCatalog} tip="Cargando...">
      <Form
        name="add-promo"
        layout="vertical"
        form={form}
        onFinish={addPricingFunction}
        onFinishFailed={errorFields}
      >
        <Tabs
          defaultActiveKey={'BasicPricingTab'}
          activeKey={keyTabActive}
          type="card"
          size={'middle'}
          onChange={callback}
        >
          <TabPane tab="Detalle" key="BasicPricingTab">
            <BasicPricingTab
              disableAllInputs={disableAllInputs}
              service={service}
              setService={setService}
              setLoadingForm={setLoadingForm}
              form={form}
              rate={rateApi}
              setLoadingCatalog={setLoadingCatalog}
              setShowAllValues={setShowAllValues}
              showAllValues={showAllValues}
              setFilterSupplier={setFilterSupplier}
              filterSupplier={filterSupplier}
              isRFQ={isRFQ}
              supplierSelected={supplierSelected}
              setSupplierSelected={setSupplierSelected}
              routeSelected={routeSelected}
            />
          </TabPane>
          <TabPane tab="Ruta" key="RouteTab" forceRender>
            <RouteTab
              rate={rateApi}
              disableAllInputs={disableAllInputs}
              service={service}
              validatedCPOrigin={validatedCPOrigin}
              setValidatedCPOrigin={setValidatedCPOrigin}
              validatedCPDestination={validatedCPDestination}
              setValidatedCPDestination={setValidatedCPDestination}
              setLoadingForm={setLoadingForm}
              form={form}
              type={type}
              isRfq={isRfq}
              allRoutes={allRoutes}
              setRouteSelected={setRouteSelected}
              supplierSelected={supplierSelected}
              routes={supplierRoutes}
            />
          </TabPane>
          {isRFQ && (
            <TabPane tab="Piezas y dimensiones" key="DimensionsTable">
              <DimensionsTable
                disableAllInputs={false}
                pieces={pieces}
                type={'rfq'}
              />
            </TabPane>
          )}
        </Tabs>

        {showAlert && (
          <Alert
            message={textType}
            description={textDescription}
            type="warning"
            showIcon
            style={{ marginBottom: 20 }}
          />
        )}

        {type !== 'watch' && (
          <Row style={{ justifyContent: 'end' }}>
            {!isRFQ && (
              <Col xs={24} md={4}>
                <Form.Item>
                  <Row justify="end">
                    <Tooltip title="Los campos que se limpian son los que sirven para filtrar el proovedor">
                      <Button
                        style={{ margin: 0, width: 150 }}
                        className="btn-download"
                        block
                        size="small"
                        icon={<ClearOutlined />}
                        onClick={showConfirm}
                        disabled={rateApi?.isCompleted}
                      >
                        Limpiar opciones
                      </Button>
                    </Tooltip>
                  </Row>
                </Form.Item>
              </Col>
            )}
            {!isResponse && (
              <Col xs={24} md={4}>
                <Form.Item>
                  <Row justify="end">
                    <Button
                      style={{ margin: 0 }}
                      htmlType="submit"
                      className="btn-enviar"
                      block
                      loading={disabledSave}
                      disabled={disabledSave || rateApi?.isCompleted}
                    >
                      Guardar
                    </Button>
                  </Row>
                </Form.Item>
              </Col>
            )}
            {isResponse && (
              <Col xs={24} md={6}>
                <Form.Item>
                  <Row justify="end">
                    <Button
                      style={{ margin: 0, width: 159 }}
                      htmlType="submit"
                      className="btn-enviar"
                      block
                      loading={disabledSave}
                      disabled={disabledSave || rateApi?.isCompleted}
                    >
                      {type === 'Add'
                        ? 'Agregar respuesta'
                        : 'Editar respuesta'}
                    </Button>
                  </Row>
                </Form.Item>
              </Col>
            )}
          </Row>
        )}
      </Form>
    </Spin>
  );
};

export default AddEditPricingForm;
