export const saveFilteredDataToLocalStorage =(tableName, pagination, sorter, filters, dateFilters) => {
  const filteredData = {
    pagination: pagination,
    sorter: sorter,
    filters: filters,
    dateFilters: dateFilters,
  };
  try {
    const filteredDataJSON = JSON.stringify(filteredData);
    localStorage.setItem(tableName, filteredDataJSON);
  } catch (error) {
    console.error('Error saving filtered data to localStorage:', error);
  }
}

export const getFilteredDataFromLocalStorage =(tableName) => {
  try {
    const datosFiltradosJSON = localStorage.getItem(tableName);
    return datosFiltradosJSON ? JSON.parse(datosFiltradosJSON) : null;
  } catch (error) {
    console.error('Error al obtener los datos filtrados del localStorage:', error);
    return null;
  }
}