import {
    Button,
    Col,
    Form,
    Row,
    Spin,
    Typography,
    Upload,
  } from 'antd';
  import { UploadOutlined } from '@ant-design/icons';
  import React, { useEffect, useState } from 'react';
  import { notification } from 'antd';
  import { basePath } from '../../../../../api/config';
  import { getAccessTokenApi } from '../../../../../api/auth';
import { METHOD_GET } from '../../../../../utils/constants';
import { fetchApi } from '../../../../../helpers/fetch';
  
  
  const AddCustomerDocument = (props) => {
    const { type, row, file:filec, setModalIsVisible,setReloadTable, add, update,clientId } = props;
    const { Text } = Typography;
    const [form] = Form.useForm();
    const [fileBlob, setFileBlob] = useState(null);
    const [fileList, setFileList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState(null);
    const token = getAccessTokenApi();
  
    useEffect(() => {
        if (filec) {
            const config = {
                method: METHOD_GET,
                url: `customerFiles/${filec.id}`,
                data: null,
                token,
              };
              fetchApi(config)
                .then((response) => {
                  if (response) {
                      if (response.statusCode === 200) {
                          setData(response.result)
                      }
                  }
                  setIsLoading(false)
                })
                .catch((err) => {
                  console.log(err);
                });
        }
      }, [token,filec]);

      useEffect(() => {
        if (data !== null) {
          form.setFieldsValue({
            file: {
                uid: 'tmp',
                name: data.fileName,
                status: 'done',
                url: data.fileLocation,
            },
          });
          setFileList([
            { uid: 'tmp', name: data.fileName, status: 'done', url: data.fileLocation },
          ]);
        }
        setIsLoading(false)
      }, [data, form]);
  
    const addFileFunction = async (values) => {
        setIsLoading(true)
      if (type === 'add' && fileBlob) {
        let url = basePath + '/customerFiles';
        const formData = new FormData();
        let fileValues = values.file.file.originFileObj;
        formData.append('file', fileValues);
        formData.append('key', row.key);
        formData.append('customerId', clientId);
        let fetchParams = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          method: 'POST',
          body: formData,
        };
        let httpResponse = await fetch(url, fetchParams);
        let jsonText = await httpResponse.text();
        let json = JSON.parse(jsonText);
        if (json.statusCode === 200) {
          notification.success({
            message: 'Archivo subido correctamente',
          });
          let savedFile = json.result;
          add(savedFile);
          setReloadTable(true);
          setModalIsVisible(false);
        }
      } else if (type === 'edit' && fileBlob) {
        let url = basePath + `/customerFiles/${filec.id}`;
  
console.log(values,'values');
        
        const formData = new FormData();
        let fileValues = values.file.file.originFileObj;
        formData.append('file', fileValues);
        formData.append('id', filec.id);
console.log(formData,'formData');
        let fetchParams= {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          method: 'PUT',
          body: formData,
        };
        let httpResponse = await fetch(url, fetchParams);
        let jsonText = await httpResponse.text();
        let json = JSON.parse(jsonText);
        if (json.statusCode === 200) {
          notification.success({
            message: 'Archivo editado correctamente',
          });
          let savedFile = json.response;
          update(savedFile);
          setReloadTable(true);
          setModalIsVisible(false);
        }
      }
      setIsLoading(false)

    };
    const propsUpload = {
        showUploadList: {
            showDownloadIcon: false,
            showRemoveIcon: false,
        },
      };
    return (
      <Spin spinning={isLoading}>
        <Form name="file" form={form} onFinish={addFileFunction}>
          <Row style={{ display: 'flex', justifyContent: 'center' }}>
            <Col xs={20}>
              <Row style={{ marginBottom: 20 }}>
                <Col xs={24} md={14} style={{ marginBottom: 20 }}>
                  <Text style={{ fontSize: 18, fontWeight: 500 }}>
                    {/* {JSON.stringify(file)} */}
                    { row.name_description}
                  </Text>
                </Col>
                <Col xs={24} md={8}>
                  <Form.Item name="file">
                    <Upload
                    //   accept=".pdf"
                    {...propsUpload}
                      fileList={[...fileList]}
                    //   listType="picture"
                      maxCount={1}
                      style={{ width: '100% !important' }}
                      customRequest={({ file, onSuccess, onError }) => {
                        if (onSuccess) {
                          onSuccess('ok');
                          setFileList([
                            { uid: 'tmp', name: file.name, status: 'done', url: '' },
                          ]);
                          setFileBlob(file);
                        }
                      }}
                    >
                      <Button
                        style={{ width: 195, marginLeft: 0 }}
                        className="btn-download"
                        icon={<UploadOutlined />}
                      >
                        Subir archivo
                      </Button>
                    </Upload>
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="end">
                <Form.Item>
                  <Button
                    htmlType="submit"
                    disabled={isLoading}
                    className="btn-enviar"
                    >
                    Guardar
                  </Button>
                </Form.Item>
              </Row>
            </Col>
          </Row>
        </Form>
      </Spin>
    );
  };
  
  export default AddCustomerDocument;
  