import {
  Col,
  Row,
  Form,
  Modal as ModalAntd,
  Input,
  Button,
  notification,
  InputNumber,
  Select,
  Typography,
  Tag,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { METHOD_GET } from '../../../../../utils/constants';
import { PACKAGING_UNIT_CATALOG_ID } from '../../../../../helpers/constants';
import { API_URL_CATALOG_VALUES } from '../../../../../helpers/urls';
import { getAccessTokenApi } from '../../../../../api/auth';
import { fetchApi } from '../../../../../helpers/fetch';

const AddDimensionsForm = ({
  setModalIsVisible,
  setPieces,
  pieces,
  linesData,
  setLinesData,
  saveKey,
  type,
  dimensionRow,
  linea,
}) => {
  const [partsForm] = Form.useForm();
  const { confirm } = ModalAntd;
  const [editDimensionsForm, setEditDimensionsForm] = useState([]);
  const [loadingForm, setLoadingForm] = useState(false);
  const [packagingCatalog, setPackagingCatalog] = useState([]);
  const [packagingLabel, setPackagingLabel] = useState('');
  const [cbm, setCbm] = useState(0);
  const [lengthPiece, setLengthPiece] = useState(
    dimensionRow !== undefined ? dimensionRow.length : 1
  );
  const [heightPiece, setHeightPiece] = useState(
    dimensionRow !== undefined ? dimensionRow.height : 1
  );
  const [widthPiece, setWidthPiece] = useState(
    dimensionRow !== undefined ? dimensionRow.width : 1
  );
  const token = getAccessTokenApi();
  const { Text } = Typography;
  useEffect(() => {
    let cbmcount = lengthPiece * heightPiece * widthPiece;
    setCbm(cbmcount);
  }, [lengthPiece, heightPiece, widthPiece]);

  useEffect(() => {
    if (type === 'Edit') {
      setEditDimensionsForm([
        {
          name: ['piecesNumber'],
          value: dimensionRow.piecesNumber,
        },
        {
          name: ['packaging'],
          value: dimensionRow.packaging,
        },
        {
          name: ['length'],
          value: dimensionRow.length,
        },
        {
          name: ['width'],
          value: dimensionRow.width,
        },
        {
          name: ['height'],
          value: dimensionRow.height,
        },
        {
          name: ['weight'],
          value: dimensionRow.weight,
        },
        {
          name: ['cbm'],
          value: dimensionRow.cbm,
        },
        {
          name: ['description'],
          value: dimensionRow.description,
        },
      ]);
      setCbm(dimensionRow.cbm);
    }

    // return () => {
    //     cleanup
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dimensionRow]);

  useEffect(() => {
    setLoadingForm(true);
    const config = {
      method: METHOD_GET,
      url: `${API_URL_CATALOG_VALUES}/GetAll/${PACKAGING_UNIT_CATALOG_ID}`,
      data: null,
      token,
      // showNotification: true
    };
    fetchApi(config)
      .then((response) => {
        if (response) {
          let calogsValues = response.result;

          if (calogsValues) {
            calogsValues.map((cat, index) => {
              cat['key'] = index;
              cat['label'] = cat.description;
              cat['value'] = cat.id;
            });
          }
          setPackagingCatalog(calogsValues);
        }
        setLoadingForm(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [token]);

  const handleSaveDimensions = (values) => {
    confirm({
      title: 'Guardar dimensiones',
      icon: <ExclamationCircleOutlined />,
      content: '¿Estás seguro que deseas guardar dimensiones?',
      okText: 'Si',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        addDimensionsFunction(values);
      },
      onCancel() {},
    });
  };
  const addDimensionsFunction = async (values) => {
    values['cbm'] = cbm;
    values['totalWeight'] = values.piecesNumber * values.weight;
    values['totalCBM'] = values.piecesNumber * values.cbm;
    values['packagingLabel'] = packagingLabel;
    const parsekey = parseInt(saveKey);
    if (type === 'Add') {
      if (saveKey === 'nl') {
        values['key'] = pieces.length;
        setPieces([...pieces, values]);
        setModalIsVisible(false);
      } else {
        if (pieces !== undefined) {
          values['quoteLineId'] = linea.id;
          let copy = [...pieces, values];
          setPieces(copy);
          const linesDataCopy = [...linesData];
          linesDataCopy[parsekey].pieces = copy;
          setLinesData(linesDataCopy);
          setModalIsVisible(false);
        }
      }
      notification['success']({
        message: `Dimensiones agregadas exitosamente `,
      });
    } else {
      // console.log(values, 'values');
      let copy = [...pieces];
      let i = copy.findIndex((_, i) => i === dimensionRow.key);
      copy[i] = { ...values };
      if (linea) {
        copy[i]['quoteLineId'] = linea.id;
      }
      if (copy[i].packagingLabel === '') {
        copy[i]['packagingLabel'] = pieces[i].packagingLabel;
      }
      setPieces(copy);
      if (!isNaN(parsekey)) {
        const linesDataCopy = [...linesData];
        linesDataCopy[parsekey].pieces = copy;
        setLinesData(linesDataCopy);
      }
      notification['success']({
        message: `Dimensiones editadas exitosamente `,
      });
      setModalIsVisible(false);
    }
  };
  return (
    <div>
      <Form
        name="add-line"
        layout="vertical"
        form={partsForm}
        onFinish={handleSaveDimensions}
        fields={editDimensionsForm}
      >
        <Row gutter={16}>
          <Col md={6} xs={24}>
            <Form.Item
              label="Piezas:"
              name="piecesNumber"
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese numero de piezas',
                },
              ]}
            >
              <InputNumber
                style={{ width: '100%' }}
                min={0}
                placeholder="Ingrese información"
              />
            </Form.Item>
          </Col>
          <Col md={6} xs={24}>
            <Form.Item label="Embalaje:" name="packaging">
              <Select
                placeholder="Pallet"
                onChange={(value, label) => {
                  setPackagingLabel(label.children);
                }}
              >
                {packagingCatalog.map((s) => (
                  <Select.Option key={s.value} value={s.value}>
                    {s.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col md={6} xs={24}>
            <Form.Item
              label="Largo(mts):"
              name="length"
              initialValue={1}
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese largo',
                },
              ]}
            >
              <InputNumber
                style={{ width: '100%' }}
                min={0}
                placeholder="Ingrese información"
                onChange={(e) => setLengthPiece(e)}
              />
            </Form.Item>
          </Col>
          <Col md={6} xs={24}>
            <Form.Item
              label="Ancho(mts):"
              name="width"
              initialValue={1}
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese ancho',
                },
              ]}
            >
              <InputNumber
                style={{ width: '100%' }}
                min={0}
                placeholder="Ingrese información"
                onChange={(e) => setWidthPiece(e)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col md={6} xs={24}>
            <Form.Item
              label="Alto(mts):"
              name="height"
              initialValue={1}
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese alto',
                },
              ]}
            >
              <InputNumber
                style={{ width: '100%' }}
                min={0}
                placeholder="Ingrese información"
                onChange={(e) => setHeightPiece(e)}
              />
            </Form.Item>
          </Col>
          <Col md={6} xs={24}>
            <Form.Item
              label="Peso(kg):"
              name="weight"
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese peso',
                },
              ]}
            >
              <InputNumber
                style={{ width: '100%' }}
                min={0}
                placeholder="Ingrese información"
              />
            </Form.Item>
          </Col>

          <Col md={6} xs={24}>
            <Form.Item label="Descripción:" name="description">
              <Input placeholder="Ingrese información" />
            </Form.Item>
          </Col>
          <Col md={6} xs={24} style={{ marginBottom: 20 }}>
            <p style={{ marginBottom: 7 }}>CBM:</p>
            <Tag color="blue" style={{ padding: 5, width: '100%' }}>
              <Text className="text-label">{cbm}</Text>
            </Tag>
          </Col>
        </Row>
        <Row>
          <Col
            span={24}
            style={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <Form.Item>
              <Button htmlType="submit" className="btn-enviar" block>
                Guardar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default AddDimensionsForm;
