import React, { useEffect, useState } from 'react';
import { Col, Input, Layout, Row, Typography } from 'antd';
import GreetingsTop from '../../../components/Admin/GreetingsTop/GreetingsTop';
import AccountBalance from '../../../components/PortalClients/AccountBalance';
import { getAccessTokenApi } from '../../../api/auth';
import { formatMoney } from '../../../utils/general';
import { METHOD_GET } from '../../../utils/constants';

import './Balance.scss';
import { API_URL_INVOICE } from '../../../helpers/urls';
import { fetchApi } from '../../../helpers/fetch';
import BackButton from '../../../components/General/BackButton/BackButton';

export default function Balance() {
  const [balance, setBalance] = useState([]);
  const [total, setTotal] = useState(null);
  const token = getAccessTokenApi();
  const [loadingForm, setLoadingForm] = useState(null);

  useEffect(() => {
    setLoadingForm(true);
    const config = {
      method: METHOD_GET,
      url: `${API_URL_INVOICE}/?UnpaidBalance=true`,
      data: null,
      token,
      showNotificationError: true,
    };
    fetchApi(config)
      .then((response) => {
        if (response.statusCode === 200) {
          const result = response.result.invoices;
          const totalBlance = response.result.totalBalance;
          if (result) {
            result.map((cat, index) => {
              cat['key'] = index;
            });
          }
          setBalance(result);
          setTotal(totalBlance);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoadingForm(false);
      });
  }, [token]);

  return (
    <Layout>
      <GreetingsTop />
      <HeaderTop total={total} />
      <div className="billing__container">
        <AccountBalance
          balance={balance}
          loadingForm={loadingForm}
          setTotal={setTotal}
          total={total}
        />
      </div>
    </Layout>
  );
}
function HeaderTop({ total, setClient }) {
  const { Title } = Typography;

  return (
    <div className="billing__container">
      <Row className="header-top">
        <Col xs={20} md={20} className="header-top__left">
          <Row>
            <Title
              level={3}
              style={{
                position: 'absolute',
                top: 0,
                bottom: 0,
                height: '78%',
                margin: 'auto',
              }}
            >
              Estado de cuenta
            </Title>
          </Row>
        </Col>
        <Col xs={4}>
          <Row
            style={{
              marginBottom: 30,
              justifyContent: 'right',
            }}
          >
            <BackButton routeName={'/cxc/cliente'}></BackButton>
          </Row>
        </Col>
      </Row>
      <Row justify="end">
        <Col xs={20} md={11} className="container-fecha">
          <Row xs={24} md={12} style={{ marginBottom: 10 }}>
            <p style={{ marginRight: 5 }}>Saldo total:</p>
            <Input
              style={{ width: 218 }}
              disabled
              xs={24}
              value={total ? `$${formatMoney(total)}` : 0}
            ></Input>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
