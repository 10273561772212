import { getAccessTokenApi } from '../api/auth';
import { METHOD_GET } from '../utils/constants';
import { fetchApi } from './fetch';
import { API_URL_CATALOG_VALUES } from './urls';

export const getCatalogValueHandle = (
    catalogId,
    setState,
    setLoading,
    relationalId,
    descriptionId
) => {
    if (setLoading) {
        setLoading(true);
    }
    const token = getAccessTokenApi();

    let url = `${API_URL_CATALOG_VALUES}/GetAll/${catalogId}`;

    if (relationalId) {
        url = `${API_URL_CATALOG_VALUES}/GetAll/${catalogId}/${relationalId}`;
    }
    if (descriptionId) {
        url = `${API_URL_CATALOG_VALUES}/GetByCustomId/${descriptionId}`;
    }
    const config = {
        method: METHOD_GET,
        url,
        data: null,
        token,
        // showNotification: true
    };
    fetchApi(config)
        .then((response) => {
            if (response) {
                let calogsValues = response.result;

                if (calogsValues) {
                    calogsValues.map((cat, index) => {
                        cat['key'] = index;
                        cat['label'] = cat.description;
                        cat['value'] = cat.id;
                    });
                }
                setState(calogsValues);
                //  setStates(calogsValues);
            }
            if (setLoading) {
                setLoading(false);
            }
        })
        .catch((err) => {
            console.log(err);
        });
};

export const getCatalogValueReducerHandle = (
    catalogId,
    dispatch,
    setLoading,
    relationalId,
    type,
    descriptionId,
) => {
    if (setLoading) {
        setLoading(true);
    }
    const token = getAccessTokenApi();

    let url = `${API_URL_CATALOG_VALUES}/GetAll/${catalogId}`;

    if (relationalId) {
        url = `${API_URL_CATALOG_VALUES}/GetAll/${catalogId}/${relationalId}`;
    }
    if (descriptionId) {
        url = `${API_URL_CATALOG_VALUES}/GetByCustomId/${descriptionId}`;
    }

    const config = {
        method: METHOD_GET,
        url,
        data: null,
        token,
        // showNotification: true
    };
    fetchApi(config)
        .then((response) => {
            // console.log(response,'response');
            if (response) {
                let calogsValues = response.result;

                if (calogsValues) {
                    calogsValues.map((cat, index) => {
                        cat['key'] = index;
                        cat['label'] = cat.description;
                        cat['value'] = cat.id;
                    });
                }
                dispatch({ type: type, payload: calogsValues });
            }
            if (setLoading) {
                setLoading(false);
            }
        })
        .catch((err) => {
            console.log(err);
        });
};
