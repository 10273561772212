import React from 'react';
import { Col, Layout, Row, Typography } from 'antd';
import GreetingsTop from '../../../components/Admin/GreetingsTop/GreetingsTop';
import BillingTable from '../../../components/PortalClients/Billling/BillingTable';
import './Billing.scss';
import BackButton from '../../../components/General/BackButton/BackButton';
import useUser from '../../../hooks/useUser';
import { ROL_CUSTOMER } from '../../../utils/constants';

export default function Billing() {
  return (
    <Layout>
      <GreetingsTop />
      <HeaderTop />
      <div className="billing__container">
        <BillingTable />
      </div>
    </Layout>
  );
}
function HeaderTop() {
  const { Title } = Typography;
  const { person } = useUser();

  return (
    <div className="billing__container">
      <Row className="header-top">
        <Col xs={20} md={20} className="header-top__left">
          <Row>
            <Title
              level={3}
              style={{
                position: 'absolute',
                top: 0,
                bottom: 0,
                height: '78%',
                margin: 'auto',
              }}
            >
              Facturación cliente
            </Title>
          </Row>
        </Col>
        <Col xs={4}>
          <Row
            style={{
              marginBottom: 30,
              justifyContent: 'right',
            }}
          >
            {![ROL_CUSTOMER].some((r) => person.roles.includes(r)) && (
              <BackButton routeName={'/cxc/cliente'}></BackButton>
            )}
          </Row>
        </Col>
      </Row>
    </div>
  );
}
